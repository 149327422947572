import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { toggleProjectActiveState } from '../../actions/thunkProjectsActions.ts';
import { MyProject as MyProjectComponent } from '../../components/projects/MyProject.tsx';
import { IProjectViewModel } from '../../selectors/createProjectList.ts';
import { ProjectUiTransitionState } from '../../types/projectUiTransitionState.ts';

interface IMyProjectOwnProps {
  readonly project: IProjectViewModel;
  readonly onDeleteProject: () => void;
  readonly onCloneProject: () => void;
}

export const MyProject: React.FC<IMyProjectOwnProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isSettingUp = useSelector(
    (s) =>
      s.projectsApp.projectsUiTransitionState.findKey(
        (value) => value === ProjectUiTransitionState.SettingUp,
      ) === props.project.projectId,
  );

  const onToggleProjectActiveState = (activate: ProjectUiTransitionState) =>
    dispatch(toggleProjectActiveState(props.project.projectId, activate));
  const onRedirectToProjectSettings = () => {
    if (props.project.url) {
      history.push(props.project.url);
    }
  };

  return (
    <MyProjectComponent
      isSettingUp={isSettingUp}
      onDeleteProject={props.onDeleteProject}
      project={props.project}
      onToggleProjectActiveState={onToggleProjectActiveState}
      onRedirectToProjectSettings={onRedirectToProjectSettings}
      onCloneProject={props.onCloneProject}
    />
  );
};

MyProject.displayName = 'MyProject';
