import { Icon, Icons } from '@kontent-ai/component-library/Icons';
import { ISelectItem, Option } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SetUtils } from '../../../../_shared/utils/setUtils/setUtils.ts';
import { SemanticSearchOnboarding } from '../containers/SemanticSearchOnboarding.tsx';
import { SearchScope } from '../models/filter/IListingFilter.ts';

interface ISearchScopeOption extends ISelectItem<ISearchScopeOption> {
  readonly icon?: Icon;
  readonly id: string;
  readonly label: string;
  readonly value: ReadonlySet<SearchScope>;
}

const fullTextDisabledTooltipText =
  'Searching by content is currently unavailable. For more details, check our status page on https://status.kontent.ai.';

const namesAndContentOption = {
  id: 'names-and-content',
  label: 'Search in names and content',
  value: new Set<SearchScope>([SearchScope.Name, SearchScope.Content]),
};

const namesOnlyOption = {
  id: 'names',
  label: 'Search in names only',
  value: new Set<SearchScope>([SearchScope.Name]),
};

const aiSearchOption = {
  id: 'ai',
  label: 'Semantic search',
  value: new Set<SearchScope>([SearchScope.Ai]),
  icon: Icons.Sparkles,
};

const getScopeOptions = ({
  allowAiSearch,
  isFullTextDisabled,
}: Pick<Props, 'allowAiSearch' | 'isFullTextDisabled'>): ReadonlyArray<ISearchScopeOption> => {
  const options = [
    {
      ...namesAndContentOption,
      disabledTooltipText: isFullTextDisabled ? fullTextDisabledTooltipText : undefined,
    },
    namesOnlyOption,
  ];
  return allowAiSearch ? [...options, aiSearchOption] : options;
};

type Props = {
  readonly allowAiSearch: boolean;
  readonly isFullTextDisabled: boolean;
  readonly onChange: (scope: ReadonlySet<SearchScope>) => void;
  readonly scope: ReadonlySet<SearchScope>;
};

export const ContentItemsSearchScope = ({
  allowAiSearch,
  onChange,
  scope,
  isFullTextDisabled,
}: Props) => {
  const options = getScopeOptions({ allowAiSearch, isFullTextDisabled });
  const disabledOptionIds = options.filter((o) => !!o.disabledTooltipText).map((o) => o.id);
  const foundSelectedOption = options
    .filter((o) => !o.disabledTooltipText)
    .find((option) => SetUtils.isEqual(option.value, scope));

  const selectedOption =
    foundSelectedOption ?? options.find((o) => !o.disabledTooltipText) ?? options[0];

  return (
    <SemanticSearchOnboarding
      isSemanticSearchEnabled={allowAiSearch}
      renderTarget={(props) => (
        <div className="content-item-search-area__search-scope-selector-wrapper" {...props}>
          <SingleSelect
            aria-label="Select how to search for content"
            items={options}
            disabledItemIds={disabledOptionIds}
            placeholder="Select a search type"
            verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.SearchScope)}
            onSelectionChange={(_, item) => item && onChange(item.value)}
            selectedItemId={selectedOption?.id}
            renderMenuOption={(optionProps) => (
              <Option
                {...optionProps}
                leadingElement={optionProps.item.value?.icon && <optionProps.item.value.icon />}
              />
            )}
            renderPrefix={(_, item: ISearchScopeOption | null) => item?.icon && <item.icon />}
          />
        </div>
      )}
    />
  );
};
