import { identity } from '@kontent-ai/utils';
import { Action } from '../../../../../@types/Action.type.ts';
import { isLanguageActive } from '../../../../../_shared/models/utils/isLanguageActive.ts';
import { moveItem } from '../../../../../_shared/utils/dragDrop/dragDropUtils.ts';
import {
  LocalizationEditor_LangCreator_Hide,
  LocalizationEditor_LangCreator_Show,
  LocalizationEditor_LanguageBar_Move,
  LocalizationEditor_Language_Activate,
  LocalizationEditor_Language_Deactivate,
  LocalizationEditor_LanguagesLoading_Success,
} from '../../constants/localizationActionTypes.ts';

const initialState: ReadonlyArray<Uuid> = [];

export const languageItemsOrder = (
  state: ReadonlyArray<Uuid> = initialState,
  action: Action,
): ReadonlyArray<Uuid> => {
  switch (action.type) {
    case LocalizationEditor_LanguagesLoading_Success: {
      const loadedProjectLanguages = action.payload.projectLanguages;
      const languageItemsIds = loadedProjectLanguages.languages.map(
        (languageItem) => languageItem.id,
      );
      return languageItemsIds;
    }

    case LocalizationEditor_LangCreator_Show: {
      return [...state, action.payload.language.id];
    }

    case LocalizationEditor_LangCreator_Hide: {
      return state.filter((id) => id !== action.payload.languageId);
    }

    case LocalizationEditor_Language_Deactivate: {
      return [...state.filter((id) => id !== action.payload.id), action.payload.id]; // Put to bottom.
    }

    case LocalizationEditor_Language_Activate: {
      const { id, languages } = action.payload;
      const stateWithoutActivatedLanguage = state.filter((stateId) => stateId !== id);
      const firstInactiveIndex = stateWithoutActivatedLanguage.findIndex(
        (languageId) => !!languageId && !isLanguageActive(languages.get(languageId)),
      );
      return firstInactiveIndex >= 0
        ? [
            ...stateWithoutActivatedLanguage.slice(0, firstInactiveIndex),
            id,
            ...stateWithoutActivatedLanguage.slice(firstInactiveIndex),
          ]
        : state;
    }

    case LocalizationEditor_LanguageBar_Move: {
      const { movedItemId, targetItemId } = action.payload;
      return moveItem(state, movedItemId, targetItemId, identity);
    }

    default:
      return state;
  }
};
