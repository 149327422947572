import { AnchorButton, Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import React from 'react';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { isWebSpotlightEnabled } from '../../../../../webSpotlight/selectors/webSpotlightSelectors.ts';
import { useWebSpotlightInItemEditing } from '../../context/WebSpotlightInItemEditingContext.tsx';

type OpenInNewTabButtonProps = Readonly<{
  spaceId: Uuid | null;
}>;

export const OpenInNewTabButton: React.FC<OpenInNewTabButtonProps> = ({ spaceId }) => {
  const dispatch = useDispatch();
  const isWebSpotlightActive = useSelector(isWebSpotlightEnabled);
  const {
    previewMetadata: { url },
  } = useWebSpotlightInItemEditing();

  if (!url) {
    return null;
  }

  const trackAction = () => {
    dispatch(
      trackUserEventWithData(TrackedEvent.SpacePreviewOpened, {
        'space-id': spaceId ?? '',
        'is-web-spotlight-enabled': isWebSpotlightActive,
      }),
    );
  };

  return (
    <AnchorButton href={url} onPress={trackAction} target="_blank" buttonStyle="secondary">
      <Button.Icon icon={Icons.ArrowRightTopSquare} />
      <Button.Label>Open in new tab</Button.Label>
    </AnchorButton>
  );
};
