import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { getSuggestedContentTypeIds } from '../utils/getSuggestedContentTypeIds.ts';

export const getSuggestedContentTypes = (
  state: IStore,
  availableContentTypes: ReadonlyArray<IContentType>,
): ReadonlyArray<IContentType> => {
  const { recentlyUsedContentTypeIds, suggestedContentTypeIds } =
    state.contentApp.newContentItem.formConfig.contentType;

  const contentTypeIds = getSuggestedContentTypeIds(
    availableContentTypes,
    suggestedContentTypeIds,
    recentlyUsedContentTypeIds,
  );

  return availableContentTypes.filter((contentType) => contentTypeIds.includes(contentType.id));
};
