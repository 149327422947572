import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { ILanguage, Languages } from '../../../../../data/models/languages/Language.ts';
import {
  DecoratedYourContentItem,
  YourContentItem,
} from '../../../../../data/models/listingContentItems/YourContentItem.ts';

export const decorateYourContentItems = memoize.weak(
  (
    items: ReadonlyArray<YourContentItem>,
    languages: Immutable.OrderedMap<Uuid, ILanguage>,
    viewableLanguages: Languages,
    contentTypes: Immutable.Map<Uuid, IContentType>,
  ): ReadonlyArray<DecoratedYourContentItem> =>
    items.map((item): DecoratedYourContentItem => {
      const language = viewableLanguages.size === 1 ? null : languages.get(item.id.variantId);
      const contentType = contentTypes.get(item.contentTypeId);

      return {
        id: item.id,
        contentType: contentType?.name || '',
        contentTypeId: item.contentTypeId,
        due: item.due,
        language: language?.name || '',
        lastUpdatedAt: item.lastUpdatedAt,
        workflowStatus: item.workflowStatus,
        publishingState: item.publishingState,
        scheduledToPublishAt: item.scheduledToPublishAt,
        scheduledToUnpublishAt: item.scheduledToUnpublishAt,
        variantCompletionStatus: item.variantCompletionStatus,
        name: item.name,
      };
    }),
);
