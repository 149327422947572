import { isAbortError, isXMLHttpRequest } from '@kontent-ai/errors';
import { Collection } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { updateCurrentProject } from '../../../../_shared/actions/thunkSharedActions.ts';
import {
  ContentAppEntryRoute,
  ContentAppEntryRouteParams,
  ContentItemOpenCommentRoute,
  ContentItemOpenCommentRouteParams,
  ContentItemOpenTaskRoute,
  ContentItemOpenTaskRouteParams,
  ContentItemRoute,
  ContentItemRouteParams,
  ContentItemsAppRouteSegment,
} from '../../../../_shared/constants/routePaths.ts';
import { BuildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { isUuid } from '../../../../_shared/utils/validation/typeValidators.ts';
import { IContentItemRepository } from '../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { cannotOpenUsageDueToLackOfAccess } from '../../../contentInventory/content/constants/uiConstants.ts';
import { SmartLink_HandleSuccessfulRedirectToContentItem } from '../../constants/smartLinkActionTypes.ts';
import {
  ContentItemDeletedMessage,
  ContentItemNotFoundLinkText,
  ContentItemNotFoundMessage,
  CreateNewContentItemLinkText,
  LinkIsInvalidMessage,
  NoAccessToProjectLinkText,
  NoAccessToProjectMessage,
} from '../../constants/uiConstants.ts';
import { handleUnsuccessfulRedirect } from '../smartLinkActions.ts';

export interface IItemElement {
  readonly itemId: Uuid;
  readonly deliveryContentComponentId?: Uuid;
  readonly elementCodename?: string;
}

export interface IItemElementPath {
  readonly path: string;
  readonly currentItemId: Uuid;
  readonly deliveryContentComponentId?: Uuid;
  readonly elementCodename?: string;
}

export interface IEditItemVariantDependencies {
  readonly contentItemRepository: IContentItemRepository;
  readonly buildPath: BuildPath;
  readonly getSignInPageUrl: (includeRedirectUri?: boolean) => string;
}

export interface IEditItemVariantParameters {
  readonly projectId?: Uuid;
  readonly variantId?: Uuid;
  readonly path?: ReadonlyArray<IItemElement>;
  readonly predefinedFocus?: PredefinedFocus;
}

const handleSuccessfulRedirectToContentItem = (
  targetPath: string,
  hopsToEditedItem: ReadonlyArray<IItemElementPath>,
) =>
  ({
    type: SmartLink_HandleSuccessfulRedirectToContentItem,
    payload: {
      targetPath,
      hopsToEditedItem,
    },
  }) as const;

export enum FocusTarget {
  CommentThread = 'CommentThread',
  Task = 'Task',
}

type CommentThreadPredefinedFocus = {
  target: FocusTarget.CommentThread;
  commentThreadId: Uuid;
};

type TaskPredefinedFocus = {
  target: FocusTarget.Task;
  taskId: Uuid;
};

export type PredefinedFocus = CommentThreadPredefinedFocus | TaskPredefinedFocus;

const isValidCommentThreadFocus = (
  predefinedFocus: PredefinedFocus,
): predefinedFocus is CommentThreadPredefinedFocus =>
  predefinedFocus.target === FocusTarget.CommentThread && isUuid(predefinedFocus.commentThreadId);
const isValidTaskFocus = (
  predefinedFocus: PredefinedFocus,
): predefinedFocus is TaskPredefinedFocus =>
  predefinedFocus.target === FocusTarget.Task && isUuid(predefinedFocus.taskId);

export type EditItemVariantActionsType = ReturnType<typeof handleSuccessfulRedirectToContentItem>;

export const editItemVariantCreator =
  (deps: IEditItemVariantDependencies) =>
  (
    { projectId, variantId, path, predefinedFocus }: IEditItemVariantParameters,
    abortSignal?: AbortSignal,
  ): ThunkPromise =>
  async (dispatch, getState) => {
    try {
      if (
        !projectId ||
        !path ||
        !variantId ||
        !isUuid(variantId) ||
        !path.length ||
        path.some((pathItem: IItemElement) => !isUuid(pathItem.itemId))
      ) {
        dispatch(handleUnsuccessfulRedirect(null, LinkIsInvalidMessage, null));

        throw new Error('Provide valid project and item id.');
      }

      const {
        data: { user },
      } = getState();
      const project = user.projectsInfoById.get(projectId);
      if (!project) {
        dispatch(
          handleUnsuccessfulRedirect(
            NoAccessToProjectLinkText,
            NoAccessToProjectMessage,
            deps.getSignInPageUrl(false),
          ),
        );
        return;
      }

      await dispatch(updateCurrentProject(project, abortSignal));

      const hopsToEditedItem: IItemElementPath[] = [];
      path.reduce((itemsBeforeCurrent: ReadonlyArray<Uuid>, pathItem: IItemElement) => {
        const newPath = [...itemsBeforeCurrent, pathItem.itemId];
        if (pathItem.elementCodename || pathItem.deliveryContentComponentId) {
          const pathToCurrent = deps.buildPath<ContentItemRouteParams<UuidArray>>(
            ContentItemRoute,
            {
              app: ContentItemsAppRouteSegment.Content,
              variantId,
              projectId,
              spaceId: undefined,
              contentItemIds: newPath,
            },
          );
          hopsToEditedItem.push({
            path: pathToCurrent,
            currentItemId: pathItem.itemId,
            deliveryContentComponentId: pathItem.deliveryContentComponentId,
            elementCodename: pathItem.elementCodename,
          });
        }
        return newPath;
      }, []);

      const itemId = Collection.getLast(path)?.itemId;
      if (!itemId) {
        return;
      }
      const item = await deps.contentItemRepository.getItemWithAllVariants(itemId, abortSignal);

      if (item.variants.some((v) => !!v && v._id.variantId === variantId)) {
        const contentItemIds = path.map((pathItem: IItemElement) => pathItem.itemId);
        if (!predefinedFocus) {
          const targetPath = deps.buildPath<ContentItemRouteParams<UuidArray>>(ContentItemRoute, {
            app: ContentItemsAppRouteSegment.Content,
            projectId,
            variantId,
            spaceId: undefined,
            contentItemIds,
          });
          dispatch(handleSuccessfulRedirectToContentItem(targetPath, hopsToEditedItem));
        } else if (isValidCommentThreadFocus(predefinedFocus)) {
          const targetPath = deps.buildPath<ContentItemOpenCommentRouteParams<UuidArray>>(
            ContentItemOpenCommentRoute,
            {
              app: ContentItemsAppRouteSegment.Content,
              commentThreadId: predefinedFocus.commentThreadId,
              variantId,
              contentItemIds,
              projectId,
              spaceId: undefined,
            },
          );
          dispatch(handleSuccessfulRedirectToContentItem(targetPath, hopsToEditedItem));
        } else if (isValidTaskFocus(predefinedFocus)) {
          const targetPath = deps.buildPath<ContentItemOpenTaskRouteParams<UuidArray>>(
            ContentItemOpenTaskRoute,
            {
              app: ContentItemsAppRouteSegment.Content,
              taskId: predefinedFocus.taskId,
              variantId,
              contentItemIds,
              projectId,
              spaceId: undefined,
            },
          );
          dispatch(handleSuccessfulRedirectToContentItem(targetPath, hopsToEditedItem));
        }
      } else {
        dispatch(
          handleUnsuccessfulRedirect(
            ContentItemNotFoundLinkText,
            ContentItemNotFoundMessage,
            deps.buildPath<ContentAppEntryRouteParams>(ContentAppEntryRoute, {
              projectId,
              variantId: undefined,
            }),
          ),
        );
      }
    } catch (error) {
      if (!isAbortError(error) && isXMLHttpRequest(error)) {
        const linkMessage =
          error.status === 403 ? cannotOpenUsageDueToLackOfAccess : ContentItemDeletedMessage;

        dispatch(handleUnsuccessfulRedirect(CreateNewContentItemLinkText, linkMessage, '/'));
        return;
      }

      throw error;
    }
  };
