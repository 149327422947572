import { SearchPhraseHighlighter } from '../../../../../../../../_shared/components/Highlighting/SearchPhraseHighlighter.tsx';
import { findMatchingIndexesInWordPrefixes } from '../../../../../../../../_shared/utils/filter/nameFilterUtils.ts';

type Props = {
  readonly highlightedName?: string | null;
  readonly itemName: string;
  readonly searchPhrase: string | null;
};

export const ContentItemSearchPhraseHighlighter = ({
  highlightedName,
  itemName,
  searchPhrase,
}: Props) => {
  if (highlightedName !== undefined) {
    return highlightedName ? (
      <span
        className="scroll-table__value scroll-table__item-name-text"
        dangerouslySetInnerHTML={{ __html: highlightedName }}
      />
    ) : (
      <span className="scroll-table__item-name-text">{itemName}</span>
    );
  }

  return searchPhrase ? (
    <SearchPhraseHighlighter
      className="scroll-table__value scroll-table__item-name-text"
      findMatchingIndexes={findMatchingIndexesInWordPrefixes}
      searchPhrase={searchPhrase}
      text={itemName}
    />
  ) : (
    <span className="scroll-table__item-name-text">{itemName}</span>
  );
};
