import { EnvironmentSettingsAppNames } from '../../applications/environmentSettings/root/constants/EnvironmentSettingsAppNames.ts';
import { AccessDeniedToContentItemAction } from '../../applications/errorHandling/constants/AccessDeniedToContentItemAction.ts';
import { ProjectSettingsAppNames } from '../../applications/projectSettings/root/constants/ProjectSettingsAppNames.ts';
import {
  ApiKeyListingRouteSegment,
  DeliveryKeysRouteSegment,
  EnvironmentsRouteSegment,
  GeneralRouteSegment,
  ManagementKeysRouteSegment,
  PersonalManagementKeyRouteSegment,
  TokenSeedIdSegment,
} from '../../applications/projectSettings/root/constants/routeSegments.ts';
import { uuidPattern } from '../utils/validation/typeValidators.ts';
import { DefaultWorkflowId } from './variantIdValues.ts';

export const RootRoute = '/';

/*
 * Out of environment routes
 */
export const ProjectsRoute = `${RootRoute}projects`;
export const ActiveProjectsRoute = `${ProjectsRoute}/active`;
export const ArchivedProjectsRoute = `${ProjectsRoute}/archived`;
export const UserProfileRoute = `${RootRoute}user-profile`;

/*
 * Project
 */
export const ProjectRoute = `${ProjectsRoute}/:projectContainerId(${uuidPattern})`;
export const ProjectSettingsRoute = `${ProjectRoute}/settings`;
export const ProjectSettingsEnvironmentsRoute = `${ProjectSettingsRoute}/${EnvironmentsRouteSegment}`;
export const ProjectSettingsGeneralRoute = `${ProjectSettingsRoute}/${GeneralRouteSegment}`;
export const ApiKeyListingRoute = `${ProjectSettingsRoute}/${ApiKeyListingRouteSegment}`;
export const MapiKeyListingRoute = `${ApiKeyListingRoute}/${ManagementKeysRouteSegment}`;
export const DapiKeyListingRoute = `${ApiKeyListingRoute}/${DeliveryKeysRouteSegment}`;
export const PersonalMapiKeyDetailRoute = `${MapiKeyListingRoute}/${PersonalManagementKeyRouteSegment}/${TokenSeedIdSegment}`;
export const MapiKeyDetailRoute = `${MapiKeyListingRoute}/${TokenSeedIdSegment}`;
export const DapiKeyDetailRoute = `${DapiKeyListingRoute}/${TokenSeedIdSegment}`;
export const NewPersonalMapiKeyDetailRoute = `${MapiKeyListingRoute}/${PersonalManagementKeyRouteSegment}/new`;
export const NewDapiKeyDetailRoute = `${DapiKeyListingRoute}/new`;
export const NewMapiKeyDetailRoute = `${MapiKeyListingRoute}/new`;

export type ProjectRouteParams = {
  readonly projectContainerId: Uuid;
};

export type ApiKeyDetailRouteParams = ProjectRouteParams & {
  readonly tokenSeedId: Uuid;
};

/*
 * Environment
 */
export const EnvironmentRoute = `${RootRoute}:projectId(${uuidPattern})`;

export type EnvironmentRouteParams = {
  readonly projectId: Uuid;
};

/*
 * CustomApps
 */
export const CustomAppsRoute = `${EnvironmentRoute}/custom-apps`;
export const CustomAppDetailRoute = `${CustomAppsRoute}/detail/:customAppId(${uuidPattern})`;

/*
 * Content models
 */
export const ContentModelsRoute = `${EnvironmentRoute}/content-models`;
export const ContentTypesRoute = `${ContentModelsRoute}/types`;
export const CreateNewContentTypeRoute = `${ContentTypesRoute}/new`;
export const EditContentTypeRoute = `${ContentTypesRoute}/edit/:contentTypeId(${uuidPattern})`;
export const ContentTypeSnippetsRoute = `${ContentModelsRoute}/snippets`;
export const CreateNewContentTypeSnippetsRoute = `${ContentTypeSnippetsRoute}/new`;
export const EditContentTypeSnippetRoute = `${ContentTypeSnippetsRoute}/edit/:contentTypeSnippetId(${uuidPattern})`;
export const SitemapRoute = `${ContentModelsRoute}/sitemap`;
export const TaxonomyGroupsRoute = `${ContentModelsRoute}/taxonomy`;
export const CreateNewTaxonomyGroupRoute = `${TaxonomyGroupsRoute}/new`;
export const EditTaxonomyGroupRoute = `${TaxonomyGroupsRoute}/edit/:taxonomyGroupId(${uuidPattern})`;
export const AssetTypesRoute = `${ContentModelsRoute}/asset-types`;

export type EditTaxonomyGroupRouteParams = EnvironmentRouteParams & {
  readonly taxonomyGroupId: Uuid;
};

export type EditContentTypeRouteParams = EnvironmentRouteParams & {
  readonly contentTypeId: Uuid;
};

export type EditContentTypeSnippetRouteParams = EnvironmentRouteParams & {
  readonly contentTypeSnippetId: Uuid;
};

/*
 * Settings
 */
export const EnvironmentSettingsRoute = `${EnvironmentRoute}/settings`;
export const AuditLogRoute = `${EnvironmentSettingsRoute}/audit-log`;
export const CollectionsRoute = `${EnvironmentSettingsRoute}/collections`;
export const CustomAppsConfigurationRoute = `${EnvironmentSettingsRoute}/custom-apps`;
export const CustomAppConfigurationCreatingRoute = `${CustomAppsConfigurationRoute}/new`;
export const CustomAppConfigurationEditingRoute = `${CustomAppsConfigurationRoute}/edit/:customAppId(${uuidPattern})`;
export const EnvironmentsRoute = `${EnvironmentSettingsRoute}/environments`;
export const LocalizationRoute = `${EnvironmentSettingsRoute}/localization`;
export const GeneralEnvironmentSettingsRoute = `${EnvironmentSettingsRoute}/general`;
export const PreviewURLsRoute = `${EnvironmentSettingsRoute}/preview-urls`;
export const RolesRoute = `${EnvironmentSettingsRoute}/roles`;
export const RoleCreatorRoute = `${RolesRoute}/new`;
export const RoleEditorRoute = `${RolesRoute}/edit/:roleId(${uuidPattern})`;
export const SpacesRoute = `${EnvironmentSettingsRoute}/spaces`;
export const SpaceRoute = `${SpacesRoute}/:spaceId(${uuidPattern})`;
export const UsersRoute = `${EnvironmentSettingsRoute}/users`;
export const UserDetailRoute = `${UsersRoute}/detail/:userId`;
export const InviteUserRoute = `${UsersRoute}/invite/:urlEncodedEmail?`;
export const WebhooksRoute = `${EnvironmentSettingsRoute}/webhooks`;
export const LegacyWebhooksRoute = `${EnvironmentSettingsRoute}/legacy-webhooks`;
export const WebhooksCreatingRoute = `${WebhooksRoute}/new`;
export const WebhooksEditingRoute = `${WebhooksRoute}/edit/:webhookId(${uuidPattern})`;
export const LegacyWebhookMessagesRoute = `${LegacyWebhooksRoute}/:webhookId(${uuidPattern})/messages`;
export const WebhookMessagesRoute = `${WebhooksRoute}/:webhookId(${uuidPattern})/messages`;
export const WorkflowsRoute = `${EnvironmentSettingsRoute}/workflows`;
export const WorkflowCreatingRoute = `${WorkflowsRoute}/new`;
export const WorkflowEditingRoute = `${WorkflowsRoute}/edit/:workflowId(${uuidPattern})`;
export const DefaultWorkflowRoute = `${WorkflowsRoute}/edit/${DefaultWorkflowId}`;

export type WebhooksRouteParams = EnvironmentRouteParams;

export type WebhookEditorRouteParams = WebhooksRouteParams & {
  readonly webhookId: Uuid;
};

export type RoleEditorRouteParams = EnvironmentRouteParams & {
  readonly roleId: Uuid;
};

export type WorkflowsRouteParams = EnvironmentRouteParams;

export type WorkflowEditingRouteParams = WorkflowsRouteParams & {
  readonly workflowId: Uuid;
};

export type WebhookMessagesRouteParams = EnvironmentRouteParams & {
  readonly webhookId: Uuid;
};

export type LegacyWebhookMessagesRouteParams = EnvironmentRouteParams & {
  readonly webhookId: Uuid;
};

export type UserDetailRouteParams = EnvironmentRouteParams & {
  readonly userId: Uuid;
};

export type InviteModalRouteParams = EnvironmentRouteParams & {
  readonly urlEncodedEmail?: string;
};

export type SpacesRouteParams = EnvironmentRouteParams;
export type SpaceRouteParams = SpacesRouteParams & {
  readonly spaceId: Uuid;
};

export type PreviewConfigurationRouteParams = EnvironmentRouteParams;

export type CustomAppConfigurationRouteParams = EnvironmentRouteParams;
export type CustomAppConfigurationEditingRouteParams = CustomAppConfigurationRouteParams & {
  readonly customAppId: Uuid;
};

export type CustomAppRouteParams = EnvironmentRouteParams;
export type CustomAppDetailRouteParams = CustomAppRouteParams & {
  readonly customAppId: Uuid;
};

/*
 * Subscription management
 */
export const SubscriptionsRoute = `${RootRoute}subscription`;
export const SubscriptionRoute = `${SubscriptionsRoute}/:subscriptionId(${uuidPattern})?`;
export const EnsuredSubscriptionRoute = `${SubscriptionsRoute}/:subscriptionId(${uuidPattern})`;
export const AdministratorsRoute = `${EnsuredSubscriptionRoute}/administrators`;
export const BillingHistoryRoute = `${EnsuredSubscriptionRoute}/billing-history`;
export const BillingInformationRoute = `${EnsuredSubscriptionRoute}/billing-information`;
export const PlanSelectionRoute = `${EnsuredSubscriptionRoute}/plan-selection`;
export const TransactionsRoute = `${EnsuredSubscriptionRoute}/transactions`;
export const ProjectsInSubscriptionRoute = `${EnsuredSubscriptionRoute}/projects`;
export const SubscriptionApiKeysRoute = `${EnsuredSubscriptionRoute}/api-keys`;
export const SubscriptionGeneralRoute = `${EnsuredSubscriptionRoute}/general`;
export const SubscriptionOverviewRoute = `${EnsuredSubscriptionRoute}/overview`;
export const SubscriptionUsersRoute = `${EnsuredSubscriptionRoute}/users`;
export const SubscriptionUserDetailRoute = `${SubscriptionUsersRoute}/detail/:userId`;
export const SubscriptionEnvironmentSettingsRoute = `${ProjectsInSubscriptionRoute}${EnvironmentRoute}/settings`;
export const SubscriptionEnvironmentAuditLogRoute = `${SubscriptionEnvironmentSettingsRoute}/audit-log`;
export const SubscriptionEnvironmentCollectionsRoute = `${SubscriptionEnvironmentSettingsRoute}/collections`;
export const SubscriptionEnvironmentCustomAppsConfigurationRoute = `${SubscriptionEnvironmentSettingsRoute}/custom-apps`;
export const SubscriptionEnvironmentCustomAppConfigurationCreatingRoute = `${SubscriptionEnvironmentCustomAppsConfigurationRoute}/new`;
export const SubscriptionEnvironmentCustomAppConfigurationEditingRoute = `${SubscriptionEnvironmentCustomAppsConfigurationRoute}/edit/:customAppId(${uuidPattern})`;
export const SubscriptionEnvironmentEnvironmentsRoute = `${SubscriptionEnvironmentSettingsRoute}/environments`;
export const SubscriptionEnvironmentLocalizationRoute = `${SubscriptionEnvironmentSettingsRoute}/localization`;
export const SubscriptionEnvironmentGeneralSettingsRoute = `${SubscriptionEnvironmentSettingsRoute}/general`;
export const SubscriptionEnvironmentPreviewURLsRoute = `${SubscriptionEnvironmentSettingsRoute}/preview-urls`;
export const SubscriptionEnvironmentRolesRoute = `${SubscriptionEnvironmentSettingsRoute}/roles`;
export const SubscriptionEnvironmentRoleCreatorRoute = `${SubscriptionEnvironmentSettingsRoute}/roles/new`;
export const SubscriptionEnvironmentRoleEditorRoute = `${SubscriptionEnvironmentSettingsRoute}/roles/edit/:roleId(${uuidPattern})`;
export const SubscriptionEnvironmentSpacesRoute = `${SubscriptionEnvironmentSettingsRoute}/spaces`;
export const SubscriptionEnvironmentUsersRoute = `${SubscriptionEnvironmentSettingsRoute}/users`;
export const SubscriptionEnvironmentUserDetailRoute = `${SubscriptionEnvironmentUsersRoute}/detail/:userId`;
export const SubscriptionEnvironmentInviteUserRoute = `${SubscriptionEnvironmentUsersRoute}/invite/:urlEncodedEmail?`;
export const SubscriptionEnvironmentWebhooksRoute = `${SubscriptionEnvironmentSettingsRoute}/webhooks`;
export const SubscriptionEnvironmentLegacyWebhooksRoute = `${SubscriptionEnvironmentSettingsRoute}/legacy-webhooks`;
export const SubscriptionEnvironmentWebhooksCreatingRoute = `${SubscriptionEnvironmentSettingsRoute}/webhooks/new`;
export const SubscriptionEnvironmentWebhooksEditingRoute = `${SubscriptionEnvironmentSettingsRoute}/webhooks/edit/:webhookId(${uuidPattern})`;
export const SubscriptionEnvironmentWebhookMessagesRoute = `${SubscriptionEnvironmentSettingsRoute}/webhooks/:webhookId(${uuidPattern})/messages`;
export const SubscriptionEnvironmentLegacyWebhookMessagesRoute = `${SubscriptionEnvironmentLegacyWebhooksRoute}/:webhookId(${uuidPattern})/messages`;
export const SubscriptionEnvironmentWorkflowsRoute = `${SubscriptionEnvironmentSettingsRoute}/workflows`;
export const SubscriptionEnvironmentWorkflowCreatingRoute = `${SubscriptionEnvironmentWorkflowsRoute}/new`;
export const SubscriptionEnvironmentWorkflowEditingRoute = `${SubscriptionEnvironmentWorkflowsRoute}/edit/:workflowId(${uuidPattern})`;
export const SubscriptionEnvironmentDefaultWorkflowRoute = `${SubscriptionEnvironmentWorkflowsRoute}/edit/${DefaultWorkflowId}`;

export type SubscriptionRouteParams = {
  readonly subscriptionId: Uuid | undefined;
};

export type EnsuredSubscriptionRouteParams = {
  readonly subscriptionId: Uuid;
};

export type SubscriptionEnvironmentSettingsRouteParams = EnsuredSubscriptionRouteParams &
  EnvironmentRouteParams;

export type SubscriptionEnvironmentWebhooksEditorRouteParams =
  SubscriptionEnvironmentSettingsRouteParams & {
    readonly webhookId: Uuid;
  };

export type SubscriptionEnvironmentRoleEditorRouteParams =
  SubscriptionEnvironmentSettingsRouteParams & {
    readonly roleId: Uuid;
  };

export type SubscriptionEnvironmentWorkflowEditingRouteParams =
  SubscriptionEnvironmentSettingsRouteParams & {
    readonly workflowId: Uuid;
  };

export type SubscriptionEnvironmentWebhookMessagesRouteParams =
  SubscriptionEnvironmentSettingsRouteParams & {
    readonly webhookId: Uuid;
  };

export type SubscriptionEnvironmentLegacyWebhookMessagesRouteParams =
  SubscriptionEnvironmentSettingsRouteParams & {
    readonly webhookId: Uuid;
  };

export type SubscriptionEnvironmentUserDetailRouteParams =
  SubscriptionEnvironmentSettingsRouteParams & {
    readonly userId: Uuid;
  };

export type SubscriptionEnvironmentInviteModalRouteParams =
  SubscriptionEnvironmentSettingsRouteParams & {
    readonly urlEncodedEmail?: string;
  };

export type SubscriptionUserDetailRouteParams = EnsuredSubscriptionRouteParams & {
  readonly userId: Uuid;
};

export type SubscriptionEnvironmentSpacesRouteParams = SubscriptionEnvironmentSettingsRouteParams;

export type SubscriptionEnvironmentPreviewConfigurationRouteParams =
  SubscriptionEnvironmentSettingsRouteParams;

export type SubscriptionEnvironmentCustomAppsConfigurationRouteParams =
  SubscriptionEnvironmentSettingsRouteParams;
export type SubscriptionEnvironmentCustomAppConfigurationEditingRouteParams =
  SubscriptionEnvironmentCustomAppsConfigurationRouteParams & {
    readonly customAppId: Uuid;
  };

/*
 * General content item management - Routes branched to the individual content item management apps
 */
export enum ContentItemsAppRouteSegment {
  Content = 'content-inventory',
  WebSpotlight = 'web-spotlight',
}

// Entry point
export const ContentItemsAppRootRoute = `${EnvironmentRoute}/:app(${ContentItemsAppRouteSegment.Content}|${ContentItemsAppRouteSegment.WebSpotlight})`;
export const ContentItemsAppEntryRoute = `${ContentItemsAppRootRoute}/:variantId(${uuidPattern})?`;

export type ContentItemsAppRootRouteParams = EnvironmentRouteParams & {
  readonly app: ContentItemsAppRouteSegment;
};

type SpecificAppRouteParams<T> = Omit<T, 'app'>;

export type VariantRouteParams = {
  readonly variantId: Uuid;
};

type OptionalVariantRouteParams = Partial<VariantRouteParams>;

export type ContentItemsAppEntryRouteParams = ContentItemsAppRootRouteParams &
  OptionalVariantRouteParams;

// Items
export const ContentItemsAppRoute = `${ContentItemsAppRootRoute}/:variantId(${uuidPattern})/:spaceId(${uuidPattern})?`;
export const ContentItemsRoute = `${ContentItemsAppRoute}/content`;

export type ContentItemsRouteParams = ContentItemsAppRootRouteParams &
  VariantRouteParams & {
    readonly spaceId: Uuid | undefined;
  };

// Item
export const ContentItemRoute = `${ContentItemsRoute}/:contentItemIds(${uuidPattern})+`;
export const ContentItemPreviewRoute = `${ContentItemsRoute}/:contentItemIds(${uuidPattern})+/preview`;
export const ContentItemPreviewWithEditorRoute = `${ContentItemPreviewRoute}/editor/:editedItemId(${uuidPattern})`;
// Routes where the item editor UI may be placed (floating editor / normal editor)
export const ContentItemEditorRoutes = [ContentItemPreviewWithEditorRoute, ContentItemRoute];

const AccessDeniedRouteSuffix = '/access-denied/:requestedAction';
export const ContentItemAccessDeniedRoute = `${ContentItemRoute}${AccessDeniedRouteSuffix}`;
export const ContentItemPreviewWithEditorAccessDeniedRoute = `${ContentItemPreviewRoute}/editor/:editedItemId(${uuidPattern})${AccessDeniedRouteSuffix}`;

export const ContentItemOpenCommentRoute = `${ContentItemRoute}/open-comment/:commentThreadId(${uuidPattern})`;
export const ContentItemOpenTaskRoute = `${ContentItemRoute}/open-task/:taskId(${uuidPattern})`;
export const ContentItemTimelineItemRoute = `${ContentItemRoute}/timeline-item`;
export const ContentItemRevisionViewerRoute = `${ContentItemTimelineItemRoute}/:timelineItemId(${uuidPattern})`;
export const ContentItemRevisionCompareRoute = `${ContentItemRevisionViewerRoute}/compare/:compareItemId(${uuidPattern})`;
export const ContentItemTimelineLatestPublishedRoute = `${ContentItemTimelineItemRoute}/latest-published`;

// router match params gives string of ids joined by '/' but buildPath expects array of ids.
export type ContentItemRouteParams<TContentItemIds extends Uuid | UuidArray> =
  ContentItemsRouteParams & {
    readonly contentItemIds: TContentItemIds;
  };

export type ContentItemPreviewRouteParams<TContentItemIds extends Uuid | UuidArray> =
  ContentItemRouteParams<TContentItemIds>;

export type ContentItemPreviewWithEditorRouteParams<TContentItemIds extends Uuid | UuidArray> =
  ContentItemPreviewRouteParams<TContentItemIds> & {
    readonly editedItemId: Uuid;
  };

export type ContentItemEditorRouteParams<TContentItemIds extends Uuid | UuidArray> =
  ContentItemRouteParams<TContentItemIds> &
    Partial<Pick<ContentItemPreviewWithEditorRouteParams<TContentItemIds>, 'editedItemId'>> & {
      // We need to differentiate these params from ContentItemRouteParams as they may overlap due to the optionality of editedItemId,
      // but we need to make sure that every location that needs them gets the full params in case they are available
      mustBeEditorRoute: never;
    };

export type ContentItemAccessDeniedRouteParams<TContentItemIds extends Uuid | UuidArray> =
  ContentItemRouteParams<TContentItemIds> & {
    readonly requestedAction: AccessDeniedToContentItemAction;
  };

export type ContentItemPreviewWithEditorAccessDeniedRouteParams<
  TContentItemIds extends Uuid | UuidArray,
> = ContentItemAccessDeniedRouteParams<TContentItemIds> & {
  readonly editedItemId: Uuid;
};

export type ContentItemOpenCommentRouteParams<TContentItemIds extends Uuid | UuidArray> =
  ContentItemRouteParams<TContentItemIds> & {
    readonly commentThreadId: Uuid;
  };

export type ContentItemOpenTaskRouteParams<TContentItemIds extends Uuid | UuidArray> =
  ContentItemRouteParams<TContentItemIds> & {
    readonly taskId: Uuid;
  };

export type ContentItemTimelineRouteParams<TContentItemIds extends Uuid | UuidArray> =
  ContentItemRouteParams<TContentItemIds>;

export type ContentItemRevisionViewerRouteParams<TContentItemIds extends Uuid | UuidArray> =
  ContentItemTimelineRouteParams<TContentItemIds> & {
    readonly timelineItemId: Uuid;
  };

export type ContentItemTimelineLatestPublishedRouteParams<
  TContentItemIds extends Uuid | UuidArray,
> = ContentItemTimelineRouteParams<TContentItemIds>;

export type ContentItemRevisionCompareRouteParams<TContentItemIds extends Uuid | UuidArray> =
  ContentItemRevisionViewerRouteParams<TContentItemIds> & {
    readonly compareItemId: Uuid;
  };

/*
 * Web Spotlight
 */
export const WebSpotlightRootRoute = `${EnvironmentRoute}/${ContentItemsAppRouteSegment.WebSpotlight}`;
export const WebSpotlightEntryRoute = `${WebSpotlightRootRoute}/:variantId(${uuidPattern})?`;
export const WebSpotlightRoute = `${WebSpotlightRootRoute}/:variantId(${uuidPattern})/:spaceId(${uuidPattern})?`;
export const WebSpotlightContentRoute = `${WebSpotlightRoute}/content`;

export type WebSpotlightEntryRouteParams = SpecificAppRouteParams<ContentItemsAppEntryRouteParams>;
export type WebSpotlightRouteParams = SpecificAppRouteParams<ContentItemsRouteParams>;

/*
 * Content app specific
 */
export const ContentAppRootRoute = `${EnvironmentRoute}/${ContentItemsAppRouteSegment.Content}`;
export const ContentAppEntryRoute = `${ContentAppRootRoute}/:variantId(${uuidPattern})?`;

export type ContentAppRootRouteParams = EnvironmentRouteParams;
export type ContentAppEntryRouteParams = SpecificAppRouteParams<ContentItemsAppEntryRouteParams>;

/*
 * Relations
 */
export const RelationsRootRoute = `${ContentAppRootRoute}/relations`;
export const RelationsEntryRoute = `${RelationsRootRoute}/:variantId(${uuidPattern})?`;
export const RelationsRoute = `${RelationsRootRoute}/:variantId(${uuidPattern})`;

export type RelationsEntryRouteParams = SpecificAppRouteParams<ContentItemsAppEntryRouteParams>;

/*
 * Assets
 */
export const AssetsRoute = `${ContentAppRootRoute}/assets`;
export const AssetListingRoute = `${AssetsRoute}/:assetFolderId(${uuidPattern})`;
export const AssetRoute = `${AssetsRoute}/asset/:assetId(${uuidPattern})`;
export const AccessDeniedToAssetRoute = `${AssetRoute}/access-denied`;

export type AssetsRouteParams = ContentAppRootRouteParams;

export type AssetListingRouteParams = AssetsRouteParams & {
  readonly assetFolderId: Uuid;
};

export type AssetRouteParams = AssetsRouteParams & {
  readonly assetId: Uuid;
};

/*
 * Warning
 */
export const WarningRoute = `${RootRoute}warning`;
export const PageNotFoundRoute = `${WarningRoute}/page-not-found`;
export const AccessDeniedRoute = `${WarningRoute}/access-denied/:requestedAppName`;
export const AccessDeniedGeneralSettingsRoute = `${WarningRoute}/access-denied/${ProjectSettingsAppNames.General}`;

export type AccessDeniedRouteParams = {
  readonly requestedAppName: string;
};

/*
 * Warning in an environment
 */
export const EnvironmentWarningRoute = `${EnvironmentRoute}/warning`;
export const EnvironmentAccessDeniedGeneralSettingsRoute = `${EnvironmentWarningRoute}/access-denied/${EnvironmentSettingsAppNames.General}`;
export const EnvironmentAccessDeniedRoute = `${EnvironmentWarningRoute}/access-denied/:requestedAppName`;
export const AccessDeniedToLanguageRoute = `${EnvironmentWarningRoute}/access-denied-to-language/:variantId`;
export const AccessLostDueToDeactivatedLanguageRoute = `${EnvironmentWarningRoute}/access-lost-due-to-deactivated-language`;
export const AccessDeniedToContentItemRoute = `${EnvironmentWarningRoute}/access-denied-to-content-item/:variantId/:requestedAction`;

export type EnvironmentAccessDeniedRouteParams = EnvironmentRouteParams & {
  readonly requestedAppName: string;
};

/*
 * Warning in a project
 */
export const ProjectWarningRoute = `${ProjectRoute}/warning`;
export const ProjectAccessDeniedGeneralSettingsRoute = `${ProjectWarningRoute}/access-denied/${ProjectSettingsAppNames.General}`;
export const ApiKeyDetailAccessDeniedRouteSegment = 'api-key-detail';
export const ProjectAccessDeniedApiKeyDetailRoute = `${ProjectWarningRoute}/access-denied/${ApiKeyDetailAccessDeniedRouteSegment}`;

export const ProjectAccessDeniedRoute = `${ProjectWarningRoute}/access-denied/:requestedAppName`;

export type ProjectAccessDeniedRouteParams = ProjectRouteParams & {
  readonly requestedAppName: string;
};

export interface AccessDeniedToLanguageRouteParams
  extends EnvironmentRouteParams,
    OptionalVariantRouteParams {}

export type AccessDeniedToContentItemRouteParams = EnvironmentRouteParams &
  VariantRouteParams & {
    readonly requestedAction: AccessDeniedToContentItemAction;
  };

/*
 * Home
 */
export const HomeRootRoute = `${EnvironmentRoute}/home`;
export const HomeRoute = `${HomeRootRoute}/:variantId(${uuidPattern})`;
export const ProjectOverviewRoute = `${HomeRoute}/project-overview`;
export const CalendarRoute = `${HomeRoute}/calendar`;
export const YourContentRoute = `${HomeRootRoute}/your-content`;
export const ContentAssignedToYouRoute = `${YourContentRoute}/assigned-to-you`;
export const ContentRecentlyEditedByYouRoute = `${YourContentRoute}/edited-by-you`;
export const YourTasksRoute = `${YourContentRoute}/your-tasks`;
export const QuickstartRoute = `${HomeRootRoute}/quickstart`;

/*
 * Mission Control
 */
export const MissionControlRootRoute = `${EnvironmentRoute}/mission-control`;
export const ContentPaceRoute = `${MissionControlRootRoute}/content-pace`;
export const MissionControlDashboardRoute = `${MissionControlRootRoute}/dashboard`;
export const ContentStatusRoute = `${MissionControlRootRoute}/content-status`;
export const MissionControlLanguageDependentRoute = `${MissionControlRootRoute}/:variantId(${uuidPattern})`;
export const MissionControlCalendarRoute = `${MissionControlLanguageDependentRoute}/calendar`;
export const MissionControlProjectOverviewRoute = `${MissionControlLanguageDependentRoute}/project-overview`;
export const MissionControlYourWorkRoute = `${MissionControlRootRoute}/your-work`;
export const MissionControlQuickstartRoute = `${MissionControlRootRoute}/quickstart`;
export const MissionControlKickstartRoute = `${MissionControlRootRoute}/kickstart`;

type MissionControlRootRouteParams = EnvironmentRouteParams;
export type MissionControlSampleProjectRouteParams = MissionControlRootRouteParams;
export type MissionControlLanguageAgnosticRouteParams = MissionControlRootRouteParams;
export type MissionControlLanguageDependentRouteParams = MissionControlRootRouteParams &
  VariantRouteParams;
