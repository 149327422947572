import Immutable from 'immutable';
import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { Cache } from '../_shared/utils/cache.ts';
import { getMilliseconds } from '../_shared/utils/dateTime/timeUtils.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import {
  getTaxonomyGroupDummyKeyByProjectId,
  getTaxonomyGroupKeyById,
  getTaxonomyGroupsKeyByProjectId,
  touchTaxonomyGroupAndItsDependencies,
} from './cacheKeys/taxonomyCacheUtils.ts';
import { ITaxonomyRepository } from './interfaces/ITaxonomyRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import { ITaxonomyGroupServerModel } from './serverModels/contentModels/TaxonomyGroupServerModel.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());
const cacheExpiration = getMilliseconds({ minutes: 5 });

export const taxonomyRepository: RepositoryWithContext<ITaxonomyRepository> = {
  async getTaxonomyGroups(
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<Array<ITaxonomyGroupServerModel>> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/taxonomy`;

    return await Cache.cache(
      getTaxonomyGroupsKeyByProjectId(requestContext.projectId),
      [getTaxonomyGroupDummyKeyByProjectId(requestContext.projectId)],
      () => restProvider.get(url, null, abortSignal, requestContext),
      cacheExpiration,
      abortSignal,
    );
  },

  async getTaxonomyGroup(
    requestContext: IRequestContext,
    taxonomyGroupId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ITaxonomyGroupServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/taxonomy/${taxonomyGroupId}`;

    return await Cache.cache(
      getTaxonomyGroupKeyById(taxonomyGroupId, requestContext.projectId),
      [],
      () => restProvider.get(url, null, abortSignal, requestContext),
      cacheExpiration,
      abortSignal,
    );
  },

  async archiveTaxonomyGroups(
    requestContext: IRequestContext,
    taxonomyGroupIds: Immutable.Set<Uuid>,
    abortSignal?: AbortSignal,
  ): Promise<any[]> {
    return await Promise.all(
      taxonomyGroupIds
        .toArray()
        .map((groupId) => this.archiveTaxonomyGroup(requestContext, groupId, abortSignal)),
    );
  },

  async archiveTaxonomyGroup(
    requestContext: IRequestContext,
    taxonomyGroupId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ITaxonomyGroupServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/taxonomy/${taxonomyGroupId}/archive`;

    try {
      return await restProvider.put(url, { archived: true }, abortSignal, requestContext);
    } finally {
      touchTaxonomyGroupAndItsDependencies(requestContext.projectId, taxonomyGroupId);
    }
  },

  async restoreTaxonomyGroups(
    requestContext: IRequestContext,
    taxonomyGroupIds: Immutable.Set<Uuid>,
    abortSignal?: AbortSignal,
  ): Promise<any[]> {
    return await Promise.all(
      taxonomyGroupIds
        .toArray()
        .map((groupId) => this.restoreTaxonomyGroup(requestContext, groupId, abortSignal)),
    );
  },

  async restoreTaxonomyGroup(
    requestContext: IRequestContext,
    taxonomyGroupId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ITaxonomyGroupServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/taxonomy/${taxonomyGroupId}/archive`;

    try {
      return await restProvider.put(url, { archived: false }, abortSignal, requestContext);
    } finally {
      touchTaxonomyGroupAndItsDependencies(requestContext.projectId, taxonomyGroupId);
    }
  },

  async createTaxonomyGroup(
    requestContext: IRequestContext,
    taxonomyGroup: ITaxonomyGroupServerModel,
    abortSignal?: AbortSignal,
  ): Promise<ITaxonomyGroupServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/taxonomy`;

    try {
      return await restProvider.post(url, taxonomyGroup, abortSignal, requestContext);
    } finally {
      touchTaxonomyGroupAndItsDependencies(requestContext.projectId, taxonomyGroup.id);
    }
  },

  async updateTaxonomyGroup(
    requestContext: IRequestContext,
    taxonomyGroup: ITaxonomyGroupServerModel,
    abortSignal?: AbortSignal,
  ): Promise<ITaxonomyGroupServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/taxonomy/${
      taxonomyGroup.id
    }`;

    try {
      return await restProvider.put(url, taxonomyGroup, abortSignal, requestContext);
    } finally {
      touchTaxonomyGroupAndItsDependencies(requestContext.projectId, taxonomyGroup.id);
    }
  },
};
