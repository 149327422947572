import { CollectionSection } from '../../../applications/itemEditor/features/NewContentItem/constants/collectionSection.ts';
import { ContentTypeSection } from '../../../applications/itemEditor/features/NewContentItem/constants/contentTypeSection.ts';

export enum ContentItemEditingEventOrigins {
  FriendlyWarning = 'friendly-warning',
  ItemDetails = 'sidebar',
  MoreActions = 'more-actions',
  NewVariantModal = 'new-variant-modal',
  NotificationBar = 'notification-bar',
  Paper = 'paper',
  Revisions = 'revisions',
  QuickActions = 'quick-actions',
  StatusBar = 'status-bar',
  Undefined = 'undefined',
}

export enum ContentItemEditingEventTypes {
  AssignAsset = 'assign-asset',
  AssignContributors = 'assign-contributors',
  AssignContributorsSelected = 'assign-contributors--selected',
  CancelScheduledPublish = 'cancel-scheduled-publish',
  CancelScheduledUnpublish = 'cancel-scheduled-unpublish',
  ChangeCollectionSelected = 'change-collection--selected',
  ChangeDueDate = 'change-due-date',
  ChangeDueDateSelected = 'change-due-date--selected',
  ChangeNoteSelected = 'change-note--selected',
  ChangeSitemapLocation = 'change-sitemap-location',
  ChangeWorkflowSelected = 'change-workflow--selected',
  ChangeWorkflowStep = 'change-workflow-step',
  ChangeWorkflowStepSelected = 'change-workflow-step--selected',
  Codename = 'codename',
  CodenameSelected = 'codename--selected',
  CollapseComponents = 'collapse-components',
  CompareVersions = 'compare-versions',
  ContentItemDetailsSidebarClosed = 'content-item-details-sidebar--closed',
  ContentItemDetailsSidebarOpened = 'content-item-details-sidebar--opened',
  ContentItemDetailsSidebarPinned = 'content-item-details-sidebar--pinned',
  ContentItemDetailsSidebarUnpinned = 'content-item-details-sidebar--unpinned',
  ContentType = 'content-type',
  ConvertComponentToContentItem = 'convert-component-to-content-item',
  CopyFromLanguage = 'copy-from-language',
  CopyFromLanguageSelected = 'copy-from-language--selected',
  CreateANewVersion = 'create-a-new-version',
  CreateComponent = 'create-component',
  CreateContentItem = 'create-content-item',
  CreateNew = 'create-new',
  DefaultPreview = 'default-preview',
  Delete = 'delete',
  DiscardNewVersion = 'discard-new-version',
  DiscussionsTabSelected = 'discussions-tab--selected',
  Duplicate = 'duplicate',
  DuplicateSelected = 'duplicate--selected',
  ExpandComponents = 'expand-components',
  HideAllComments = 'hide-all-comments',
  LinkContentItem = 'link-content-item',
  MoveToCollection = 'move-to-collection',
  MultipleSpacesPreviewSelected = 'multiple-spaces-preview--selected',
  NewVariantWorkflowSelected = 'new-variant-workflow--selected',
  NotSetupPreviewSelected = 'not-setup-preview--selected',
  Note = 'note',
  NoteSelected = 'note--selected',
  OpenResolved = 'open-resolved',
  Publish = 'publish',
  PublishSelected = 'publish--selected',
  SampleAppPreview = 'sample-app-preview',
  SampleAppPreviewSelected = 'sample-app-preview--selected',
  Schedule = 'schedule',
  ScheduleUnpublish = 'schedule-unpublish',
  ShareItemLink = 'share-item-link',
  SharePreviewLink = 'share-preview-link',
  ShareSelected = 'share--selected',
  ShowAllComments = 'show-all',
  TasksSelected = 'tasks--selected',
  Unpublish = 'unpublish',
  UsedIn = 'used-in',
}

export enum ContentItemEditingWorkflowStepChangeFeatures {
  HasNote = 'has-note',
  HasDueDate = 'has-due-date',
  HasScheduledToPublish = 'has-scheduled-to-publish',
  HasScheduledToUnpublish = 'has-scheduled-to-unpublish',
}

type ContentItemEditingChangeWorkflowSharedIntercomData = {
  readonly origin: ContentItemEditingEventOrigins;
  readonly workflowStepId: Uuid;
  readonly workflowId: Uuid;
};

type ContentItemEditingBasicChangeWorkflowActions =
  | ContentItemEditingEventTypes.Publish
  | ContentItemEditingEventTypes.Unpublish
  | ContentItemEditingEventTypes.CancelScheduledPublish
  | ContentItemEditingEventTypes.CancelScheduledUnpublish;

type ContentItemEditingBasicChangeWorkflowIntercomData =
  ContentItemEditingChangeWorkflowSharedIntercomData & {
    readonly action: ContentItemEditingBasicChangeWorkflowActions;
  };

type ContentItemEditingCustomWorkflowStepIntercomData =
  ContentItemEditingChangeWorkflowSharedIntercomData & {
    readonly action: ContentItemEditingEventTypes.ChangeWorkflowStep;
    readonly assigneesCount: number;
    readonly features: ReadonlyArray<ContentItemEditingWorkflowStepChangeFeatures>;
    readonly oldWorkflowId: Uuid;
    readonly oldWorkflowStepId: Uuid;
    readonly regularStepIndex: number;
    readonly secondsSinceLastAssignmentUpdate: number;
  };

type ContentItemEditingScheduleUnpublishIntercomData =
  ContentItemEditingChangeWorkflowSharedIntercomData & {
    readonly action: ContentItemEditingEventTypes.ScheduleUnpublish;
    readonly secondsSinceLastPublish: number;
  };

type ContentItemEditingScheduleIntercomData = ContentItemEditingChangeWorkflowSharedIntercomData & {
  readonly action: ContentItemEditingEventTypes.Schedule;
  readonly features: ReadonlyArray<ContentItemEditingWorkflowStepChangeFeatures>;
};

type ContentItemEditingChangeWorkflowIntercomData =
  | ContentItemEditingBasicChangeWorkflowIntercomData
  | ContentItemEditingCustomWorkflowStepIntercomData
  | ContentItemEditingScheduleIntercomData
  | ContentItemEditingScheduleUnpublishIntercomData;

type ContentItemEditingElementIntercomData = {
  readonly origin: ContentItemEditingEventOrigins;
  readonly contentElementType: string;
};

type ContentItemEditingCreateContentItemIntercomData = ContentItemEditingElementIntercomData & {
  readonly action: ContentItemEditingEventTypes.CreateContentItem;
  readonly allowedContentTypesCount: number;
  readonly collectionSection: CollectionSection;
  readonly contentTypeSection: ContentTypeSection;
};

type ContentItemEditingConvertComponentItemIntercomData = ContentItemEditingElementIntercomData & {
  readonly action: ContentItemEditingEventTypes.ConvertComponentToContentItem;
};

type ContentItemEditingCreateComponentItemIntercomData = ContentItemEditingElementIntercomData & {
  readonly action: ContentItemEditingEventTypes.CreateComponent;
  readonly contentTypeSection: ContentTypeSection;
};

type ContentItemEditingComponentItemIntercomData =
  | ContentItemEditingConvertComponentItemIntercomData
  | ContentItemEditingCreateComponentItemIntercomData;

type ContentItemEditingLinkExistingItemIntercomData = ContentItemEditingElementIntercomData & {
  readonly action: ContentItemEditingEventTypes.LinkContentItem;
  readonly allowedContentTypesCount: number;
  readonly selectedItemsCount: number;
};

type ContentItemEditingLinkAssetIntercomData = ContentItemEditingElementIntercomData & {
  readonly action: ContentItemEditingEventTypes.AssignAsset;
  readonly selectedItemsCount: number;
};

type ContentItemEditingAssignContributorsIntercomData = {
  readonly action: ContentItemEditingEventTypes.AssignContributors;
  readonly origin: ContentItemEditingEventOrigins;
  readonly assignees: ReadonlyArray<UserId>;
};

export type ContentItemEditingBasicActions =
  | ContentItemEditingEventTypes.AssignContributorsSelected
  | ContentItemEditingEventTypes.ChangeCollectionSelected
  | ContentItemEditingEventTypes.ChangeDueDate
  | ContentItemEditingEventTypes.ChangeDueDateSelected
  | ContentItemEditingEventTypes.ChangeNoteSelected
  | ContentItemEditingEventTypes.ChangeSitemapLocation
  | ContentItemEditingEventTypes.ChangeWorkflowSelected
  | ContentItemEditingEventTypes.ChangeWorkflowStepSelected
  | ContentItemEditingEventTypes.Codename
  | ContentItemEditingEventTypes.CodenameSelected
  | ContentItemEditingEventTypes.CollapseComponents
  | ContentItemEditingEventTypes.CompareVersions
  | ContentItemEditingEventTypes.ContentItemDetailsSidebarClosed
  | ContentItemEditingEventTypes.ContentItemDetailsSidebarOpened
  | ContentItemEditingEventTypes.ContentItemDetailsSidebarPinned
  | ContentItemEditingEventTypes.ContentItemDetailsSidebarUnpinned
  | ContentItemEditingEventTypes.ContentType
  | ContentItemEditingEventTypes.CopyFromLanguage
  | ContentItemEditingEventTypes.CopyFromLanguageSelected
  | ContentItemEditingEventTypes.CreateANewVersion
  | ContentItemEditingEventTypes.CreateNew
  | ContentItemEditingEventTypes.DefaultPreview
  | ContentItemEditingEventTypes.Delete
  | ContentItemEditingEventTypes.DiscardNewVersion
  | ContentItemEditingEventTypes.DiscussionsTabSelected
  | ContentItemEditingEventTypes.Duplicate
  | ContentItemEditingEventTypes.DuplicateSelected
  | ContentItemEditingEventTypes.ExpandComponents
  | ContentItemEditingEventTypes.HideAllComments
  | ContentItemEditingEventTypes.MoveToCollection
  | ContentItemEditingEventTypes.MultipleSpacesPreviewSelected
  | ContentItemEditingEventTypes.NewVariantWorkflowSelected
  | ContentItemEditingEventTypes.NotSetupPreviewSelected
  | ContentItemEditingEventTypes.Note
  | ContentItemEditingEventTypes.NoteSelected
  | ContentItemEditingEventTypes.OpenResolved
  | ContentItemEditingEventTypes.PublishSelected
  | ContentItemEditingEventTypes.SampleAppPreview
  | ContentItemEditingEventTypes.SampleAppPreviewSelected
  | ContentItemEditingEventTypes.ShareItemLink
  | ContentItemEditingEventTypes.SharePreviewLink
  | ContentItemEditingEventTypes.ShareSelected
  | ContentItemEditingEventTypes.ShowAllComments
  | ContentItemEditingEventTypes.TasksSelected
  | ContentItemEditingEventTypes.UsedIn;

type ContentItemEditingBasicIntercomData = {
  readonly action: ContentItemEditingBasicActions;
  readonly origin: ContentItemEditingEventOrigins;
};

export type ContentItemEditingOpenedData = {
  readonly 'is-web-spotlight-enabled': boolean;
};

export type ContentItemEditingIntercomData =
  | ContentItemEditingAssignContributorsIntercomData
  | ContentItemEditingBasicIntercomData
  | ContentItemEditingChangeWorkflowIntercomData
  | ContentItemEditingComponentItemIntercomData
  | ContentItemEditingCreateContentItemIntercomData
  | ContentItemEditingLinkAssetIntercomData
  | ContentItemEditingLinkExistingItemIntercomData;
