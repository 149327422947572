import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React from 'react';
import { RelatedItemDoesNotExistReason } from '../../../applications/itemEditor/constants/errorMessages.ts';
import { IAssetValidationResult } from '../../../applications/itemEditor/features/ContentItemEditing/components/elements/asset/AssetTile.tsx';
import { IAssetRendition } from '../../../data/models/assetRenditions/AssetRendition.ts';
import { Asset, IAsset } from '../../../data/models/assets/Asset.ts';
import { IgnoreByGrammarly } from '../IgnoreByGrammarly.tsx';
import { AssetCollection } from './AssetCollection.tsx';
import { AssetTileFolderSummary } from './AssetTileFolderSummary.tsx';
import { AssetTileTechnicalDetails } from './AssetTileTechnicalDetails.tsx';
import { AssetTitle } from './AssetTitle.tsx';

type Props = {
  readonly asset: IAsset;
  readonly canViewAsset?: boolean;
  readonly collectionName?: string | null;
  readonly folderName?: string | null;
  readonly folderPathTooltip?: string | null;
  readonly titleId?: HTMLDivElement['id'];
  readonly isHeightLimitationSet?: boolean;
  readonly isWidthLimitationSet?: boolean;
  readonly rendition?: IAssetRendition;
  readonly renditionId?: Uuid;
  readonly searchPhrase?: string;
  readonly validationResult: IAssetValidationResult;
};

export const AssetTileSummary: React.FC<Props> = ({
  asset,
  canViewAsset = true,
  collectionName,
  folderName,
  folderPathTooltip,
  titleId,
  isHeightLimitationSet,
  isWidthLimitationSet,
  rendition,
  renditionId,
  searchPhrase,
  validationResult,
}) => {
  if (!Asset.exists(asset)) {
    return (
      <div className="asset-thumbnail__name">
        <Tooltip placement="bottom" tooltipText={RelatedItemDoesNotExistReason}>
          <IgnoreByGrammarly text="Asset doesn’t exist" />
        </Tooltip>
      </div>
    );
  }

  if (!canViewAsset && !asset._uploading && !asset._failed) {
    return (
      <div className="asset-thumbnail__name">
        <IgnoreByGrammarly text="You don’t have permission to view this asset." />
      </div>
    );
  }

  return (
    <div className="asset-thumbnail__summary">
      <AssetTitle
        asset={asset}
        isAssetFileTypeValid={validationResult.isAssetFileTypeValid}
        searchPhrase={searchPhrase}
        id={titleId}
      />

      <AssetTileTechnicalDetails
        asset={asset}
        isHeightLimitationSet={isHeightLimitationSet}
        isWidthLimitationSet={isWidthLimitationSet}
        rendition={rendition}
        renditionId={renditionId}
        validationResult={validationResult}
      />
      <AssetCollection collectionName={collectionName} />
      {folderName && (
        <AssetTileFolderSummary name={folderName} pathTooltip={folderPathTooltip ?? undefined} />
      )}
    </div>
  );
};
