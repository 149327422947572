import React from 'react';
import { DataTable } from '../../../../../_shared/components/DataTable/DataTable.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WidgetDetailTableStateContent } from '../../shared/components/WidgetDetailTableStateContent.tsx';
import { WidgetListingState } from '../../shared/types/WidgetListingState.type.ts';

export type UnchangedPublishedItemsWidgetDetailProps = React.PropsWithChildren<{
  readonly ariaLabelledBy: string;
  readonly state: WidgetListingState;
}>;

export const UnchangedPublishedItemsWidgetDetail: React.FC<
  UnchangedPublishedItemsWidgetDetailProps
> = ({ ariaLabelledBy, children, state }) => {
  return (
    <WidgetDetailTableStateContent state={state}>
      <DataTable
        ariaLabelledBy={ariaLabelledBy}
        dataUiCollectionName={DataUiCollection.ContentItems}
        header={<DataTableHeadRow columns={tableHeadColumns} />}
      >
        {children}
      </DataTable>
    </WidgetDetailTableStateContent>
  );
};

UnchangedPublishedItemsWidgetDetail.displayName = 'UnchangedPublishedItemsWidgetDetail';

const tableHeadColumns: ReadonlyArray<Column> = [
  {
    columnName: 'Name',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-7',
  },
  {
    columnName: 'Variant',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-3',
  },
  {
    columnName: 'Collection',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-2',
  },
  {
    columnName: 'Assigned',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-2',
  },
  {
    columnName: 'Published',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-3',
  },
  {
    columnName: 'Days since published',
    orderBy: OrderByDirection.Descending,
    className: 'data-table__column--growing-3',
  },
];
