import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { ITypeRuleSetting } from '../../../../../data/models/roles/IRoleSettings.ts';
import {
  IRoleWithSettings,
  defaultRoleWithSettings,
} from '../../../../../data/models/roles/IRoleWithSettings.ts';
import {
  Role_Create_Finished,
  Role_CreatorInit_Finished,
  Role_CreatorInit_Started,
  Role_Editing_CanRuleGroupAdded,
  Role_Editing_CanRuleGroupRemoved,
  Role_Editing_CanRuleTypeAdded,
  Role_Editing_CanRuleTypeRemoved,
  Role_Editing_CannotRuleGroupAdded,
  Role_Editing_CannotRuleGroupRemoved,
  Role_Editing_CannotRuleTypeAdded,
  Role_Editing_CannotRuleTypeRemoved,
  Role_Editing_CapabilitiesChecked,
  Role_Editing_CapabilitiesUnchecked,
  Role_Editing_NameChanged,
  Role_Editing_RoleSettingsChanged,
  Role_EditorInit_Finished,
  Role_EditorInit_Started,
  Role_Update_Finished,
  Roles_ListingInit_Started,
} from '../../constants/rolesActionTypes.ts';
import { RoleEditorStatus } from '../../models/RoleEditorStatus.ts';
import {
  getEditedRoleApplicationModel,
  withoutUnnecessaryDeletedTypesAndGroups,
} from '../../utils/editedRoleUtils.ts';
import { editedCapabilities } from './editedCapabilities.ts';

export const editedRole = (
  state: IRoleWithSettings = defaultRoleWithSettings,
  action: Action,
): IRoleWithSettings => {
  switch (action.type) {
    case Roles_ListingInit_Started:
    case Role_CreatorInit_Started:
      return defaultRoleWithSettings;

    case Role_EditorInit_Started:
      return action.payload.status === RoleEditorStatus.FreshlySaved
        ? state
        : defaultRoleWithSettings;

    case Role_CreatorInit_Finished:
    case Role_EditorInit_Finished:
    case Role_Create_Finished:
    case Role_Update_Finished: {
      const { role, allTypes } = action.payload;

      return getEditedRoleApplicationModel(role, allTypes);
    }

    case Role_Editing_NameChanged:
      return {
        ...state,
        name: action.payload.name,
      };

    case Role_Editing_CapabilitiesChecked:
    case Role_Editing_CapabilitiesUnchecked:
      return {
        ...state,
        capabilities: editedCapabilities(state.capabilities, action),
      };

    case Role_Editing_RoleSettingsChanged:
      return {
        ...state,
        settings: action.payload.roleSettings,
      };

    case Role_Editing_CanRuleTypeAdded:
    case Role_Editing_CanRuleTypeRemoved:
    case Role_Editing_CanRuleGroupAdded:
    case Role_Editing_CanRuleGroupRemoved: {
      const { allTypes, typeId, ruleIndex } = action.payload;

      const newCanRules = Collection.replaceWith(
        state.settings.canRules,
        ruleIndex,
        (oldCanRule) => {
          const editedTypeRuleSettings = editedTypeSettings(
            oldCanRule.types,
            action,
            typeId,
            allTypes,
          );
          const typeRuleSettings = withoutUnnecessaryDeletedTypesAndGroups(
            editedTypeRuleSettings,
            allTypes,
          );
          return { ...oldCanRule, types: typeRuleSettings };
        },
      );

      return {
        ...state,
        settings: {
          cannotRules: state.settings.cannotRules,
          canRules: newCanRules,
        },
      };
    }

    case Role_Editing_CannotRuleTypeAdded:
    case Role_Editing_CannotRuleTypeRemoved:
    case Role_Editing_CannotRuleGroupAdded:
    case Role_Editing_CannotRuleGroupRemoved: {
      const { allTypes, typeId, ruleIndex } = action.payload;

      const newCannotRules = Collection.replaceWith(
        state.settings.cannotRules,
        ruleIndex,
        (oldCannotRules) => {
          const editedTypeRuleSettings = editedTypeSettings(
            oldCannotRules.types,
            action,
            typeId,
            allTypes,
          );
          const typeRuleSettings = withoutUnnecessaryDeletedTypesAndGroups(
            editedTypeRuleSettings,
            allTypes,
          );
          return { ...oldCannotRules, types: typeRuleSettings };
        },
      );

      return {
        ...state,
        settings: {
          canRules: state.settings.canRules,
          cannotRules: newCannotRules,
        },
      };
    }

    default:
      return state;
  }
};

export function editedTypeSettings(
  state: ReadonlyArray<ITypeRuleSetting>,
  action: Action,
  affectedTypeId: Uuid,
  allTypes: Immutable.Map<Uuid, IContentType>,
): ReadonlyArray<ITypeRuleSetting> {
  const affectedType = allTypes.get(affectedTypeId);
  const existingTypeSetting = state.find((typeSetting) => typeSetting.typeId === affectedTypeId);

  switch (action.type) {
    case Role_Editing_CanRuleTypeAdded:
    case Role_Editing_CannotRuleTypeAdded: {
      const typeIsAlreadyIncluded = state.some(
        (typeSetting) => typeSetting.typeId === affectedTypeId,
      );

      if (typeIsAlreadyIncluded || !affectedType) {
        return state;
      }

      return [
        ...state,
        {
          typeId: affectedTypeId,
          contentGroupIds: Immutable.Set<Uuid>(affectedType.contentGroups.map((group) => group.id)),
        },
      ];
    }

    case Role_Editing_CanRuleTypeRemoved:
    case Role_Editing_CannotRuleTypeRemoved: {
      return state.filter((typeSetting) => typeSetting.typeId !== action.payload.typeId);
    }

    case Role_Editing_CanRuleGroupAdded:
    case Role_Editing_CannotRuleGroupAdded: {
      const { typeId, groupId } = action.payload;

      if (!affectedType) {
        return state;
      }

      if (!existingTypeSetting) {
        return [
          ...state,
          {
            typeId,
            contentGroupIds: Immutable.Set([groupId]),
          },
        ];
      }

      return state.map((typeSetting) =>
        typeSetting.typeId !== typeId
          ? typeSetting
          : {
              typeId: typeSetting.typeId,
              contentGroupIds: typeSetting.contentGroupIds.add(groupId).toSet(),
            },
      );
    }

    case Role_Editing_CanRuleGroupRemoved:
    case Role_Editing_CannotRuleGroupRemoved: {
      const { typeId, groupId } = action.payload;

      if (!existingTypeSetting) {
        return state;
      }

      const isTheOnlySelectedGroup = existingTypeSetting.contentGroupIds.every(
        (alreadySelectedGroupId: Uuid) => alreadySelectedGroupId === groupId,
      );

      if (isTheOnlySelectedGroup) {
        return state.filter((typeSetting) => typeSetting.typeId !== typeId);
      }

      return state.map((typeSetting) =>
        typeSetting.typeId !== typeId
          ? typeSetting
          : {
              typeId: typeSetting.typeId,
              contentGroupIds: typeSetting.contentGroupIds.remove(groupId),
            },
      );
    }
    default:
      throw new Error(
        `editedTypeSettings was called with action ${action.type} which is not handled`,
      );
  }
}
