import { Menu } from '@kontent-ai/component-library/Menu';
import React from 'react';
import { ContentItemEditingEventOrigins } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { EditingAction } from '../../../models/EditingAction.ts';
import { ActionsMenuItemToActionMapper } from './ActionsMenuItemToActionMapper.tsx';

type ActionsMenuGroupProps = {
  readonly allGroupActions: readonly EditingAction[];
  readonly availableActions: readonly EditingAction[];
  readonly isFirst?: boolean;
  readonly title?: string;
};

const ActionsMenuItems: React.FC<{ actionsToRender: EditingAction[] }> = (props) =>
  props.actionsToRender.map((action) => (
    <ActionsMenuItemToActionMapper
      action={action}
      actionOrigin={ContentItemEditingEventOrigins.MoreActions}
      key={action}
    />
  ));

export const ActionsMenuGroup: React.FC<ActionsMenuGroupProps> = ({
  allGroupActions,
  availableActions,
  title,
  isFirst,
}) => {
  const actionsToRender = allGroupActions.filter((action) => availableActions.includes(action));

  if (!actionsToRender.length) {
    return null;
  }

  return title ? (
    <Menu.Section label={title} isFirst={isFirst}>
      <ActionsMenuItems actionsToRender={actionsToRender} />
    </Menu.Section>
  ) : (
    <ActionsMenuItems actionsToRender={actionsToRender} />
  );
};
