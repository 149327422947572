import { assert, Collection, alphabetically, groupBy } from '@kontent-ai/utils';
import { IContentItemVariantReference } from '../../../applications/itemEditor/models/contentItem/ContentItemVariantReference.ts';

export interface UsageItemReference {
  primary: IContentItemVariantReference;
  secondary: IContentItemVariantReference | null;
}

const createUsageItemReference = (
  refs: ReadonlyArray<IContentItemVariantReference>,
): UsageItemReference => {
  const currentVersion = refs.find((ref) => !ref.isPublishedVersion);
  const publishedVersion = refs.find((ref) => ref.isPublishedVersion);

  const primaryVersion = currentVersion || publishedVersion;
  assert(primaryVersion, () => 'There must be at least some reference in UsageItemReference');

  return currentVersion && publishedVersion
    ? {
        primary: currentVersion,
        secondary: publishedVersion,
      }
    : {
        primary: primaryVersion,
        secondary: null,
      };
};

export const createUsages = (
  references: ReadonlyArray<IContentItemVariantReference>,
): ReadonlyArray<UsageItemReference> =>
  Collection.getValues(groupBy(references, (ref) => `${ref.id.itemId};${ref.id.variantId}`)).map(
    (group) => createUsageItemReference(group),
  );

export const compareUsages = (a: UsageItemReference, b: UsageItemReference): number =>
  alphabetically(a.primary.name, b.primary.name);
