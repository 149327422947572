import { memoize } from '@kontent-ai/memoization';
import { areEmailsSame } from '../../../../_shared/utils/emailUtils.ts';
import { Invitation } from '../reducers/IUsersAppState.type.ts';

export const createIsDuplicateInvitation =
  (invitations: ReadonlyArray<Invitation>) => (potentialDuplicate: Invitation) => {
    const firstOccurrence = invitations.find((i: Invitation) =>
      areEmailsSame(i.email, potentialDuplicate.email),
    );

    return firstOccurrence !== potentialDuplicate;
  };

export const findDuplicateInvitations = memoize.maxOne(
  (invitations: ReadonlyArray<Invitation>): ReadonlySet<Invitation> =>
    new Set(invitations.filter(createIsDuplicateInvitation(invitations))),
);
