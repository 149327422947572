import { memoize } from '@kontent-ai/memoization';
import { isArray, notNull } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { isAllowedTypeForCapability } from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { joinWithOxfordComma } from '../../../../../_shared/utils/stringUtils.ts';
import {
  IContentType,
  createDeletedContentType,
} from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IRoleSettings } from '../../../../../data/models/roles/IRoleSettings.ts';

export function getAllowedTypes(
  allowedTypesIds: ReadonlyArray<Uuid>,
  contentTypes: Immutable.Map<Uuid, IContentType>,
  includeDeleted: boolean = false,
): Immutable.Set<IContentType> {
  return getAllowedTypesMemoized(allowedTypesIds, contentTypes, includeDeleted);
}

const getAllowedTypesMemoized = memoize.weak(
  (
    allowedTypesIds: ReadonlyArray<Uuid>,
    contentTypes: Immutable.Map<Uuid, IContentType>,
    includeDeleted: boolean = false,
  ): Immutable.Set<IContentType> => {
    const allowedContentTypes = allowedTypesIds
      .map((id) => contentTypes.get(id) ?? (includeDeleted ? createDeletedContentType(id) : null))
      .filter(notNull);

    return Immutable.Set(allowedContentTypes);
  },
);

const getAllowedContentTypesMessage = (
  messagePrefix: string,
  allowedContentTypes: ReadonlyArray<IContentType>,
): string | undefined =>
  allowedContentTypes.length
    ? `${messagePrefix} ${joinWithOxfordComma(allowedContentTypes.map((t) => t.name))}`
    : undefined;

export const getAllowedTypesMessage = (
  allowedContentTypes: ReadonlyArray<IContentType>,
): string | undefined =>
  getAllowedContentTypesMessage(
    'Inserted content items and components limited to',
    allowedContentTypes,
  );

export const getAllowedLinkedItemTypesMessage = (
  allowedContentTypes: ReadonlyArray<IContentType>,
): string | undefined =>
  getAllowedContentTypesMessage('Inserted content items limited to', allowedContentTypes);

export const getAllowedItemLinkTypesMessage = (
  allowedItemLinkTypes: ReadonlyArray<IContentType>,
): string | undefined =>
  getAllowedContentTypesMessage('Content item links limited to', allowedItemLinkTypes);

export const getUsableContentTypes = memoize.weak(
  (
    allowedTypes: ReadonlyArray<IContentType> | Immutable.Set<IContentType>,
    allContentTypes: Immutable.Map<Uuid, IContentType>,
    roleSettings: IRoleSettings,
  ): Immutable.Set<IContentType> => {
    const _allowedTypes = isArray(allowedTypes) ? allowedTypes : allowedTypes.toArray();
    const contentTypes = _allowedTypes.length ? _allowedTypes : allContentTypes.toArray();

    return Immutable.Set(
      contentTypes.filter((contentType) =>
        isAllowedTypeForCapability(roleSettings, Capability.CreateContent)(contentType.id),
      ),
    );
  },
);
