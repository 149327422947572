import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { filterInvitationsCountedInSubscriptionUserLimits } from '../../../../_shared/utils/subscriptionUsageUtils.ts';
import { IExistingUserToInvite } from '../../../../data/models/users/ExistingUserToInvite.ts';
import { Invitation } from '../reducers/IUsersAppState.type.ts';
import { createIsNewInvitation } from '../selectors/createIsNewInvitation.ts';

export const getNewEmailsCountedInSubscriptionLimitsSelector = ({ usersApp }: IStore) =>
  getNewEmailsCountedInSubscriptionLimits(
    usersApp.userInvitation.invitations,
    usersApp.userInvitation.existingUsersByEmail,
  );

const getNewEmailsCountedInSubscriptionLimits = memoize.maxOne(
  (
    invitations: ReadonlyArray<Invitation>,
    existingUsers: Immutable.Map<Uuid, IExistingUserToInvite>,
  ): ReadonlyArray<string> =>
    filterInvitationsCountedInSubscriptionUserLimits(invitations)
      .filter(createIsNewInvitation(existingUsers))
      .map((i) => i.email),
);
