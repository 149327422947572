import { UnexpectedTypeException } from '@kontent-ai/errors';
import { memoize } from '@kontent-ai/memoization';
import { Collection, alphabetically, createCompare, isArray, naturally } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { isAllowedTypeForCapability } from '../../../../_shared/utils/permissions/activeCapabilities.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { isImmutableCollection } from '../../../../_shared/utils/typeguards.ts';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IRoleSettings } from '../../../../data/models/roles/IRoleSettings.ts';
import { IContentGroup } from '../../../contentInventory/content/models/contentTypeElements/types/ContentGroup.ts';
import { IBaseTypeElementData } from '../models/elements/types/TypeElementData.ts';
import { TypeElementValidationResult } from './typeElementValidators/types/TypeElementValidationResult.type.ts';
import { getTypeElementsInContentGroup, withDefaultNameIfNotSet } from './typeElementsUtils.ts';

export function getSortedContentTypesByName<TContentType extends Readonly<{ name: string }>>(
  types: ReadonlyArray<TContentType>,
): ReadonlyArray<TContentType>;
export function getSortedContentTypesByName<TContentType extends Readonly<{ name: string }>>(
  types: Immutable.Collection<unknown, TContentType>,
): ReadonlyArray<TContentType>;
export function getSortedContentTypesByName<TContentType extends Readonly<{ name: string }>>(
  types: ReadonlyArray<TContentType> | Immutable.Collection<unknown, TContentType>,
): ReadonlyArray<TContentType> {
  return getSortedContentTypesByNameMemoized(types);
}

const getSortedContentTypesByNameMemoized = memoize.weak(
  <TContentType extends Readonly<{ name: string }>>(
    types: ReadonlyArray<TContentType> | Immutable.Collection<unknown, TContentType>,
  ): ReadonlyArray<TContentType> => {
    if (isArray(types)) {
      return types.toSorted(
        createCompare({
          compare: naturally,
          select: (t) => t.name,
        }),
      );
    }

    if (isImmutableCollection(types)) {
      return types
        .sort(
          createCompare({
            compare: naturally,
            select: (t) => t.name,
          }),
        )
        .toArray();
    }

    throw UnexpectedTypeException('Array | Collection', types);
  },
);

export const filterContentTypesByCapability = (
  types: ReadonlyArray<IContentType>,
  roleSettings: IRoleSettings,
  capability: Capability,
): ReadonlyArray<IContentType> =>
  filterContentTypesByCapabilityMemoized(types, roleSettings, capability);

const filterContentTypesByCapabilityMemoized = memoize.weak(
  (
    types: ReadonlyArray<IContentType>,
    roleSettings: IRoleSettings,
    capability: Capability,
  ): ReadonlyArray<IContentType> =>
    types.filter((type) => isAllowedTypeForCapability(roleSettings, capability)(type.id)),
);

export const getSortedElementsByName = memoize.weak(
  (elements: ReadonlyArray<IBaseTypeElementData>): ReadonlyArray<IBaseTypeElementData> =>
    elements
      .map((element) => withDefaultNameIfNotSet(element))
      .sort((a, b) => alphabetically(a.name, b.name)),
);

export const getElementsSortedByContentGroups = (
  elements: ReadonlyArray<IBaseTypeElementData>,
  contentGroups: ReadonlyArray<IContentGroup>,
): ReadonlyArray<IBaseTypeElementData> =>
  elements
    .map((element: IBaseTypeElementData, index: number) => ({
      element,
      contentGroupIndex: contentGroups.findIndex(
        (group: IContentGroup) => group.id === element.contentGroupId,
      ),
      elementIndex: index,
    }))
    .sort((valueA, valueB) => {
      const groupCompare = valueA.contentGroupIndex - valueB.contentGroupIndex;
      const elementCompare = valueA.elementIndex - valueB.elementIndex;
      return groupCompare || elementCompare;
    })
    .map(({ element }) => element);

export const getUpdatedElements = (
  typeElements: ReadonlyArray<IBaseTypeElementData>,
  updatedElement: IBaseTypeElementData,
): ReadonlyArray<IBaseTypeElementData> =>
  typeElements.map((element) =>
    element && element.elementId === updatedElement.elementId ? updatedElement : element,
  );

export const containsInvalidElements = (
  typeElements: ReadonlyArray<IBaseTypeElementData>,
  validationResults: ReadonlyMap<UuidPath, TypeElementValidationResult>,
): boolean => {
  const elementsId = typeElements.map((element: IBaseTypeElementData) => element.elementId);
  const invalidElementsId = Array.from(validationResults)
    .filter(([, result]) => result && result.errorMessages.length > 0)
    .map(([elementId]) => elementId);

  return elementsId.some((elementId) => invalidElementsId.includes(elementId));
};

export const getClosestPreviousElement = (
  typeElements: ReadonlyArray<IBaseTypeElementData>,
  contentGroups: ReadonlyArray<IContentGroup>,
  currentContentGroupId: Uuid | null,
): IBaseTypeElementData | null => {
  if (!currentContentGroupId) {
    return Collection.getLast(typeElements);
  }

  const currentContentGroupIndex = contentGroups.findIndex(
    (group: IContentGroup) => group.id === currentContentGroupId,
  );
  if (currentContentGroupIndex < 0) {
    return null;
  }

  return (
    contentGroups
      .slice(0, currentContentGroupIndex + 1)
      .reverse()
      .map((group) => Collection.getLast(getTypeElementsInContentGroup(typeElements, group.id)))
      .find((g) => g !== null) ?? null
  );
};

export const sendTypeElementsToContentGroup = (
  selectedTypeElementIds: Array<Uuid>,
  contentGroupId: Uuid,
  typeElements: ReadonlyArray<IBaseTypeElementData>,
  contentGroups: ReadonlyArray<IContentGroup>,
): ReadonlyArray<IBaseTypeElementData> => {
  const elementsAfterGroupChange = typeElements.map((typeElement: IBaseTypeElementData) =>
    selectedTypeElementIds.includes(typeElement.elementId)
      ? {
          ...typeElement,
          contentGroupId,
        }
      : typeElement,
  );
  const sorted = getElementsSortedByContentGroups(elementsAfterGroupChange, contentGroups);
  return sorted;
};
