import { InvariantException } from '@kontent-ai/errors';
import { Collection } from '@kontent-ai/utils';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import {
  IElementPathItem,
  traverseFindElement,
} from '../../../../../richText/plugins/contentComponents/api/traverseComponentUtils.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { canViewContentElement } from '../../../../stores/utils/contentItemElementsUtils.ts';
import { getValidationSelectorId } from '../../../../utils/itemElementWarningCheckers/utils/getValidationSelectorId.ts';
import { ContentGroupSelectionReason } from '../../constants/ContentGroupSelectionReason.ts';
import { getIncompleteElementIdPathsAndTheirTopLevelElementId } from '../../utils/itemValidationUtils.ts';

interface IDependencies {
  readonly expandContentComponentsWithIncompleteElements: () => ThunkFunction;
  readonly selectContentGroupsByElementPath: (
    reason: ContentGroupSelectionReason,
    elementPaths: ReadonlyArray<IElementPathItem>,
  ) => ThunkFunction;
}

export const createShowIncompleteElementsInContentGroupAction =
  (deps: IDependencies) => (): ThunkFunction => (dispatch, getState) => {
    const {
      editedContentItem,
      editedContentItemVariantElements,
      itemValidationErrors,
      itemValidationWarnings,
    } = getState().contentApp;

    const incompleteElementIds = getIncompleteElementIdPathsAndTheirTopLevelElementId(
      itemValidationErrors,
      itemValidationWarnings,
    );
    if (!incompleteElementIds.size) {
      return;
    }
    if (!editedContentItem) {
      throw InvariantException(
        'createShowIncompleteElementsInContentGroupAction.ts action: "editedContentItem" is not loaded',
      );
    }

    const findFirstIncompleteVisibleElementResult = traverseFindElement(
      editedContentItem,
      editedContentItemVariantElements,
      (element: ICompiledContentItemElementData, currentPath: ReadonlyArray<IElementPathItem>) => {
        const componentId =
          currentPath.length > 1 ? Collection.getLast(currentPath)?.itemId : undefined;

        return Collection.getEntries(incompleteElementIds).some(
          ([incompleteElementIdPath, topLevelElementId]) =>
            incompleteElementIdPath === getValidationSelectorId(element.elementId, componentId) &&
            canViewContentElement(topLevelElementId, getState()),
        );
      },
    );

    if (findFirstIncompleteVisibleElementResult.found) {
      dispatch(
        deps.selectContentGroupsByElementPath(
          ContentGroupSelectionReason.FocusIncompleteElement,
          findFirstIncompleteVisibleElementResult.path,
        ),
      );
      dispatch(deps.expandContentComponentsWithIncompleteElements());
    }
  };
