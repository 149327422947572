import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { EditorState } from 'draft-js';
import { ChangeEvent, FormEvent, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { LabelFor } from '../../../../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import { isEmptyOrWhitespace } from '../../../../../../../_shared/utils/stringUtils.ts';
import {
  AutoFocusBehavior,
  IFocusable,
} from '../../../../../../richText/plugins/behavior/FocusPlugin.tsx';
import {
  DebouncedChanges,
  EditorChangeCallback,
} from '../../../../../../richText/plugins/behavior/OnChangePlugin.tsx';
import { emptyEditorState } from '../../../../../../richText/utils/general/editorEmptyValues.ts';
import {
  CommentThreadItemType,
  ICommentThreadItemContentModel,
} from '../../../../../models/comments/CommentThreadItem.ts';
import { CommentInput } from '../../../containers/comments/input/CommentInput.tsx';
import { SuggestionInput } from '../input/SuggestionInput.tsx';
import { NewCommentThreadItemActionsBar } from './NewCommentThreadItemActionsBar.tsx';

type Props = {
  readonly autoFocusBehavior?: AutoFocusBehavior;
  readonly elementSegment: string | null;
  readonly inputValue: string;
  readonly isEditing: boolean;
  readonly isSubmitting: boolean;
  readonly onBlur?: (isCommentPending: boolean) => void;
  readonly onCancel: () => void;
  readonly onSubmit: (
    type: CommentThreadItemType,
    content: ICommentThreadItemContentModel,
  ) => Promise<void>;
  readonly type: CommentThreadItemType;
};

export type NewCommentThreadItemHandle = {
  readonly isTextValid: () => boolean;
};

export const NewCommentThreadItem = forwardRef<NewCommentThreadItemHandle, Props>((props, ref) => {
  const lastKnownHasFocus = useRef<boolean | null>(null);

  const isItemSuggestion = props.type === CommentThreadItemType.Suggestion;
  const [commentContent, setCommentContent] = useState<EditorState>(emptyEditorState);
  const [suggestionText, setSuggestionText] = useState(() =>
    isItemSuggestion ? (props.elementSegment ?? '') : '',
  );

  const focusableRef = useRef<IFocusable>(null);
  const debouncedChangesRef = useRef<DebouncedChanges>(null);
  const suggestionRef = useRef<SuggestionInput>(null);

  const isTextValid = (): boolean =>
    !isEmptyOrWhitespace(
      isItemSuggestion ? suggestionText : commentContent.getCurrentContent().getPlainText(),
    );

  useImperativeHandle(ref, () => ({
    isTextValid,
  }));

  const isSubmitButtonDisabled = !isTextValid() || props.isSubmitting;

  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.stopPropagation();
    event.preventDefault();

    handleSubmitThreadItem();
  };

  const onCommentChange: EditorChangeCallback = (editorState) => {
    setCommentContent(editorState);
    return Promise.resolve();
  };

  const onSuggestionChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    setSuggestionText(event.target.value);
  };

  const onCommentSelectionChange: EditorChangeCallback = (editorState) => {
    const hasFocus = editorState.getSelection().getHasFocus();
    if (lastKnownHasFocus.current !== hasFocus) {
      lastKnownHasFocus.current = hasFocus;
      if (!hasFocus) {
        onBlur();
      }
    }
    return Promise.resolve();
  };

  const handleSubmitThreadItem = (): void => {
    if (isTextValid()) {
      submitThreadItem();
    }
  };

  const submitThreadItem = async (): Promise<void> => {
    await debouncedChangesRef.current?.propagatePendingContentChanges();
    const content = isItemSuggestion
      ? {
          type: props.type,
          suggestedText: suggestionText,
        }
      : {
          type: props.type,
          content: commentContent.getCurrentContent(),
        };

    await props.onSubmit(props.type, content);
  };

  const cancelEditing = (): void => {
    props.onCancel();
  };

  const onBlur = (): void => {
    props.onBlur?.(isTextValid() && props.type === CommentThreadItemType.Comment);
  };

  const hotkeysProps = useHotkeys({
    [ShortcutsConfig.ControlEnter]: handleSubmitThreadItem,
  });

  return (
    <form className="form comment-editor__form" onSubmit={onSubmit} {...hotkeysProps}>
      <LabelFor
        isHidden
        target={(id) =>
          isItemSuggestion ? (
            <SuggestionInput
              ref={suggestionRef}
              autoFocus
              inputId={id}
              inputValue={suggestionText}
              onChange={onSuggestionChange}
            />
          ) : (
            <CommentInput
              autoFocus
              autoFocusBehavior={props.autoFocusBehavior}
              debouncedChangesRef={debouncedChangesRef}
              disabled={props.isSubmitting}
              editorState={commentContent}
              focusableRef={focusableRef}
              onContentChange={onCommentChange}
              onSelectionChange={onCommentSelectionChange}
            />
          )
        }
      >
        {isItemSuggestion ? 'Suggest changes' : 'Comment'}
      </LabelFor>
      <NewCommentThreadItemActionsBar
        isSubmitting={props.isSubmitting}
        onCancelEditing={cancelEditing}
        isSubmitButtonDisabled={isSubmitButtonDisabled}
        isSuggestion={isItemSuggestion}
      />
    </form>
  );
});

NewCommentThreadItem.displayName = 'NewCommentThreadItem';
