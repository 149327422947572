import { usePrevious } from '@kontent-ai/hooks';
import React, { useEffect } from 'react';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { AiAssistantId } from '../../../../../../paperModels/aiReview/constants/commentsConstants.ts';
import { IInlineCommentThread } from '../../../../models/comments/CommentThreads.ts';
import { showCommentThreadsOnRemovedContentOnboarding } from '../../actions/thunkContentItemEditingActions.ts';
import { getCommentsOnRemovedContent } from '../../selectors/inlineCommentSelectors.ts';

const isCommentThreadByAiAssistant = (thread: IInlineCommentThread): boolean =>
  thread.threadItems[0]?.createdBy === AiAssistantId;

export const CommentThreadsOnRemovedContentOnboarding: React.FC = () => {
  const dispatch = useDispatch();

  const commentThreadsOnRemovedContentOnboardingShown = useSelector(
    (state) => !!state.sharedApp.userProperties.commentThreadsOnRemovedContentOnboardingShownAt,
  );
  const commentThreadsOnRemovedContent = useSelector((state) =>
    commentThreadsOnRemovedContentOnboardingShown
      ? 0
      : getCommentsOnRemovedContent(state).filter((thread) => !isCommentThreadByAiAssistant(thread))
          .length,
  );
  const previousCommentThreadsOnRemovedContent = usePrevious(commentThreadsOnRemovedContent);

  useEffect(() => {
    if (commentThreadsOnRemovedContent > previousCommentThreadsOnRemovedContent) {
      dispatch(showCommentThreadsOnRemovedContentOnboarding());
    }
  }, [commentThreadsOnRemovedContent, previousCommentThreadsOnRemovedContent]);

  return null;
};

CommentThreadsOnRemovedContentOnboarding.displayName = 'CommentThreadsOnRemovedContentOnboarding';
