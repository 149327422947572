import { ILinkedItemsElementServerModel } from '../../../../repositories/serverModels/elementServerModels.type.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  EmptyLinkedItemElement,
  ILinkedItemsElement,
} from '../../models/contentItemElements/modularItems/ILinkedItemsElement.ts';
import {
  ISimpleItemElementDomainModelConverter,
  ISimpleItemElementServerModelConverter,
} from './types/IItemElementDataConverters.type.ts';

export const linkedItemsElementDomainModelConverter: ISimpleItemElementDomainModelConverter<
  ILinkedItemsElementServerModel,
  ILinkedItemsElement
> = (serverElement) => {
  const { elementId, value, lastModifiedBy, lastModifiedAt } = serverElement;

  const contentItemReferences: ReadonlyArray<Uuid> =
    value?.map((serverReference) => serverReference._id) || [];

  return {
    ...EmptyLinkedItemElement,
    elementId,
    value: contentItemReferences,
    lastModifiedBy: lastModifiedBy ? lastModifiedBy : null,
    lastModifiedAt: lastModifiedAt ? lastModifiedAt : null,
  };
};

export const linkedItemsElementServerModelConverter: ISimpleItemElementServerModelConverter<
  ILinkedItemsElement,
  ILinkedItemsElementServerModel
> = (element) => {
  const { elementId, value, lastModifiedBy, lastModifiedAt } = element;

  const serverElement: ILinkedItemsElementServerModel = {
    elementId,
    type: ElementType.LinkedItems,
    value: value.map((id: Uuid) => ({ _id: id })),
    lastModifiedBy,
    lastModifiedAt,
  };

  return serverElement;
};
