import { Icons } from '@kontent-ai/component-library/Icons';
import { NavigationBarQuinaryMenuButton } from '@kontent-ai/component-library/NavigationBar';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ITaxonomyTerm } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { AssetFoldersBreadcrumbsTooltip } from './AssetFoldersBreadcrumbsTooltip.tsx';

type Props = {
  readonly onFolderOpen: (folderId: Uuid) => void;
  readonly onFolderReset: () => void;
  readonly openedFolders: ReadonlyArray<ITaxonomyTerm>;
};

export const AssetFolderEllipsisBreadcrumbItem: React.FC<Props> = ({
  onFolderOpen,
  onFolderReset,
  openedFolders,
}) => {
  return (
    <div {...getDataUiElementAttribute(DataUiElement.BreadcrumbsEllipsis)}>
      <NavigationBarQuinaryMenuButton
        placement="bottom start"
        trigger={{
          name: 'Entire folder hierarchy',
          icon: Icons.Ellipsis,
        }}
        menuDataAttributes={getDataUiElementAttribute(DataUiElement.AssetFolderBreadcrumbsPopover)}
      >
        <AssetFoldersBreadcrumbsTooltip
          onFolderOpen={onFolderOpen}
          onFolderReset={onFolderReset}
          openedFolders={openedFolders}
        />
      </NavigationBarQuinaryMenuButton>
    </div>
  );
};

AssetFolderEllipsisBreadcrumbItem.displayName = 'AssetFolderEllipsisBreadcrumbItem';
