import { isEmptyOrWhitespace } from '../../../../_shared/utils/stringUtils.ts';
import {
  IContentGroup,
  getContentGroupFromServerModel,
  getContentGroupServerModel,
} from '../../../../applications/contentInventory/content/models/contentTypeElements/types/ContentGroup.ts';
import { UntitledContentTypeName } from '../../../../applications/contentModels/contentTypes/constants/contentTypeConstants.ts';
import { convertTypeElementsToServerModel } from '../../../../applications/contentModels/shared/utils/typeElementDataConverter.ts';
import { ContentTypeServerModel } from '../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ContentTypeConversionOptions } from '../ContentTypeConversionOptions.type.ts';
import {
  IContentTypeBase,
  convertContentTypeBaseToServerModel,
  emptyContentTypeBase,
  getContentTypeBaseFromServerModel,
} from '../sharedContentTypeModels.ts';

export interface IContentType extends IContentTypeBase {
  readonly contentGroups: ReadonlyArray<IContentGroup>;
}

export const emptyContentType: IContentType = {
  ...emptyContentTypeBase,
  contentGroups: [],
};

export const createDeletedContentType = (typeId: Uuid): IContentType => ({
  ...emptyContentType,
  id: typeId,
  name: 'Deleted content type',
  isArchived: true,
});

export const getContentTypeFromServerModel = (
  serverModel: ContentTypeServerModel,
  conversionOptions: ContentTypeConversionOptions,
): IContentType => ({
  ...getContentTypeBaseFromServerModel(serverModel, conversionOptions),
  name: isEmptyOrWhitespace(serverModel.name) ? UntitledContentTypeName : serverModel.name,
  contentGroups: serverModel.contentGroups.map(getContentGroupFromServerModel),
});

export const convertContentTypeToServerModel = (
  contentType: IContentType,
): ContentTypeServerModel => ({
  ...convertContentTypeBaseToServerModel(contentType),
  contentElements: convertTypeElementsToServerModel(contentType.typeElements),
  contentGroups: contentType.contentGroups.map(getContentGroupServerModel),
  name: contentType.name || UntitledContentTypeName,
});
