import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import {
  ContentItemRevisionViewerRoute,
  ContentItemRevisionViewerRouteParams,
  ContentItemRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import {
  buildPath,
  parseContentItemIds,
} from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { TimelineItem } from '../../../models/revisions/TimeLineItem.ts';

const getTimelineItemPathsMap = (
  entryTimeline: ReadonlyArray<TimelineItem> | null,
  match: ContentItemRouteParams<string>,
): Immutable.Map<Uuid, string> | null => {
  const paths = entryTimeline?.map((timelineItem) => {
    const timelineItemPath = buildPath<ContentItemRevisionViewerRouteParams<UuidArray>>(
      ContentItemRevisionViewerRoute,
      {
        app: match.app,
        projectId: match.projectId,
        variantId: match.variantId,
        spaceId: match.spaceId,
        contentItemIds: parseContentItemIds(match.contentItemIds),
        timelineItemId: timelineItem.itemId,
      },
    );

    return [timelineItem.itemId, timelineItemPath];
  });

  return paths ? Immutable.Map<Uuid, string>(paths) : null;
};

const getTimelineItemPathsMapMemoized = memoize.allForever(getTimelineItemPathsMap);

export { getTimelineItemPathsMapMemoized as getTimelineItemPathsMap };
