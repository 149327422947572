import { memoize } from '@kontent-ai/memoization';
import { IRole } from '../../../../data/models/roles/IRole.ts';
import { IProjectContributorRole } from '../../../../data/models/users/ProjectContributor.ts';
import { getUsedRoleIds } from './getUsedRoleIds.ts';

export const getRemainingRoles = memoize.maxOne(
  (
    userRoles: ReadonlyArray<IProjectContributorRole>,
    roles: ReadonlyArray<IRole>,
  ): ReadonlyArray<IRole> => {
    const usedRolesIds = getUsedRoleIds(userRoles);

    return roles.filter((role) => !usedRolesIds.has(role.id));
  },
);
