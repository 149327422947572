import { alphabetically, createCompare } from '@kontent-ai/utils';
import { ILanguage } from '../../../data/models/languages/Language.ts';
import { anyLanguageLanguage } from '../constants/anyLanguageLanguage.ts';
import { LanguageOption, mapLanguageToOption } from '../models/LanguageOption.ts';

export const getSortedLanguageOptionsListWithAnyLanguageFirst = (
  languagesById: Immutable.Map<Uuid, ILanguage>,
): ReadonlyArray<LanguageOption> =>
  [anyLanguageLanguage].concat(getSortedLanguages(languagesById)).map(mapLanguageToOption);

const getSortedLanguages = (
  languagesById: Immutable.Map<Uuid, ILanguage>,
): ReadonlyArray<ILanguage> =>
  languagesById.toArray().sort(
    createCompare({
      compare: alphabetically,
      select: (x) => x.name,
    }),
  );
