import { Toggle } from '@kontent-ai/component-library/Toggle';

type PreviewToggleProps = Readonly<{
  disabledTooltip: string | undefined;
  isActive: boolean;
  onToggle?: (newStatus: boolean) => void;
}>;

export const PreviewToggle = ({ disabledTooltip, isActive, onToggle }: PreviewToggleProps) => {
  return (
    <Toggle
      disabled={!!disabledTooltip}
      labelText="Preview"
      status={isActive ? 'on' : 'off'}
      tooltipPlacement="bottom-start"
      tooltipText={disabledTooltip}
      onToggleOn={() => onToggle?.(true)}
      onToggleOff={() => onToggle?.(false)}
    />
  );
};
