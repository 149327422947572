import { CharacterMetadata } from 'draft-js';
import { CompositionEventHandler, useCallback } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { Apply, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { getBlockIdClassName } from '../../editorCore/utils/editorComponentUtils.ts';
import { isCustomBlockSleeve, isEmptyTextBlock } from '../../utils/blocks/blockTypeUtils.ts';
import { findBlockIndex, setBlockText } from '../../utils/blocks/editorBlockUtils.ts';
import { getBlocks } from '../../utils/general/editorContentGetters.ts';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import { MentionsPlugin } from '../mentions/MentionsPlugin.tsx';
import { WrapperPlugin } from '../visuals/WrapperPlugin.tsx';

export type CompositionModeAdjustmentsPlugin = DraftJsEditorPlugin<
  None,
  None,
  None,
  None,
  [WrapperPlugin]
>;

// Transform empty text blocks and custom sleeve blocks into non-empty regular paragraph text blocks directly in DOM on composition start (IME, speech-to-text).
// Draft-js then takes care of the newly added content on composition end.
export const CompositionModeAdjustmentsPlugin: PluginComponent<CompositionModeAdjustmentsPlugin> = (
  props,
) => {
  const render: Decorator<Render<MentionsPlugin>> = useCallback(
    (baseRender) => (state) => {
      const handleCompositionStart: CompositionEventHandler = () => {
        const selection = state.editorState.getSelection();
        const content = state.editorState.getCurrentContent();
        const blocks = getBlocks(content);

        const selectionFocusIndex = findBlockIndex(blocks, selection.getStartKey());
        const firstBlockAtSelection = content.getBlockForKey(selection.getStartKey());

        if (!firstBlockAtSelection) {
          return;
        }
        const firstBlockAtSelectionKey = firstBlockAtSelection.getKey();

        if (
          isEmptyTextBlock(firstBlockAtSelection) ||
          isCustomBlockSleeve(selectionFocusIndex, blocks)
        ) {
          const blockIdClass = getBlockIdClassName(firstBlockAtSelectionKey);

          // Adding random text to make block non-empty, actual content doesn't matter — ContentState is not affected.
          const textBlock = setBlockText(firstBlockAtSelection, {
            text: 'a',
            characterList: Immutable.List<CharacterMetadata>([CharacterMetadata.create()]),
          });

          const textBlockClassName = state.editorProps.blockStyleFn?.(textBlock) ?? '';
          const blockElement = state
            .getWrapperRef()
            .current?.getElementsByClassName(blockIdClass)[0];

          if (!blockElement) {
            return;
          }

          blockElement.className = textBlockClassName;
        }
      };

      const stateWithRestrictedComposition = {
        ...state,
        wrapperProps: {
          ...state.wrapperProps,
          onCompositionStart: handleCompositionStart,
        },
      };
      return baseRender(stateWithRestrictedComposition);
    },
    [],
  );

  const apply: Apply<CompositionModeAdjustmentsPlugin> = useCallback(
    (state) => {
      state.render.decorate(render);
      return {};
    },
    [render],
  );

  return useEditorWithPlugin(props, { apply });
};
