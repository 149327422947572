import React, { useRef } from 'react';
import { DatetimePickerRefType } from '../../../../../../../_shared/components/DatetimePicker/DatetimePicker.tsx';
import {
  areParsedDateTimesEqual,
  parseDatetime,
} from '../../../../../../../_shared/components/DatetimePicker/InternalFiles/datetimeUtils.ts';
import { DateTime as DateTimeShape } from '../../../../../../../_shared/models/DateTime.ts';
import { transferBetweenTimeZonesKeepingLocalTime } from '../../../../../../../_shared/utils/dateTime/timeZoneUtils.ts';
import { IDateTimeTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/DateTimeTypeElement.ts';
import { DateTimeItemElement } from '../../../../../models/contentItemElements/DateTimeItemElement.ts';
import { ItemElement } from '../../../containers/elements/ItemElement.tsx';
import { ItemElementRefresher } from '../../../containers/elements/ItemElementRefresher.tsx';
import { hasDefaultValue } from '../../../utils/defaultValueUtils.ts';
import { IItemElementComponentOwnProps } from '../IItemElementOwnProps.type.ts';
import { DefaultValueStatus } from '../subComponents/limitInfoMessages/DefaultValueStatus.tsx';
import { DateTimeInputs } from './DateTimeInputs.tsx';

export type DateTimeProps = IItemElementComponentOwnProps<
  DateTimeItemElement,
  IDateTimeTypeElement
> & {
  readonly onValueChanged: (dateTime: DateTimeShape, displayTimeZone: string | null) => void;
};

export const DateTime: React.FC<DateTimeProps> = ({
  elementData,
  typeElement,
  onValueChanged,
  disabled,
  autoFocus,
}) => {
  const datetimePickerRef = useRef<DatetimePickerRefType>(null);

  const isDefaultValueSet = hasDefaultValue(typeElement.defaultValue);
  const parsedValue = parseDatetime(elementData.value);
  const parsedDefaultValue = parseDatetime(typeElement.defaultValue);
  const isCurrentValueDefault = areParsedDateTimesEqual(parsedValue, parsedDefaultValue);

  const onChange = (dateTime: DateTimeShape, newTimeZoneId: string): void => {
    if (!dateTime.isValid || disabled) {
      return;
    }

    const utcDateTimeInNewTimeZone =
      dateTime.value &&
      newTimeZoneId &&
      transferBetweenTimeZonesKeepingLocalTime(
        dateTime.value,
        elementData.displayTimeZone,
        newTimeZoneId,
      );

    onValueChanged(
      {
        ...dateTime,
        value: utcDateTimeInNewTimeZone ?? dateTime.value,
      },
      newTimeZoneId,
    );
  };

  const focusInputAtTheStart = (): void => {
    datetimePickerRef.current?.focusInputAtTheStart();
  };

  const focusInputAtTheEnd = (): void => {
    datetimePickerRef.current?.focusInputAtTheEnd();
  };

  return (
    <ItemElement
      typeElement={typeElement}
      disabled={disabled}
      onHeaderClick={focusInputAtTheStart}
      onContentClick={focusInputAtTheEnd}
    >
      <ItemElementRefresher
        elementData={elementData}
        renderInput={(refresherItemElement: DateTimeItemElement) => (
          <>
            <DateTimeInputs
              ariaLabel={typeElement.name}
              autoFocus={autoFocus}
              disabled={disabled}
              onChange={onChange}
              elementData={refresherItemElement}
              datetimePickerRef={datetimePickerRef}
            />
            <DefaultValueStatus
              isStatusRendered={isDefaultValueSet && !disabled}
              isValueDefault={isCurrentValueDefault}
            />
          </>
        )}
      />
    </ItemElement>
  );
};

DateTime.displayName = 'DateTime';
