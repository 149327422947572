import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../_shared/constants/sharedActionTypes.ts';
import {
  ContentItemEditing_Init_Started,
  ContentItemEditing_ItemElementsSaving_Failed,
  ContentItemEditing_ItemElementsSaving_Finished,
  ContentItemEditing_ItemSaving_Finished,
  ContentItemEditing_LanguageVariantSwitched,
  ContentItemEditing_Saving_Failed,
} from '../../itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { ContentItemSavingOrigin } from '../../itemEditor/features/ContentItemEditing/models/ContentItemSavingOrigin.ts';
import {
  WebSpotlight_LocalizedRoute_Left,
  WebSpotlight_Node_Selected,
  WebSpotlight_PreviewApiPolling_ChangeReady,
  WebSpotlight_PreviewApiPolling_Failed,
  WebSpotlight_PreviewApiPolling_Finished,
  WebSpotlight_Preview_Refreshed,
} from '../constants/webSpotlightActionTypes.ts';
import { getGreaterDate } from '../utils/webSpotlightUtils.ts';

export type LastModificationDateByItemCodename = ReadonlyMap<string, Date>;

export const initialState: LastModificationDateByItemCodename = new Map();

export const lastModifiedPreviewItems = (state = initialState, action: Action) => {
  switch (action.type) {
    case ContentItemEditing_ItemElementsSaving_Failed:
    case ContentItemEditing_LanguageVariantSwitched:
    case ContentItemEditing_Saving_Failed:
    case Shared_CurrentProjectId_Updated:
    case ContentItemEditing_Init_Started:
    case WebSpotlight_LocalizedRoute_Left:
    case WebSpotlight_Node_Selected:
    case WebSpotlight_PreviewApiPolling_Failed:
    case WebSpotlight_PreviewApiPolling_Finished:
      return initialState;

    case WebSpotlight_Preview_Refreshed:
      // Reset state when user triggered preview reload by clicking on refresh preview button.
      return action.payload.manualRefresh ? initialState : state;

    case ContentItemEditing_ItemElementsSaving_Finished:
    case ContentItemEditing_ItemSaving_Finished: {
      const isEditorWithPreview =
        action.payload.origin === ContentItemSavingOrigin.FloatingEditor ||
        action.payload.origin === ContentItemSavingOrigin.ContentItemEditorWithPreview;
      if (!isEditorWithPreview) {
        return state;
      }

      const itemCodename =
        action.type === ContentItemEditing_ItemElementsSaving_Finished
          ? action.payload.itemWithVariant.item.codeName
          : action.payload.itemServerModel.codeName;

      const previouslyModifiedDate = new Date(action.payload.itemPreviouslyUpdatedAt ?? 0);
      const lastModifiedDateFromServer =
        action.type === ContentItemEditing_ItemElementsSaving_Finished
          ? new Date(action.payload.itemWithVariant.variant.lastModified ?? 0)
          : new Date(action.payload.itemServerModel.lastModified ?? 0);

      // Last modification date hasn't been updated on the BE, which means that it won't be updated
      // on the Preview API either. We can ignore this save, because with the current polling logic this polling would be infinite.
      if (previouslyModifiedDate >= lastModifiedDateFromServer) {
        return state;
      }

      return Collection.add(state, [itemCodename, lastModifiedDateFromServer], getGreaterDate);
    }

    case WebSpotlight_PreviewApiPolling_ChangeReady:
      return Collection.remove(state, action.payload.itemCodename);

    default:
      return state;
  }
};
