import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import {
  ContentItemPreviewRoute,
  ContentItemRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import {
  matchPath,
  parseContentItemIds,
} from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { loadItemPreviewInfo } from '../../actions/thunkWebSpotlightActions.ts';
import { itemPreviewInfoReloadRequested } from '../../actions/webSpotlightActions.ts';
import { useSharedContentItemPreviewRouteState } from '../../hooks/useSharedContentItemPreviewRouteState.ts';
import { WebSpotlightPreviewContent } from './WebSpotlightPreviewContent.tsx';

export const WebSpotlightSmartPreview = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const itemPreviewInfo = useSelector((state) => state.webSpotlightApp.itemPreviewInfo);
  const isPreviewConfigurationLoaded = useSelector(
    (state) => !!state.contentApp.previewConfiguration,
  );

  const currentPath = location.pathname;
  const matchedParams = matchPath<ContentItemRouteParams<string>>(
    currentPath,
    ContentItemPreviewRoute,
  );
  const routeContentItemIds = matchedParams
    ? parseContentItemIds(matchedParams.contentItemIds)
    : [];
  const isCorrectPreviewInfoLoaded = itemPreviewInfo?.routeContentItemIds === routeContentItemIds;

  useEffect(() => {
    dispatch(itemPreviewInfoReloadRequested());
  }, []);

  const spaceId = useSelector((s) => s.webSpotlightApp.spaceId);

  useThunkPromise(loadItemPreviewInfo, routeContentItemIds, spaceId, {
    canRun: !isCorrectPreviewInfoLoaded && isPreviewConfigurationLoaded,
  });

  const [sharedPreviewState] = useSharedContentItemPreviewRouteState();

  if (!itemPreviewInfo || !isCorrectPreviewInfoLoaded || !isPreviewConfigurationLoaded) {
    return <Loader />;
  }

  return (
    <WebSpotlightPreviewContent
      previewContentItemInfo={itemPreviewInfo.previewContentItemInfo}
      previewUrlInfo={itemPreviewInfo.previewUrlInfo}
      sharedPreviewUrl={sharedPreviewState?.url ?? null}
    />
  );
};
