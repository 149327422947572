import React from 'react';
import { DataTable } from '../../../../../_shared/components/DataTable/DataTable.tsx';
import { DataTableHeadRow } from '../../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { DataTableNameCell } from '../../../../../_shared/components/DataTable/DataTableNameCell.tsx';
import { DataTableRow } from '../../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { LastUpdatedAt } from '../../../../../_shared/components/LastUpdatedAt.tsx';
import {
  ScheduledActionInProgress,
  ScheduledActionType,
} from '../../../../../_shared/components/Workflow/ScheduledActionInProgress.tsx';
import { OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { renderDatetimeString } from '../../../../../_shared/utils/dateTime/timeUtils.ts';
import { isVariantScheduledActionInProgress } from '../../../../../_shared/utils/workflow/isVariantScheduledActionInProgress.ts';
import { ViewItemsProps } from './ListingView.tsx';

const headColumns = [
  {
    columnName: 'Name',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Content type',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--4',
  },
  {
    columnName: 'Scheduled to',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--5',
  },
  {
    columnName: 'Last updated',
    orderBy: OrderByDirection.Descending,
    className: 'data-table__column--4',
  },
];

export const ScheduledToPublishView: React.FC<ViewItemsProps> = ({ contentItems }) => (
  <DataTable
    dataUiCollectionName={DataUiCollection.ContentItems}
    header={<DataTableHeadRow columns={headColumns} />}
  >
    {contentItems.map((contentItem) => {
      const { id, lastUpdatedAt, name, contentType, linkTo, scheduledToPublishAt } = contentItem;
      return (
        <DataTableRow dataUiObjectName={name} id={id} key={id}>
          <DataTableNameCell contentItem={contentItem} />
          <LinkDataTableCell linkPath={linkTo} title={name}>
            {name}
          </LinkDataTableCell>
          <LinkDataTableCell linkPath={linkTo} title={contentType}>
            {contentType}
          </LinkDataTableCell>
          <LinkDataTableCell linkPath={linkTo} title={renderDatetimeString(scheduledToPublishAt)}>
            {isVariantScheduledActionInProgress(scheduledToPublishAt) ? (
              <ScheduledActionInProgress actionType={ScheduledActionType.Publish} showTooltip>
                {renderDatetimeString(scheduledToPublishAt)}
              </ScheduledActionInProgress>
            ) : (
              renderDatetimeString(scheduledToPublishAt)
            )}
          </LinkDataTableCell>
          <LinkDataTableCell linkPath={linkTo}>
            <LastUpdatedAt time={lastUpdatedAt} />
          </LinkDataTableCell>
        </DataTableRow>
      );
    })}
  </DataTable>
);

ScheduledToPublishView.displayName = 'ScheduledToPublishView';
