import { ActionType } from '../../../applications/environmentSettings/auditLog/constants/ActionType.ts';
import { ObjectType } from '../../../applications/environmentSettings/auditLog/constants/ObjectType.ts';
import { Source } from '../../../applications/environmentSettings/auditLog/constants/Source.ts';
import {
  IAuditEventServerModel,
  IAuditLogSearchServerResponseModel,
} from '../../../repositories/serverModels/IAuditLogServerModels.type.ts';

export interface IAuditEvent {
  readonly id: Uuid;
  readonly projectId: Uuid;
  readonly userEmail: string;
  readonly userFullName: string;
  readonly userId: Uuid;
  readonly objectDescription: string;
  readonly objectDisplayName: string;
  readonly objectId: Uuid;
  readonly objectType: ObjectType;
  readonly actionType: ActionType;
  readonly actionDescription: string;
  readonly timestamp: DateTimeStamp;
  readonly source: Source;
}

export interface IAuditEvents {
  readonly auditEvents: ReadonlyArray<IAuditEvent>;
}

export const emptyAuditEvent: IAuditEvent = {
  id: '',
  projectId: '',
  userEmail: '',
  userFullName: '',
  userId: '',
  objectDescription: '',
  objectDisplayName: '',
  objectId: '',
  objectType: ObjectType.ContentType,
  actionType: ActionType.Create,
  actionDescription: '',
  timestamp: '',
  source: Source.system,
};

export function createAuditEventFromServerModel(serverModel: IAuditEventServerModel): IAuditEvent {
  return {
    id: serverModel.id ?? emptyAuditEvent.id,
    projectId: serverModel.projectId ?? emptyAuditEvent.projectId,
    userEmail: serverModel.userEmail ?? emptyAuditEvent.userEmail,
    userFullName: serverModel.userFullName ?? emptyAuditEvent.userFullName,
    userId: serverModel.userId ?? emptyAuditEvent.userId,
    objectDescription: serverModel.objectDescription ?? emptyAuditEvent.objectDescription,
    objectDisplayName: serverModel.objectDisplayName ?? emptyAuditEvent.objectDisplayName,
    objectId: serverModel.objectId ?? emptyAuditEvent.objectId,
    objectType: serverModel.objectType ?? emptyAuditEvent.objectType,
    actionType: serverModel.actionType ?? emptyAuditEvent.actionType,
    actionDescription: serverModel.actionDescription ?? emptyAuditEvent.actionDescription,
    timestamp: serverModel.timestamp ?? emptyAuditEvent.timestamp,
    source: serverModel.source ?? emptyAuditEvent.source,
  };
}

export function createAuditEventsFromServerModel(
  serverModel: IAuditLogSearchServerResponseModel,
): IAuditEvents {
  return {
    auditEvents: serverModel.events.map(createAuditEventFromServerModel),
  };
}
