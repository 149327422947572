import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { Capability, getUserCapability } from '../../../../_shared/utils/permissions/capability.ts';
import { IUserProjectInfo } from '../../../../data/models/user/UserProjectInfo.ts';
import { isProjectInSettingUpUiTransitionState } from '../../../projects/utils/projectUtils.ts';
import { IBaseEnvironment } from '../models/IBaseEnvironment.type.ts';

export const getEnvironmentsForCloning = (
  state: IStore,
  masterEnvironmentId: Uuid,
): ReadonlyArray<IBaseEnvironment> => {
  const {
    data: {
      user: { projectsInfoById },
    },
    projectsApp: { projectsUiTransitionState },
  } = state;

  return projectsInfoById
    .toArray()
    .filter(
      (p: IUserProjectInfo) =>
        p.masterEnvironmentId === masterEnvironmentId &&
        getUserCapability(p).can(Capability.ManageEnvironments) &&
        !isProjectInSettingUpUiTransitionState(p.projectId, projectsUiTransitionState) &&
        p.isSetUpCorrectly,
    )
    .map(
      (environment: IUserProjectInfo): IBaseEnvironment => ({
        environmentName: environment.environmentName,
        environmentId: environment.projectId,
      }),
    );
};
