import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';

export const getAvailableContentTypesForWebSpotlightRootItem = (
  state: IStore,
): ReadonlyArray<IContentType> => {
  const rootTypeId = state.webSpotlightApp.configuration?.rootTypeId;
  const rootType = rootTypeId && state.data.contentTypes.byId.get(rootTypeId);

  return rootType ? [rootType] : [];
};
