import { usePrevious } from '@kontent-ai/hooks';
import { nameof } from '@kontent-ai/utils';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { getDefaultProjectLocationId } from '../../../../_shared/constants/projectLocationIds.ts';
import { ProjectOrderBy } from '../../../../_shared/constants/projectOrderBy.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { IProjectLocation } from '../../../../_shared/models/ProjectLocation.ts';
import { SidebarNames } from '../../../../_shared/models/SidebarNames.ts';
import { createFormValidationResolver } from '../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { nonEmptyValidationBuilder } from '../../../../_shared/utils/validation/isEmptyOrWhitespace.ts';
import {
  getProjectDetail,
  getProjectsForListing,
} from '../../../../data/reducers/projects/selectors/getProjectsForListing.ts';
import {
  getActiveSubscriptions,
  getAdministratedSubscriptions,
  getSelectedSubscription,
  getSubscriptionAvailableProjectLocations,
  getSubscriptionDefaultProjectLocation,
  getSubscriptionsSortedByName,
} from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import {
  clearCopyProjectDataInfo,
  closeCreateProjectModal,
} from '../../actions/projectsActions.ts';
import {
  createEmptyProject,
  createProjectFromTemplate,
} from '../../actions/thunkProjectsActions.ts';
import { CreateProjectModal } from '../../components/projects/CreateProjectModal.tsx';
import { INewProjectFormShape } from '../../models/INewProjectFormShape.type.ts';
import { canProjectBeCopied, getCopyProjectValidationData } from '../../utils/copyProjectUtils.ts';
import { createProjectTemplateList } from '../../utils/createProjectTemplateList.ts';

const emptyProjectLocations: IProjectLocation[] = [];

const CreateProjectModalContainer: React.FC = () => {
  const activeSubscriptions = useSelector((state) => {
    const { administratedIds, byId } = state.data.subscriptions;
    const administratedSubscriptions = getAdministratedSubscriptions(administratedIds, byId);
    const sortedAdministratedSubscriptions = getSubscriptionsSortedByName(
      administratedSubscriptions,
    );
    return getActiveSubscriptions(sortedAdministratedSubscriptions);
  });

  const hasAnyActiveSubscription = useSelector(
    (state) =>
      getActiveSubscriptions(
        getAdministratedSubscriptions(
          state.data.subscriptions.administratedIds,
          state.data.subscriptions.byId,
        ),
      ).length > 0,
  );
  const showSidebar = useSelector(
    (state) =>
      state.sharedApp.sidebarStatus.isVisible &&
      state.sharedApp.sidebarStatus.sidebarName === SidebarNames.CreateProjectModal &&
      hasAnyActiveSubscription,
  );

  const availableProjectTemplates = useSelector((state) => {
    const { projects, user } = state.data;

    return createProjectTemplateList(
      user.info,
      getProjectsForListing(projects.byId, ProjectOrderBy.ProjectName, null),
    );
  });

  const selectedSubscription = useSelector(getSelectedSubscription);
  const disableSubscriptionSelector = !!selectedSubscription;

  const selectedSubscriptionId = selectedSubscription
    ? selectedSubscription.subscriptionId
    : activeSubscriptions[0]?.subscriptionId || '';

  const defaultProjectLocationId = useSelector((state) => {
    const defaultProjectLocation = getSubscriptionDefaultProjectLocation(
      state,
      selectedSubscriptionId,
    );

    return defaultProjectLocation?.projectLocationId || getDefaultProjectLocationId();
  });

  const dispatch = useDispatch();

  const onCloseDialog = () => dispatch(closeCreateProjectModal());

  const creatingProject = useSelector((state) => state.projectsApp.creatingProject);
  const errorMessage = useSelector((state) => state.projectsApp.createProjectErrorMessage);

  const formProps = useForm<INewProjectFormShape>({
    defaultValues: {
      subscriptionId: selectedSubscriptionId,
      projectLocationId: getDefaultProjectLocationId(),
      includeContent: false,
      projectName: '',
      projectTemplateId: '',
    },
    resolver: createFormValidationResolver<INewProjectFormShape>(
      {
        projectName: [nonEmptyValidationBuilder('project name')],
      },
      {},
    ),
  });

  const { handleSubmit, setValue, watch } = formProps;
  const submitForm = handleSubmit((newProject) =>
    newProject.projectTemplateId
      ? dispatch(createProjectFromTemplate(newProject))
      : dispatch(createEmptyProject(newProject)),
  );

  const {
    subscriptionId: destinationSubscriptionId,
    includeContent: includeContentValue,
    projectLocationId: selectedProjectLocationId,
    projectTemplateId: selectedProjectTemplateId,
  } = watch();

  const prevSelectedProjectTemplateId = usePrevious(selectedProjectTemplateId);
  const prevIncludeContentValue = usePrevious(includeContentValue);

  const availableProjectLocations = useSelector((state) =>
    destinationSubscriptionId
      ? getSubscriptionAvailableProjectLocations(state, destinationSubscriptionId)
      : emptyProjectLocations,
  );

  useEffect(() => {
    // Ensure default or any available datacenter when location selection disabled or when destination subscription was changed and selected datacenter is not available
    if (
      !availableProjectLocations.find((loc) => loc.projectLocationId === selectedProjectLocationId)
    ) {
      const presetLocation =
        availableProjectLocations.find(
          (loc) => loc.projectLocationId === defaultProjectLocationId,
        ) ?? availableProjectLocations[0];
      setValue(
        nameof<INewProjectFormShape>('projectLocationId'),
        presetLocation?.projectLocationId ?? defaultProjectLocationId,
      );
    }
  }, [availableProjectLocations, selectedProjectLocationId, setValue, defaultProjectLocationId]);

  useEffect(() => {
    const selectedProjectTemplateChanged =
      selectedProjectTemplateId !== prevSelectedProjectTemplateId;
    const includeContentChanged = !includeContentValue && prevIncludeContentValue;

    if (selectedProjectTemplateChanged || includeContentChanged) {
      dispatch(clearCopyProjectDataInfo());
    }
  }, [
    selectedProjectTemplateId,
    prevSelectedProjectTemplateId,
    includeContentValue,
    prevIncludeContentValue,
  ]);

  const isProjectTemplateSelected = useSelector((state) => {
    const selectedProjectTemplate = selectedProjectTemplateId
      ? getProjectDetail(state, selectedProjectTemplateId)
      : undefined;

    return !!selectedProjectTemplate;
  });

  /* As we obtain copyProjectDataInfo data from server while trying to submit form, this limitation is not based on form state
  and form is not re-validated after this object is being received. Also it's impossible to start form validation manually.
  That's why we have to validate it separately from the rest of the form */
  const copyProjectValidationData = useSelector((state) =>
    getCopyProjectValidationData(
      state,
      selectedProjectTemplateId,
      destinationSubscriptionId,
      state.projectsApp.copyProjectDataInfo,
    ),
  );
  const canBeCopied = canProjectBeCopied(copyProjectValidationData);

  const subscriptionProjectsLimit =
    copyProjectValidationData.targetPlanLimits.maxSubscriptionProjects;
  const subscriptionProjectsLimitReached =
    copyProjectValidationData.validationErrors.maxSubscriptionProjectsReached;

  return (
    <CreateProjectModal
      activeSubscriptions={activeSubscriptions}
      availableProjectLocations={availableProjectLocations}
      availableProjectTemplates={availableProjectTemplates}
      canSubmitForm={canBeCopied}
      creatingProject={creatingProject}
      destinationSubscriptionId={destinationSubscriptionId}
      disableSubscriptionSelector={disableSubscriptionSelector}
      errorMessage={errorMessage}
      formProps={formProps}
      isProjectTemplateSelected={isProjectTemplateSelected}
      onCloseDialog={onCloseDialog}
      onSubmit={submitForm}
      selectedSubscriptionId={destinationSubscriptionId}
      showSidebar={showSidebar}
      sourceProjectId={selectedProjectTemplateId}
      subscriptionProjectsLimit={subscriptionProjectsLimit}
      subscriptionProjectsLimitReached={subscriptionProjectsLimitReached}
    />
  );
};

CreateProjectModalContainer.displayName = 'CreateProjectModalContainer';

export { CreateProjectModalContainer as CreateProjectModal };
