import { memoize } from '@kontent-ai/memoization';
import { ILanguage, Languages } from '../../../data/models/languages/Language.ts';
import { ILanguagesData } from '../../../data/reducers/languages/ILanguagesData.type.ts';
import { getAllActiveLanguagesForCurrentUserInAnyCollection } from '../../../data/reducers/languages/selectors/getLanguages.ts';
import { IUser } from '../../../data/reducers/user/IUser.type.ts';
import { Capability } from '../permissions/capability.ts';

export interface IListingLanguageSwitcherOption
  extends Pick<ILanguage, 'name' | 'id' | 'codename'> {
  readonly linkPath: string;
}

function addLinkPathToLanguages(
  allLanguages: Languages,
  currentPathname: string,
  getLinkPath: (currentPathName: string, languageId: Uuid) => string,
): ReadonlyArray<IListingLanguageSwitcherOption> {
  return allLanguages.toArray().map(
    (language): IListingLanguageSwitcherOption => ({
      id: language.id,
      name: language.name,
      codename: language.codename,
      linkPath: getLinkPath(currentPathname, language.id),
    }),
  );
}

export const getListingLanguageSwitcherOptionsCreator = (
  getLinkPath: (currentPathName: string, languageId: Uuid) => string,
) =>
  memoize.maxOne(
    (
      projectId: Uuid,
      user: IUser,
      languages: ILanguagesData,
      currentPathname: string,
    ): ReadonlyArray<IListingLanguageSwitcherOption> => {
      const allLanguages = getAllActiveLanguagesForCurrentUserInAnyCollection(
        user,
        projectId,
        languages,
        Capability.ViewContent,
      );
      const allLanguagesWithLinks = addLinkPathToLanguages(
        allLanguages,
        currentPathname,
        getLinkPath,
      );
      return allLanguagesWithLinks;
    },
  );
