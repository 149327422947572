import { InvariantException } from '@kontent-ai/errors';
import React from 'react';
import { useLocation } from 'react-router';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  ContentItemRevisionCompareRoute,
  ContentItemRevisionCompareRouteParams,
  ContentItemRoute,
  ContentItemRouteParams,
} from '../../../../../../_shared/constants/routePaths.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import {
  buildPath,
  matchPath,
  parseContentItemIds,
} from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { LastUpdatedSection as LastUpdatedSectionComponent } from '../../components/details/LastUpdatedSection.tsx';

const editedContentItemVariantSelector = (s: IStore) => {
  const editedContentItemVariant = s.contentApp.editedContentItemVariant;

  if (!editedContentItemVariant) {
    throw InvariantException('"LastUpdatedSection.tsx": editedContentItemVariant not initialized.');
  }

  return editedContentItemVariant;
};

export const LastUpdatedSection: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const contentLastUpdatedAt = useSelector(
    (s) => editedContentItemVariantSelector(s).contentLastUpdatedAt,
  );
  const contentLastUpdatedBy = useSelector(
    (s) => editedContentItemVariantSelector(s).contentLastUpdatedBy,
  );
  const projectContributors = useSelector((s) => s.data.users.usersById);
  const currentUserId = useSelector((s) => s.data.user.info.userId);

  const revisionComparisonPath = useSelector((s) => {
    const match = matchPath<ContentItemRouteParams<string>>(location.pathname, ContentItemRoute);
    if (!match) {
      throw InvariantException(
        'LastUpdatedSection is rendered within different route than ContentItemRoute',
      );
    }

    const entryTimeline = s.contentApp.entryTimeline;

    const currentItem = entryTimeline?.[0] ?? null;
    const previousItem = entryTimeline?.[1] ?? null;

    return (
      currentItem &&
      previousItem &&
      buildPath<ContentItemRevisionCompareRouteParams<UuidArray>>(ContentItemRevisionCompareRoute, {
        app: match.app,
        projectId: match.projectId,
        variantId: match.variantId,
        spaceId: match.spaceId,
        contentItemIds: parseContentItemIds(match.contentItemIds),
        timelineItemId: currentItem.itemId,
        compareItemId: previousItem.itemId,
      })
    );
  });

  const handleClick = () => {
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingEventTypes.CompareVersions,
        origin: ContentItemEditingEventOrigins.ItemDetails,
      }),
    );
  };

  return (
    <LastUpdatedSectionComponent
      contentLastUpdatedAt={contentLastUpdatedAt}
      contentLastUpdatedBy={contentLastUpdatedBy}
      projectContributors={projectContributors}
      currentUserId={currentUserId}
      revisionComparisonPath={revisionComparisonPath}
      onClick={handleClick}
    />
  );
};

LastUpdatedSection.displayName = 'LastUpdatedSection';
