import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { RouterLinkButton } from '@kontent-ai/component-library/Button';
import { EmptyState } from '../../../../../_shared/components/EmptyState/EmptyState.tsx';
import { documentationLinks } from '../../../../../_shared/constants/documentationLinks.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type CustomAppsEmptyStateProps = {
  readonly createNewCustomAppPath: string;
};

export const CustomAppsEmptyState = ({ createNewCustomAppPath }: CustomAppsEmptyStateProps) => (
  <EmptyState>
    <EmptyState.Title>Bring your favorite tools into one place</EmptyState.Title>
    <EmptyState.Content>
      <EmptyState.ContentGroup>
        <p>
          Integrate external applications and data sources directly into your Kontent.ai
          environment.
        </p>
        <p>
          Whether it’s monitoring deployments, tracking SEO metrics, or managing translation
          progress, <OutwardLink href={documentationLinks.customApps}>custom apps</OutwardLink>{' '}
          empower you to tailor the CMS to your specific needs.
        </p>
      </EmptyState.ContentGroup>
    </EmptyState.Content>
    <EmptyState.Footer>
      <RouterLinkButton
        buttonStyle="primary"
        to={createNewCustomAppPath}
        {...getDataUiActionAttribute(DataUiAction.CreateNew)}
      >
        Create new custom app
      </RouterLinkButton>
    </EmptyState.Footer>
  </EmptyState>
);
