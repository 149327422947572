import { createGuid } from '@kontent-ai/utils';
import { Dispatch, ThunkPromise } from '../../../../../../../../@types/Dispatcher.type.ts';
import { showErrorNotification } from '../../../../../../../../_shared/actions/sharedActions.ts';
import { logError } from '../../../../../../../../_shared/utils/logError.ts';
import { IContentItemRepository } from '../../../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import {
  ContentListing_UndoScheduledPublish_Finished,
  ContentListing_UndoScheduledPublish_Started,
} from '../../../constants/contentItemInventoryActionTypes.ts';
import { BulkUndoScheduleErrorMessage } from '../../../constants/uiConstants.ts';
import { createBulkActionResult } from '../../../utils/createBulkActionResult.ts';
import {
  createBulkActionFinished,
  createBulkActionStarted,
  resetStatusInfo,
} from '../../contentItemInventoryActions.ts';
import { setTimeoutForMessageChange } from './setTimeoutForMessageChange.ts';

interface IUndoSchedulePublishContentItemsActionDependencies {
  readonly contentItemRepository: IContentItemRepository;
  readonly loadListingContentItems: () => ThunkPromise;
}

const actionStarted = createBulkActionStarted<typeof ContentListing_UndoScheduledPublish_Started>(
  ContentListing_UndoScheduledPublish_Started,
);
const actionFinished = createBulkActionFinished<
  typeof ContentListing_UndoScheduledPublish_Finished
>(ContentListing_UndoScheduledPublish_Finished);

export type UndoScheduleContentItemsActionsType = ReturnType<
  typeof actionStarted | typeof actionFinished
>;

export const undoSchedulePublishContentItemsActionCreator =
  (deps: IUndoSchedulePublishContentItemsActionDependencies) =>
  (variantId: Uuid, affectedIds: ReadonlyArray<Uuid>): ThunkPromise =>
  async (dispatch: Dispatch): Promise<any> => {
    const operationId = createGuid();
    dispatch(actionStarted({ operationId }));
    dispatch(setTimeoutForMessageChange(operationId));

    try {
      const operationResult = await deps.contentItemRepository.cancelScheduledPublishOfVariants(
        variantId,
        { includedItemIds: affectedIds },
      );
      dispatch(actionFinished(createBulkActionResult(operationResult, variantId)));
    } catch (error) {
      logError('Error while undoing schedule publish on content listing', error);
      dispatch(resetStatusInfo());
      dispatch(showErrorNotification(BulkUndoScheduleErrorMessage));
    }

    dispatch(deps.loadListingContentItems());
  };
