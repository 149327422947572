import React from 'react';
import { DataTable } from '../../../../../_shared/components/DataTable/DataTable.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import {
  ItemColumnCode,
  translateColumnCodeToTitle,
} from '../../../../../_shared/constants/itemColumnCode.ts';
import { OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WidgetDetailTableStateContent } from '../../shared/components/WidgetDetailTableStateContent.tsx';
import { WidgetListingState } from '../../shared/types/WidgetListingState.type.ts';

type RecentlyEditedByYouWidgetDetailProps = React.PropsWithChildren<{
  readonly ariaLabelledBy: string;
  readonly state: WidgetListingState;
}>;

export const RecentlyEditedByYouWidgetDetail: React.FC<RecentlyEditedByYouWidgetDetailProps> = ({
  ariaLabelledBy,
  state,
  children,
}) => {
  return (
    <WidgetDetailTableStateContent state={state}>
      <DataTable
        dataUiCollectionName={DataUiCollection.ContentItems}
        header={<DataTableHeadRow columns={tableHeadColumns} />}
        ariaLabelledBy={ariaLabelledBy}
      >
        {children}
      </DataTable>
    </WidgetDetailTableStateContent>
  );
};

const tableHeadColumns: ReadonlyArray<Column> = [
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.Name),
    orderBy: OrderByDirection.None,
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.ContentType),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--4',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.WorkflowStep),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--5',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.DueDate),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--4',
  },
  {
    columnName: 'Your last edit',
    orderBy: OrderByDirection.Descending,
    className: 'data-table__column--4',
  },
];
