import { Button } from '@kontent-ai/component-library/Button';
import { FC } from 'react';
import { SidebarSection } from '../../../../../_shared/components/Sidebar/SidebarSection.tsx';
import { SidebarSubmitButtonWithErrorMessage } from '../../../../../_shared/components/Sidebar/SidebarSubmitButtonWithErrorMessage.tsx';
import { Sidebar } from '../../../../../_shared/containers/Sidebar/SidebarContainer.tsx';
import { SubscriptionPlanIncludedUserLimitReachedWarning } from '../../../../../_shared/containers/SubscriptionWarnings/SubscriptionPlanIncludedUserLimitReachedWarning.tsx';
import { SubscriptionPlanMaxUserLimitReachedWarning } from '../../../../../_shared/containers/SubscriptionWarnings/SubscriptionPlanMaxUserLimitReachedWarning.tsx';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ISubscriptionUser } from '../../../shared/models/SubscriptionUserInfo.ts';
import {
  ISubscriptionAdminInvitationFormShape,
  SubscriptionAdminInvitationForm,
} from './SubscriptionAdminInvitationForm.tsx';

type Props = {
  readonly canInviteNewAdmin: boolean;
  readonly email: string;
  readonly errorMessage: string;
  readonly formProps: HookFormProps<ISubscriptionAdminInvitationFormShape>;
  readonly isSubmittingSubscriptionAdmin: boolean;
  readonly onCloseDialog: () => void;
  readonly onShowPromoteUsersModal: () => void;
  readonly onSubmit: () => void;
  readonly showSidebar: boolean;
  readonly subscriptionId: Uuid | null;
  readonly subscriptionUsers: ReadonlyArray<ISubscriptionUser>;
};

export const SubscriptionAdminInvitationModal: FC<Props> = ({
  canInviteNewAdmin,
  email,
  errorMessage,
  formProps,
  isSubmittingSubscriptionAdmin,
  onCloseDialog,
  onShowPromoteUsersModal,
  onSubmit,
  showSidebar,
  subscriptionId,
  subscriptionUsers,
}) => {
  const title = (
    <>
      Invite subscription admins
      <br />
      to work with subscription
    </>
  );

  const onFormSubmit = isSubmittingSubscriptionAdmin ? undefined : onSubmit;

  const submitButton = canInviteNewAdmin && (
    <>
      <div className="sidebar__submit-details">
        <SubscriptionPlanIncludedUserLimitReachedWarning newEmails={[email]} />
      </div>
      <SidebarSubmitButtonWithErrorMessage
        onSubmit={onFormSubmit}
        buttonLabel={isSubmittingSubscriptionAdmin ? 'Sending invitation' : 'Send invitation'}
        dataUiAttribute={getDataUiActionAttribute(DataUiAction.SendAdminInvitation)}
        disabled={isSubmittingSubscriptionAdmin}
        errorMessage={errorMessage}
        isBeingSubmitted={isSubmittingSubscriptionAdmin}
      />
    </>
  );

  return (
    <Sidebar
      isVisible={showSidebar}
      onClose={onCloseDialog}
      onSubmit={onFormSubmit}
      titleElement={title}
      submitElement={submitButton}
    >
      {!!subscriptionUsers.length && (
        <SidebarSection>
          <Button
            buttonStyle="secondary"
            buttonDisplay="block"
            onClick={onShowPromoteUsersModal}
            {...getDataUiActionAttribute(DataUiAction.PromoteExistingUsers)}
          >
            Promote existing users
          </Button>
        </SidebarSection>
      )}
      {!!subscriptionUsers.length && (
        <div
          className="sidebar__divider"
          {...getDataUiElementAttribute(DataUiElement.SidebarDivider)}
        />
      )}
      <SidebarSection title="Invite a new subscription admin">
        <div className="u-spacing-bottom-l">
          <SubscriptionPlanMaxUserLimitReachedWarning />
        </div>
        {subscriptionId && canInviteNewAdmin && (
          <SubscriptionAdminInvitationForm formProps={formProps} onSubmit={onFormSubmit} />
        )}
      </SidebarSection>
    </Sidebar>
  );
};

SubscriptionAdminInvitationModal.displayName = 'SubscriptionAdminInvitationModal';
