import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Menu } from '@kontent-ai/component-library/Menu';
import { mergeProps } from '@react-aria/utils';
import React, { RefObject, useCallback } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  activateContentItemEditingAction,
  deactivateContentItemEditingAction,
} from '../../../../../../itemEditor/features/ContentItemEditing/actions/contentItemEditingActions.ts';
import { EditingAction } from '../../../../../../itemEditor/features/ContentItemEditing/models/EditingAction.ts';
import { getAvailableContentItemEditingActions } from '../../../../../../itemEditor/features/ContentItemEditing/utils/editingActions/getAvailableContentItemEditingActions.ts';
import { MoreActionsMainMenu } from './MoreActionsMainMenu.tsx';

export const MoreActions: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const availableActions = useSelector((state: IStore) =>
    getAvailableContentItemEditingActions(state, location.pathname),
  );

  const onHideMenu = useCallback(() => {
    dispatch(deactivateContentItemEditingAction());
  }, []);

  const onShowMenu = useCallback(() => {
    dispatch(activateContentItemEditingAction(EditingAction.showMainMenu));
  }, []);

  return (
    <Menu>
      <Menu.Trigger>
        {(ref: RefObject<HTMLButtonElement>, triggerProps, isOpen) => (
          <QuinaryButton
            ref={ref}
            tooltipText="More actions"
            {...mergeProps(triggerProps, {
              onClick: isOpen ? onHideMenu : onShowMenu,
            })}
          >
            <QuinaryButton.Icon icon={Icons.Ellipsis} />
          </QuinaryButton>
        )}
      </Menu.Trigger>
      <Menu.List {...getDataUiCollectionAttribute(DataUiCollection.MoreActionsDropdown)}>
        <MoreActionsMainMenu actions={availableActions} />
      </Menu.List>
    </Menu>
  );
};

MoreActions.displayName = 'MoreActions';
