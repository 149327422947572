import { ContentItemCollection } from '../containers/elements/ContentItemCollection.tsx';
import { ItemNameElement } from '../containers/elements/ItemNameElement.tsx';
import { ContentItemPaneElementsGroup } from './ContentItemPaneElementsGroup.tsx';

type ContentItemHeaderProps = Readonly<{
  areAnyContentGroupsVisible: boolean;
  canEditName: boolean;
  isCollectionInMainPaneEnabled: boolean;
}>;

export const ContentItemHeader = ({
  areAnyContentGroupsVisible,
  canEditName,
  isCollectionInMainPaneEnabled,
}: ContentItemHeaderProps) => {
  return (
    <>
      <ContentItemPaneElementsGroup
        isDisabled={!canEditName}
        hasTopRoundedCorners
        hasBottomRoundedCorners={areAnyContentGroupsVisible || isCollectionInMainPaneEnabled}
      >
        <ItemNameElement />
        {isCollectionInMainPaneEnabled && <ContentItemCollection />}
      </ContentItemPaneElementsGroup>
    </>
  );
};
