import React from 'react';
import { ContentItemEditingEventOrigins } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { PreviewLinks } from '../../containers/editingActions/actionSubmenuOptions/PreviewLinks.tsx';
import { EditingAction } from '../../models/EditingAction.ts';

export type UseCustomHandleClickOutside = (
  onClickOutside: () => void,
  shouldCloseOnClickOutside?: (element: HTMLElement) => boolean,
) => void;

export type LeafActionFormProps = {
  readonly actionOrigin: ContentItemEditingEventOrigins;
  readonly useCustomHandleClickOutside: UseCustomHandleClickOutside;
};

type EditingLeafActionFormProps = LeafActionFormProps & {
  readonly activatedAction: EditingAction;
  readonly actionOrigin: ContentItemEditingEventOrigins;
};

export const EditingLeafActionForm: React.FC<EditingLeafActionFormProps> = ({
  activatedAction,
}) => {
  switch (activatedAction) {
    case EditingAction.previewItemInMultipleSpaces:
      return <PreviewLinks isQuickActionsMenu />;

    default:
      return null;
  }
};
