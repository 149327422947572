import { Collection } from '@kontent-ai/utils';
import { ThunkFunction } from '../../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import {
  getItemsBetweenInclusive,
  rangeSelectionShouldSelect,
} from '../../../../../../../_shared/utils/itemSelectionUtils.ts';
import { IListingContentItem } from '../../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import {
  ContentListing_Items_Selected,
  ContentListing_Items_Unselected,
} from '../../constants/contentItemInventoryActionTypes.ts';

const unselectContentItems = (ids: ReadonlySet<Uuid>, lastTouchedItemId: Uuid) =>
  ({
    type: ContentListing_Items_Unselected,
    payload: {
      ids,
      lastTouchedItemId,
    },
  }) as const;

const selectContentItems = (ids: ReadonlySet<Uuid>, lastTouchedItemId: Uuid) =>
  ({
    type: ContentListing_Items_Selected,
    payload: {
      ids,
      lastTouchedItemId,
    },
  }) as const;

export type SelectContentItemsActionsType = ReturnType<
  typeof unselectContentItems | typeof selectContentItems
>;

const getCurrentlySelectedIds = (
  selectAll: boolean,
  allIds: ReadonlyArray<Uuid>,
  selectedItemIds: ReadonlyArray<Uuid>,
  unselectedItemIds: ReadonlyArray<Uuid>,
): ReadonlyArray<Uuid> =>
  selectAll ? allIds.filter((id) => !unselectedItemIds.includes(id)) : selectedItemIds;

export const setContentItemSelectionActionCreator =
  () =>
  (
    itemId: Uuid,
    isSelect: boolean,
    isShiftSelection: boolean,
    canBeSelected: (item: IListingContentItem, state: IStore) => boolean,
  ): ThunkFunction =>
  (dispatch, getState) => {
    const {
      data: {
        listingContentItems: { allIds, byId },
      },
      contentApp: {
        listingUi: {
          itemsSelection: {
            lastSelectionTouchedItem,
            selectedItemIds,
            selectAll,
            unselectedItemIds,
          },
        },
      },
    } = getState();
    const state = getState();

    if (isShiftSelection && lastSelectionTouchedItem && allIds) {
      const isSelectable = (id: Uuid) => {
        const item = byId.get(id);
        return !!item && canBeSelected(item, state);
      };
      const selectedIds: ReadonlySet<Uuid> = new Set(
        Collection.getValues(
          getItemsBetweenInclusive(lastSelectionTouchedItem, itemId, allIds),
        ).filter(isSelectable),
      );

      const currentlySelectedIds = getCurrentlySelectedIds(
        selectAll,
        allIds,
        selectedItemIds,
        unselectedItemIds,
      );

      const multipleSelectionAction = rangeSelectionShouldSelect(
        selectedIds,
        new Set(currentlySelectedIds),
        lastSelectionTouchedItem,
      )
        ? selectContentItems
        : unselectContentItems;

      dispatch(multipleSelectionAction(selectedIds, itemId));
    } else {
      const selectionAction = isSelect ? selectContentItems : unselectContentItems;
      dispatch(selectionAction(new Set([itemId]), itemId));
    }
  };
