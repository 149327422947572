export enum OnboardingNotification {
  ContentTypeEditedNotification = 'ContentTypeCreatedNotification',
  ContentPublishedNotification = 'ContentPublishedNotification',
}

export interface ContentTypeEditedOnboardingNotificationData {
  readonly editedTypeId: Uuid;
  readonly languageIdToCreateItem: Uuid | undefined;
  readonly collectionIdToCreateItem: Uuid | undefined;
}

export interface ContentItemPublishedOnboardingNotificationData {
  readonly publishedItemIds: ReadonlyArray<Uuid>;
}

export type AdditionalOnboardingNotificationData =
  | ContentTypeEditedOnboardingNotificationData
  | ContentItemPublishedOnboardingNotificationData;

export type OnboardingNotificationsInfo = ReadonlyRecord<OnboardingNotification, boolean>;
