import { Box } from '@kontent-ai/component-library/Box';
import { Menu } from '@kontent-ai/component-library/Menu';
import { mergeProps } from '@react-aria/utils';
import React, { RefObject } from 'react';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EditingAction } from '../../models/EditingAction.ts';
import { EditingActionsButton } from './actions/EditingActionsButton.tsx';
import { ActionsMainMenu } from './actionsMenu/ActionsMainMenu.tsx';

type EditingActionsMenuProps = {
  readonly actions: readonly EditingAction[];
  readonly activatedAction: EditingAction;
  readonly onHideMenu: () => void;
  readonly onShowMenu: () => void;
  readonly areAllActionsInMenu: boolean;
};

type MenuButtonProps = {
  readonly dataUiAction?: DataUiAction;
  readonly disableTabulator?: boolean;
  readonly forwardedRef?: React.RefObject<HTMLButtonElement>;
  readonly isActive?: boolean;
  readonly areAllActionsInMenu?: boolean;
  readonly onClick: React.MouseEventHandler<HTMLElement>;
  readonly text: string;
};

const MenuButton: React.FC<MenuButtonProps> = React.memo(({ forwardedRef, ...restProps }) => (
  <EditingActionsButton
    buttonStyle="tertiary"
    ref={forwardedRef}
    iconName="Ellipsis"
    {...restProps}
  />
));

export const EditingActionsMenu: React.FC<EditingActionsMenuProps> = ({
  actions,
  activatedAction,
  areAllActionsInMenu,
  onHideMenu,
  onShowMenu,
}) => {
  const menuButtonText = areAllActionsInMenu ? 'Actions' : 'More actions';

  return (
    <Menu>
      <Menu.Trigger>
        {(ref: RefObject<HTMLButtonElement>, triggerProps, isOpen) => (
          <Box position="relative">
            <Box visibility="hidden" aria-hidden="true">
              <MenuButton
                disableTabulator
                isActive={false}
                text="More actions"
                onClick={() => null}
              />
            </Box>
            <Box position="absolute" top={0} right={0}>
              <MenuButton
                forwardedRef={ref}
                isActive={isOpen}
                text={menuButtonText}
                {...mergeProps(triggerProps, {
                  onClick: isOpen ? onHideMenu : onShowMenu,
                })}
                dataUiAction={DataUiAction.MoreActions}
              />
            </Box>
          </Box>
        )}
      </Menu.Trigger>
      <Menu.List {...getDataUiCollectionAttribute(DataUiCollection.ItemEditingActionsMenu)}>
        <ActionsMainMenu actions={actions} activatedActions={activatedAction} />
      </Menu.List>
    </Menu>
  );
};
