import { Box } from '@kontent-ai/component-library/Box';
import { CheckboxState } from '@kontent-ai/component-library/Checkbox';
import { CheckboxGroup } from '@kontent-ai/component-library/CheckboxGroup';
import { Hint } from '@kontent-ai/component-library/Hint';
import { Input, InputType } from '@kontent-ai/component-library/Input';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { Stack } from '@kontent-ai/component-library/Stack';
import { DefaultTag, Tag } from '@kontent-ai/component-library/Tag';
import { BaseColor, Spacing, Typography } from '@kontent-ai/component-library/tokens';
import React, { ComponentProps } from 'react';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import {
  DataUiCollection,
  DataUiInput,
  getDataUiCollectionAttribute,
  getDataUiInputAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IRoleOption } from '../containers/CreateEnvironmentDialog.tsx';
import { IBaseEnvironment } from '../models/IBaseEnvironment.type.ts';
import { CopyDataOption, copyDataOptionToLabelMap } from '../types/copyDataOption.ts';
import { OriginEnvironmentSelector } from './OriginEnvironmentSelector.tsx';

export interface ICreateEnvironmentFormProps {
  readonly cloneFromId: Uuid;
  readonly environments: ReadonlyArray<IBaseEnvironment>;
  readonly isNewEnvironment: boolean;
  readonly onSelectRoles: (selectedRoles: ReadonlySet<Uuid>) => void;
  readonly onSourceEnvironmentChanged: (cloneFromId: Uuid | null) => void;
  readonly preselectedRoles: ReadonlyArray<IRoleOption>;
  readonly selectableRoleOptions: ReadonlyArray<IRoleOption>;
  readonly selectedCopyDataOptions: ReadonlyArray<string>;
  readonly selectedRoleIds: ReadonlySet<Uuid>;
  readonly setEnvironmentName: (name: string) => void;
  readonly setSelectedCopyDataOptions: (
    selectedCopyDataOptions: ReadonlyArray<CopyDataOption>,
  ) => void;
}

const renderPermanentOption = (
  _id: string,
  _roleOption: IRoleOption,
  defaultTagProps: ComponentProps<typeof DefaultTag>,
) => {
  return <Tag {...defaultTagProps} background={BaseColor.Gray30} />;
};

const getCheckboxState = (
  option: CopyDataOption,
  selectedCopyDataOptions: readonly string[],
): CheckboxState => {
  if (
    option === CopyDataOption.CopyTimelines &&
    !selectedCopyDataOptions.includes(CopyDataOption.CopyContent)
  ) {
    return 'disabled';
  }

  return 'default';
};

export const CreateEnvironmentForm: React.FC<ICreateEnvironmentFormProps> = ({
  cloneFromId,
  environments,
  isNewEnvironment,
  onSelectRoles,
  onSourceEnvironmentChanged,
  preselectedRoles,
  selectableRoleOptions,
  selectedCopyDataOptions,
  selectedRoleIds,
  setEnvironmentName,
  setSelectedCopyDataOptions,
}) => {
  const onCopyOptionsChanged = (copyDataOptions: ReadonlyArray<CopyDataOption>) => {
    if (
      copyDataOptions.includes(CopyDataOption.CopyTimelines) &&
      !copyDataOptions.includes(CopyDataOption.CopyContent)
    ) {
      setSelectedCopyDataOptions([]);
    } else {
      setSelectedCopyDataOptions(copyDataOptions);
    }
  };

  return (
    <Stack spacing={Spacing.L}>
      <Input
        type={InputType.Text}
        autoFocus
        maxLength={ValidationConstants.ProjectNameMaxLength}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setEnvironmentName(event.target.value)
        }
        label="Name"
        caption="Type a name that you can easily use in an API."
        {...getDataUiInputAttribute(DataUiInput.EnvironmentName)}
      />
      <OriginEnvironmentSelector
        disabled={!isNewEnvironment}
        onSelect={onSourceEnvironmentChanged}
        options={environments}
        selectedEnvironmentId={cloneFromId}
        label="Clone from"
        caption="Select an environment you want to clone."
      />
      <MultiSelect<IRoleOption>
        label="Activate users with these roles"
        items={selectableRoleOptions}
        onSelectionChange={onSelectRoles}
        selectedItemIds={selectedRoleIds}
        permanentOptions={preselectedRoles}
        renderPermanentOption={renderPermanentOption}
        {...getDataUiCollectionAttribute(DataUiCollection.RoleList)}
        caption="Only you, Project managers, and users whose roles you’ve selected will have access to this
        environment. You can change this later in the environment’s settings."
      />
      <CheckboxGroup
        selectedValues={selectedCopyDataOptions}
        aria-label="Copy data options"
        onChange={onCopyOptionsChanged}
      >
        {Object.values(CopyDataOption).map((option) => {
          const checkboxState = getCheckboxState(option, selectedCopyDataOptions);
          const tooltip =
            checkboxState === 'disabled' && option === CopyDataOption.CopyTimelines
              ? 'You have to include content items and assets first'
              : undefined;

          return (
            <CheckboxGroup.Checkbox
              key={option}
              checkboxState={checkboxState}
              value={option}
              tooltipText={tooltip}
            >
              <Box display="flex" columnGap={Spacing.XS} alignItems="flex-start">
                {copyDataOptionToLabelMap[option]}
                {checkboxState !== 'disabled' && option === CopyDataOption.CopyTimelines && (
                  <Box
                    display="flex"
                    height={Typography.LabelLarge.lineHeight}
                    alignItems="center"
                    flexBasis="auto"
                    flexShrink={0}
                    flexGrow={0}
                  >
                    <Hint
                      tooltipText="Only the latest 50 versions per content item are cloned."
                      tooltipPlacement="right-end"
                    />
                  </Box>
                )}
              </Box>
            </CheckboxGroup.Checkbox>
          );
        })}
      </CheckboxGroup>
    </Stack>
  );
};

CreateEnvironmentForm.displayName = 'CreateEnvironmentForm';
