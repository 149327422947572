import { memoize } from '@kontent-ai/memoization';
import { assert, alphabetically, notNull } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { IProjectLocation } from '../../../../_shared/models/ProjectLocation.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { ISubscriptionAdmin } from '../../../../applications/subscriptionManagement/shared/models/SubscriptionAdmin.ts';
import { CommonPlanName } from '../../../constants/CommonPlanName.ts';
import { IPlan, emptyPlan } from '../../../models/plans/Plan.ts';
import {
  ISubscription,
  SubscriptionStatus,
  emptySubscription,
} from '../../../models/subscriptions/Subscription.ts';

export function getSubscription(state: IStore, subscriptionId: Uuid): ISubscription {
  const {
    data: { subscriptions },
  } = state;

  return subscriptions.byId.get(subscriptionId) || emptySubscription;
}

export function getSubscriptionPlan(state: IStore, subscriptionId: Uuid): IPlan {
  const {
    data: { plans },
  } = state;
  const subscription = getSubscription(state, subscriptionId);

  return plans.byId.get(subscription.currentPlan.planId) || emptyPlan;
}

export function getSelectedSubscription(state: IStore): ISubscription | null {
  const {
    data: { subscriptions },
    subscriptionApp: { selectedSubscriptionId },
  } = state;

  if (!selectedSubscriptionId) {
    return null;
  }

  return subscriptions.byId.get(selectedSubscriptionId) || null;
}

export const getSelectedSubscriptionIdOrThrow = (state: IStore): Uuid => {
  const subscriptionId = getSelectedSubscription(state)?.subscriptionId;
  assert(subscriptionId, () => 'Selected subscription ID not loaded in the state');

  return subscriptionId;
};

export const getSelectedSubscriptionPlan = (state: IStore): IPlan | null => {
  const subscriptionId = getSelectedSubscription(state)?.subscriptionId;
  return subscriptionId ? getSubscriptionPlan(state, subscriptionId) : null;
};

export function isAdminOnSubscription(state: IStore, subscriptionId: Uuid): boolean {
  const {
    data: { subscriptions },
  } = state;

  return subscriptions.administratedIds.contains(subscriptionId);
}

export function isSubscriptionCanceled(subscription: ISubscription): boolean {
  return subscription.status === SubscriptionStatus.Canceled;
}

export function isSubscriptionExpired(subscription: ISubscription): boolean {
  return subscription.status === SubscriptionStatus.Expired;
}

export function isSubscriptionSuspended(subscription: ISubscription): boolean {
  return subscription.status === SubscriptionStatus.Suspended;
}

export function areAllSubscriptionsExpired(subscriptions: ReadonlyArray<ISubscription>): boolean {
  if (subscriptions.length === 0) {
    return false;
  }

  return !subscriptions.some((subscription: ISubscription) => !isSubscriptionExpired(subscription));
}

export function areAllSubscriptionsCanceled(subscriptions: ReadonlyArray<ISubscription>): boolean {
  if (subscriptions.length === 0) {
    return true;
  }

  return !subscriptions.some(
    (subscription: ISubscription) => !isSubscriptionCanceled(subscription),
  );
}

export function hasEmployeePlan(state: IStore): boolean {
  const {
    data: { subscriptions },
  } = state;

  return subscriptions.administratedIds.some(
    (subscriptionId: Uuid) =>
      getSubscriptionPlan(state, subscriptionId).name === CommonPlanName.Employee,
  );
}

export const getAdministratedSubscriptions = memoize.maxOne(
  (
    administratedIds: Immutable.Set<Uuid>,
    subscriptions: Immutable.Map<Uuid, ISubscription>,
  ): ReadonlyArray<ISubscription> =>
    administratedIds
      .toArray()
      .map((subscriptionId) => subscriptions.get(subscriptionId) ?? null)
      .filter(notNull),
);

export const getSubscriptionsSortedByName = memoize.maxOne(
  (subscriptions: ReadonlyArray<ISubscription>): ReadonlyArray<ISubscription> => {
    return [...subscriptions].sort((a, b) =>
      alphabetically(a.subscriptionName, b.subscriptionName),
    );
  },
);

export const getActiveSubscriptions = memoize.maxOne(
  (subscriptions: ReadonlyArray<ISubscription>): ReadonlyArray<ISubscription> =>
    subscriptions.filter((subscription) => subscription.isActive),
);

export const getExpiredSubscriptions = memoize.maxOne(
  (subscriptions: ReadonlyArray<ISubscription>): ReadonlyArray<ISubscription> =>
    subscriptions.filter(isSubscriptionExpired),
);

export const getSubscriptionAdministrators = (
  administrators: Immutable.Map<Uuid, ReadonlyArray<ISubscriptionAdmin>>,
  subscriptionId: Uuid,
): ReadonlyArray<ISubscriptionAdmin> => {
  return administrators.get(subscriptionId) || [];
};

const getSubscriptionAvailableProjectLocationsMemoized = memoize.maxOne(
  (
    allowedProjectLocationIds: ReadonlyArray<Uuid>,
    availableProjectLocations: ReadonlyArray<IProjectLocation>,
  ): ReadonlyArray<IProjectLocation> => {
    const predicate: (loc: IProjectLocation) => boolean =
      allowedProjectLocationIds.length === 0
        ? (loc) => !loc.isDedicated
        : (loc) => allowedProjectLocationIds.includes(loc.projectLocationId);

    return availableProjectLocations.filter(predicate);
  },
);

export function getSubscriptionAvailableProjectLocations(
  state: IStore,
  subscriptionId: Uuid,
): ReadonlyArray<IProjectLocation> {
  const {
    sharedApp: { availableProjectLocations },
  } = state;

  const subscription = getSubscription(state, subscriptionId);
  const { allowedProjectLocationIds } = subscription.subscriptionDetails;

  return getSubscriptionAvailableProjectLocationsMemoized(
    allowedProjectLocationIds,
    availableProjectLocations,
  );
}

export function getSubscriptionDefaultProjectLocation(
  state: IStore,
  subscriptionId: Uuid,
): IProjectLocation | null {
  const availableProjectLocations = getSubscriptionAvailableProjectLocations(state, subscriptionId);
  return (
    availableProjectLocations.find((loc: IProjectLocation) => loc.isDefault) ??
    availableProjectLocations[0] ??
    null
  );
}

export const getSubscriptionActiveProjectsCount = (state: IStore, subscriptionId: Uuid): number => {
  const {
    data: { projects },
  } = state;

  const subscriptionProjects = projects.byId.filter(
    (project) =>
      project?.subscriptionId === subscriptionId &&
      !project?.inactive &&
      project.projectId === project.masterEnvironmentId,
  );
  return subscriptionProjects.size;
};
