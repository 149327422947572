import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import {
  Spacing,
  borderWidthDefault,
  colorBorderLowEmphasis,
  px,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  ContentItemsAppRootRoute,
  ContentItemsAppRootRouteParams,
  ContentItemsAppRouteSegment,
} from '../../../../../../_shared/constants/routePaths.ts';
import { useIsUnifiedWebSpotlightEnabled } from '../../../../../../_shared/hooks/useIsUnifiedWebSpotlightEnabled.tsx';
import { matchPath } from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { ContentDetailsButton } from '../statusBar/ContentDetailsButton.tsx';
import { EditingActions } from './EditingActions.tsx';
import { PreviewToggle } from './PreviewToggle.tsx';

export const EditingActionsBar = () => {
  const isUnifiedWebSpotlightEnabled = useIsUnifiedWebSpotlightEnabled();
  const isInContentItemEditingApp =
    matchPath<ContentItemsAppRootRouteParams>(location.pathname, ContentItemsAppRootRoute)?.app ===
    ContentItemsAppRouteSegment.Content;

  return (
    <Container>
      <Row alignY="center" noWrap spacingX={Spacing.M}>
        {isInContentItemEditingApp && isUnifiedWebSpotlightEnabled && (
          <Column width="content">
            <PreviewToggle />
          </Column>
        )}
        <Column>
          <EditingActions />
        </Column>
        {isInContentItemEditingApp && (
          <Column width="content">
            <ContentDetailsButton />
          </Column>
        )}
      </Row>
    </Container>
  );
};

const Container = ({ children }: React.PropsWithChildren) => (
  <Box
    css={`
        grid-area: editing-actions;
        border-bottom: ${px(borderWidthDefault)} solid ${colorBorderLowEmphasis};
    `}
    paddingX={Spacing.XL}
    paddingY={Spacing.S}
  >
    {children}
  </Box>
);
