import { areShallowEqual } from '@kontent-ai/utils';
import { forwardRef } from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import {
  getRichTextAssetCommentThreads,
  getUnresolvedRichTextAssetCommentThreads,
} from '../../../selectors/inlineCommentSelectors.ts';
import { AssetTile, IAssetTileOwnProps } from './AssetTile.tsx';

export type RichTextAssetTileProps = Omit<
  IAssetTileOwnProps,
  'commentThreadId' | 'hasFocusedComment'
> & {
  readonly contentComponentId: Uuid | null;
};

export const RichTextAssetTile = forwardRef<HTMLDivElement, RichTextAssetTileProps>(
  (props, ref) => {
    const { commentSegmentId, contentComponentId, elementId } = props;

    const { firstUnresolvedThreadId, hasFocusedComment } = useSelector((state) => {
      const threads = commentSegmentId
        ? getRichTextAssetCommentThreads(
            state.contentApp.editedContentItemVariantComments.commentThreads,
            commentSegmentId,
            elementId,
            contentComponentId ?? null,
          )
        : undefined;
      const unresolvedThreads =
        commentSegmentId && threads
          ? getUnresolvedRichTextAssetCommentThreads(
              threads,
              commentSegmentId,
              elementId,
              contentComponentId ?? null,
            )
          : undefined;
      const focusedCommentThreadId =
        state.contentApp.editedContentItemVariantComments.focusedCommentThreadId;

      return {
        firstUnresolvedThreadId: unresolvedThreads?.[0]?.id ?? null,
        hasFocusedComment:
          !!focusedCommentThreadId &&
          !!threads?.some((thread) => thread.id === focusedCommentThreadId),
      };
    }, areShallowEqual);

    return (
      <AssetTile
        commentThreadId={firstUnresolvedThreadId}
        hasFocusedComment={hasFocusedComment}
        ref={ref}
        isFocusable={false}
        {...props}
      />
    );
  },
);

RichTextAssetTile.displayName = 'RichTextAssetTile';
