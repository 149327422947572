import { Action } from '../../../../../@types/Action.type.ts';
import { Content_Editing_CurrentItemOverwritten } from '../../../constants/editorActionTypes.ts';
import {
  ContentItemEditing_ContentComponentConvertedToItemNotification_Close,
  ContentItemEditing_ConvertingContentComponent_Finished,
  ContentItemEditing_ItemName_Changed,
} from '../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: ReadonlyArray<Uuid> = [];

export function convertedItemIds(state = initialState, action: Action): ReadonlyArray<Uuid> {
  switch (action.type) {
    case ContentItemEditing_ConvertingContentComponent_Finished: {
      return [...state, action.payload.itemId];
    }
    case ContentItemEditing_ItemName_Changed:
    case Content_Editing_CurrentItemOverwritten:
    case ContentItemEditing_ContentComponentConvertedToItemNotification_Close: {
      const itemId = action.payload.itemId;
      return state.filter((id) => id !== itemId);
    }

    default:
      return state;
  }
}
