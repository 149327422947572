import { IconName, Icons } from '@kontent-ai/component-library/Icons';
import { Menu } from '@kontent-ai/component-library/Menu';
import React from 'react';
import {
  Color,
  DropDownOptionBadge,
} from '../../../../../../../_shared/uiComponents/DropDown/DropDownOptionBadge.tsx';
import { ObjectWithDataAttribute } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export type ActionsMenuItemProps = {
  readonly badgeColor?: Color;
  readonly badgeValue?: number;
  readonly dataAttributes?: ObjectWithDataAttribute;
  readonly disabled?: boolean;
  readonly iconName: IconName;
  readonly id: string;
  readonly isDestructive?: boolean;
  readonly isSlim?: boolean;
  readonly onClick: () => void;
  readonly outwardLinkUrl?: string;
  readonly text: string;
  readonly tooltip?: string;
};

export const ActionsMenuItem: React.FC<ActionsMenuItemProps> = ({
  badgeColor,
  badgeValue,
  dataAttributes,
  disabled,
  iconName,
  isDestructive,
  id,
  onClick,
  outwardLinkUrl,
  text,
  tooltip,
}) => {
  const Icon = Icons[iconName];
  const Component = outwardLinkUrl ? Menu.OutwardLink : Menu.Item;

  const getMenuItemState = () => {
    if (disabled) {
      return 'disabled';
    }
    if (isDestructive) {
      return 'destructive';
    }
    return 'default';
  };

  return (
    <Component
      menuItemState={getMenuItemState()}
      href={outwardLinkUrl}
      label={text}
      tooltipText={tooltip}
      id={id}
      onAction={onClick}
      leadingElement={<Icon className="actions-menu-item__icon" />}
      trailingElements={
        !!badgeValue &&
        !!badgeColor && <DropDownOptionBadge badgeColor={badgeColor} badgeValue={badgeValue} />
      }
      {...dataAttributes}
    />
  );
};

ActionsMenuItem.displayName = 'ActionsMenuItem';
