import { useIdWithPrefix } from '@kontent-ai/hooks';
import React, { useEffect, useState } from 'react';
import { FullScreenModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/FullScreenModalDialog.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { createTaskFromServerModel } from '../../../../../_shared/models/Task.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { MissionControlRepository } from '../../../repositories/MissionControlRepository.type.ts';
import { StyledDataTable } from '../../shared/components/StyledDataTable.tsx';
import { Widget } from '../../shared/components/Widget.tsx';
import { WidgetListingContentShowMoreButton } from '../../shared/components/WidgetListingContentShowMoreButton.tsx';
import { WidgetListingStateContent } from '../../shared/components/WidgetListingStateContent.tsx';
import {
  entityListDetailDisplayCount,
  entityListWidgetDisplayCount,
} from '../../shared/constants/widgetConstants.ts';
import { TaskListWidgetDetailProps } from '../../shared/containers/TaskWidgetDetailTableLiveContainer.tsx';
import { useWidgetListingDataFetcher } from '../../shared/hooks/useWidgetListingDataFetcher.tsx';
import { TasksAssignedByYouWidgetRow } from '../containers/TasksAssignedByYouWidgetRow.tsx';
import { WidgetEmptyState } from './WidgetEmptyState.tsx';

type MostOverdueTasksWidgetProps = Readonly<{
  onDetailItemClick?: () => void;
  onShowMoreClick?: () => void;
  tasksFetcher: MissionControlRepository['getTasksCreatedByCurrentUser'];
  WidgetDetail: React.FC<TaskListWidgetDetailProps>;
}>;

export const TasksAssignedByYouWidget: React.FC<MostOverdueTasksWidgetProps> = ({
  onDetailItemClick,
  onShowMoreClick,
  tasksFetcher,
  WidgetDetail,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const {
    fetchInit,
    fetchMore,
    state: tasksState,
    data: tasks,
  } = useWidgetListingDataFetcher(tasksFetcher, createTaskFromServerModel);

  useEffect(() => fetchInit(entityListWidgetDisplayCount).cancel, [fetchInit]);

  const openDetail = () => {
    onShowMoreClick?.();
    fetchMore(entityListDetailDisplayCount);
    setIsDialogOpen(true);
  };

  const closeDetail = () => setIsDialogOpen(false);

  const widgetTitleId = useIdWithPrefix('tasks-by-you');

  return (
    <>
      <Widget>
        <Widget.Title text="Tasks assigned by you" id={widgetTitleId} />
        <WidgetListingStateContent state={tasksState} renderEmptyState={() => <WidgetEmptyState />}>
          <Widget.Body>
            <StyledDataTable
              dataUiCollectionName={DataUiCollection.ContentTasks}
              header={<DataTableHeadRow columns={widgetTableHeadColumns} />}
              ariaLabelledBy={widgetTitleId}
            >
              {tasks.slice(0, entityListWidgetDisplayCount).map((task) => (
                <TasksAssignedByYouWidgetRow
                  key={task.id}
                  task={task}
                  rowFocusLinkAriaLabel={`visit content item with task — ${task.description}`}
                />
              ))}
            </StyledDataTable>
          </Widget.Body>

          <Widget.Footer>
            <Widget.CenterContent>
              <WidgetListingContentShowMoreButton
                onClick={openDetail}
                ariaDescribedBy={widgetTitleId}
              />
            </Widget.CenterContent>
          </Widget.Footer>
        </WidgetListingStateContent>
      </Widget>

      <FullScreenModalDialog
        headline="Tasks assigned by you"
        isDismissable
        isOpen={isDialogOpen}
        onClose={closeDetail}
      >
        <WidgetDetail onItemClick={onDetailItemClick} state={tasksState} tasks={tasks} />
      </FullScreenModalDialog>
    </>
  );
};

const widgetTableHeadColumns: ReadonlyArray<Column> = [
  {
    columnName: 'Task',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--2',
  },
  {
    columnName: 'Assigned to',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--1',
  },
  {
    columnName: 'Due date',
    orderBy: OrderByDirection.Descending,
    className: 'data-table__column--1',
  },
];

TasksAssignedByYouWidget.displayName = 'TasksAssignedByYouWidget';
