import React from 'react';
import { ModalDialogType } from '../../../../../../../../_shared/constants/modalDialogType.ts';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { ContentItemEditingEventTypes } from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { compose } from '../../../../../../../../_shared/utils/func/compose.ts';
import { openEditingActionsDialog } from '../../../../actions/thunks/openEditingActionsDialog.ts';
import {
  EditingActionDynamicDetailProvider,
  EditingActionDynamicDetailProviderOwnProps,
} from '../../../../components/editingActions/actions/EditingActionDynamicDetailProvider.tsx';

export const ViewCodenameAction: React.FC<EditingActionDynamicDetailProviderOwnProps> = ({
  children,
  ...props
}) => {
  const dispatch = useDispatch();
  const performAction = compose(
    dispatch,
    openEditingActionsDialog(
      ContentItemEditingEventTypes.CodenameSelected,
      ModalDialogType.ContentItemCodenameDialog,
    ),
  );

  return (
    <EditingActionDynamicDetailProvider {...props} performAction={performAction}>
      {children}
    </EditingActionDynamicDetailProvider>
  );
};
