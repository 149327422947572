import classNames from 'classnames';
import React, { useRef } from 'react';
import { useScrollOnDragEvents } from '../../../../../_shared/hooks/useScrollOnDragEvents.ts';
import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import {
  DataUiCollection,
  getDataUiActiveCapabilitiesDataAttributes,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ICompiledContentType } from '../../../../contentInventory/content/models/CompiledContentType.ts';
import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { CommentThreadHighlighter } from '../containers/comments/CommentThreadHighlighter.tsx';
import { FocusedCommentThreadHighlighter } from '../containers/comments/FocusedCommentThreadHighlighter.tsx';
import { FocusedCommentThreadScroller } from '../containers/comments/FocusedCommentThreadScroller.tsx';
import { ContentItemElementsAutoDispatcher } from '../containers/elements/ContentItemElementsAutoDispatcher.tsx';
import { usePreviewContext } from '../context/PreviewContextProvider.tsx';
import { ContentItemElementGroup } from './ContentItemElementGroup.tsx';
import { ContentItemElementGroupCompact } from './ContentItemElementGroupCompact.tsx';
import { NoElementsEmptyState } from './NoElementsEmptyState.tsx';
import { NoElementsEmptyStateCompact } from './NoElementsEmptyStateCompact.tsx';

type ContentItemElementsProps = Readonly<{
  activeCapabilities: ReadonlyArray<ActiveCapabilityType>;
  areAnyContentGroupsVisible: boolean;
  canUpdateContent: boolean;
  contentType: ICompiledContentType;
  disabled: boolean;
  isCollectionInMainPaneEnabled: boolean;
  selectedLanguageIsoCodename: string | null;
  typeElements: ReadonlyArray<TypeElement>;
  workflowStepId: Uuid;
}>;

export const ContentItemElements = ({
  activeCapabilities,
  areAnyContentGroupsVisible,
  canUpdateContent,
  disabled,
  isCollectionInMainPaneEnabled,
  selectedLanguageIsoCodename,
  workflowStepId,
  typeElements,
}: ContentItemElementsProps) => {
  const canEdit = !disabled && canUpdateContent;

  const { isPreviewOpened } = usePreviewContext();

  const elementsRef = useRef<HTMLDivElement | null>(null);
  useScrollOnDragEvents(elementsRef);

  return (
    <React.Fragment key={workflowStepId}>
      <CommentThreadHighlighter />
      <ContentItemElementsAutoDispatcher />
      <div
        className={classNames('content-item-pane__elements-list', {
          'content-item-pane__elements-list--is-disabled': !canEdit,
          'content-item-pane__elements-list--top-spacing':
            !isPreviewOpened && !areAnyContentGroupsVisible && isCollectionInMainPaneEnabled,
        })}
        {...getDataUiCollectionAttribute(DataUiCollection.ContentElements)}
        {...getDataUiActiveCapabilitiesDataAttributes(activeCapabilities)}
        lang={selectedLanguageIsoCodename || undefined}
        ref={elementsRef}
      >
        {isPreviewOpened ? (
          <CompactView canEdit={canEdit} typeElements={typeElements} />
        ) : (
          <NormalView
            canEdit={canEdit}
            hasTopRoundedCorners={areAnyContentGroupsVisible || isCollectionInMainPaneEnabled}
            typeElements={typeElements}
          />
        )}
      </div>
      <FocusedCommentThreadHighlighter />
      {/* After elements due to auto-scroll - KCL-1453 */}
      <FocusedCommentThreadScroller />
    </React.Fragment>
  );
};

type NormalViewProps = Readonly<{
  canEdit: boolean;
  hasTopRoundedCorners: boolean;
  typeElements: ReadonlyArray<TypeElement>;
}>;

const NormalView = ({ canEdit, hasTopRoundedCorners, typeElements }: NormalViewProps) => (
  <>
    {typeElements.length === 0 ? (
      <NoElementsEmptyState canEdit={canEdit} />
    ) : (
      <ContentItemElementGroup
        canEdit={canEdit}
        typeElements={typeElements}
        hasTopRoundedCorners={hasTopRoundedCorners}
        hasBottomRoundedCorners
      />
    )}
  </>
);

type CompactViewProps = Readonly<{
  canEdit: boolean;
  typeElements: ReadonlyArray<TypeElement>;
}>;

const CompactView = ({ canEdit, typeElements }: CompactViewProps) => (
  <>
    {typeElements.length === 0 ? (
      <NoElementsEmptyStateCompact canEdit={canEdit} />
    ) : (
      <ContentItemElementGroupCompact canEdit={canEdit} typeElements={typeElements} />
    )}
  </>
);
