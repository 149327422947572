import { SpacesInDeliver, UnifiedWebSpotlight } from './ProjectFeatureFlagsServerKeys.ts';

export type ProjectFeatureFlagValue = boolean;

export type ProjectFeatureFlagsModel = {
  SpacesInDeliver: ProjectFeatureFlagValue;
  UnifiedWebSpotlight: ProjectFeatureFlagValue;
};

export const ProjectFeatureFlagsStateKeysByServerKeys: Dictionary<keyof ProjectFeatureFlagsModel> =
  {
    [SpacesInDeliver]: 'SpacesInDeliver',
    [UnifiedWebSpotlight]: 'UnifiedWebSpotlight',
  };
