import { assert, Collection } from '@kontent-ai/utils';
import { Location } from 'history';
import { buildAssetRoutePath } from '../../../applications/contentInventory/assets/utils/assetsPathUtils.ts';
import { RedactedItemName } from '../../../applications/contentInventory/content/constants/uiConstants.ts';
import {
  BreadcrumbsOriginItem,
  IBreadcrumbsOrigin,
} from '../../../applications/contentInventory/shared/reducers/IContentInventoryStoreState.type.ts';
import { UntitledEntityWebhookName } from '../../../applications/entityWebhooks/constants/entityWebhookConstants.ts';
import { InnovationLabBreadcrumbResolverInfo } from '../../../applications/environmentSettings/innovationLab/constants/routePaths.ts';
import { EnvironmentSettingsAppNames } from '../../../applications/environmentSettings/root/constants/EnvironmentSettingsAppNames.ts';
import { ProjectSettingsAppNames } from '../../../applications/projectSettings/root/constants/ProjectSettingsAppNames.ts';
import {
  ApiKeyListingRouteSegment,
  DeliveryKeysRouteSegment,
  EnvironmentsRouteSegment,
  GeneralRouteSegment,
  ManagementKeysRouteSegment,
  PersonalManagementKeyRouteSegment,
  TokenSeedIdSegment,
} from '../../../applications/projectSettings/root/constants/routeSegments.ts';
import { SubscriptionTabName } from '../../../applications/subscriptionManagement/shared/constants/subscriptionTabName.ts';
import { CustomApp } from '../../../data/models/customApps/CustomApp.ts';
import { IListingContentItem } from '../../../data/models/listingContentItems/IListingContentItem.ts';
import { IListingContentItems } from '../../../data/reducers/listingContentItems/IListingContentItems.type.ts';
import { IAssetResponseServerModel } from '../../../repositories/serverModels/AssetServerModels.type.ts';
import { IEntityWebhookServerModel } from '../../../repositories/serverModels/IEntityWebhookServerModel.type.ts';
import { IProjectDetailsServerModel } from '../../../repositories/serverModels/ProjectDetailsServerModel.type.ts';
import { IAdministratedSubscriptionsServerModel } from '../../../repositories/serverModels/SubscriptionServerModel.type.ts';
import { IWebhookServerModel } from '../../../repositories/serverModels/WebhookServerModel.ts';
import { ITaxonomyGroupServerModel } from '../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import { ContentTypeServerModel } from '../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ContentTypeSnippetServerModel } from '../../../repositories/serverModels/contentModels/contentTypeSnippetServerModels.type.ts';
import { AppNames } from '../../constants/applicationNames.ts';
import {
  AdministratorsRoute,
  ApiKeyDetailRouteParams,
  AssetListingRoute,
  AssetRoute,
  AssetRouteParams,
  AuditLogRoute,
  BillingHistoryRoute,
  BillingInformationRoute,
  CollectionsRoute,
  ContentItemEditorRouteParams,
  ContentItemPreviewRoute,
  ContentItemPreviewWithEditorRoute,
  ContentItemRoute,
  ContentItemRouteParams,
  ContentItemsAppRouteSegment,
  ContentItemsRoute,
  ContentItemsRouteParams,
  ContentPaceRoute,
  ContentStatusRoute,
  ContentTypeSnippetsRoute,
  ContentTypesRoute,
  CustomAppConfigurationCreatingRoute,
  CustomAppConfigurationEditingRoute,
  CustomAppConfigurationEditingRouteParams,
  CustomAppDetailRoute,
  CustomAppDetailRouteParams,
  CustomAppsConfigurationRoute,
  CustomAppsRoute,
  EditContentTypeRoute,
  EditContentTypeRouteParams,
  EditContentTypeSnippetRoute,
  EditContentTypeSnippetRouteParams,
  EditTaxonomyGroupRoute,
  EditTaxonomyGroupRouteParams,
  EnsuredSubscriptionRoute,
  EnsuredSubscriptionRouteParams,
  EnvironmentRoute,
  EnvironmentRouteParams,
  EnvironmentsRoute,
  GeneralEnvironmentSettingsRoute,
  LegacyWebhookMessagesRoute,
  LegacyWebhookMessagesRouteParams,
  LegacyWebhooksRoute,
  LocalizationRoute,
  MissionControlCalendarRoute,
  MissionControlDashboardRoute,
  MissionControlProjectOverviewRoute,
  MissionControlQuickstartRoute,
  MissionControlYourWorkRoute,
  PlanSelectionRoute,
  PreviewURLsRoute,
  ProjectSettingsRoute,
  ProjectsInSubscriptionRoute,
  ProjectsRoute,
  RelationsEntryRoute,
  RoleCreatorRoute,
  RoleEditorRoute,
  RoleEditorRouteParams,
  RolesRoute,
  SpacesRoute,
  SubscriptionApiKeysRoute,
  SubscriptionEnvironmentAuditLogRoute,
  SubscriptionEnvironmentCollectionsRoute,
  SubscriptionEnvironmentCustomAppConfigurationCreatingRoute,
  SubscriptionEnvironmentCustomAppConfigurationEditingRoute,
  SubscriptionEnvironmentCustomAppConfigurationEditingRouteParams,
  SubscriptionEnvironmentCustomAppsConfigurationRoute,
  SubscriptionEnvironmentEnvironmentsRoute,
  SubscriptionEnvironmentGeneralSettingsRoute,
  SubscriptionEnvironmentLegacyWebhookMessagesRoute,
  SubscriptionEnvironmentLegacyWebhooksRoute,
  SubscriptionEnvironmentLocalizationRoute,
  SubscriptionEnvironmentPreviewURLsRoute,
  SubscriptionEnvironmentRoleCreatorRoute,
  SubscriptionEnvironmentRoleEditorRoute,
  SubscriptionEnvironmentRoleEditorRouteParams,
  SubscriptionEnvironmentRolesRoute,
  SubscriptionEnvironmentSettingsRoute,
  SubscriptionEnvironmentSettingsRouteParams,
  SubscriptionEnvironmentSpacesRoute,
  SubscriptionEnvironmentUsersRoute,
  SubscriptionEnvironmentWebhookMessagesRoute,
  SubscriptionEnvironmentWebhooksCreatingRoute,
  SubscriptionEnvironmentWebhooksEditingRoute,
  SubscriptionEnvironmentWebhooksEditorRouteParams,
  SubscriptionEnvironmentWebhooksRoute,
  SubscriptionEnvironmentWorkflowCreatingRoute,
  SubscriptionEnvironmentWorkflowEditingRoute,
  SubscriptionEnvironmentWorkflowEditingRouteParams,
  SubscriptionEnvironmentWorkflowsRoute,
  SubscriptionGeneralRoute,
  SubscriptionOverviewRoute,
  SubscriptionUsersRoute,
  SubscriptionsRoute,
  TaxonomyGroupsRoute,
  TransactionsRoute,
  UserProfileRoute,
  UsersRoute,
  WebSpotlightContentRoute,
  WebSpotlightRootRoute,
  WebhookEditorRouteParams,
  WebhookMessagesRoute,
  WebhookMessagesRouteParams,
  WebhooksCreatingRoute,
  WebhooksEditingRoute,
  WebhooksRoute,
  WorkflowCreatingRoute,
  WorkflowEditingRoute,
  WorkflowEditingRouteParams,
  WorkflowsRoute,
} from '../../constants/routePaths.ts';
import { VariantIdMacro } from '../../constants/variantIdValues.ts';
import { Breadcrumb } from '../../models/Breadcrumb.type.ts';
import { ActiveCapabilityType } from '../../models/activeCapability.type.ts';
import { repositoryCollection } from '../../repositories/repositories.ts';
import { logError } from '../logError.ts';
import { hasActiveVariantCapability } from '../permissions/activeCapabilities.ts';
import {
  buildPath,
  convertMatchPathParamsToBuildPathParams,
  matchExactPath,
  matchPath,
  parseContentItemIds,
} from '../routing/routeTransitionUtils.ts';
import { renderTaxonomyGroupName } from '../taxonomies/taxonomyUtils.ts';
import { uuidRegex } from '../validation/typeValidators.ts';
import { getPreviouslyOpenAssetFolderPath } from './getPreviouslyOpenAssetFolderPath.ts';

type BreadcrumbResolver = (
  location: Location,
  abortSignal: AbortSignal,
) => Promise<ReadonlyArray<Breadcrumb>>;

export interface IBreadcrumbResolverInfo {
  readonly pattern: RegExp;
  readonly resolver?: BreadcrumbResolver;
  readonly childResolvers: ReadonlyArray<IBreadcrumbResolverInfo>;
}

const {
  apiKeysRepository,
  assetRepository,
  contentTypeRepository,
  contentTypeSnippetRepository,
  projectRepository,
  roleRepository,
  workflowRepository,
  subscriptionRepository,
  taxonomyRepository,
  webhookRepository,
  entityWebhookRepository,
} = repositoryCollection;

const createBreadcrumb = (
  title: string,
  path: string,
  useProjectIdPrefix: boolean = false,
): Breadcrumb => ({
  title,
  path,
  useProjectIdPrefix,
});

const createBreadcrumbResolverInternal = (
  title: string,
  path: string,
  useProjectIdPrefix: boolean,
): BreadcrumbResolver => {
  if (!title || !path) {
    throw new Error('You must provide `title` and `path` arguments to create breadcrumb resolver');
  }

  return (location: Location) => {
    const match = matchPath(location.pathname, path);
    const buildParams = convertMatchPathParamsToBuildPathParams(match);
    const breadcrumb = createBreadcrumb(title, buildPath(path, buildParams), useProjectIdPrefix);
    const breadcrumbs = [breadcrumb];

    return Promise.resolve(breadcrumbs);
  };
};

const createBreadcrumbResolverWithProjectIdPrefix = (
  title: string,
  path: string,
): BreadcrumbResolver => createBreadcrumbResolverInternal(title, path, true);

const createBreadcrumbResolver = (title: string, path: string): BreadcrumbResolver =>
  createBreadcrumbResolverInternal(title, path, false);

const ContentAppBySegment: ReadonlyRecord<string, AppNames> = {
  [ContentItemsAppRouteSegment.Content]: AppNames.ContentItems,
  [ContentItemsAppRouteSegment.WebSpotlight]: AppNames.WebSpotlight,
} as const;

const createContentItemBreadcrumb = (item: IListingContentItem, path: string) => {
  const canViewItem = hasActiveVariantCapability(ActiveCapabilityType.ViewContent, item);

  return createBreadcrumb(canViewItem ? item.item.name : RedactedItemName, path);
};

function getContentItemBreadcrumbs(
  getContentItemsById: (
    contentItemIds: UuidArray,
  ) => ReadonlyArray<IListingContentItem | undefined>,
  location: Location,
  breadcrumbsOrigin: IBreadcrumbsOrigin,
): ReadonlyArray<Breadcrumb> {
  const previewMatch = matchPath<ContentItemEditorRouteParams<string>>(location.pathname, {
    path: [ContentItemPreviewWithEditorRoute, ContentItemPreviewRoute],
  });
  const match =
    previewMatch ||
    matchPath<ContentItemEditorRouteParams<string>>(location.pathname, ContentItemRoute);
  if (!match) {
    return [];
  }

  const routeContentItemIds = parseContentItemIds(match.contentItemIds);

  // Do not repeat the last item if the item for floating editor is the same as the last item in the preview path
  const contentItemIds =
    match.editedItemId && match.editedItemId !== routeContentItemIds[routeContentItemIds.length - 1]
      ? [...routeContentItemIds, match.editedItemId]
      : routeContentItemIds;

  const contentItems = getContentItemsById(contentItemIds);
  const breadcrumbs = contentItems.reduce(
    (reducedBreadcrumbs: ReadonlyArray<Breadcrumb>, item: IListingContentItem | undefined) => {
      if (!item) {
        return reducedBreadcrumbs;
      }

      const doesVariantExist = !!item.variant && !item.variant.isArchived;

      // Not translated variant needs to redirect to the editor to make sure the user gets the correct UI and prompt
      // for copying from existing language after the variant is automatically created, otherwise preserve preview
      const isInPreview = !!previewMatch;
      const contentItemRoute =
        doesVariantExist && isInPreview ? ContentItemPreviewRoute : ContentItemRoute;

      const lastBreadcrumb = Collection.getLast(reducedBreadcrumbs);
      if (lastBreadcrumb) {
        const nextContentItemPathMatch = matchPath<ContentItemRouteParams<string>>(
          lastBreadcrumb.path,
          ContentItemRoute,
        );
        if (nextContentItemPathMatch) {
          const nextContentItemPath = buildPath<ContentItemRouteParams<UuidArray>>(
            contentItemRoute,
            {
              app: nextContentItemPathMatch.app,
              projectId: nextContentItemPathMatch.projectId,
              variantId: VariantIdMacro,
              spaceId: nextContentItemPathMatch.spaceId,
              contentItemIds: [
                ...parseContentItemIds(nextContentItemPathMatch.contentItemIds),
                item.item.id,
              ],
            },
          );
          return [...reducedBreadcrumbs, createContentItemBreadcrumb(item, nextContentItemPath)];
        }
      } else {
        const contentItemsRouteMatch = matchPath<ContentItemsRouteParams>(
          location.pathname,
          ContentItemsRoute,
        );
        if (!contentItemsRouteMatch) {
          return reducedBreadcrumbs;
        }

        const firstContentItemPath = buildPath<ContentItemRouteParams<UuidArray>>(
          contentItemRoute,
          {
            app: contentItemsRouteMatch.app,
            projectId: contentItemsRouteMatch.projectId,
            variantId: VariantIdMacro,
            spaceId: contentItemsRouteMatch.spaceId,
            contentItemIds: [item.item.id],
          },
        );
        const contentItemBreadcrumb = createContentItemBreadcrumb(item, firstContentItemPath);

        if (breadcrumbsOrigin.items.length) {
          return [
            ...breadcrumbsOrigin.items.map((originItem: BreadcrumbsOriginItem) =>
              createBreadcrumb(originItem.title, originItem.path),
            ),
            contentItemBreadcrumb,
          ];
        }

        const contentItemsPath = buildPath<ContentItemsRouteParams>(ContentItemsRoute, {
          app: contentItemsRouteMatch.app,
          projectId: contentItemsRouteMatch.projectId,
          variantId: VariantIdMacro,
          spaceId: contentItemsRouteMatch.spaceId,
        });

        const title = ContentAppBySegment[contentItemsRouteMatch.app];
        if (title) {
          return [
            ...reducedBreadcrumbs,
            createBreadcrumb(title, contentItemsPath),
            contentItemBreadcrumb,
          ];
        }
      }
      return reducedBreadcrumbs;
    },
    [],
  );
  return breadcrumbs;
}

interface IContentItemBreadcrumbResolverDeps {
  readonly breadcrumbsOrigin: IBreadcrumbsOrigin;
  readonly getContentItemsByIds: (
    contentItemIds: UuidArray,
  ) => ReadonlyArray<IListingContentItem | undefined>;
}

const createContentItemBreadcrumbResolver =
  ({
    breadcrumbsOrigin,
    getContentItemsByIds,
  }: IContentItemBreadcrumbResolverDeps): BreadcrumbResolver =>
  (location: Location) =>
    Promise.resolve(getContentItemBreadcrumbs(getContentItemsByIds, location, breadcrumbsOrigin));

async function contentTypeBreadcrumbResolverFunction(
  getContentType: (typeId: Uuid, abortSignal: AbortSignal) => Promise<ContentTypeServerModel>,
  location: Location,
  abortSignal: AbortSignal,
): Promise<ReadonlyArray<Breadcrumb>> {
  const match = matchPath<EditContentTypeRouteParams>(location.pathname, EditContentTypeRoute);
  if (!match) {
    return [];
  }
  const contentType = await getContentType(match.contentTypeId, abortSignal);
  const contentTypesPath = buildPath<EnvironmentRouteParams>(ContentTypesRoute, {
    projectId: match.projectId,
  });
  const contentTypeEditPath = buildPath<EditContentTypeRouteParams>(EditContentTypeRoute, {
    projectId: match.projectId,
    contentTypeId: contentType._id,
  });
  return [
    createBreadcrumb(AppNames.ContentTypes, contentTypesPath),
    createBreadcrumb(contentType.name, contentTypeEditPath),
  ];
}

type NewObjectBreadcrumbResolverConfiguration = {
  readonly newObjectName: string;
  readonly objectListingRoute: string;
  readonly objectListingAppName: string;
  readonly projectRoute: string;
};

const createNewObjectBreadcrumbResolverFunction =
  <T extends AnyObject>(config: NewObjectBreadcrumbResolverConfiguration) =>
  (location: Location): Promise<ReadonlyArray<Breadcrumb>> => {
    return new Promise((resolve) => {
      const match = matchPath<T>(location.pathname, config.projectRoute);
      if (match) {
        const objectListingPath = buildPath<T>(config.objectListingRoute, match);
        resolve([
          createBreadcrumb(config.objectListingAppName, objectListingPath),
          createBreadcrumb(config.newObjectName, objectListingPath),
        ]);
      } else {
        resolve([]);
      }
    });
  };

const newContentTypeBreadcrumbResolver =
  createNewObjectBreadcrumbResolverFunction<EnvironmentRouteParams>({
    objectListingAppName: AppNames.ContentTypes,
    objectListingRoute: ContentTypesRoute,
    projectRoute: EnvironmentRoute,
    newObjectName: 'New content type',
  });

const newContentTypeSnippetBreadcrumbResolver =
  createNewObjectBreadcrumbResolverFunction<EnvironmentRouteParams>({
    objectListingAppName: AppNames.ContentTypeSnippets,
    objectListingRoute: ContentTypeSnippetsRoute,
    projectRoute: EnvironmentRoute,
    newObjectName: 'New content type snippet',
  });

const createContentTypeBreadcrumbResolver =
  (
    getContentType: (typeId: Uuid, abortSignal: AbortSignal) => Promise<ContentTypeServerModel>,
  ): BreadcrumbResolver =>
  (location: Location, abortSignal: AbortSignal) =>
    contentTypeBreadcrumbResolverFunction(getContentType, location, abortSignal);

const contentTypeSnippetBreadcrumbResolverFunction = async (
  getContentTypeSnippet: (
    typeSnippetId: Uuid,
    abortSignal: AbortSignal,
  ) => Promise<ContentTypeSnippetServerModel>,
  location: Location,
  abortSignal: AbortSignal,
): Promise<ReadonlyArray<Breadcrumb>> => {
  const match = matchPath<EditContentTypeSnippetRouteParams>(
    location.pathname,
    EditContentTypeSnippetRoute,
  );
  if (!match) {
    return [];
  }
  const contentTypeSnippet = await getContentTypeSnippet(match.contentTypeSnippetId, abortSignal);
  const contentTypeSnippetsPath = buildPath<EnvironmentRouteParams>(ContentTypeSnippetsRoute, {
    projectId: match.projectId,
  });
  const contentTypeSnippetEditPath = buildPath<EditContentTypeSnippetRouteParams>(
    EditContentTypeSnippetRoute,
    {
      projectId: match.projectId,
      contentTypeSnippetId: contentTypeSnippet._id,
    },
  );
  return [
    createBreadcrumb(AppNames.ContentTypeSnippets, contentTypeSnippetsPath),
    createBreadcrumb(contentTypeSnippet.name, contentTypeSnippetEditPath),
  ];
};

const createContentTypeSnippetBreadcrumbResolver =
  (
    getContentTypeSnippet: (
      typeSnippetId: Uuid,
      abortSignal: AbortSignal,
    ) => Promise<ContentTypeSnippetServerModel>,
  ): BreadcrumbResolver =>
  (location: Location, abortSignal: AbortSignal) =>
    contentTypeSnippetBreadcrumbResolverFunction(getContentTypeSnippet, location, abortSignal);

const assetBreadcrumbResolverFunction = async (
  getAsset: (assetId: Uuid, abortSignal: AbortSignal) => Promise<IAssetResponseServerModel>,
  location: Location,
  breadcrumbsOrigin: IBreadcrumbsOrigin,
  abortSignal: AbortSignal,
): Promise<ReadonlyArray<Breadcrumb>> => {
  const match = matchPath<AssetRouteParams>(location.pathname, AssetRoute);
  if (!match) {
    return [];
  }

  const asset = await getAsset(match.assetId, abortSignal);
  const assetsPath = getPreviouslyOpenAssetFolderPath(
    breadcrumbsOrigin,
    match.projectId,
    asset.folderId,
  );

  const assetPath = buildAssetRoutePath({
    projectId: match.projectId,
    assetId: asset._id,
  });

  return [
    createBreadcrumb(AppNames.Assets, assetsPath),
    createBreadcrumb(asset.title || asset.filename || '', assetPath),
  ];
};

const createAssetBreadcrumbResolver =
  (
    getAsset: (assetId: Uuid, abortSignal: AbortSignal) => Promise<IAssetResponseServerModel>,
    breadcrumbsOrigin: IBreadcrumbsOrigin,
  ): BreadcrumbResolver =>
  (location: Location, abortSignal: AbortSignal) =>
    assetBreadcrumbResolverFunction(getAsset, location, breadcrumbsOrigin, abortSignal);

const newTaxonomyBreadcrumbResolver =
  createNewObjectBreadcrumbResolverFunction<EnvironmentRouteParams>({
    objectListingAppName: AppNames.Taxonomies,
    objectListingRoute: TaxonomyGroupsRoute,
    projectRoute: EnvironmentRoute,
    newObjectName: 'New taxonomy',
  });

const taxonomyBreadcrumbResolverFunction = async (
  getTaxonomyGroup: (
    taxonomyGroupId: Uuid,
    abortSignal: AbortSignal,
  ) => Promise<ITaxonomyGroupServerModel>,
  location: Location,
  abortSignal: AbortSignal,
): Promise<ReadonlyArray<Breadcrumb>> => {
  const match = matchPath<EditTaxonomyGroupRouteParams>(location.pathname, EditTaxonomyGroupRoute);
  if (!match) {
    return [];
  }
  const taxonomyGroup = await getTaxonomyGroup(match.taxonomyGroupId, abortSignal);
  const taxonomyGroupsPath = buildPath<EnvironmentRouteParams>(TaxonomyGroupsRoute, {
    projectId: match.projectId,
  });
  const editTaxonomyGroupPath = buildPath<EditTaxonomyGroupRouteParams>(EditTaxonomyGroupRoute, {
    projectId: match.projectId,
    taxonomyGroupId: taxonomyGroup.id,
  });
  return [
    createBreadcrumb('Taxonomy groups', taxonomyGroupsPath),
    createBreadcrumb(renderTaxonomyGroupName(taxonomyGroup), editTaxonomyGroupPath),
  ];
};

const createTaxonomyBreadcrumbResolver =
  (
    getTaxonomyGroup: (
      taxonomyGroupId: Uuid,
      abortSignal: AbortSignal,
    ) => Promise<ITaxonomyGroupServerModel>,
  ): BreadcrumbResolver =>
  (location: Location, abortSignal: AbortSignal) =>
    taxonomyBreadcrumbResolverFunction(getTaxonomyGroup, location, abortSignal);

const subscriptionBreadcrumbResolverFunction = async (
  getAdministratedSubscriptions: (
    abortSignal: AbortSignal,
  ) => Promise<IAdministratedSubscriptionsServerModel>,
  location: Location,
  abortSignal: AbortSignal,
): Promise<ReadonlyArray<Breadcrumb>> => {
  const match = matchPath<EnsuredSubscriptionRouteParams>(
    location.pathname,
    EnsuredSubscriptionRoute,
  );
  if (!match) {
    return [];
  }
  const { subscriptions } = await getAdministratedSubscriptions(abortSignal);
  if (!subscriptions) {
    return [];
  }
  const subscription = subscriptions.find((s) => s.subscriptionId === match.subscriptionId);
  if (!subscription) {
    return [];
  }
  const subscriptionDetailPath = buildPath<EnsuredSubscriptionRouteParams>(
    EnsuredSubscriptionRoute,
    { subscriptionId: subscription.subscriptionId },
  );
  return [createBreadcrumb(subscription.subscriptionName || '', subscriptionDetailPath)];
};

const subscriptionProjectsBreadcrumbResolverFunction = async (
  getProjects: (abortSignal: AbortSignal) => Promise<Array<IProjectDetailsServerModel>>,
  location: Location,
  abortSignal: AbortSignal,
): Promise<ReadonlyArray<Breadcrumb>> => {
  const subscriptionMatch = matchPath<EnsuredSubscriptionRouteParams>(
    location.pathname,
    ProjectsInSubscriptionRoute,
  );
  if (!subscriptionMatch) {
    return [];
  }

  const projectsBreadcrumb = createBreadcrumb(
    SubscriptionTabName.Projects,
    buildPath<EnsuredSubscriptionRouteParams>(ProjectsInSubscriptionRoute, subscriptionMatch),
  );

  const projectMatch = matchPath<SubscriptionEnvironmentSettingsRouteParams>(
    location.pathname,
    SubscriptionEnvironmentSettingsRoute,
  );
  if (!projectMatch) {
    return [projectsBreadcrumb];
  }

  const projects = await getProjects(abortSignal);
  if (!projects) {
    return [projectsBreadcrumb];
  }
  const project = projects.find((p) => p.projectId === projectMatch.projectId);
  if (!project) {
    return [projectsBreadcrumb];
  }

  return [
    projectsBreadcrumb,
    createBreadcrumb(
      project.projectContainerName || '',
      buildPath<SubscriptionEnvironmentSettingsRouteParams>(SubscriptionEnvironmentSettingsRoute, {
        subscriptionId: projectMatch.subscriptionId,
        projectId: projectMatch.projectId,
      }),
    ),
  ];
};

const createSubscriptionBreadcrumbResolver =
  (
    getAdministratedSubscriptions: (
      abortSignal: AbortSignal,
    ) => Promise<IAdministratedSubscriptionsServerModel>,
  ): BreadcrumbResolver =>
  (location: Location, abortSignal: AbortSignal) =>
    subscriptionBreadcrumbResolverFunction(getAdministratedSubscriptions, location, abortSignal);

const createSubscriptionProjectsBreadcrumbResolver =
  (
    getProjects: (abortSignal: AbortSignal) => Promise<Array<IProjectDetailsServerModel>>,
  ): BreadcrumbResolver =>
  (location: Location, abortSignal: AbortSignal) =>
    subscriptionProjectsBreadcrumbResolverFunction(getProjects, location, abortSignal);

export const replaceLanguageMacroInBreadcrumbs = <T extends { path: string }>(
  breadcrumbs: ReadonlyArray<T>,
  selectedLanguage: Uuid,
): ReadonlyArray<T> =>
  breadcrumbs.map((breadcrumb: T) => ({
    ...breadcrumb,
    path: breadcrumb.path.replace(VariantIdMacro, selectedLanguage),
  }));

const contentTypeBreadcrumbResolver = createContentTypeBreadcrumbResolver((contentTypeId) =>
  contentTypeRepository.getContentType(contentTypeId),
);
const contentTypeSnippetBreadcrumbResolver = createContentTypeSnippetBreadcrumbResolver(
  (contentTypeSnippetId) =>
    contentTypeSnippetRepository.getContentTypeSnippet(contentTypeSnippetId),
);
const subscriptionBreadcrumbResolver = createSubscriptionBreadcrumbResolver(() =>
  subscriptionRepository.getAdministratedSubscriptions(),
);
const subscriptionProjectsBreadcrumbResolver = createSubscriptionProjectsBreadcrumbResolver(() =>
  projectRepository.getMyProjects(),
);
const taxonomyGroupBreadcrumbResolver = createTaxonomyBreadcrumbResolver((taxonomyGroupId) =>
  taxonomyRepository.getTaxonomyGroup(taxonomyGroupId),
);

const usersRouteResolver = createBreadcrumbResolver(EnvironmentSettingsAppNames.Users, UsersRoute);
const spacesRouteResolver = createBreadcrumbResolver(
  EnvironmentSettingsAppNames.Spaces,
  SpacesRoute,
);
const collectionsRouteResolver = createBreadcrumbResolver(
  EnvironmentSettingsAppNames.Collections,
  CollectionsRoute,
);
const localizationRouteResolver = createBreadcrumbResolver(
  EnvironmentSettingsAppNames.Localization,
  LocalizationRoute,
);
const generalSettingsRouteResolver = createBreadcrumbResolver(
  EnvironmentSettingsAppNames.General,
  GeneralEnvironmentSettingsRoute,
);
const environmentsRouteResolver = createBreadcrumbResolver(
  ProjectSettingsAppNames.Environments,
  EnvironmentsRoute,
);
const previewUrlsRouteResolver = createBreadcrumbResolver(
  EnvironmentSettingsAppNames.PreviewConfiguration,
  PreviewURLsRoute,
);
const webhooksRouteResolver = createBreadcrumbResolver(
  EnvironmentSettingsAppNames.Webhooks,
  WebhooksRoute,
);
const legacyWebhooksRouteResolver = createBreadcrumbResolver(
  EnvironmentSettingsAppNames.LegacyWebhooks,
  LegacyWebhooksRoute,
);
const auditLogRouteResolver = createBreadcrumbResolver(
  EnvironmentSettingsAppNames.AuditLog,
  AuditLogRoute,
);

const subscriptionOverviewRouteResolver = createBreadcrumbResolver(
  SubscriptionTabName.Usage,
  SubscriptionOverviewRoute,
);
const subscriptionGeneralRouteResolver = createBreadcrumbResolver(
  SubscriptionTabName.General,
  SubscriptionGeneralRoute,
);
const billingHistoryRouteResolver = createBreadcrumbResolver(
  SubscriptionTabName.BillingHistory,
  BillingHistoryRoute,
);
const billingInformationRouteResolver = createBreadcrumbResolver(
  SubscriptionTabName.BillingInfo,
  BillingInformationRoute,
);
const planSelectionRouteResolver = createBreadcrumbResolver(
  SubscriptionTabName.Plans,
  PlanSelectionRoute,
);
const transactionsRouteResolver = createBreadcrumbResolver(
  SubscriptionTabName.Transactions,
  TransactionsRoute,
);
const administratorsRouteResolver = createBreadcrumbResolver(
  SubscriptionTabName.Admins,
  AdministratorsRoute,
);
const subscriptionUsersRouteResolver = createBreadcrumbResolver(
  SubscriptionTabName.Users,
  SubscriptionUsersRoute,
);
const subscriptionApiKeysRouteResolver = createBreadcrumbResolver(
  SubscriptionTabName.ApiKeys,
  SubscriptionApiKeysRoute,
);

const subscriptionProjectsUserRouteResolver = createBreadcrumbResolver(
  EnvironmentSettingsAppNames.Users,
  SubscriptionEnvironmentUsersRoute,
);
const subscriptionProjectsSpacesRouteResolver = createBreadcrumbResolver(
  EnvironmentSettingsAppNames.Spaces,
  SubscriptionEnvironmentSpacesRoute,
);
const subscriptionProjectsCollectionsRouteResolver = createBreadcrumbResolver(
  EnvironmentSettingsAppNames.Collections,
  SubscriptionEnvironmentCollectionsRoute,
);
const subscriptionProjectsLocalizationRouteResolver = createBreadcrumbResolver(
  EnvironmentSettingsAppNames.Localization,
  SubscriptionEnvironmentLocalizationRoute,
);
const subscriptionProjectsGeneralSettingsRouteResolver = createBreadcrumbResolver(
  EnvironmentSettingsAppNames.General,
  SubscriptionEnvironmentGeneralSettingsRoute,
);
const subscriptionProjectsEnvironmentsRouteResolver = createBreadcrumbResolver(
  ProjectSettingsAppNames.Environments,
  SubscriptionEnvironmentEnvironmentsRoute,
);
const subscriptionProjectsPreviewUrlRouteResolver = createBreadcrumbResolver(
  EnvironmentSettingsAppNames.PreviewConfiguration,
  SubscriptionEnvironmentPreviewURLsRoute,
);
const subscriptionProjectsWebhooksRouteResolver = createBreadcrumbResolver(
  EnvironmentSettingsAppNames.Webhooks,
  SubscriptionEnvironmentWebhooksRoute,
);
const subscriptionProjectsLegacyWebhooksRouteResolver = createBreadcrumbResolver(
  EnvironmentSettingsAppNames.LegacyWebhooks,
  SubscriptionEnvironmentLegacyWebhooksRoute,
);
const subscriptionProjectsAuditLogRouteResolver = createBreadcrumbResolver(
  EnvironmentSettingsAppNames.AuditLog,
  SubscriptionEnvironmentAuditLogRoute,
);

const createWebhookMessagesBreadcrumbResolver =
  (): BreadcrumbResolver =>
  async (location: Location, abortSignal: AbortSignal): Promise<ReadonlyArray<Breadcrumb>> => {
    const match =
      matchPath<WebhookMessagesRouteParams>(location.pathname, WebhookMessagesRoute) ||
      matchPath<WebhookMessagesRouteParams>(
        location.pathname,
        SubscriptionEnvironmentWebhookMessagesRoute,
      );
    if (!match) {
      return [];
    }
    try {
      const webhooks = await entityWebhookRepository.getAll(abortSignal);
      const selectedWebhook = webhooks.find(
        (webhook: IEntityWebhookServerModel) => webhook.id === match.webhookId,
      );
      if (!selectedWebhook) {
        return [];
      }
      return [createBreadcrumb(`${selectedWebhook.name} debugging`, location.pathname)];
    } catch {
      logError(
        `${__filename}: Cannot get webhook with id: ${match.webhookId} (might have been archived)`,
      );
      return [];
    }
  };

const webhookMessagesRouteResolver = createWebhookMessagesBreadcrumbResolver();

const createLegacyWebhookMessagesBreadcrumbResolver =
  (): BreadcrumbResolver =>
  async (location: Location, abortSignal: AbortSignal): Promise<ReadonlyArray<Breadcrumb>> => {
    const match =
      matchPath<LegacyWebhookMessagesRouteParams>(location.pathname, LegacyWebhookMessagesRoute) ||
      matchPath<LegacyWebhookMessagesRouteParams>(
        location.pathname,
        SubscriptionEnvironmentLegacyWebhookMessagesRoute,
      );
    if (!match) {
      return [];
    }
    const webhooks = await webhookRepository.getAll(abortSignal);
    const selectedWebhook = webhooks.find(
      (webhook: IWebhookServerModel) => webhook.id === match.webhookId,
    );
    if (!selectedWebhook) {
      return [];
    }

    try {
      return [createBreadcrumb(selectedWebhook.name, location.pathname)];
    } catch {
      logError(
        `${__filename}: Cannot get webhook with id: ${match.webhookId} (might have been archived)`,
      );
      return [];
    }
  };

const legacyWebhookMessagesRouteResolver = createLegacyWebhookMessagesBreadcrumbResolver();

const newRoleName = 'New role';

const rolesRouteResolver = createBreadcrumbResolver(EnvironmentSettingsAppNames.Roles, RolesRoute);
const newRoleBreadcrumbResolver = createBreadcrumbResolver(newRoleName, RoleCreatorRoute);

const subscriptionProjectsRolesRouteResolver = createBreadcrumbResolver(
  EnvironmentSettingsAppNames.Roles,
  SubscriptionEnvironmentRolesRoute,
);
const subscriptionProjectsNewRoleBreadcrumbResolver = createBreadcrumbResolver(
  newRoleName,
  SubscriptionEnvironmentRoleCreatorRoute,
);

type EditedRoleBreadcrumbResolverConfig = {
  route: string;
};
const createEditedRoleBreadcrumbResolver =
  <RouteParams extends { roleId: Uuid }>(config: EditedRoleBreadcrumbResolverConfig) =>
  async (location: Location, abortSignal: AbortSignal): Promise<ReadonlyArray<Breadcrumb>> => {
    const routeMatch = matchPath<RouteParams>(location.pathname, config.route);

    assert(
      routeMatch?.roleId,
      () => 'Resolving role editor breadcrumb for a route that does not match!',
    );

    try {
      const editedRole = await roleRepository.getRole(routeMatch.roleId, abortSignal);

      return [createBreadcrumb(editedRole.name || '', location.pathname)];
    } catch {
      logError(
        `${__filename}: Cannot get role with id: ${routeMatch.roleId} (might have been archived)`,
      );
      return [];
    }
  };

const editedRoleBreadcrumbResolver = createEditedRoleBreadcrumbResolver<RoleEditorRouteParams>({
  route: RoleEditorRoute,
});

const subscriptionProjectsRoleBreadcrumbResolver =
  createEditedRoleBreadcrumbResolver<SubscriptionEnvironmentRoleEditorRouteParams>({
    route: SubscriptionEnvironmentRoleEditorRoute,
  });

const newWorkflowName = 'New workflow';
const newWorkflowBreadcrumbResolver = createBreadcrumbResolver(
  newWorkflowName,
  WorkflowCreatingRoute,
);
const subscriptionProjectsNewWorkflowBreadcrumbResolver = createBreadcrumbResolver(
  newWorkflowName,
  SubscriptionEnvironmentWorkflowCreatingRoute,
);

type WorkflowsBreadcrumbResolverConfig = {
  route: string;
};

interface IWorkflowBreadcrumbResolverDeps {
  readonly isMultipleWorkflowsConfigurationAllowed: boolean;
}

const createWorkflowsBreadcrumbResolver =
  (config: WorkflowsBreadcrumbResolverConfig) =>
  ({ isMultipleWorkflowsConfigurationAllowed }: IWorkflowBreadcrumbResolverDeps) =>
  async (location: Location, abortSignal: AbortSignal): Promise<ReadonlyArray<Breadcrumb>> => {
    return isMultipleWorkflowsConfigurationAllowed
      ? await createBreadcrumbResolver(EnvironmentSettingsAppNames.MultipleWorkflows, config.route)(
          location,
          abortSignal,
        )
      : [];
  };

const workflowsBreadcrumbResolver = createWorkflowsBreadcrumbResolver({
  route: WorkflowsRoute,
});

const subscriptionWorkflowsBreadcrumbResolver = createWorkflowsBreadcrumbResolver({
  route: SubscriptionEnvironmentWorkflowsRoute,
});

type EditedWorkflowBreadcrumbResolverConfig = {
  route: string;
};

const createEditedWorkflowBreadcrumbResolver =
  <RouteParams extends { workflowId: Uuid }>(config: EditedWorkflowBreadcrumbResolverConfig) =>
  ({ isMultipleWorkflowsConfigurationAllowed }: IWorkflowBreadcrumbResolverDeps) =>
  async (location: Location, abortSignal: AbortSignal): Promise<ReadonlyArray<Breadcrumb>> => {
    if (!isMultipleWorkflowsConfigurationAllowed) {
      return [createBreadcrumb('Workflow', location.pathname)];
    }

    const routeMatch = matchPath<RouteParams>(location.pathname, config.route);

    assert(
      routeMatch?.workflowId,
      () => 'Resolving workflow editor breadcrumb for a route that does not match!',
    );

    try {
      const editedWorkflow = await workflowRepository.get(routeMatch.workflowId, abortSignal);

      return [createBreadcrumb(editedWorkflow.name || '', location.pathname)];
    } catch {
      logError(
        `${__filename}: Cannot get workflow with id: ${routeMatch.workflowId} (might have been archived)`,
      );
      return [];
    }
  };

const editedWorkflowBreadcrumbResolver =
  createEditedWorkflowBreadcrumbResolver<WorkflowEditingRouteParams>({
    route: WorkflowEditingRoute,
  });

const subscriptionProjectsWorkflowBreadcrumbResolver =
  createEditedWorkflowBreadcrumbResolver<SubscriptionEnvironmentWorkflowEditingRouteParams>({
    route: SubscriptionEnvironmentWorkflowEditingRoute,
  });

const newWebhookBreadcrumbResolver = createBreadcrumbResolver(
  UntitledEntityWebhookName,
  WebhooksCreatingRoute,
);
const subscriptionProjectsNewWebhookBreadcrumbResolver = createBreadcrumbResolver(
  UntitledEntityWebhookName,
  SubscriptionEnvironmentWebhooksCreatingRoute,
);

type EditedEntityWebhookBreadcrumbResolverConfig = {
  route: string;
};

const createEditedWebhookBreadcrumbResolver =
  <RouteParams extends { webhookId: Uuid }>(config: EditedEntityWebhookBreadcrumbResolverConfig) =>
  async (location: Location, abortSignal: AbortSignal): Promise<ReadonlyArray<Breadcrumb>> => {
    const routeMatch = matchPath<RouteParams>(location.pathname, config.route);
    assert(
      routeMatch?.webhookId,
      () => 'Resolving webhook editor breadcrumb for a route that does not match!',
    );

    try {
      const editedEntityWebhook = await entityWebhookRepository.get(
        routeMatch.webhookId,
        abortSignal,
      );
      return [createBreadcrumb(editedEntityWebhook.name || '', location.pathname)];
    } catch {
      logError(
        `${__filename}: Cannot get entity webhook with id: ${routeMatch.webhookId} (might have been archived)`,
      );
      return [];
    }
  };

const editedWebhookBreadcrumbResolver =
  createEditedWebhookBreadcrumbResolver<WebhookEditorRouteParams>({
    route: WebhooksEditingRoute,
  });

const subscriptionProjectsWebhookBreadcrumbResolver =
  createEditedWebhookBreadcrumbResolver<SubscriptionEnvironmentWebhooksEditorRouteParams>({
    route: SubscriptionEnvironmentWebhooksEditingRoute,
  });

const createEditedApiKeyBreadcrumbResolver =
  <RouteParams extends ApiKeyDetailRouteParams>(config: {
    route: string;
  }) =>
  async (location: Location, abortSignal: AbortSignal): Promise<ReadonlyArray<Breadcrumb>> => {
    const routeMatch = matchPath<RouteParams>(location.pathname, config.route);

    assert(
      routeMatch?.tokenSeedId,
      () => 'Resolving api key editor breadcrumb for a route that does not match!',
    );

    try {
      const apiKey = await apiKeysRepository.getApiKey(routeMatch.tokenSeedId, abortSignal);

      return [createBreadcrumb(apiKey.name || '', location.pathname)];
    } catch {
      return [createBreadcrumb('', location.pathname)];
    }
  };

const editedPersonalManagementApiKeyBreadcrumbResolver =
  createEditedApiKeyBreadcrumbResolver<ApiKeyDetailRouteParams>({
    route: `${ProjectSettingsRoute}/${ApiKeyListingRouteSegment}/${ManagementKeysRouteSegment}/${PersonalManagementKeyRouteSegment}/${TokenSeedIdSegment}`,
  });

const editedManagementApiKeyBreadcrumbResolver =
  createEditedApiKeyBreadcrumbResolver<ApiKeyDetailRouteParams>({
    route: `${ProjectSettingsRoute}/${ApiKeyListingRouteSegment}/${ManagementKeysRouteSegment}/${TokenSeedIdSegment}`,
  });

const editedDeliveryApiKeyBreadcrumbResolver =
  createEditedApiKeyBreadcrumbResolver<ApiKeyDetailRouteParams>({
    route: `${ProjectSettingsRoute}/${ApiKeyListingRouteSegment}/${DeliveryKeysRouteSegment}/${TokenSeedIdSegment}`,
  });

const createParentResolverForExactlyMatchingChildBreadcrumbResolver =
  (appName: AppNames, path: string) => (routeToMatch: string) => {
    const matchingResolver = createBreadcrumbResolverWithProjectIdPrefix(appName, path);

    return (location: Location, abortSignal: AbortSignal): Promise<ReadonlyArray<Breadcrumb>> =>
      matchExactPath<ContentItemRouteParams<string>>(location.pathname, routeToMatch)
        ? matchingResolver(location, abortSignal)
        : Promise.resolve([]);
  };

const webSpotlightChildResolver = createParentResolverForExactlyMatchingChildBreadcrumbResolver(
  AppNames.WebSpotlight,
  `/${ContentItemsAppRouteSegment.WebSpotlight}`,
);
const webSpotlightContentBreadcrumbResolver = webSpotlightChildResolver(WebSpotlightContentRoute);
const webSpotlightBreadcrumbResolver = webSpotlightChildResolver(WebSpotlightRootRoute);

const contentInventoryContentChildResolver =
  createParentResolverForExactlyMatchingChildBreadcrumbResolver(
    AppNames.Content,
    `/${ContentItemsAppRouteSegment.Content}/${VariantIdMacro}`,
  );
const contentBreadcrumbResolver = contentInventoryContentChildResolver(ContentItemsRoute);

const contentInventoryAssetsChildResolver =
  createParentResolverForExactlyMatchingChildBreadcrumbResolver(
    AppNames.Content,
    '/content-inventory/assets',
  );
const assetsBreadcrumbResolver = contentInventoryAssetsChildResolver(AssetListingRoute);

const contentInventoryRelationsChildResolver =
  createParentResolverForExactlyMatchingChildBreadcrumbResolver(
    AppNames.Content,
    '/content-inventory/relations',
  );
const relationsBreadcrumbResolver = contentInventoryRelationsChildResolver(RelationsEntryRoute);

const contentModelsExactChildResolver =
  createParentResolverForExactlyMatchingChildBreadcrumbResolver(
    AppNames.ContentModels,
    '/content-models',
  );
const contentTypesBreadcrumbResolver = contentModelsExactChildResolver(ContentTypesRoute);
const contentTypeSnippetsBreadcrumbResolver =
  contentModelsExactChildResolver(ContentTypeSnippetsRoute);
const taxonomyGroupsBreadcrumbResolver = contentModelsExactChildResolver(TaxonomyGroupsRoute);

const customAppsRouteResolver = createBreadcrumbResolver(AppNames.CustomApps, CustomAppsRoute);

type CustomAppDetailBreadcrumbResolverConfig = {
  route: string;
};

const createCustomAppDetailBreadcrumbResolver =
  <RouteParams extends { customAppId: Uuid }>(config: CustomAppDetailBreadcrumbResolverConfig) =>
  (customApps: ReadonlyArray<CustomApp>) =>
  async (location: Location): Promise<ReadonlyArray<Breadcrumb>> => {
    const routeMatch = matchPath<RouteParams>(location.pathname, config.route);

    assert(
      routeMatch?.customAppId,
      () => 'Resolving custom app detail breadcrumb for a route that does not match!',
    );

    try {
      const customApp = customApps.find((app) => app.id === routeMatch.customAppId);

      return [createBreadcrumb(customApp?.name || '', location.pathname)];
    } catch {
      logError(
        `${__filename}: Cannot get custom app with id: ${routeMatch.customAppId} (might have been archived)`,
      );
      return [];
    }
  };

const customAppDetailBreadcrumbResolver =
  createCustomAppDetailBreadcrumbResolver<CustomAppDetailRouteParams>({
    route: CustomAppDetailRoute,
  });

const customAppsConfigurationRouteResolver = createBreadcrumbResolver(
  EnvironmentSettingsAppNames.CustomApps,
  CustomAppsConfigurationRoute,
);

const subscriptionProjectsCustomAppConfigurationRouteResolver = createBreadcrumbResolver(
  EnvironmentSettingsAppNames.CustomApps,
  SubscriptionEnvironmentCustomAppsConfigurationRoute,
);

const newCustomAppName = 'New custom app';
const newCustomAppConfigurationBreadcrumbResolver = createBreadcrumbResolver(
  newCustomAppName,
  CustomAppConfigurationCreatingRoute,
);
const subscriptionProjectsNewCustomAppConfigurationBreadcrumbResolver = createBreadcrumbResolver(
  newCustomAppName,
  SubscriptionEnvironmentCustomAppConfigurationCreatingRoute,
);

type EditedCustomAppConfigurationBreadcrumbResolverConfig = {
  route: string;
};

const createEditedCustomAppConfigurationBreadcrumbResolver =
  <RouteParams extends { customAppId: Uuid }>(
    config: EditedCustomAppConfigurationBreadcrumbResolverConfig,
  ) =>
  (customApps: ReadonlyArray<CustomApp>) =>
  async (location: Location): Promise<ReadonlyArray<Breadcrumb>> => {
    const routeMatch = matchPath<RouteParams>(location.pathname, config.route);

    assert(
      routeMatch?.customAppId,
      () => 'Resolving custom app editor breadcrumb for a route that does not match!',
    );

    try {
      const editedCustomApp = customApps.find((app) => app.id === routeMatch.customAppId);

      return [createBreadcrumb(editedCustomApp?.name || '', location.pathname)];
    } catch {
      logError(
        `${__filename}: Cannot get custom app with id: ${routeMatch.customAppId} (might have been archived)`,
      );
      return [];
    }
  };

const editedCustomAppConfigurationBreadcrumbResolver =
  createEditedCustomAppConfigurationBreadcrumbResolver<CustomAppConfigurationEditingRouteParams>({
    route: CustomAppConfigurationEditingRoute,
  });

const subscriptionProjectsCustomAppConfigurationBreadcrumbResolver =
  createEditedCustomAppConfigurationBreadcrumbResolver<SubscriptionEnvironmentCustomAppConfigurationEditingRouteParams>(
    {
      route: SubscriptionEnvironmentCustomAppConfigurationEditingRoute,
    },
  );

export interface IBreadcrumbResolverDependencies
  extends IWorkflowBreadcrumbResolverDeps,
    Pick<IContentItemBreadcrumbResolverDeps, 'breadcrumbsOrigin'> {
  readonly listingContentItems: IListingContentItems;
  readonly customApps: ReadonlyArray<CustomApp>;
}

export const createBreadcrumbResolvers = ({
  listingContentItems,
  customApps,
  isMultipleWorkflowsConfigurationAllowed,
  breadcrumbsOrigin,
}: IBreadcrumbResolverDependencies): ReadonlyArray<IBreadcrumbResolverInfo> => {
  const getContentItemsByIds = (
    contentItemIds: UuidArray,
  ): ReadonlyArray<IListingContentItem | undefined> => {
    return contentItemIds.map((contentItemId) => listingContentItems.byId.get(contentItemId));
  };

  return [
    {
      pattern: /^user-profile$/,
      resolver: createBreadcrumbResolver(AppNames.UserProfile, UserProfileRoute),
      childResolvers: [],
    },
    {
      pattern: /^projects$/,
      childResolvers: [
        {
          pattern: /^active$/,
          resolver: createBreadcrumbResolver(AppNames.Projects, ProjectsRoute),
          childResolvers: [],
        },
        {
          pattern: /^archived$/,
          resolver: createBreadcrumbResolver(AppNames.Projects, ProjectsRoute),
          childResolvers: [],
        },
        {
          pattern: uuidRegex, // projectContainerId
          childResolvers: [
            {
              pattern: /^settings$/,
              resolver: createBreadcrumbResolver(AppNames.ProjectSettings, ProjectSettingsRoute),
              childResolvers: [
                {
                  pattern: new RegExp(`^${GeneralRouteSegment}$`),
                  resolver: createBreadcrumbResolver(
                    ProjectSettingsAppNames.General,
                    `${ProjectSettingsRoute}/${GeneralRouteSegment}`,
                  ),
                  childResolvers: [],
                },
                {
                  pattern: new RegExp(`^${EnvironmentsRouteSegment}$`),
                  resolver: createBreadcrumbResolver(
                    ProjectSettingsAppNames.Environments,
                    `${ProjectSettingsRoute}/${EnvironmentsRouteSegment}`,
                  ),
                  childResolvers: [],
                },
                {
                  pattern: new RegExp(`^${ApiKeyListingRouteSegment}$`),
                  resolver: createBreadcrumbResolver(
                    ProjectSettingsAppNames.ApiKeyListing,
                    `${ProjectSettingsRoute}/${ApiKeyListingRouteSegment}`,
                  ),
                  childResolvers: [
                    {
                      pattern: new RegExp(`^${DeliveryKeysRouteSegment}$`),
                      resolver: createBreadcrumbResolver(
                        ProjectSettingsAppNames.DeliveryKeys,
                        `${ProjectSettingsRoute}/${ApiKeyListingRouteSegment}/${DeliveryKeysRouteSegment}`,
                      ),
                      childResolvers: [
                        {
                          pattern: uuidRegex,
                          resolver: editedDeliveryApiKeyBreadcrumbResolver,
                          childResolvers: [],
                        },
                      ],
                    },
                    {
                      pattern: new RegExp(`^${ManagementKeysRouteSegment}$`),
                      resolver: createBreadcrumbResolver(
                        ProjectSettingsAppNames.ManagementKeys,
                        `${ProjectSettingsRoute}/${ApiKeyListingRouteSegment}/${ManagementKeysRouteSegment}`,
                      ),
                      childResolvers: [
                        {
                          pattern: new RegExp(`^${PersonalManagementKeyRouteSegment}$`),
                          childResolvers: [
                            {
                              pattern: uuidRegex,
                              resolver: editedPersonalManagementApiKeyBreadcrumbResolver,
                              childResolvers: [],
                            },
                          ],
                        },
                        {
                          pattern: uuidRegex,
                          resolver: editedManagementApiKeyBreadcrumbResolver,
                          childResolvers: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      pattern: /^subscription$/,
      resolver: createBreadcrumbResolver(AppNames.Subscriptions, SubscriptionsRoute),
      childResolvers: [
        {
          pattern: uuidRegex,
          resolver: subscriptionBreadcrumbResolver,
          childResolvers: [
            {
              pattern: /^overview$/,
              resolver: subscriptionOverviewRouteResolver,
              childResolvers: [],
            },
            {
              pattern: /^general$/,
              resolver: subscriptionGeneralRouteResolver,
              childResolvers: [],
            },
            {
              pattern: /^billing-history$/,
              resolver: billingHistoryRouteResolver,
              childResolvers: [],
            },
            {
              pattern: /^billing-information$/,
              resolver: billingInformationRouteResolver,
              childResolvers: [],
            },
            {
              pattern: /^plan-selection$/,
              resolver: planSelectionRouteResolver,
              childResolvers: [],
            },
            {
              pattern: /^transactions$/,
              resolver: transactionsRouteResolver,
              childResolvers: [],
            },
            {
              pattern: /^administrators$/,
              resolver: administratorsRouteResolver,
              childResolvers: [],
            },
            {
              pattern: /^users$/,
              resolver: subscriptionUsersRouteResolver,
              childResolvers: [],
            },
            {
              pattern: /^api-keys$/,
              resolver: subscriptionApiKeysRouteResolver,
              childResolvers: [],
            },
            {
              pattern: /^projects$/,
              resolver: subscriptionProjectsBreadcrumbResolver,
              childResolvers: [
                {
                  pattern: uuidRegex,
                  childResolvers: [
                    {
                      pattern: /^settings$/,
                      childResolvers: [
                        {
                          pattern: /^general$/,
                          resolver: subscriptionProjectsGeneralSettingsRouteResolver,
                          childResolvers: [],
                        },
                        {
                          pattern: /^users$/,
                          resolver: subscriptionProjectsUserRouteResolver,
                          childResolvers: [],
                        },
                        {
                          pattern: /^roles$/,
                          resolver: subscriptionProjectsRolesRouteResolver,
                          childResolvers: [
                            {
                              pattern: /^new$/,
                              resolver: subscriptionProjectsNewRoleBreadcrumbResolver,
                              childResolvers: [],
                            },
                            {
                              pattern: /^edit$/,
                              childResolvers: [
                                {
                                  pattern: uuidRegex,
                                  resolver: subscriptionProjectsRoleBreadcrumbResolver,
                                  childResolvers: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          pattern: /^environments$/,
                          resolver: subscriptionProjectsEnvironmentsRouteResolver,
                          childResolvers: [],
                        },
                        {
                          pattern: /^workflows$/,
                          resolver: subscriptionWorkflowsBreadcrumbResolver({
                            isMultipleWorkflowsConfigurationAllowed,
                          }),
                          childResolvers: [
                            {
                              pattern: /^new$/,
                              resolver: subscriptionProjectsNewWorkflowBreadcrumbResolver,
                              childResolvers: [],
                            },
                            {
                              pattern: /^edit$/,
                              childResolvers: [
                                {
                                  pattern: uuidRegex,
                                  resolver: subscriptionProjectsWorkflowBreadcrumbResolver({
                                    isMultipleWorkflowsConfigurationAllowed,
                                  }),
                                  childResolvers: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          pattern: /^spaces$/,
                          resolver: subscriptionProjectsSpacesRouteResolver,
                          childResolvers: [],
                        },
                        {
                          pattern: /^collections$/,
                          resolver: subscriptionProjectsCollectionsRouteResolver,
                          childResolvers: [],
                        },
                        {
                          pattern: /^localization$/,
                          resolver: subscriptionProjectsLocalizationRouteResolver,
                          childResolvers: [],
                        },
                        {
                          pattern: /^preview-urls$/,
                          resolver: subscriptionProjectsPreviewUrlRouteResolver,
                          childResolvers: [],
                        },
                        {
                          pattern: /^webhooks$/,
                          resolver: subscriptionProjectsWebhooksRouteResolver,
                          childResolvers: [
                            {
                              pattern: /^new$/,
                              resolver: subscriptionProjectsNewWebhookBreadcrumbResolver,
                              childResolvers: [],
                            },
                            {
                              pattern: /^edit$/,
                              childResolvers: [
                                {
                                  pattern: uuidRegex,
                                  resolver: subscriptionProjectsWebhookBreadcrumbResolver,
                                  childResolvers: [],
                                },
                              ],
                            },
                            {
                              pattern: uuidRegex, // webhook id
                              childResolvers: [
                                {
                                  pattern: /^messages$/,
                                  resolver: webhookMessagesRouteResolver,
                                  childResolvers: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          pattern: /^legacy-webhooks$/,
                          resolver: subscriptionProjectsLegacyWebhooksRouteResolver,
                          childResolvers: [
                            {
                              pattern: uuidRegex, // webhook id
                              childResolvers: [
                                {
                                  pattern: /^messages$/,
                                  resolver: legacyWebhookMessagesRouteResolver,
                                  childResolvers: [],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          pattern: /^audit-log$/,
                          resolver: subscriptionProjectsAuditLogRouteResolver,
                          childResolvers: [],
                        },
                        {
                          pattern: /^custom-apps$/,
                          resolver: subscriptionProjectsCustomAppConfigurationRouteResolver,
                          childResolvers: [
                            {
                              pattern: /^new$/,
                              resolver:
                                subscriptionProjectsNewCustomAppConfigurationBreadcrumbResolver,
                              childResolvers: [],
                            },
                            {
                              pattern: /^edit$/,
                              childResolvers: [
                                {
                                  pattern: uuidRegex,
                                  resolver:
                                    subscriptionProjectsCustomAppConfigurationBreadcrumbResolver(
                                      customApps,
                                    ),
                                  childResolvers: [],
                                },
                              ],
                            },
                          ],
                        },
                        InnovationLabBreadcrumbResolverInfo,
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      pattern: uuidRegex, // projectId
      childResolvers: [
        {
          pattern: /^mission-control$/,
          resolver: createBreadcrumbResolverWithProjectIdPrefix(
            AppNames.MissionControl,
            '/mission-control',
          ),
          childResolvers: [
            {
              pattern: /^quickstart$/,
              resolver: createBreadcrumbResolver(
                AppNames.Quickstart,
                MissionControlQuickstartRoute,
              ),
              childResolvers: [],
            },
            {
              pattern: /^your-work$/,
              resolver: createBreadcrumbResolver(AppNames.YourWork, MissionControlYourWorkRoute),
              childResolvers: [],
            },
            {
              pattern: /^dashboard$/,
              resolver: createBreadcrumbResolver(AppNames.Dashboard, MissionControlDashboardRoute),
              childResolvers: [],
            },
            {
              pattern: /^content-status$/,
              resolver: createBreadcrumbResolver(AppNames.ContentStatus, ContentStatusRoute),
              childResolvers: [],
            },
            {
              pattern: /^content-pace$/,
              resolver: createBreadcrumbResolver(AppNames.ContentPace, ContentPaceRoute),
              childResolvers: [],
            },
            {
              pattern: uuidRegex, // variantId
              childResolvers: [
                {
                  pattern: /^project-overview$/,
                  resolver: createBreadcrumbResolver(
                    AppNames.ProjectOverview,
                    MissionControlProjectOverviewRoute,
                  ),
                  childResolvers: [],
                },
                {
                  pattern: /^calendar$/,
                  resolver: createBreadcrumbResolver(
                    AppNames.Calendar,
                    MissionControlCalendarRoute,
                  ),
                  childResolvers: [],
                },
              ],
            },
          ],
        },
        {
          pattern: new RegExp(`^${ContentItemsAppRouteSegment.Content}$`),
          childResolvers: [
            {
              pattern: uuidRegex, // variantId
              childResolvers: [
                {
                  pattern: /^content/,
                  resolver: contentBreadcrumbResolver,
                  childResolvers: [
                    {
                      pattern: uuidRegex, // contentItemId
                      resolver: createContentItemBreadcrumbResolver({
                        getContentItemsByIds,
                        breadcrumbsOrigin,
                      }),
                      childResolvers: [],
                    },
                  ],
                },
              ],
            },
            {
              pattern: /^assets$/,
              resolver: assetsBreadcrumbResolver,
              childResolvers: [
                {
                  pattern: /^asset$/,
                  childResolvers: [
                    {
                      pattern: uuidRegex, // assetId
                      resolver: createAssetBreadcrumbResolver(
                        (assetId) => assetRepository.getAsset(assetId),
                        breadcrumbsOrigin,
                      ),
                      childResolvers: [],
                    },
                  ],
                },
              ],
            },
            {
              pattern: /^relations$/,
              resolver: relationsBreadcrumbResolver,
              childResolvers: [],
            },
          ],
        },
        {
          pattern: new RegExp(`^${ContentItemsAppRouteSegment.WebSpotlight}$`),
          resolver: webSpotlightBreadcrumbResolver,
          childResolvers: [
            {
              pattern: uuidRegex, // variantId
              childResolvers: [
                {
                  pattern: /^content/,
                  resolver: webSpotlightContentBreadcrumbResolver,
                  childResolvers: [
                    {
                      pattern: uuidRegex, // contentItemId
                      resolver: createContentItemBreadcrumbResolver({
                        getContentItemsByIds,
                        breadcrumbsOrigin,
                      }),
                      childResolvers: [],
                    },
                  ],
                },
                {
                  pattern: uuidRegex, // optional spaceId
                  childResolvers: [
                    {
                      pattern: /^content/,
                      resolver: webSpotlightContentBreadcrumbResolver,
                      childResolvers: [
                        {
                          pattern: uuidRegex, // contentItemId
                          resolver: createContentItemBreadcrumbResolver({
                            getContentItemsByIds,
                            breadcrumbsOrigin,
                          }),
                          childResolvers: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          pattern: /^content-models$/,
          childResolvers: [
            {
              pattern: /^types$/,
              resolver: contentTypesBreadcrumbResolver,
              childResolvers: [
                {
                  pattern: /^edit$/,
                  childResolvers: [
                    {
                      pattern: uuidRegex, // typeId
                      resolver: contentTypeBreadcrumbResolver,
                      childResolvers: [],
                    },
                  ],
                },
                {
                  pattern: /^new$/,
                  childResolvers: [],
                  resolver: newContentTypeBreadcrumbResolver,
                },
              ],
            },
            {
              pattern: /^snippets$/,
              resolver: contentTypeSnippetsBreadcrumbResolver,
              childResolvers: [
                {
                  pattern: /^edit$/,
                  childResolvers: [
                    {
                      pattern: uuidRegex, // snippetId
                      resolver: contentTypeSnippetBreadcrumbResolver,
                      childResolvers: [],
                    },
                  ],
                },
                {
                  pattern: /^new$/,
                  childResolvers: [],
                  resolver: newContentTypeSnippetBreadcrumbResolver,
                },
              ],
            },
            {
              pattern: /^taxonomy$/,
              resolver: taxonomyGroupsBreadcrumbResolver,
              childResolvers: [
                {
                  pattern: /^edit$/,
                  childResolvers: [
                    {
                      pattern: uuidRegex, // taxonomyGroupId
                      resolver: taxonomyGroupBreadcrumbResolver,
                      childResolvers: [],
                    },
                  ],
                },
                {
                  pattern: /^new$/,
                  childResolvers: [],
                  resolver: newTaxonomyBreadcrumbResolver,
                },
              ],
            },
          ],
        },
        {
          pattern: /^custom-apps$/,
          resolver: customAppsRouteResolver,
          childResolvers: [
            {
              pattern: /^detail$/,
              childResolvers: [
                {
                  pattern: uuidRegex, // customAppId
                  resolver: customAppDetailBreadcrumbResolver(customApps),
                  childResolvers: [],
                },
              ],
            },
          ],
        },
        {
          pattern: /^settings$/,
          resolver: createBreadcrumbResolverWithProjectIdPrefix(
            AppNames.EnvironmentSettings,
            '/settings',
          ),
          childResolvers: [
            {
              pattern: /^general$/,
              resolver: generalSettingsRouteResolver,
              childResolvers: [],
            },
            {
              pattern: /^users$/,
              resolver: usersRouteResolver,
              childResolvers: [],
            },
            {
              pattern: /^roles$/,
              resolver: rolesRouteResolver,
              childResolvers: [
                {
                  pattern: /^new$/,
                  resolver: newRoleBreadcrumbResolver,
                  childResolvers: [],
                },
                {
                  pattern: /^edit$/,
                  childResolvers: [
                    {
                      pattern: uuidRegex,
                      resolver: editedRoleBreadcrumbResolver,
                      childResolvers: [],
                    },
                  ],
                },
              ],
            },
            {
              pattern: /^environments$/,
              resolver: environmentsRouteResolver,
              childResolvers: [],
            },
            {
              pattern: /^workflows$/,
              resolver: workflowsBreadcrumbResolver({ isMultipleWorkflowsConfigurationAllowed }),
              childResolvers: [
                {
                  pattern: /^new$/,
                  resolver: newWorkflowBreadcrumbResolver,
                  childResolvers: [],
                },
                {
                  pattern: /^edit$/,
                  childResolvers: [
                    {
                      pattern: uuidRegex,
                      resolver: editedWorkflowBreadcrumbResolver({
                        isMultipleWorkflowsConfigurationAllowed,
                      }),
                      childResolvers: [],
                    },
                  ],
                },
              ],
            },
            {
              pattern: /^spaces$/,
              resolver: spacesRouteResolver,
              childResolvers: [],
            },
            {
              pattern: /^collections$/,
              resolver: collectionsRouteResolver,
              childResolvers: [],
            },
            {
              pattern: /^localization$/,
              resolver: localizationRouteResolver,
              childResolvers: [],
            },
            {
              pattern: /^preview-urls$/,
              resolver: previewUrlsRouteResolver,
              childResolvers: [],
            },
            {
              pattern: /^webhooks$/,
              resolver: webhooksRouteResolver,
              childResolvers: [
                {
                  pattern: /^new$/,
                  resolver: newWebhookBreadcrumbResolver,
                  childResolvers: [],
                },
                {
                  pattern: /^edit$/,
                  childResolvers: [
                    {
                      pattern: uuidRegex,
                      resolver: editedWebhookBreadcrumbResolver,
                      childResolvers: [],
                    },
                  ],
                },
                {
                  pattern: uuidRegex, // webhook id
                  childResolvers: [
                    {
                      pattern: /^messages$/,
                      resolver: webhookMessagesRouteResolver,
                      childResolvers: [],
                    },
                  ],
                },
              ],
            },
            {
              pattern: /^legacy-webhooks$/,
              resolver: legacyWebhooksRouteResolver,
              childResolvers: [
                {
                  pattern: uuidRegex, // webhook id
                  childResolvers: [
                    {
                      pattern: /^messages$/,
                      resolver: legacyWebhookMessagesRouteResolver,
                      childResolvers: [],
                    },
                  ],
                },
              ],
            },
            {
              pattern: /^audit-log$/,
              resolver: auditLogRouteResolver,
              childResolvers: [],
            },
            {
              pattern: /^custom-apps$/,
              resolver: customAppsConfigurationRouteResolver,
              childResolvers: [
                {
                  pattern: /^new$/,
                  resolver: newCustomAppConfigurationBreadcrumbResolver,
                  childResolvers: [],
                },
                {
                  pattern: /^edit$/,
                  childResolvers: [
                    {
                      pattern: uuidRegex,
                      resolver: editedCustomAppConfigurationBreadcrumbResolver(customApps),
                      childResolvers: [],
                    },
                  ],
                },
              ],
            },
            InnovationLabBreadcrumbResolverInfo,
          ],
        },
      ],
    },
  ];
};
