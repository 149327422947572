import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { useButton } from '@react-aria/button';
import React, { useCallback, useRef } from 'react';
import { buildAssetLink } from '../../../applications/itemEditor/features/ContentItemEditing/utils/renditionUtils.ts';
import { IImageTransformation } from '../../../data/models/assetRenditions/AssetRendition.ts';
import { IAsset } from '../../../data/models/assets/Asset.ts';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { TrackUserEventWithData } from '../../models/TrackUserEvent.type.ts';
import { Icon } from '../../uiComponents/Icon/Icon.tsx';

type AssetDownloadButtonProps = {
  readonly asset: IAsset;
  readonly disableTabulator?: boolean;
  readonly trackUserEvent?: TrackUserEventWithData;
  readonly transformation?: IImageTransformation;
};

export const AssetDownloadButton: React.FC<AssetDownloadButtonProps> = ({
  asset,
  disableTabulator,
  trackUserEvent,
  transformation,
}) => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const trackAssetDownload = useCallback((): void => {
    if (trackUserEvent) {
      trackUserEvent(TrackedEvent.AssetDownloaded, {
        size: asset.fileSize || 0,
        type: asset.type,
        'asset-id': asset.id,
      });
    }
  }, [asset, trackUserEvent]);

  const { buttonProps } = useButton(
    {
      elementType: 'a',
      target: '_blank',
      rel: 'noopener noreferrer',
      href: asset._downloadLink
        ? buildAssetLink(asset._downloadLink, transformation ?? null)
        : undefined,
      onPress: trackAssetDownload,
    },
    linkRef,
  );

  if (!asset._downloadLink) {
    return null;
  }

  return (
    <Tooltip
      tooltipText={transformation ? 'Download customized image' : 'Download'}
      placement="bottom"
    >
      <a
        download={asset.filename}
        className="asset-thumbnail__action asset-thumbnail__action--download"
        ref={linkRef}
        {...buttonProps}
        tabIndex={disableTabulator ? -1 : buttonProps.tabIndex}
      >
        <Icon iconName={IconName.ArrowDownLine} />
      </a>
    </Tooltip>
  );
};

AssetDownloadButton.displayName = 'AssetDownloadButton';
