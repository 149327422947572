export enum EditingAction {
  addTask = 'addTask',
  assignPeople = 'assignPeople',
  changeCollection = 'changeCollection',
  changeDueDate = 'changeDueDate',
  changeNote = 'changeNote',
  changeStep = 'changeStep',
  changeWorkflow = 'changeWorkflow',
  closeComponents = 'closeComponents',
  closeInlineComments = 'closeInlineComments',
  compareVersions = 'compareVersions',
  copyFromLanguage = 'copyFromLanguage',
  deleteItem = 'deleteItem',
  demonstrateDisabledPreviewItem = 'demonstrateDisabledPreviewItem',
  demonstratePublishPreventsDeletingItem = 'demonstratePublishPreventsDeletingItem',
  demonstrateWebSpotlightPreventsDeletingItem = 'demonstrateWebSpotlightPreventsDeletingItem',
  discardNewVersion = 'discardNewVersion',
  demonstrateDisabledTranslation = 'demonstrateDisabledTranslation',
  duplicateItemWithContent = 'duplicateItemWithContent',
  duplicateItemWithoutContent = 'duplicateItemWithoutContent',
  none = 'none',
  openComponents = 'openComponents',
  openDiscussions = 'openDiscussions',
  openInlineComments = 'openInlineComments',
  openResolvedComments = 'openResolvedComments',
  previewItem = 'previewItem',
  previewItemInMultipleSpaces = 'previewItemInMultipleSpaces',
  previewSampleAppItem = 'previewSampleAppItem',
  publishItem = 'publishItem',
  setDueDate = 'setDueDate',
  setNote = 'setNote',
  setupItemPreview = 'setupItemPreview',
  shareLink = 'shareLink',
  showDuplicationOptions = 'showDuplicationOptions',
  showMainMenu = 'showMainMenu',
  translateVariant = 'translateVariant',
  viewCodename = 'viewCodename',
}
