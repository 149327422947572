import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import {
  ContentItemEditing_ArchiveTemporaryContentItem_Finished,
  ContentItemEditing_ArchiveTemporaryContentItem_Started,
} from '../../../../applications/itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import {
  Data_ItemsRecentlyEditedByCurrentUser_Started,
  Data_ItemsRecentlyEditedByCurrentUser_Success,
} from '../../../constants/dataActionTypes.ts';
import {
  YourContentItem,
  createYourContentItem,
} from '../../../models/listingContentItems/YourContentItem.ts';

const initialState: ReadonlyArray<YourContentItem> = [];

export function items(
  state: ReadonlyArray<YourContentItem> = initialState,
  action: Action,
): ReadonlyArray<YourContentItem> {
  switch (action.type) {
    case Data_ItemsRecentlyEditedByCurrentUser_Success: {
      const contentItems = action.payload.data;
      return contentItems.map(createYourContentItem);
    }

    case Shared_CurrentProjectId_Updated:
    case Data_ItemsRecentlyEditedByCurrentUser_Started:
      return initialState;

    case ContentItemEditing_ArchiveTemporaryContentItem_Started:
    case ContentItemEditing_ArchiveTemporaryContentItem_Finished:
      return state.filter((listItem) => listItem.id.itemId !== action.payload.itemId);

    default:
      return state;
  }
}
