import Immutable from 'immutable';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';

export const filterContentTypesByIds = (
  contentTypes: ReadonlyArray<IContentType>,
  contentTypeIds: Immutable.Set<Uuid> | null,
): ReadonlyArray<IContentType> => {
  return contentTypeIds && contentTypes
    ? contentTypes.filter((type) => contentTypeIds.contains(type.id))
    : [];
};
