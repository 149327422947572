import { memoize } from '@kontent-ai/memoization';
import { findElement } from '../../../../richText/plugins/contentComponents/api/traverseComponentUtils.ts';
import { ICommentThread } from '../../../models/comments/CommentThreads.ts';
import { IEditedContentItem } from '../../../models/contentItem/edited/EditedContentItem.ts';
import { ICompiledContentItemElementData } from '../../../models/contentItemElements/ICompiledContentItemElement.type.ts';

const calculateTopLevelElementId = (
  contentItem: IEditedContentItem,
  variantElements: ReadonlyArray<ICompiledContentItemElementData>,
  elementId: Uuid | null,
  contentComponentId: Uuid | null,
): Uuid | null => {
  if (!elementId) {
    return null;
  }

  const findElementResult = findElement(
    contentItem,
    variantElements,
    elementId,
    contentComponentId || undefined,
  );
  if (!findElementResult.found) {
    return null;
  }

  return findElementResult.path[0]?.elementId ?? null;
};

// As comment thread's location in item doesn't change during its existence, it doesn't make sense to recalculate its top level element's id
// on each element's re-rendering. Using of simple memoization is complicated by  editedContentItemVariantElement parameter which is being
// changed on each item element's change. It was possible to declare editedContentItem and editedContentItemVariantElements properties
// outside of the memoized function scope, but using of Cache seems to be more clean. Cache expiration is set to 10 minutes to avoid memory overusing
export const getCommentThreadTopLevelElementId = memoize.maxAgeWithTransformedArgs(
  (
    contentItem: IEditedContentItem,
    variantElements: ReadonlyArray<ICompiledContentItemElementData>,
    commentThread: ICommentThread,
  ): Uuid | null =>
    calculateTopLevelElementId(
      contentItem,
      variantElements,
      commentThread.elementId,
      commentThread.contentComponentId,
    ),
  (args) => [`topLevelElementId_${args[2].id}`],
  10 * 60 * 1000,
);
