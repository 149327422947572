import { ReactNode, useEffect } from 'react';
import { InitializerLoader } from '../../_shared/components/InitializerLoader.tsx';
import { useAuthToken } from '../../_shared/contexts/AuthTokenProvider.tsx';
import { useDispatch } from '../../_shared/hooks/useDispatch.ts';
import { useHandleEmailVerification } from '../../_shared/hooks/useHandleEmailVerification.ts';
import { useThunkPromise } from '../../_shared/hooks/useThunkPromise.ts';
import { initializeRepositories } from '../actions/thunkInitializationActions.ts';
import { initializeApp } from '../actions/thunks/initializeApp.ts';
import { useLogIntroductionEvents } from '../hooks/useLogIntroductionEvents.ts';

type Props = {
  readonly renderWhenInitialized: () => ReactNode;
};

export const Initializer = (props: Props) => {
  const { getAuthToken } = useAuthToken();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initializeRepositories(getAuthToken));
  }, [getAuthToken]);

  useLogIntroductionEvents();
  const isVerified = useHandleEmailVerification(getAuthToken);
  const [isInitThunkDone] = useThunkPromise(initializeApp, { canRun: isVerified });

  return isInitThunkDone ? props.renderWhenInitialized() : <InitializerLoader />;
};
