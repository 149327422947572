import { useCallback } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { isAiSearchEnabled } from '../../../../_shared/selectors/aiSelectors.ts';
import { useAiSearchScope } from '../../../../paperModels/aiSearch/selectors/useAiSearchScope.ts';
import { FullTextSearchStatus } from '../../shared/reducers/IContentInventoryStoreState.type.ts';
import { ContentItemsSearchScope as ContentItemsSearchScopeComponent } from '../components/ContentItemsSearchScope.tsx';
import { onSearchScopeChanged } from '../features/ListingFilter/actions/listingFilterActions.ts';
import { SearchScope } from '../models/filter/IListingFilter.ts';

type Props = {
  readonly fullTextSearchStatus: FullTextSearchStatus;
};

export const ContentItemsSearchScope = ({ fullTextSearchStatus }: Props) => {
  const isFullTextSearchUnavailable = fullTextSearchStatus === FullTextSearchStatus.Unavailable;

  const scope = useSelector((state) =>
    isFullTextSearchUnavailable
      ? new Set([SearchScope.Name])
      : state.contentApp.listingUi.filter.searchScope,
  );

  const dispatch = useDispatch();
  const changeScope = useCallback(
    (newScope: ReadonlySet<SearchScope>) => dispatch(onSearchScopeChanged(newScope)),
    [],
  );

  const aiSearchEnabled = useSelector(isAiSearchEnabled);

  const displayScope = useAiSearchScope(aiSearchEnabled, scope, changeScope);

  return (
    <ContentItemsSearchScopeComponent
      allowAiSearch={aiSearchEnabled}
      isFullTextDisabled={isFullTextSearchUnavailable}
      onChange={changeScope}
      scope={displayScope}
    />
  );
};
