import { ReactNode } from 'react';
import { HtmlPageTitle } from '../../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import { ProjectType } from '../../../../_shared/models/ProjectType.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { GettingStartedQuickstart } from '../containers/GettingStartedQuickstart/GettingStartedQuickstart.tsx';
import { SampleProjectQuickstart } from './SampleProjectQuickstart/SampleProjectQuickstart.tsx';
import { KickstartProjectQuickstart } from './kickstart/KickstartProjectQuickstart.tsx';

interface IQuickstartProps {
  readonly projectType: ProjectType;
}

export const Quickstart = (props: IQuickstartProps): JSX.Element => (
  <div className="canvas" {...getDataUiAppNameAttribute(DataUiAppName.Quickstart)}>
    <HtmlPageTitle appName={AppNames.Quickstart} />
    {renderContent(props.projectType)}
  </div>
);

Quickstart.displayName = 'Quickstart';

const renderContent = (projectType: ProjectType): ReactNode => {
  switch (projectType) {
    case ProjectType.Kickstart:
      return <KickstartProjectQuickstart />;
    case ProjectType.GettingStarted:
      return <GettingStartedQuickstart />;
    case ProjectType.DancingGoat:
      return <SampleProjectQuickstart />;
    case ProjectType.UserProject:
      return null;
  }
};
