import { IBaseSelectItem, ISelectSection } from '@kontent-ai/component-library/Selects';
import { getSectionItemId } from '../../../../../_shared/utils/component-library/selectSections.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { ContentTypeSection } from '../constants/contentTypeSection.ts';
import { ContentTypeSelectorState } from './getContentTypeSelectorState.ts';

const createContentTypeOption =
  (section: ContentTypeSection) =>
  ({ id, name }: IContentType): IBaseSelectItem => ({
    //  Id must be unique amongst all options, even if the same option is in multiple sections
    id: getSectionItemId(id, section),
    label: name,
  });

export const createContentTypeSelectorOptions = (
  suggestedContentTypes: ReadonlyArray<IContentType>,
  allowedContentTypes: ReadonlyArray<IContentType>,
  availableContentTypes: ReadonlyArray<IContentType>,
  state: ContentTypeSelectorState,
): ReadonlyArray<ISelectSection<IBaseSelectItem>> => {
  switch (state) {
    case ContentTypeSelectorState.ShowSuggested:
      return [
        {
          id: ContentTypeSection.SuggestedTypes,
          items: suggestedContentTypes.map(
            createContentTypeOption(ContentTypeSection.SuggestedTypes),
          ),
          type: 'section',
          label: 'Suggested content types',
        },
        {
          id: ContentTypeSection.AllTypes,
          items: availableContentTypes.map(createContentTypeOption(ContentTypeSection.AllTypes)),
          type: 'section',
          label: 'All content types',
        },
      ];

    case ContentTypeSelectorState.ShowRestricted:
      return [
        {
          id: ContentTypeSection.AcceptedTypes,
          items: allowedContentTypes.map(createContentTypeOption(ContentTypeSection.AcceptedTypes)),
          type: 'section',
          label: 'Accepted content types',
        },
        {
          id: ContentTypeSection.AllTypes,
          items: availableContentTypes.map(createContentTypeOption(ContentTypeSection.AllTypes)),
          type: 'section',
          label: 'All content types',
        },
      ];

    case ContentTypeSelectorState.ShowAllOnly:
      return [
        {
          id: ContentTypeSection.AllTypes,
          items: availableContentTypes.map(createContentTypeOption(ContentTypeSection.None)),
          type: 'section',
        },
      ];
  }
};
