import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { PreviewError, getPreviewErrorMessage } from '../../../../utils/previewUtils.ts';
import { PreviewToggle as PreviewToggleComponent } from '../../components/editingActions/PreviewToggle.tsx';
import { usePreviewContext } from '../../context/PreviewContextProvider.tsx';
import { getAvailableValidPreviewLinksForUser } from '../selectors/getAvailableValidPreviewLinksForUser.tsx';
import { selectFirstErrorInPreviewLinks } from '../selectors/selectPreviewLinks.ts';

export const PreviewToggle = () => {
  const { isPreviewOpened, setIsPreviewOpened } = usePreviewContext();
  const isAnyPreviewLinkAvailable = useSelector(
    (state) => !!getAvailableValidPreviewLinksForUser(state).length,
  );

  const error = useSelector((s) => selectFirstErrorInPreviewLinks(s));
  const errorMessage = isAnyPreviewLinkAvailable
    ? undefined
    : getPreviewErrorMessage(error ?? PreviewError.NoPreview);

  return (
    <PreviewToggleComponent
      isActive={isPreviewOpened && isAnyPreviewLinkAvailable}
      onToggle={isAnyPreviewLinkAvailable ? setIsPreviewOpened : undefined}
      disabledTooltip={errorMessage}
    />
  );
};
