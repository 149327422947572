import { areArraysShallowEqual, identity } from '@kontent-ai/utils';
import Immutable from 'immutable';
import React from 'react';
import { DragMoveHandler } from '../../../../../../../_shared/components/DragDrop/dragDrop.type.ts';
import { DataUiElement } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { moveItem } from '../../../../../../../_shared/utils/dragDrop/dragDropUtils.ts';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IContentItemWithVariantServerModel } from '../../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { ElementType } from '../../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { ILinkedItemsTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/LinkedItemsTypeElement.ts';
import { ILinkedItemsElement } from '../../../../../models/contentItemElements/modularItems/ILinkedItemsElement.ts';
import { IModularContentItemElementWarningResult } from '../../../../../utils/itemElementWarningCheckers/types/IModularContentItemElementWarningResult.type.ts';
import { CollectionSection } from '../../../../NewContentItem/constants/collectionSection.ts';
import { ContentTypeSection } from '../../../../NewContentItem/constants/contentTypeSection.ts';
import { LinkedItemsActionsPane } from '../../../containers/elements/modularContent/LinkedItemsActionsPane.tsx';
import { hasDefaultValue } from '../../../utils/defaultValueUtils.ts';
import { ContentItemElementStatus } from '../ContentItemElementStatus.tsx';
import { DefaultValueStatus } from '../subComponents/limitInfoMessages/DefaultValueStatus.tsx';
import {
  ItemElementLimitType,
  ItemLimitStatusMessage,
} from '../subComponents/limitInfoMessages/ItemLimitStatusMessage.tsx';
import { StatusMessage } from '../subComponents/limitInfoMessages/StatusMessage.tsx';
import { LinkedItemsList } from './LinkedItemsList.tsx';

export interface ILinkedItemsComponentStateProps {
  readonly allowedContentTypes: Immutable.Set<IContentType>;
  readonly allowedTypesMessage?: string;
  readonly collectionId?: Uuid;
  readonly validationResult: IModularContentItemElementWarningResult;
}

export interface ILinkedItemsComponentDispatchProps {
  readonly onDuplicateContentItem: (
    contentItemId: Uuid,
    destinationCollectionId: Uuid,
  ) => Promise<IContentItemWithVariantServerModel | null>;
  readonly onContentItemAdded: (
    allowedContentTypes: Immutable.Set<IContentType>,
    itemId: Uuid,
    contentTypeSection: ContentTypeSection,
    collectionSection: CollectionSection,
  ) => void;
  readonly onContentItemsLinked: (
    allowedContentTypes: Immutable.Set<IContentType>,
    newlyLinkedItems: ReadonlyArray<Uuid>,
  ) => void;
}

export interface ILinkedItemsComponentOwnProps {
  readonly autoFocus?: boolean;
  readonly contentComponentId?: Uuid;
  readonly elementData: ILinkedItemsElement;
  readonly isDisabled: boolean;
  readonly onValueChanged: (
    itemElement: ILinkedItemsElement,
    addedReferencesCount?: number,
  ) => void;
  readonly typeElement: ILinkedItemsTypeElement;
}

interface ILinkedItemsComponentProps
  extends ILinkedItemsComponentStateProps,
    ILinkedItemsComponentDispatchProps,
    ILinkedItemsComponentOwnProps {}

export const LinkedItemsComponent: React.FC<ILinkedItemsComponentProps> = ({
  elementData,
  onValueChanged,
  onContentItemsLinked,
  contentComponentId,
  allowedContentTypes,
  isDisabled,
  autoFocus,
  collectionId,
  typeElement,
  allowedTypesMessage,
  onContentItemAdded,
  validationResult,
  onDuplicateContentItem,
}) => {
  const setReferences = (references: ReadonlyArray<Uuid>) => {
    const updatedItemElement: ILinkedItemsElement = {
      ...elementData,
      value: references,
    };
    const addedReferencesCount = updatedItemElement.value.length - elementData.value.length;

    onValueChanged(updatedItemElement, addedReferencesCount);
  };

  const onRelatedEntryMoved: DragMoveHandler = ({ sourceId, targetId }): void => {
    const updatedReferences = moveItem(elementData.value, sourceId, targetId, identity);
    setReferences(updatedReferences);
  };

  const onRelatedEntryDeleted = (id: Uuid): void => {
    const updatedReferences = elementData.value.filter((refId) => refId !== id);
    setReferences(updatedReferences);
  };

  const addExistingItems = (
    itemsIds: ReadonlyArray<Uuid>,
    insertAtIndex: number | null = null,
  ): void => {
    const newIds = itemsIds.filter((id) => !elementData.value.includes(id));

    const updatedReferences = insertValues(insertAtIndex, elementData.value, newIds);

    setReferences(updatedReferences);
    onContentItemsLinked(allowedContentTypes, newIds);
  };

  const createNewItem = (
    itemId: Uuid,
    contentTypeSection: ContentTypeSection,
    collectionSection: CollectionSection,
    insertAtIndex: number | null,
  ): void => {
    const updatedReferences = insertValues(insertAtIndex, elementData.value, [itemId]);

    setReferences(updatedReferences);
    onContentItemAdded(allowedContentTypes, itemId, contentTypeSection, collectionSection);
  };

  const onItemDuplicate = async (
    contentItemId: Uuid,
    destinationCollectionId: Uuid,
  ): Promise<void> => {
    const duplicatedItem = await onDuplicateContentItem(contentItemId, destinationCollectionId);

    if (duplicatedItem) {
      const sourceItemIndex = elementData.value.findIndex((id) => contentItemId === id);
      const updatedReferences = insertValues(sourceItemIndex + 1, elementData.value, [
        duplicatedItem.item.id,
      ]);

      setReferences(updatedReferences);
    }
  };

  const renderActionsPane = (): JSX.Element | null => {
    return isDisabled ? null : (
      <LinkedItemsActionsPane
        allowedContentTypes={allowedContentTypes}
        alreadyLinkedItemIds={elementData.value}
        autoFocus={autoFocus}
        collectionId={collectionId}
        elementId={elementData.elementId}
        onAddExistingItems={addExistingItems}
        onCreateNewItem={createNewItem}
        modalDialogTitle={getModalDialogTitleForElement(typeElement)}
        typeElement={typeElement}
      />
    );
  };

  const renderElementLimitationStatus = () => {
    const { maxItems, minItems } = typeElement;

    const isNumberOfItemsLimited = !!minItems || !!maxItems;
    const isTypeLimited = !!allowedTypesMessage;
    const isLimited = isNumberOfItemsLimited || isTypeLimited;

    if (!isLimited) {
      return null;
    }

    const isNumberOfItemsLimitMet = validationResult.isNumberOfItemsLimitMet;
    const containsOnlyAllowedContentTypes = validationResult.containsOnlyAllowedContentTypes;

    return (
      <ContentItemElementStatus>
        <div className="content-item-element__status-pane">
          <span>Element parameters: </span>
          {isNumberOfItemsLimited && (
            <span className="content-item-element__status-segment">
              <ItemLimitStatusMessage
                min={minItems}
                max={maxItems}
                isLimitValueMet={isNumberOfItemsLimitMet}
                type={ItemElementLimitType.ModularItemCount}
              />
            </span>
          )}
          {isTypeLimited && (
            <span className="content-item-element__status-segment">
              <StatusMessage
                message={allowedTypesMessage}
                isInvalid={!containsOnlyAllowedContentTypes}
                dataUiElement={DataUiElement.ElementLimitations}
              />
            </span>
          )}
        </div>
      </ContentItemElementStatus>
    );
  };

  const renderEmptyDisabledElementMessage = () => (
    <div className="content-item-element__placeholder">
      {`There are no ${
        typeElement.type === ElementType.Subpages ? 'pages' : 'linked items'
      } selected.`}
    </div>
  );

  const { elementId, value } = elementData;

  const isEmpty = !value.length;
  const isDefaultValueSet = hasDefaultValue(typeElement.defaultValue);
  const isCurrentValueDefault =
    !!typeElement.defaultValue &&
    areArraysShallowEqual(elementData.value, typeElement.defaultValue);

  return (
    <>
      {isDisabled && isEmpty && renderEmptyDisabledElementMessage()}
      {!isEmpty && (
        <div className="modular-content__items">
          <LinkedItemsList
            allowedContentTypes={allowedContentTypes}
            contentComponentId={contentComponentId ?? null}
            contentItemIds={value}
            elementId={elementId}
            isDisabled={isDisabled}
            areItemsPages={typeElement.type === ElementType.Subpages}
            onItemDuplicate={onItemDuplicate}
            onRelatedEntryDeleted={onRelatedEntryDeleted}
            onRelatedEntryMoved={onRelatedEntryMoved}
          />
        </div>
      )}
      <div className="modular-content__actions-pane">{renderActionsPane()}</div>
      <DefaultValueStatus
        isStatusRendered={isDefaultValueSet && !isDisabled}
        isValueDefault={isCurrentValueDefault}
      />
      {renderElementLimitationStatus()}
    </>
  );
};

const getModalDialogTitleForElement = (typeElement: ILinkedItemsTypeElement): string => {
  const entityName = typeElement.type === ElementType.Subpages ? 'pages' : 'content items';
  const elementType =
    typeElement.type === ElementType.Subpages ? '(Subpages element)' : '(Linked items element)';

  return `Insert existing ${entityName} to ${typeElement.name} ${elementType}`;
};

const insertValues = (
  insertAtIndex: number | null,
  value: ReadonlyArray<Uuid>,
  newIds: ReadonlyArray<Uuid>,
): ReadonlyArray<Uuid> =>
  insertAtIndex === null
    ? value.concat(newIds)
    : [...value.slice(0, insertAtIndex), ...newIds, ...value.slice(insertAtIndex)];
