import { Menu } from '@kontent-ai/component-library/Menu';
import React from 'react';

type MenuOption = {
  readonly variantId: Uuid;
  readonly name: string;
  readonly isDisabled: boolean;
};

type CopyFromLanguageOptionsProps = {
  readonly onSelectLanguage: (params: Uuid) => void;
  readonly menuOptions: ReadonlyArray<MenuOption>;
};

export const CopyFromLanguageOptions: React.FC<CopyFromLanguageOptionsProps> = (props) => (
  <>
    {props.menuOptions.map((option: MenuOption, index: number) => (
      <Menu.Item
        id={option.name}
        menuItemState={option.isDisabled ? 'disabled' : 'default'}
        key={index}
        onAction={() => props.onSelectLanguage(option.variantId)}
        tooltipText={
          option.isDisabled
            ? 'You can only copy from language variants assigned to you.'
            : undefined
        }
        label={option.name}
      />
    ))}
  </>
);
