import { usePrevious } from '@kontent-ai/hooks';
import { Loader } from '@storybook/components';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ActiveProjectsRoute } from '../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../_shared/hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../../_shared/models/LoadingStatusEnum.ts';
import { SidebarNames } from '../../../_shared/models/SidebarNames.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  getActiveSubscriptions,
  getAdministratedSubscriptions,
} from '../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { projectCopyStateStop } from '../actions/projectsActions.ts';
import { initializeProjectManagement } from '../actions/thunkProjectsActions.ts';
import { MyProjectsPage } from '../components/MyProjectsPage.tsx';
import { IProjectViewModel, createProjectList } from '../selectors/createProjectList.ts';
import { ExpiredSubscriptionsWarning } from './ExpiredSubscriptionsWarning.tsx';
import { MyProjectsEmptyState } from './MyProjectsEmptyState.tsx';
import { StartTrialQuickTip } from './StartTrialQuickTip.ts';
import { SuspendedSubscriptionsWarning } from './SuspendedSubscriptionsWarning.tsx';
import { TrialActivationModal } from './TrialActivationModal.tsx';
import { MyProject } from './projects/MyProject.tsx';
import { NewProjectModal } from './projects/NewProjectModal.tsx';
import { ProjectDeleteConfirmationDialog } from './projects/ProjectDeleteConfirmationDialog.tsx';

interface IMyProjectsProps {
  readonly projectsToShow: 'active' | 'archived';
}

export const MyProjects = (props: IMyProjectsProps) => {
  const [projectIdToDelete, setProjectIdToDelete] = useState<Uuid | null>(null);
  const [projectIdToCopy, setProjectIdToCopy] = useState<Uuid | null>(null);

  const dispatch = useDispatch();
  const projectsLoaded = useSelector((s) => s.data.projects.loadingStatus === LoadingStatus.Loaded);
  const creatingProject = useSelector((s) => s.projectsApp.creatingProject);
  const projectList = useSelector((s) =>
    createProjectList(s, s.sharedApp.userProperties.projectOrderBy).filter(
      createShouldBeShown(props.projectsToShow),
    ),
  );

  useThunkPromise(initializeProjectManagement, true);

  useEffect(() => {
    return () => {
      dispatch(projectCopyStateStop());
    };
  }, []);

  const history = useHistory();

  const previousCreatingProject = usePrevious(creatingProject);
  useEffect(() => {
    if (creatingProject && !previousCreatingProject) {
      history.push(ActiveProjectsRoute);
    }
  }, [history, creatingProject, previousCreatingProject]);

  const hasAnyActiveSubscription = useSelector(
    (state) =>
      getActiveSubscriptions(
        getAdministratedSubscriptions(
          state.data.subscriptions.administratedIds,
          state.data.subscriptions.byId,
        ),
      ).length > 0,
  );

  const isCreateNewProjectModalOpen = useSelector(
    (state) =>
      state.sharedApp.sidebarStatus.isVisible &&
      state.sharedApp.sidebarStatus.sidebarName === SidebarNames.CreateProjectModal,
  );

  const showNewProjectModal =
    (isCreateNewProjectModalOpen || !!projectIdToCopy) && hasAnyActiveSubscription;

  if (!projectsLoaded) {
    return <Loader />;
  }

  return (
    <MyProjectsPage>
      <ExpiredSubscriptionsWarning />
      <SuspendedSubscriptionsWarning />

      <div className="my-projects" {...getDataUiCollectionAttribute(DataUiCollection.Projects)}>
        {projectList.map((project) => (
          <MyProject
            key={project.projectId}
            project={project}
            onDeleteProject={() => setProjectIdToDelete(project.projectId)}
            onCloneProject={() => setProjectIdToCopy(project.projectId)}
          />
        ))}
      </div>
      {props.projectsToShow === 'active' && <StartTrialQuickTip />}

      <MyProjectsEmptyState activeProjectsShown={props.projectsToShow === 'active'} />
      <TrialActivationModal />
      {showNewProjectModal && (
        <NewProjectModal
          sourceProjectId={projectIdToCopy}
          onClose={() => setProjectIdToCopy(null)}
        />
      )}
      {projectIdToDelete && (
        <ProjectDeleteConfirmationDialog
          projectId={projectIdToDelete}
          onClose={() => setProjectIdToDelete(null)}
        />
      )}
    </MyProjectsPage>
  );
};

const createShouldBeShown =
  (shownProjects: 'active' | 'archived') =>
  (project: IProjectViewModel): boolean => {
    if (shownProjects === 'archived') {
      return project.inactive;
    }

    return !project.inactive;
  };
