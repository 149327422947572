import { ContentItemPaneElementsGroup } from './ContentItemPaneElementsGroup.tsx';
import { NoElementsElement } from './NoElementsElement.tsx';

type NoElementsEmptyStateProps = Readonly<{
  canEdit: boolean;
}>;

export const NoElementsEmptyState = ({ canEdit }: NoElementsEmptyStateProps) => {
  const isDisabled = !canEdit;

  return (
    <ContentItemPaneElementsGroup
      isDisabled={isDisabled}
      isGuideline
      hasTopRoundedCorners
      hasBottomRoundedCorners
    >
      <NoElementsElement isDisabled={isDisabled} />
    </ContentItemPaneElementsGroup>
  );
};
