import { InputState } from '@kontent-ai/component-library/Input';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { Collection } from '@kontent-ai/utils';
import React from 'react';
import {
  ObsoleteTimeZoneIds,
  StandardTimeZoneIds,
  getObsoleteTimeZoneNameTooltipText,
} from '../../utils/dateTime/timeZoneUtils.ts';

export const TimeZoneSelect: React.FC<{
  readonly selectedTimeZoneId: string;
  readonly isDisabled?: boolean;
  readonly onChange: (selectedTimeZoneId: string) => void;
}> = ({ isDisabled, selectedTimeZoneId, onChange }) => (
  <SingleSelect
    aria-label="Time zone"
    selectedItemId={selectedTimeZoneId}
    items={supportedTimeZonesItems}
    inputState={isDisabled ? InputState.Disabled : InputState.Default}
    onSelectionChange={onChange}
    placeholder="Select a time zone"
    tooltipText={
      ObsoleteTimeZoneIds.has(selectedTimeZoneId)
        ? getObsoleteTimeZoneNameTooltipText(selectedTimeZoneId)
        : undefined
    }
    isVirtualized
  />
);

TimeZoneSelect.displayName = 'TimeZoneSelect';

const getTimeZoneNameFromId = (id: string): string => id.replaceAll('_', ' ');

const standardTimeZonesItems = Collection.getValues(StandardTimeZoneIds).map((timeZoneId) => ({
  id: timeZoneId,
  label: getTimeZoneNameFromId(timeZoneId),
}));

const obsoleteTimeZonesItems = Collection.getValues(ObsoleteTimeZoneIds).map((timeZoneId) => ({
  id: timeZoneId,
  label: `${getTimeZoneNameFromId(timeZoneId)} (obsolete name)`,
  tooltipText: getObsoleteTimeZoneNameTooltipText(timeZoneId),
}));

const supportedTimeZonesItems = [...standardTimeZonesItems, ...obsoleteTimeZonesItems];
