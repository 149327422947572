import { Box } from '@kontent-ai/component-library/Box';
import { TabItem, TabView } from '@kontent-ai/component-library/TabView';
import {
  spacingMainLayoutLeft,
  spacingMainLayoutRight,
  spacingMainLayoutTop,
} from '@kontent-ai/component-library/tokens';
import { Redirect, Route, Switch, useParams } from 'react-router';
import { PageTitle } from '../../../_shared/components/PageTitle.tsx';
import { useRedirectPropsWithSameSearch } from '../../../_shared/components/routing/useRedirectPropsWithSameSearch.tsx';
import { AppNames } from '../../../_shared/constants/applicationNames.ts';
import {
  ContentPaceRoute,
  ContentStatusRoute,
  MissionControlCalendarRoute,
  MissionControlDashboardRoute,
  MissionControlKickstartRoute,
  MissionControlLanguageAgnosticRouteParams,
  MissionControlLanguageDependentRoute,
  MissionControlLanguageDependentRouteParams,
  MissionControlProjectOverviewRoute,
  MissionControlQuickstartRoute,
  MissionControlYourWorkRoute,
} from '../../../_shared/constants/routePaths.ts';
import { EnsureContentProductionCapabilities } from '../../../_shared/containers/EnsureContentProductionCapabilities.tsx';
import { EnsureSelectedVariantIdInRoute } from '../../../_shared/containers/EnsureSelectedVariantIdInRoute.tsx';
import { AuthorizedSection } from '../../../_shared/containers/routing/AuthorizedSection.tsx';
import { useGetLastRouteSegment } from '../../../_shared/hooks/useGetLastRouteSegment.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../_shared/hooks/useThunkPromise.ts';
import {
  getSelectedLanguageId,
  getSelectedLanguageIdOrRouteMacro,
} from '../../../_shared/selectors/getSelectedLanguageId.ts';
import {
  DataUiAppName,
  DataUiCollection,
  getDataUiAppNameAttribute,
  getDataUiCollectionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Capability, getUserCapability } from '../../../_shared/utils/permissions/capability.ts';
import { buildPath } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { QuickstartType, getProjectQuickstartType } from '../../../_shared/utils/trialUtils.ts';
import { getCurrentProject } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { contentRequiredCapabilities } from '../../contentInventory/shared/utils/contentInventoryRequiredCapabilities.ts';
import { localizedRouteLeft } from '../actions/missionControlActions.ts';
import {
  ensureSelectedVariantForMissionControlLanguageDependentRoutes,
  initMissionControl,
} from '../actions/thunkMissionControlActions.ts';
import { Calendar } from '../calendar/containers/Calendar/Calendar.tsx';
import { EnsureSelectedArrangementForCalendar } from '../calendar/containers/EnsureSelectedArrangementForCalendar.tsx';
import { ContentPace } from '../contentPace/containers/ContentPace.tsx';
import { isContentPaceEnabled } from '../contentPace/selectors/isContentPaceEnabled.ts';
import { ContentStatusTabContent } from '../contentStatus/components/ContentStatusTabContent.tsx';
import { YourWork } from '../contentStatus/yourWork/components/YourWork.tsx';
import { MissionControlDemoContextProvider } from '../contexts/MissionControlDemoContext.tsx';
import { DashboardTabContent } from '../dashboard/container/DashboardTabContent.tsx';
import { FeedbackButton } from '../feedback/containers/FeedbackButton.tsx';
import { ProjectOverviewPage } from '../projectOverview/containers/ProjectOverviewPage.tsx';
import { Quickstart } from '../quickstart/containers/Quickstart.tsx';
import { missionControlRequiredCapabilities } from '../shared/missionControlRequiredCapabilities.ts';
import { MissionControlDemoModeToggle } from './MissionControlDemoModeToggle.tsx';
import { MissionControlHeaderLayout } from './MissionControlHeaderLayout.tsx';
import { MissionControlTabPanel } from './MissionControlTabPanel.tsx';

export const MissionControlPage = () => {
  const selectedSectionPath = useGetLastRouteSegment();

  const tabs = useTabs();
  const selectedTab = tabs.find((tab) => tab.to.endsWith(selectedSectionPath));

  useThunkPromise(initMissionControl);

  return (
    <MissionControlDemoContextProvider>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        paddingTop={spacingMainLayoutTop}
        {...getDataUiAppNameAttribute(DataUiAppName.MissionControl)}
      >
        <MissionControlHeaderLayout>
          <PageTitle>{AppNames.MissionControl}</PageTitle>
          <FeedbackButton />
        </MissionControlHeaderLayout>

        <Box flexGrow={1} minHeight={0}>
          <TabView selectedKey={selectedTab?.id || ''} items={tabs}>
            <Box paddingLeft={spacingMainLayoutLeft} paddingRight={spacingMainLayoutRight}>
              <TabView.TabGroup
                {...getDataUiCollectionAttribute(DataUiCollection.MissionControlNavigationMenu)}
                renderAuxElement={() => <MissionControlDemoModeToggle />}
              />
            </Box>
            <MissionControlTabPanel>{Routes}</MissionControlTabPanel>
          </TabView>
        </Box>
      </Box>
    </MissionControlDemoContextProvider>
  );
};

const tabs = {
  quickstart: {
    id: 'quickstart',
    name: AppNames.Quickstart,
    buildLink: (projectId: Uuid) =>
      buildPath<MissionControlLanguageAgnosticRouteParams>(MissionControlQuickstartRoute, {
        projectId,
      }),
    requiresOneOfCapabilities: missionControlRequiredCapabilities,
    routePath: MissionControlQuickstartRoute,
  },
  kickstart: {
    id: 'kickstart',
    name: AppNames.Kickstart,
    buildLink: (projectId: Uuid) =>
      buildPath<MissionControlLanguageAgnosticRouteParams>(MissionControlKickstartRoute, {
        projectId,
      }),
    requiresOneOfCapabilities: missionControlRequiredCapabilities,
    routePath: MissionControlKickstartRoute,
  },
  yourWork: {
    id: 'your-work',
    name: AppNames.YourWork,
    buildLink: (projectId: Uuid) =>
      buildPath<MissionControlLanguageAgnosticRouteParams>(MissionControlYourWorkRoute, {
        projectId,
      }),
    requiresOneOfCapabilities: contentRequiredCapabilities,
    routePath: MissionControlYourWorkRoute,
  },
  dashboard: {
    id: 'dashboard',
    name: AppNames.Dashboard,
    buildLink: (projectId: Uuid) =>
      buildPath<MissionControlLanguageAgnosticRouteParams>(MissionControlDashboardRoute, {
        projectId,
      }),
    requiresOneOfCapabilities: contentRequiredCapabilities,
    routePath: MissionControlDashboardRoute,
  },
  contentStatus: {
    id: 'content-status',
    name: AppNames.ContentStatus,
    buildLink: (projectId: Uuid) =>
      buildPath<MissionControlLanguageAgnosticRouteParams>(ContentStatusRoute, {
        projectId,
      }),
    requiresOneOfCapabilities: contentRequiredCapabilities,
    routePath: ContentStatusRoute,
  },
  contentPace: {
    id: 'content-pace',
    name: AppNames.ContentPace,
    buildLink: (projectId: Uuid) =>
      buildPath<MissionControlLanguageAgnosticRouteParams>(ContentPaceRoute, {
        projectId,
      }),
    requiresOneOfCapabilities: contentRequiredCapabilities,
    routePath: ContentPaceRoute,
  },
  projectOverview: {
    id: 'project-overview',
    name: AppNames.ProjectOverview,
    buildLink: (projectId: Uuid, languageId: Uuid) =>
      buildPath<MissionControlLanguageDependentRouteParams>(MissionControlProjectOverviewRoute, {
        projectId,
        variantId: languageId,
      }),
    requiresOneOfCapabilities: contentRequiredCapabilities,
    routePath: MissionControlProjectOverviewRoute,
  },
  calendar: {
    id: 'calendar',
    name: AppNames.Calendar,
    buildLink: (projectId: Uuid, languageId: Uuid) =>
      buildPath<MissionControlLanguageDependentRouteParams>(MissionControlCalendarRoute, {
        projectId,
        variantId: languageId,
      }),
    requiresOneOfCapabilities: contentRequiredCapabilities,
    routePath: MissionControlCalendarRoute,
  },
} as const satisfies ReadonlyRecord<string, MissionControlTab>;

const getQuickstartTab = (
  quickstartType: QuickstartType,
): (typeof tabs)['quickstart' | 'kickstart'] | null => {
  switch (quickstartType) {
    case QuickstartType.None:
      return null;
    case QuickstartType.Quickstart:
      return tabs.quickstart;
    case QuickstartType.Kickstart:
      return tabs.kickstart;
  }
};

type MissionControlTab = {
  readonly id: string;
  readonly name: AppNames;
  readonly buildLink:
    | ((projectId: Uuid) => string)
    | ((projectId: Uuid, languageId: Uuid) => string);
  readonly requiresOneOfCapabilities: ReadonlyArray<Capability>;
  readonly routePath: string;
};

const tabStructure = [
  tabs.quickstart,
  tabs.kickstart,
  tabs.yourWork,
  tabs.dashboard,
  tabs.contentStatus,
  tabs.contentPace,
  tabs.projectOverview,
  tabs.calendar,
] as const;

const mapTabToMissionControlTabItem = (
  tab: MissionControlTab,
  projectId: Uuid,
  languageId: Uuid,
): MissionControlTabItem => ({
  id: tab.id,
  label: tab.name,
  to: tab.buildLink(projectId, languageId),
  ...getDataUiObjectNameAttribute(tab.id),
});

type MissionControlTabItem = TabItem & {
  readonly id: string;
  readonly label: AppNames;
  readonly to: string;
};

const useTabs = (): ReadonlyArray<MissionControlTabItem> => {
  const quickstartType = useSelector((s) => getProjectQuickstartType(s, getCurrentProject(s)));
  const shouldShowContentPace = useSelector(isContentPaceEnabled);
  const project = useSelector(getCurrentProject);
  const projectId = project.projectId;
  const selectedLanguageIdWithMacroFallback = useSelector(getSelectedLanguageIdOrRouteMacro);
  const userCapability = getUserCapability(project);

  return tabStructure
    .filter((tab) => {
      switch (tab.id) {
        case tabs.quickstart.id:
          return quickstartType === QuickstartType.Quickstart;
        case tabs.kickstart.id:
          return quickstartType === QuickstartType.Kickstart;
        case tabs.contentPace.id:
          return shouldShowContentPace;
        default:
          return true;
      }
    })
    .filter((tab) => userCapability.canOneOf(tab.requiresOneOfCapabilities))
    .map((tab) =>
      mapTabToMissionControlTabItem(tab, projectId, selectedLanguageIdWithMacroFallback),
    );
};

const Routes = () => {
  const getRedirectPropsWithSameSearch = useRedirectPropsWithSameSearch();
  const { projectId } = useParams<MissionControlLanguageAgnosticRouteParams>();
  const projectQuickstartType = useSelector((s) =>
    getProjectQuickstartType(s, getCurrentProject(s)),
  );
  const shouldShowContentPace = useSelector(isContentPaceEnabled);

  const quickstartTab = getQuickstartTab(projectQuickstartType);

  const defaultRoute = quickstartTab?.buildLink(projectId) ?? tabs.yourWork.buildLink(projectId);

  return (
    <Switch>
      {quickstartTab && (
        <Route path={quickstartTab.routePath}>
          <AuthorizedSection
            appName={quickstartTab.name}
            requiresOneOfCapabilities={quickstartTab.requiresOneOfCapabilities}
          >
            <Quickstart />
          </AuthorizedSection>
        </Route>
      )}
      <Route exact path={tabs.yourWork.routePath}>
        <AuthorizedSection
          appName={tabs.yourWork.name}
          requiresOneOfCapabilities={tabs.yourWork.requiresOneOfCapabilities}
        >
          <EnsureContentProductionCapabilities appName={tabs.yourWork.name}>
            <YourWork />
          </EnsureContentProductionCapabilities>
        </AuthorizedSection>
      </Route>
      <Route exact path={tabs.contentStatus.routePath}>
        <AuthorizedSection
          appName={tabs.contentStatus.name}
          requiresOneOfCapabilities={tabs.contentStatus.requiresOneOfCapabilities}
        >
          <EnsureContentProductionCapabilities appName={tabs.contentStatus.name}>
            <ContentStatusTabContent />
          </EnsureContentProductionCapabilities>
        </AuthorizedSection>
      </Route>
      <Route exact path={tabs.dashboard.routePath}>
        <AuthorizedSection
          appName={tabs.dashboard.name}
          requiresOneOfCapabilities={tabs.dashboard.requiresOneOfCapabilities}
        >
          <EnsureContentProductionCapabilities appName={tabs.dashboard.name}>
            <DashboardTabContent />
          </EnsureContentProductionCapabilities>
        </AuthorizedSection>
      </Route>
      {shouldShowContentPace && (
        <Route exact path={tabs.contentPace.routePath}>
          <AuthorizedSection
            appName={tabs.contentPace.name}
            requiresOneOfCapabilities={tabs.contentPace.requiresOneOfCapabilities}
          >
            <EnsureContentProductionCapabilities appName={tabs.contentPace.name}>
              <ContentPace />
            </EnsureContentProductionCapabilities>
          </AuthorizedSection>
        </Route>
      )}
      <Route path={MissionControlLanguageDependentRoute}>
        <LanguageDependentRoutes />
      </Route>
      <Route>
        <Redirect {...getRedirectPropsWithSameSearch({ to: defaultRoute })} />
      </Route>
    </Switch>
  );
};

const LanguageDependentRoutes = () => {
  const { projectId, variantId } = useParams<MissionControlLanguageDependentRouteParams>();

  return (
    <EnsureSelectedVariantIdInRoute
      key={`${projectId}${variantId}`}
      currentRouteVariantId={variantId}
      ensureSelectedVariant={ensureSelectedVariantForMissionControlLanguageDependentRoutes}
      getSelectedLanguageIdFromStore={getSelectedLanguageId}
      localizedRouteLeft={localizedRouteLeft}
    >
      <Switch>
        <Route exact path={tabs.projectOverview.routePath}>
          <AuthorizedSection
            appName={tabs.projectOverview.name}
            requiresOneOfCapabilities={tabs.projectOverview.requiresOneOfCapabilities}
          >
            <EnsureContentProductionCapabilities appName={tabs.projectOverview.name}>
              <ProjectOverviewPage />
            </EnsureContentProductionCapabilities>
          </AuthorizedSection>
        </Route>
        <Route exact path={tabs.calendar.routePath}>
          <AuthorizedSection
            appName={AppNames.Calendar}
            requiresOneOfCapabilities={tabs.calendar.requiresOneOfCapabilities}
          >
            <EnsureContentProductionCapabilities appName={tabs.calendar.name}>
              <EnsureSelectedArrangementForCalendar>
                <Calendar />
              </EnsureSelectedArrangementForCalendar>
            </EnsureContentProductionCapabilities>
          </AuthorizedSection>
        </Route>
      </Switch>
    </EnsureSelectedVariantIdInRoute>
  );
};
