import { px } from '@kontent-ai/component-library/tokens';
import { memoize } from '@kontent-ai/memoization';
import { animated, useTransition } from '@react-spring/web';
import Immutable from 'immutable';
import React, { useMemo } from 'react';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ICompiledContentType } from '../../../../../contentInventory/content/models/CompiledContentType.ts';
import { ICommentThreadItem } from '../../../../models/comments/CommentThreadItem.ts';
import { IInlineCommentThread } from '../../../../models/comments/CommentThreads.ts';
import {
  getElementFromContentTypes,
  getSelectedContentGroupIdFromStateOrFirst,
} from '../../../../stores/utils/contentItemElementsUtils.ts';
import { ICommentThreadWithLocation, isThreadSaved } from '../../../../utils/commentUtils.ts';
import { getItemElementCommentManager } from '../../../../utils/getItemElementCommentManager.ts';
import {
  IInlineCommentPaneOwnProps,
  InlineCommentPane as InlineCommentPaneComponent,
} from '../../components/comments/InlineCommentPane.tsx';
import {
  ContentItemPaneCommentListWidth,
  FullSizedEditorCommentsListWidth,
  FullSizedEditorCommentsPaneMargin,
} from '../../constants/uiConstants.ts';
import {
  getOrderedInlineComments,
  selectUnresolvedInlineCommentThreads,
} from '../../selectors/inlineCommentSelectors.ts';
import { CreateContentGroupTabsId } from '../../utils/contentGroupTabsId.ts';
import { CommentThreadsOnRemovedContentOnboarding } from './CommentThreadsOnRemovedContentOnboarding.tsx';

const createGetCommentThreadPosition =
  (loadedContentItemTypes: Immutable.Map<Uuid, ICompiledContentType>) =>
  (commentThread: IInlineCommentThread): number | null => {
    const element = getElementFromContentTypes(commentThread.elementId, loadedContentItemTypes);
    if (!element) {
      return Number.NaN;
    }

    const commentManager = getItemElementCommentManager(element.type);
    const offset = commentManager.getCommentThreadOffset(commentThread);

    return offset;
  };

const isCommentThreadActive = memoize.weak(
  (thread: ICommentThreadWithLocation, focusedCommentThreadId: Uuid | null) => {
    const commentThread = thread.commentThread;

    return (
      !isThreadSaved(commentThread) ||
      commentThread.id === focusedCommentThreadId ||
      commentThread.isInUndoResolvedState ||
      commentThread.isReplying ||
      commentThread.isSubmitting ||
      commentThread.threadItems.some(
        (item: ICommentThreadItem) => item.isEditing || item.isSubmitting,
      )
    );
  },
);

const getActiveCommentThreads = memoize.weak(
  (
    threads: ReadonlyArray<ICommentThreadWithLocation>,
    focusedCommentThreadId: Uuid | null,
  ): ReadonlyArray<ICommentThreadWithLocation> =>
    threads.filter((thread) => isCommentThreadActive(thread, focusedCommentThreadId)),
);

interface IInlineCommentPaneContainerProps extends IInlineCommentPaneOwnProps {
  readonly showsOnlyActiveComments?: boolean;
  readonly hasLeftMargin?: boolean;
}

export const InlineCommentPane: React.FC<IInlineCommentPaneContainerProps> = (props) => {
  const loadedContentItemTypes = useSelector((state) => state.contentApp.loadedContentItemTypes);

  const selectedContentGroupId = useSelector((state) => {
    const editedContentItem = state.contentApp.editedContentItem;
    if (!editedContentItem) {
      return null;
    }

    const contentItemId = editedContentItem.id;
    const editedContentItemType = loadedContentItemTypes.get(
      editedContentItem.editedContentItemTypeId,
    );
    const contentTypeGroups = editedContentItemType?.contentGroups ?? [];
    const contentGroupTabsId = CreateContentGroupTabsId.forContentItem(contentItemId);
    return getSelectedContentGroupIdFromStateOrFirst(contentGroupTabsId, contentTypeGroups, state);
  });

  const focusedCommentThreadId = useSelector(
    (state) => state.contentApp.editedContentItemVariantComments.focusedCommentThreadId,
  );

  const displayedContentGroupInlineCommentThreads = useSelector((state) => {
    const contentGroupInlineCommentThreads = getOrderedInlineComments(
      state,
      selectedContentGroupId,
    );
    return props.showsOnlyActiveComments
      ? getActiveCommentThreads(contentGroupInlineCommentThreads, focusedCommentThreadId)
      : contentGroupInlineCommentThreads;
  });

  const getCommentThreadPosition = useMemo(
    () => createGetCommentThreadPosition(loadedContentItemTypes),
    [loadedContentItemTypes],
  );

  const isVisible = useSelector((state) => {
    const itemHasInlineCommentThreads = !!selectUnresolvedInlineCommentThreads(state).length;
    return (
      state.contentApp.isCommentsPaneVisible &&
      (itemHasInlineCommentThreads || !!props.showsOnlyActiveComments)
    );
  });

  const transitions = useTransition(isVisible, {
    initial: {
      opacity: 1,
      maxWidth: px(
        props.hasLeftMargin ? FullSizedEditorCommentsListWidth : ContentItemPaneCommentListWidth,
      ),
    },
    from: {
      opacity: 0,
      maxWidth: '0px',
    },
    enter: {
      opacity: 1,
      maxWidth: px(
        props.hasLeftMargin ? FullSizedEditorCommentsListWidth : ContentItemPaneCommentListWidth,
      ),
    },
    leave: {
      opacity: 0,
      maxWidth: '0px',
    },
  });

  return (
    <>
      {transitions((style, item, transitionState) => {
        return (
          item && (
            <animated.div
              css={`margin-left: ${px(props.hasLeftMargin ? FullSizedEditorCommentsPaneMargin : 0)}`}
              className="content-item-pane__comment-pane comment-pane"
              key={transitionState.ctrl.id}
              style={style}
            >
              <InlineCommentPaneComponent
                {...props}
                contentGroupInlineCommentThreads={displayedContentGroupInlineCommentThreads}
                focusedCommentThreadId={focusedCommentThreadId}
                getCommentThreadPosition={getCommentThreadPosition}
                selectedContentGroupId={selectedContentGroupId}
              />
            </animated.div>
          )
        );
      })}
      <CommentThreadsOnRemovedContentOnboarding />
    </>
  );
};

InlineCommentPane.displayName = 'InlineCommentPaneContainer';
