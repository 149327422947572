import { notUndefined } from '@kontent-ai/utils';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ContentItemEditing_CommentThread_AiInlineThreadAdded } from '../../../../../applications/itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { getCommentThreadTopLevelElementId } from '../../../../../applications/itemEditor/features/ContentItemEditing/utils/getCommentThreadTopLevelElementId.ts';
import {
  createCommentContentServerModel,
  isComment,
} from '../../../../../applications/itemEditor/models/comments/Comment.ts';
import {
  CommentThreadItemType,
  ICommentThreadItem,
} from '../../../../../applications/itemEditor/models/comments/CommentThreadItem.ts';
import {
  ICommentThread,
  IInlineCommentThread,
  createCommentThreadDomainModel,
} from '../../../../../applications/itemEditor/models/comments/CommentThreads.ts';
import { isSuggestion } from '../../../../../applications/itemEditor/models/comments/Suggestion.ts';
import {
  CommentRequestModel,
  ICommentRequestModel,
  ISuggestionRequestModel,
} from '../../../../../repositories/interfaces/ICommentRepository.type.ts';
import { IAiCommentRepository } from '../../../repositories/IAiCommentRepository.type.ts';

const threadAdded = (createdThread: ICommentThread) =>
  ({
    type: ContentItemEditing_CommentThread_AiInlineThreadAdded,
    payload: {
      createdThread,
    },
  }) as const;

export type SaveNewAiInlineCommentThreadToServerActionsType = ReturnType<typeof threadAdded>;

interface IDeps {
  readonly aiCommentRepository: Pick<IAiCommentRepository, 'createAiCommentThread'>;
}

const createCommentThreadItemRequestModels = (
  threadItems: ReadonlyArray<ICommentThreadItem>,
): ReadonlyArray<CommentRequestModel> =>
  threadItems
    .map((threadItem) => {
      if (isComment(threadItem)) {
        return {
          type: CommentThreadItemType.Comment,
          content: createCommentContentServerModel(threadItem.content),
        } as ICommentRequestModel;
      }

      if (isSuggestion(threadItem)) {
        return {
          type: CommentThreadItemType.Suggestion,
          suggestedText: threadItem.suggestedText,
        } as ISuggestionRequestModel;
      }

      return;
    })
    .filter(notUndefined);

export const createSaveNewAiInlineCommentThreadToServerAction =
  (deps: IDeps) =>
  (thread: IInlineCommentThread, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      contentApp: { editedContentItem, editedContentItemVariant, editedContentItemVariantElements },
    } = getState();

    if (!thread || !editedContentItem || !editedContentItemVariant) {
      return;
    }

    const { itemId, variantId } = editedContentItemVariant.id;

    const threadItemRequestModels = createCommentThreadItemRequestModels(thread.threadItems);
    if (!threadItemRequestModels.length) {
      return;
    }

    const topLevelElementId = getCommentThreadTopLevelElementId(
      editedContentItem,
      editedContentItemVariantElements,
      thread,
    );

    const newCommentThreadServerModel = await deps.aiCommentRepository.createAiCommentThread(
      itemId,
      variantId,
      {
        id: thread.id,
        contentComponentId: thread.contentComponentId,
        elementId: thread.elementId,
        elementSegment: thread.elementSegment,
        externalSegmentId: thread.externalSegmentId,
        threadType: thread.threadType,
        threadItems: threadItemRequestModels,
        topLevelElementId,
      },
      abortSignal,
    );

    const newCommentThread = createCommentThreadDomainModel(newCommentThreadServerModel);
    dispatch(threadAdded(newCommentThread));
  };
