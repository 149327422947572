import { px } from '@kontent-ai/component-library/tokens';
import { useAttachRef } from '@kontent-ai/hooks';
import classNames from 'classnames';
import React, { forwardRef, memo, useContext, useRef, CSSProperties } from 'react';
import { usePopper } from 'react-popper';
import useResizeObserver from 'use-resize-observer';
import { defaultPopperModifiers } from '../../../../../../component-library/components/Dialogs/Popover/utils/tippyOptionsUtils.ts';
import { ScrollContainerContextProvider } from '../../../../../../component-library/components/ScrollContainer/ScrollContainerContext.tsx';
import { ScrollOptionsContextProvider } from '../../../../../_shared/components/AutoScroll/ScrollOptionsContext.tsx';
import {
  EditorPaperContext,
  useUpdatePaperContext,
} from '../../../../../_shared/contexts/EditorPaperContext.tsx';
import { IntersectionObserverRootContextProvider } from '../../../../../_shared/hooks/IntersectionObserverRootContext.tsx';
import { useFixAutoCompleteOnScroll } from '../../../../../_shared/hooks/useFixAutoCompleteOnScroll.ts';
import { usePopperRef } from '../../../../../_shared/hooks/usePopperRef.ts';
import { getReachedPaperThresholds } from '../../../../../_shared/utils/editorViewContext/editorPaperContext/utils/getReachedPaperThresholds.ts';
import { WebSpotlightZIndex } from '../../../../webSpotlight/constants/WebSpotlightZIndex.ts';
import { FloatingCommentsListWidth, ItemEditorScrollOptions } from '../constants/uiConstants.ts';
import { ContentItemElements } from './ContentItemElements.tsx';
import { ContentItemGroupTabs } from './ContentItemGroupTabs.tsx';
import { ContentItemHeader } from './ContentItemHeader.tsx';
import { ContentItemTabView } from './ContentItemTabView.tsx';
import { ElementLocksObserver } from './ElementLocksObserver.tsx';
import { InlineCommentPane } from './comments/InlineCommentPane.tsx';

type ContentItemEditorPaneProps = Readonly<{
  disabled: boolean;
  isCompact: boolean;
}>;

const ContentItemEditorPaneComponent = forwardRef<HTMLDivElement, ContentItemEditorPaneProps>(
  ({ disabled, isCompact }, ref) => {
    const { refObject: contentItemCanvasRef, refToForward: contentItemCanvasRefToForward } =
      useAttachRef(ref);
    const contentItemPaperRef = useRef<HTMLDivElement>(null);

    const isContentItemCanvasScrollable = contentItemCanvasRef.current
      ? contentItemCanvasRef.current.offsetWidth > contentItemCanvasRef.current.clientWidth // this works only if the canvas has no borders — KCL-8777
      : false;

    useFixAutoCompleteOnScroll(contentItemCanvasRef);

    // Do not render item content until paper context is updated because anything using it would rerender after it initializes
    // this mainly causes performance problems in case of many linked items
    const { isEditorPaperSet: canRenderPaperContent } = useUpdatePaperContext(contentItemPaperRef);

    const paperContext = useContext(EditorPaperContext);
    const reachedThresholds = getReachedPaperThresholds(paperContext.thresholds);

    const threadListRef = useRef<HTMLDivElement>(null);
    const { height: threadListHeight } = useResizeObserver({ ref: threadListRef });
    const [setCommentsContainerRef, commentsContainerRef] = usePopperRef<HTMLDivElement>();
    const { styles } = usePopper(contentItemPaperRef.current, commentsContainerRef.current, {
      placement: 'left-start',
      modifiers: defaultPopperModifiers,
    });

    return (
      <IntersectionObserverRootContextProvider rootRef={contentItemCanvasRef}>
        <ScrollContainerContextProvider
          scrollContainerRef={contentItemCanvasRef}
          tippyBoundaryRef={contentItemCanvasRef}
        >
          <div className="content-item-pane__canvas" ref={contentItemCanvasRefToForward}>
            <div
              className={classNames('content-item-pane', {
                'content-item-pane--is-disabled': disabled,
                'content-item-pane--is-small': reachedThresholds.sizeXS || reachedThresholds.sizeS,
                'content-item-pane--is-canvas-scrollable': isContentItemCanvasScrollable,
                'content-item-pane--top-spacing': !isCompact,
                'content-item-pane--has-reduced-spacing': isCompact,
              })}
              data-hj-suppress=""
            >
              <ScrollOptionsContextProvider scrollOptions={ItemEditorScrollOptions}>
                <div
                  className="content-item-pane__main-pane"
                  ref={contentItemPaperRef}
                  // We need to make sure that the editor content is at least as high as the comments list
                  // to make sure it can be scrolled till the end of the last comment
                  style={{ minHeight: threadListHeight }}
                >
                  {canRenderPaperContent && (
                    <ContentItemTabView>
                      <ContentItemHeader />
                      <ContentItemGroupTabs>
                        <ContentItemElements />
                      </ContentItemGroupTabs>
                      <ElementLocksObserver />
                    </ContentItemTabView>
                  )}
                </div>
                {!isCompact && <InlineCommentPane hasLeftMargin />}
              </ScrollOptionsContextProvider>
            </div>
          </div>
        </ScrollContainerContextProvider>
        {isCompact && (
          <FloatingCommentsContainer popperStyles={styles.popper} ref={setCommentsContainerRef}>
            <InlineCommentPane
              showsOnlyActiveComments
              threadListRef={threadListRef}
              scrollContainerRef={contentItemCanvasRef}
            />
          </FloatingCommentsContainer>
        )}
      </IntersectionObserverRootContextProvider>
    );
  },
);

type FloatingCommentsContainerProps = Readonly<{
  popperStyles: CSSProperties | undefined;
  children: React.ReactNode;
}>;

const FloatingCommentsContainer = React.forwardRef<HTMLDivElement, FloatingCommentsContainerProps>(
  ({ popperStyles, children }: FloatingCommentsContainerProps, forwardedRef) => (
    <div
      className="content-item-editor__floating-comments"
      css={`
        grid-area: preview;
        position: relative;
        overflow: hidden;
      `}
    >
      <div
        css={`
          width: ${px(FloatingCommentsListWidth)};
          z-index: ${WebSpotlightZIndex.CommentsPane}
        `}
        style={popperStyles}
        ref={forwardedRef}
      >
        {children}
      </div>
    </div>
  ),
);

export const ContentItemEditorPane = memo(
  ContentItemEditorPaneComponent,
  (prevProps, nextProps) =>
    prevProps.disabled === nextProps.disabled && prevProps.isCompact === nextProps.isCompact,
);
