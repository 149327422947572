import { AppMenuItem } from '@kontent-ai/component-library/NavigationBar';
import { memoize } from '@kontent-ai/memoization';
import { MissionControlOnboarding } from '../../../applications/missionControl/containers/MissionControlOnboarding.tsx';
import { AppNames } from '../../constants/applicationNames.ts';
import {
  ContentAppEntryRoute,
  ContentModelsRoute,
  CustomAppsRoute,
  EnvironmentRouteParams,
  EnvironmentSettingsRoute,
  MissionControlRootRoute,
  ProjectRouteParams,
  ProjectSettingsRoute,
  WebSpotlightEntryRoute,
} from '../../constants/routePaths.ts';
import { MainMenuItemConfig } from '../../containers/MainMenu/MainMenu.tsx';
import { DataUiAppName, getDataUiNavAttribute } from '../dataAttributes/DataUiAttributes.ts';
import { ICapability } from '../permissions/capability.ts';
import { getProjectEnvironmentRoutes } from '../routing/getProjectEnvironmentRoutes.ts';
import { buildPath } from '../routing/routeTransitionUtils.ts';

export const getMainMenuStructure = memoize.weak(
  (
    capability: ICapability,
    projectId: Uuid,
    projectContainerId: Uuid,
    canAccessAnyCustomApp: boolean,
    showSitemap: boolean,
    showWebSpotlight: boolean,
    showEnvironmentSettings: boolean,
    pathname: string,
  ): ReadonlyArray<AppMenuItem> => {
    const projectEnvironmentRoutes = getProjectEnvironmentRoutes(
      canAccessAnyCustomApp,
      showSitemap,
      showWebSpotlight,
      showEnvironmentSettings,
    );
    const menuStructure: Array<MainMenuItemConfig> = [];

    if (
      projectEnvironmentRoutes.missionControl &&
      capability.canOneOf(projectEnvironmentRoutes.missionControl.requiredCapabilities)
    ) {
      menuStructure.push({
        icon: 'Rocket',
        to: buildPath<EnvironmentRouteParams>(MissionControlRootRoute, { projectId }),
        label: AppNames.MissionControl,
        OnboardingComponent: MissionControlOnboarding,
        ...getDataUiNavAttribute(DataUiAppName.MissionControl),
      });
    }

    if (
      projectEnvironmentRoutes.webSpotlight &&
      capability.canOneOf(projectEnvironmentRoutes.webSpotlight.requiredCapabilities)
    ) {
      menuStructure.push({
        icon: 'Earth',
        to: buildPath<EnvironmentRouteParams>(WebSpotlightEntryRoute, { projectId }),
        label: AppNames.WebSpotlight,
        ...getDataUiNavAttribute(DataUiAppName.WebSpotlight),
      });
    }

    if (capability.canOneOf(projectEnvironmentRoutes.content.requiredCapabilities)) {
      menuStructure.push({
        icon: 'TwoSquaresWithLines',
        to: buildPath<EnvironmentRouteParams>(ContentAppEntryRoute, { projectId }),
        label: AppNames.Content,
        ...getDataUiNavAttribute(DataUiAppName.ContentInventory),
      });
    }

    if (capability.canOneOf(projectEnvironmentRoutes.contentModels.requiredCapabilities)) {
      menuStructure.push({
        icon: 'Binder',
        to: buildPath<EnvironmentRouteParams>(ContentModelsRoute, { projectId }),
        label: AppNames.ContentModels,
        ...getDataUiNavAttribute(DataUiAppName.ContentModels),
      });
    }

    if (
      projectId &&
      projectEnvironmentRoutes.customApps &&
      capability.canOneOf(projectEnvironmentRoutes.customApps.requiredCapabilities)
    ) {
      menuStructure.push({
        icon: 'PuzzlePlus',
        to: buildPath<EnvironmentRouteParams>(CustomAppsRoute, { projectId }),
        label: AppNames.CustomApps,
        ...getDataUiNavAttribute(DataUiAppName.CustomApps),
      });
    }

    if (capability.canOneOf(projectEnvironmentRoutes.environmentSettings.requiredCapabilities)) {
      menuStructure.push({
        icon: 'Cogwheel',
        to: projectId
          ? buildPath<EnvironmentRouteParams>(EnvironmentSettingsRoute, { projectId })
          : '',
        label: AppNames.EnvironmentSettings,
        ...getDataUiNavAttribute(DataUiAppName.EnvironmentSettings),
      });
    }

    if (projectContainerId) {
      menuStructure.push({
        icon: 'Cogwheels',
        to: buildPath<ProjectRouteParams>(ProjectSettingsRoute, { projectContainerId }),
        label: AppNames.ProjectSettings,
        ...getDataUiNavAttribute(DataUiAppName.ProjectSettings),
      });
    }

    return menuStructure.map(
      (mainMenuLinkInfo): AppMenuItem => ({
        ...mainMenuLinkInfo,
        id: mainMenuLinkInfo.to,
        isActive: pathname.includes(mainMenuLinkInfo.to),
      }),
    );
  },
);
