import { Collection } from '@kontent-ai/utils';

export const getItemsBetweenInclusive = (
  fromChecked: Uuid,
  toChecked: Uuid,
  allIds: ReadonlyArray<Uuid>,
): ReadonlySet<Uuid> => {
  const fromIndex = allIds.indexOf(fromChecked);
  const toIndex = allIds.indexOf(toChecked);

  if (fromIndex < 0 || toIndex < 0) {
    return new Set();
  }

  return new Set(
    fromIndex <= toIndex
      ? allIds.slice(fromIndex, toIndex + 1)
      : allIds.slice(toIndex, fromIndex + 1),
  );
};

export const rangeSelectionShouldSelect = (
  affectedIds: ReadonlySet<Uuid>,
  selectedItems: ReadonlySet<Uuid>,
  lastTouchedItemId: Uuid,
): boolean => {
  // Remove the "from" item to allow create unselect inside of the selected range
  const selected = Collection.remove(selectedItems, lastTouchedItemId);
  const toSelect = Collection.remove(affectedIds, lastTouchedItemId);

  const intersection = Collection.intersect(Array.from(selected), toSelect);

  // Deselect only in case toSelect are all already selected
  return intersection.length !== toSelect.size;
};
