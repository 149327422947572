import { SrOnly } from '@kontent-ai/component-library/styles';
import classNames from 'classnames';
import React from 'react';
import { IgnoreByGrammarly } from '../../../../../../../_shared/components/IgnoreByGrammarly.tsx';
import { getFileSizeObject } from '../../../../../../../_shared/utils/fileSizeUtils.ts';
import { getFileSizeOptionName } from '../../../../../../contentInventory/content/models/fileSizeOptions.ts';

interface IFormattedFileSize {
  readonly fileSizeInBytes?: number | null;
  readonly className?: string;
  readonly wrapperClassName?: string;
  readonly invalidClassName?: string;
  readonly messageBefore?: string;
}

export const FormattedFileSize: React.FC<IFormattedFileSize> = ({
  messageBefore,
  fileSizeInBytes,
  className,
  invalidClassName,
  wrapperClassName,
}: IFormattedFileSize): JSX.Element | null => {
  if (!fileSizeInBytes) {
    return null;
  }

  const fileSizeObject = getFileSizeObject(fileSizeInBytes);

  if (!fileSizeObject) {
    return null;
  }

  return (
    <span className={wrapperClassName}>
      {messageBefore}
      <span className={classNames(className, invalidClassName)}>
        <IgnoreByGrammarly>
          <SrOnly>size: </SrOnly>
          {fileSizeObject?.fileSize}&nbsp;
          {fileSizeObject && getFileSizeOptionName(fileSizeObject.unit)}
        </IgnoreByGrammarly>
      </span>
    </span>
  );
};

FormattedFileSize.displayName = 'FormattedFileSize';
