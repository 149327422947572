import { FC } from 'react';
import { ContentItemEditingEventOrigins } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { CopyFromLanguageOptions } from '../../containers/editingActions/actionSubmenuOptions/CopyFromLanguageOptions.tsx';
import { DuplicationOptions } from '../../containers/editingActions/actionSubmenuOptions/DuplicationOptions.tsx';
import { PreviewLinks } from '../../containers/editingActions/actionSubmenuOptions/PreviewLinks.tsx';
import { ShareItemOptions } from '../../containers/editingActions/actionSubmenuOptions/ShareItemOptions.tsx';
import { TranslateFromLanguageOptions } from '../../containers/editingActions/actionSubmenuOptions/TranslateFromLanguageOptions.tsx';
import { EditingAction } from '../../models/EditingAction.ts';

type EditingActionSubmenuItemsProps = {
  readonly activatedAction: EditingAction;
  readonly actionOrigin: ContentItemEditingEventOrigins;
};

export type EditingActionItemsProps = Pick<EditingActionSubmenuItemsProps, 'actionOrigin'>;

export const EditingActionSubmenuItems: FC<EditingActionSubmenuItemsProps> = (props) => {
  switch (props.activatedAction) {
    case EditingAction.copyFromLanguage:
      return <CopyFromLanguageOptions actionOrigin={props.actionOrigin} />;

    case EditingAction.previewItemInMultipleSpaces:
      return <PreviewLinks />;

    case EditingAction.shareLink:
      return <ShareItemOptions actionOrigin={props.actionOrigin} />;

    case EditingAction.showDuplicationOptions:
      return <DuplicationOptions actionOrigin={props.actionOrigin} />;

    case EditingAction.translateVariant:
      return <TranslateFromLanguageOptions />;

    default:
      return null;
  }
};
