import { Icons } from '@kontent-ai/component-library/Icons';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  Tab,
  TabGroup,
  TabItem,
  TabViewPanel,
  TabViewWrapper,
} from '@kontent-ai/component-library/TabView';
import { Spacing, colorAlertIcon } from '@kontent-ai/component-library/tokens';
import { InvariantException } from '@kontent-ai/errors';
import React, { ReactNode, useState } from 'react';
import { HtmlSettingsPageTitle } from '../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { PageTitle } from '../../../_shared/components/PageTitle.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentType } from '../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { SpacesMap } from '../../../data/models/space/space.ts';
import { EnvironmentSettingsAppNames } from '../../environmentSettings/root/constants/EnvironmentSettingsAppNames.ts';
import {
  PreviewConfigurationTab,
  PreviewConfigurationTabs,
} from '../constants/previewConfigurationTabs.ts';
import { PreviewUrlsSection } from '../containers/PreviewUrlsSection.tsx';
import { IPreviewUrlPattern } from '../models/IPreviewUrlPattern.type.ts';
import { IPreviewConfiguration, ISpaceDomain } from '../models/PreviewConfiguration.type.ts';
import {
  ContentTypeId,
  IPreviewUrlPatternError,
  SpaceRowId,
} from '../stores/IPreviewConfigurationAppStoreState.type.ts';
import { PreviewUrlsQuickTipSection } from './PreviewUrlsQuickTipSection.tsx';
import { SpaceDomains } from './SpaceDomains.tsx';
import { SpacesQuickTipSection } from './SpacesQuickTipSection.tsx';

type Props = {
  readonly contentTypes: ReadonlyArray<IContentType>;
  readonly onChangePreviewUrlPatterns: (
    previewUrlPatterns: ReadonlyMap<Uuid, ReadonlyArray<IPreviewUrlPattern>>,
  ) => void;
  readonly onChangeSpaceDomains: (spaceDomains: ReadonlyArray<ISpaceDomain>) => void;
  readonly previewConfiguration: IPreviewConfiguration | null;
  readonly previewUrlsSectionErrors: ReadonlyMap<
    ContentTypeId,
    ReadonlyMap<SpaceRowId, IPreviewUrlPatternError>
  >;
  readonly spaces: SpacesMap;
  readonly spacesAppRoute: string;
  readonly areSpacesEnabled: boolean;
};

export const PreviewConfiguration: React.FC<Props> = ({
  areSpacesEnabled,
  contentTypes,
  onChangePreviewUrlPatterns,
  onChangeSpaceDomains,
  previewConfiguration,
  previewUrlsSectionErrors,
  spaces,
  spacesAppRoute,
}) => {
  const [selectedTabItem, setSelectedTabItem] = useState<PreviewConfigurationTab>(
    PreviewConfigurationTabs.Spaces,
  );
  const previewUrlPatterns = new Map<Uuid, readonly IPreviewUrlPattern[]>(
    Array.from(
      previewConfiguration?.previewUrlPatterns.entries() ??
        new Map<Uuid, readonly IPreviewUrlPattern[]>(),
    ).filter(([, patterns]) => patterns.some((p) => p.enabled)),
  );

  if (!areSpacesEnabled) {
    return (
      <PreviewConfigurationWrapper>
        <Row spacing={Spacing.L}>
          <Column flexFactor={5} flexBasis={600} maxWidth={1000 + Spacing.L}>
            <PreviewUrlsSection
              contentTypes={contentTypes}
              errors={previewUrlsSectionErrors}
              onChange={onChangePreviewUrlPatterns}
              previewUrlPatterns={previewUrlPatterns}
              spaces={spaces}
            />
          </Column>
          <Column flexFactor={1} flexBasis={250}>
            <PreviewUrlsQuickTipSection />
          </Column>
        </Row>
      </PreviewConfigurationWrapper>
    );
  }

  const previewUrlsLeadingElement = previewUrlsSectionErrors.size ? (
    <Icons.ExclamationTriangleInverted color={colorAlertIcon} />
  ) : undefined;

  const tabItems: ReadonlyArray<TabItem> = [
    {
      id: PreviewConfigurationTabs.Spaces,
      label: 'Space domains',
    },
    {
      id: PreviewConfigurationTabs.PreviewUrls,
      label: 'Preview URLs for content types',
      leadingElement: previewUrlsLeadingElement,
    },
  ];

  const renderSelectedTab = (): ReactNode => {
    switch (selectedTabItem) {
      case PreviewConfigurationTabs.Spaces: {
        return (
          <SpaceDomains
            onChange={onChangeSpaceDomains}
            spaces={spaces}
            spacesAppRoute={spacesAppRoute}
            spacesDomains={previewConfiguration?.spaceDomains ?? []}
          />
        );
      }

      case PreviewConfigurationTabs.PreviewUrls: {
        return (
          <PreviewUrlsSection
            contentTypes={contentTypes}
            errors={previewUrlsSectionErrors}
            onChange={onChangePreviewUrlPatterns}
            previewUrlPatterns={previewUrlPatterns}
            spaces={spaces}
          />
        );
      }

      default:
        throw InvariantException(
          `${__filename}: Invalid selected preview configuration tab id: ${selectedTabItem}.`,
        );
    }
  };

  return (
    <PreviewConfigurationWrapper>
      <Row spacing={Spacing.L}>
        <Column flexFactor={5} flexBasis={600} maxWidth={1000 + Spacing.L}>
          <TabViewWrapper>
            <TabGroup keys={tabItems.map(({ id }) => id)}>
              {tabItems.map(({ id, ...item }, index) => (
                <Tab
                  key={id}
                  isActive={id === selectedTabItem}
                  isFirst={index === 0}
                  isLast={index === tabItems.length - 1}
                  onClick={() => setSelectedTabItem(id as PreviewConfigurationTab)}
                  {...item}
                />
              ))}
            </TabGroup>
            <TabViewPanel>{renderSelectedTab()}</TabViewPanel>
          </TabViewWrapper>
        </Column>
        <Column flexFactor={1} flexBasis={250}>
          {selectedTabItem === PreviewConfigurationTabs.PreviewUrls ? (
            <PreviewUrlsQuickTipSection />
          ) : (
            <SpacesQuickTipSection spacesAppRoute={spacesAppRoute} />
          )}
        </Column>
      </Row>
    </PreviewConfigurationWrapper>
  );
};

PreviewConfiguration.displayName = 'PreviewConfiguration';

type WrapperProps = {
  readonly children: ReactNode;
};

const PreviewConfigurationWrapper: React.FC<WrapperProps> = ({ children }) => (
  <div className="canvas__inner-section" {...getDataUiAppNameAttribute(DataUiAppName.PreviewUrls)}>
    <HtmlSettingsPageTitle settingsAppName={EnvironmentSettingsAppNames.PreviewConfiguration} />
    <Stack spacing={Spacing.XL}>
      <PageTitle>{EnvironmentSettingsAppNames.PreviewConfiguration}</PageTitle>
      {children}
    </Stack>
  </div>
);

PreviewConfigurationWrapper.displayName = 'PreviewConfigurationWrapper';
