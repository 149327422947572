import { alphabetically, createCompare } from '@kontent-ai/utils';
import { ITaxonomyGroup } from '../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { anyTaxonomyTaxonomy } from '../constants/anyTaxonomyTaxonomy.ts';
import { TaxonomyOption, mapTaxonomyToOption } from '../models/TaxonomyOption.ts';

export const getSortedTaxonomyOptionsListWithAnyTaxonomyFirst = (
  taxonomiesById: Immutable.Map<Uuid, ITaxonomyGroup>,
): ReadonlyArray<TaxonomyOption> =>
  [anyTaxonomyTaxonomy].concat(getSortedTaxonomies(taxonomiesById)).map(mapTaxonomyToOption);

const getSortedTaxonomies = (
  taxonomiesById: Immutable.Map<Uuid, ITaxonomyGroup>,
): ReadonlyArray<ITaxonomyGroup> =>
  taxonomiesById.toArray().sort(
    createCompare({
      compare: alphabetically,
      select: (x) => x.name,
    }),
  );
