import { ValidationConstants } from '../../../_shared/constants/validationConstants.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { IPlan } from '../../../data/models/plans/Plan.ts';
import { IProjectDetails } from '../../../data/models/projects/ProjectDetails.ts';
import { getProjectDetail } from '../../../data/reducers/projects/selectors/getProjectsForListing.ts';
import {
  getSubscriptionActiveProjectsCount,
  getSubscriptionPlan,
} from '../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { ICopyProjectDataInfo } from '../models/ICopyProjectDataInfo.type.ts';
import {
  ICopyProjectErrors,
  ICopyProjectValidationData,
  ITargetPlanLimits,
} from '../models/ICopyProjectValidationData.type.ts';

const ValidationResultForUnknownTargetSubscription: ICopyProjectErrors = {
  secureDeliveryApiViolated: true,
  maxLanguagesExceeded: true,
  maxCustomRolesExceeded: true,
  customElementsRestrictionViolated: true,
  collectionsRestrictionViolated: true,
  maxSubscriptionProjectsReached: true,
};

const DataLimitsForUnknownTargetPlan = {
  maxLanguages: 0,
  canContainCustomElements: false,
  maxSubscriptionProjects: 0,
  isSecureDeliveryApiEnabled: false,
};

const meetsTemplateLanguagesLimit = (plan: IPlan, actualLanguagesCount: number): boolean => {
  const maxLanguages = plan.features.maxActiveLanguages;
  return maxLanguages === null || maxLanguages >= actualLanguagesCount;
};

const meetsCustomRolesLimit = (plan: IPlan, selectedProjectTemplate: IProjectDetails): boolean => {
  if (plan.features.maxCustomRoles === null) {
    return true;
  }
  return selectedProjectTemplate.customRolesCount <= plan.features.maxCustomRoles;
};

const meetsCustomElementsLimit = (
  targetPlan: IPlan,
  calculatedSourceProjectDataInfo: ICopyProjectDataInfo,
): boolean => {
  return (
    !calculatedSourceProjectDataInfo.containsCustomElements ||
    targetPlan.features.areCustomElementsEnabled
  );
};

const meetsSubscriptionProjectsCountLimit = (
  plan: IPlan,
  currentSubscriptionProjectsCount?: number,
): boolean => {
  if (plan.features.maxSubscriptionProjects === null || !currentSubscriptionProjectsCount) {
    return true;
  }
  return plan.features.maxSubscriptionProjects > currentSubscriptionProjectsCount;
};

const getValidateCopySourceProjectResult = (
  calculatedSourceProjectDataInfo: ICopyProjectDataInfo | null,
  sourceProject?: IProjectDetails,
  targetPlan?: IPlan,
  targetSubscriptionId?: Uuid,
  currentSubscriptionProjectsCount?: number,
): ICopyProjectErrors => {
  if (!targetPlan || !targetSubscriptionId) {
    return ValidationResultForUnknownTargetSubscription;
  }

  const maxSubscriptionProjectsReached = !meetsSubscriptionProjectsCountLimit(
    targetPlan,
    currentSubscriptionProjectsCount,
  );

  if (!sourceProject) {
    return {
      secureDeliveryApiViolated: false,
      maxLanguagesExceeded: false,
      maxCustomRolesExceeded: false,
      customElementsRestrictionViolated: false,
      collectionsRestrictionViolated: false,
      maxSubscriptionProjectsReached,
    };
  }

  const maxLanguagesExceeded = !meetsTemplateLanguagesLimit(
    targetPlan,
    sourceProject.activeLanguagesCount,
  );
  const maxCustomRolesExceeded = !meetsCustomRolesLimit(targetPlan, sourceProject);

  if (!calculatedSourceProjectDataInfo) {
    return {
      secureDeliveryApiViolated: false,
      maxLanguagesExceeded,
      maxCustomRolesExceeded,
      customElementsRestrictionViolated: false,
      collectionsRestrictionViolated: false,
      maxSubscriptionProjectsReached,
    };
  }

  const secureDeliveryApiViolated =
    targetSubscriptionId !== sourceProject.subscriptionId &&
    calculatedSourceProjectDataInfo.isSecureDeliveryApiEnabled &&
    !targetPlan.features.isSecuredDeliveryApiAvailable;
  const customElementsRestrictionViolated = !meetsCustomElementsLimit(
    targetPlan,
    calculatedSourceProjectDataInfo,
  );
  const collectionsRestrictionViolated =
    targetSubscriptionId !== sourceProject.subscriptionId &&
    calculatedSourceProjectDataInfo.containsContentCollections &&
    !targetPlan.features.areContentCollectionsEnabled;

  return {
    secureDeliveryApiViolated,
    maxLanguagesExceeded,
    maxCustomRolesExceeded,
    customElementsRestrictionViolated,
    collectionsRestrictionViolated,
    maxSubscriptionProjectsReached,
  };
};

const getTargetPlanDataLimits = (targetPlan?: IPlan): ITargetPlanLimits => {
  if (!targetPlan) {
    return DataLimitsForUnknownTargetPlan;
  }

  return {
    maxLanguages: targetPlan.features.maxActiveLanguages,
    canContainCustomElements: targetPlan.features.areCustomElementsEnabled,
    maxSubscriptionProjects: targetPlan.features.maxSubscriptionProjects,
    isSecureDeliveryApiEnabled: targetPlan.features.isSecuredDeliveryApiAvailable,
  };
};

export const getCopyProjectValidationData = (
  state: IStore,
  sourceProjectId: Uuid,
  targetSubscriptionId: Uuid,
  copyProjectDataInfo: ICopyProjectDataInfo | null,
): ICopyProjectValidationData => {
  const sourceProjectDetail = sourceProjectId
    ? getProjectDetail(state, sourceProjectId)
    : undefined;
  const targetPlan = targetSubscriptionId
    ? getSubscriptionPlan(state, targetSubscriptionId)
    : undefined;
  const currentSubscriptionProjectsCount = targetSubscriptionId
    ? getSubscriptionActiveProjectsCount(state, targetSubscriptionId)
    : 0;

  const errors = getValidateCopySourceProjectResult(
    copyProjectDataInfo,
    sourceProjectDetail,
    targetPlan,
    targetSubscriptionId,
    currentSubscriptionProjectsCount,
  );

  return {
    targetPlanLimits: getTargetPlanDataLimits(targetPlan),
    validationErrors: errors,
  };
};

export type ICanProjectBeCopied = (
  copyProjectValidationData: ICopyProjectValidationData,
) => boolean;

export const canProjectBeCopied: ICanProjectBeCopied = (
  copyProjectValidationData: ICopyProjectValidationData,
): boolean => {
  const isWithoutErrors =
    !copyProjectValidationData.validationErrors ||
    Object.values(copyProjectValidationData.validationErrors).every((k) => !k);
  return isWithoutErrors;
};

const suffix = ' (copy)';
const ellipsisSuffix = '...';

export const createNameForClonedProject = (projectName: string): string => {
  const projectNameLength = projectName.length;
  const duplicateNameLength = suffix.length;
  const ellipsisLength = ellipsisSuffix.length;
  const excessiveCharacters =
    projectNameLength + duplicateNameLength - ValidationConstants.ProjectNameMaxLength;

  if (excessiveCharacters > 0) {
    const limitedProjectName = projectName.substring(
      0,
      projectNameLength - excessiveCharacters - ellipsisLength,
    );
    return `${limitedProjectName}${ellipsisSuffix}${suffix}`;
  }

  return `${projectName}${suffix}`;
};
