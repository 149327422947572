export enum AppNames {
  AccessDenied = 'Access Denied',
  AssetType = 'Asset type',
  Assets = 'Assets',
  Calendar = 'Editorial calendar',
  Content = 'Content & assets', // There is a non-breaking space between "&" and "Assets" to prevent from "&" jumps from one row to another when selecting this app in the navigation bar.
  ContentItemEditor = 'Content item editor',
  ContentItemRevisionViewer = 'Content item revision',
  ContentItems = 'Content',
  ContentModels = 'Content model',
  ContentPace = 'Content pace',
  ContentStatus = 'Content status',
  ContentTypeSnippets = 'Content type snippets',
  ContentTypes = 'Content types',
  Custom = 'Custom modules',
  CustomApps = 'Custom apps',
  EnvironmentSettings = 'Environment settings',
  ErrorHandling = 'Error handling',
  Kickstart = 'Kickstart',
  MissionControl = 'Mission Control',
  Dashboard = 'Dashboard',
  ProjectOverview = 'Project overview',
  ProjectSettings = 'Project settings',
  Projects = 'Projects',
  Quickstart = 'Quickstart',
  Relations = 'Relations',
  Settings = 'Settings',
  Sitemap = 'Sitemap',
  SmartLink = 'SmartLink',
  SubscriptionManagement = 'Subscription Management',
  Subscriptions = 'Subscriptions',
  Taxonomies = 'Taxonomies',
  UserProfile = 'Profile details',
  WebSpotlight = 'Web Spotlight',
  YourWork = 'Your work',
}

export const isAppName = (name: string): name is AppNames =>
  Object.values<string>(AppNames).includes(name);
