import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ISubscriptionRepository } from '../../../../../repositories/interfaces/ISubscriptionRepository.type.ts';
import { Subscription_BillingHistoryItems_Loaded } from '../../constants/actionTypes.ts';
import {
  IBillingHistoryItem,
  getBillingHistoryItemFromServerModel,
} from '../../models/BillingHistoryItem.ts';

interface ILoadBillingHistoryItemsDependencies {
  readonly subscriptionRepository: ISubscriptionRepository;
}

const billingHistoryItemsLoaded = (billingHistoryItems: ReadonlyArray<IBillingHistoryItem>) =>
  ({
    type: Subscription_BillingHistoryItems_Loaded,
    payload: {
      billingHistoryItems,
    },
  }) as const;

export type GetBillingHistoryItemsActionsType = ReturnType<typeof billingHistoryItemsLoaded>;

export const getBillingHistoryItemsCreator =
  (dependencies: ILoadBillingHistoryItemsDependencies) =>
  (subscriptionId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const billingHistoryItemServerModels =
      await dependencies.subscriptionRepository.getBillingHistoryItems(subscriptionId, abortSignal);
    const billingHistoryItems = billingHistoryItemServerModels.map((billingHistoryItem) =>
      getBillingHistoryItemFromServerModel(billingHistoryItem),
    );
    dispatch(billingHistoryItemsLoaded(billingHistoryItems));
  };
