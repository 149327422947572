import { memoize } from '@kontent-ai/memoization';
import { isAdmin } from '../../../_shared/utils/usersUtils.ts';
import { IProjectDetails } from '../../../data/models/projects/ProjectDetails.ts';
import { ICurrentUserInfo } from '../../../data/models/user/CurrentUserInfo.ts';
import { isCloningFailed, isCloningInProgress } from './copyStateUtils.ts';

const createProjectTemplateList = (
  userInfo: ICurrentUserInfo,
  projects: ReadonlyArray<IProjectDetails>,
): ReadonlyArray<IProjectDetails> => {
  if (!projects) {
    return [];
  }

  return projects
    .filter((project) => isAdmin(project.subscriptionAdmins, userInfo.userId))
    .filter((project) => !project.planIsExpired)
    .filter((project) => !project.inactive)
    .filter((project) => !isCloningInProgress(project.copyState))
    .filter((project) => !isCloningFailed(project.copyState));
};

const createProjectTemplateListMemoized = memoize.maxOne(createProjectTemplateList);

export { createProjectTemplateListMemoized as createProjectTemplateList };
