import {} from '@kontent-ai/DOM';
import { Direction } from '@kontent-ai/types';
import { announce } from '@react-aria/live-announcer';
import { EditorState, SelectionState } from 'draft-js';
import { useCallback } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { Apply } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import {
  BlockClassName,
  getBlockIdClassName,
} from '../../editorCore/utils/editorComponentUtils.ts';
import { isObjectBlockType } from '../../utils/blocks/blockType.ts';
import { getBaseBlockType } from '../../utils/blocks/editorBlockGetters.ts';
import {
  createSelection,
  getFullySelectedBlocks,
  moveSelectionFocus,
} from '../../utils/editorSelectionUtils.ts';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import {
  ExecuteCommand,
  KeyboardShortcutsPlugin,
} from '../keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { RichTextInputCommand } from '../keyboardShortcuts/api/EditorCommand.ts';
import { getCommandDirection } from '../keyboardShortcuts/api/editorCommandUtils.ts';
import {
  KeyCommandMap,
  richTextKeyCommandMap,
} from '../keyboardShortcuts/api/editorKeyboardUtils.ts';
import { WrapperPlugin } from '../visuals/WrapperPlugin.tsx';

export type CustomSelectionHandlingPlugin = DraftJsEditorPlugin<
  None,
  None,
  None,
  None,
  [WrapperPlugin, KeyboardShortcutsPlugin<RichTextInputCommand>]
>;

export const CustomSelectionHandlingPlugin: PluginComponent<CustomSelectionHandlingPlugin> = (
  props,
) => {
  const apply: Apply<CustomSelectionHandlingPlugin> = useCallback((state) => {
    const executeCommand: Decorator<ExecuteCommand<RichTextInputCommand>> =
      (baseExecuteCommand) => (command, isShiftPressed) => {
        switch (command) {
          case RichTextInputCommand.Focus: {
            state.executeChange((editorState) => {
              const content = editorState.getCurrentContent();
              const selection = editorState.getSelection();
              const selectedBlocks = getFullySelectedBlocks(content, selection);

              for (const block of selectedBlocks) {
                const blockKey = block.getKey();
                const editorDOM = state.getWrapperRef().current;

                const blockDOM = editorDOM?.querySelector(
                  `.${BlockClassName}.${getBlockIdClassName(blockKey)}`,
                );

                const focusableElement = blockDOM?.querySelector('[data-focusable="true"]');

                if (focusableElement) {
                  (focusableElement as HTMLElement).focus();
                  return editorState;
                }
              }

              return editorState;
            });

            return true;
          }
          case RichTextInputCommand.MoveCaretToNextBlock:
          case RichTextInputCommand.MoveCaretToPreviousBlock: {
            const direction = getCommandDirection(command);

            let isCommandUnhandled = false;
            state.executeChange((editorState) => {
              const content = editorState.getCurrentContent();
              const selection = editorState.getSelection();
              const focusBlockKey = selection.getFocusKey();
              const focusBlock = content.getBlockForKey(focusBlockKey);

              let anchorSelectionBlock = content.getBlockBefore(focusBlock.getKey());
              let focusSelectionBlock = content.getBlockAfter(focusBlock.getKey());
              if (direction === Direction.Backward) {
                anchorSelectionBlock = content.getBlockAfter(focusBlock.getKey());
                focusSelectionBlock = content.getBlockBefore(focusBlock.getKey());
              }

              const shouldUnselectBlock =
                anchorSelectionBlock && isObjectBlockType(getBaseBlockType(anchorSelectionBlock));
              const hasSelectedBlock = selection.getAnchorKey() !== selection.getFocusKey();
              if (shouldUnselectBlock && hasSelectedBlock) {
                const newSelection = SelectionState.createEmpty(focusBlock.getKey());
                return EditorState.forceSelection(editorState, newSelection);
              }

              const shouldSelectBlock =
                focusSelectionBlock && isObjectBlockType(getBaseBlockType(focusSelectionBlock));
              if (focusSelectionBlock && shouldSelectBlock) {
                const newSelection = moveSelectionFocus(
                  content,
                  selection,
                  focusSelectionBlock.getKey(),
                  1,
                );

                announce(
                  `Press ${findKeyByCommand(RichTextInputCommand.Focus, richTextKeyCommandMap)} to focus ${getBaseBlockType(focusSelectionBlock)}`,
                );

                return EditorState.forceSelection(editorState, newSelection);
              }

              isCommandUnhandled = true;
              return editorState;
            });

            return !isCommandUnhandled || baseExecuteCommand(command, isShiftPressed);
          }

          default:
            return baseExecuteCommand(command, isShiftPressed);
        }
      };

    state.executeCommand.decorate(executeCommand);

    return {};
  }, []);

  return useEditorWithPlugin(props, { apply });
};

function findKeyByCommand(
  targetCommand: RichTextInputCommand,
  keyCommandMap: KeyCommandMap<RichTextInputCommand>,
): string | undefined {
  for (const [key, value] of Object.entries(keyCommandMap)) {
    if (value?.command === targetCommand) {
      return key;
    }
  }
  return undefined;
}

export const returnFocusFromCustomBlock = (editorState: EditorState): EditorState => {
  const selection = editorState.getSelection();
  const newSelection = createSelection(selection.getAnchorKey(), 0, selection.getAnchorKey());
  return EditorState.forceSelection(editorState, newSelection);
};
