import React from 'react';
import { DataTable } from '../../../../../_shared/components/DataTable/DataTable.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { ListingMessage } from '../../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { pluralizeWithCount } from '../../../../../_shared/utils/stringUtils.ts';
import { IProjectContributor } from '../../../../../data/models/users/ProjectContributor.ts';
import { UserListingState } from '../../utils/getUserListingState.ts';
import { UserListingInfoMessage } from './UserListingInfoMessage.tsx';
import { UserListingRow } from './UserListingRow.tsx';

type Props = {
  readonly createUserDetailLink: (userId: Uuid) => string;
  readonly displayedUsers: ReadonlyArray<IProjectContributor>;
  readonly headData: ReadonlyArray<Column>;
  readonly state: UserListingState;
};

export const UserListingDataTable: React.FC<Props> = ({
  createUserDetailLink,
  displayedUsers,
  headData,
  state,
}) => {
  const infoMessage = <UserListingInfoMessage state={state} />;

  const showContent =
    state === UserListingState.EmptyProject || state === UserListingState.WithUsers;

  const headRow = showContent ? <DataTableHeadRow columns={headData} /> : undefined;

  return (
    <DataTable
      dataUiCollectionName={DataUiCollection.UsersList}
      header={headRow}
      infoMessage={infoMessage}
      title={
        <ListingMessage
          statusInfoMessage={{
            text: pluralizeWithCount('user', displayedUsers.length),
          }}
        />
      }
    >
      {showContent
        ? displayedUsers.map((contributor: IProjectContributor) => {
            const pathToUserDetail = createUserDetailLink(contributor.userId);

            return (
              <UserListingRow
                contributor={contributor}
                key={contributor.userId}
                pathToUserDetail={pathToUserDetail}
              />
            );
          })
        : null}
    </DataTable>
  );
};

UserListingDataTable.displayName = 'UserListingDataTable';
