import classNames from 'classnames';
import { PropsWithChildren } from 'react';

type ContentItemPaneElementsGroupProps = Readonly<{
  areHeaderContentGroups?: boolean;
  className?: string;
  isDisabled: boolean;
  isGuideline?: boolean;
  hasTopRoundedCorners?: boolean;
  hasBottomRoundedCorners?: boolean;
}>;

export const ContentItemPaneElementsGroup = ({
  areHeaderContentGroups,
  children,
  className,
  isDisabled,
  isGuideline,
  hasTopRoundedCorners,
  hasBottomRoundedCorners,
}: PropsWithChildren<ContentItemPaneElementsGroupProps>) => {
  return (
    <div
      className={classNames(className, 'content-item-pane__elements-group', {
        'content-item-pane__elements-group--is-disabled': isDisabled,
        'content-item-pane__elements-group--is-guideline': isGuideline,
        'content-item-pane__elements-group--has-top-rounded-corners': hasTopRoundedCorners,
        'content-item-pane__elements-group--has-bottom-rounded-corners': hasBottomRoundedCorners,
        'content-item-pane__elements-group--are-header-content-group-tabs': areHeaderContentGroups,
      })}
    >
      {children}
    </div>
  );
};
