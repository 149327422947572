import { InvariantException } from '@kontent-ai/errors';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { TimelineItem } from '../../../models/revisions/TimeLineItem.ts';

export const isRevisionCurrent = (
  entryTimeline: ReadonlyArray<TimelineItem> | null,
  revision: TimelineItem | null,
): boolean => {
  return !!entryTimeline && !!revision && entryTimeline[0]?.itemId === revision.itemId;
};

const isSelectedTimelineItem = (item: TimelineItem, clickedItemId: Uuid): boolean =>
  item.itemId === clickedItemId;

const hasRevisionId = (item: TimelineItem): boolean => !!item.revisionId;

export const getSelectedItemOrFallbackWithRevisionId = (
  timeline: ReadonlyArray<TimelineItem> | null,
  timelineItemId: Uuid,
): TimelineItem => {
  const firstTimelineItem = timeline?.[0];
  if (!timeline || !firstTimelineItem) {
    throw InvariantException('Timeline must not be empty and include at least the current item');
  }

  const selectedItemIndex = timeline.findIndex((item) =>
    isSelectedTimelineItem(item, timelineItemId),
  );
  if (selectedItemIndex < 0) return firstTimelineItem;

  const firstPrecedingTimelineItemWithRevisionId = timeline
    .slice(selectedItemIndex)
    .find(hasRevisionId);

  const reversedTimeline = timeline.toReversed();
  const firstFollowingTimelineItemWithRevisionId =
    reversedTimeline.slice(timeline.length - selectedItemIndex).find(hasRevisionId) || null;

  return (
    firstPrecedingTimelineItemWithRevisionId ||
    firstFollowingTimelineItemWithRevisionId ||
    firstTimelineItem
  );
};

export const shouldDisplayDiffForElement = (typeElement: TypeElement, variantId: Uuid): boolean =>
  variantId === DefaultVariantId || !typeElement.isNonLocalizable;

export const getUserForRevisionElement = (
  elementId: Uuid,
  variantId: Uuid,
  typeElement: TypeElement,
  elementsChangedBy: ReadonlyMap<Uuid, UserId> | undefined,
): UserId | undefined => {
  if (!shouldDisplayDiffForElement(typeElement, variantId)) {
    return undefined;
  }

  return elementsChangedBy?.get(elementId);
};
