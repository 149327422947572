import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { Data_SnippetUsages_Success } from '../../../constants/dataActionTypes.ts';

const initialState: Immutable.Map<Uuid, ReadonlyArray<Uuid>> = Immutable.Map();

export const snippetUsage = (
  state: Immutable.Map<Uuid, ReadonlyArray<Uuid>> = initialState,
  action: Action,
): Immutable.Map<Uuid, ReadonlyArray<Uuid>> => {
  switch (action.type) {
    case Data_SnippetUsages_Success: {
      const snippetUsageServerModel = action.payload.data;
      const snippetIds = Object.keys(snippetUsageServerModel);
      const snippetUsages = snippetIds.reduce((usages, snippetId) => {
        const typeSnippetUsage = snippetUsageServerModel[snippetId];
        return typeSnippetUsage ? usages.set(snippetId, typeSnippetUsage) : usages;
      }, Immutable.Map<Uuid, ReadonlyArray<Uuid>>());
      return snippetUsages;
    }

    case Shared_CurrentProjectId_Updated:
      return initialState;

    default:
      return state;
  }
};
