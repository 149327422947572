import { useAttachRef } from '@kontent-ai/hooks';
import { useTabPanel } from '@react-aria/tabs';
import { mergeProps } from '@react-aria/utils';
import { Key } from '@react-types/shared';
import { AriaTabPanelProps } from '@react-types/tabs';
import React from 'react';
import styled from 'styled-components';
import { Box } from '../../../layout/Box/Box.tsx';
import { shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { transition250 } from '../../../tokens/quarks/transitions.ts';
import { getDataUiComponentAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { useTabViewState } from '../utils.ts';

const TabViewBox = styled(Box).attrs({
  paddingTop: Spacing.XL,
})<{
  // TODO CL-374
  readonly $isFocusVisible: boolean;
}>`
  position: relative;
  border-top-left-radius: 0;

  &:before {
    border-radius: inherit;
    position: absolute;
    inset: 0;

    content: '';

    pointer-events: none;
    opacity: 0;
    ${shadowFocusStyles};
    transition: opacity ${transition250};
  }
`;

const FullWidthTabViewBox = styled(TabViewBox)`
  border-top-right-radius: 0;
`;

type TabViewPanelProps = AriaTabPanelProps & {
  readonly fullWidthTabs?: boolean;
};

export const TabViewPanel = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<TabViewPanelProps>
>(({ children, fullWidthTabs, ...otherProps }, ref) => {
  return fullWidthTabs ? (
    <FullWidthTabViewBox ref={ref} {...getDataUiComponentAttribute(TabViewPanel)} {...otherProps}>
      {children}
    </FullWidthTabViewBox>
  ) : (
    <TabViewBox ref={ref} {...getDataUiComponentAttribute(TabViewPanel)} {...otherProps}>
      {children}
    </TabViewBox>
  );
});

TabViewPanel.displayName = 'TabViewPanel';

type TabViewPanelWithStateProps = AriaTabPanelProps & {
  readonly children: (activeTabId: Key | null) => React.ReactNode;
};

export const TabViewPanelWithState = React.forwardRef<HTMLElement, TabViewPanelWithStateProps>(
  ({ children, ...props }, ref) => {
    const { refToForward, refObject } = useAttachRef(ref);
    const { tabListState, fullWidthTabs } = useTabViewState();
    const { tabPanelProps } = useTabPanel(props, tabListState, refObject);
    const mergedProps = mergeProps(props, tabPanelProps);

    const content = children(tabListState.selectedKey);
    if (!content) {
      return null;
    }

    return (
      <TabViewPanel ref={refToForward} fullWidthTabs={fullWidthTabs} {...mergedProps}>
        {content}
      </TabViewPanel>
    );
  },
);

TabViewPanelWithState.displayName = 'TabViewPanelWithState';
