import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { ICopyProjectDataInfoServerModel } from '../../../../repositories/serverModels/ICopyProjectDataInfoServerModel.type.ts';
import { canProjectBeCopied, getCopyProjectValidationData } from '../../utils/copyProjectUtils.ts';

export const validateProjectCopy =
  (
    sourceProjectId: Uuid,
    targetSubscriptionId: Uuid,
    copyProjectDataInfo: ICopyProjectDataInfoServerModel,
  ): ThunkPromise<{ readonly canCopy: boolean }> =>
  (_dispatch, getState) => {
    const copyProjectValidationData = getCopyProjectValidationData(
      getState(),
      sourceProjectId,
      targetSubscriptionId,
      copyProjectDataInfo,
    );

    return Promise.resolve({ canCopy: canProjectBeCopied(copyProjectValidationData) });
  };
