import Immutable from 'immutable';
import { CollectionsMap } from '../../../data/models/collections/Collection.ts';
import {
  ICollectionGroupRoles,
  ILanguageBoundRole,
  INormalizedRolesWithSettings,
} from '../../../data/models/roles/INormalizedRolesWithSettings.ts';
import { Capability } from './capability.ts';
import { getApplicableCollectionGroup } from './getContributorRole.ts';

const getLanguageIdsWithAllowedCapability = (
  languageBoundRoles: ReadonlyArray<ILanguageBoundRole>,
  allLanguageIds: Immutable.OrderedSet<Uuid>,
  capability: Capability,
): Immutable.Set<Uuid> => {
  const explicitlyDefinedLanguages = languageBoundRoles.flatMap(
    ({ languageIds }: ILanguageBoundRole) => languageIds,
  );
  const remainingLanguages = allLanguageIds.subtract(explicitlyDefinedLanguages).toArray();

  return Immutable.Set.of<Uuid>(
    ...languageBoundRoles
      .filter(({ role }) => role.capabilities.contains(capability))
      .flatMap(({ languageIds }) => (languageIds.length ? languageIds : remainingLanguages)),
  );
};

export const getLanguageIdsWithAllowedCapabilityForCollection = (
  userRoles: INormalizedRolesWithSettings,
  collectionId: Uuid | null,
  allLanguageIds: Immutable.OrderedSet<Uuid>,
  capability: Capability,
): Immutable.Set<Uuid> =>
  getLanguageIdsWithAllowedCapability(
    (collectionId
      ? getApplicableCollectionGroup(userRoles.collectionGroups, collectionId)?.roles
      : null) ?? [],
    allLanguageIds,
    capability,
  );

export const getLanguageIdsWithAllowedCapabilityInAnyCollection = (
  userRoles: INormalizedRolesWithSettings,
  allLanguageIds: Immutable.OrderedSet<Uuid>,
  capability: Capability,
  collections: CollectionsMap | null,
): Immutable.Set<Uuid> =>
  Immutable.Set.of<Uuid>(
    ...userRoles.collectionGroups
      .filter(
        (group: ICollectionGroupRoles) =>
          !collections ||
          group.collectionIds.length === 0 ||
          group.collectionIds.some((id) => collections.has(id)),
      )
      .flatMap((group: ICollectionGroupRoles) =>
        getLanguageIdsWithAllowedCapability(group.roles, allLanguageIds, capability).toArray(),
      ),
  );
