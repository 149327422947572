import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentModels_TypeEditor_FirstInvalidElement_Set,
  ContentModels_TypeEditor_InitStarted,
  ContentModels_TypeEditor_TypeLoaded,
} from '../../../shared/constants/sharedContentModelsActionTypes.ts';
import {
  ContentType_Creator_InitStarted,
  ContentType_Editor_ContentGroupCreated,
  ContentType_Editor_SelectedContentGroupChanged,
} from '../../constants/contentTypesActionTypes.ts';

const initialState = null;

export function selectedContentGroupId(
  state: Uuid | null = initialState,
  action: Action,
): Uuid | null {
  switch (action.type) {
    case ContentType_Editor_SelectedContentGroupChanged: {
      return action.payload.contentGroupId;
    }

    case ContentModels_TypeEditor_TypeLoaded: {
      const firstGroup = action.payload.editedContentType.contentGroups[0];
      return firstGroup ? firstGroup.id : null;
    }

    case ContentType_Editor_ContentGroupCreated: {
      return action.payload.contentGroup.id;
    }

    case ContentType_Creator_InitStarted:
    case ContentModels_TypeEditor_InitStarted: {
      return initialState;
    }

    case ContentModels_TypeEditor_FirstInvalidElement_Set: {
      return action.payload.element.contentGroupId || state;
    }

    default:
      return state;
  }
}
