import { Box } from '@kontent-ai/component-library/Box';
import { AnchorButton } from '@kontent-ai/component-library/Button';
import { Card } from '@kontent-ai/component-library/Card';
import { Divider, DividerDirection } from '@kontent-ai/component-library/Dividers';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography, colorTextDefault, px } from '@kontent-ai/component-library/tokens';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';

export const KickstartProjectQuickstart = () => (
  <div className="canvas__workspace">
    <div className="canvas__content">
      <div className="canvas__content-pane">
        <div
          className="canvas__inner-section canvas__inner-section--restricted-width canvas__inner-section--centered"
          style={{ maxWidth: 1100 }}
        >
          <div className="quickstart">
            <Box
              typography={Typography.UIParagraph}
              color={colorTextDefault}
              paddingTop={Spacing.XXXL}
            >
              <Stack spacing={Spacing.XXXL}>
                <VideoSection />
                <StepsSection />
              </Stack>
            </Box>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Video = () => {
  const [opacity, setOpacity] = useState(0);

  return (
    <>
      <Helmet>
        <script src="https://fast.wistia.com/embed/medias/as34yiyxby.jsonp" async />
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async />
      </Helmet>
      <div
        className="wistia_responsive_padding"
        style={{ padding: '56.25% 0 0 0', position: 'relative' }}
      >
        <div
          className="wistia_responsive_wrapper"
          style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}
        >
          <div
            className="wistia_embed wistia_async_as34yiyxby seo=false videoFoam=true"
            style={{ height: '100%', position: 'relative', width: '100%' }}
          >
            <div
              className="wistia_swatch"
              style={{
                height: '100%',
                left: 0,
                opacity,
                overflow: 'hidden',
                position: 'absolute',
                top: 0,
                transition: 'opacity 200ms',
                width: '100%',
              }}
            >
              <img
                src="https://fast.wistia.com/embed/medias/as34yiyxby/swatch"
                style={{ filter: 'blur(5px)', height: '100%', objectFit: 'contain', width: '100%' }}
                alt=""
                aria-hidden="true"
                onLoad={() => setOpacity(1)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const VideoSection = () => (
  <Card component="section">
    <Card.Body>
      <Row noWrap spacingX={Spacing.XL}>
        <Column>
          <Card.Headline>Kickstart your Kontent.ai journey!</Card.Headline>
          <Box>
            <p>
              Let’s create a fully functional healthcare landing page while learning the
              fundamentals of content management, modeling, and publishing. This hands-on experience
              is designed to help you quickly grasp the basics and start building with confidence.
            </p>

            <p>
              You’ll find step-by-step instructions, explanatory videos, and hands-on practice
              waiting for you in the Kontent.ai Learn portal.
            </p>

            <Box paddingTop={Spacing.XL}>
              <AnchorButton
                href="https://kontent.ai/learn/try-kontent-ai/kontent-ai-kickstart"
                buttonStyle="primary"
              >
                Let’s begin
              </AnchorButton>
            </Box>
          </Box>
        </Column>
        <Column width="2/5">
          <Video />
        </Column>
      </Row>
    </Card.Body>
  </Card>
);

const StepsSection = () => (
  <Card component="section">
    <Card.Headline>What’s ahead of you</Card.Headline>
    <Card.Body>
      <Row noWrap>
        <Column css="flex-basis: 25%" width="fit-content">
          <Step
            imagePath="quickstart/model-content-card.svg"
            title="Model the basics"
            descriptions={[
              'Learn how to build the foundation for a content-rich landing page.',
              'Set up taxonomies to tag content.',
            ]}
          />
        </Column>
        <Column width="fit-content">
          <Divider
            direction={DividerDirection.Vertical}
            offsetBefore={Spacing.XL}
            offsetAfter={Spacing.XL}
          />
        </Column>
        <Column css="flex-basis: 25%" width="fit-content">
          <Step
            imagePath="quickstart/create-content-card.svg"
            title="Create content"
            descriptions={[
              'Craft your first landing page and article.',
              'Ask AI to quickly draft content for you.',
            ]}
          />
        </Column>
        <Column width="fit-content">
          <Divider
            direction={DividerDirection.Vertical}
            offsetBefore={Spacing.XL}
            offsetAfter={Spacing.XL}
          />
        </Column>
        <Column css="flex-basis: 25%" width="fit-content">
          <Step
            imagePath="warnings/Assets.svg"
            customImageWidth={200}
            title="Assemble the page"
            descriptions={[
              'Bring your content together to showcase articles, services, and more.',
              'Add dynamic elements like videos and events to make your page more interactive.',
            ]}
          />
        </Column>
        <Column width="fit-content">
          <Divider
            direction={DividerDirection.Vertical}
            offsetBefore={Spacing.XL}
            offsetAfter={Spacing.XL}
          />
        </Column>
        <Column css="flex-basis: 25%" width="fit-content">
          <Step
            imagePath="quickstart/deliver-card.svg"
            title="Preview & publish"
            descriptions={[
              'See your content in its actual placement on the website.',
              'Go live by publishing your content.',
            ]}
          />
        </Column>
      </Row>
    </Card.Body>
  </Card>
);

type StepProps = {
  readonly imagePath: string;
  readonly title: string;
  readonly descriptions: ReadonlyArray<string>;
  readonly customImageWidth?: number;
};

const Step = (props: StepProps) => (
  <Stack align="center">
    <div
      css={`
        height: 200px;
        margin-bottom: 16px;
        ${props.customImageWidth ? `width: ${px(props.customImageWidth)};` : 'width: 100%;'}
        background: url('/images/${props.imagePath}') no-repeat center/contain;
      `}
    />
    <Box component="h2" typography={Typography.HeadlineMedium}>
      {props.title}
    </Box>

    <ul>
      <Box typography={Typography.BodyMedium} paddingTop={Spacing.L}>
        {props.descriptions.map((description, i) => (
          <li key={i}>{description}</li>
        ))}
      </Box>
    </ul>
  </Stack>
);
