import classNames from 'classnames';

type NoElementsElementProps = Readonly<{
  isDisabled: boolean;
}>;

export const NoElementsElement = ({ isDisabled }: NoElementsElementProps) => (
  <div
    className={classNames('content-item-element__wrapper', {
      'content-item-element__wrapper--is-disabled': isDisabled,
    })}
  >
    <div className="content-item-element content-item-element--is-disabled">
      <div className="content-item-element__info">There are no elements to fill in.</div>
    </div>
  </div>
);
