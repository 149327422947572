import { Action } from '../../../../../@types/Action.type.ts';
import { createTaxonomyGroupDomainModel } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import {
  Taxonomy_App_Initialized,
  Taxonomy_Groups_Loaded,
  Taxonomy_Groups_LoadingStarted,
} from '../../constants/taxonomyActionTypes.ts';

export const taxonomyGroups = (state: any = null, action: Action): any => {
  switch (action.type) {
    case Taxonomy_Groups_LoadingStarted:
      return null;

    case Taxonomy_App_Initialized:
    case Taxonomy_Groups_Loaded: {
      const loadedTaxonomyGroups = action.payload.taxonomyGroups;
      return loadedTaxonomyGroups.map(createTaxonomyGroupDomainModel);
    }

    default:
      return state;
  }
};
