import { isAbortError, isXMLHttpRequest } from '@kontent-ai/errors';
import { delay } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { touchProjectsDependencies } from '../../../../repositories/cacheKeys/projectCacheUtils.ts';
import { IProjectRepository } from '../../../../repositories/interfaces/IProjectRepository.type.ts';
import { CopyType } from '../../../../repositories/serverModels/IProjectServerModel.type.ts';
import { ProjectStatusPollingInterval } from '../../constants/copyState.ts';
import { INewProjectFormShape } from '../../models/INewProjectFormShape.type.ts';
import { isCloningDone, isCloningFailed } from '../../utils/copyStateUtils.ts';
import { waitUntilProjectIsActive } from '../../utils/projectUtils.ts';
import {
  failCloningProject,
  finishCloningProject,
  startCloningProject,
} from '../cloneProjectActions.ts';
import {
  projectCloningInitialized,
  projectCopyStateFinished,
  projectCopyStateStart,
  projectCopyStateUpdated,
} from '../projectsActions.ts';

interface IDeps {
  readonly projectRepository: IProjectRepository;
  readonly loadUserProjectsInfo: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadProjects: (abortSignal?: AbortSignal) => ThunkPromise;
}

export const startPollingCloningState = (
  projectRepository: IProjectRepository,
  projectId: Uuid,
  timeout: number,
  reloadProjects: (abortSignal?: AbortSignal) => ThunkPromise,
  abortSignal?: AbortSignal,
): ThunkPromise => {
  return async (dispatch, getState) => {
    dispatch(projectCopyStateStart(projectId));

    while (getState().projectsApp.cloningProjectsIds.keySeq().toArray().includes(projectId)) {
      const projectCopyState = await projectRepository.getCopyState(projectId, abortSignal);

      dispatch(projectCopyStateUpdated(projectId, projectCopyState));

      if (isCloningDone(projectCopyState)) {
        touchProjectsDependencies();
        await dispatch(reloadProjects(abortSignal));
      }

      if (isCloningDone(projectCopyState) || isCloningFailed(projectCopyState)) {
        dispatch(projectCopyStateFinished(projectId));
        return;
      }

      await delay(timeout);
    }
  };
};

export const startPollingForProjectCopyState = (
  projectRepository: IProjectRepository,
  reloadProjects: (abortSignal?: AbortSignal) => ThunkPromise,
  abortSignal?: AbortSignal,
): ThunkPromise => {
  return async (dispatch, getState) => {
    const cloningProjectIds = getState()
      .projectsApp.cloningProjectsIds.filter((isPolling) => !isPolling)
      .keySeq()
      .toArray();
    const promises = cloningProjectIds.map((projectId) =>
      dispatch(
        startPollingCloningState(
          projectRepository,
          projectId,
          ProjectStatusPollingInterval,
          reloadProjects,
          abortSignal,
        ),
      ),
    );
    await Promise.all(promises);
  };
};

export const createCloneProjectAction =
  (deps: IDeps) =>
  (formValues: INewProjectFormShape, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    try {
      dispatch(startCloningProject());

      const clonedProject = await deps.projectRepository.copyProject(
        formValues.projectTemplateId,
        {
          destinationLocationId: formValues.projectLocationId,
          projectName: formValues.projectName,
          destinationSubscriptionId: formValues.subscriptionId,
          includeContent: formValues.includeContent,
          copyType: CopyType.Clone,
        },
        abortSignal,
      );

      await waitUntilProjectIsActive(
        clonedProject.projectGuid,
        deps.projectRepository,
        abortSignal,
      );
      dispatch(projectCloningInitialized(clonedProject.projectGuid));
      await dispatch(deps.loadProjects(abortSignal));
      dispatch(
        startPollingCloningState(
          deps.projectRepository,
          clonedProject.projectGuid,
          ProjectStatusPollingInterval,
          deps.loadProjects,
          abortSignal,
        ),
      );
      dispatch(finishCloningProject());
      await dispatch(deps.loadUserProjectsInfo(abortSignal));
    } catch (error) {
      if (!isAbortError(error) && isXMLHttpRequest(error)) {
        dispatch(failCloningProject(formValues.projectTemplateId));
      }

      throw error;
    }
  };
