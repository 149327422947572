import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IContentItemElementWarningStatusProps {
  readonly id?: string;
  readonly isHidden?: boolean;
  readonly onClick?: MouseEventHandler;
}

export const ContentItemElementStatus: React.FC<
  React.PropsWithChildren<IContentItemElementWarningStatusProps>
> = ({ id, children, isHidden, onClick }): JSX.Element | null => {
  if (!children) {
    return null;
  }

  return (
    <div
      className={classNames('content-item-element__status', {
        'content-item-element__status--is-hidden': isHidden,
      })}
      id={id}
      onClick={onClick}
      {...getDataUiElementAttribute(DataUiElement.Status)}
    >
      {children}
    </div>
  );
};

ContentItemElementStatus.displayName = 'ContentItemElementStatus';
