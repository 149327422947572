import { Icons } from '@kontent-ai/component-library/Icons';
import { Menu } from '@kontent-ai/component-library/Menu';
import React from 'react';
import { ContentItemEditingEventOrigins } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { ActionType } from '../../../constants/editingActions/staticDetails.ts';
import { EditingAction as EditingActionEnum } from '../../../models/EditingAction.ts';
import { doesActionRepresentMenuWithMultipleOptions } from '../../../utils/editingActions/doesActionRepresentMenuWithMultipleOptions.ts';
import { EditingActionSubmenuItems } from '../EditingActionSubmenuItems.tsx';
import { EditingAction } from '../actions/EditingAction.tsx';
import { ActionsMenuItem } from './ActionsMenuItem.tsx';

type ActionsMenuItemToActionMapperProps = {
  readonly action: EditingActionEnum;
  readonly actionOrigin: ContentItemEditingEventOrigins;
};

export const ActionsMenuItemToActionMapper: React.FC<ActionsMenuItemToActionMapperProps> = (
  props,
) =>
  doesActionRepresentMenuWithMultipleOptions(props.action) ? (
    <Menu.Submenu>
      <EditingAction action={props.action}>
        {(details) => {
          const Icon = Icons[details.iconName];
          return (
            <>
              <Menu.SubmenuTrigger
                leadingElement={<Icon className="actions-menu-item__icon" />}
                id={details.caption}
                label={details.caption}
                {...details.dataUiAttribute}
              />
              <Menu.List>
                <EditingActionSubmenuItems
                  activatedAction={props.action}
                  actionOrigin={ContentItemEditingEventOrigins.MoreActions}
                />
              </Menu.List>
            </>
          );
        }}
      </EditingAction>
    </Menu.Submenu>
  ) : (
    <EditingAction action={props.action}>
      {(details) => (
        <ActionsMenuItem
          id={details.caption}
          badgeColor={details.badgeColor}
          badgeValue={details.badgeValue}
          dataAttributes={details.dataUiAttribute}
          disabled={details.isDisabled}
          iconName={details.iconName}
          isDestructive={details.actionType === ActionType.Destructive}
          onClick={() => details.performAction(props.actionOrigin)}
          outwardLinkUrl={details.outwardUrl}
          text={details.caption}
          tooltip={details.tooltip}
        />
      )}
    </EditingAction>
  );
