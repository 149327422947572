import Immutable from 'immutable';
import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getCannotCreateMessageForItem } from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { setContentItemSelection } from '../../../../contentInventory/content/features/ContentItemInventory/actions/thunkContentItemInventoryActions.ts';
import { WorkflowStatusWithFallbacksCellContent } from '../../../../contentInventory/content/features/ContentItemInventory/components/ItemInventoryScrollTable/Cells/ContentItemScrollTableCellsContents.tsx';
import { ContentItemScrollTableRow as ContentItemScrollTableRowComponent } from '../../../../contentInventory/content/features/ContentItemInventory/components/ItemInventoryScrollTable/ContentItemScrollTableRow.tsx';
import { isItemSelected } from '../../../../contentInventory/content/reducers/listingUi/itemsSelection/selectors/itemsSelectionSelectors.ts';
import { getCollection } from '../../../../contentInventory/content/selectors/getCollection.ts';
import { getContentItemListingHiddenColumns } from '../../../../contentInventory/content/selectors/getContentItemListingHiddenColumns.ts';
import { getContentType } from '../../../../contentInventory/content/selectors/getContentType.ts';
import { useSpaceData } from '../../../../environmentSettings/spaces/hooks/useSpaceData.ts';
import { variantSpecificColumns } from '../../constants/variantSpecificColumns.ts';

type Props = {
  readonly hideVariantSpecificInfo?: boolean;
  readonly index: number;
  readonly item: IListingContentItem;
  readonly itemIsAlreadyLinkedMsg?: string;
  readonly onItemDoubleClick?: (contentItemId: Uuid, variantId: Uuid) => void;
};

const isItemAlreadyLinked = (alreadyLinkedItems: Immutable.Set<Uuid>, itemId?: Uuid) =>
  !!itemId && !alreadyLinkedItems.contains(itemId);

export const MultipleContentItemsSelectorScrollTableRow: React.FC<Props> = memo((props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { item, onItemDoubleClick } = props;
  const itemId = item.item.id;
  const variantId = item.variant?.id.variantId ?? DefaultVariantId;

  const multipleContentItemsModalSelectorAlreadyLinkedItemIds = useSelector(
    (s) => s.contentApp.editorUi.multipleContentItemsModalSelectorAlreadyLinkedItemIds,
  );
  const itemLinkPath = getContentItemPath(history.location.pathname, item.item.id);
  const isAlreadyLinked = !isItemAlreadyLinked(
    multipleContentItemsModalSelectorAlreadyLinkedItemIds,
    item.item.id,
  );
  const isItemChecked =
    useSelector((s) => isItemSelected(s.contentApp.listingUi.itemsSelection, item.item.id)) ||
    isAlreadyLinked;

  const onDoubleClick = useCallback(
    () => onItemDoubleClick?.(itemId, variantId),
    [itemId, variantId, onItemDoubleClick],
  );
  const onToggleItemSelection = useCallback(
    (shiftPressed: boolean) =>
      dispatch(
        setContentItemSelection(
          item.item.id,
          !isItemChecked,
          shiftPressed,
          (i: IListingContentItem) =>
            isItemAlreadyLinked(multipleContentItemsModalSelectorAlreadyLinkedItemIds, i.item.id),
        ),
      ),
    [item.item.id, isItemChecked, multipleContentItemsModalSelectorAlreadyLinkedItemIds],
  );

  const searchPhrase = useSelector((s) => s.contentApp.listingUi.filter.searchPhrase);
  const hiddenColumns = useSelector(getContentItemListingHiddenColumns);
  const itemType = useSelector((s) => getContentType(s, item.item.typeId));
  const collectionName = useSelector((s) => getCollection(s, item.item.collectionId)?.name ?? '');
  const spaceNames = useSpaceData(props.item.item.collectionId);

  if (!itemType) {
    return null;
  }

  return (
    <ContentItemScrollTableRowComponent
      collectionName={collectionName}
      disabledCheckboxMessage={isAlreadyLinked ? props.itemIsAlreadyLinkedMsg : undefined}
      disabledClickMessage={isAlreadyLinked ? props.itemIsAlreadyLinkedMsg : undefined}
      searchPhrase={searchPhrase}
      hiddenColumns={props.hideVariantSpecificInfo ? variantSpecificColumns : hiddenColumns}
      hideVariantSpecificInfo={props.hideVariantSpecificInfo}
      index={props.index}
      isDisabled={isAlreadyLinked}
      isItemChecked={isItemChecked}
      item={item}
      itemLinkPath={itemLinkPath}
      itemType={itemType}
      onCheckboxClick={onToggleItemSelection}
      onItemClick={isAlreadyLinked ? undefined : onToggleItemSelection}
      onItemDoubleClick={isAlreadyLinked ? undefined : onDoubleClick}
      openInNewWindowDisabledMessage={getCannotCreateMessageForItem(item)}
      renderWorkflowStatus={() => (
        <WorkflowStatusWithFallbacksCellContent variant={item.variant} itemId={item.item.id} />
      )}
      showOpenInNewWindowCell
      spaceData={spaceNames}
    />
  );
});

MultipleContentItemsSelectorScrollTableRow.displayName =
  'MultipleContentItemsSelectorScrollTableRow';
