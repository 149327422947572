import { Box } from '@kontent-ai/component-library/Box';
import { Icons } from '@kontent-ai/component-library/Icons';
import { NotificationBarAlert } from '@kontent-ai/component-library/NotificationBar';
import { Spacing, gridUnit } from '@kontent-ai/component-library/tokens';
import { Link } from 'react-router-dom';
import { ModalDialog } from '../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { Warning } from '../../../../_shared/components/infos/Warning.tsx';
import { SubscriptionsRoute } from '../../../../_shared/constants/routePaths.ts';
import { IProjectLocation } from '../../../../_shared/models/ProjectLocation.ts';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IProjectDetails } from '../../../../data/models/projects/ProjectDetails.ts';
import { ISubscription } from '../../../../data/models/subscriptions/Subscription.ts';
import {
  SampleProjectLocationsLimited,
  SampleProjectLocationsUnavailable,
} from '../../constants/errorMessages.ts';
import {
  InitialProjectSelector,
  InitialProjectType,
} from '../../containers/projects/InitialProjectSelector.tsx';
import { Stage } from '../../containers/projects/NewProjectModal.tsx';
import { TemplateErrors } from '../../containers/projects/copyProjectErrors/TemplateErrors.tsx';
import { INewProjectFormShape } from '../../models/INewProjectFormShape.type.ts';
import { CreateProjectForm } from './CreateProjectForm.tsx';

type Props = {
  readonly activeSubscriptions: ReadonlyArray<ISubscription>;
  readonly availableProjectLocations: ReadonlyArray<IProjectLocation>;
  readonly availableProjectTemplates: ReadonlyArray<IProjectDetails>;
  readonly defaultProjectLocationId: Uuid;
  readonly defaultValues: INewProjectFormShape;
  readonly destinationSubscriptionId: Uuid;
  readonly errorMessage: string | null;
  readonly formProps: HookFormProps<INewProjectFormShape>;
  readonly canBeCopied: boolean;
  readonly creatingProject: boolean;
  readonly isProjectTemplateSelected: boolean;
  readonly onClose: () => void;
  readonly onExtraAction: () => void | undefined;
  readonly onInitialProjectSelectorChange: (projectType: InitialProjectType) => void;
  readonly onNotificationBarDismiss: () => void;
  readonly onPrimaryActionClick: (() => void) | undefined;
  readonly onSubmit: () => void;
  readonly progressMessage: string | null;
  readonly selectedProjectTemplateId: Uuid;
  readonly selectedSubscription: ISubscription | null;
  readonly sourceProjectId: Uuid | null;
  readonly stage: Stage;
  readonly submitButtonTooltipText: string | null;
};

export const NewProjectModal = (props: Props) => {
  const disableSubscriptionSelector = !!props.selectedSubscription;

  return (
    <ModalDialog
      isOpen
      minWidth={`min(90vw, ${maxModalWidth}px)`}
      isDismissable
      onClose={props.onClose}
      headline={createModalHeadline(props.stage)}
      extraAction={
        props.stage.name === 'projectDetails' && !props.sourceProjectId
          ? {
              text: 'Back',
              iconBefore: Icons.ArrowDoubleLeft,
              onClick: props.onExtraAction,
            }
          : undefined
      }
      primaryAction={{
        text: createPrimaryActionText(props.stage, props.progressMessage),
        iconAfter: props.stage.name === 'projectType' ? Icons.ArrowDoubleRight : undefined,
        type: 'submit',
        disabled:
          !props.stage.projectType ||
          props.creatingProject ||
          !!props.progressMessage ||
          (props.stage.name === 'projectDetails' && !!props.submitButtonTooltipText),
        tooltipText: props.submitButtonTooltipText,
        ...getDataUiActionAttribute(DataUiAction.CreateNew),
        onClick: props.onPrimaryActionClick,
      }}
      renderNotificationBar={
        props.errorMessage
          ? () => (
              <NotificationBarAlert onDismiss={props.onNotificationBarDismiss}>
                {props.errorMessage}
              </NotificationBarAlert>
            )
          : undefined
      }
    >
      {props.stage.name === 'projectType' && (
        <InitialProjectSelector
          selectedProjectType={props.stage.projectType}
          onChange={props.onInitialProjectSelectorChange}
        />
      )}

      {props.stage.name === 'projectDetails' && (
        <div css="min-width: 500px">
          <CreateProjectForm
            initialProjectType={props.stage.projectType}
            activeSubscriptions={props.activeSubscriptions}
            availableProjectTemplates={props.availableProjectTemplates}
            destinationSubscriptionId={props.destinationSubscriptionId}
            disableSubscriptionSelector={disableSubscriptionSelector}
            formProps={props.formProps}
            hasTemplateErrors={!props.canBeCopied}
            isProjectTemplateSelected={
              props.isProjectTemplateSelected || props.stage.projectType === InitialProjectType.Copy
            }
            onSubmit={props.onSubmit}
          />
          {!props.creatingProject && !props.canBeCopied && (
            <TemplateErrors
              selectedSubscriptionId={props.destinationSubscriptionId}
              sourceProjectId={props.selectedProjectTemplateId}
            />
          )}
          {props.activeSubscriptions.length === 0 && <SampleProjectLocationsUnavailableWarning />}
          {[InitialProjectType.Kickstart, InitialProjectType.MultiSite].includes(
            props.stage.projectType,
          ) &&
            props.activeSubscriptions.length > 0 && <SampleProjectLocationsLimitedWarning />}
        </div>
      )}
    </ModalDialog>
  );
};

const maxModalWidth = gridUnit * 120;

const createModalHeadline = (stage: Stage) => {
  if (stage.name === 'projectType') {
    return 'Choose a template for your new project';
  }
  switch (stage.projectType) {
    case InitialProjectType.Copy:
      return 'Copy project';
    case InitialProjectType.Blank:
      return 'Create a new project';
    case InitialProjectType.MultiSite:
      return 'Create a multi-site sample project';
    case InitialProjectType.Kickstart:
      return 'Create a kickstart sample project';
  }
};

const createPrimaryActionText = (stage: Stage, progressMessage: string | null) => {
  if (stage.name === 'projectType') {
    return '1/2 Next Step';
  }
  if (!progressMessage && stage.projectType === InitialProjectType.Copy) {
    return 'Copy project';
  }
  return progressMessage ?? 'Create project';
};

const SampleProjectLocationsLimitedWarning = () => (
  <Box paddingTop={Spacing.XL}>
    <Warning subtitle="Limited choice of subscriptions">
      <p>{SampleProjectLocationsLimited}</p>
    </Warning>
  </Box>
);

const SampleProjectLocationsUnavailableWarning = () => (
  <Box paddingTop={Spacing.XL}>
    <Warning subtitle="No subscriptions available">
      <p>{SampleProjectLocationsUnavailable}</p>
      <p>
        <Link to={buildPath(SubscriptionsRoute, {})}>Create a new subscription</Link>
        &nbsp;for the sample project.
      </p>
    </Warning>
  </Box>
);

NewProjectModal.displayName = 'NewProjectModal';
