import { Action } from '../../../@types/Action.type.ts';
import { AssetLibrary_Assets_ShowUnsupportedFileTypeError } from '../../../applications/contentInventory/assets/constants/assetLibraryActionTypes.ts';
import {
  ContentListing_MoveToArchivedStep_Started,
  ContentListing_MoveToCollection_Started,
  ContentListing_PublishItems_Failed,
  ContentListing_PublishItems_Started,
  ContentListing_RestoreFromArchivedStep_Started,
  ContentListing_ScheduledPublishItems_Failed,
  ContentListing_ScheduledPublishItems_Started,
  ContentListing_ScheduledUnpublishItems_Failed,
  ContentListing_ScheduledUnpublishItems_Started,
  ContentListing_UnpublishItems_Failed,
  ContentListing_UnpublishItems_Started,
  ContentListing_UpdateStandardStep_Started,
  ContentListing_UpdateWorkflowStep_Failed,
  ContentListing_UpdateWorkflowStep_Finished,
} from '../../../applications/contentInventory/content/features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import {
  Content_Editing_AssignmentSubmittingFailed,
  Content_Editing_AssignmentSubmittingFinished,
  Content_Editing_PublishContentItemVariantFailed,
  Content_Editing_PublishContentItemVariantStarted,
  Content_Editing_ScheduledPublishingFailed,
  Content_Editing_ScheduledPublishingStarted,
  Content_Editing_ScheduledUnpublishingFailed,
  Content_Editing_ScheduledUnpublishingStarted,
  Content_Editing_UnpublishContentItemVariantFailed,
  Content_Editing_UnpublishContentItemVariantStarted,
} from '../../../applications/itemEditor/constants/editorActionTypes.ts';
import {
  ContentEditing_CascadeAction_UndoFailed,
  ContentEditing_CascadeAction_UndoFinished,
  ContentEditing_CascadeAction_UndoStarted,
  ContentEditing_CascadeModal_PublishingFailed,
  ContentEditing_CascadeModal_PublishingFinished,
  ContentEditing_CascadeModal_PublishingStarted,
  ContentEditing_CascadeModal_SchedulingFailed,
  ContentEditing_CascadeModal_SchedulingFinished,
  ContentEditing_CascadeModal_SchedulingStarted,
} from '../../../applications/itemEditor/features/CascadePublish/constants/cascadeModalActionTypes.ts';
import {
  ContentItemEditing_CodenameSaving_Failed,
  ContentItemEditing_CodenameSaving_Finished,
  ContentItemEditing_CreateTask_Finished,
  ContentItemEditing_TaskArchive_Finished,
  ContentItemEditing_UpdateTask_Finished,
} from '../../../applications/itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { ContentEditing_MoveItemToCollection_Started } from '../../../applications/itemEditor/features/MoveToCollectionDialog/constants/moveToCollectionDialogActionTypes.ts';
import { ContentItemEditing_NewVariant_Started } from '../../../applications/itemEditor/features/NewVariantWorkflowSelectionDialog/constants/newVariantActionTypes.ts';
import { ModalDialogType } from '../../constants/modalDialogType.ts';
import {
  Shared_FeedbackModal_Submitted,
  Shared_ModalDismissed,
  Shared_ModalOpened,
  Shared_ModalOpenedWithProperties,
} from '../../constants/sharedActionTypes.ts';
import { ModalProperties } from '../../types/ModalDialogProperties.type.ts';

export type ModalDialogState = {
  readonly type: ModalDialogType;
  readonly properties: ModalProperties | null;
};

const initialState: ModalDialogState = {
  properties: null,
  type: ModalDialogType.None,
};

export function modalDialog(
  state: ModalDialogState = initialState,
  action: Action,
): ModalDialogState {
  switch (action.type) {
    case Shared_ModalOpened:
      return {
        type: action.payload.type,
        properties: null,
      };

    case Shared_ModalOpenedWithProperties:
      return {
        type: action.payload.properties.dialogType,
        properties: action.payload.properties,
      };

    case Content_Editing_PublishContentItemVariantFailed:
    case Content_Editing_UnpublishContentItemVariantFailed:
    case Content_Editing_ScheduledPublishingFailed:
    case Content_Editing_ScheduledUnpublishingFailed:
    case Content_Editing_AssignmentSubmittingFailed:
      return {
        type: ModalDialogType.ChangeWorkflowStepDialog,
        properties: action.payload.modalDialogProperties,
      };

    case ContentListing_ScheduledPublishItems_Failed:
    case ContentListing_ScheduledUnpublishItems_Failed:
    case ContentListing_UpdateWorkflowStep_Failed:
    case ContentListing_PublishItems_Failed:
    case ContentListing_UnpublishItems_Failed:
      return {
        type: ModalDialogType.ChangeWorkflowStepListingDialog,
        properties: null,
      };

    case ContentEditing_CascadeModal_PublishingFailed:
    case ContentEditing_CascadeModal_PublishingStarted:
    case ContentEditing_CascadeModal_SchedulingFailed:
    case ContentEditing_CascadeModal_SchedulingStarted:
    case ContentEditing_CascadeAction_UndoFailed:
    case ContentEditing_CascadeAction_UndoStarted:
    case ContentEditing_MoveItemToCollection_Started:
    case ContentItemEditing_NewVariant_Started:
    case ContentListing_PublishItems_Started:
    case ContentListing_UnpublishItems_Started:
    case ContentListing_ScheduledPublishItems_Started:
    case ContentListing_ScheduledUnpublishItems_Started:
    case ContentListing_UpdateWorkflowStep_Finished:
    case ContentListing_UpdateStandardStep_Started:
    case ContentListing_RestoreFromArchivedStep_Started:
    case ContentListing_MoveToArchivedStep_Started:
    case ContentListing_MoveToCollection_Started:
    case Content_Editing_AssignmentSubmittingFinished:
    case ContentItemEditing_TaskArchive_Finished:
    case ContentItemEditing_UpdateTask_Finished:
    case ContentItemEditing_CreateTask_Finished:
    case ContentItemEditing_CodenameSaving_Finished:
    case ContentItemEditing_CodenameSaving_Failed:
    case Content_Editing_PublishContentItemVariantStarted:
    case Content_Editing_UnpublishContentItemVariantStarted:
    case Content_Editing_ScheduledPublishingStarted:
    case Content_Editing_ScheduledUnpublishingStarted:
    case Shared_ModalDismissed:
      return initialState;

    case Shared_FeedbackModal_Submitted:
      return {
        ...initialState,
        type: ModalDialogType.ThanksForFeedbackDialog,
      };

    case AssetLibrary_Assets_ShowUnsupportedFileTypeError:
      return {
        ...initialState,
        type: ModalDialogType.UnsupportedAssetFileType,
      };

    case ContentEditing_CascadeAction_UndoFinished:
    case ContentEditing_CascadeModal_PublishingFinished: {
      return action.payload.failedItemIds.size > 0
        ? {
            ...initialState,
            type: ModalDialogType.CascadePublishFailedItemsDialog,
          }
        : state;
    }

    case ContentEditing_CascadeModal_SchedulingFinished:
      return action.payload.failedItemIds.size > 0
        ? {
            ...initialState,
            type: ModalDialogType.CascadeScheduleFailedItemsDialog,
          }
        : state;

    default:
      return state;
  }
}
