import { InvariantException, isAbortError, isXMLHttpRequest } from '@kontent-ai/errors';
import { usePrevious } from '@kontent-ai/hooks';
import { nameof } from '@kontent-ai/utils';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Dispatch } from '../../../..//@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { DefaultKickstartProjectName } from '../../../../_shared/constants/defaultNames.ts';
import { getDefaultProjectLocationId } from '../../../../_shared/constants/projectLocationIds.ts';
import { ProjectOrderBy } from '../../../../_shared/constants/projectOrderBy.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { useAuthToken } from '../../../../_shared/contexts/AuthTokenProvider.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { IProjectLocation } from '../../../../_shared/models/ProjectLocation.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { createFormValidationResolver } from '../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { nonEmptyValidationBuilder } from '../../../../_shared/utils/validation/isEmptyOrWhitespace.ts';
import {
  getProjectDetail,
  getProjectsForListing,
} from '../../../../data/reducers/projects/selectors/getProjectsForListing.ts';
import {
  getActiveSubscriptions,
  getAdministratedSubscriptions,
  getSelectedSubscription,
  getSubscriptionAvailableProjectLocations,
  getSubscriptionDefaultProjectLocation,
  getSubscriptionsSortedByName,
} from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import {
  clearCopyProjectDataInfo,
  closeCreateProjectModal,
} from '../../actions/projectsActions.ts';
import {
  cloneProject,
  createEmptyProject,
  createProjectFromTemplate,
  createSampleProject,
} from '../../actions/thunkProjectsActions.ts';
import { validateProjectCopy } from '../../actions/thunks/validateProjectCopy.ts';
import { NewProjectModal } from '../../components/projects/NewProjectModal.tsx';
import { SelectedSubscriptionProjectLimitReachedTooltipMessage } from '../../constants/UIConstants.ts';
import { SampleProjectType } from '../../constants/sampleProjectType.ts';
import { INewProjectFormShape } from '../../models/INewProjectFormShape.type.ts';
import { getCloneProjectFormDefaultValues } from '../../selectors/getCloneProjectFormDefaultValues.ts';
import { canProjectBeCopied, getCopyProjectValidationData } from '../../utils/copyProjectUtils.ts';
import { createProjectTemplateList } from '../../utils/createProjectTemplateList.ts';
import { InitialProjectType } from './InitialProjectSelector.tsx';

const emptyProjectLocations: IProjectLocation[] = [];

const { projectRepository } = repositoryCollection;

type Props = {
  readonly sourceProjectId: Uuid | null;
  readonly onClose: () => void;
};

const NewProjectModalContainer = (props: Props) => {
  const [stage, setStage] = useState<Stage>(
    props.sourceProjectId
      ? { name: 'projectDetails', projectType: InitialProjectType.Copy }
      : { name: 'projectType', projectType: null },
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [progressMessage, setProgressMessage] = useState<string | null>(null);

  const activeSubscriptions = useSelector((state) => {
    const { administratedIds, byId } = state.data.subscriptions;
    const administratedSubscriptions = getAdministratedSubscriptions(administratedIds, byId);
    const sortedAdministratedSubscriptions = getSubscriptionsSortedByName(
      administratedSubscriptions,
    );
    return getActiveSubscriptions(sortedAdministratedSubscriptions);
  });

  const availableProjectTemplates = useSelector((state) => {
    const { projects, user } = state.data;

    return createProjectTemplateList(
      user.info,
      getProjectsForListing(projects.byId, ProjectOrderBy.ProjectName, null),
    );
  });

  const selectedSubscription = useSelector(getSelectedSubscription);

  const selectedSubscriptionId = selectedSubscription
    ? selectedSubscription.subscriptionId
    : activeSubscriptions[0]?.subscriptionId || '';

  const defaultProjectLocationId = useSelector((state) => {
    const defaultProjectLocation = getSubscriptionDefaultProjectLocation(
      state,
      selectedSubscriptionId,
    );

    return defaultProjectLocation?.projectLocationId || getDefaultProjectLocationId();
  });

  const dispatch = useDispatch();

  const creatingProject = useSelector((state) => state.projectsApp.creatingProject);

  const defaultValues = useSelector<INewProjectFormShape>((s) => {
    switch (stage.projectType) {
      case InitialProjectType.Copy:
        return getCloneProjectFormDefaultValues(
          s,
          props.sourceProjectId ?? '',
          activeSubscriptions,
        );
      case InitialProjectType.Kickstart:
      case InitialProjectType.MultiSite:
      case InitialProjectType.Blank:
      case null:
        return {
          subscriptionId: selectedSubscriptionId,
          projectLocationId: getDefaultProjectLocationId(),
          includeContent: false,
          projectName: '',
          projectTemplateId: '',
        };
    }
  });

  const formProps = useForm<INewProjectFormShape>({
    defaultValues,
    resolver: createFormValidationResolver<INewProjectFormShape>(
      {
        projectName: [nonEmptyValidationBuilder('project name')],
      },
      {},
    ),
  });

  const { handleSubmit, setValue, watch, getFieldState } = formProps;

  const { getAuthToken } = useAuthToken();

  const validateProjectCreation = async (
    newProject: INewProjectFormShape,
  ): Promise<string | null> => {
    switch (stage.projectType) {
      case InitialProjectType.Blank: {
        const isCopyValid = await isCloneProjectDataInfoValid(
          newProject.projectTemplateId,
          newProject.subscriptionId,
          dispatch,
        );

        return newProject.projectTemplateId && !isCopyValid
          ? 'Project cannot be cloned. Please refresh the page and try it again.'
          : null;
      }
      case InitialProjectType.MultiSite: {
        return null;
      }
      case InitialProjectType.Kickstart: {
        return null;
      }
      case InitialProjectType.Copy: {
        const isCopyValid = await isCloneProjectDataInfoValid(
          newProject.projectTemplateId,
          newProject.subscriptionId,
          dispatch,
        );

        return isCopyValid
          ? null
          : 'Project cannot be cloned. Please refresh the page and try it again.';
      }
      case null:
        throw InvariantException('Project type is not selected');
    }
  };

  const createProject = async (newProject: INewProjectFormShape) => {
    switch (stage.projectType) {
      case InitialProjectType.Blank: {
        setProgressMessage('Creating project...');
        if (newProject.projectTemplateId) {
          await dispatch(createProjectFromTemplate(newProject));
          break;
        }
        await dispatch(createEmptyProject(newProject));
        break;
      }
      case InitialProjectType.MultiSite: {
        setProgressMessage('Creating sample project...');
        await dispatch(
          createSampleProject(
            newProject.subscriptionId,
            SampleProjectType.HealthTech,
            newProject.projectName,
            await getAuthToken(),
          ),
        );
        break;
      }
      case InitialProjectType.Kickstart: {
        setProgressMessage('Creating sample project...');
        await dispatch(
          createSampleProject(
            newProject.subscriptionId,
            SampleProjectType.Kickstart,
            newProject.projectName,
            await getAuthToken(),
          ),
        );
        break;
      }
      case InitialProjectType.Copy: {
        setProgressMessage('Copying project...');
        await dispatch(cloneProject(newProject));
        break;
      }
      case null:
        throw InvariantException('Project type is not selected');
    }
  };

  const submitForm = handleSubmit(async (newProject) => {
    const validationError = newProject.projectTemplateId
      ? await validateProjectCreation(newProject)
      : null;

    if (validationError) {
      setErrorMessage(validationError);
      setProgressMessage(null);
      return;
    }

    try {
      await createProject(newProject);
    } catch (error) {
      setProgressMessage(null);

      if (!isAbortError(error) && isXMLHttpRequest(error)) {
        setErrorMessage('The project wasn’t cloned as we’re unable to reach the servers.');
        return;
      }
      setErrorMessage(
        'An error occurred while creating the project. Please refresh the page and try again.',
      );
    }
  });

  const {
    subscriptionId: destinationSubscriptionId,
    includeContent: includeContentValue,
    projectLocationId: selectedProjectLocationId,
    projectTemplateId: selectedProjectTemplateId,
  } = watch();

  const prevSelectedProjectTemplateId = usePrevious(selectedProjectTemplateId);
  const prevIncludeContentValue = usePrevious(includeContentValue);

  const availableProjectLocations = useSelector((state) =>
    destinationSubscriptionId
      ? getSubscriptionAvailableProjectLocations(state, destinationSubscriptionId)
      : emptyProjectLocations,
  );

  useEffect(() => {
    // Ensure default or any available datacenter when location selection disabled or when destination subscription was changed and selected datacenter is not available
    if (
      !availableProjectLocations.find((loc) => loc.projectLocationId === selectedProjectLocationId)
    ) {
      const presetLocation =
        availableProjectLocations.find(
          (loc) => loc.projectLocationId === defaultProjectLocationId,
        ) ?? availableProjectLocations[0];
      setValue(
        nameof<INewProjectFormShape>('projectLocationId'),
        presetLocation?.projectLocationId ?? defaultProjectLocationId,
      );
    }
  }, [availableProjectLocations, selectedProjectLocationId, setValue, defaultProjectLocationId]);

  useEffect(() => {
    const selectedProjectTemplateChanged =
      selectedProjectTemplateId !== prevSelectedProjectTemplateId;
    const includeContentChanged = !includeContentValue && prevIncludeContentValue;

    if (selectedProjectTemplateChanged || includeContentChanged) {
      dispatch(clearCopyProjectDataInfo());
    }
  }, [
    selectedProjectTemplateId,
    prevSelectedProjectTemplateId,
    includeContentValue,
    prevIncludeContentValue,
  ]);

  const isProjectTemplateSelected = useSelector((state) => {
    const selectedProjectTemplate = selectedProjectTemplateId
      ? getProjectDetail(state, selectedProjectTemplateId)
      : undefined;

    return !!selectedProjectTemplate;
  });

  /* As we obtain copyProjectDataInfo data from server while trying to submit form, this limitation is not based on form state
  and form is not re-validated after this object is being received. Also it's impossible to start form validation manually.
  That's why we have to validate it separately from the rest of the form */
  const copyProjectValidationData = useSelector((state) =>
    getCopyProjectValidationData(
      state,
      selectedProjectTemplateId,
      destinationSubscriptionId,
      state.projectsApp.copyProjectDataInfo,
    ),
  );
  const canBeCopied = canProjectBeCopied(copyProjectValidationData);

  const subscriptionProjectsLimit =
    copyProjectValidationData.targetPlanLimits.maxSubscriptionProjects;
  const subscriptionProjectsLimitReached =
    copyProjectValidationData.validationErrors.maxSubscriptionProjectsReached;

  const getSubmitButtonTooltipText = (): string | null => {
    if (stage.name !== 'projectDetails' || creatingProject) {
      return null;
    }
    if (subscriptionProjectsLimitReached) {
      return SelectedSubscriptionProjectLimitReachedTooltipMessage(subscriptionProjectsLimit);
    }
    if (!canBeCopied) {
      return 'Limitations exceeded';
    }
    return null;
  };

  return (
    <NewProjectModal
      activeSubscriptions={activeSubscriptions}
      availableProjectLocations={availableProjectLocations}
      availableProjectTemplates={availableProjectTemplates}
      canBeCopied={canBeCopied}
      creatingProject={creatingProject}
      defaultProjectLocationId={defaultProjectLocationId}
      defaultValues={defaultValues}
      destinationSubscriptionId={destinationSubscriptionId}
      errorMessage={errorMessage}
      formProps={formProps}
      isProjectTemplateSelected={isProjectTemplateSelected}
      onClose={compose(dispatch, closeCreateProjectModal, props.onClose)}
      onExtraAction={() => setStage((prev) => ({ ...prev, name: 'projectType' }))}
      onPrimaryActionClick={() => {
        switch (stage.name) {
          case 'projectType': {
            if (!stage.projectType) {
              return;
            }
            if (
              stage.projectType === InitialProjectType.Kickstart &&
              !getFieldState('projectName').isDirty
            ) {
              setValue(nameof<INewProjectFormShape>('projectName'), DefaultKickstartProjectName);
            }
            setStage({ name: 'projectDetails', projectType: stage.projectType });
            break;
          }
          case 'projectDetails':
            submitForm();
            break;
        }
      }}
      onInitialProjectSelectorChange={(projectType) =>
        setStage((prev) => ({ ...prev, projectType }))
      }
      onNotificationBarDismiss={() => setErrorMessage(null)}
      onSubmit={submitForm}
      progressMessage={progressMessage}
      selectedProjectTemplateId={selectedProjectTemplateId}
      selectedSubscription={selectedSubscription}
      sourceProjectId={props.sourceProjectId}
      stage={stage}
      submitButtonTooltipText={getSubmitButtonTooltipText()}
    />
  );
};

const isCloneProjectDataInfoValid = async (
  sourceProjectId: Uuid,
  targetSubscriptionId: Uuid,
  dispatch: Dispatch,
): Promise<boolean> => {
  const copyProjectDataInfo = await projectRepository.getProjectDataInfo(sourceProjectId);

  const { canCopy } = await dispatch(
    validateProjectCopy(sourceProjectId, targetSubscriptionId, copyProjectDataInfo),
  );

  if (!canCopy) {
    const templateContentLimitExceededData = {
      'project-template-id': sourceProjectId,
      'has-custom-type-elements': copyProjectDataInfo.containsCustomElements,
    };
    dispatch(
      trackUserEventWithData(
        TrackedEvent.TemplateContentLimitExceeded,
        templateContentLimitExceededData,
      ),
    );
  }

  return canCopy;
};

export type Stage =
  | { readonly name: 'projectType'; readonly projectType: InitialProjectType | null }
  | { readonly name: 'projectDetails'; readonly projectType: InitialProjectType };

export { NewProjectModalContainer as NewProjectModal };
