import React from 'react';
import { PreviewContentItemInfo } from '../../../../utils/selectDeepestItemWithPreview.ts';
import { WebSpotlightPreviewInItemEditingContent as WebSpotlightPreviewInItemEditingContentComponent } from '../../components/contentItemPreview/WebSpotlightPreviewInItemEditingContent.tsx';
import { useWebSpotlightInItemEditing } from '../../context/WebSpotlightInItemEditingContext.tsx';

type Props = Readonly<{
  previewContentItemInfo: PreviewContentItemInfo | null;
  sharedPreviewUrl: string | null;
}>;

export const WebSpotlightPreviewInItemEditingContent: React.FC<Props> = ({
  previewContentItemInfo,
  sharedPreviewUrl,
}) => {
  const { previewMetadata } = useWebSpotlightInItemEditing();

  return (
    <WebSpotlightPreviewInItemEditingContentComponent
      previewContentItemInfo={previewContentItemInfo}
      previewMetadata={previewMetadata}
      sharedPreviewUrl={sharedPreviewUrl}
    />
  );
};
