import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';

export const ItemElementHeaderSection: React.FC<React.PropsWithChildren<NoProps>> = ({
  children,
}) => (
  <Box
    display="flex"
    flexWrap="wrap-reverse"
    justifyContent="space-between"
    alignItems="center"
    rowGap={Spacing.M}
    columnGap={Spacing.XL}
  >
    {children}
  </Box>
);

ItemElementHeaderSection.displayName = 'ItemElementHeaderSection';
