import Immutable from 'immutable';
import { IRole } from '../../../../data/models/roles/IRole.ts';
import { IProjectContributorRole } from '../../../../data/models/users/ProjectContributor.ts';
import { ILanguagesData } from '../../../../data/reducers/languages/ILanguagesData.type.ts';
import { getActiveLanguageIds } from '../../../../data/reducers/languages/selectors/getLanguages.ts';

export const createDisabledAddButtonTooltip = (
  userRoles: readonly IProjectContributorRole[],
  roles: ReadonlyArray<IRole>,
): string | undefined => {
  if (roles.length === 1) {
    return 'Create more roles.';
  }

  if (userRoles.length >= roles.length) {
    return 'You’ve already assigned all roles.';
  }

  return undefined;
};

export type DisabledLanguageDropDownTooltipParams = {
  readonly areLanguageRolesEnabled: boolean;
  readonly isRoleBuilderValid: boolean;
  readonly languages: ILanguagesData;
};

type FactoryDependencies = {
  readonly getActiveLanguageIds: (languages: ILanguagesData) => Immutable.OrderedSet<Uuid>;
};

export const createDisabledLanguageDropDownTooltipFactory =
  (deps: FactoryDependencies) =>
  (params: DisabledLanguageDropDownTooltipParams): string | undefined => {
    const { areLanguageRolesEnabled, isRoleBuilderValid, languages } = params;

    if (areLanguageRolesEnabled || !isRoleBuilderValid) {
      return undefined;
    }

    const activeLanguages = deps.getActiveLanguageIds(languages);

    if (activeLanguages.size <= 1) {
      return 'Your project has only one language. When you add more, the user will have this role in the new languages as well.';
    }

    return 'Your subscription plan prevents limiting roles to specific languages.';
  };

export const createDisabledLanguageDropDownTooltip = createDisabledLanguageDropDownTooltipFactory({
  getActiveLanguageIds,
});
