import { InvariantException } from '@kontent-ai/errors';
import Immutable from 'immutable';
import React, { useCallback } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getViewableContentGroups } from '../../../../../_shared/utils/contentItemUtils.ts';
import { getCanEditGroupContent } from '../../../selectors/getCanEditGroupContent.ts';
import { getEditedContentItemType } from '../../../selectors/getEditedContentItemType.ts';
import { getSelectedContentGroupIdFromStateOrFirst } from '../../../stores/utils/contentItemElementsUtils.ts';
import {
  PendingAiChangesGuard,
  TryLeaveCallback,
} from '../../PendingChangesDialog/containers/PendingAiChangesGuard.tsx';
import {
  focusCommentThread,
  selectContentGroup,
} from '../actions/thunkContentItemEditingActions.ts';
import {
  ContentItemTabView as ContentItemTabViewComponent,
  IContentItemTabViewOwnProps,
} from '../components/ContentItemTabView.tsx';
import { ContentGroupSelectionReason } from '../constants/ContentGroupSelectionReason.ts';
import { getContentGroupBadgeType } from '../selectors/getContentGroupBadgeType.ts';
import { getOrderedInlineComments } from '../selectors/inlineCommentSelectors.ts';
import { CreateContentGroupTabsId } from '../utils/contentGroupTabsId.ts';
import { isUnresolvedSavedInlineCommentThread } from '../utils/inlineCommentUtils.ts';
import {
  getIncompleteElementIdPaths,
  getIncompleteElementsPerGroup,
} from '../utils/itemValidationUtils.ts';

interface IContentItemTabViewContainerProps extends IContentItemTabViewOwnProps {
  readonly hideValidationStatus?: boolean;
}

export const ContentItemTabView: React.FC<IContentItemTabViewContainerProps> = (props) => {
  const { hideValidationStatus } = props;

  const editedContentItemType = useSelector((state) => getEditedContentItemType(state));

  const canEditGroupContent = useSelector((state) => getCanEditGroupContent(state));

  const contentGroupTabsId = useSelector((state) =>
    CreateContentGroupTabsId.forContentItem(state.contentApp.editedContentItem?.id ?? ''),
  );

  const editedContentItem = useSelector((state) => state.contentApp.editedContentItem);
  if (!editedContentItem || !editedContentItemType) {
    throw InvariantException(
      'ContentItemTabView.tsx container: "editedContentItem" or "editedContentItemType" is not loaded',
    );
  }

  const contentTypeGroups = useSelector((state) =>
    getViewableContentGroups(editedContentItemType, state.contentApp.editorUi),
  );

  const selectedContentGroupId = useSelector((state) =>
    getSelectedContentGroupIdFromStateOrFirst(contentGroupTabsId, contentTypeGroups, state),
  );

  const badgeType = useSelector((state) =>
    getContentGroupBadgeType(
      state.contentApp.showIncompleteItemWarningsBeforePublish,
      hideValidationStatus,
    ),
  );

  const incompleteElementsPerGroup = useSelector((state) => {
    if (hideValidationStatus) {
      return Immutable.Map<Uuid, number>();
    }

    const { itemValidationErrors, itemValidationWarnings, editedContentItemVariantElements } =
      state.contentApp;

    const incompleteElementIds = getIncompleteElementIdPaths(
      itemValidationErrors,
      itemValidationWarnings,
    );
    const contentTypeElements = editedContentItemType.contentElements;

    return getIncompleteElementsPerGroup(
      contentTypeGroups,
      editedContentItemVariantElements,
      contentTypeElements,
      incompleteElementIds,
      undefined,
    );
  });

  const dispatch = useDispatch();

  const contentTypeGroupsWithComments = useSelector((state) => {
    return contentTypeGroups.map((group) => {
      const unresolvedInlineCommentThreads = getOrderedInlineComments(
        state,
        group?.id,
        isUnresolvedSavedInlineCommentThread,
      );

      const onCommentsClick = () => {
        const firstCommentThreadId = unresolvedInlineCommentThreads[0]?.commentThread.id;
        if (firstCommentThreadId) {
          dispatch(focusCommentThread(firstCommentThreadId));
        }
      };

      return {
        ...group,
        onCommentsClick,
        commentCount: unresolvedInlineCommentThreads.length,
      };
    });
  });

  const onSelect = useCallback(
    (contentGroupId: Uuid) => {
      dispatch(
        selectContentGroup({
          contentGroupTabsId,
          contentGroupId,
          reason: ContentGroupSelectionReason.UserClick,
        }),
      );
    },
    [contentGroupTabsId],
  );

  const tryLeaveIfGroupChanged = useCallback(
    (tryLeave: TryLeaveCallback, groupId: Uuid): void => {
      if (groupId !== selectedContentGroupId) {
        tryLeave(() => onSelect(groupId));
      }
    },
    [onSelect, selectedContentGroupId],
  );

  return (
    <PendingAiChangesGuard
      renderContent={(tryLeave: TryLeaveCallback) => (
        <ContentItemTabViewComponent
          {...props}
          badgeType={badgeType}
          canEditContent={canEditGroupContent}
          contentTypeGroups={contentTypeGroupsWithComments}
          incompleteElementsPerGroup={incompleteElementsPerGroup}
          selectedContentGroupId={selectedContentGroupId}
          onSelect={(groupId) => tryLeaveIfGroupChanged(tryLeave, groupId)}
        >
          {props.children}
        </ContentItemTabViewComponent>
      )}
    />
  );
};

ContentItemTabView.displayName = 'ContentItemTabView';
