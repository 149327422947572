import React from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  getCurrentProjectContainer,
  getProjectPlan,
} from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { showCreateEnvironmentDialog } from '../actions/environmentsActions.ts';
import { EnvironmentsToolbarActions as EnvironmentsToolbarActionsComponent } from '../components/EnvironmentsToolbarActions.tsx';
import { getEnvironmentsForCloning } from '../selectors/getEnvironmentsForCloning.ts';
import {
  getDefaultEnvironmentToCloneFromId,
  getDisabledCreateButtonTooltipText,
  getEnvironmentsCount,
  isEnvironmentsLimitReached,
} from '../utils/environmentsUtils.ts';

export const EnvironmentsToolbarActions: React.FC = () => {
  const currentProject = useSelector((state) => getCurrentProjectContainer(state));
  const environmentsCount = useSelector((state) =>
    getEnvironmentsCount(state, currentProject.projectId),
  );
  const hasAnyEnvironmentToCloneFrom = useSelector(
    (state) => !!getEnvironmentsForCloning(state, currentProject.masterEnvironmentId).length,
  );
  const defaultCloneFromId = useSelector((state) =>
    getDefaultEnvironmentToCloneFromId(
      getEnvironmentsForCloning(state, currentProject.masterEnvironmentId),
      currentProject.masterEnvironmentId,
    ),
  );
  const maxProjectEnvironments = useSelector(
    (state) => getProjectPlan(state, currentProject.projectId).features.maxProjectEnvironments,
  );
  const planLimitReached = isEnvironmentsLimitReached(maxProjectEnvironments, environmentsCount);
  const tooltipText = getDisabledCreateButtonTooltipText(
    planLimitReached,
    hasAnyEnvironmentToCloneFrom,
  );

  const dispatch = useDispatch();
  const createEnvironment = () =>
    defaultCloneFromId && dispatch(showCreateEnvironmentDialog(defaultCloneFromId, true));

  return (
    <EnvironmentsToolbarActionsComponent
      onCreateEnvironment={createEnvironment}
      tooltipText={tooltipText}
      isDisabled={planLimitReached || !hasAnyEnvironmentToCloneFrom}
    />
  );
};
