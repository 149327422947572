import { EditorState, SelectionState } from 'draft-js';
import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { AiFollowingAction } from '../../../../../_shared/models/events/AiActionEventData.type.ts';
import { getCurrentProject } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { CONTENT_COMPONENT_MAX_NESTING_LEVEL } from '../../../../itemEditor/features/ContentItemEditing/constants/contentNestingConstants.ts';
import {
  EmptyContentComponents,
  IContentComponent,
} from '../../../../itemEditor/models/contentItem/ContentComponent.ts';
import { RichText_Paste_Failed } from '../../../constants/richTextActionTypes.ts';
import { PasteContentToRichTextErrorMessage } from '../../../constants/uiConstants.ts';
import { ObjectDataType } from '../../../utils/export/html/elements/objects.ts';
import { contentIsPlainText } from '../../../utils/general/editorContentUtils.ts';
import {
  EditorFeatureLimitations,
  EditorLimitations,
} from '../../apiLimitations/api/EditorFeatureLimitations.ts';
import {
  TopLevelBlockCategoryFeature,
  removeInvalidBlocks,
} from '../../apiLimitations/api/editorLimitationUtils.ts';
import { getMaxComponentDepth } from '../../contentComponents/api/editorContentComponentUtils.ts';
import { PasteContent } from '../api/EditorClipboardApi.type.ts';
import { IImportFromPastedText } from '../api/editorClipboardUtils.ts';

export interface IRichTextContent {
  readonly contentComponents: ReadonlyMap<Uuid, IContentComponent>;
  readonly editorState: EditorState;
}

export type IPasteContentToRichTextAction = (
  editorState: EditorState,
  params: PasteContentToRichTextParams,
  limitations?: EditorLimitations,
) => ThunkFunction<IRichTextContent>;

interface IPasteContentToRichTextDependencies {
  readonly importFromPastedText: IImportFromPastedText;
}

export type PasteContentToRichTextParams = {
  readonly isPlainText: boolean;
  readonly nestedLevel: number;
  readonly pasteContent: PasteContent;
  readonly pastedHtmlString: string;
  readonly pastedPlainText: string;
  readonly selection: SelectionState;
  readonly limitations: EditorFeatureLimitations;
};

const failed = (errorMessage: string) =>
  ({
    type: RichText_Paste_Failed,
    payload: { errorMessage },
  }) as const;

export type PasteToRichTextActionsType = ReturnType<typeof failed>;

export type PasteContentToRichTextActionWithTrackingPropsType = (
  elementId: Uuid,
  elementType: ElementType,
) => IPasteContentToRichTextAction;

export const createPasteContentToRichTextAction =
  ({ importFromPastedText }: IPasteContentToRichTextDependencies) =>
  (elementId: Uuid | null, elementType: ElementType | null): IPasteContentToRichTextAction =>
  (
    editorState,
    { isPlainText, limitations, pastedHtmlString, pasteContent, selection, nestedLevel },
  ) => {
    return (dispatch, getState) => {
      const currentProject = getCurrentProject(getState());

      const pastedContent = importFromPastedText(
        pastedHtmlString,
        isPlainText,
        currentProject.projectId,
      );

      const aiSessionId = pastedContent.pastedMetadata?.get(ObjectDataType.AiSession);
      if (aiSessionId && elementId && elementType) {
        dispatch(
          trackUserEventWithData(TrackedEvent.AiAction, {
            action: AiFollowingAction.SuggestionPasted,
            aiSessionId,
            elementId,
            elementType,
          }),
        );
      }

      // When we are pasting just plain text, we don't want to filter out the text wrapped into paragraph block in case some text blocks are allowed
      // This happens for example in case only list items are allowed, and we are pasting plain text into a list item
      const shouldFilterPastedContent =
        !contentIsPlainText(pastedContent.content) ||
        !limitations.allowedBlocks.has(TopLevelBlockCategoryFeature.Text);
      const allowedContent = shouldFilterPastedContent
        ? removeInvalidBlocks(pastedContent.content, limitations)
        : pastedContent.content;

      if (
        getMaxComponentDepth(allowedContent, pastedContent.contentComponents) + nestedLevel >
        CONTENT_COMPONENT_MAX_NESTING_LEVEL
      ) {
        dispatch(failed(PasteContentToRichTextErrorMessage(CONTENT_COMPONENT_MAX_NESTING_LEVEL)));
        return { editorState, contentComponents: EmptyContentComponents };
      }

      const allowedPastedContent = {
        ...pastedContent,
        content: allowedContent,
      };

      const newEditorState = pasteContent(editorState, selection, allowedPastedContent);
      const newContentComponents = pastedContent.contentComponents;

      return {
        editorState: newEditorState,
        contentComponents: newContentComponents,
      };
    };
  };
