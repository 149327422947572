import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { useLocation } from 'react-router';
import { BackNavigation } from '../../../_shared/components/AppBar/BackNavigation.tsx';
import { ContentItemRoute, ContentItemRouteParams } from '../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { getGoBackLinkByPath } from '../../../_shared/utils/appToolbar/appToolbarRouteUtils.ts';
import {
  matchPath,
  parseContentItemIds,
} from '../../../_shared/utils/routing/routeTransitionUtils.ts';

export const WebSpotlightContentItemBackNavigation: React.FC = () => {
  const location = useLocation();

  const goBackLink = useSelector((s) => {
    const lastOriginItem = Collection.getLast(s.contentInventory.breadcrumbsOrigin.items);
    return getGoBackLinkByPath(location.pathname, lastOriginItem?.path);
  });

  const isDisabled = useSelector((s) => {
    const isSaving = !Collection.isEmpty(s.contentApp.editedContentItemStatus.saving);
    const match = matchPath<ContentItemRouteParams<string>>(location.pathname, ContentItemRoute);
    const isTopItem = !match || parseContentItemIds(match.contentItemIds).length <= 1;
    return isSaving || isTopItem;
  });

  return <BackNavigation to={goBackLink} isDisabled={isDisabled} />;
};

WebSpotlightContentItemBackNavigation.displayName = 'WebSpotlightContentItemBackNavigation';
