import { FormGroup } from '../../../../_shared/components/input/FormGroup.tsx';
import { ValidatedInput } from '../../../../_shared/components/input/ValidatedInput.tsx';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.type.ts';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ISubscription } from '../../../../data/models/subscriptions/Subscription.ts';
import { InitialProjectType } from '../../containers/projects/InitialProjectSelector.tsx';
import { IBaseProject } from '../../models/IBaseProject.type.ts';
import { INewProjectFormShape } from '../../models/INewProjectFormShape.type.ts';
import { CopyProjectDocumentationLinks } from './CopyProjectDocumentationLinks.tsx';
import { CopyProjectDocumentationLinksExtended } from './CopyProjectDocumentationLinksExtended.tsx';
import { IncludeContentSelector } from './IncludeContentSelector.tsx';
import { ProjectLocationSelectorWrapper } from './ProjectLocationSelectorWrapper.tsx';
import { ProjectTemplateSelectorWrapper } from './ProjectTemplateSelectorWrapper.tsx';
import { SubscriptionSelectorWrapper } from './SubscriptionSelectorWrapper.tsx';

type Props = {
  readonly activeSubscriptions: ReadonlyArray<ISubscription>;
  readonly availableProjectTemplates: ReadonlyArray<IBaseProject>;
  readonly destinationSubscriptionId: Uuid;
  readonly disableSubscriptionSelector: boolean;
  readonly formProps: HookFormProps<INewProjectFormShape>;
  readonly hasTemplateErrors: boolean;
  readonly isProjectTemplateSelected: boolean;
  readonly onSubmit: (() => void) | undefined;
  readonly initialProjectType: InitialProjectType;
};

export const CreateProjectForm = (props: Props) => {
  return (
    <form className="form create-project-form" onSubmit={props.onSubmit}>
      <FormGroup>
        <ValidatedInput<INewProjectFormShape>
          name="projectName"
          label="Project name"
          maxLength={ValidationConstants.ProjectNameMaxLength}
          dataUiInputName={DataUiInput.ProjectName}
          autoFocus
          inputSuppressedInHotjar
          formProps={props.formProps}
        />
      </FormGroup>

      <FormGroup>
        <SubscriptionSelectorWrapper<INewProjectFormShape>
          activeSubscriptions={props.activeSubscriptions}
          disabled={props.disableSubscriptionSelector}
          formProps={props.formProps}
          name="subscriptionId"
        />
      </FormGroup>

      {[InitialProjectType.Blank, InitialProjectType.Copy].includes(props.initialProjectType) && (
        <FormGroup>
          <ProjectLocationSelectorWrapper<INewProjectFormShape>
            destinationSubscriptionId={props.destinationSubscriptionId}
            formProps={props.formProps}
            name="projectLocationId"
          />
        </FormGroup>
      )}

      {props.initialProjectType === InitialProjectType.Blank && (
        <FormGroup>
          <ProjectTemplateSelectorWrapper
            availableProjectTemplates={props.availableProjectTemplates}
            formProps={props.formProps}
            hasTemplateErrors={props.hasTemplateErrors}
          />
        </FormGroup>
      )}

      {[InitialProjectType.Blank, InitialProjectType.Copy].includes(props.initialProjectType) && (
        <FormGroup isCompact>
          {props.isProjectTemplateSelected ? (
            <>
              <IncludeContentSelector<INewProjectFormShape>
                formProps={props.formProps}
                name="includeContent"
              />
              <CopyProjectDocumentationLinksExtended />
            </>
          ) : (
            <CopyProjectDocumentationLinks />
          )}
        </FormGroup>
      )}
    </form>
  );
};

CreateProjectForm.displayName = 'CreateProjectForm';
