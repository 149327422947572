import { ReactNode } from 'react';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type CustomAppsProps = {
  readonly renderMenu: () => ReactNode;
  readonly renderCustomApp: () => ReactNode;
};

export const CustomApps = ({ renderMenu, renderCustomApp }: CustomAppsProps) => {
  return (
    <>
      <div className="canvas" {...getDataUiAppNameAttribute(DataUiAppName.CustomApps)}>
        <div className="canvas__workspace">
          {renderMenu()}
          <section className="canvas__content">{renderCustomApp()}</section>
        </div>
      </div>
    </>
  );
};
