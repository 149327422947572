import React, { useCallback } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getViewableContentGroups } from '../../../../../_shared/utils/contentItemUtils.ts';
import { ICompiledContentType } from '../../../../contentInventory/content/models/CompiledContentType.ts';
import { getSelectedContentGroupIdFromStateOrFirst } from '../../../stores/utils/contentItemElementsUtils.ts';
import { selectContentGroup } from '../actions/thunkContentItemEditingActions.ts';
import { ContentGroupTab } from '../components/ContentGroupTab.tsx';
import { ContentGroupTabs } from '../components/ContentGroupTabs.tsx';
import { ContentGroupSelectionReason } from '../constants/ContentGroupSelectionReason.ts';
import { getContentGroupBadgeType } from '../selectors/getContentGroupBadgeType.ts';
import { ContentGroupTabsId } from '../utils/contentGroupTabsId.ts';
import {
  getIncompleteElementIdPaths,
  getIncompleteElementsPerGroup,
} from '../utils/itemValidationUtils.ts';

interface IContentGroupTabsInLinkedItemProps {
  readonly canEditContent: boolean;
  readonly contentGroupTabsId: ContentGroupTabsId;
  readonly contentItemId: Uuid;
  readonly contentType: ICompiledContentType;
  readonly hideValidationStatus?: boolean;
}

export const ContentGroupTabsInLinkedItem: React.FC<IContentGroupTabsInLinkedItemProps> = (
  props,
) => {
  const { canEditContent, contentGroupTabsId, contentItemId, hideValidationStatus, contentType } =
    props;
  const contentTypeElements = contentType.contentElements;
  const contentItem = useSelector((state) =>
    state.contentApp.loadedContentItems.get(contentItemId),
  );
  const contentTypeGroups = contentItem ? getViewableContentGroups(contentType, contentItem) : [];
  const selectedContentGroupId = useSelector((state) =>
    getSelectedContentGroupIdFromStateOrFirst(contentGroupTabsId, contentTypeGroups, state),
  );

  const badgeType = useSelector((state) =>
    getContentGroupBadgeType(
      state.contentApp.showIncompleteItemWarningsBeforePublish,
      hideValidationStatus,
    ),
  );

  const incompleteElementsPerGroup = useSelector((state) => {
    if (!contentItem?.variant) {
      return Immutable.Map<Uuid, number>();
    }

    const { itemId, variantId } = contentItem.variant.id;

    const itemValidation = state.contentApp.loadedContentItemValidations
      .get(itemId)
      ?.get(variantId);
    if (!itemValidation) {
      return Immutable.Map<Uuid, number>();
    }

    const { errors, warnings } = itemValidation;
    const incompleteElementIds = getIncompleteElementIdPaths(errors, warnings);
    const elements = contentItem.variant?.elements;
    if (!elements) {
      return Immutable.Map<Uuid, number>();
    }

    return getIncompleteElementsPerGroup(
      contentTypeGroups,
      elements,
      contentTypeElements,
      incompleteElementIds,
      undefined,
    );
  });

  const dispatch = useDispatch();
  const onSelect = useCallback(
    (contentGroupId: Uuid) => {
      dispatch(
        selectContentGroup({
          contentGroupTabsId,
          contentGroupId,
          reason: ContentGroupSelectionReason.UserClick,
        }),
      );
    },
    [contentGroupTabsId],
  );

  return (
    <ContentGroupTabs
      {...props}
      badgeType={badgeType}
      canEditContent={canEditContent}
      contentTypeGroups={contentTypeGroups}
      incompleteElementsPerGroup={incompleteElementsPerGroup}
      selectedContentGroupId={selectedContentGroupId}
      tabItemComponent={ContentGroupTab}
      onSelect={onSelect}
    />
  );
};

ContentGroupTabsInLinkedItem.displayName = 'ContentGroupTabsInLinkedItem';
