import { Box } from '@kontent-ai/component-library/Box';
import { Button, QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing, colorTextDefault } from '@kontent-ai/component-library/tokens';
import { useAttachRef } from '@kontent-ai/hooks';
import { useDialog } from '@react-aria/dialog';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useFocusRing } from '@react-aria/focus';
import { chain, mergeProps } from '@react-aria/utils';
import classNames from 'classnames';
import React from 'react';
import {
  DataUiCommentsAction,
  DataUiElement,
  getDataUiCommentActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ICommentThread } from '../../../../models/comments/CommentThreads.ts';
import { getCommentThreadTriggerId } from '../../../../utils/commentUtils.ts';
import { CommentThreadCssClass } from '../../constants/uiConstants.ts';
import { CommentThreadFocusRing } from './CommentThreadFocusRing.tsx';
import { useAccessibleCommentThread } from './useAccessibleCommentThread.ts';

type ResolveUndoDialogProps = {
  readonly commentThread: ICommentThread;
  readonly isSuggestionThread: boolean;
  readonly onResolveUndo: () => void;
  readonly onResolveUndoCancel: () => void;
};

export const ResolveUndoDialog = React.forwardRef<HTMLDivElement, ResolveUndoDialogProps>(
  (props, forwardedRef) => {
    const { refObject } = useAttachRef(forwardedRef);

    const ariaLabel = `${props.isSuggestionThread ? 'Suggestion' : 'Comment'} resolved`;
    const { dialogProps, titleProps } = useDialog({ 'aria-label': ariaLabel }, refObject);

    const { commentThreadProps, onClose } = useAccessibleCommentThread(
      {
        'aria-label': ariaLabel,
        getCommentTriggerElement: () =>
          document.getElementById(getCommentThreadTriggerId(props.commentThread.id)),
        onInteractOutside: props.onResolveUndoCancel,
        onEscape: props.onResolveUndoCancel,
      },
      refObject,
    );

    const { isFocusVisible, focusProps: focusRingProps } = useFocusRing();

    return (
      <Box
        position="relative"
        className={classNames(CommentThreadCssClass, 'comment-thread__undo-dialog')}
        {...mergeProps(commentThreadProps, dialogProps, focusRingProps)}
        {...getDataUiElementAttribute(DataUiElement.CommentThread)}
      >
        <CommentThreadFocusRing isFocusVisible={isFocusVisible} />
        <Row spacing={Spacing.L} alignY="center" noWrap>
          <Column>
            <Inline spacing={Spacing.L} align="center">
              <Label size={LabelSize.L} color={colorTextDefault} {...titleProps}>
                {ariaLabel}
              </Label>
              <Button
                buttonStyle="tertiary"
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  props.onResolveUndo();
                }}
                {...getDataUiCommentActionAttribute(DataUiCommentsAction.ResolveUndo)}
              >
                Undo
              </Button>
            </Inline>
          </Column>
          <Column width="content">
            <QuinaryButton
              onClick={chain(props.onResolveUndoCancel, onClose)}
              tooltipText="Close"
              tooltipPlacement="top-start"
              {...getDataUiCommentActionAttribute(DataUiCommentsAction.ResolveUndoCancel)}
            >
              <QuinaryButton.Icon icon={Icons.Times} screenReaderText="Close" />
            </QuinaryButton>
          </Column>
        </Row>
      </Box>
    );
  },
);

ResolveUndoDialog.displayName = 'ResolveUndoDialog';
