import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { isAiSearchScope } from '../../../../../../../paperModels/aiSearch/utils/isAiSearchScope.ts';
import { FullTextSearchStatus } from '../../../../../shared/reducers/IContentInventoryStoreState.type.ts';
import { ContentItemSearchPhraseHighlighter as ContentItemSearchPhraseHighlighterComponent } from '../../components/ItemInventoryScrollTable/Cells/ContentItemSearchPhraseHighlighter.tsx';

type Props = {
  readonly highlightedName: string | null;
  readonly itemName: string;
  readonly searchPhrase: string | null;
};

export const ContentItemSearchPhraseHighlighter = ({
  highlightedName,
  itemName,
  searchPhrase,
}: Props) => {
  const shouldUseHighlightedName = useSelector(
    (state) =>
      searchPhrase &&
      !isAiSearchScope(state.contentApp.listingUi.filter.searchScope) &&
      state.contentInventory.fullTextSearchStatus === FullTextSearchStatus.Enabled,
  );

  return (
    <ContentItemSearchPhraseHighlighterComponent
      highlightedName={shouldUseHighlightedName ? highlightedName : undefined}
      itemName={itemName}
      searchPhrase={searchPhrase}
    />
  );
};
