import { Card } from '@kontent-ai/component-library/Card';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { BaseColor, Spacing, gridUnit } from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export enum InitialProjectType {
  Kickstart = 'Kickstart',
  MultiSite = 'MultiSite',
  Blank = 'Blank',
  Copy = 'Copy',
}

type Props = {
  readonly selectedProjectType: InitialProjectType | null;
  readonly onChange: (projectType: InitialProjectType) => void;
};

export const InitialProjectSelector = (props: Props) => {
  return (
    <CardsRow noWrap spacingX={Spacing.XL}>
      <Column>
        <KickstartProjectCard
          component="section"
          cardLabel="kickstart-project-option"
          isSelected={props.selectedProjectType === InitialProjectType.Kickstart}
          {...getDataUiElementAttribute(DataUiElement.KickstartProjectCard)}
        >
          <OptionCardContent
            title="Kickstart sample project"
            description="Follow our step-by-step Guided Trial to build a professional healthcare landing page quickly, with practical exercises and video instructions that make learning easy."
            imageName="rocket.svg"
            onClick={() => props.onChange(InitialProjectType.Kickstart)}
          />
        </KickstartProjectCard>
      </Column>
      <Column>
        <ProjectCard
          component="section"
          cardLabel="multisite-project-option"
          isSelected={props.selectedProjectType === InitialProjectType.MultiSite}
          {...getDataUiElementAttribute(DataUiElement.MultisiteProjectCard)}
        >
          <OptionCardContent
            title="Multi-site sample project"
            description="Dive into a robust, pre-configured multisite sample project designed to showcase complex content structures and advanced functionalities."
            imageName="quickstart/model-content-card.svg"
            onClick={() => props.onChange(InitialProjectType.MultiSite)}
          />
        </ProjectCard>
      </Column>
      <Column>
        <ProjectCard
          component="section"
          cardLabel="blank-project-option"
          isSelected={props.selectedProjectType === InitialProjectType.Blank}
          {...getDataUiElementAttribute(DataUiElement.BlankProjectCard)}
        >
          <OptionCardContent
            title="Blank project"
            description="Choose the Blank Project option if you’re familiar with the platform and want complete control over your content structure and design."
            imageName="content/plant.svg"
            onClick={() => props.onChange(InitialProjectType.Blank)}
          />
        </ProjectCard>
      </Column>
    </CardsRow>
  );
};

const CardsRow = styled(Row)`align-items: stretch;`;

type OptionCardProps = {
  readonly title: string;
  readonly description: string;
  readonly imageName: string;
  readonly onClick: () => void;
};

const OptionCardContent = (props: OptionCardProps) => (
  <div onClick={props.onClick}>
    <div
      css={`
        height: 200px;
        margin-bottom: 16px;
        background: url('/images/${props.imageName}') no-repeat center/contain;
      `}
    />
    <Card.Headline css="text-align: center">{props.title}</Card.Headline>
    <Card.Body css="text-align: center">{props.description}</Card.Body>
  </div>
);

const ProjectCardBase = styled(Card)`
  cursor: pointer;
  max-width: ${gridUnit * 51}px;
  height: 100%;
`;

const selectedCardStyles = `
  background-color: ${BaseColor.DeepPurple10};
  border-color: ${BaseColor.DeepPurple60};
`;

const ProjectCard = styled(ProjectCardBase)<{ readonly isSelected: boolean }>`
  ${({ isSelected }) => isSelected && selectedCardStyles}
`;

const selectedKickstartCardStyles = `
  background-color: ${BaseColor.Orange10};
  border-color: ${BaseColor.Orange60};
`;

const KickstartProjectCard = styled(ProjectCardBase)<{ readonly isSelected: boolean }>`
  ${({ isSelected }) => isSelected && selectedKickstartCardStyles}
`;
