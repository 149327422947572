import { getCurrentProject } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { EnvironmentMenuItem } from '../../components/ProjectMenu/selects/EnvironmentSelect.tsx';
import { EnvironmentRouteParams, EnvironmentSettingsRoute } from '../../constants/routePaths.ts';
import { getEnvironmentsForMenu } from '../../selectors/getEnvironmentsForMenu.ts';
import { IStore } from '../../stores/IStore.type.ts';
import { DataUiAppName, getDataUiNavAttribute } from '../dataAttributes/DataUiAttributes.ts';
import { createEnvironmentsLink } from '../routing/projectRoutingUtils.ts';
import { buildPath } from '../routing/routeTransitionUtils.ts';

export type EnvironmentOption = {
  readonly environmentId: Uuid;
  readonly environmentName: string;
  readonly hasAccessToSettings: boolean;
  readonly link: string;
};

export const manageEnvironmentsItemId = 'environments-pinned';

export const getEnvironments = (
  state: IStore,
  pathname: string,
): ReadonlyArray<EnvironmentOption> => {
  const {
    data: {
      user: { projectsInfoById },
    },
    projectsApp: { projectsUiTransitionState },
  } = state;
  const currentMasterEnvironmentId = getCurrentProject(state).masterEnvironmentId;

  return getEnvironmentsForMenu(
    projectsInfoById,
    projectsUiTransitionState,
    currentMasterEnvironmentId,
    pathname,
    state,
  );
};

export const getEnvironmentItems = (
  environments: ReadonlyArray<EnvironmentOption>,
  currentEnvironmentId: string,
  currentProjectContainerId: string,
): ReadonlyArray<EnvironmentMenuItem> =>
  environments
    .map(
      (e): EnvironmentMenuItem => ({
        dataAttributes: getDataUiNavAttribute(e?.environmentName as DataUiAppName),
        id: e?.environmentId,
        isSelected: e?.environmentId === currentEnvironmentId,
        label: e?.environmentName,
        link: e?.link,
        settingsEnabled: e.hasAccessToSettings,
        settingsLink: getEnvironmentSettingsPath(e?.environmentId),
        type: 'item',
      }),
    )
    .concat([
      {
        dataAttributes: getDataUiNavAttribute(DataUiAppName.Environments),
        id: manageEnvironmentsItemId,
        isSelected: false,
        label: 'Environments',
        link: getEnvironmentListingPath(currentProjectContainerId),
        settingsEnabled: false,
        settingsLink: '',
        type: 'item',
      },
    ]);

const getEnvironmentListingPath = (id: string) =>
  id ? createEnvironmentsLink({ projectContainerId: id }) : '';

const getEnvironmentSettingsPath = (id: string) =>
  id ? buildPath<EnvironmentRouteParams>(EnvironmentSettingsRoute, { projectId: id }) : '';
