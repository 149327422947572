import React from 'react';
import { SidebarSection } from '../../../../_shared/components/Sidebar/SidebarSection.tsx';
import { SidebarSubmitButtonWithErrorMessage } from '../../../../_shared/components/Sidebar/SidebarSubmitButtonWithErrorMessage.tsx';
import { Sidebar } from '../../../../_shared/containers/Sidebar/SidebarContainer.tsx';
import { IProjectLocation } from '../../../../_shared/models/ProjectLocation.ts';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ISubscription } from '../../../../data/models/subscriptions/Subscription.ts';
import { SelectedSubscriptionProjectLimitReachedTooltipMessage } from '../../constants/UIConstants.ts';
import { InitialProjectType } from '../../containers/projects/InitialProjectSelector.tsx';
import { TemplateErrors } from '../../containers/projects/copyProjectErrors/TemplateErrors.tsx';
import { IBaseProject } from '../../models/IBaseProject.type.ts';
import { INewProjectFormShape } from '../../models/INewProjectFormShape.type.ts';
import { CreateProjectForm } from './CreateProjectForm.tsx';

type Props = {
  readonly activeSubscriptions: ReadonlyArray<ISubscription>;
  readonly availableProjectLocations: ReadonlyArray<IProjectLocation>;
  readonly availableProjectTemplates: ReadonlyArray<IBaseProject>;
  readonly canSubmitForm: boolean;
  readonly creatingProject: boolean;
  readonly destinationSubscriptionId: Uuid;
  readonly disableSubscriptionSelector: boolean;
  readonly errorMessage: string;
  readonly formProps: HookFormProps<INewProjectFormShape>;
  readonly isProjectTemplateSelected: boolean;
  readonly onCloseDialog: () => void;
  readonly onSubmit: () => void;
  readonly selectedSubscriptionId: Uuid;
  readonly showSidebar: boolean;
  readonly sourceProjectId: Uuid;
  readonly subscriptionProjectsLimit: number | null;
  readonly subscriptionProjectsLimitReached: boolean;
};

export const CreateProjectModal: React.FC<Props> = ({
  activeSubscriptions,
  availableProjectTemplates,
  canSubmitForm,
  creatingProject,
  destinationSubscriptionId,
  disableSubscriptionSelector,
  errorMessage,
  formProps,
  isProjectTemplateSelected,
  onCloseDialog,
  onSubmit,
  selectedSubscriptionId,
  showSidebar,
  sourceProjectId,
  subscriptionProjectsLimit,
  subscriptionProjectsLimitReached,
}) => {
  const shouldRenderWarnings = !canSubmitForm && !creatingProject;

  const getSubmitButtonTooltipText = () => {
    if (creatingProject) {
      return undefined;
    }
    if (subscriptionProjectsLimitReached) {
      return SelectedSubscriptionProjectLimitReachedTooltipMessage(subscriptionProjectsLimit);
    }
    if (!canSubmitForm) {
      return 'Limitations exceeded';
    }
    return undefined;
  };

  const onFormSubmit = creatingProject || !canSubmitForm ? undefined : onSubmit;

  const submitButton = (
    <SidebarSubmitButtonWithErrorMessage
      onSubmit={onFormSubmit}
      buttonLabel={creatingProject ? 'Creating project' : 'Create project'}
      dataUiAttribute={getDataUiActionAttribute(DataUiAction.CreateNew)}
      disabled={creatingProject || !canSubmitForm}
      errorMessage={errorMessage}
      isBeingSubmitted={creatingProject}
      tooltipText={getSubmitButtonTooltipText()}
    />
  );

  return (
    <Sidebar
      isVisible={showSidebar}
      onClose={onCloseDialog}
      titleElement="Create a new project"
      submitElement={submitButton}
      onSubmit={onFormSubmit}
    >
      <SidebarSection>
        <CreateProjectForm
          initialProjectType={InitialProjectType.Blank}
          activeSubscriptions={activeSubscriptions}
          availableProjectTemplates={availableProjectTemplates}
          destinationSubscriptionId={destinationSubscriptionId}
          disableSubscriptionSelector={disableSubscriptionSelector}
          formProps={formProps}
          hasTemplateErrors={!canSubmitForm}
          isProjectTemplateSelected={isProjectTemplateSelected}
          onSubmit={onFormSubmit}
        />
        {shouldRenderWarnings && (
          <div className="create-project-sidebar__increase-warning">
            <TemplateErrors
              selectedSubscriptionId={selectedSubscriptionId}
              sourceProjectId={sourceProjectId}
            />
          </div>
        )}
      </SidebarSection>
    </Sidebar>
  );
};

CreateProjectModal.displayName = 'CreateProjectModal';
