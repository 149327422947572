import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Menu } from '@kontent-ai/component-library/Menu';
import React, { RefObject } from 'react';
import { useIsPreviewUrlOutdated } from '../../../../../webSpotlight/hooks/useIsPreviewUrlOutdated.ts';
import { useWebSpotlightInItemEditing } from '../../context/WebSpotlightInItemEditingContext.tsx';

export const PreviewActionsMenu: React.FC = () => {
  const { isAutoRefreshEnabledByUser, setIsAutoRefreshEnabledByUser, refreshPreview } =
    useWebSpotlightInItemEditing();
  const isPreviewUrlOutdated = useIsPreviewUrlOutdated();

  return (
    <Menu>
      <Menu.Trigger>
        {(ref, triggerProps) => (
          <Button
            buttonStyle="secondary"
            ref={ref as RefObject<HTMLButtonElement>}
            {...triggerProps}
          >
            <Button.Icon icon={Icons.Ellipsis} />
            <Button.Label>Preview actions</Button.Label>
          </Button>
        )}
      </Menu.Trigger>
      <Menu.List>
        {isAutoRefreshEnabledByUser ? (
          <Menu.Item
            id="disable-autorefresh"
            label="Disable autorefresh"
            leadingElement={<Icons.RotateDoubleRightSlash />}
            onAction={() => setIsAutoRefreshEnabledByUser(false)}
          />
        ) : (
          <Menu.Item
            id="enable-autorefresh"
            label="Enable autorefresh"
            leadingElement={<Icons.RotateDoubleRightA />}
            onAction={() => setIsAutoRefreshEnabledByUser(true)}
          />
        )}
        <Menu.Item
          id="refresh-manually"
          label="Refresh manually"
          leadingElement={<Icons.RotateDoubleRight />}
          onAction={() => refreshPreview({ isManualRefresh: true, isPreviewUrlOutdated })}
        />
      </Menu.List>
    </Menu>
  );
};
