import { Pathname } from 'history';
import { DataTable } from '../../../../_shared/components/DataTable/DataTable.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { DataTableRow } from '../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { HtmlSettingsPageTitle } from '../../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import { ListingMessage } from '../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { Tag } from '../../../../_shared/uiComponents/Tag/Tag.tsx';
import {
  DataUiAppName,
  DataUiCollection,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { TagColor } from '../../../../data/constants/tagColor.ts';
import { EnvironmentSettingsAppNames } from '../../root/constants/EnvironmentSettingsAppNames.ts';
import { MaxNumberOfRolesExceededMessege } from '../constants/UIConstants.ts';
import {
  IRoleListingOperationStatus,
  RoleListingOperationType,
} from '../models/RoleListingOperation.ts';

type RoleItem = {
  readonly id: Uuid;
  readonly name: string;
  readonly userCount: number;
  readonly roleEditorPath: Pathname;
  readonly isDefaultRole: boolean;
};

type Props = {
  readonly createNewRolePath: Pathname;
  readonly roleItems: ReadonlyArray<RoleItem>;
  readonly operationStatus: IRoleListingOperationStatus;
  readonly canCreateNewRole: boolean;
  readonly onRenewDeletedRole?: () => void;
};

const dataTableHeadColumns: ReadonlyArray<Column> = [
  {
    columnName: 'Name',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Users in this role',
    orderBy: OrderByDirection.None,
  },
];

export const RoleListing = ({
  roleItems,
  createNewRolePath,
  operationStatus,
  onRenewDeletedRole,
  canCreateNewRole,
}: Props) => {
  return (
    <div
      className="canvas__inner-section canvas__inner-section--restricted-width"
      {...getDataUiAppNameAttribute(DataUiAppName.Roles)}
    >
      <HtmlSettingsPageTitle settingsAppName={EnvironmentSettingsAppNames.Roles} />
      <DataTable
        createNewItemLinkPath={createNewRolePath}
        isCreateNewDisabled={!canCreateNewRole}
        createNewItemTooltip={canCreateNewRole ? undefined : MaxNumberOfRolesExceededMessege}
        dataUiCollectionName={DataUiCollection.RoleList}
        title={
          <ListingMessage
            statusInfoMessage={{
              text: operationStatus.message,
              style: operationStatus.messageStyle,
            }}
            onUndo={
              operationStatus.operationType === RoleListingOperationType.Deleted && canCreateNewRole
                ? onRenewDeletedRole
                : null
            }
          />
        }
        header={<DataTableHeadRow columns={dataTableHeadColumns} />}
      >
        {roleItems.map((roleItem: RoleItem) => (
          <DataTableRow id={roleItem.id} key={roleItem.id} dataUiObjectName={roleItem.name}>
            <LinkDataTableCell linkPath={roleItem.roleEditorPath}>
              {roleItem.name}
              {roleItem.isDefaultRole && (
                <Tag color={TagColor.Gray} title="Predefined role" customClass="data-table__tag">
                  Predefined
                </Tag>
              )}
            </LinkDataTableCell>
            <LinkDataTableCell linkPath={roleItem.roleEditorPath}>
              {roleItem.userCount}
            </LinkDataTableCell>
          </DataTableRow>
        ))}
      </DataTable>
    </div>
  );
};
