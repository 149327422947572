import { Dispatch, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../_shared/models/TrackUserEvent.type.ts';
import { IProjectRepository } from '../../../../repositories/interfaces/IProjectRepository.type.ts';
import { CopyType } from '../../../../repositories/serverModels/IProjectServerModel.type.ts';
import { ProjectStatusPollingInterval } from '../../constants/copyState.ts';
import { INewProjectFormShape } from '../../models/INewProjectFormShape.type.ts';
import { waitUntilProjectIsActive } from '../../utils/projectUtils.ts';
import {
  failCreatingNewProject,
  finishCreatingTemplateProject,
  startCreatingNewProject,
} from '../createProjectActions.ts';
import { projectCreationInitialized } from '../projectsActions.ts';
import { startPollingCloningState } from './cloneProject.ts';

interface IDeps {
  readonly projectRepository: IProjectRepository;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
  readonly loadUserProjectsInfo: () => ThunkPromise;
  readonly loadProjects: () => ThunkPromise;
}

export const createCreateProjectFromTemplateAction =
  (deps: IDeps) =>
  (project: INewProjectFormShape): ThunkPromise =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(startCreatingNewProject());

      const createdProject = await deps.projectRepository.copyProject(project.projectTemplateId, {
        projectName: project.projectName,
        destinationLocationId: project.projectLocationId,
        destinationSubscriptionId: project.subscriptionId,
        includeContent: project.includeContent,
        copyType: CopyType.Template,
      });
      await waitUntilProjectIsActive(createdProject.projectGuid, deps.projectRepository);
      dispatch(projectCreationInitialized(createdProject.projectGuid));
      await dispatch(deps.loadProjects());
      await dispatch(deps.loadUserProjectsInfo());
      dispatch(
        startPollingCloningState(
          deps.projectRepository,
          createdProject.projectGuid,
          ProjectStatusPollingInterval,
          deps.loadProjects,
        ),
      );
      dispatch(finishCreatingTemplateProject(createdProject.projectGuid));

      // if you are going to change the code below, please, consider whether you should make the same changes in createEmptyProject.ts
      dispatch(
        deps.trackUserEventWithData(TrackedEvent.ProjectCreated, {
          'project-template-id': project.projectTemplateId,
        }),
      );
    } catch (error) {
      dispatch(
        failCreatingNewProject('The project wasn’t created as we’re unable to reach the servers.'),
      );
      throw error;
    }
  };
