import { Action } from '../../../../../@types/Action.type.ts';
import {
  Sitemap_AddCollapsedNode,
  Sitemap_RemoveCollapsedNode,
} from '../../constants/sitemapActionTypes.ts';

export const collapsedNodeIds = (state = [], action: Action): ReadonlyArray<Uuid> => {
  switch (action.type) {
    case Sitemap_RemoveCollapsedNode:
      return state.filter((id) => id !== action.payload.nodeId);

    case Sitemap_AddCollapsedNode:
      return [...state, action.payload.nodeId];

    default:
      return state;
  }
};
