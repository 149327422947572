import { alphabetically, createCompare } from '@kontent-ai/utils';
import { IContentType } from '../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { anyContentTypeContentType } from '../constants/anyContentTypeContentType.ts';
import { ContentTypeOption, mapContentTypeToOption } from '../models/ContentTypeOption.ts';

export const getSortedContentTypeOptionsListWithAnyContentTypeFirst = (
  contentTypesById: Immutable.Map<Uuid, IContentType>,
): ReadonlyArray<ContentTypeOption> =>
  [anyContentTypeContentType]
    .concat(getSortedContentTypes(contentTypesById))
    .map(mapContentTypeToOption);

const getSortedContentTypes = (
  contentTypesById: Immutable.Map<Uuid, IContentType>,
): ReadonlyArray<IContentType> =>
  contentTypesById.toArray().sort(
    createCompare({
      compare: alphabetically,
      select: (x) => x.name,
    }),
  );
