import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { ILinkedItemsElement } from '../../models/contentItemElements/modularItems/ILinkedItemsElement.ts';
import { IGetItemElementFriendlyWarnings } from '../getItemElementFriendlyWarnings.ts';
import { ItemElementFriendlyWarningResult } from './types/FriendlyWarnings.ts';
import { getBrokenModularWarningsWithDetails } from './utils/contentLinkUtils.ts';

interface IParams {
  readonly itemElement: ILinkedItemsElement;
  readonly loadedEntries: Immutable.Map<Uuid, IListingContentItem>;
}

const getMemoizedResult = memoize.allForever(
  (
    contentItemsIds: ReadonlyArray<Uuid>,
    loadedEntries: Immutable.Map<Uuid, IListingContentItem>,
  ): ItemElementFriendlyWarningResult => {
    const warningMessages = getBrokenModularWarningsWithDetails(contentItemsIds, loadedEntries);

    return {
      warningMessages,
    };
  },
);

// exported just for unit tests
export const checkerFactory =
  (): IGetItemElementFriendlyWarnings<ILinkedItemsElement, IParams> =>
  ({ itemElement, loadedEntries }: IParams): ItemElementFriendlyWarningResult => {
    const contentItemsIds = itemElement.value;

    return getMemoizedResult(contentItemsIds, loadedEntries);
  };

export const getLinkedItemElementValidationFriendlyWarning = checkerFactory();
