import { ModalViewer } from '../../../../../../../_shared/components/Modal/ModalViewer.tsx';
import { ModalViewerPosition } from '../../../../../../../_shared/components/Modal/ModalViewerPosition.ts';
import { ModalAssetSelector } from '../../../../../../contentInventory/assets/features/ModalAssetSelector/containers/ModalAssetSelector.tsx';
import { ModalMultipleAssetsSelector } from '../../../../../../contentInventory/assets/features/ModalAssetSelector/containers/ModalMultipleAssetsSelector.tsx';
import { AssetFileTypeOption } from '../../../../../../contentInventory/content/models/assetFileTypeOptions.ts';
import { ModalContentItemSelector } from '../../../../../../features/ModalContentItemSelector/containers/ModalContentItemSelector.tsx';
import { ModalMultipleContentItemsSelector } from '../../../../../../features/ModalContentItemSelector/containers/ModalMultipleContentItemsSelector.tsx';
import { AssetLimitations } from '../../../../../../richText/plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { CustomElementDialogSelectionMode, CustomElementDialogType } from './customElementTypes.ts';

const CustomElementAssetLimitations: AssetLimitations = {
  fileSize: null,
  fileType: AssetFileTypeOption.Any,
  maxHeight: null,
  maxWidth: null,
  minHeight: null,
  minWidth: null,
};

type Props = {
  readonly elementName: string;
  readonly isOpen: boolean;
  readonly mode: CustomElementDialogSelectionMode;
  readonly onAssetSelected: (id: Uuid) => void;
  readonly onAssetsSelected: (ids: ReadonlyArray<Uuid>) => void;
  readonly onCloseAssetSelector: () => void;
  readonly onCloseItemSelector: () => void;
  readonly onItemSelected: (id: Uuid) => void;
  readonly onItemsSelected: (ids: ReadonlyArray<Uuid>) => void;
  readonly type: CustomElementDialogType;
};

export const CustomElementSelectionDialog = ({
  elementName,
  isOpen,
  mode,
  onAssetSelected,
  onAssetsSelected,
  onCloseAssetSelector,
  onCloseItemSelector,
  onItemSelected,
  onItemsSelected,
  type,
}: Props) => {
  const editedEntityName = `${elementName} (Custom element)`;

  switch (type) {
    case CustomElementDialogType.ItemSelector: {
      return (
        <ModalViewer
          dialogClassName="dialog"
          isDialogVisible={isOpen}
          position={ModalViewerPosition.Center}
        >
          {mode === CustomElementDialogSelectionMode.Single ? (
            <ModalContentItemSelector
              titleBarText={`Insert an existing content item to ${editedEntityName}`}
              onClose={onCloseItemSelector}
              onSelect={onItemSelected}
            />
          ) : (
            <ModalMultipleContentItemsSelector
              onClose={onCloseItemSelector}
              onSelect={onItemsSelected}
              titleBarText={`Insert existing content items to ${editedEntityName}`}
            />
          )}
        </ModalViewer>
      );
    }

    case CustomElementDialogType.AssetSelector: {
      return mode === CustomElementDialogSelectionMode.Single ? (
        <ModalAssetSelector
          isOpen={isOpen}
          onClose={onCloseAssetSelector}
          onSelect={onAssetSelected}
          showImagesOnly={false}
          titleBarText={`Insert an asset to ${editedEntityName}`}
        />
      ) : (
        <ModalMultipleAssetsSelector
          limitations={CustomElementAssetLimitations}
          isOpen={isOpen}
          onClose={onCloseAssetSelector}
          onSelect={onAssetsSelected}
          primaryButtonText="Insert"
          showImagesOnly={false}
          titleBarText={`Insert assets to ${editedEntityName}`}
        />
      );
    }

    case CustomElementDialogType.ImageSelector: {
      return mode === CustomElementDialogSelectionMode.Single ? (
        <ModalAssetSelector
          isOpen={isOpen}
          onClose={onCloseAssetSelector}
          onSelect={onAssetSelected}
          showImagesOnly
          titleBarText={`Insert an asset to ${editedEntityName}`}
        />
      ) : (
        <ModalMultipleAssetsSelector
          limitations={CustomElementAssetLimitations}
          isOpen={isOpen}
          onClose={onCloseAssetSelector}
          onSelect={onAssetsSelected}
          primaryButtonText="Insert"
          showImagesOnly
          titleBarText={`Insert assets to ${editedEntityName}`}
        />
      );
    }
  }
};
