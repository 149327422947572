import { DataUiCollection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { PublishingState } from '../../../../../../../data/constants/PublishingState.ts';
import { getDisabledAiFilterProps } from '../../../../../../../paperModels/aiSearch/utils/getDisabledAiFilterProps.ts';
import {
  IPublishingState,
  PublishingStateOptions,
  convertPublishingStateToQueryParams,
  getPublishingStateFromIds,
} from '../../../../models/filter/IPublishingState.ts';
import { ListingFilterCategorySelector } from '../../components/ListingFilterCategorySelector.tsx';

type Props = {
  readonly isAiSearchUsed: boolean;
  readonly onPublishingStatusSelectionChanged: (status: IPublishingState) => void;
  readonly selectedPublishingStateNodes: IPublishingState;
};

const getSelectedPublishingStatusesElements = (
  state: IPublishingState,
): ReadonlyArray<PublishingState> => {
  const selectedPublishingStateNodeIds = new Set(convertPublishingStateToQueryParams(state));
  return PublishingStateOptions.filter((option) =>
    selectedPublishingStateNodeIds.has(option.id),
  ).map((option) => option.id);
};

export const PublishingStatusFilterSelector = (props: Props) => {
  return (
    <ListingFilterCategorySelector
      collection={DataUiCollection.PublishingStates}
      placeholder={label}
      ariaLabel={label}
      title="Publishing state"
      selectedOptionIds={getSelectedPublishingStatusesElements(props.selectedPublishingStateNodes)}
      options={PublishingStateOptions}
      onChange={(selectedIds) =>
        props.onPublishingStatusSelectionChanged(getPublishingStateFromIds(selectedIds))
      }
      {...getDisabledAiFilterProps(props.isAiSearchUsed)}
    />
  );
};

const label = 'Select a publishing state';
