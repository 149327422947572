import { TransitionFn, animated, useTransition } from '@react-spring/web';
import React, { MouseEventHandler, useRef } from 'react';
import { upsertUserProperty } from '../../../../../../_shared/actions/thunkSharedActions.ts';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { PrefersClosedEditingSidebar } from '../../../../../../_shared/models/UserPropertiesServerKeys.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { openContentItemEditingSidebar } from '../../actions/thunks/openContentItemEditingSidebar.ts';
import { ContentItemSidebarButton } from './ContentItemSidebarButton.tsx';

const AnimatedContentItemSidebarButton = animated(ContentItemSidebarButton);

const hiddenTransform = { transform: 'translate(100%, -50%)' };
const visibleTransform = { transform: 'translate(-50%, -50%)' };

type ButtonSlideAnimation = {
  containerRef: React.RefObject<HTMLElement>;
  transitions: TransitionFn<boolean, { transform: string }>;
};

const useButtonSlideAnimation = (
  isSidebarOpen: boolean,
  prefersClosedEditingSidebar: boolean,
): ButtonSlideAnimation => {
  const containerRef = useRef<HTMLElement>(null);
  const isImmediatelyVisible = !containerRef.current && prefersClosedEditingSidebar;

  const transitions = useTransition(!isSidebarOpen, {
    initial: isImmediatelyVisible ? visibleTransform : hiddenTransform,
    from: hiddenTransform,
    enter: visibleTransform,
    leave: hiddenTransform,
  });

  return {
    containerRef,
    transitions,
  };
};

export const ContentItemSidebarPlaceholder: React.FC = () => {
  const isSidebarOpen = useSelector((state) => state.contentApp.editorUi.contentItemSidebar.isOpen);
  const prefersClosedEditingSidebar = useSelector(
    (state) => state.sharedApp.userProperties.prefersClosedEditingSidebar,
  );
  const dispatch = useDispatch();

  const { containerRef, transitions } = useButtonSlideAnimation(
    isSidebarOpen,
    prefersClosedEditingSidebar,
  );

  const onOpenClick: MouseEventHandler<HTMLElement> = (event) => {
    event.stopPropagation();

    dispatch(openContentItemEditingSidebar({ type: 'click' }));
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingEventTypes.ContentItemDetailsSidebarOpened,
        origin: ContentItemEditingEventOrigins.ItemDetails,
      }),
    );
  };

  const onPinClick: React.MouseEventHandler = (event) => {
    event.stopPropagation();

    const targetPrefersClosedEditingSidebar = !prefersClosedEditingSidebar;
    dispatch(
      upsertUserProperty(PrefersClosedEditingSidebar, targetPrefersClosedEditingSidebar.toString()),
    );
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: targetPrefersClosedEditingSidebar
          ? ContentItemEditingEventTypes.ContentItemDetailsSidebarPinned
          : ContentItemEditingEventTypes.ContentItemDetailsSidebarUnpinned,
        origin: ContentItemEditingEventOrigins.ItemDetails,
      }),
    );
  };

  return (
    <section
      className="content-item-pane__sidebar-placeholder"
      onClick={onOpenClick}
      ref={containerRef}
    >
      <ContentItemSidebarButton
        className="content-item-pane__sidebar-btn-toggle"
        iconName="ChevronDoubleLeft"
        onClick={onOpenClick}
        tooltipText="Open sidebar"
      />
      {transitions(
        (style, isVisible) =>
          isVisible && (
            <AnimatedContentItemSidebarButton
              activated={prefersClosedEditingSidebar}
              className="content-item-pane__sidebar-btn-pin"
              iconName="Pin"
              onClick={onPinClick}
              style={style}
              tooltipText={
                prefersClosedEditingSidebar
                  ? 'Open sidebar by default'
                  : 'Keep sidebar closed by default'
              }
            />
          ),
      )}
    </section>
  );
};

ContentItemSidebarPlaceholder.displayName = 'ContentItemSidebarPlaceholder';
