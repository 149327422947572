import { PropsWithChildren } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getAreAnyContentGroupsVisible } from '../../../selectors/getAreAnyContentGroupsVisible.ts';
import { getCanEditGroupContent } from '../../../selectors/getCanEditGroupContent.ts';
import { ContentItemGroupTabs as ContentItemGroupTabsComponents } from '../components/ContentItemGroupTabs.tsx';
import { usePreviewContext } from '../context/PreviewContextProvider.tsx';

export const ContentItemGroupTabs = ({ children }: PropsWithChildren) => {
  const { isPreviewOpened } = usePreviewContext();
  const areAnyContentGroupsVisible = useSelector(getAreAnyContentGroupsVisible);
  const canEditGroupContent = useSelector(getCanEditGroupContent);

  return (
    <ContentItemGroupTabsComponents
      areAnyContentGroupsVisible={areAnyContentGroupsVisible}
      canEditGroupContent={canEditGroupContent}
      isCompact={isPreviewOpened}
    >
      {children}
    </ContentItemGroupTabsComponents>
  );
};
