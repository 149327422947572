import { Menu } from '@kontent-ai/component-library/Menu';
import React from 'react';
import { useVariantTranslation } from '../../../../../../../_shared/features/AI/hooks/useVariantTranslation.ts';
import { ContentItemId } from '../../../../../../../_shared/models/ContentItemId.ts';

type MenuOption = {
  readonly variantId: Uuid;
  readonly name: string;
  readonly isDisabled: boolean;
};

type TranslateFromLanguageOptionsProps = {
  readonly contentItemId: ContentItemId;
  readonly menuOptions: ReadonlyArray<MenuOption>;
  readonly onTranslationStarted: () => void;
  readonly projectEnvironmentId: Uuid;
  readonly userId: string;
};

export const TranslateFromLanguageOptions: React.FC<TranslateFromLanguageOptionsProps> = ({
  contentItemId,
  menuOptions,
  onTranslationStarted,
  projectEnvironmentId,
  userId,
}) => {
  const { startTranslation } = useVariantTranslation(
    projectEnvironmentId,
    contentItemId.itemId,
    contentItemId.variantId,
  );

  return (
    <>
      {menuOptions.map((option: MenuOption, index: number) => (
        <Menu.Item
          id={option.name}
          menuItemState={option.isDisabled ? 'disabled' : 'default'}
          key={index}
          onAction={async () => {
            await startTranslation(option.variantId, userId).then(onTranslationStarted);
          }}
          tooltipText={
            option.isDisabled
              ? 'You can only translate from language variants assigned to you.'
              : undefined
          }
          label={option.name}
        />
      ))}
    </>
  );
};

TranslateFromLanguageOptions.displayName = 'TranslateFromLanguageOptions';
