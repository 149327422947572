import { Card } from '@kontent-ai/component-library/Card';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { CreateNewBarItemButton } from '../../../../_shared/components/BarItems/CreateNewBarItemButton.tsx';
import { useScrollOnDragEvents } from '../../../../_shared/hooks/useScrollOnDragEvents.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ILanguage } from '../../../../data/models/languages/Language.ts';
import { LanguageItem } from '../containers/LanguageItem.tsx';
import { DefaultLanguageItem } from './DefaultLanguageItem.tsx';

export type LanguagesCardProps = {
  readonly languages: ReadonlyArray<ILanguage>;
  readonly isCreatingAllowed: boolean;
  readonly onCreateNewClick: () => void;
};

export const LanguagesCard: React.FC<LanguagesCardProps> = ({
  languages,
  isCreatingAllowed,
  onCreateNewClick,
}) => {
  const isDragging = useSelector((state) => !!state.localizationApp.draggedLanguageId);
  const isDefaultLanguageEdited = useSelector((s) => s.localizationApp.isDefaultLanguageEdited);

  const dndScrollAreaRef = useRef<HTMLUListElement | null>(null);
  useScrollOnDragEvents(dndScrollAreaRef);

  return (
    <Card component="section">
      <Card.Headline>Languages</Card.Headline>
      <Card.Body>
        <ul
          className={classNames('bar-item__list', {
            'bar-item__list--is-dragging': isDragging,
          })}
          ref={dndScrollAreaRef}
          {...getDataUiCollectionAttribute(DataUiCollection.LanguageList)}
        >
          <DefaultLanguageItem isEdited={isDefaultLanguageEdited} />
          {languages.map((language) => (
            <LanguageItem key={language.id} language={language} />
          ))}
          {isCreatingAllowed && (
            <CreateNewBarItemButton onCreateNewClick={onCreateNewClick} itemName="Language" />
          )}
        </ul>
      </Card.Body>
    </Card>
  );
};

LanguagesCard.displayName = 'LanguagesCard';
