import { Action } from '../../../@types/Action.type.ts';
import { Shared_LoadProjectFeatureFlags_Finished } from '../../constants/sharedActionTypes.ts';
import {
  ProjectFeatureFlagValue,
  ProjectFeatureFlagsModel,
  ProjectFeatureFlagsStateKeysByServerKeys,
} from '../../models/ProjectFeatureFlagsModel.ts';

const initialState: ProjectFeatureFlagsModel = {
  SpacesInDeliver: false,
  UnifiedWebSpotlight: false,
};

const mapFeatureFlagKeyAndValueToState = (
  state: ProjectFeatureFlagsModel,
  featureFlagKey: string,
  featureFlagValue: ProjectFeatureFlagValue,
): ProjectFeatureFlagsModel => {
  const flagName = ProjectFeatureFlagsStateKeysByServerKeys[featureFlagKey];
  if (!flagName) {
    return state;
  }

  return {
    ...state,
    [flagName]: featureFlagValue,
  };
};

const mapEnabledFeatureFlagToState = (
  state: ProjectFeatureFlagsModel,
  featureFlagKey: string,
): ProjectFeatureFlagsModel => mapFeatureFlagKeyAndValueToState(state, featureFlagKey, true);

export const projectFeatureFlags = (
  state = initialState,
  action: Action,
): ProjectFeatureFlagsModel => {
  switch (action.type) {
    case Shared_LoadProjectFeatureFlags_Finished: {
      return action.payload.featureFlags.enabledFeatures.reduce(
        mapEnabledFeatureFlagToState,
        initialState,
      );
    }

    default:
      return state;
  }
};
