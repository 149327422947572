import { focusAtTheEnd, focusAtTheStart } from '@kontent-ai/DOM';
import { Box } from '@kontent-ai/component-library/Box';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Hint } from '@kontent-ai/component-library/Hint';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Input, InputState, InputType } from '@kontent-ai/component-library/Input';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Spacing, colorTextDefault, gridUnit } from '@kontent-ai/component-library/tokens';
import { delay, noOperation, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { millisecondsInMinute, millisecondsInSecond } from 'date-fns/constants';
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  MouseEventHandler,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { DeferredAutoFocus } from '../../../../applications/itemEditor/features/ContentItemEditing/components/DeferredAutoFocus.tsx';
import {
  DataUiAction,
  DataUiElement,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import {
  IsoFormattedDateTime,
  formatDateTimeToReadableWithAt,
  formatLocalDateTimeToReadableUtcOffset,
  parseDatetime,
  shiftByUTCOffsetBackwards,
  shiftByUTCOffsetForward,
} from './datetimeUtils.ts';

const getTooltip = (selectedValue: IsoFormattedDateTime, currentTime: Date) => {
  if (selectedValue) {
    const { isValid, value } = parseDatetime(selectedValue);
    if (isValid) {
      const utcTime = shiftByUTCOffsetBackwards(value);
      return `You selected ${formatDateTimeToReadableWithAt(value)}
UTC (Coordinated Universal Time,
also known as GMT).

This is ${formatDateTimeToReadableWithAt(utcTime)} your
local time (${formatLocalDateTimeToReadableUtcOffset(value)}).`;
    }
  }
  return `Your current local time (${formatLocalDateTimeToReadableUtcOffset(currentTime)})
is ${formatDateTimeToReadableWithAt(currentTime)}.

This is ${formatDateTimeToReadableWithAt(shiftByUTCOffsetForward(currentTime))} UTC
(Coordinated Universal Time,
also known as GMT).`;
};

const getInputState = (isDisabled: boolean, isValid: boolean): InputState => {
  if (isDisabled) {
    return InputState.Disabled;
  }

  if (!isValid) {
    return InputState.Alert;
  }

  return InputState.Default;
};

export const dateTimeInputMaxWidth = 33 * gridUnit;

export type IDatetimePickerInputHandles = {
  triggerFocusAtTheEnd: () => void;
  triggerFocusAtTheStart: () => void;
};

type DatetimePickerInputProps = {
  readonly autoFocus: boolean;
  readonly className?: string;
  readonly currentStoredValue: string;
  readonly disabled?: boolean;
  readonly errorMessage?: string | null;
  readonly isCalendarButtonActive: boolean;
  readonly isFullWidth?: boolean;
  readonly isValid: boolean;
  readonly onBlur?: FocusEventHandler<HTMLElement>;
  readonly onChange: ChangeEventHandler<HTMLInputElement>;
  readonly onClick: MouseEventHandler<HTMLElement>;
  readonly onFocus: FocusEventHandler<HTMLElement>;
  readonly placeholder?: string;
  readonly showDataBalloon?: boolean;
  readonly utc?: boolean;
  readonly value: string;
  readonly title?: string;
  readonly ariaLabel?: string;
  readonly suffixes?: readonly React.ReactNode[];
  readonly wrapperRef: React.RefObject<HTMLDivElement>;
};

const getCurrentDateTime = (): Date => new Date();

const getMillisecondsUntilMinutesChange = (currentTime: Date) => {
  return millisecondsInSecond * currentTime.getSeconds() - currentTime.getMilliseconds();
};

const useCurrentDateTime = (): Date => {
  const [currentTime, setCurrentTime] = useState(getCurrentDateTime());
  useEffect(() => {
    const delayMs = millisecondsInMinute - getMillisecondsUntilMinutesChange(currentTime);
    const timeout = delay(delayMs)
      .then(() => setCurrentTime(getCurrentDateTime()))
      .catch(swallowCancelledPromiseError);

    return () => {
      timeout.cancel();
    };
  }, [currentTime]);

  return currentTime;
};

interface IUtcLabelProps {
  readonly selectedValue: IsoFormattedDateTime;
}

const UtcLabel: React.FC<IUtcLabelProps> = ({ selectedValue }) => {
  const currentTime = useCurrentDateTime();
  const tooltipText = getTooltip(selectedValue, currentTime);

  return (
    <Inline spacing={Spacing.XS} align="center">
      <Label size={LabelSize.M} color={colorTextDefault}>
        UTC
      </Label>
      <Hint tooltipPlacement="bottom-start" tooltipText={tooltipText} />
    </Inline>
  );
};

UtcLabel.displayName = 'UtcLabel';

export const DatetimePickerInput = forwardRef<
  IDatetimePickerInputHandles,
  DatetimePickerInputProps
>((props, forwardedRef) => {
  const id = useId();
  const datetimeInput = useRef<HTMLInputElement>(null);
  const focusInput = useCallback(() => datetimeInput.current?.focus(), []);
  const triggerFocusAtTheEnd = useCallback(() => focusAtTheEnd(datetimeInput.current), []);
  useEffect(() => {
    if (props.autoFocus) {
      focusInput();
    }
  }, [props.autoFocus, focusInput]);
  useImperativeHandle(forwardedRef, () => ({
    triggerFocusAtTheEnd,
    triggerFocusAtTheStart: () => focusAtTheStart(datetimeInput.current),
  }));

  const inputEventHandlers = props.disabled
    ? undefined
    : {
        onBlur: props.onBlur,
        onChange: props.onChange,
        onClick: props.onClick,
        onFocus: props.onFocus,
      };

  const userSelectedValidDateTime = !!props.value;
  const selectedValue = userSelectedValidDateTime ? props.currentStoredValue : null;

  const calendarTrigger = (
    <QuinaryButton
      aria-label="Open Calendar"
      onClick={props.disabled ? noOperation : props.onClick}
      tooltipText={
        props.showDataBalloon && !props.disabled && !props.isCalendarButtonActive
          ? 'Select date'
          : ''
      }
      tooltipPlacement="bottom-end"
      disabled={props.disabled}
      activated={props.isCalendarButtonActive}
      {...getDataUiActionAttribute(DataUiAction.PickDateTime)}
    >
      <Icons.Calendar />
    </QuinaryButton>
  );

  return (
    <div
      className="datetime-picker__input"
      aria-haspopup="dialog"
      ref={props.wrapperRef}
      {...getDataUiElementAttribute(DataUiElement.DateTimePickerInput)}
    >
      <Inline align="center" spacingX={Spacing.L}>
        <Box
          width={props.isFullWidth ? '100%' : undefined}
          maxWidth={props.isFullWidth ? undefined : dateTimeInputMaxWidth}
        >
          <Input
            label={props.title}
            id={id}
            placeholder={props.placeholder}
            inputState={getInputState(!!props.disabled, props.isValid)}
            caption={props.errorMessage}
            value={props.value}
            type={InputType.Text}
            ref={datetimeInput}
            onBlur={inputEventHandlers?.onBlur}
            onChange={inputEventHandlers?.onChange}
            onClick={inputEventHandlers?.onClick}
            onFocus={inputEventHandlers?.onFocus}
            suffixes={props.suffixes ?? [calendarTrigger]}
            aria-label={props.ariaLabel}
            {...getDataUiInputAttribute(DataUiInput.DateTime)}
          />
        </Box>
        {props.utc && <UtcLabel selectedValue={selectedValue} />}
        <DeferredAutoFocus autoFocus={props.autoFocus} onAutoFocus={focusInput} />
      </Inline>
    </div>
  );
});

DatetimePickerInput.displayName = 'DatetimePickerInput';
