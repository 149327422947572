import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { useMemo } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { filterCollectionsByCapability } from '../../../../../_shared/utils/permissions/filterCollectionsByCapability.ts';
import { IUser } from '../../../../../data/reducers/user/IUser.type.ts';
import { getCurrentProjectId } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ListingFilterCategorySelector } from '../../../../contentInventory/content/features/ListingFilter/components/ListingFilterCategorySelector.tsx';

type Props = Readonly<{
  user: IUser;
  selectedCollections: ReadonlySet<Uuid>;
  onCollectionSelectionChanged: (ids: ReadonlySet<Uuid>) => void;
}>;

interface ICollectionSelectItem extends ISelectItem<ICollectionSelectItem> {}

export const CollectionsFilterSelector = (props: Props) => {
  const collections = useSelector((state) => {
    const projectId = getCurrentProjectId(state);
    return filterCollectionsByCapability(
      Capability.ViewContent,
      state.data.collections.byId,
      projectId,
      props.user,
    );
  });

  const options = useMemo(
    () =>
      collections.map(({ id, name, ...rest }) => ({
        id,
        label: name,
        ...rest,
      })),
    [collections],
  );

  const selectedOptionIds = useMemo(
    () =>
      collections
        .filter((collection) => props.selectedCollections.has(collection.id))
        .map((c) => c.id),
    [collections, props.selectedCollections],
  );

  return collections.length > 1 ? (
    <ListingFilterCategorySelector<ICollectionSelectItem>
      options={options}
      onChange={props.onCollectionSelectionChanged}
      collection={DataUiCollection.ContentCollections}
      placeholder="Select a collection"
      selectedOptionIds={selectedOptionIds}
      title="Collection"
    />
  ) : null;
};
