import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { getIsAiSearchUsed } from '../utils/getIsAiSearchUsed.ts';

export const pickSearchAction =
  <T extends (...args: any[]) => ThunkPromise>(
    standardSearchScopeAction: T,
    aiSearchScopeAction: T,
  ): ((...args: Parameters<T>) => ThunkPromise) =>
  (...args): ThunkPromise =>
  async (dispatch, getState) =>
    await dispatch(
      (getIsAiSearchUsed(getState().contentApp.listingUi.filter)
        ? aiSearchScopeAction
        : standardSearchScopeAction)(...args),
    );
