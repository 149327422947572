import { memoize } from '@kontent-ai/memoization';
import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ITaxonomyGroup } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { ITaxonomyTerm } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import {
  AssetFoldersBreadcrumbs as AssetFoldersBreadcrumbsComponent,
  IAssetListingFoldersBreadcrumbsDispatchProps,
} from '../../components/Breadcrumbs/AssetFoldersBreadcrumbs.tsx';
import { RootFolderId } from '../../constants/assetFolderConstants.ts';
import { backTrackOpenedAssetFolders } from '../../utils/backTrackOpenedAssetFolders.ts';

const getOpenedFolderHierarchy = memoize.maxOne(
  (taxonomyGroup: ITaxonomyGroup, openedFolderId: Uuid): ReadonlyArray<ITaxonomyTerm> | null => {
    if (openedFolderId === RootFolderId) {
      return [];
    }

    const folders = backTrackOpenedAssetFolders(taxonomyGroup, null, openedFolderId);

    return folders.length ? folders : null;
  },
);

export const AssetFoldersBreadcrumbs: React.FC<IAssetListingFoldersBreadcrumbsDispatchProps> = (
  props,
) => {
  const openedFolders = useSelector((s) =>
    getOpenedFolderHierarchy(s.data.assetFolders.taxonomyGroup, s.assetLibraryApp.openedFolderId),
  );

  if (openedFolders === null) {
    return null;
  }

  return (
    <AssetFoldersBreadcrumbsComponent
      openedFolders={openedFolders}
      onFolderOpen={props.onFolderOpen}
      onFolderReset={props.onFolderReset}
    />
  );
};
