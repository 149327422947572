import React from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getProjectType } from '../../../../_shared/models/utils/quickstartUtils.ts';
import { getCurrentProject } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { Quickstart as QuickstartComponent } from '../components/Quickstart.tsx';

export const Quickstart: React.FC = () => {
  const projectType = useSelector((s) =>
    getProjectType(getCurrentProject(s), s.sharedApp.projectProperties),
  );

  return <QuickstartComponent projectType={projectType} />;
};

Quickstart.displayName = 'Quickstart';
