import { memoize } from '@kontent-ai/memoization';
import { notNull } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { IListingContentItem } from '../../../models/listingContentItems/IListingContentItem.ts';
import { IContentTypeComponentsUsage } from '../../contentTypeComponentsUsage/contentTypeComponentsUsage.type.ts';

type ContentItemsBase = {
  readonly allIds: ReadonlyArray<Uuid> | null;
  readonly byId: Immutable.Map<Uuid, IListingContentItem>;
  readonly nextContinuationToken: string | null;
};

export const getItemListForScrollTable = memoize.maxOne(
  (
    listingContentItems: ContentItemsBase,
    numberOfItemsInViewport: number,
  ): ReadonlyArray<IListingContentItem | null> => {
    const { allIds, byId, nextContinuationToken } = listingContentItems;

    if (allIds === null) {
      return [];
    }
    const items = allIds.map((id: Uuid) => byId.get(id) ?? null);
    return getItems(items, nextContinuationToken, numberOfItemsInViewport);
  },
);

export const getContentTypeUsedAsComponentItemListForScrollTable = memoize.maxOne(
  (
    listingContentItems: IContentTypeComponentsUsage,
    numberOfItemsInViewport: number,
  ): ReadonlyArray<IListingContentItem | null> => {
    const { items, continuationToken } = listingContentItems;

    return getItems(items, continuationToken, numberOfItemsInViewport);
  },
);

export const getItems = memoize.maxOne(
  (
    items: ReadonlyArray<IListingContentItem | null> | null,
    continuationToken: string | null,
    numberOfItemsInViewport: number,
  ): ReadonlyArray<IListingContentItem | null> => {
    if (items === null) {
      return [];
    }

    const numberOfPlaceholderItems = numberOfItemsInViewport;
    const nullItems = continuationToken ? new Array(numberOfPlaceholderItems).fill(null) : [];

    return items.filter(notNull).concat(nullItems);
  },
);
