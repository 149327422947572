import { Button } from '@kontent-ai/component-library/Button';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import Immutable from 'immutable';
import { ModalDialog } from '../../../../../_shared/components/ModalDialog/ModalDialog.tsx';
import {
  NotificationBar,
  NotificationBarType,
} from '../../../../../_shared/components/NotificationBar.tsx';
import { HandleUnsavedFormOnNavigation } from '../../../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { SubscriptionPlanIncludedUserLimitReachedWarning } from '../../../../../_shared/containers/SubscriptionWarnings/SubscriptionPlanIncludedUserLimitReachedWarning.tsx';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IExistingUserToInvite } from '../../../../../data/models/users/ExistingUserToInvite.ts';
import { ICollectionGroupRoles } from '../../../../../data/models/users/ProjectContributor.ts';
import { invitationInProgress } from '../../constants/errorMessages.ts';
import { InvitationEmailInput } from '../../containers/invitations/InvitationEmailInput.tsx';
import { Invitation } from '../../reducers/IUsersAppState.type.ts';
import { CollectionPermissionsQuickTip } from '../CollectionPermissionsQuickTip.tsx';
import { LanguageRolesQuickTip } from '../LanguageRolesQuickTip.tsx';
import { UserConfiguration } from '../collections/UserConfiguration.tsx';
import { InviteButton } from './InviteButton.tsx';

type Props = {
  readonly changeInvitations: (newSelected: ReadonlyArray<Invitation>) => void;
  readonly changeSearchText: (newEmail: string) => void;
  readonly collectionGroups: ReadonlyArray<ICollectionGroupRoles>;
  readonly collidingContributorEmails: ReadonlySet<string>;
  readonly createUserDetailLink: (userId: UserId) => string;
  readonly disabledInviteTooltip: string | undefined;
  readonly emailInputValidationMessages: ReadonlyArray<string>;
  readonly emailsCountedInSubscriptionLimits: ReadonlyArray<string>;
  readonly environmentName: string;
  readonly errorMessage: string | undefined;
  readonly existingUsersByEmail: Immutable.Map<string, IExistingUserToInvite>;
  readonly hasUnsavedChanges: boolean;
  readonly hideNotificationBar: () => void;
  readonly invitations: ReadonlyArray<Invitation>;
  readonly isInviting: boolean;
  readonly onClose: () => void;
  readonly onCollectionGroupsChange: (
    collectionGroups: ReadonlyArray<ICollectionGroupRoles>,
  ) => void;
  readonly onSubmit: () => void;
  readonly onSubmitUnsavedChangesDialog: (onSuccess: () => void, onFail: () => void) => void;
  readonly savingError: string | null;
  readonly shouldConfirmInvite: boolean;
  readonly shouldHighlightNewUsers: boolean;
};

const InvitationModalBody = (props: Props) => {
  return (
    <div className="user-modal__body">
      <div className="user-modal__content">
        <div className="user-modal__form">
          <Stack spacing={Spacing.L}>
            <div className="user-modal__form-inputs-pane">
              <InvitationEmailInput {...props} />
            </div>
            <SubscriptionPlanIncludedUserLimitReachedWarning
              newEmails={props.emailsCountedInSubscriptionLimits}
            />
            <div className="user-modal__role-builder-pane">
              <UserConfiguration
                collectionGroups={props.collectionGroups}
                disabledTooltip={props.isInviting ? invitationInProgress : undefined}
                onCollectionGroupsChange={props.onCollectionGroupsChange}
              />
            </div>
            <LanguageRolesQuickTip isRoleBuilderValid={!props.errorMessage} />
            <CollectionPermissionsQuickTip />
          </Stack>
        </div>
      </div>
      <div className="user-modal__footer">
        <NotificationBar
          type={NotificationBarType.Error}
          message={props.savingError}
          onClosePanel={props.hideNotificationBar}
        />
      </div>
    </div>
  );
};

export const InvitationModal = (props: Props) => {
  const disabledTooltip = props.disabledInviteTooltip || props.errorMessage;

  return (
    <>
      <HandleUnsavedFormOnNavigation
        disabledTooltip={disabledTooltip}
        isBeingSaved={props.isInviting}
        onSaveChanges={props.onSubmitUnsavedChangesDialog}
        hasUnsavedChanges={props.hasUnsavedChanges}
      />
      <ModalDialog
        dataUiElement={DataUiElement.InvitationModalDialog}
        headerContent={`Invite users to “${props.environmentName}” environment`}
        bodyContent={<InvitationModalBody {...props} />}
        onClose={props.onClose}
        footerContentRight={
          <>
            <Button
              buttonStyle="secondary"
              onClick={props.onClose}
              disabled={props.isInviting}
              {...getDataUiActionAttribute(DataUiAction.Cancel)}
            >
              Cancel
            </Button>
            <InviteButton
              disabledTooltip={disabledTooltip}
              onInvite={props.onSubmit}
              shouldConfirm={props.shouldConfirmInvite}
              emailsCountedInSubscriptionLimits={props.emailsCountedInSubscriptionLimits}
              isInvitationInProgress={props.isInviting}
            />
          </>
        }
        withFullWidthBodyContent
        withDividers
      />
    </>
  );
};
