import { ElementType } from '../../../applications/contentInventory/content/models/ContentItemElementType.ts';
import { AiActionName } from '../../../repositories/serverModels/ai/AiActionName.type.ts';
import { AiMessageErrorCode } from '../../../repositories/serverModels/ai/AiMessageErrorCode.ts';
import { Tone } from '../../../repositories/serverModels/ai/actions/AiServerModels.changeTone.ts';
import { AiErrorCode } from '../../features/AI/types/aiErrors.ts';

export enum AiActionSource {
  ActionDialog = 'action-dialog',
  ActionMenu = 'action-menu',
  BlockToolbar = 'block-toolbar',
  Element = 'element',
  InlineToolbar = 'inline-toolbar',
  TextShortcut = 'text-shortcut',
  Undo = 'undo',
}

export enum AiFollowingAction {
  AddTaxonomyTerms = 'add-taxonomy-terms',
  Cancel = 'cancel',
  CopyToClipboard = 'copy-to-clipboard',
  Discard = 'discard',
  EditInputs = 'edit-inputs',
  Escape = 'escape',
  RemoveSuggestedTerm = 'remove-suggested-term',
  ReplaceTaxonomyTerms = 'replace-taxonomy-terms',
  ReplaceSelection = 'replace-selection',
  SuggestedLinkedItemsAdded = 'suggested-linked-items-added',
  SuggestionPasted = 'suggestion-pasted',
  TryAgain = 'try-again',
}

export enum AiFinishedAction {
  Error = 'error',
  Result = 'result',
  ResultWithError = 'result-with-error',
}

export enum TrackingAiActionName {
  ImproveContent = 'ImproveContent',
  MatchWritingStyle = 'MatchWritingStyle',
  NewInlineInstruction = 'NewInlineInstruction',
  SuggestLinkedItems = 'SuggestLinkedItems',
}

export type AiEventContext = {
  readonly aiSessionId: Uuid;
};

export type AiElementEventContext = AiEventContext & {
  readonly elementId: Uuid;
  readonly elementType: ElementType;
};

type AiStartingCategorizeItemActionEventData = AiElementEventContext & {
  readonly action: AiActionName.CategorizeItem;
  readonly source: AiActionSource;
};

type AiStartingSuggestLinkedItemsActionEventData = AiElementEventContext & {
  readonly action: TrackingAiActionName.SuggestLinkedItems;
  readonly source: AiActionSource;
};

type AiStartingChangeToneActionEventData = AiElementEventContext & {
  readonly action: AiActionName.ChangeTone;
  readonly source: AiActionSource;
  readonly tone: Tone;
};

type AiStartingMatchWritingStyleOfItemActionEventData = AiElementEventContext & {
  readonly action: AiActionName.MatchWritingStyleOfItem;
  readonly referenceItemId: Uuid;
  readonly source: AiActionSource;
};

export type AiStartingTranslateContentActionEventData = AiElementEventContext & {
  readonly action: AiActionName.TranslateContent;
  readonly contentComponentId?: Uuid;
  readonly source: AiActionSource;
  readonly sourceVariantId?: Uuid;
  readonly sourceLanguageCode?: string;
  readonly targetLanguageCode: string;
};

export type AiSpecificStartingActionEventData =
  | AiStartingCategorizeItemActionEventData
  | AiStartingChangeToneActionEventData
  | AiStartingMatchWritingStyleOfItemActionEventData
  | AiStartingSuggestLinkedItemsActionEventData
  | AiStartingTranslateContentActionEventData;

type StartingActionName = AiActionName | TrackingAiActionName;

export type AiRemainingStartingActionEventData = AiElementEventContext & {
  readonly action: Exclude<StartingActionName, AiSpecificStartingActionEventData['action']>;
  readonly source: AiActionSource;
};

export type AiStartingActionEventData =
  | AiSpecificStartingActionEventData
  | AiRemainingStartingActionEventData;

type AiFinishedActionEventData = AiEventContext & {
  readonly action: AiFinishedAction;
  readonly errorCode?: AiMessageErrorCode | AiErrorCode.InactivityTimeout;
  readonly hasResult: boolean;
  readonly operationId: Uuid;
};

type AiFollowingSuggestionPastedActionEventData = AiElementEventContext & {
  readonly action: AiFollowingAction.SuggestionPasted;
};

type AiSuggestedLinkedItemEventData = {
  readonly itemId: Uuid;
  readonly score: number;
};

type AiFollowingSuggestedLinkedItemsAddedActionEventData = AiElementEventContext & {
  readonly action: AiFollowingAction.SuggestedLinkedItemsAdded;
  readonly items: ReadonlyArray<AiSuggestedLinkedItemEventData>;
};

export type AiSpecificFollowingActionData =
  | AiFollowingSuggestedLinkedItemsAddedActionEventData
  | AiFollowingSuggestionPastedActionEventData;

export type AiRemainingFollowingActionEventData = AiEventContext & {
  readonly action: Exclude<AiFollowingAction, AiSpecificFollowingActionData['action']>;
};

export type AiFollowingActionEventData =
  | AiSpecificFollowingActionData
  | AiRemainingFollowingActionEventData;

export type AiActionEventData =
  | AiStartingActionEventData
  | AiFinishedActionEventData
  | AiFollowingActionEventData;
