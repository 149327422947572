import { memoize } from '@kontent-ai/memoization';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../../@types/Dispatcher.type.ts';
import {
  INotificationBarDispatchProps,
  INotificationBarStateProps,
  NotificationBar,
  NotificationBarType,
} from '../../../../../../_shared/components/NotificationBar.tsx';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { renderDatetimeString } from '../../../../../../_shared/utils/dateTime/timeUtils.ts';
import { formatUserName } from '../../../../../../_shared/utils/usersUtils.ts';
import { IUsersData } from '../../../../../../data/reducers/users/IUsersData.type.ts';
import { TimelineItem } from '../../../../models/revisions/TimeLineItem.ts';
import { RevisionRestoredMessage } from '../../../Revisions/constants/uiConstants.ts';
import { closeRevisionRestoredNotificationBar } from '../../actions/contentItemEditingActions.ts';

const findTimeLineItemByItemId = memoize.maxOne(
  (timeline: ReadonlyArray<TimelineItem>, lastSelectedRevisionId: Uuid): TimelineItem | null => {
    const timelineItemIndex = timeline.findIndex(
      (item) => !!item && item.itemId === lastSelectedRevisionId,
    );
    return timelineItemIndex >= 0 ? (timeline[timelineItemIndex] ?? null) : null;
  },
);

function getRevisionRestoredMessage(
  timeline: ReadonlyArray<TimelineItem> | null,
  lastSelectedRevisionId: Uuid | null,
  users: IUsersData,
): string | null {
  if (!timeline || !lastSelectedRevisionId) {
    return null;
  }

  const timelineItem = findTimeLineItemByItemId(timeline, lastSelectedRevisionId);

  if (!timelineItem) {
    return null;
  }

  const user = users.usersById.get(timelineItem.lastModifiedBy);

  return RevisionRestoredMessage(
    formatUserName(user),
    renderDatetimeString(timelineItem.lastModified),
  );
}

function mapStateToProps(state: IStore): INotificationBarStateProps {
  const { entryTimeline, lastSelectedRevisionId } = state.contentApp;
  const { users } = state.data;

  return {
    type: NotificationBarType.Success,
    message: getRevisionRestoredMessage(entryTimeline, lastSelectedRevisionId, users),
  };
}

function mapDispatchToProps(dispatch: Dispatch): INotificationBarDispatchProps {
  return {
    onClosePanel: () => dispatch(closeRevisionRestoredNotificationBar()),
  };
}

export const RevisionRestoredNotificationBar: React.ComponentType = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationBar);
