import { useEffect } from 'react';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { isDisabled } from '../../../../../_shared/utils/contentItemUtils.ts';
import { TemporaryContentItemState } from '../../../../contentInventory/content/models/temporaryContentItemState.ts';
import { newlyCreatedItemEditorMounted } from '../actions/contentItemEditingActions.ts';
import { ContentItemEditor as ContentItemEditorComponent } from '../components/ContentItemEditor.tsx';
import { useIsJustCreatedItemClone } from '../context/IsJustCreatedItemCloneContext.tsx';
import { usePreviewContext } from '../context/PreviewContextProvider.tsx';
import { getAvailableValidPreviewLinksForUser } from './selectors/getAvailableValidPreviewLinksForUser.tsx';

type Props = {
  readonly shouldRenderSidebar: boolean;
};

export const ContentItemEditor = (props: Props) => {
  const dispatch = useDispatch();
  const isAnyPreviewLinkAvailable = useSelector(
    (state) => !!getAvailableValidPreviewLinksForUser(state).length,
  );
  const { isPreviewOpened } = usePreviewContext();

  const { isJustCreatedItemClone } = useIsJustCreatedItemClone();

  const isNewlyCreatedItem = useSelector((s) => {
    const { temporaryItem } = s.contentApp.editorUi;
    return (
      (temporaryItem && temporaryItem.itemState !== TemporaryContentItemState.Edited) ||
      isJustCreatedItemClone
    );
  });

  useEffect(() => {
    if (isNewlyCreatedItem) {
      dispatch(newlyCreatedItemEditorMounted());
    }
  }, [isNewlyCreatedItem]);

  const disabled = useSelector((s) => isDisabled(false, s.contentApp.editedContentItemVariant));
  const contentItemName = useSelector((s) => s.contentApp.editedContentItem?.name ?? null);
  const progressMessage = useSelector((s) => s.contentApp.progressMessage);

  const allEditingDataLoaded = useSelector((s) => {
    const {
      editedContentItem,
      editedContentItemVariant,
      editedContentItemVariantElements,
      loadedContentItemTypes,
    } = s.contentApp;

    return !!(
      editedContentItem &&
      loadedContentItemTypes.get(editedContentItem.editedContentItemTypeId) &&
      editedContentItemVariant &&
      editedContentItemVariantElements
    );
  });

  if (progressMessage) {
    return (
      <div className="utility-message utility-message--centered utility-message--in-progress">
        <h1 className="utility-message__title">{progressMessage}</h1>
      </div>
    );
  }

  if (!allEditingDataLoaded) {
    return <Loader />;
  }

  return (
    <ContentItemEditorComponent
      contentItemName={contentItemName}
      disabled={disabled}
      isPreviewOpened={isPreviewOpened && isAnyPreviewLinkAvailable}
      shouldRenderSidebar={props.shouldRenderSidebar}
    />
  );
};
