import { useAttachRef, useEnsuredContext } from '@kontent-ai/hooks';
import { useInteractOutside } from '@react-aria/interactions';
import { AriaMenuOptions, useMenu } from '@react-aria/menu';
import { AriaPopoverProps, DismissButton, Overlay, usePopover } from '@react-aria/overlays';
import { mergeProps } from '@react-aria/utils';
import { animated, useTransition } from '@react-spring/web';
import { OverlayTriggerState } from '@react-stately/overlays';
import React, { useContext, PropsWithChildren, useRef } from 'react';
import { Paper, PaperProps } from '../../../containers/Paper/Paper.tsx';
import { Box } from '../../../layout/Box/Box.tsx';
import { spacingPopupDistance } from '../../../tokens/decision/spacing.ts';
import { defaultTippyZIndex } from '../../../tokens/decision/zIndex.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { VerticalMenuProps } from '../../VerticalMenu/VerticalMenu.tsx';
import {
  verticalMenuMaxHeight,
  verticalMenuMaxWidth,
  verticalMenuMinWidth,
} from '../../VerticalMenu/decisionTokens.ts';
import { VerticalMenuState } from '../../VerticalMenu/useNonAccessibleVerticalMenu.ts';
import { useVerticalMenu } from '../../VerticalMenu/useVerticalMenu.ts';
import {
  PortalContainerContext,
  PortalContainerContextProvider,
} from '../../_contexts/PortalContainerContext.tsx';
import {
  Descendant,
  DescendantContextProvider,
  useDescendantContext,
  useDescendantContextInit,
} from '../contexts/DescendantContext.tsx';
import { MenuContext } from '../contexts/MenuContext.tsx';
import { SubmenuContext } from '../contexts/SubmenuContext.tsx';

const dropDownTransitions = {
  from: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  leave: {
    opacity: 0,
  },
};

type PopoverProps = Omit<AriaPopoverProps, 'popoverRef' | 'maxHeight'> &
  Pick<PaperProps, 'minWidth' | 'maxWidth' | 'maxHeight' | 'width'> &
  Readonly<{
    state: OverlayTriggerState;
    type: 'root' | 'submenu';
  }>;

const MenuPopover = React.forwardRef<HTMLDivElement, PropsWithChildren<PopoverProps>>(
  ({ children, state, maxHeight, type, offset, ...props }, forwardedRef) => {
    const { refObject, refToForward } = useAttachRef(forwardedRef);

    const menuContext = useContext(MenuContext);
    const submenuContext = useContext(SubmenuContext);
    const isRoot = type === 'root';

    const { portalContainerRef } = useContext(PortalContainerContext);
    const newPortalContainerRef = useRef<HTMLDivElement>(null);

    const { popoverProps, underlayProps } = usePopover(
      {
        ...props,
        offset,
        popoverRef: refObject,
        shouldUpdatePosition: true,
      },
      state,
    );

    const transitions = useTransition(state.isOpen, {
      ...dropDownTransitions,
      config: {
        duration: 150,
      },
    });

    useInteractOutside({
      ref: refObject,
      onInteractOutside: (event) => {
        if (isRoot) {
          if (event.target && !menuContext?.menuListRef.current?.contains(event.target as Node)) {
            menuContext?.menuTriggerState.close();
          }
        } else if (
          event.target &&
          !submenuContext?.submenuTriggerRef.current?.contains(event.target as Node)
        ) {
          submenuContext?.submenuTriggerState?.closeAll();
        }
      },
    });

    return transitions(
      (style, item) =>
        item && (
          <Overlay portalContainer={portalContainerRef.current ?? undefined}>
            <PortalContainerContextProvider portalContainerRef={newPortalContainerRef}>
              {isRoot && (
                <div
                  {...underlayProps}
                  style={{
                    position: 'fixed',
                    inset: 0,
                  }}
                />
              )}
              <Box
                {...popoverProps}
                ref={refToForward}
                style={{
                  ...popoverProps.style,
                  zIndex: defaultTippyZIndex,
                }}
                display="flex"
                flexDirection="column"
              >
                <animated.div
                  style={style}
                  css={`
                  display: flex;
                  flex-direction: column;
                  min-height: 0;
                `}
                >
                  <Paper
                    component={animated.div}
                    display="flex"
                    flexDirection="column"
                    overflow="hidden"
                  >
                    <Box
                      paddingY={Spacing.S}
                      overflowX="hidden"
                      overflowY="auto"
                      tabIndex={-1}
                      {...props}
                    >
                      <DismissButton onDismiss={() => state.close()} />
                      {children}
                      <DismissButton onDismiss={() => state.close()} />
                    </Box>
                  </Paper>
                  <div ref={newPortalContainerRef} />
                </animated.div>
              </Box>
            </PortalContainerContextProvider>
          </Overlay>
        ),
    );
  },
);

MenuPopover.displayName = 'MenuPopover';

const InitializedMenu = React.forwardRef<HTMLDivElement, PropsWithChildren<MenuListInnerProps>>(
  (props, forwardedRef) => {
    const { refObject, refToForward } = useAttachRef(forwardedRef);

    const { state } = useDescendantContext<VerticalMenuState<Descendant>>();
    const { menuProps } = useMenu(
      {
        ...props,
        // TODO: allow selection
        selectionMode: 'none',
      },
      state,
      refObject,
    );

    return (
      <div ref={refToForward} {...menuProps} role={props?.role ?? menuProps.role}>
        {props.children}
      </div>
    );
  },
);

InitializedMenu.displayName = 'InitializedMenu';

type MenuListInnerProps = Omit<AriaMenuOptions<any>, 'items'> & {
  readonly role?: HTMLDivElement['role'];
};

const MenuListInner = React.forwardRef<HTMLDivElement, PropsWithChildren<MenuListInnerProps>>(
  (props, forwardedRef) => {
    const descendantContextProps = useDescendantContextInit();
    const { descendants } = descendantContextProps;
    const { verticalMenuState } = useVerticalMenu(descendants);

    const isMenuInitialized = descendants.length && verticalMenuState.collection.size;

    return (
      <DescendantContextProvider state={verticalMenuState} {...descendantContextProps}>
        {isMenuInitialized ? <InitializedMenu ref={forwardedRef} {...props} /> : props.children}
      </DescendantContextProvider>
    );
  },
);

MenuListInner.displayName = 'MenuListInner';

type MenuListProps = Omit<VerticalMenuProps<any>, 'items' | 'renderItem' | 'state'> &
  Pick<AriaPopoverProps, 'placement' | 'isNonModal'> & {
    readonly children: React.ReactNode;
    readonly role?: HTMLDivElement['role'];
    readonly offset?: number;
  };

export const MenuList = React.forwardRef<HTMLDivElement, MenuListProps>(
  (
    {
      children,
      contain = true,
      isNonModal = false,
      maxHeight = verticalMenuMaxHeight,
      maxWidth = verticalMenuMaxWidth,
      minWidth = verticalMenuMinWidth,
      offset = spacingPopupDistance,
      placement,
      restoreFocus = true,
      width,
      ...otherProps
    },
    forwardedRef,
  ) => {
    const menuContext = useEnsuredContext(MenuContext);
    const submenuContext = useContext(SubmenuContext);
    const isSubmenu = !!submenuContext;

    const sizeProps = {
      maxHeight,
      maxWidth,
      minWidth,
      width,
    };

    if (isSubmenu && submenuContext.submenuTriggerState) {
      return (
        <MenuPopover
          offset={offset}
          isNonModal={isNonModal}
          placement={placement ?? 'right'}
          state={submenuContext.submenuTriggerState}
          triggerRef={submenuContext.submenuTriggerRef}
          ref={forwardedRef}
          type="submenu"
          {...mergeProps(submenuContext.popoverProps, sizeProps, otherProps)}
        >
          <MenuListInner ref={submenuContext.submenuListRef} {...submenuContext.submenuListProps}>
            {children}
          </MenuListInner>
        </MenuPopover>
      );
    }
    if (menuContext.menuTriggerState) {
      return (
        <MenuPopover
          isNonModal={isNonModal}
          offset={offset}
          placement={placement ?? 'bottom'}
          state={menuContext.menuTriggerState}
          triggerRef={menuContext.menuTriggerRef}
          ref={forwardedRef}
          type="root"
          {...mergeProps(sizeProps, otherProps)}
        >
          <MenuListInner ref={menuContext.menuListRef} {...menuContext.menuListProps}>
            {children}
          </MenuListInner>
        </MenuPopover>
      );
    }

    return null;
  },
);

MenuList.displayName = 'MenuList';
