import Immutable from 'immutable';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import {
  IRule,
  IRuleWithScope,
  ITypeRuleSetting,
} from '../../../../data/models/roles/IRoleSettings.ts';
import {
  IRoleWithSettings,
  getRoleWithSettingsFromServerModel,
} from '../../../../data/models/roles/IRoleWithSettings.ts';
import { IRoleServerModel } from '../../../../repositories/serverModels/IRoleServerModel.type.ts';
import { UntitledRoleSurrogateName } from '../constants/UIConstants.ts';

export const getTypeSettingsUtils = (allTypes: Immutable.Map<Uuid, IContentType>) => {
  const isExistingType = (typeId: Uuid): boolean => allTypes.has(typeId);

  const isExistingGroup = (typeId: Uuid, groupId: Uuid): boolean =>
    allTypes.get(typeId)?.contentGroups.some((group) => group.id === groupId) ?? false;

  const containsOnlyDeletedTypes = (typeSettings: ReadonlyArray<ITypeRuleSetting>): boolean =>
    typeSettings.every((setting) => !isExistingType(setting.typeId));

  const containsOnlyDeletedGroups = ({ typeId, contentGroupIds }: ITypeRuleSetting): boolean =>
    contentGroupIds.every((groupId: Uuid) => !isExistingGroup(typeId, groupId));

  return {
    isExistingType,
    isExistingGroup,
    containsOnlyDeletedTypes,
    containsOnlyDeletedGroups,
  };
};

export const withoutUnnecessaryDeletedTypesAndGroups = (
  typeSettings: ReadonlyArray<ITypeRuleSetting>,
  allTypes: Immutable.Map<Uuid, IContentType>,
): ReadonlyArray<ITypeRuleSetting> => {
  const { isExistingGroup, isExistingType, containsOnlyDeletedGroups, containsOnlyDeletedTypes } =
    getTypeSettingsUtils(allTypes);

  const hasOnlyDeletedTypes = containsOnlyDeletedTypes(typeSettings);

  return typeSettings
    .filter((type) => hasOnlyDeletedTypes || isExistingType(type.typeId))
    .map((typeRuleSetting): ITypeRuleSetting => {
      const hasOnlyDeletedGroups = containsOnlyDeletedGroups(typeRuleSetting);

      return {
        typeId: typeRuleSetting.typeId,
        contentGroupIds: typeRuleSetting.contentGroupIds
          .filter(
            (groupId: Uuid) =>
              hasOnlyDeletedGroups || isExistingGroup(typeRuleSetting.typeId, groupId),
          )
          .toSet(),
      };
    });
};

const getEditedRoleName = (originalName: string | null | undefined): string =>
  !originalName || originalName === UntitledRoleSurrogateName ? '' : originalName;

export const getEditedRoleApplicationModel = (
  serverModel: IRoleServerModel,
  allTypes: Immutable.Map<Uuid, IContentType>,
): IRoleWithSettings => {
  const roleWithSettings = getRoleWithSettingsFromServerModel(serverModel);
  const getDecoratedSelectedGroups = ({
    typeId,
    contentGroupIds,
  }: ITypeRuleSetting): Immutable.Set<Uuid> =>
    contentGroupIds.isEmpty()
      ? Immutable.Set<Uuid>(allTypes.get(typeId)?.contentGroups.map((group) => group.id) ?? [])
      : contentGroupIds;

  const getTypeSettings = (
    typeSettings: ReadonlyArray<ITypeRuleSetting>,
  ): ReadonlyArray<ITypeRuleSetting> =>
    withoutUnnecessaryDeletedTypesAndGroups(
      typeSettings.map((type) => ({
        typeId: type.typeId,
        contentGroupIds: getDecoratedSelectedGroups(type),
      })),
      allTypes,
    );

  return {
    ...roleWithSettings,
    name: getEditedRoleName(serverModel.name),
    settings: {
      canRules: roleWithSettings.settings.canRules.map(
        (rule): IRuleWithScope => ({
          ...rule,
          types: getTypeSettings(rule.types),
        }),
      ),
      cannotRules: roleWithSettings.settings.cannotRules.map(
        (rule): IRule => ({
          ...rule,
          types: getTypeSettings(rule.types),
        }),
      ),
    },
  };
};
