import { ComponentProps } from 'react';
import { ListingFilterCategorySelector } from '../../../applications/contentInventory/content/features/ListingFilter/components/ListingFilterCategorySelector.tsx';

export const getDisabledAiFilterProps = (
  isAiSearchUsed: boolean,
):
  | Pick<
      ComponentProps<typeof ListingFilterCategorySelector<any>>,
      'caption' | 'disabled' | 'placeholder'
    >
  | undefined => (isAiSearchUsed ? disabledAiFilterProps : undefined);

const disabledAiFilterProps = {
  caption: 'Not supported with semantic search yet.',
  disabled: true,
  placeholder: '',
};
