import React from 'react';
import { PreviewContentItemInfo } from '../../../../utils/selectDeepestItemWithPreview.ts';
import { WebSpotlightPreviewInItemEditingContent } from '../../containers/contentItemPreview/WebSpotlightPreviewInItemEditingContent.tsx';
import { ContentItemPreviewResizer } from './ContentItemPreviewResizer.tsx';

type ContentItemPreviewCanvasProps = Readonly<{
  contentItemId: Uuid;
}>;

export const ContentItemPreviewCanvas: React.FC<ContentItemPreviewCanvasProps> = ({
  contentItemId,
}) => {
  const previewContentItemInfo: PreviewContentItemInfo = {
    contentItemId,
    isForLastRouteItem: true,
    isPageContent: false,
  };

  return (
    <ContentItemPreviewResizer>
      <WebSpotlightPreviewInItemEditingContent
        previewContentItemInfo={previewContentItemInfo}
        sharedPreviewUrl={null}
      />
    </ContentItemPreviewResizer>
  );
};
