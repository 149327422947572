import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { IRouteContext, RouteContext } from '../../_shared/containers/routing/RouteContext.tsx';
import { useDispatch } from '../../_shared/hooks/useDispatch.ts';
import { smartLinkRouteEntered, smartLinkRouteLeft } from './actions/smartLinkActions.ts';
import { IItemElement } from './actions/thunks/editItemVariant.ts';
import {
  AssetsLibraryComponentKey,
  AssetsLibraryRoute,
  AssetsLibraryRouteKey,
  CommentComponentKey,
  CommentRoute,
  CommentRouteKey,
  EditComponentElementVariantCodenameRoute,
  EditComponentElementVariantCodenameRouteKey,
  EditComponentElementVariantCodenameRouteParams,
  EditItemComponentKey,
  EditItemElementVariantCodenameRoute,
  EditItemElementVariantCodenameRouteKey,
  EditItemElementVariantCodenameRouteParams,
  EditItemRoute,
  EditItemRouteKey,
  EditItemVariantCodenameComponentKey,
  EditItemVariantCodenameRoute,
  EditItemVariantCodenameRouteKey,
  EditItemVariantCodenameRouteParams,
  FilterComponentKey,
  FilterRoute,
  FilterRouteKey,
  OpenProjectComponentKey,
  OpenProjectRoute,
  OpenProjectRouteKey,
  OtherComponentKey,
  OtherRouteKey,
  SmartLinkRootRoute,
  SmartLinkRootRouteKey,
  TaskComponentKey,
  TaskRoute,
  TaskRouteKey,
  WebSpotlightSharedPreviewComponentKey,
  WebSpotlightSharedPreviewRoute,
  WebSpotlightSharedPreviewRouteKey,
  componentElementRouteSegment,
  itemElementRouteSegment,
} from './constants/routePaths.ts';
import { AssetLibrarySmartLink } from './containers/AssetLibrarySmartLink.tsx';
import { CommentSmartLink } from './containers/CommentSmartLink.tsx';
import { EditItemSmartLink } from './containers/EditItemSmartLink.tsx';
import { EditItemVariantCodenameSmartLink } from './containers/EditItemVariantCodenameSmartLink.tsx';
import { EditItemVariantSmartLink } from './containers/EditItemVariantSmartLink.tsx';
import { FilterSmartLink } from './containers/FilterSmartLink.tsx';
import { OpenProjectSmartLink } from './containers/OpenProjectSmartLink.tsx';
import { OtherRouteSmartLink } from './containers/OtherRouteSmartLink.tsx';
import { TaskSmartLink } from './containers/TaskSmartLink.tsx';
import { WebSpotlightSharedPreviewSmartLink } from './containers/WebSpotlightSharedPreviewSmartLink.tsx';

interface IRecursiveItemElementDataProps {
  readonly path: ReadonlyArray<IItemElement>;
  readonly alreadyMatched: string;
}

const RecursiveItemElementSegment: React.FC<
  React.PropsWithChildren<IRecursiveItemElementDataProps>
> = (props) => (
  <Switch>
    <Route path={`${props.alreadyMatched}/${componentElementRouteSegment}`}>
      <RouteContext>
        {({ match }: IRouteContext<EditComponentElementVariantCodenameRouteParams>) => (
          <RecursiveItemElementSegment
            alreadyMatched={match.url}
            path={[
              ...props.path,
              {
                itemId: match.params.itemGuid,
                deliveryContentComponentId: match.params.deliveryContentComponentId,
                elementCodename: match.params.elementCodename,
              },
            ]}
          />
        )}
      </RouteContext>
    </Route>
    <Route path={`${props.alreadyMatched}/${itemElementRouteSegment}`}>
      <RouteContext>
        {({ match }: IRouteContext<EditItemElementVariantCodenameRouteParams>) => (
          <RecursiveItemElementSegment
            alreadyMatched={match.url}
            path={[
              ...props.path,
              {
                itemId: match.params.itemGuid,
                elementCodename: match.params.elementCodename,
              },
            ]}
          />
        )}
      </RouteContext>
    </Route>
    <Route path={EditItemVariantCodenameRoute}>
      <RouteContext>
        {({ match }: IRouteContext<EditItemVariantCodenameRouteParams>) => (
          <EditItemVariantSmartLink key={match.url} path={props.path} />
        )}
      </RouteContext>
    </Route>
  </Switch>
);

RecursiveItemElementSegment.displayName = 'RecursiveItemElementSegment';

const SmartLinkApp: React.FC<React.PropsWithChildren<NoProps>> = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(smartLinkRouteEntered());

    return () => {
      dispatch(smartLinkRouteLeft());
    };
  }, []);

  return <>{children}</>;
};

export const getSmartLinkRoutes = () => {
  return (
    <Route key={SmartLinkRootRouteKey} path={SmartLinkRootRoute}>
      <SmartLinkApp>
        <Switch>
          <Route key={AssetsLibraryRouteKey} path={AssetsLibraryRoute}>
            <AssetLibrarySmartLink key={AssetsLibraryComponentKey} />
          </Route>
          <Route key={OpenProjectRouteKey} path={OpenProjectRoute}>
            <OpenProjectSmartLink key={OpenProjectComponentKey} />
          </Route>
          <Route key={EditItemRouteKey} path={EditItemRoute}>
            <EditItemSmartLink key={EditItemComponentKey} />
          </Route>
          <Route
            key={EditComponentElementVariantCodenameRouteKey}
            path={EditComponentElementVariantCodenameRoute}
          >
            <RouteContext>
              {({ match }: IRouteContext<EditComponentElementVariantCodenameRouteParams>) => (
                <RecursiveItemElementSegment
                  path={[
                    {
                      itemId: match.params.itemGuid,
                      deliveryContentComponentId: match.params.deliveryContentComponentId,
                      elementCodename: match.params.elementCodename,
                    },
                  ]}
                  alreadyMatched={match.url}
                />
              )}
            </RouteContext>
          </Route>
          <Route
            key={EditItemElementVariantCodenameRouteKey}
            path={EditItemElementVariantCodenameRoute}
          >
            <RouteContext>
              {({ match }: IRouteContext<EditItemElementVariantCodenameRouteParams>) => (
                <RecursiveItemElementSegment
                  path={[
                    {
                      itemId: match.params.itemGuid,
                      elementCodename: match.params.elementCodename,
                    },
                  ]}
                  alreadyMatched={match.url}
                />
              )}
            </RouteContext>
          </Route>
          <Route key={EditItemVariantCodenameRouteKey} path={EditItemVariantCodenameRoute}>
            <EditItemVariantCodenameSmartLink key={EditItemVariantCodenameComponentKey} />
          </Route>
          <Route key={CommentRouteKey} path={CommentRoute}>
            <CommentSmartLink key={CommentComponentKey} />
          </Route>
          <Route key={TaskRouteKey} path={TaskRoute}>
            <TaskSmartLink key={TaskComponentKey} />
          </Route>
          <Route key={FilterRouteKey} path={FilterRoute}>
            <FilterSmartLink key={FilterComponentKey} />
          </Route>
          <Route key={WebSpotlightSharedPreviewRouteKey} path={WebSpotlightSharedPreviewRoute}>
            <WebSpotlightSharedPreviewSmartLink key={WebSpotlightSharedPreviewComponentKey} />
          </Route>
          <Route key={OtherRouteKey}>
            <OtherRouteSmartLink key={OtherComponentKey} />
          </Route>
        </Switch>
      </SmartLinkApp>
    </Route>
  );
};
