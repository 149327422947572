import { Box } from '@kontent-ai/component-library/Box';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { SrOnly } from '@kontent-ai/component-library/styles';
import { Spacing, Typography } from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';
import { notInCollectionText } from '../../utils/assets/assetUtils.ts';
import { IgnoreByGrammarly } from '../IgnoreByGrammarly.tsx';
interface IAssetCollectionProps {
  readonly collectionName?: string | null;
}

const StyledLabel = styled.span`
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const AssetCollection: React.FC<IAssetCollectionProps> = ({ collectionName }) => {
  return (
    collectionName && (
      <Box typography={Typography.LabelLarge} paddingTop={Spacing.S} position="relative">
        <Tooltip placement="top-end" tooltipText={`Collection: ${collectionName}`}>
          <IgnoreByGrammarly>
            <StyledLabel>
              {notInCollectionText !== collectionName ? (
                <>
                  <SrOnly>collection: </SrOnly> {collectionName}
                </>
              ) : (
                <>
                  <span aria-hidden>{notInCollectionText}</span>
                  <SrOnly>Not in collection</SrOnly>
                </>
              )}
            </StyledLabel>
          </IgnoreByGrammarly>
        </Tooltip>
      </Box>
    )
  );
};

AssetCollection.displayName = 'AssetCollection';
