import { alphabetically, createCompare, noOperation } from '@kontent-ai/utils';
import { useCallback, useContext } from 'react';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { ContentItemFilterOrigin } from '../../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { getSelectedLanguageId } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getIsAiSearchUsed } from '../../../../../paperModels/aiSearch/utils/getIsAiSearchUsed.ts';
import {
  onCollectionFilterChanged,
  onContentItemStatusFilterChanged,
  onContentTypeFilterChanged,
  onContributorsFilterChanged,
  onPublishingStatusFilterChanged,
  onSitemapFilterChanged,
  onSpaceFilterChanged,
  onTaxonomyGroupsFilterChanged,
  onWorkflowStatusFilterChanged,
} from '../../../../contentInventory/content/features/ListingFilter/actions/listingFilterActions.ts';
import {
  createLinkedFilter,
  saveFilter,
} from '../../../../contentInventory/content/features/ListingFilter/actions/thunkListingFilterActions.ts';
import { ContentItemFilter as ContentItemFilterComponent } from '../../../../contentInventory/content/features/ListingFilter/components/ContentItemFilter.tsx';
import {
  ContentItemFilter_Left,
  ContentItemFilter_SetUp,
} from '../../../../contentInventory/content/features/ListingFilter/constants/listingFilterActionTypes.ts';
import { ContentItemFilterActionTypes } from '../../../../contentInventory/content/features/ListingFilter/containers/ContentItemFilter.tsx';
import { PreselectedFilter } from '../../../../contentInventory/content/features/ListingFilter/hooks/useSetUpContentItemFilter.ts';
import { IListingFilter } from '../../../../contentInventory/content/models/filter/IListingFilter.ts';
import { isFullTextSearchUnavailableOrDisabled } from '../../../../contentInventory/shared/selectors/fullTextSearchStatus.ts';
import { ContentTypeAsComponentUsageDialogContext } from '../../../../contentModels/contentTypes/context/ContentTypeAsComponentUsageDialogContext.tsx';
import { filterContentItems } from '../../../../missionControl/contentStatus/actions/thunkContentStatusActions.ts';
import { LanguageFilterContext } from '../../../../missionControl/contentStatus/contexts/LanguageFilter.tsx';
import { ContentStatusFilterSelectors } from './ContentStatusFilterSelectors.tsx';

type Props = Readonly<{
  clearFilter: () => void;
  forceContentTypesTooltipText?: string | undefined;
  forcedContentTypeIds?: ReadonlySet<Uuid>;
  hideVariantSpecificInfo?: boolean;
  listingItemsLoadingStatus: LoadingStatus;
  preselectedFilter?: PreselectedFilter;
}>;

export const ContentStatusFilter = ({ clearFilter: clearFilterFromProps, ...props }: Props) => {
  const { selectedLanguageIds } = useContext(LanguageFilterContext);

  const savedFiltersUi = useSelector((s) => s.contentApp.listingUi.savedFiltersUi);
  const filtersById = useSelector((s) => s.data.filters.byId);
  const storedFilters = Array.from(filtersById.values()).sort(
    createCompare({
      compare: alphabetically,
      select: (t) => t.name,
    }),
  );
  const filter = useSelector((s) => s.contentApp.listingUi.filter);
  const isFilterBeingCreated = useSelector(
    (s) => s.contentApp.listingUi.savedFiltersUi.isFilterBeingCreated,
  );
  const user = useSelector((s) => s.data.user);

  const isInContentTypeAsComponentUsageDialog = useContext(
    ContentTypeAsComponentUsageDialogContext,
  );
  const displayTaxonomyWarningStatus = useSelector((s) => {
    const fullTextSearchUnavailableOrDisabled = isFullTextSearchUnavailableOrDisabled(s);
    const isSelectedLanguageDefault = getSelectedLanguageId(s) === DefaultVariantId;
    return (
      fullTextSearchUnavailableOrDisabled &&
      (!isSelectedLanguageDefault || isInContentTypeAsComponentUsageDialog)
    );
  });

  const isAiSearchUsed = useSelector((s) => getIsAiSearchUsed(s.contentApp.listingUi.filter));

  const dispatch = useDispatch();
  const filterSetUp = useCallback(
    (listingFilter: IListingFilter) => dispatch(contentItemFilterSetUp(listingFilter)),
    [],
  );
  const filterLeft = useCallback(() => dispatch(contentItemFilterLeft()), []);

  const clearFilter = useCallback(() => {
    clearFilterFromProps();
  }, [clearFilterFromProps]);

  return (
    <ContentItemFilterComponent
      filter={filter}
      filterLeft={filterLeft}
      filterSetUp={filterSetUp}
      filters={storedFilters}
      forcedContentTypeIds={props.forcedContentTypeIds}
      hideVariantSpecificInfo={props.hideVariantSpecificInfo}
      isFilterBeingCreated={isFilterBeingCreated}
      listingItemsLoadingStatus={props.listingItemsLoadingStatus}
      onClearFilter={clearFilter}
      onCreateLinkedFilter={() => dispatch(createLinkedFilter())}
      onFilterChange={() => dispatch(filterContentItems(selectedLanguageIds))}
      onSaveFilter={(name) => dispatch(saveFilter({ name }))}
      origin={ContentItemFilterOrigin.ContentStatus}
      preselectedFilter={props.preselectedFilter}
      renderSelectors={() => (
        <ContentStatusFilterSelectors
          displayTaxonomyWarningStatus={displayTaxonomyWarningStatus}
          filter={filter}
          forceContentTypesTooltipText={props.forceContentTypesTooltipText}
          forcedContentTypeIds={props.forcedContentTypeIds}
          isAiSearchUsed={isAiSearchUsed}
          onCollectionSelectionChanged={(ids) => dispatch(onCollectionFilterChanged(ids))}
          onContentItemStatusSelectionChanged={(status) =>
            dispatch(onContentItemStatusFilterChanged(status))
          }
          onContentTypesSelectionChanged={
            props.forcedContentTypeIds
              ? noOperation
              : (ids) => dispatch(onContentTypeFilterChanged(ids))
          }
          onContributorsSelectionChanged={(ids) => dispatch(onContributorsFilterChanged(ids))}
          onPublishingStatusSelectionChanged={(publishingStatus) =>
            dispatch(onPublishingStatusFilterChanged(publishingStatus))
          }
          onSitemapSelectionChanged={(selectedSitemapNodes) =>
            dispatch(onSitemapFilterChanged(selectedSitemapNodes))
          }
          onSpaceSelectionChanged={(ids) => dispatch(onSpaceFilterChanged(ids))}
          onTaxonomySelectionChanged={(groupToTerms) =>
            dispatch(onTaxonomyGroupsFilterChanged(groupToTerms))
          }
          onWorkflowsSelectionChanged={(...args) =>
            dispatch(onWorkflowStatusFilterChanged(...args))
          }
          user={user}
        />
      )}
      savedFilters={filtersById}
      savedFiltersUi={savedFiltersUi}
    />
  );
};

const contentItemFilterSetUp = (filter: IListingFilter) =>
  ({
    type: ContentItemFilter_SetUp,
    payload: {
      filter,
      origin: ContentItemFilterOrigin.ContentStatus,
    },
  }) satisfies ContentItemFilterActionTypes;

const contentItemFilterLeft = () =>
  ({
    type: ContentItemFilter_Left,
    payload: {
      origin: ContentItemFilterOrigin.ContentStatus,
    },
  }) satisfies ContentItemFilterActionTypes;
