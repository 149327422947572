import { Box } from '@kontent-ai/component-library/Box';
import { forwardRef } from 'react';
import { ContentItemPreviewActionBar } from './ContentItemPreviewActionBar.tsx';
import { ContentItemPreviewCanvas } from './ContentItemPreviewCanvas.tsx';

type ContentItemPreviewProps = Readonly<{
  contentItemId: Uuid;
  spaceId: Uuid | null;
  setSpaceId: (newState: Uuid) => void;
}>;

export const ContentItemPreview = forwardRef<HTMLDivElement, ContentItemPreviewProps>(
  ({ contentItemId, spaceId, setSpaceId }, ref) => {
    return (
      <Box css="grid-area: preview" display="flex" flexDirection="column" ref={ref}>
        <ContentItemPreviewActionBar spaceId={spaceId} setSpaceId={setSpaceId} />
        <Box width="100%" height="100%" flexGrow={1}>
          <ContentItemPreviewCanvas contentItemId={contentItemId} />
        </Box>
      </Box>
    );
  },
);
