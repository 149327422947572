import { EditorState } from 'draft-js';
import React from 'react';
import { isMacOS } from '../../../../../_shared/utils/agentTypeDetection.ts';
import { BlockType } from '../../../utils/blocks/blockType.ts';
import {
  isCustomBlockSleeve,
  isInTable,
  isTableCell,
} from '../../../utils/blocks/blockTypeUtils.ts';
import { findBlockIndex } from '../../../utils/blocks/editorBlockUtils.ts';
import { isFocusOnTheCustomBlock } from '../../../utils/editorSelectionUtils.ts';
import { getBlocks } from '../../../utils/general/editorContentGetters.ts';
import { RichTextInputCommand, TextInputCommand } from './EditorCommand.ts';

type CommandInfo<TCommand> = {
  readonly command: TCommand;
  readonly isCustomCommandRelevant: (editorState: EditorState) => boolean;
};

export type KeyCommandMap<TCommand> = Record<string, CommandInfo<TCommand> | undefined>;

const always = () => true;
const isFocusInCustomBlockSleeve = (editorState: EditorState): boolean => {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const blocks = getBlocks(content);
  const selectionFocusIndex = findBlockIndex(blocks, selection.getFocusKey());
  return isCustomBlockSleeve(selectionFocusIndex, blocks);
};
const isFocusAtTheStartOfTableContent = (editorState: EditorState): boolean => {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const focusBlock = content.getBlockForKey(selection.getFocusKey());
  if (isInTable(focusBlock) && selection.getFocusOffset() === 0) {
    const previousBlock = content.getBlockBefore(focusBlock.getKey());
    return !!previousBlock && isTableCell(previousBlock) && previousBlock.getDepth() === 0;
  }
  return false;
};
const isFocusAtTheEndOfTableContent = (editorState: EditorState) => {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const focusBlock = content.getBlockForKey(selection.getFocusKey());
  if (isInTable(focusBlock) && selection.getFocusOffset() === focusBlock.getLength()) {
    const blocks = content.getBlocksAsArray();
    return isCustomBlockSleeve(blocks.indexOf(focusBlock) + 1, blocks, BlockType.TableCell);
  }
  return false;
};

export const textKeyCommandMap: KeyCommandMap<TextInputCommand> = {
  'CTRL+ALT+M': {
    command: TextInputCommand.AddComment,
    isCustomCommandRelevant: always,
  },
  'CTRL+ALT+S': {
    command: TextInputCommand.AddSuggestion,
    isCustomCommandRelevant: always,
  },

  'CTRL+Z': {
    command: TextInputCommand.Undo,
    isCustomCommandRelevant: always,
  },
  'CTRL+Y': {
    command: TextInputCommand.Redo,
    isCustomCommandRelevant: always,
  },
  'CTRL+SHIFT+Z': {
    command: TextInputCommand.Redo,
    isCustomCommandRelevant: always,
  },
};

export const richTextKeyCommandMap: KeyCommandMap<RichTextInputCommand> = {
  ...textKeyCommandMap,

  Tab: {
    command: RichTextInputCommand.BlockDepthIncrease,
    isCustomCommandRelevant: always,
  },
  'SHIFT+Tab': {
    command: RichTextInputCommand.BlockDepthDecrease,
    isCustomCommandRelevant: always,
  },

  [isMacOS() ? 'CTRL+ArrowLeft' : 'Home']: {
    command: RichTextInputCommand.MoveCaretToStartOfLine,
    isCustomCommandRelevant: isFocusInCustomBlockSleeve,
  },
  [isMacOS() ? 'CTRL+ArrowRight' : 'End']: {
    command: RichTextInputCommand.MoveCaretToEndOfLine,
    isCustomCommandRelevant: isFocusInCustomBlockSleeve,
  },
  [isMacOS() ? 'CTRL+SHIFT+ArrowLeft' : 'SHIFT+Home']: {
    command: RichTextInputCommand.AdjustSelectionToStartOfLine,
    isCustomCommandRelevant: isFocusInCustomBlockSleeve,
  },
  [isMacOS() ? 'CTRL+SHIFT+ArrowRight' : 'SHIFT+End']: {
    command: RichTextInputCommand.AdjustSelectionToEndOfLine,
    isCustomCommandRelevant: isFocusInCustomBlockSleeve,
  },
  ArrowLeft: {
    command: RichTextInputCommand.MoveCaretToPreviousBlock,
    isCustomCommandRelevant: (editorState) =>
      isFocusInCustomBlockSleeve(editorState) || isFocusAtTheStartOfTableContent(editorState),
  },
  ArrowRight: {
    command: RichTextInputCommand.MoveCaretToNextBlock,
    isCustomCommandRelevant: (editorState) =>
      isFocusInCustomBlockSleeve(editorState) || isFocusAtTheEndOfTableContent(editorState),
  },
  Space: {
    command: RichTextInputCommand.Focus,
    isCustomCommandRelevant: isFocusOnTheCustomBlock,
  },
  'SHIFT+ArrowLeft': {
    command: RichTextInputCommand.AdjustSelectionToPreviousBlock,
    isCustomCommandRelevant: isFocusAtTheStartOfTableContent,
  },
  'SHIFT+ArrowRight': {
    command: RichTextInputCommand.AdjustSelectionToNextBlock,
    isCustomCommandRelevant: (editorState) =>
      isFocusInCustomBlockSleeve(editorState) || isFocusAtTheEndOfTableContent(editorState),
  },

  'CTRL+K': {
    command: RichTextInputCommand.InsertLink,
    isCustomCommandRelevant: always,
  },
  'CTRL+SHIFT+Space': {
    command: RichTextInputCommand.InsertNonBreakingSpace,
    isCustomCommandRelevant: always,
  },
  'CTRL+SHIFT+Period': {
    command: RichTextInputCommand.Superscript,
    isCustomCommandRelevant: always,
  },
  'CTRL+SHIFT+Comma': {
    command: RichTextInputCommand.Subscript,
    isCustomCommandRelevant: always,
  },

  'CTRL+SHIFT+M': {
    command: RichTextInputCommand.Code,
    isCustomCommandRelevant: always,
  },

  'CTRL+ALT+Numpad0': {
    command: RichTextInputCommand.Unstyled,
    isCustomCommandRelevant: always,
  },
  'CTRL+ALT+Digit0': {
    command: RichTextInputCommand.Unstyled,
    isCustomCommandRelevant: always,
  },

  'CTRL+ALT+Numpad1': {
    command: RichTextInputCommand.HeadingOne,
    isCustomCommandRelevant: always,
  },
  'CTRL+ALT+Digit1': {
    command: RichTextInputCommand.HeadingOne,
    isCustomCommandRelevant: always,
  },

  'CTRL+ALT+Numpad2': {
    command: RichTextInputCommand.HeadingTwo,
    isCustomCommandRelevant: always,
  },
  'CTRL+ALT+Digit2': {
    command: RichTextInputCommand.HeadingTwo,
    isCustomCommandRelevant: always,
  },

  'CTRL+ALT+Numpad3': {
    command: RichTextInputCommand.HeadingThree,
    isCustomCommandRelevant: always,
  },
  'CTRL+ALT+Digit3': {
    command: RichTextInputCommand.HeadingThree,
    isCustomCommandRelevant: always,
  },

  'CTRL+ALT+Numpad4': {
    command: RichTextInputCommand.HeadingFour,
    isCustomCommandRelevant: always,
  },
  'CTRL+ALT+Digit4': {
    command: RichTextInputCommand.HeadingFour,
    isCustomCommandRelevant: always,
  },

  'CTRL+ALT+Numpad5': {
    command: RichTextInputCommand.HeadingFive,
    isCustomCommandRelevant: always,
  },
  'CTRL+ALT+Digit5': {
    command: RichTextInputCommand.HeadingFive,
    isCustomCommandRelevant: always,
  },

  'CTRL+ALT+Numpad6': {
    command: RichTextInputCommand.HeadingSix,
    isCustomCommandRelevant: always,
  },
  'CTRL+ALT+Digit6': {
    command: RichTextInputCommand.HeadingSix,
    isCustomCommandRelevant: always,
  },

  'CTRL+ALT+A': {
    command: RichTextInputCommand.InsertAsset,
    isCustomCommandRelevant: always,
  },
  'CTRL+ALT+C': {
    command: RichTextInputCommand.InsertComponent,
    isCustomCommandRelevant: always,
  },
  'CTRL+ALT+H': {
    command: RichTextInputCommand.CycleHeading,
    isCustomCommandRelevant: always,
  },
  'CTRL+ALT+I': {
    command: RichTextInputCommand.InsertItem,
    isCustomCommandRelevant: always,
  },
  'CTRL+ALT+O': {
    command: RichTextInputCommand.OrderedList,
    isCustomCommandRelevant: always,
  },
  'CTRL+ALT+T': {
    command: RichTextInputCommand.InsertTable,
    isCustomCommandRelevant: always,
  },
  'CTRL+ALT+U': {
    command: RichTextInputCommand.UnorderedList,
    isCustomCommandRelevant: always,
  },
};

export function getKeyCommand<T>(
  event: React.KeyboardEvent,
  keyCommandMap: KeyCommandMap<T>,
): CommandInfo<T> | null {
  const isShiftPressed = event.shiftKey;
  const isAltPressed = event.altKey;
  const isCtrlOrCommandPressed = event.ctrlKey || event.metaKey;

  const modifiers = [
    isCtrlOrCommandPressed ? 'CTRL' : '',
    isAltPressed ? 'ALT' : '',
    isShiftPressed ? 'SHIFT' : '',
  ].filter((x) => !!x);

  const key = event.key.toUpperCase();
  const keyShortcut = [...modifiers, key].join('+');

  const keyCommand = keyCommandMap[keyShortcut];
  if (keyCommand) {
    return keyCommand;
  }

  const keyCode = event.nativeEvent.code;
  const keyCodeShortcut = [...modifiers, keyCode].join('+');

  const keyCodeCommand = keyCommandMap[keyCodeShortcut];
  if (keyCodeCommand) {
    return keyCodeCommand;
  }

  if (/^Key[A-Z]$/.test(keyCode)) {
    const letter = keyCode.substring(3);
    const keyCodeLetterShortcut = [...modifiers, letter].join('+');

    const keyCodeLetterCommand = keyCommandMap[keyCodeLetterShortcut];
    if (keyCodeLetterCommand) {
      return keyCodeLetterCommand;
    }
  }

  return null;
}

export function getCustomTextInputKeyboardCommand(
  event: React.KeyboardEvent,
  editorState: EditorState,
): TextInputCommand | null {
  const commandCandidate = getKeyCommand(event, textKeyCommandMap);
  if (commandCandidate?.isCustomCommandRelevant(editorState)) {
    return commandCandidate?.command;
  }
  return null;
}

export function getCustomRichTextKeyboardCommand(
  event: React.KeyboardEvent,
  editorState: EditorState,
): RichTextInputCommand | null {
  const commandCandidate = getKeyCommand(event, richTextKeyCommandMap);
  if (commandCandidate?.isCustomCommandRelevant(editorState)) {
    return commandCandidate?.command;
  }
  return null;
}
