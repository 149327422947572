import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { filterContentTypesByIds } from '../utils/filterContentTypesByIds.ts';

export const getAllowedContentTypes = (
  state: IStore,
  availableContentTypes: ReadonlyArray<IContentType>,
): ReadonlyArray<IContentType> => {
  const { allowedContentTypeIds } = state.contentApp.newContentItem.formConfig.contentType;

  return filterContentTypesByIds(availableContentTypes, allowedContentTypeIds);
};
