import { CustomCompiledTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { TypeElementConfigurability } from '../../../../contentModels/shared/models/elements/types/elementConfigurabilityOptions.ts';
import { ElementType } from '../ContentItemElementType.ts';
import {
  EmptyBaseTypeElement,
  IBaseTypeElement,
  getBaseTypeElementFromServerModel,
} from './types/TypeElement.ts';

export interface ICustomTypeElement extends IBaseTypeElement {
  readonly sourceUrl: string;
  readonly config: string | null;
  readonly allowedElements: ReadonlyArray<Uuid>;
  readonly type: ElementType.Custom;
}

export const EmptyCustomTypeElement: ICustomTypeElement = {
  ...EmptyBaseTypeElement,
  _configurability: TypeElementConfigurability.Required,
  allowedElements: [],
  config: null,
  sourceUrl: '',
  type: ElementType.Custom,
};

export const createCustomTypeElementDomainModel = (
  serverModel: CustomCompiledTypeElementServerModel,
): ICustomTypeElement => ({
  ...getBaseTypeElementFromServerModel(serverModel),
  _configurability: EmptyCustomTypeElement._configurability,
  allowedElements: serverModel.allowedElements || EmptyCustomTypeElement.allowedElements,
  config: serverModel.config,
  sourceUrl: serverModel.sourceUrl || EmptyCustomTypeElement.sourceUrl,
  type: ElementType.Custom,
});
