import { Box } from '@kontent-ai/component-library/Box';
import { Input, InputState } from '@kontent-ai/component-library/Input';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { isValid } from 'date-fns';
import React, { FocusEvent, KeyboardEvent } from 'react';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { formatTimeToReadable, parseTime } from './datetimeUtils.ts';

interface ITimeInputProps {
  readonly defaultTime?: string;
  readonly isValid: boolean;
  readonly onBlur: (event: FocusEvent<HTMLInputElement>) => void;
  readonly onChange: (event: React.ChangeEvent<HTMLElement>) => void;
  readonly onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  readonly value?: string;
}

export const TimeInput: React.FC<ITimeInputProps> = (props) => {
  const selectAllText = (event: FocusEvent<HTMLInputElement>): void => {
    event.currentTarget.select();
  };
  const { defaultTime = '' } = props;
  const parsedTime = parseTime(defaultTime);
  const formattedDefaultTime = formatTimeToReadable(
    parsedTime && isValid(parsedTime) ? parsedTime : new Date(),
  );

  return (
    <div className="datetime-picker__time">
      <Box marginRight={Spacing.XL}>
        <Input
          autoComplete="off"
          onBlur={props.onBlur}
          onChange={props.onChange}
          onFocus={selectAllText}
          onKeyDown={props.onKeyDown}
          placeholder={formattedDefaultTime || '12:00 am'}
          value={props.value || ''}
          inputState={props.isValid ? InputState.Default : InputState.Alert}
          {...getDataUiInputAttribute(DataUiInput.Time)}
        />
      </Box>
    </div>
  );
};

TimeInput.displayName = 'TimeInput';
