import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { IContentItemRepository } from '../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { TimelineItem } from '../../../../models/revisions/TimeLineItem.ts';
import { fetchTimelineUntilAllRestoredFromReferencesResolved } from '../../utils/fetchTimelineUntilReferencesResolved.ts';
import {
  itemVariantTimelineLoadInitialFinished,
  itemVariantTimelineLoadInitialStarted,
} from '../timelineActions.ts';

interface IDeps {
  readonly contentItemRepository: IContentItemRepository;
}

export const loadItemVariantTimelineActionCreator =
  ({ contentItemRepository }: IDeps) =>
  (
    contentItemId: Uuid,
    variantId: Uuid,
    abortSignal?: AbortSignal,
  ): ThunkPromise<ReadonlyArray<TimelineItem>> =>
  async (dispatch) => {
    dispatch(itemVariantTimelineLoadInitialStarted());

    const { timeline, continuationToken } =
      await fetchTimelineUntilAllRestoredFromReferencesResolved(
        contentItemRepository,
        contentItemId,
        variantId,
        null,
        abortSignal,
      );

    dispatch(itemVariantTimelineLoadInitialFinished(timeline, continuationToken));

    return timeline;
  };
