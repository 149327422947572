import React from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useIsUnifiedWebSpotlightEnabled } from '../../../../../../_shared/hooks/useIsUnifiedWebSpotlightEnabled.tsx';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import {
  isArchivedWorkflowStepSelected,
  isPublishingStepSelected,
} from '../../../../../../_shared/utils/contentItemUtils.ts';
import { shallowEqual } from '../../../../../../_shared/utils/shallowEqual.ts';
import {
  activateContentItemEditingAction,
  deactivateContentItemEditingAction,
} from '../../actions/contentItemEditingActions.ts';
import { EditingActions as EditingActionsComponent } from '../../components/editingActions/EditingActions.tsx';
import { EditingAction } from '../../models/EditingAction.ts';
import { getAvailableContentItemEditingActions } from '../../utils/editingActions/getAvailableContentItemEditingActions.ts';

const removePreviewActionFilter = (action: EditingAction) =>
  action !== EditingAction.previewItem &&
  action !== EditingAction.previewItemInMultipleSpaces &&
  action !== EditingAction.demonstrateDisabledPreviewItem;

export const EditingActions: React.FC = () => {
  const isUnifiedWebSpotlightEnabled = useIsUnifiedWebSpotlightEnabled();
  const location = useLocation();
  const dispatch = useDispatch();
  const activatedAction = useSelector((s) => s.contentApp.editorUi.editingActions.activatedAction);
  const availableActions = useSelector((s) => {
    const actions = getAvailableContentItemEditingActions(s, location.pathname);
    if (!isUnifiedWebSpotlightEnabled) {
      return actions;
    }

    return actions.filter(removePreviewActionFilter);
  }, shallowEqual);
  const isInPublishingOrArchivingStep = useSelector((s) => {
    const editedContentItemVariant = s.contentApp.editedContentItemVariant;
    return (
      !!editedContentItemVariant &&
      (isPublishingStepSelected(editedContentItemVariant.assignment) ||
        isArchivedWorkflowStepSelected(editedContentItemVariant.assignment))
    );
  });

  return (
    <EditingActionsComponent
      activatedAction={activatedAction}
      availableActions={availableActions}
      isInPublishingOrArchivingStep={isInPublishingOrArchivingStep}
      onDeactivateAction={() => dispatch(deactivateContentItemEditingAction())}
      onShowActionsMenu={() =>
        dispatch(activateContentItemEditingAction(EditingAction.showMainMenu))
      }
    />
  );
};
