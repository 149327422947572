import classNames from 'classnames';
import React from 'react';
import { IAsset } from '../../../data/models/assets/Asset.ts';
import { shouldAssetFileNameBeRendered } from '../../utils/assets/assetUtils.ts';
import { SearchPhraseHighlighter } from '../Highlighting/SearchPhraseHighlighter.tsx';
import { IgnoreByGrammarly } from '../IgnoreByGrammarly.tsx';
import { AssetFileName } from './AssetFileName.tsx';

type Props = {
  readonly asset: IAsset;
  readonly isAssetFileTypeValid: boolean;
  readonly id?: HTMLDivElement['id'];
  readonly searchPhrase?: string;
};

export const nameWithHighlight = (searchPhrase: string | undefined, name: string) =>
  searchPhrase ? <SearchPhraseHighlighter searchPhrase={searchPhrase} text={name} /> : name;

export const AssetTitle: React.FC<Props> = ({
  asset,
  searchPhrase,
  isAssetFileTypeValid,
  id,
}: Props): JSX.Element | null => {
  return (
    <div className="asset-thumbnail__title" id={id}>
      {asset.title && (
        <div className={classNames('asset-thumbnail__name')}>
          <IgnoreByGrammarly text={asset.title}>
            {nameWithHighlight(searchPhrase, asset.title)}
          </IgnoreByGrammarly>
        </div>
      )}
      {shouldAssetFileNameBeRendered(searchPhrase, asset, isAssetFileTypeValid) && (
        <div
          className={classNames('asset-thumbnail__name', {
            'asset-thumbnail__name--is-invalid': !isAssetFileTypeValid,
            'asset-thumbnail__name--is-not-first': !!asset.title,
          })}
        >
          <AssetFileName
            searchPhrase={searchPhrase}
            name={asset.filename}
            invalidClassName={
              isAssetFileTypeValid ? '' : 'asset-thumbnail__file-extension--is-invalid'
            }
          />
        </div>
      )}
      {asset.archived && (
        <span className="asset-thumbnail__deleted">
          <IgnoreByGrammarly text="(Deleted)" />
        </span>
      )}
    </div>
  );
};
