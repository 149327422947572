import { Button } from '@kontent-ai/component-library/Button';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing, colorTextHint, px } from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';
import { BrowseButton } from '../../../../../../../_shared/components/BrowseButton.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { FileWithThumbnail } from '../../../../../../contentInventory/assets/models/FileWithThumbnail.type.ts';

export interface IAssetPickerDataProps {
  readonly ariaDescription?: string;
  readonly children: string;
  readonly pickerDisabledTooltipMessage?: string;
}

export interface IAssetPickerCallbackProps {
  readonly onPick: () => void;
  readonly onUpload: ((files: ReadonlyArray<FileWithThumbnail>) => void) | null;
}

type IAssetPickerProps = IAssetPickerDataProps & IAssetPickerCallbackProps;

const AssetPickerButton: React.FC<IAssetPickerProps> = (props) => (
  <Button
    buttonDisplay="inline"
    buttonStyle="tertiary"
    disabled={!!props.pickerDisabledTooltipMessage}
    onClick={props.onPick}
    size="small"
    tooltipText={props.pickerDisabledTooltipMessage}
    aria-description={props.ariaDescription}
    {...getDataUiActionAttribute(DataUiAction.PickAsset)}
  >
    {props.children}
  </Button>
);

const ButtonLabel = styled(Label).attrs({
  size: LabelSize.L,
  color: colorTextHint,
})`
  margin-left: ${px(Spacing.S)};
`;

export const AssetPicker: React.FC<IAssetPickerProps> = (props) => (
  <Row spacing={Spacing.S}>
    <Column width="fit-content">
      <AssetPickerButton {...props} />
    </Column>
    <Column width="fit-content">
      {props.onUpload && (
        <Inline align="center">
          <BrowseButton
            buttonSize="small"
            buttonStyle="secondary"
            buttonText="Browse"
            onUpload={props.onUpload}
            screenReaderText="Select an asset from your computer."
            supportClick
          />
          <ButtonLabel>or drop assets here to upload.</ButtonLabel>
        </Inline>
      )}
    </Column>
  </Row>
);

AssetPicker.displayName = 'AssetPicker';
