import { Collection } from '@kontent-ai/utils';
import { useMemo } from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { DataUiCollection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IListingFilter } from '../../../../models/filter/IListingFilter.ts';
import { ExtendedWorkflowStepFilterSelector } from './ExtendedWorkflowStepFilterSelector.tsx';
import { WorkflowStepFilterSelector } from './WorkflowStepFilterSelector.tsx';

type Props = {
  readonly filter: IListingFilter;
  readonly isAiSearchUsed: boolean;
  readonly onWorkflowsSelectionChanged: (
    workflowToSelectedStepIds: ReadonlyMap<Uuid, ReadonlySet<Uuid>>,
    workflowToAllStepIds: ReadonlyMap<Uuid, ReadonlyArray<Uuid>>,
  ) => void;
};

export const WorkflowFilterSelector = (props: Props) => {
  const workflowsById = useSelector((state) => state.data.workflows.byId);
  const workflows = useMemo(() => Collection.getValues(workflowsById), [workflowsById]);

  if (workflows.length === 1) {
    return (
      <WorkflowStepFilterSelector
        collection={DataUiCollection.WorkflowSteps}
        filter={props.filter}
        isAiSearchUsed={props.isAiSearchUsed}
        onWorkflowsStepSelectionChanged={props.onWorkflowsSelectionChanged}
        placeholder="Select a workflow step"
        title="Workflow step"
      />
    );
  }

  if (workflows.length > 1) {
    return (
      <ExtendedWorkflowStepFilterSelector
        collection={DataUiCollection.WorkflowSteps}
        filter={props.filter}
        isAiSearchUsed={props.isAiSearchUsed}
        onWorkflowsStepSelectionChanged={props.onWorkflowsSelectionChanged}
        placeholder="Select a workflow step"
        title="Workflow step"
      />
    );
  }

  return null;
};
