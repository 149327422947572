import { assert } from '@kontent-ai/utils';
import { UntitledTaxonomyGroup } from '../../../../_shared/constants/defaultNames.ts';
import { isEmptyOrWhitespace } from '../../../../_shared/utils/stringUtils.ts';
import { ITaxonomyGroupServerModel } from '../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import {
  ITaxonomyTerm,
  createTaxonomyTermDomainModel,
  createTaxonomyTermServerModel,
} from './TaxonomyTerm.ts';

export interface ITaxonomyGroup {
  readonly id: Uuid;
  readonly name: string;
  readonly codename: string;
  readonly childIds: ReadonlyArray<Uuid>;
  readonly terms: ReadonlyMap<Uuid, ITaxonomyTerm>;
  readonly lastModified: DateTimeStamp;
  readonly lastModifiedBy: UserId;
  readonly isArchived: boolean;
  readonly etag: string | null;
}

export const emptyTaxonomyGroup: ITaxonomyGroup = {
  id: '',
  name: '',
  codename: '',
  childIds: [],
  terms: new Map(),
  lastModified: '',
  lastModifiedBy: '',
  isArchived: false,
  etag: null,
};

export function createTaxonomyGroupDomainModel(
  serverModel: ITaxonomyGroupServerModel | null,
): ITaxonomyGroup {
  assert(!!serverModel, () => 'Taxonomy group server model is falsy');

  let flatChildrenNodes: Map<Uuid, ITaxonomyTerm> = new Map();
  const saveNodes = (nodesToSave: ReadonlyArray<ITaxonomyTerm>) => {
    nodesToSave.forEach((node: ITaxonomyTerm) => {
      flatChildrenNodes = flatChildrenNodes.set(node.id, node);
    });
  };

  const serverRootChildren = serverModel.nodes || [];
  const rootNodes = serverRootChildren.map((child) =>
    createTaxonomyTermDomainModel(child, saveNodes),
  );
  saveNodes(rootNodes);

  return {
    id: serverModel.id ?? emptyTaxonomyGroup.id,
    name:
      !serverModel.name || isEmptyOrWhitespace(serverModel.name)
        ? UntitledTaxonomyGroup
        : serverModel.name,
    codename: serverModel.codeName ?? emptyTaxonomyGroup.codename,
    childIds: serverRootChildren.map((child) => child.id),
    terms: flatChildrenNodes,
    lastModified: serverModel.lastModified ?? emptyTaxonomyGroup.lastModified,
    lastModifiedBy: serverModel.lastModifiedBy ?? emptyTaxonomyGroup.lastModifiedBy,
    isArchived: serverModel.archived ?? emptyTaxonomyGroup.isArchived,
    etag: serverModel.etag ?? emptyTaxonomyGroup.etag,
  };
}

export function createTaxonomyGroupServerModel(
  taxonomyGroup: ITaxonomyGroup,
): ITaxonomyGroupServerModel {
  const { id, name, codename, isArchived, childIds, terms, lastModified, lastModifiedBy, etag } =
    taxonomyGroup;

  const nodes = childIds.map((childId) => createTaxonomyTermServerModel(childId, terms));
  return {
    id,
    name: name ? name : UntitledTaxonomyGroup,
    archived: isArchived,
    codeName: codename,
    lastModified,
    lastModifiedBy,
    nodes,
    etag,
  };
}
