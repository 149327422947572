import { InvariantException } from '@kontent-ai/errors';
import { RefObject, useRef } from 'react';
import { z } from 'zod';
import { HtmlPageTitle } from '../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../_shared/constants/applicationNames.ts';
import { useEventListener } from '../../../_shared/hooks/useEventListener.ts';
import { matchesSchema } from '../../../_shared/utils/matchesSchema.ts';
import { getUrlOrigin } from '../../../_shared/utils/urlUtils.ts';
import { CustomApp } from '../../../data/models/customApps/CustomApp.ts';
import {
  AllClientRequestMessages,
  ClientGetContextV1Request,
  ErrorCode,
  ErrorMessage,
} from '../types/iframeSchema.ts';
import { AllClientResponses } from '../utils/typeUtils.type.ts';
import { CustomAppSandboxIframe } from './CustomAppSandboxIframe.tsx';

type Response<TPayload> =
  | {
      readonly error: {
        readonly code: ErrorCode;
        readonly description: string;
      };
      readonly payload?: never;
    }
  | {
      readonly error?: never;
      readonly payload: TPayload;
    };

type Props = {
  readonly customApp: CustomApp;
  readonly createGetContextV1Response: () => Response<{
    readonly config: unknown;
    readonly context: {
      readonly environmentId: Uuid;
      readonly userId: string;
      readonly userEmail: string;
      readonly userRoles: ReadonlyArray<{
        readonly id: Uuid;
        readonly codename: string | null;
      }>;
    };
  }>;
};

export const CustomAppSandbox = (props: Props) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const onMessage = (event: MessageEvent): void => {
    const { source, origin, data } = event;

    if (!isCustomAppIFrame(iframeRef, source)) {
      return;
    }

    if (!equalsToCustomAppSourceUrlOrigin(props.customApp.sourceUrl, origin)) {
      throw InvariantException(
        `Incoming message origin ${origin} does not correspond with provided custom app’s url.`,
      );
    }

    if (AllClientRequestMessages.safeParse(data).error) {
      sendResponse(
        iframeRef,
        props.customApp.sourceUrl,
        createErrorResponse(
          data.requestId,
          ErrorCode.UnknownMessage,
          'The request message does not match the expected format.',
        ),
      );
    }

    if (matchesSchema(ClientGetContextV1Request, data)) {
      const response = props.createGetContextV1Response();
      const responseMessage = response.error
        ? createErrorResponse(data.requestId, response.error.code, response.error.description)
        : ({
            isError: false,
            version: '1.0.0',
            type: 'get-context-response',
            requestId: data.requestId,
            payload: response.payload,
          } as const);
      sendResponse(iframeRef, props.customApp.sourceUrl, responseMessage);
    }
  };

  useEventListener('message', onMessage, self, true);

  return (
    <>
      <HtmlPageTitle appName={AppNames.CustomApps} customName={props.customApp.name} />
      <CustomAppSandboxIframe
        ref={iframeRef}
        name={props.customApp.name}
        src={props.customApp.sourceUrl}
      />
    </>
  );
};

const getIframeWindow = (iframeRef: RefObject<HTMLIFrameElement>): Window | null => {
  return iframeRef.current?.contentWindow ?? null;
};

const isCustomAppIFrame = (
  iframeRef: RefObject<HTMLIFrameElement>,
  eventSource: MessageEventSource | null,
): boolean => {
  return !!eventSource && eventSource === getIframeWindow(iframeRef);
};

const equalsToCustomAppSourceUrlOrigin = (
  customAppOrigin: string,
  receivedMessageUrlOrigin: string,
): boolean => {
  return getUrlOrigin(customAppOrigin) === receivedMessageUrlOrigin;
};

const sendResponse = (
  iframeRef: RefObject<HTMLIFrameElement>,
  customAppOrigin: string,
  message: AllClientResponses,
): void => {
  const origin = getUrlOrigin(customAppOrigin);
  // Send the message only to origin generated from customApp.sourceUrl to prevent others from sniffing
  getIframeWindow(iframeRef)?.postMessage(message, origin);
};

const createErrorResponse = (
  requestId: Uuid,
  code: ErrorCode,
  description: string,
): z.infer<typeof ErrorMessage> => ({
  isError: true,
  requestId,
  code,
  description,
});
