import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { IStore } from '../../../stores/IStore.type.ts';

export const getFirstWorkflowIdOfItemSelection = (state: IStore): Uuid | undefined => {
  const { selectAll, selectedItemIds, unselectedItemIds } =
    state.contentApp.listingUi.itemsSelection;
  const { allIds, byId } = state.data.listingContentItems;
  const firstRelevantItem: IListingContentItem | undefined = selectAll
    ? // "allIds" contains the identifiers of currently shown items in inventory
      allIds?.filter((id) => !unselectedItemIds.includes(id)).map((id) => byId.get(id))[0]
    : byId.get(selectedItemIds[0] ?? '');

  return firstRelevantItem?.variant?.assignment.workflowStatus.workflowId;
};
