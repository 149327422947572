import { ThunkFunction } from '../../../../../../../@types/Dispatcher.type.ts';
import { isEmptyOrWhitespace } from '../../../../../../../_shared/utils/stringUtils.ts';
import { isAiSearchScope } from '../../../../../../../paperModels/aiSearch/utils/isAiSearchScope.ts';
import { FullTextSearchStatus } from '../../../../../shared/reducers/IContentInventoryStoreState.type.ts';
import { ContentListing_Filter_SearchPhraseChanged } from '../../constants/contentItemInventoryActionTypes.ts';

export const filterSearchPhraseChanged = (searchPhrase: string) =>
  ({
    type: ContentListing_Filter_SearchPhraseChanged,
    payload: {
      searchPhrase,
    },
  }) as const;

export type UpdateFilterSearchPhraseActionsType = ReturnType<typeof filterSearchPhraseChanged>;

interface IDeps {
  readonly handleFullTextSearchTurnedOff: (isInDialog: boolean) => ThunkFunction;
  readonly handleFullTextSearchTurnedOn: (isInDialog: boolean) => ThunkFunction;
}

export const createUpdateFilterSearchPhraseAction =
  (deps: IDeps) =>
  (searchPhrase: string, isInDialog: boolean): ThunkFunction =>
  (dispatch, getState) => {
    const state = getState();
    const {
      contentApp: {
        listingUi: { filter },
      },
      contentInventory: { fullTextSearchStatus },
    } = state;

    dispatch(filterSearchPhraseChanged(searchPhrase));

    if (
      fullTextSearchStatus !== FullTextSearchStatus.Enabled ||
      isAiSearchScope(state.contentApp.listingUi.filter.searchScope)
    ) {
      return;
    }

    if (!filter.searchPhrase && !isEmptyOrWhitespace(searchPhrase)) {
      dispatch(deps.handleFullTextSearchTurnedOn(isInDialog));
    } else if (filter.searchPhrase && !searchPhrase) {
      dispatch(deps.handleFullTextSearchTurnedOff(isInDialog));
    }
  };
