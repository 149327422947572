import { useParams } from 'react-router';
import { useThunkPromise } from '../../../_shared/hooks/useThunkPromise.ts';
import { IItemElement } from '../actions/thunks/editItemVariant.ts';
import { editItemVariantCodenameByPath } from '../actions/thunks/editItemVariantCodenameByPath.ts';
import { SmartLink } from '../components/SmartLink.tsx';
import { EditItemVariantCodenameRouteParams } from '../constants/routePaths.ts';

type Props = {
  readonly path: ReadonlyArray<IItemElement>;
};

export const EditItemVariantSmartLink = (props: Props) => {
  const { projectId, variantCodename } = useParams<EditItemVariantCodenameRouteParams>();

  useThunkPromise(editItemVariantCodenameByPath, projectId, variantCodename, props.path);

  return <SmartLink />;
};
