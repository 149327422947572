import { Button, ButtonStyle } from '@kontent-ai/component-library/Button';
import { IconName, Icons } from '@kontent-ai/component-library/Icons';
import { MouseEventHandler, forwardRef } from 'react';
import {
  DataUiAction,
  getDataAttributeProps,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type EditingActionsButtonProps = {
  readonly buttonStyle: ButtonStyle;
  readonly dataUiAction?: DataUiAction;
  readonly disabled?: boolean;
  readonly disableTabulator?: boolean;
  readonly iconName: IconName;
  readonly isActive?: boolean;
  readonly onClick?: MouseEventHandler<HTMLElement>;
  readonly text: string;
};

export const EditingActionsButton = forwardRef<HTMLButtonElement, EditingActionsButtonProps>(
  (props, forwardedRef) => {
    const { isActive, buttonStyle, iconName, text, ...rest } = props;

    return (
      <Button
        disableTabulator={props.disableTabulator}
        activated={isActive}
        {...rest}
        ref={forwardedRef}
        buttonStyle={buttonStyle ?? 'tertiary'}
        {...getDataAttributeProps(props)}
      >
        <Button.Icon icon={Icons[iconName]} />
        {text}
      </Button>
    );
  },
);

EditingActionsButton.displayName = 'EditingActionsButton';
