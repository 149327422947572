import { Box } from '@kontent-ai/component-library/Box';
import {
  borderWidthDefault,
  colorBorderLowEmphasis,
  px,
} from '@kontent-ai/component-library/tokens';
import { ContentItemPaneElementsGroupCompact } from './ContentItemPaneElementsGroupCompact.tsx';
import { NoElementsElement } from './NoElementsElement.tsx';

type NoElementsEmptyStateCompactProps = Readonly<{
  canEdit: boolean;
}>;

export const NoElementsEmptyStateCompact = ({ canEdit }: NoElementsEmptyStateCompactProps) => {
  const isDisabled = !canEdit;

  return (
    <Box css={`border-bottom: ${px(borderWidthDefault)} solid ${colorBorderLowEmphasis}`}>
      <ContentItemPaneElementsGroupCompact isDisabled={isDisabled} isGuideline>
        <NoElementsElement isDisabled={isDisabled} />
      </ContentItemPaneElementsGroupCompact>
    </Box>
  );
};
