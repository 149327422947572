import { InputCaption, InputState } from '@kontent-ai/component-library/Input';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Placement } from '@kontent-ai/component-library/types';
import { propagateInstanceToParent } from '@kontent-ai/hooks';
import { memoize } from '@kontent-ai/memoization';
import { Collection, delay } from '@kontent-ai/utils';
import classNames from 'classnames';
import React, { MutableRefObject, ReactNode, Ref, RefObject } from 'react';
import { TagColor } from '../../../data/constants/tagColor.ts';
import {
  AnimatedChevron,
  RestDirection,
} from '../../uiComponents/AnimatedChevron/AnimatedChevron.tsx';
import { DropDownPositioner } from '../../uiComponents/DropDown/DropDownPositioner.tsx';
import { IDropdownTippyOptions } from '../../uiComponents/DropDown/dropDownTippyOptions.ts';
import { FormFieldError } from '../../uiComponents/FormAlert/FormFieldError.tsx';
import {
  DataUiAction,
  DataUiCollection,
  DataUiElement,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { doesEntitySatisfyFilterPhrase } from '../../utils/filter/nameFilterUtils.ts';
import { DebouncedFunction, debounce } from '../../utils/func/debounce.ts';
import { HotkeysHandler } from '../Hotkeys/HotkeysHandler.tsx';
import { EditableSpan } from '../input/EditableSpan.tsx';
import { MultipleOptionSelectDropDown } from './MultipleOptionSelectDropDown.tsx';
import {
  IMultipleOptionSelectOptionProps,
  MultipleOptionSelectOption,
} from './MultipleOptionSelectOption.tsx';
import {
  IMultipleOptionSelectPermanentOption,
  MultipleOptionSelectPermanentOption,
} from './MultipleOptionSelectPermanentOption.tsx';
import { MultipleOptionSelectValidationMessageBehavior } from './MultipleOptionSelectValidationMessageBehavior.ts';
import {
  IMultipleOptionSelectDropDownOptionProps,
  MultipleOptionSelectDropDownOption,
  OptionExpansionState,
  OptionType,
} from './MultipleSelectDropDownOption.tsx';

export enum ChangeType {
  Add = 'Add',
  Remove = 'Remove',
}

export type MultipleOptionSelectProps<T> = {
  readonly autofocus?: boolean;
  readonly caption?: string;
  readonly collectionName: DataUiCollection;
  readonly customClassName?: string;
  readonly customSearchTextClassName?: string;
  readonly delayAutofocus?: number;
  readonly dropdownId?: string;
  readonly getDisabledOptionColor?: (option: T) => TagColor;
  readonly getOptionColor?: (option: T) => TagColor;
  readonly getOptionExpansionState?: (option: T) => OptionExpansionState;
  readonly getOptionId: (option: T) => string;
  readonly getOptionIndent?: (option: T) => number;
  readonly getOptionName: (option: T) => string;
  readonly getOptionTooltip?: (option: T) => string | undefined;
  readonly getOptionType?: (option: T) => OptionType;
  readonly getSelectedOptionName?: (option: T) => string;
  readonly getSelectedOptionTooltip?: (option: T) => string | undefined;
  readonly hasError?: boolean;
  readonly inputName?: DataUiInput;
  readonly isDisabled?: boolean;
  readonly isOptionDisabled?: (option: T) => boolean;
  readonly multipleSelectRef?: Ref<HTMLElement>;
  readonly normalize?: (
    formValues: ReadonlyArray<T>,
    previousValues: ReadonlyArray<T>,
  ) => ReadonlyArray<T>;
  readonly onChange: (
    newSelectedOptions: ReadonlyArray<T>,
    changeType: ChangeType,
    changedOption: T,
  ) => void;
  readonly onFilter?: (options: ReadonlyArray<T>, searchText: string) => ReadonlyArray<T>;
  readonly onOptionCollapse?: (option: T) => T | null; // returns new option to be highlighted
  readonly onOptionExpand?: (option: T) => void;
  readonly onSearchFieldBlur?: () => void;
  readonly onSearchTextChange?: (newText: string) => void;
  readonly onSelectionFinished?: () => void;
  readonly options: ReadonlyArray<T>;
  readonly permanentOptionComponent: React.ComponentType<IMultipleOptionSelectPermanentOption<T>>;
  readonly permanentOptions?: ReadonlyArray<T>;
  readonly placeholder?: string;
  readonly renderDropdownOption: (props: IMultipleOptionSelectDropDownOptionProps<T>) => ReactNode;
  readonly searchText?: string;
  readonly selectedOptionComponent: React.ComponentType<IMultipleOptionSelectOptionProps<T>>;
  readonly selectedOptions: ReadonlyArray<T>;
  readonly selectedOptionFloatingElementPlacement?: Placement;
  readonly shouldRenderSelectedOption?: (option: T) => boolean;
  readonly shouldSearchedOptionBeHighlighted?: (searchPhrase: string, option: T) => boolean;
  readonly showOptionWithWorkflowStepColorIndicator?: boolean;
  readonly singleSelectionOnly?: boolean;
  readonly tooltipText?: string;
  readonly tooltipPlacement?: Placement;
  readonly validationMessage?: ReactNode;
  readonly validationMessageBehavior: MultipleOptionSelectValidationMessageBehavior;
};

interface IMultipleOptionSelectState<T> {
  readonly hasBeenTouched: boolean;
  readonly highlightedOption?: T;
  readonly searchText: string;
  readonly showDropDown: boolean;
}

enum Direction {
  Up = 'up',
  Down = 'down',
}

const getDefaultOptionColor = () => TagColor.Product;
const getDefaultDisabledOptionColor = () => TagColor.LightGray;

const shouldDisplayValidation = (
  behavior: MultipleOptionSelectValidationMessageBehavior,
  hasBeenTouched: boolean,
  message?: ReactNode,
): boolean => {
  switch (behavior) {
    case MultipleOptionSelectValidationMessageBehavior.ShowWheneverDefined:
      return !!message;

    case MultipleOptionSelectValidationMessageBehavior.ShowWhenTouched:
      return hasBeenTouched && !!message;
  }
};

const wrapAndMemoizeFunction = memoize.maxOne((fnc: () => void) => () => fnc());
const tippyOptions: IDropdownTippyOptions = {
  placement: 'bottom-start',
  popperOptions: {
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom-end', 'top-start', 'top-end', 'right-end', 'left-end'],
        },
      },
    ],
  },
};

export type DefaultMultipleOptionSelectProps =
  | 'validationMessageBehavior'
  | 'renderDropdownOption'
  | 'selectedOptionComponent'
  | 'permanentOptionComponent';

export class MultipleOptionSelect<T> extends React.PureComponent<
  MultipleOptionSelectProps<T>,
  IMultipleOptionSelectState<T>
> {
  static displayName = 'MultipleOptionSelect';

  static defaultProps: Required<
    Pick<MultipleOptionSelectProps<unknown>, DefaultMultipleOptionSelectProps | 'permanentOptions'>
  > = {
    renderDropdownOption: (props) => (
      <MultipleOptionSelectDropDownOption key={props.getOptionId(props.option)} {...props} />
    ),
    permanentOptionComponent: MultipleOptionSelectPermanentOption,
    permanentOptions: [],
    selectedOptionComponent: MultipleOptionSelectOption,
    validationMessageBehavior: MultipleOptionSelectValidationMessageBehavior.ShowWhenTouched,
  };

  private searchInput: EditableSpan | null = null;
  private _multipleSelectRef = React.createRef<HTMLElement>();
  private readonly _dropDownRef = React.createRef<HTMLDivElement>() as MutableRefObject<any>;

  private readonly _debouncedHideDropDown: DebouncedFunction;

  constructor(props: MultipleOptionSelectProps<T>) {
    super(props);

    // Hiding dropdown upon lost focus is debounced, in order to prevent flicker when selecting item in dropdown.
    this._debouncedHideDropDown = debounce(this._hideDropDown, 100);

    this.state = {
      hasBeenTouched: false,
      searchText: props.searchText ?? '',
      showDropDown: false,
    };
  }

  static getDerivedStateFromProps(
    props: MultipleOptionSelectProps<unknown>,
    state: IMultipleOptionSelectState<unknown>,
  ) {
    if (props.searchText !== undefined && props.searchText !== state.searchText) {
      return {
        ...state,
        searchText: props.searchText,
      };
    }

    return null;
  }

  componentDidMount(): void {
    if (this.props.autofocus) {
      const delayTime = this.props.delayAutofocus ?? 0;
      delay(delayTime).then(() => this._focusSearch());
    }
  }

  componentWillUnmount() {
    this._debouncedHideDropDown.cancel();
  }

  componentDidUpdate(
    prevProps: MultipleOptionSelectProps<T>,
    prevState: IMultipleOptionSelectState<T>,
  ) {
    if (prevState.searchText !== this.state.searchText) {
      this._handleDropdownAfterTextChange();
    }

    if (!prevProps.autofocus && this.props.autofocus) {
      this._focusSearch();
    }

    if (
      this.props.singleSelectionOnly &&
      prevProps.selectedOptions !== this.props.selectedOptions
    ) {
      this._handleDropdownForSingleSelection();
    }
  }

  private readonly _handleDropdownAfterTextChange = () => {
    const options = this._getOptionsForDropDown();

    this._ensureHighlightedOption();

    if (!options.length) {
      this._hideDropDownAndResetHighlightedOption();
    }
  };

  private readonly _handleDropdownForSingleSelection = () => {
    const wasSingleOptionSelected = !!this.props.selectedOptions.length;
    if (wasSingleOptionSelected) {
      this._hideDropDownAndResetHighlightedOption();
    } else {
      this._focusSearch();
    }
  };

  private readonly _handleClickOutside = (): void => {
    if (this.state.showDropDown) {
      this._hideDropDownAndResetHighlightedOption();
    }
  };

  private readonly _focusSearch = (): void => {
    if (this.searchInput) {
      this._debouncedHideDropDown.cancel();
      this.searchInput.focus();
    }
  };

  private readonly _showDropDown = (optionToBeHighlighted?: T): void => {
    const highlightCorrectOption = optionToBeHighlighted
      ? () => this._highlightOption(optionToBeHighlighted)
      : this._ensureHighlightedOption;

    this.setState(() => ({ showDropDown: true }), highlightCorrectOption);
  };

  private readonly _hideDropDownAndResetHighlightedOption = (): void => {
    this._hideDropDown();

    this.setState(() => ({
      highlightedOption: undefined, // forces to recalculate scroll position once DD is open again
    }));
  };

  private readonly toggleDropdown = (): void => {
    if (this.state.showDropDown) {
      this._hideDropDownAndResetHighlightedOption();
    } else {
      this._showDropDown();
    }
  };

  private readonly _hideDropDown = (): void => {
    this.setState(() => ({
      showDropDown: false,
      hasBeenTouched: true,
    }));
    this.props.onSelectionFinished?.();
  };

  private readonly _isOptionSelected = (option: T): boolean => {
    const { getOptionId, selectedOptions } = this.props;

    return selectedOptions.map(getOptionId).includes(getOptionId(option));
  };

  private readonly _onChange = (
    newOptions: ReadonlyArray<T>,
    changeType: ChangeType,
    option: T,
  ) => {
    if (!this.props.normalize) {
      this.props.onChange(newOptions, changeType, option);

      return;
    }

    const previousOptions =
      changeType === ChangeType.Add
        ? newOptions.filter((opt) => this.props.getOptionId(opt) !== this.props.getOptionId(option))
        : [...newOptions, option];

    const normalizedOptions = this.props.normalize(newOptions, previousOptions);

    this.props.onChange(normalizedOptions, changeType, option);
  };

  private readonly _addOption = (option: T): void => {
    if (!this.props.isOptionDisabled || !this.props.isOptionDisabled(option)) {
      this._onChange([...this.props.selectedOptions, option], ChangeType.Add, option);
      this._optionExpand(option);
      this._updateSearchText('');
    }

    this._focusSearch();
  };

  private readonly _updateSearchText = (newSearchText: string): void => {
    this.props.onSearchTextChange?.(newSearchText);
    this.setState(() => ({ searchText: newSearchText }), this._ensureHighlightedOption);
  };

  private readonly _isOptionAmongDropdownOptions = (option: T): boolean => {
    const { getOptionId } = this.props;

    return this._getOptionsForDropDown().map(getOptionId).includes(getOptionId(option));
  };

  private readonly _ensureHighlightedOption = (): void => {
    const { highlightedOption } = this.state;

    if (!highlightedOption || !this._isOptionAmongDropdownOptions(highlightedOption)) {
      this._highlightFirstOption();
    }
  };

  private readonly _isOptionCategory = (option: T): boolean =>
    !!this.props.getOptionType && this.props.getOptionType(option) === OptionType.Category;

  private readonly _highlightOption = (option: T | null): void => {
    const shouldHighlight =
      option && !this._isOptionCategory(option) && this._isOptionAmongDropdownOptions(option);

    this.setState(() => ({
      highlightedOption: shouldHighlight ? option : undefined,
    }));
  };

  private readonly _getOptionsForDropDown = (): ReadonlyArray<T> => {
    const { getOptionName, onFilter, options } = this.props;

    const { searchText } = this.state;

    if (onFilter) {
      return onFilter(options, searchText);
    }

    const phrase = searchText.trim();

    return options.filter((option) =>
      doesEntitySatisfyFilterPhrase(phrase, option, [getOptionName]),
    );
  };

  private readonly _removeByReselect = (option: T): void => {
    this._removeOption(option);
    this._focusSearch();
  };

  private readonly _removeOption = (option: T): void => {
    const newListOfOptions = this.props.selectedOptions.filter(
      (opt) => this.props.getOptionId(opt) !== this.props.getOptionId(option),
    );

    this.setState(() => ({ hasBeenTouched: true }));
    this._onChange(newListOfOptions, ChangeType.Remove, option);
  };

  private readonly _onOptionClick = (option: T): void => {
    this._focusSearch();
    this._showDropDown(option);
    this._optionExpand(option);
  };

  private readonly _highlightFirstOption = (): void => {
    const dropdownOptions = this._getOptionsForDropDown();

    const newHighlightedOption =
      dropdownOptions.find((opt) => !this._isOptionCategory(opt)) ?? null;

    this._highlightOption(newHighlightedOption);
  };

  private readonly _getNextHighlightedOption = (
    startIndex: number,
    options: ReadonlyArray<T>,
    index: number,
    direction: Direction,
  ): T | undefined => {
    const length = options.length;
    const increment = direction === Direction.Down ? 1 : -1;
    const newIndex = (index + increment + length) % length;
    const newHighlightedOption = options[newIndex];

    // Skip categories
    if (
      newHighlightedOption &&
      this._isOptionCategory(newHighlightedOption) &&
      newIndex !== startIndex
    ) {
      return this._getNextHighlightedOption(startIndex, options, newIndex, direction);
    }

    return newHighlightedOption;
  };

  private readonly _highlightNextOption = (direction: Direction): void => {
    const { highlightedOption } = this.state;
    const dropdownOptions = this._getOptionsForDropDown();

    const index = highlightedOption
      ? dropdownOptions.findIndex(
          (o) => this.props.getOptionId(o) === this.props.getOptionId(highlightedOption),
        )
      : -1;
    const newHighlightedOption =
      this._getNextHighlightedOption(index, dropdownOptions, index, direction) ?? null;

    this._highlightOption(newHighlightedOption);
  };

  private readonly _keyDown = (event: React.KeyboardEvent<HTMLSpanElement>): void => {
    const keysNotShowingDropdown = ['Escape', 'Tab', 'Backspace', 'Enter'];
    const shouldShowDropDown =
      !keysNotShowingDropdown.includes(event.key) && !this.state.showDropDown;

    if (shouldShowDropDown) {
      this._showDropDown();
    }
  };

  private readonly _optionCollapse = (option: T): void => {
    if (!this.props.onOptionCollapse) {
      return;
    }

    this._focusSearch();

    const nextHighlightedOption = this.props.onOptionCollapse(option);

    this._highlightOption(nextHighlightedOption);
  };

  private readonly _optionExpand = (option: T): void => {
    if (!this.props.onOptionExpand) {
      return;
    }

    this._focusSearch();
    this.props.onOptionExpand(option);
  };

  private readonly _onEnterPressed = (event: KeyboardEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    if (!this.state.highlightedOption || !this.state.showDropDown) {
      return;
    }

    if (this._isOptionSelected(this.state.highlightedOption)) {
      this._removeByReselect(this.state.highlightedOption);
    } else {
      this._addOption(this.state.highlightedOption);
    }
  };

  private readonly _onEscapePressed = (event: KeyboardEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    if (this.state.showDropDown) {
      this._hideDropDownAndResetHighlightedOption();
    }
    this.props.onSelectionFinished?.();
  };

  private readonly _onArrowUpPressed = (event: KeyboardEvent): void => {
    event.preventDefault();
    this._highlightNextOption(Direction.Up);
  };

  private readonly _onArrowDownPressed = (event: KeyboardEvent): void => {
    event.preventDefault();
    this._highlightNextOption(Direction.Down);
  };

  private readonly _onArrowRightPressed = (event: KeyboardEvent): void => {
    const { highlightedOption } = this.state;

    if (
      highlightedOption &&
      this.props.getOptionExpansionState?.(highlightedOption) === OptionExpansionState.Collapsed
    ) {
      event.preventDefault();
      this._optionExpand(highlightedOption);
    }
  };

  private readonly _onArrowLeftPressed = (event: KeyboardEvent): void => {
    const { highlightedOption } = this.state;

    if (
      highlightedOption &&
      this.props.getOptionExpansionState?.(highlightedOption) === OptionExpansionState.Expanded
    ) {
      event.preventDefault();
      this._optionCollapse(highlightedOption);
    }
  };

  private readonly _onBackspacePressed = (): void => {
    const lastSelectedOption = Collection.getLast(this.props.selectedOptions);
    if (this.state.searchText === '' && lastSelectedOption) {
      this._removeOption(lastSelectedOption);
    }
  };

  private readonly _shouldRenderPlaceholderOption = (): boolean => {
    const { selectedOptions, placeholder } = this.props;

    return (
      !selectedOptions.length &&
      typeof placeholder !== 'string' &&
      typeof placeholder !== 'undefined'
    );
  };

  private readonly _renderToggleArrow = (): React.ReactElement => {
    return (
      <div className="multi-select__drop-down-actions">
        <div
          className="multi-select__drop-down-expand"
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (!this.props.isDisabled) {
              this.toggleDropdown();
            }
          }}
          {...getDataUiActionAttribute(
            this.state.showDropDown ? DataUiAction.Collapse : DataUiAction.Expand,
          )}
        >
          <AnimatedChevron
            className="multi-select__drop-down-expand-icon"
            isTurned={this.state.showDropDown}
            restDirection={RestDirection.Down}
          />
          <span className="sr-only">Select options</span>
        </div>
      </div>
    );
  };

  private readonly _renderSelectedOptions = (): React.ReactNode[] | undefined => {
    const {
      getOptionId,
      getOptionName,
      getSelectedOptionName,
      getSelectedOptionTooltip,
      permanentOptions,
      selectedOptions,
      selectedOptionFloatingElementPlacement,
      shouldRenderSelectedOption,
    } = this.props;

    const getColor = this.props.getOptionColor || getDefaultOptionColor;
    const getDisabledOptionColor =
      this.props.getDisabledOptionColor || getDefaultDisabledOptionColor;

    return permanentOptions
      ?.map((option) => (
        <this.props.permanentOptionComponent
          color={getColor(option)}
          getOptionName={getSelectedOptionName || getOptionName}
          key={getOptionId(option)}
          option={option}
        />
      ))
      .concat(
        selectedOptions
          .filter((option) => (shouldRenderSelectedOption ?? (() => true))(option))
          .map((option) => (
            <this.props.selectedOptionComponent
              color={this.props.isDisabled ? getDisabledOptionColor(option) : getColor(option)}
              getOptionName={getSelectedOptionName || getOptionName}
              getOptionTooltip={this.state.showDropDown ? undefined : getSelectedOptionTooltip}
              isDisabled={this.props.isDisabled}
              key={getOptionId(option)}
              onClick={this._isOptionAmongDropdownOptions(option) ? this._onOptionClick : undefined}
              onRemove={this._removeOption}
              option={option}
              tooltipPlacement={selectedOptionFloatingElementPlacement}
            />
          )),
      );
  };

  private readonly _onSearchFieldBlur = () => {
    this.props.onSearchFieldBlur?.();
    this._debouncedHideDropDown();
  };

  private readonly _renderSearchField = (): JSX.Element => {
    const { searchText, showDropDown } = this.state;

    const {
      isDisabled,
      permanentOptions,
      placeholder,
      selectedOptions,
      customSearchTextClassName,
    } = this.props;

    const showPlaceholder =
      !searchText &&
      placeholder &&
      !this._shouldRenderPlaceholderOption() &&
      !selectedOptions.length &&
      !permanentOptions?.length;

    return (
      <HotkeysHandler
        handlers={{
          onBackspace: this._onBackspacePressed,
          onDown: showDropDown ? this._onArrowDownPressed : undefined,
          onEnter: showDropDown ? this._onEnterPressed : undefined,
          onEscape: showDropDown ? this._onEscapePressed : undefined,
          onLeft: showDropDown ? this._onArrowLeftPressed : undefined,
          onRight: showDropDown ? this._onArrowRightPressed : undefined,
          onUp: showDropDown ? this._onArrowUpPressed : undefined,
        }}
        className="multi-select__search-field"
      >
        <EditableSpan
          className={classNames('multi-select__search-text', customSearchTextClassName)}
          isDisabled={isDisabled}
          onBlur={this._onSearchFieldBlur}
          onChange={this._updateSearchText}
          onFocus={wrapAndMemoizeFunction(this._showDropDown)}
          onKeyDown={this._keyDown}
          ref={(c) => {
            this.searchInput = c;
          }}
          uiElement={DataUiElement.MultiSelectSearchText}
          value={searchText}
        />
        {showPlaceholder && (
          <span className="multi-select__search-field-placeholder">{placeholder}</span>
        )}
      </HotkeysHandler>
    );
  };

  private readonly _renderSelectedOptionsArea = (
    ref: RefObject<HTMLElement>,
    isArrowDisplayed: boolean,
    isValidationDisplayed: boolean,
  ): JSX.Element => {
    const {
      collectionName,
      customClassName,
      getOptionName,
      getSelectedOptionTooltip,
      hasError,
      inputName,
      isDisabled,
      multipleSelectRef,
      placeholder,
      tooltipText,
      tooltipPlacement = 'top',
    } = this.props;
    this._multipleSelectRef = ref;

    propagateInstanceToParent(multipleSelectRef, ref.current);

    return (
      <Tooltip tooltipText={tooltipText} placement={tooltipPlacement}>
        <div
          className={classNames('multi-select', customClassName, {
            'multi-select--has-focus': this.state.showDropDown,
            'multi-select--has-error': isValidationDisplayed || hasError,
            'multi-select--is-disabled': isDisabled,
          })}
          ref={ref as RefObject<HTMLDivElement>}
          onClick={this._focusSearch}
          {...getDataUiElementAttribute(DataUiElement.MultiSelectOptionArea)}
          {...getDataUiCollectionAttribute(collectionName)}
          {...(inputName && getDataUiInputAttribute(inputName))}
        >
          <div className={classNames('multi-select__option-area', customClassName)}>
            {this._renderSelectedOptions()}
            {this._shouldRenderPlaceholderOption() && (
              <this.props.selectedOptionComponent
                color={TagColor.CoolGray}
                getOptionName={getOptionName}
                getOptionTooltip={getSelectedOptionTooltip}
                key="placeholder"
                option={placeholder as any}
              />
            )}
            {this._renderSearchField()}
          </div>
          {isArrowDisplayed && this._renderToggleArrow()}
        </div>
      </Tooltip>
    );
  };

  private readonly _renderDropdownContent = (
    options: ReadonlyArray<T>,
    ref?: MutableRefObject<any>,
  ): JSX.Element | null => {
    const { searchText } = this.state;

    if (!options.length) {
      return null;
    }

    return (
      <MultipleOptionSelectDropDown<T>
        collectionName={this.props.collectionName}
        renderDropdownOption={this.props.renderDropdownOption}
        getIsOptionSelected={this._isOptionSelected}
        getOptionExpansionState={this.props.getOptionExpansionState}
        getOptionId={this.props.getOptionId}
        getOptionIndent={this.props.getOptionIndent}
        getOptionColor={this.props.getOptionColor}
        getOptionName={this.props.getOptionName}
        getOptionTooltip={this.props.getOptionTooltip}
        getOptionType={this.props.getOptionType}
        highlightedOption={this.state.highlightedOption}
        highlightedPattern={searchText.trim()}
        id={this.props.dropdownId}
        isOptionDisabled={this.props.isOptionDisabled}
        onOptionCollapse={this.props.onOptionCollapse && this._optionCollapse}
        onOptionConfirmed={this._addOption}
        onOptionExpand={this.props.onOptionExpand && this._optionExpand}
        onOptionHighlighted={this._highlightOption}
        onOptionRemoved={this._removeByReselect}
        options={options}
        ref={(e) => {
          if (ref) {
            ref.current = e;
          }

          this._dropDownRef.current = e;
        }}
        showOptionWithWorkflowStepColorIndicator={
          this.props.showOptionWithWorkflowStepColorIndicator
        }
        shouldSearchedOptionBeHighlighted={this.props.shouldSearchedOptionBeHighlighted}
        maxWidth={this._multipleSelectRef?.current?.offsetWidth}
      />
    );
  };

  render(): JSX.Element {
    const { validationMessage, validationMessageBehavior } = this.props;
    const { showDropDown } = this.state;

    const dropDownOptions = this._getOptionsForDropDown();
    const isValidationDisplayed = shouldDisplayValidation(
      validationMessageBehavior,
      this.state.hasBeenTouched,
      validationMessage,
    );
    const isArrowDisplayed = !!dropDownOptions.length;

    return (
      <>
        <DropDownPositioner
          isOptionListVisible={showDropDown}
          onClickOutside={this._handleClickOutside}
          renderContent={() => this._renderDropdownContent(dropDownOptions)}
          renderSelector={(ref) =>
            this._renderSelectedOptionsArea(ref, isArrowDisplayed, isValidationDisplayed)
          }
          tippyOptions={tippyOptions}
        />
        <FormFieldError isDisplayed={isValidationDisplayed}>{validationMessage}</FormFieldError>
        {this.props.caption && (
          <InputCaption $inputState={InputState.Default}>{this.props.caption}</InputCaption>
        )}
      </>
    );
  }
}
