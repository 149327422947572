import React from 'react';
import { ProjectOverviewItem } from '../CategoryItemsListingModal.tsx';
import { FullView } from './FullView.tsx';
import { ScheduledToPublishView } from './ScheduledToPublishView.tsx';
import { WorkflowStepView } from './WorkflowStepView.tsx';

export enum CategoryItemsListingModalView {
  ScheduledToPublishAt = 'ScheduledToPublishAt',
  WorkflowStep = 'WorkflowStep',
  Full = 'Full',
}

export interface ViewItemsProps {
  readonly contentItems: ReadonlyArray<ProjectOverviewItem>;
}

interface ListingViewProps extends ViewItemsProps {
  readonly viewType: CategoryItemsListingModalView;
}

export const ListingView: React.FC<ListingViewProps> = ({ viewType, contentItems }) => {
  switch (viewType) {
    case CategoryItemsListingModalView.Full:
      return <FullView contentItems={contentItems} />;

    case CategoryItemsListingModalView.WorkflowStep:
      return <WorkflowStepView contentItems={contentItems} />;

    case CategoryItemsListingModalView.ScheduledToPublishAt:
      return <ScheduledToPublishView contentItems={contentItems} />;
  }
};
