import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import classNames from 'classnames';
import Immutable from 'immutable';
import { FC, Fragment, ReactNode, useState } from 'react';
import { DataTable } from '../../../../_shared/components/DataTable/DataTable.tsx';
import { DataTableAction } from '../../../../_shared/components/DataTable/DataTableActions.tsx';
import { DataTableCell } from '../../../../_shared/components/DataTable/DataTableCell.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { DataTableRow } from '../../../../_shared/components/DataTable/DataTableRow.tsx';
import { HtmlSubscriptionManagementPageTitle } from '../../../../_shared/components/HtmlSubscriptionManagementPageTitle.tsx';
import { ModalDialog } from '../../../../_shared/components/ModalDialog/ModalDialog.tsx';
import { QuickTip } from '../../../../_shared/components/infos/QuickTip.tsx';
import { OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import { ListingMessage } from '../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import {
  DataUiAction,
  DataUiAppName,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  formatUserFirstNameForUsersListing,
  formatUserLastNameForUsersListing,
  formatUserName,
  userLastNameComparer,
} from '../../../../_shared/utils/usersUtils.ts';
import { StatusMessageStyle } from '../../../contentInventory/content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';
import { disabledAdminCheckboxBalloonText } from '../../../environmentSettings/users/constants/invitationManagementBalloonTexts.ts';
import { SubscriptionTabName } from '../../shared/constants/subscriptionTabName.ts';
import { ISubscriptionAdmin } from '../../shared/models/SubscriptionAdmin.ts';
import { SubscriptionAdminInvitationStatus } from '../containers/SubscriptionAdminInvitationStatus.tsx';

const SubscriptionAdminsColumns: ReadonlyArray<Column> = [
  {
    columnName: 'First name',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Last name',
    orderBy: OrderByDirection.Ascending,
  },
  {
    columnName: 'Email',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Status',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--9',
  },
];

export type TableTitleMessage = {
  readonly text: string;
  readonly style: StatusMessageStyle;
};

type SubscriptionAdminsManagementProps = {
  readonly currentUserId: string | null;
  readonly deletingSubscriptionAdmins: boolean;
  readonly isSubscriptionExpired: boolean;
  readonly onAddSubscriptionAdmin: () => void;
  readonly onDeleteAdmins: (
    subscriptionId: Uuid,
    selectedAdminIds: Immutable.Set<UserId>,
    deactivateFromProjects: boolean,
  ) => void;
  readonly onSelectAdmin: (userId: string) => void;
  readonly onUnselectAdmin: (userId: string) => void;
  readonly selectedAdminIds: Immutable.Set<UserId>;
  readonly subscriptionAdmins: ReadonlyArray<ISubscriptionAdmin>;
  readonly subscriptionId: Uuid;
  readonly titleMessage: TableTitleMessage;
};

export const SubscriptionAdminsManagement: FC<SubscriptionAdminsManagementProps> = ({
  currentUserId,
  deletingSubscriptionAdmins,
  isSubscriptionExpired,
  onAddSubscriptionAdmin,
  onDeleteAdmins,
  onSelectAdmin,
  onUnselectAdmin,
  selectedAdminIds,
  subscriptionAdmins,
  subscriptionId,
  titleMessage,
}) => {
  const [showDeleteAdminsDialog, setShowDeleteAdminsDialog] = useState(false);
  const [deleteAdminFromAllProjects, setDeleteAdminFromAllProjects] = useState(false);

  const selectAllAdmins = (isChecked: boolean): void => {
    subscriptionAdmins.forEach((admin) => {
      if (!admin || admin.isInvitationPending) {
        return;
      }
      const userId = admin.userId;
      if (isChecked) {
        onUnselectAdmin(userId);
      } else {
        if (userId === currentUserId) {
          return;
        }
        onSelectAdmin(userId);
      }
    });
  };

  const deactivateAdminFromAllProjects = (): void => {
    setDeleteAdminFromAllProjects((prevDeleteAdmins) => !prevDeleteAdmins);
  };

  const cancelDialog = () => {
    setShowDeleteAdminsDialog(false);
    setDeleteAdminFromAllProjects(false);
  };

  const deleteAdmins = (): void => {
    if (!subscriptionId) {
      return;
    }

    onDeleteAdmins(subscriptionId, selectedAdminIds, deleteAdminFromAllProjects);
    cancelDialog();
  };

  const renderModalDialogAdminNames = () => {
    const selectedAdmins = subscriptionAdmins.filter((a: ISubscriptionAdmin) =>
      selectedAdminIds.includes(a.userId),
    );
    const adminCount = selectedAdmins.length;
    let namesLabels: ReactNode = null;

    switch (adminCount) {
      case 1: {
        namesLabels = <AdminName admin={selectedAdmins[0] ?? null} />;
        break;
      }

      case 2: {
        namesLabels = (
          <>
            <AdminName admin={selectedAdmins[0] ?? null} />
            and <AdminName admin={selectedAdmins[1] ?? null} />
          </>
        );
        break;
      }

      default: {
        namesLabels = selectedAdmins.map((admin, index, arr) =>
          arr.length - 1 === index ? (
            <Fragment key={admin.userId}>
              and <AdminName admin={admin} />
            </Fragment>
          ) : (
            <AdminName key={admin.userId} admin={admin} />
          ),
        );
      }
    }

    return <p>Should we deactivate {namesLabels} in all projects as well?</p>;
  };

  const renderDeleteAdminsConfirmationDialog = (): JSX.Element | null => {
    const areMultiple = selectedAdminIds.size > 1;

    const title = 'Removing admins';

    const content = (
      <div>
        <p>Removed admins will still be Project managers in projects under the subscription.</p>
        {renderModalDialogAdminNames()}
        <p>
          <input
            className="option__input option__input--hidden"
            type="checkbox"
            id="deactivate-admin-all-projects"
            onChange={deactivateAdminFromAllProjects}
          />
          <label
            htmlFor="deactivate-admin-all-projects"
            className={classNames('option option--is-checkbox', {
              'option--is-selected': deleteAdminFromAllProjects,
            })}
          >
            <span className="option__label">
              {areMultiple
                ? 'Yes, deactivate these users in all projects'
                : 'Yes, deactivate the user in all projects'}
            </span>
          </label>
        </p>
      </div>
    );

    const footer = (
      <>
        <Button
          buttonStyle="secondary"
          onClick={cancelDialog}
          {...getDataUiActionAttribute(DataUiAction.Cancel)}
          autoFocus
        >
          Cancel
        </Button>
        <Button
          buttonStyle="secondary"
          destructive
          onClick={deleteAdmins}
          {...getDataUiActionAttribute(DataUiAction.Remove)}
        >
          {deleteAdminFromAllProjects ? 'Remove and deactivate' : 'Remove'}
        </Button>
      </>
    );

    if (showDeleteAdminsDialog) {
      return (
        <ModalDialog
          headerContent={title}
          bodyContent={content}
          footerContentRight={footer}
          onClose={cancelDialog}
        />
      );
    }
    return null;
  };

  const deleteAction: DataTableAction = {
    text: 'Remove',
    isDestructive: true,
    isDisabled: deletingSubscriptionAdmins,
    onClick: () => setShowDeleteAdminsDialog(true),
    dataUiAction: DataUiAction.Remove,
  };

  const selectableAdminsCount = subscriptionAdmins.filter(
    (admin: ISubscriptionAdmin) => !admin.isInvitationPending && admin.userId !== currentUserId,
  ).length;
  const selectedAdminsCount = selectedAdminIds.count();
  const subscriptionAdminCanBeSelected = selectableAdminsCount > 0;
  const allEntriesSelected =
    selectedAdminsCount === selectableAdminsCount && subscriptionAdminCanBeSelected;

  return (
    <div
      className="canvas__content-pane canvas__content-pane--is-not-project-specific subscription-pane"
      {...getDataUiAppNameAttribute(DataUiAppName.SubscriptionAdmins)}
      data-hj-suppress=""
    >
      <HtmlSubscriptionManagementPageTitle tabName={SubscriptionTabName.Admins} />
      <div className="row">
        <div className="col-md-24 col-lg-16">
          <DataTable
            createNewItemLabel="Add admin"
            createNewItemTooltip={
              isSubscriptionExpired
                ? 'You cannot add an admin because your subscription has expired.'
                : ''
            }
            onCreateNewItem={onAddSubscriptionAdmin}
            isCreateNewDisabled={isSubscriptionExpired}
            dataUiCollectionName={DataUiCollection.SubscriptionAdmins}
            actions={selectedAdminsCount > 0 ? [deleteAction] : []}
            title={
              <ListingMessage
                statusInfoMessage={{
                  text: titleMessage.text,
                  style: titleMessage.style,
                }}
              />
            }
            header={
              <DataTableHeadRow
                columns={SubscriptionAdminsColumns}
                allEntriesSelected={allEntriesSelected}
                onCheckboxChange={selectAllAdmins}
                showCheckboxes
              />
            }
          >
            {[...subscriptionAdmins].sort(userLastNameComparer).map((admin) => {
              if (!admin) {
                return;
              }
              const isRowSelected = selectedAdminIds.has(admin.userId);
              const isCurrentUser = admin.userId === currentUserId;
              return (
                <DataTableRow
                  id={admin.userId}
                  key={admin.userId}
                  isSelected={isRowSelected}
                  onSelectionChange={() => {
                    if (isRowSelected) {
                      onUnselectAdmin(admin.userId);
                    } else {
                      onSelectAdmin(admin.userId);
                    }
                  }}
                  showCheckboxes
                  selectionDisabled={isCurrentUser || admin.isInvitationPending}
                  dataUiObjectName={admin.email}
                  disabledCheckboxBalloonText={
                    admin.isInvitationPending ? disabledAdminCheckboxBalloonText : undefined
                  }
                >
                  <DataTableCell>{formatUserFirstNameForUsersListing(admin)}</DataTableCell>
                  <DataTableCell>{formatUserLastNameForUsersListing(admin)}</DataTableCell>
                  <DataTableCell>{admin.email}</DataTableCell>
                  <DataTableCell>
                    <SubscriptionAdminInvitationStatus subscriptionAdmin={admin} />
                  </DataTableCell>
                </DataTableRow>
              );
            })}
          </DataTable>
        </div>
        <div className="col-md-24 col-lg-8">
          <Box position="sticky" top={0}>
            <QuickTip>
              <b>Subscription admins</b> can add and remove other admins, change subscription plan,
              and manage subscriptions. Every subscription admin has access to all languages in all
              projects under a subscription and counts as an active user.
            </QuickTip>
          </Box>
        </div>
      </div>
      {renderDeleteAdminsConfirmationDialog()}
    </div>
  );
};

const AdminName = (props: { readonly admin: ISubscriptionAdmin | null }) => {
  return <b>{formatUserName(props.admin)}</b>;
};
