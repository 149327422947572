import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { isGuidelinesTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { ContentItemElement } from './ContentItemElement.tsx';
import { ContentItemPaneElementsGroup } from './ContentItemPaneElementsGroup.tsx';

type ContentItemElementGroupProps = Readonly<{
  canEdit: boolean;
  hasTopRoundedCorners?: boolean;
  hasBottomRoundedCorners?: boolean;
  typeElements: ReadonlyArray<TypeElement>;
}>;

export const ContentItemElementGroup = ({
  canEdit,
  hasTopRoundedCorners,
  hasBottomRoundedCorners,
  typeElements,
}: ContentItemElementGroupProps) => {
  return (
    <ContentItemPaneElementsGroup
      isDisabled={!canEdit}
      isGuideline={isGuidelinesTypeElement(typeElements[0])}
      hasTopRoundedCorners={hasTopRoundedCorners}
      hasBottomRoundedCorners={hasBottomRoundedCorners}
    >
      {typeElements.map((typeElement) => (
        <ContentItemElement
          key={typeElement.elementId}
          canEdit={canEdit}
          typeElement={typeElement}
        />
      ))}
    </ContentItemPaneElementsGroup>
  );
};
