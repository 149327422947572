import { memoize } from '@kontent-ai/memoization';
import { Collection, alphabetically, createCompare } from '@kontent-ai/utils';
import { IRole } from '../../../../data/models/roles/IRole.ts';

export const getSortedRoles = (rolesById: ReadonlyMap<Uuid, IRole>): ReadonlyArray<IRole> =>
  Collection.getValues(rolesById).toSorted(
    createCompare({
      compare: alphabetically,
      select: (x) => x.name,
    }),
  );

export const getSortedRolesMemoized = memoize.maxOne(getSortedRoles);
