import React, { useCallback } from 'react';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { getCommentThreadTriggerId } from '../../../../../utils/commentUtils.ts';
import { focusCommentThread } from '../../../actions/thunkContentItemEditingActions.ts';
import { CommentButton as CommentButtonComponent } from '../../../components/elements/subComponents/CommentButton.tsx';

export type CommentButtonContainerProps = {
  readonly commentThreadId: Uuid | null;
  readonly disableTabulator?: boolean;
  readonly hasFocusedComment: boolean;
  readonly onNewComment: () => void;
};

export const CommentButton: React.FC<CommentButtonContainerProps> = ({
  commentThreadId,
  disableTabulator,
  hasFocusedComment,
  onNewComment,
}) => {
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    if (commentThreadId) {
      dispatch(focusCommentThread(commentThreadId));
      const commentThreadElement: HTMLElement | null = document.getElementById(commentThreadId);
      commentThreadElement?.focus();
    } else {
      onNewComment();
    }
  }, [commentThreadId, onNewComment]);

  return (
    <CommentButtonComponent
      id={commentThreadId ? getCommentThreadTriggerId(commentThreadId) : null}
      disableTabulator={disableTabulator}
      hasComment={!!commentThreadId}
      isFocused={hasFocusedComment}
      onClick={onClick}
    />
  );
};

CommentButton.displayName = 'CommentButtonContainer';
