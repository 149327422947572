import Immutable from 'immutable';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { OrderBy } from '../../../../_shared/models/OrderBy.ts';
import { IExistingUserToInvite } from '../../../../data/models/users/ExistingUserToInvite.ts';
import {
  ICollectionGroupRoles,
  IProjectContributor,
} from '../../../../data/models/users/ProjectContributor.ts';
import { IUserFilterState } from './internalReducers/filter.ts';

export enum UserListingColumnCode {
  FirstName = 'FirstName',
  LastName = 'LastName',
  Status = 'Status',
}

export interface IUsersAppState {
  readonly filter: IUserFilterState;
  readonly listingLoadingStatus: LoadingStatus;
  readonly projectContributors: ReadonlyMap<Uuid, IProjectContributor>;
  readonly sortBy: OrderBy<UserListingColumnCode>;
  readonly userDetail: IUserDetailState;
  readonly userInvitation: IInvitationModalState;
  readonly usersCountedInUsage: ReadonlyArray<string>;
}

export type Invitation = {
  readonly email: string;
};

export interface IInvitationModalState {
  readonly collectionGroups: readonly ICollectionGroupRoles[];
  readonly existingUsersByEmail: Immutable.Map<string, IExistingUserToInvite>;
  readonly invitations: ReadonlyArray<Invitation>;
  readonly isInitialized: boolean;
  readonly isSaving: boolean;
  readonly savingError: string | null;
  readonly searchText: string;
}

export interface IUserDetailState {
  readonly collectionGroups: readonly ICollectionGroupRoles[];
  readonly isInitialized: boolean;
  readonly isSaving: boolean;
  readonly savingError: string | null;
}
