import { useAttachRef } from '@kontent-ai/hooks';
import { delay } from '@kontent-ai/utils';
import { useFocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import { chain } from '@react-aria/utils';
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  FormEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  Ref,
  useEffect,
  useId,
  AriaRole,
} from 'react';
import styled from 'styled-components';
import { Box } from '../../../layout/Box/Box.tsx';
import { Column } from '../../../layout/Row/Column.tsx';
import { Row } from '../../../layout/Row/Row.tsx';
import { Stack } from '../../../layout/Stack/Stack.tsx';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { colorTextDefault } from '../../../tokens/decision/colors.ts';
import {
  spacingBetweenButtonsHorizontal,
  spacingElementCaption,
  spacingElementLabel,
  spacingInputSmall,
} from '../../../tokens/decision/spacing.ts';
import { shadowAlertFocusStyles, shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { transition250 } from '../../../tokens/quarks/transitions.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { DataUiAttributes } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { Hint } from '../../Hint/Hint.tsx';
import { LabelSize } from '../../Label/labelSize.ts';
import { Tooltip } from '../../Tooltip/Tooltip.tsx';
import { ConditionalWrapper } from '../../_utils/ConditionalWrapper.tsx';
import { A11yLabelingPropsExtension } from '../../_utils/ariaLabelingProps.ts';
import { mergeAriaDescribedBy } from '../../_utils/ariaUtils.ts';
import { TooltipPropsExtension } from '../../_utils/propPrefabs.ts';
import { InputState } from '../inputStateEnum.ts';
import {
  FakeInput,
  getFakeInputFocusBorderColor,
  getFakeInputHoverBorderColor,
} from './FakeInput.tsx';
import { InputAddon } from './InputAddon.tsx';
import { InputCaption } from './InputCaption.tsx';
import { InputField } from './InputField.tsx';
import { InputLabel } from './InputLabel.tsx';
import { InputOverlayMessage } from './InputOverlayMessage.tsx';
import { StyledInputWrapper } from './StyledInputWrapper.tsx';
import { InputStyleProps } from './inputStyleProps.type.ts';
import { inputBorderRadius, inputMinHeight } from './tokens.ts';

export interface IInputHandlers {
  readonly onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  readonly onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  readonly onClick?: MouseEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  readonly onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  readonly onInput?: FormEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  readonly onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  readonly onMouseDown?: MouseEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  readonly onMouseUp?: MouseEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

type InjectedProps = IInputHandlers &
  A11yLabelingPropsExtension & {
    readonly 'aria-controls'?: string;
    readonly 'data-ui-has-alert'?: boolean;
    readonly [DataUiAttributes.Input]?: string | number | undefined;
    readonly defaultValue?: string;
    readonly disabled?: boolean;
    readonly id: string;
    readonly inputState: InputState;
    readonly placeholder?: string;
    readonly readOnly?: boolean;
    readonly role?: AriaRole;
    readonly value?: string;
  };

export type BaseInputProps = IInputHandlers &
  Pick<InjectedProps, 'defaultValue' | 'placeholder' | 'role' | 'value' | 'aria-controls'> &
  Pick<TooltipPropsExtension, 'tooltipText' | 'tooltipPlacement'> &
  A11yLabelingPropsExtension & {
    readonly autoFocus?: boolean;
    readonly auxiliaryElements?: React.ReactNode;
    readonly caption?: string | null;
    readonly delayAutoFocus?: number;
    /** @param hintText won't be shown without providing a label */
    readonly hintText?: string;
    readonly id?: string;
    readonly inputFieldRef?: Ref<HTMLSpanElement>;
    readonly inputState?: InputState;
    readonly label?: string | null;
    readonly overlayMessage?: React.ReactNode;
  };

interface Props extends BaseInputProps {
  /**
   * customInputSelector is to be used in tandem with renderControlComponent. It is needed for focus
   * styles to work properly in the InputField component. The customInputSelector needs to be a css
   * selector which matches the top-level HTML tag returned by renderControlComponent.
   */
  readonly customInputSelector?: string;
  readonly noWrap?: boolean;
  readonly prefix?: React.ReactNode;
  /**
   * When you use renderControlComponent that does not render directly <InputControl /> specify also
   * customInputSelector to make focus-styles work properly.
   */
  readonly renderControlComponent: (
    ref: Ref<HTMLInputElement | HTMLTextAreaElement>,
    props: InjectedProps,
  ) => JSX.Element;
  readonly suffixes?: React.ReactNode;
}

const FocusRingSpan = styled.span<
  InputStyleProps & {
    readonly isFocusVisible: boolean;
  }
>`
  display: block;
  content: '';

  border-radius: ${inputBorderRadius};
  
  pointer-events: none;
  transition: border-color ${transition250};

  ${StyledInputWrapper}:focus-within && {
    border-color: ${getFakeInputFocusBorderColor};
  }

  ${InputField}:hover && {
    border-color: ${getFakeInputHoverBorderColor};
  };

  transition: opacity ${transition250};
  
  ${({ isFocusVisible, $inputState }) => isFocusVisible && ($inputState === InputState.Alert ? shadowAlertFocusStyles : shadowFocusStyles)};
`;

export const BaseInputComponent = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, Props>(
  (
    {
      'aria-describedby': ariaDescribedBy = '',
      autoFocus,
      auxiliaryElements,
      caption,
      customInputSelector,
      delayAutoFocus = 0,
      hintText,
      id,
      inputFieldRef,
      inputState = InputState.Default,
      label,
      noWrap,
      onBlur,
      onFocus,
      overlayMessage,
      prefix,
      renderControlComponent,
      suffixes,
      tooltipPlacement = 'top',
      tooltipText,
      ...otherProps
    },
    forwardedRef,
  ) => {
    const { refToForward, refObject: controlComponentRef } = useAttachRef(forwardedRef);

    useEffect(() => {
      if (autoFocus) {
        delay(delayAutoFocus).then(() => controlComponentRef.current?.focus());
      }
    }, [autoFocus, delayAutoFocus, controlComponentRef]);

    const { isFocusVisible, focusProps } = useFocusRing({ isTextInput: true });
    const { isHovered, hoverProps } = useHover({});

    const captionId = useId();
    const randomControlComponentId = useId();
    const tooltipId = useId();
    const controlComponentId = id ?? randomControlComponentId;

    const hasOverlayMessage =
      typeof overlayMessage === 'string' ? !!overlayMessage.length : !!overlayMessage;
    const shouldShowTooltip = isFocusVisible || isHovered;

    return (
      <Stack spacing={spacingElementCaption}>
        <Box component="span" display="block">
          <Stack spacing={spacingElementLabel}>
            {label && (
              <ConditionalWrapper
                condition={!!hintText}
                wrapper={(children) => (
                  <Box display="flex" columnGap={Spacing.XS} alignItems="flex-start">
                    {children}
                    <Box
                      display="flex"
                      height={Typography.LabelLarge.lineHeight}
                      alignItems="center"
                      flexBasis="auto"
                      flexShrink={0}
                      flexGrow={0}
                    >
                      <Hint
                        aria-label={`${label} hint: ${hintText}`}
                        tooltipText={hintText ?? ''}
                      />
                    </Box>
                  </Box>
                )}
              >
                <InputLabel
                  color={colorTextDefault}
                  htmlFor={controlComponentId}
                  size={LabelSize.L}
                >
                  {label}
                </InputLabel>
              </ConditionalWrapper>
            )}
            <FocusRingSpan
              $inputState={inputState}
              isFocusVisible={noWrap ? isFocusVisible : false}
            >
              <div css="position: relative">
                <div
                  css={`
                  pointer-events: ${hasOverlayMessage ? 'none' : 'initial'};
                  opacity: ${hasOverlayMessage ? 0.1 : 1};
                `}
                >
                  <Row
                    alignY="start"
                    component="span"
                    spacing={spacingBetweenButtonsHorizontal}
                    noWrap
                  >
                    <Column component="span">
                      <Tooltip
                        tooltipText={tooltipText}
                        placement={tooltipPlacement}
                        visible={shouldShowTooltip}
                        tooltipTextId={tooltipId}
                      >
                        <InputField
                          overflow={noWrap ? 'auto' : 'inherit'}
                          $inputState={inputState}
                          ref={inputFieldRef}
                          onClick={() => controlComponentRef.current?.focus()}
                          {...hoverProps}
                        >
                          <Row
                            css="height: 100%"
                            component="span"
                            spacing={spacingInputSmall}
                            noWrap
                          >
                            {prefix && (
                              <Column component="span" width="content">
                                <InputAddon $inputState={inputState}>{prefix}</InputAddon>
                              </Column>
                            )}
                            <StyledInputWrapper>
                              {renderControlComponent(refToForward, {
                                'aria-describedby': mergeAriaDescribedBy(
                                  ariaDescribedBy,
                                  caption ? captionId : null,
                                  shouldShowTooltip ? tooltipId : null,
                                ),
                                'data-ui-has-alert': inputState === InputState.Alert,
                                disabled: inputState === InputState.Disabled,
                                id: controlComponentId,
                                inputState,
                                onBlur: chain(focusProps.onBlur, onBlur),
                                onFocus: chain(focusProps.onFocus, onFocus),
                                readOnly: inputState === InputState.ReadOnly,
                                ...otherProps,
                              })}
                              <FakeInput
                                $inputState={inputState}
                                isFocusVisible={noWrap ? false : isFocusVisible}
                                $customInputSelector={customInputSelector}
                              />
                            </StyledInputWrapper>
                            {React.Children.map(
                              suffixes,
                              (child, index) =>
                                child && (
                                  <Column
                                    component="span"
                                    width="content"
                                    key={`baseInputSuffix-${index}`}
                                  >
                                    <InputAddon $inputState={inputState}>{child}</InputAddon>
                                  </Column>
                                ),
                            )}
                            {/* todo focus ring */}
                            {tooltipText && inputState === InputState.Disabled && (
                              <Box component="div" tabIndex={0}>
                                <SrOnly>{tooltipText}</SrOnly>
                              </Box>
                            )}
                          </Row>
                        </InputField>
                      </Tooltip>
                    </Column>
                    {auxiliaryElements && (
                      <Column component="span" width="content">
                        <Row
                          alignY="center"
                          css={`
                          height: ${px(inputMinHeight)};
                        `}
                          spacing={spacingBetweenButtonsHorizontal}
                          noWrap
                        >
                          {React.Children.map(
                            auxiliaryElements,
                            (child) =>
                              child && (
                                <Column component="span" width="content">
                                  {child}
                                </Column>
                              ),
                          )}
                        </Row>
                      </Column>
                    )}
                  </Row>
                </div>
                {hasOverlayMessage && <InputOverlayMessage>{overlayMessage}</InputOverlayMessage>}
              </div>
            </FocusRingSpan>
          </Stack>
        </Box>
        {caption && (
          <InputCaption id={captionId} $inputState={inputState}>
            {caption}
          </InputCaption>
        )}
      </Stack>
    );
  },
);

BaseInputComponent.displayName = 'BaseInputComponent';
