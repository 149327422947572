import { Box } from '@kontent-ai/component-library/Box';
import { TabView } from '@kontent-ai/component-library/TabView';
import { Spacing, colorBorderLowEmphasis, px } from '@kontent-ai/component-library/tokens';
import { PropsWithChildren } from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ContentItemPaneElementsGroup } from './ContentItemPaneElementsGroup.tsx';
import { ContentItemPaneElementsGroupCompact } from './ContentItemPaneElementsGroupCompact.tsx';

export type ContentItemGroupTabsProps = Readonly<
  PropsWithChildren<{
    areAnyContentGroupsVisible: boolean;
    canEditGroupContent: boolean;
    isCompact: boolean;
  }>
>;

export const ContentItemGroupTabs = ({
  areAnyContentGroupsVisible,
  canEditGroupContent,
  children,
  isCompact,
}: ContentItemGroupTabsProps) => {
  return (
    <>
      {areAnyContentGroupsVisible && (
        <PaneElementsGroup isCompact={isCompact} isDisabled={!canEditGroupContent} />
      )}
      <TabView.TabPanel
        css={`
          padding-top: 0;
        `}
      >
        {() => children}
      </TabView.TabPanel>
    </>
  );
};

type PaneElementsGroupProps = Readonly<{
  isCompact: boolean;
  isDisabled: boolean;
}>;

const PaneElementsGroup = ({ isCompact, isDisabled }: PaneElementsGroupProps) =>
  isCompact ? (
    <ContentItemPaneElementsGroupCompact areHeaderContentGroups isDisabled={isDisabled}>
      <Box
        paddingTop={Spacing.L}
        css={`
          border-left: 1px solid ${colorBorderLowEmphasis};
          border-right: 1px solid ${colorBorderLowEmphasis};
        `}
      >
        <TabView.TabGroup
          {...getDataUiCollectionAttribute(DataUiCollection.ContentGroups)}
          css={`
            padding-left: ${px(Spacing.XS)};
            padding-right: ${px(Spacing.XS)};
          `}
        />
      </Box>
    </ContentItemPaneElementsGroupCompact>
  ) : (
    <ContentItemPaneElementsGroup areHeaderContentGroups isDisabled={isDisabled}>
      <Box paddingTop={Spacing.L}>
        <TabView.TabGroup {...getDataUiCollectionAttribute(DataUiCollection.ContentGroups)} />
      </Box>
    </ContentItemPaneElementsGroup>
  );
