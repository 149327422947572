import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { filterCollectionsByCapabilityInLanguage } from '../../../../../_shared/utils/permissions/filterCollectionsByCapability.ts';
import { ICollection } from '../../../../../data/models/collections/Collection.ts';
import { ISpace } from '../../../../../data/models/space/space.ts';
import { IUser } from '../../../../../data/reducers/user/IUser.type.ts';
import { spacesContainCollection } from '../../../../environmentSettings/spaces/utils/spacesUtils.ts';

export const getAvailableSpacesForUser = (
  variantId: Uuid | null,
  projectId: Uuid,
  spaces: ReadonlyArray<ISpace>,
  collections: ReadonlyArray<ICollection>,
  user: IUser,
): ReadonlyArray<ISpace> => {
  const availableCollections = filterCollectionsByCapabilityInLanguage(
    collections,
    variantId ?? '',
    Capability.ViewContent,
    projectId,
    user,
  );

  const areAllCollectionsAvailable = availableCollections.length === collections.length;

  return areAllCollectionsAvailable
    ? spaces
    : spacesContainCollection(
        spaces,
        availableCollections.map((collection) => collection.id),
      );
};
