import { Collection, groupBy, notNull } from '@kontent-ai/utils';
import React from 'react';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { IProjectDetails } from '../../../data/models/projects/ProjectDetails.ts';
import {
  ExpiredSubscriptionsWarning as ExpiredSubscriptionsWarningComponent,
  ISubscriptionWarningLinkData,
} from '../components/ExpiredSubscriptionsWarning.tsx';

const getSubscriptionLinks = (
  projects: ReadonlyArray<IProjectDetails>,
): ReadonlyArray<ISubscriptionWarningLinkData> =>
  Collection.getEntries(groupBy(projects, (p) => p.subscriptionId))
    .map(([subscriptionId, details]) => {
      const firstDetail = details[0];
      return firstDetail
        ? {
            subscriptionId,
            subscriptionName: firstDetail.subscriptionName,
          }
        : null;
    })
    .filter(notNull);

export const ExpiredSubscriptionsWarning: React.FC = () => {
  const projects = useSelector((state) => state.data.projects.byId);
  const administratedSubscriptionsIds = useSelector(
    (state) => state.data.subscriptions.administratedIds,
  );

  const expiredProjects = projects.toArray().filter((project) => project.planIsExpired);
  const administratedExpiredProjects = expiredProjects.filter((p) =>
    administratedSubscriptionsIds.contains(p.subscriptionId),
  );
  const administratedSubscriptionLinks = getSubscriptionLinks(administratedExpiredProjects);
  const notAdministratedProjects = expiredProjects.filter(
    (p) => !administratedSubscriptionsIds.contains(p.subscriptionId),
  );
  const notAdministratedSubscriptionLinks = getSubscriptionLinks(notAdministratedProjects);

  if (!expiredProjects.length) {
    return null;
  }

  return (
    <ExpiredSubscriptionsWarningComponent
      administratedSubscriptionLinks={administratedSubscriptionLinks}
      notAdministratedSubscriptionLinks={notAdministratedSubscriptionLinks}
    />
  );
};
