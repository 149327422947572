interface IBreadcrumbsOriginItemBase {
  readonly title: string;
  readonly path: string;
}

export interface IAssetFolderBreadcrumbsOriginItem extends IBreadcrumbsOriginItemBase {
  readonly type: 'AssetFolder';
}

export type BreadcrumbsOriginItem = IBreadcrumbsOriginItemBase | IAssetFolderBreadcrumbsOriginItem;

export interface IBreadcrumbsOrigin {
  readonly items: ReadonlyArray<BreadcrumbsOriginItem>;
}

export enum FullTextSearchStatus {
  Initial = 'Initial',
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  Unavailable = 'Unavailable',
}

export interface IContentInventoryStoreState {
  readonly breadcrumbsOrigin: IBreadcrumbsOrigin;
  readonly fullTextSearchStatus: FullTextSearchStatus;
}
