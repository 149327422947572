import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { useOurFocusRing } from '@kontent-ai/component-library/hooks';
import { SrOnly } from '@kontent-ai/component-library/styles';
import { useFocusWithin } from '@react-aria/interactions';
import classNames from 'classnames';
import React, { useId, useRef, useState } from 'react';
import { AssetTilePreview } from '../../../../../_shared/components/AssetTile/AssetTilePreview.tsx';
import { AssetTileSummary } from '../../../../../_shared/components/AssetTile/AssetTileSummary.tsx';
import { CreateAutoScrollId } from '../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { useAutoScroll } from '../../../../../_shared/hooks/useAutoScroll.ts';
import { IAssetRendition } from '../../../../../data/models/assetRenditions/AssetRendition.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { AssetThumbnailBottomClassname } from '../../../../contentInventory/assets/components/AssetTile/AssetTile.tsx';
import { AssetTileActions } from '../../../../contentInventory/assets/components/AssetTile/AssetTileActions.tsx';
import { IAssetValidationResult } from '../../ContentItemEditing/components/elements/asset/AssetTile.tsx';

const AssetValidResult: IAssetValidationResult = {
  isAssetFileSizeValid: true,
  isAssetFileTypeValid: true,
  isAssetHeightValid: true,
  isAssetWidthValid: true,
};

type Props = {
  readonly altText: string;
  readonly asset: IAsset;
  readonly canViewAsset: boolean;
  readonly className: string | undefined;
  readonly collectionName: string | null;
  readonly isUncategorized: boolean | null;
  readonly isViewOnly: boolean;
  readonly isFocused?: boolean;
  readonly rendition: IAssetRendition | undefined;
};

export const AssetViewerTile: React.FC<Props> = ({
  altText,
  asset,
  canViewAsset,
  className,
  collectionName,
  isUncategorized,
  isViewOnly,
  rendition,
}) => {
  const [isAssetFocusedWithin, setIsAssetFocusedWithin] = useState(false);
  const assetRef = useRef<HTMLDivElement>(null);
  const assetScrollId = CreateAutoScrollId.forAsset(asset.id || '');
  useAutoScroll({
    scrollId: assetScrollId,
    scrollTargetRef: assetRef,
  });

  const isAssetAccessible = canViewAsset && !asset.archived;

  const titleId = useId();

  const content = (
    <>
      <AssetTilePreview
        asset={asset}
        altText={altText}
        canViewAsset={canViewAsset}
        transformation={rendition?.transformation}
      />
      <div
        className={classNames(
          AssetThumbnailBottomClassname,
          `${AssetThumbnailBottomClassname}--with-padding`,
        )}
      >
        {!isAssetAccessible || !isViewOnly ? (
          <AssetTileSummary
            asset={asset}
            canViewAsset={canViewAsset}
            collectionName={collectionName}
            searchPhrase=""
            validationResult={AssetValidResult}
            rendition={rendition}
            titleId={titleId}
          />
        ) : undefined}
      </div>
    </>
  );

  const isClickable = isAssetAccessible && isViewOnly && asset._link;

  const {
    focusWithinProps: { onClick, ...focusWithinProps },
  } = useFocusWithin({
    onFocusWithinChange: setIsAssetFocusedWithin,
    isDisabled: !!asset._uploading || !isClickable,
  });

  const descriptionId = useId();

  const { focusProps, isFocusVisible } = useOurFocusRing();

  return (
    <div
      className={classNames('asset-thumbnail', 'asset-thumbnail--is-disabled', className, {
        'asset-thumbnail--is-not-clickable': !isClickable,
        'asset-thumbnail--is-viewonly': isViewOnly,
        'asset-thumbnail--is-viewonly-focused': isAssetFocusedWithin,
        'asset-thumbnail--is-focused': isFocusVisible,
      })}
      aria-labelledby={titleId}
      aria-describedby={descriptionId}
      ref={assetRef}
      {...focusWithinProps}
    >
      <SrOnly id={descriptionId}>Asset tile, opens asset details modal</SrOnly>
      {isClickable ? (
        <OutwardLink href={isClickable && asset._link ? asset._link : ''} {...focusProps}>
          {content}
        </OutwardLink>
      ) : (
        content
      )}
      {isAssetAccessible && (
        <AssetTileActions
          asset={asset}
          isUncategorized={isUncategorized}
          showViewAction={isViewOnly}
          transformation={rendition?.transformation}
        />
      )}
    </div>
  );
};

AssetViewerTile.displayName = 'AssetViewerTile';
