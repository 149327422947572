import { memoize } from '@kontent-ai/memoization';

export const getListingItemsCount = memoize.maxOne(
  (listingContentItems: {
    readonly allIds: ReadonlyArray<Uuid> | null;
  }): number => {
    const { allIds } = listingContentItems;

    return allIds ? allIds.length : 0;
  },
);
