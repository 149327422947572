import { useEffect } from 'react';
import { getCurrentProject } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { onboardingNotificationClosed } from '../../actions/sharedActions.ts';
import { trackUserEventWithData } from '../../actions/thunks/trackUserEvent.ts';
import { OnboardingNotificationBar } from '../../components/OnboardingNotifications/OnboardingNotificationBar.tsx';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';
import { getUrlFactory } from '../../utils/urlFactory.ts';

const Message = () => (
  <>🚀 Awesome! Your content is blasting into the world. 🚀 Want to see it in action?</>
);

type Props = {
  readonly publishedContentItemIds: ReadonlyArray<Uuid>;
};

export const ContentPublishingOnboardingTechnicalUserNotification = (props: Props) => {
  const currentProjectId = useSelector((s) => getCurrentProject(s).projectId);

  const dispatch = useDispatch();

  const close = () => dispatch(onboardingNotificationClosed());

  const buttonClick = () => {
    dispatch(
      trackUserEventWithData(TrackedEvent.FeatureUsed, {
        feature: 'onboarding-notification--cta',
        info: 'content-publishing--json',
      }),
    );
    dispatch(onboardingNotificationClosed());
    const jsonSourceUrl = `${getUrlFactory().getDeliverLiveUrl()}/${currentProjectId}/items?system.id[in]=${props.publishedContentItemIds.join(
      ',',
    )}`;
    const jsonPrettifierUrl = getUrlFactory().getJsonPrettifierUrl(jsonSourceUrl);
    window.open(jsonPrettifierUrl, '_blank');
  };

  useEffect(() => {
    dispatch(
      trackUserEventWithData(TrackedEvent.FeatureUsed, {
        feature: 'onboarding-notification--displayed',
        info: 'content-publishing--json',
      }),
    );
  }, []);

  return (
    <OnboardingNotificationBar
      buttonMessage="See JSON output"
      message={<Message />}
      onButtonClick={buttonClick}
      onClose={close}
    />
  );
};
