import { EditingAction } from '../../models/EditingAction.ts';

// The actions below render a list of dropdown-items while the rest provides its own layout → these were originally not mean to be usable
// in editing actions (be it quick- or menu-actions), however, they are partially hacked-in now.

const actionsWithOptions = [
  EditingAction.showDuplicationOptions,
  EditingAction.copyFromLanguage,
  EditingAction.previewItemInMultipleSpaces,
  EditingAction.translateVariant,
  EditingAction.shareLink,
];

export const doesActionRepresentMenuWithMultipleOptions = (editingAction: EditingAction): boolean =>
  actionsWithOptions.includes(editingAction);
