import { ByStatus } from '../../../../_shared/constants/userListingFilter.ts';
import { ICollectionGroupRoles } from '../../../../data/models/users/ProjectContributor.ts';
import {
  Invite_Closed,
  Invite_Invitations_Changed,
  Invite_NotificationBar_Hidden,
  Invite_SearchText_Changed,
  Invite_SelectedCollectionGroups_Changed,
  UserDetail_Closed,
  UserDetail_CollectionGroupsChanged,
  UserDetail_NotificationBar_Hidden,
  UserListing_CollectionsFilter_Updated,
  UserListing_LanguagesFilter_Updated,
  UserListing_Left,
  UserListing_NameListingFilter_Changed,
  UserListing_RolesFilter_Updated,
  UserListing_SortByFirstName_Selected,
  UserListing_SortByLastName_Selected,
  UserListing_SortByStatus_Selected,
  UserListing_SortBy_DirectionToggled,
  UserListing_StatusFilter_Updated,
} from '../constants/usersActionTypes.ts';
import { Invitation } from '../reducers/IUsersAppState.type.ts';

interface ICollectionGroupsInUserDetailChangedPayload {
  readonly newCollectionGroups: readonly ICollectionGroupRoles[];
  readonly originalCollectionGroups: readonly ICollectionGroupRoles[];
}

export const collectionGroupsInUserDetailChanged = (
  payload: ICollectionGroupsInUserDetailChangedPayload,
) =>
  ({
    type: UserDetail_CollectionGroupsChanged,
    payload,
  }) as const;

export const hideNotificationBarInUserDetail = () =>
  ({
    type: UserDetail_NotificationBar_Hidden,
  }) as const;

export const userListingSortDirectionToggled = () =>
  ({
    type: UserListing_SortBy_DirectionToggled,
  }) as const;

export const userListingSortedByLastName = () =>
  ({
    type: UserListing_SortByLastName_Selected,
  }) as const;

export const userListingSortedByFirstName = () =>
  ({
    type: UserListing_SortByFirstName_Selected,
  }) as const;

export const userListingSortedByStatus = () =>
  ({
    type: UserListing_SortByStatus_Selected,
  }) as const;

export const userListingLeft = () =>
  ({
    type: UserListing_Left,
  }) as const;

export const closeUserDetail = () =>
  ({
    type: UserDetail_Closed,
  }) as const;

export const closeInviteModal = () =>
  ({
    type: Invite_Closed,
  }) as const;

export const changeInvitations = (newInvitations: ReadonlyArray<Invitation>) =>
  ({
    type: Invite_Invitations_Changed,
    payload: {
      newInvitations,
    },
  }) as const;

export const changeSearchText = (searchText: string) =>
  ({
    type: Invite_SearchText_Changed,
    payload: {
      searchText,
    },
  }) as const;

export const hideNotificationBarInInvitation = () =>
  ({
    type: Invite_NotificationBar_Hidden,
  }) as const;

export const invitationCollectionGroupsChanged = (
  newCollectionGroups: readonly ICollectionGroupRoles[],
) =>
  ({
    type: Invite_SelectedCollectionGroups_Changed,
    payload: {
      newCollectionGroups,
    },
  }) as const;

export const nameListingFilterChanged = (name: string) =>
  ({
    type: UserListing_NameListingFilter_Changed,
    payload: { name },
  }) as const;

export const collectionsFilterUpdated = (collectionsIds: ReadonlySet<Uuid>) =>
  ({
    type: UserListing_CollectionsFilter_Updated,
    payload: { collectionsIds },
  }) as const;

export const languagesFilterUpdated = (languagesIds: ReadonlySet<Uuid>) =>
  ({
    type: UserListing_LanguagesFilter_Updated,
    payload: { languagesIds },
  }) as const;

export const rolesFilterUpdated = (rolesIds: ReadonlySet<Uuid>) =>
  ({
    type: UserListing_RolesFilter_Updated,
    payload: { rolesIds },
  }) as const;

export const statusFilterUpdated = (activity: ByStatus) =>
  ({
    type: UserListing_StatusFilter_Updated,
    payload: { status: activity },
  }) as const;
