import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { isAiSearchScope } from '../../../../paperModels/aiSearch/utils/isAiSearchScope.ts';
import { FullTextSearchStatus } from '../../shared/reducers/IContentInventoryStoreState.type.ts';
import { ContentItemsTextFilter as ContentItemsTextFilterComponent } from '../components/ContentItemsTextFilter.tsx';
import {
  resetFulltextSorting,
  updateFilterSearchPhrase,
} from '../features/ContentItemInventory/actions/thunkContentItemInventoryActions.ts';
import { notSpecifiedContentListingOrderBy } from '../reducers/listingUi/reducers/orderBy.ts';

type Props = {
  readonly allowAiSearch: boolean;
  readonly fullTextSearchStatus: FullTextSearchStatus;
  readonly isInDialog: boolean;
};

export const ContentItemsTextFilter = ({
  allowAiSearch,
  fullTextSearchStatus,
  isInDialog,
}: Props) => {
  const isAiSearch = useSelector(
    (s) => allowAiSearch && isAiSearchScope(s.contentApp.listingUi.filter.searchScope),
  );
  const searchPhrase = useSelector((state) => state.contentApp.listingUi.filter.searchPhrase);
  const orderByColumnIsCustom = useSelector(
    (s) =>
      s.contentApp.listingUi.orderBy.columnCode !== notSpecifiedContentListingOrderBy.columnCode,
  );

  const dispatch = useDispatch();
  const onUpdateSearchPhrase = (newSearchPhrase: string) =>
    dispatch(updateFilterSearchPhrase(newSearchPhrase, isInDialog));

  return (
    <ContentItemsTextFilterComponent
      autofocus
      fullTextSearchStatus={fullTextSearchStatus}
      isAiSearch={isAiSearch}
      onSortReset={() => dispatch(resetFulltextSorting(!isInDialog))}
      onUpdateSearchPhrase={onUpdateSearchPhrase}
      orderByColumnIsCustom={orderByColumnIsCustom}
      searchPhrase={searchPhrase}
    />
  );
};
