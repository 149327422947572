import { areShallowEqual } from '@kontent-ai/utils';
import React, { useRef } from 'react';
import { DatetimePickerRefType } from '../../../../../_shared/components/DatetimePicker/DatetimePicker.tsx';
import { parseDatetime } from '../../../../../_shared/components/DatetimePicker/InternalFiles/datetimeUtils.ts';
import { DateTime } from '../../../../../_shared/models/DateTime.ts';
import { transferBetweenTimeZonesKeepingLocalTime } from '../../../../../_shared/utils/dateTime/timeZoneUtils.ts';
import { IDateTimeTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/DateTimeTypeElement.ts';
import { DateTimeItemElement } from '../../../models/contentItemElements/DateTimeItemElement.ts';
import { DateTimeInputs } from '../../ContentItemEditing/components/elements/dateTime/DateTimeInputs.tsx';
import { DefaultValueStatus } from '../../ContentItemEditing/components/elements/subComponents/limitInfoMessages/DefaultValueStatus.tsx';
import { hasDefaultValue } from '../../ContentItemEditing/utils/defaultValueUtils.ts';
import { IContentComponentItemElementOwnProps } from '../IContentComponentItemElementOwnProps.type.ts';
import { ContentComponentItemElement } from '../containers/ContentComponentItemElement.tsx';

type DateTimeElementProps = IContentComponentItemElementOwnProps<
  DateTimeItemElement,
  IDateTimeTypeElement
>;

export const ContentComponentDateTimeElement: React.FC<DateTimeElementProps> = ({
  autoFocus,
  contentComponentId,
  disabled,
  elementData,
  onUpdate,
  typeElement,
  validationResultSelectorId,
}) => {
  const datetimePickerRef = useRef<DatetimePickerRefType>(null);

  const isDefaultValueSet = hasDefaultValue(typeElement.defaultValue);
  const parsedValue = parseDatetime(elementData.value);
  const parsedDefaultValue = parseDatetime(typeElement.defaultValue);
  const isCurrentValueDefault = areShallowEqual(parsedValue, parsedDefaultValue);

  const onChange = (dateTime: DateTime, newTimeZoneId: string): void => {
    if (!dateTime.isValid) {
      return;
    }

    const utcDateTimeInNewTimeZone =
      dateTime.value &&
      newTimeZoneId &&
      transferBetweenTimeZonesKeepingLocalTime(
        dateTime.value,
        elementData.displayTimeZone,
        newTimeZoneId,
      );

    onUpdate({
      ...elementData,
      displayTimeZone: newTimeZoneId,
      value: utcDateTimeInNewTimeZone ?? dateTime.value,
      _dateTime: dateTime,
    });
  };

  const focusInputAtTheStart = (): void => {
    datetimePickerRef.current?.focusInputAtTheStart();
  };

  const focusInputAtTheEnd = (): void => {
    datetimePickerRef.current?.focusInputAtTheEnd();
  };

  return (
    <ContentComponentItemElement
      typeElement={typeElement}
      validationResultSelectorId={validationResultSelectorId}
      disabled={disabled}
      contentComponentId={contentComponentId}
      onHeaderClick={focusInputAtTheStart}
      onContentClick={focusInputAtTheEnd}
    >
      <DateTimeInputs
        autoFocus={autoFocus}
        disabled={disabled}
        onChange={onChange}
        elementData={elementData}
        datetimePickerRef={datetimePickerRef}
      />
      <DefaultValueStatus
        isStatusRendered={isDefaultValueSet && !disabled}
        isValueDefault={isCurrentValueDefault}
      />
    </ContentComponentItemElement>
  );
};

ContentComponentDateTimeElement.displayName = 'ContentComponentDateTimeElement';
