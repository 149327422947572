import { useMemo } from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { loadSelectedContentItemUsage } from '../features/ContentItemInventory/actions/thunkContentItemInventoryActions.ts';

export const useSelectedContentItemUsedIn = () => {
  const shouldLoadItemUsages = useSelector(
    (s) => s.contentApp.listingUi.itemsSelection.selectedItemIds.length === 1,
  );
  const contentItemUsages = useSelector((s) => s.data.listingContentItems.contentItemUsages);
  const rawSelectedItemIds = useSelector(
    (s) => s.contentApp.listingUi.itemsSelection.selectedItemIds,
  );

  const selectedItemIds = useMemo(() => new Set(rawSelectedItemIds), [rawSelectedItemIds]);

  const [isLoadUsageThunkDone] = useThunkPromise(loadSelectedContentItemUsage, {
    canRun: shouldLoadItemUsages,
  });

  return {
    isLoading: shouldLoadItemUsages && !isLoadUsageThunkDone,
    contentItemUsages,
    selectedItemIds,
  };
};
