import { useLayoutEffect } from 'react';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import {
  SearchScope,
  emptyListingFilter,
} from '../../../applications/contentInventory/content/models/filter/IListingFilter.ts';
import { isAiSearchScope } from '../utils/isAiSearchScope.ts';

export const useAiSearchScope = (
  aiSearchEnabled: boolean,
  scope: ReadonlySet<SearchScope>,
  changeScope: (newScope: ReadonlySet<SearchScope>) => void,
): ReadonlySet<SearchScope> => {
  const isAiSearch = useSelector((s) => isAiSearchScope(s.contentApp.listingUi.filter.searchScope));

  const switchToStandardSearch = !aiSearchEnabled && isAiSearch;
  useLayoutEffect(() => {
    // The selected scope can be AI search if it was used previously and the feature was disabled later
    // We ensure automatic switch to a standard one when that happens to keep the app in a consistent state
    if (switchToStandardSearch) {
      changeScope(emptyListingFilter.searchScope);
    }
  }, [changeScope, switchToStandardSearch]);

  const displayScope = !aiSearchEnabled && isAiSearch ? emptyListingFilter.searchScope : scope;

  return displayScope;
};
