import { Action } from '../../../../@types/Action.type.ts';
import { Subscription_BillingHistoryItems_Loaded } from '../../../../applications/subscriptionManagement/BillingHistory/constants/actionTypes.ts';
import { IBillingHistoryItem } from '../../../../applications/subscriptionManagement/BillingHistory/models/BillingHistoryItem.ts';
import { Subscription_SelectSubscription } from '../../../../applications/subscriptionManagement/shared/constants/subscriptionManagementActionTypes.ts';

export const billingHistoryItems = (
  state: ReadonlyArray<IBillingHistoryItem> = [],
  action: Action,
): ReadonlyArray<IBillingHistoryItem> => {
  switch (action.type) {
    case Subscription_SelectSubscription:
      return [];

    case Subscription_BillingHistoryItems_Loaded:
      return action.payload.billingHistoryItems;

    default:
      return state;
  }
};
