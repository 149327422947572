import { createGuid } from '@kontent-ai/utils';
import { trackUserEventWithData } from '../../../_shared/actions/thunks/trackUserEvent.ts';
import { repositoryCollection } from '../../../_shared/repositories/repositories.ts';
import { ensureLoadedListingItems } from '../../itemEditor/features/LoadedItems/actions/thunkLoadedItemsActions.ts';
import { getItemElementServerModelConverter } from '../../itemEditor/utils/getItemElementDataConverters.ts';
import { importFromPastedText } from '../plugins/clipboard/api/editorClipboardUtils.ts';
import { createConvertRichTextToClipboardHtml } from '../plugins/clipboard/thunks/createConvertRichTextToClipboardHtml.ts';
import {
  PasteContentToRichTextActionWithTrackingPropsType,
  createPasteContentToRichTextAction,
} from '../plugins/clipboard/thunks/pasteContentToRichText.ts';
import { createSetRichTextClipboardAction } from '../plugins/clipboard/thunks/setRichTextClipboard.ts';
import { createConvertContentComponentToContentItemVariant } from '../plugins/contentComponents/thunks/convertContentComponentToContentItemVariant.ts';

const { contentItemRepository } = repositoryCollection;

export const pasteContentToRichTextGuidelines = createPasteContentToRichTextAction({
  importFromPastedText,
})(null, null);

export const pasteContentToRichText: PasteContentToRichTextActionWithTrackingPropsType =
  createPasteContentToRichTextAction({
    importFromPastedText,
  });

export const convertContentComponentToContentItemVariant =
  createConvertContentComponentToContentItemVariant({
    contentItemRepository,
    ensureLoadedListingItems,
    trackUserEventWithData,
    createGuid,
  });

export const convertRichTextToClipboardHtml = createConvertRichTextToClipboardHtml({
  getItemElementServerModelConverter,
});

export const setRichTextClipboard = createSetRichTextClipboardAction({
  convertRichTextToClipboardHtml,
});
