import { InvariantException } from '@kontent-ai/errors';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  ContentItemRevisionCompareRoute,
  ContentItemRevisionCompareRouteParams,
  ContentItemRoute,
  ContentItemRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  buildPath,
  matchPath,
  parseContentItemIds,
} from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { RevisionTimeline as RevisionTimelineComponent } from '../components/RevisionTimeline.tsx';
import { getTimelineItemPathsMap } from '../selectors/getTimelineItemPathsMap.ts';

export const RevisionTimeline: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const match = matchPath<ContentItemRouteParams<string>>(location.pathname, ContentItemRoute);
  if (!match) {
    throw InvariantException(
      'RevisionTimeline is rendered within different route than ContentItemRoute',
    );
  }

  const selectedRevision = useSelector((s) => s.contentApp.selectedRevision);
  const paths = useSelector((s) => getTimelineItemPathsMap(s.contentApp.entryTimeline, match));
  const entryTimeline = useSelector((s) => s.contentApp.entryTimeline);

  const compareWithPrevious = () => {
    if (selectedRevision && entryTimeline) {
      const previousIndex =
        entryTimeline.findLastIndex((item) => !!item && selectedRevision.itemId === item.itemId) +
        1;
      const originalIndex =
        previousIndex < entryTimeline.length ? previousIndex : entryTimeline.length - 1;
      const originalItem = entryTimeline[originalIndex];

      const comparePath = buildPath<ContentItemRevisionCompareRouteParams<UuidArray>>(
        ContentItemRevisionCompareRoute,
        {
          app: match.app,
          projectId: match.projectId,
          variantId: match.variantId,
          spaceId: match.spaceId,
          contentItemIds: parseContentItemIds(match.contentItemIds),
          timelineItemId: selectedRevision.itemId,
          compareItemId: originalItem?.itemId || '',
        },
      );
      history.push(comparePath);
    }
  };

  return (
    <RevisionTimelineComponent
      paths={paths}
      isInitialized={!!entryTimeline}
      onCompareWithPrevious={compareWithPrevious}
    />
  );
};

RevisionTimeline.displayName = 'RevisionTimeline';
