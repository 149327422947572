import { useCallback } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { CanUpdateContent } from '../../editorCore/types/Editor.base.type.ts';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { Apply, PluginState, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';

type ReadonlyPluginProps = {
  // We don't want the disabled property to be settable from outside anymore
  readonly disabled?: never;
  readonly isLocked?: never;
  readonly isViewOnly?: boolean;
  readonly readOnly?: boolean;
};

export type ReadonlyPlugin = DraftJsEditorPlugin<None, ReadonlyPluginProps>;

const canUpdateContent: Decorator<CanUpdateContent> = () => () => false;

export const ReadonlyPlugin: PluginComponent<ReadonlyPlugin> = (props) => {
  const { readOnly } = props;

  const render: Decorator<Render<ReadonlyPlugin>> = useCallback(
    (baseRender) => (state) => {
      const newState: PluginState<ReadonlyPlugin> = {
        ...state,
        editorProps: {
          ...state.editorProps,
          readOnly: state.editorProps.readOnly || readOnly,
        },
      };

      return baseRender(newState);
    },
    [readOnly],
  );

  const apply: Apply<ReadonlyPlugin> = useCallback(
    (state) => {
      state.canUpdateContent.decorate(canUpdateContent);
      state.render.decorate(render);
      return {};
    },
    [render],
  );

  const propsWithDisabled = {
    ...props,
    disabled: true,
  };

  return useEditorWithPlugin(propsWithDisabled, { apply });
};
