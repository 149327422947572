import { Collection } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { ILoadListingItemsAction } from './loadListingItems.ts';

export type IEnsureLoadedListingItemsAction = (contentItemIds: ReadonlyArray<Uuid>) => ThunkPromise;

interface IDeps {
  readonly loadListingItems: ILoadListingItemsAction;
}

export const createEnsureLoadedListingItemsAction =
  (dependencies: IDeps): IEnsureLoadedListingItemsAction =>
  (ids: ReadonlyArray<Uuid>): ThunkPromise =>
  async (dispatch, getState) => {
    const uniqueIds = new Set(ids);
    const alreadyLoaded = getState().data.listingContentItems.byId;
    const loadIds = Collection.getValues(uniqueIds).filter((id) => !alreadyLoaded.has(id));

    await dispatch(dependencies.loadListingItems(loadIds));
  };
