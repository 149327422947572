import { initializePeopleListingForProject } from '../../../applications/environmentSettings/users/actions/thunkUsersActions.ts';
import { IProjectListingUser } from '../../../applications/projects/models/ProjectListingUser.ts';
import { getCurrentProject } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { AvailableSubscriptionAdmins as AvailableSubscriptionAdminsComponent } from '../../components/infos/AvailableSubscriptionAdmins.tsx';
import { useSelector } from '../../hooks/useSelector.ts';
import { useThunkPromise } from '../../hooks/useThunkPromise.ts';

const MAX_ADMINS_IN_DIALOG = 10;

type Props = {
  readonly onClose: () => void;
};

export const AvailableSubscriptionAdmins = ({ onClose }: Props) => {
  const projectId = useSelector((state) => getCurrentProject(state).projectId);
  const admins = useSelector<ReadonlyArray<IProjectListingUser> | null>((state) => {
    const projectDetail = state.data.projects.byId.get(projectId);
    return projectDetail
      ? projectDetail.projectManagers
          .filter((manager: IProjectListingUser) =>
            projectDetail.subscriptionAdmins.includes(manager.userId),
          )
          .slice(0, MAX_ADMINS_IN_DIALOG)
      : null;
  });

  useThunkPromise(initializePeopleListingForProject, projectId);

  return <AvailableSubscriptionAdminsComponent admins={admins} onClose={onClose} />;
};
