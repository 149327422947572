import { Action } from '../../../../../@types/Action.type.ts';
import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { IContentGroup } from '../../../../contentInventory/content/models/contentTypeElements/types/ContentGroup.ts';
import { IBaseTypeElementData } from '../../../shared/models/elements/types/TypeElementData.ts';

interface IDeps {
  readonly contentGroupDeleted: (id: Uuid) => Action;
  readonly removeTypeElementWithDependencies: (typeElementId: Uuid) => ThunkFunction;
  readonly selectedContentGroupChanged: (id: Uuid | null) => Action;
  readonly typeElementUpdated: (updatedTypeElement: IBaseTypeElementData) => Action;
}

export const createDeleteContentGroupAction =
  (deps: IDeps) =>
  (contentGroupId: Uuid): ThunkFunction =>
  (dispatch, getState) => {
    const {
      editedType: { contentGroups, typeElements },
    } = getState().contentModelsApp.typeEditor;

    const deletedGroupIndex = contentGroups.findIndex(
      (group: IContentGroup) => group.id === contentGroupId,
    );
    const isLastGroup = contentGroups.length === 1;

    dispatch(deps.contentGroupDeleted(contentGroupId));

    if (isLastGroup) {
      typeElements
        .map((elementData) => ({
          ...elementData,
          contentGroupId: null,
        }))
        .forEach((elementData) => dispatch(deps.typeElementUpdated(elementData)));

      dispatch(deps.selectedContentGroupChanged(null));
    } else {
      typeElements
        .filter((el) => el.contentGroupId === contentGroupId)
        .forEach((el) => dispatch(deps.removeTypeElementWithDependencies(el.elementId)));

      const groupsAfterDelete = getState().contentModelsApp.typeEditor.editedType.contentGroups;
      const lastIndex = groupsAfterDelete.length - 1;
      const indexToSelect = deletedGroupIndex <= lastIndex ? deletedGroupIndex : lastIndex;
      const newSelectedGroup = groupsAfterDelete[indexToSelect];
      dispatch(deps.selectedContentGroupChanged(newSelectedGroup?.id ?? null));
    }
  };
