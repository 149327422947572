import { InvariantException } from '@kontent-ai/errors';
import { assert } from '@kontent-ai/utils';
import { ITaxonomyTermServerModel } from '../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';

export interface ITaxonomyTerm {
  readonly id: Uuid;
  readonly name: string;
  readonly codename: string;
  readonly childIds: ReadonlyArray<Uuid>;
  readonly lastModified: DateTimeStamp;
  readonly lastModifiedBy: UserId;
}

export const emptyTaxonomyTerm: ITaxonomyTerm = {
  id: '',
  name: '',
  codename: '',
  childIds: [],
  lastModified: '',
  lastModifiedBy: '',
};

export function createTaxonomyTermDomainModel(
  taxonomyServerModel: ITaxonomyTermServerModel,
  saveChildren?: (childNodes: ReadonlyArray<ITaxonomyTerm>) => void,
): ITaxonomyTerm {
  assert(!!taxonomyServerModel, () => 'Taxonomy term server model is falsy');

  const serverChildNodes = taxonomyServerModel.children || [];

  if (saveChildren) {
    const childNodeArray = serverChildNodes.map((child: ITaxonomyTermServerModel) =>
      createTaxonomyTermDomainModel(child, saveChildren),
    );
    saveChildren(childNodeArray);
  }

  return {
    id: taxonomyServerModel.id ?? emptyTaxonomyTerm.id,
    name: taxonomyServerModel.name ?? emptyTaxonomyTerm.name,
    codename: taxonomyServerModel.codeName ?? emptyTaxonomyTerm.codename,
    childIds: serverChildNodes.map((child) => child.id),
    lastModified: taxonomyServerModel.lastModified ?? emptyTaxonomyTerm.lastModified,
    lastModifiedBy: taxonomyServerModel.lastModifiedBy ?? emptyTaxonomyTerm.lastModifiedBy,
  };
}

export function createTaxonomyTermServerModel(
  currentTermId: Uuid,
  allTerms: ReadonlyMap<Uuid, ITaxonomyTerm>,
): ITaxonomyTermServerModel {
  const currentTerm = allTerms.get(currentTermId);
  if (!currentTerm) {
    throw InvariantException(
      `Cannot convert term with id ${currentTermId} to server model as there is no term with such id.`,
    );
  }

  const children = currentTerm.childIds.map((id) => createTaxonomyTermServerModel(id, allTerms));
  return {
    id: currentTerm.id,
    name: currentTerm.name,
    codeName: currentTerm.codename,
    children,
    lastModified: currentTerm.lastModified,
    lastModifiedBy: currentTerm.lastModifiedBy,
  };
}
