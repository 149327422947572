import { memoize } from '@kontent-ai/memoization';
import { ContentState } from 'draft-js';
import Immutable from 'immutable';
import { ensureEditorStateInRichTextElements } from '../../../contentInventory/content/utils/itemReferences/ensureEditorStateInRichTextElements.ts';
import { getContentComponentIds } from '../../../richText/plugins/contentComponents/api/editorContentComponentUtils.ts';
import { getImageAssetReferences } from '../../../richText/plugins/images/api/editorImageUtils.ts';
import { getModularContentItemIds } from '../../../richText/plugins/linkedItems/api/editorModularUtils.ts';
import {
  getAllContentLinkIds,
  getLinkedAssetIds,
} from '../../../richText/plugins/links/api/editorLinkUtils.ts';
import { IContentComponent } from '../../models/contentItem/ContentComponent.ts';
import {
  AssetReference,
  createAssetReferenceMemoized,
} from '../../models/contentItemElements/AssetItemElement.ts';
import { ICompiledContentItemElementData } from '../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { IRichTextItemElement } from '../../models/contentItemElements/RichTextItemElement.ts';
import {
  isAssetElement,
  isModularContentElement,
  isRichTextElement,
} from '../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { ILinkedItemsElement } from '../../models/contentItemElements/modularItems/ILinkedItemsElement.ts';

export type IGetReferencesFromElements<TReference = Uuid> = (
  elements: ReadonlyArray<ICompiledContentItemElementData>,
  contentComponents?: ReadonlyMap<Uuid, IContentComponent>,
) => Immutable.Set<TReference>;

export type IGetItemIdsFromElements = (
  elements: ReadonlyArray<ICompiledContentItemElementData>,
  referenceType: ReferenceType,
  contentComponents?: ReadonlyMap<Uuid, IContentComponent>,
) => Immutable.Set<Uuid>;

export type IGetReferencesFromContentComponent<TReference = Uuid> = (
  contentComponentId: Uuid,
) => Immutable.Set<TReference>;

const EmptyIds = Immutable.Set.of<Uuid>();

const createAggregateReferencesMemoized = <TReference = Uuid>() =>
  memoize.weak((...referenceChunks: ReadonlyArray<Immutable.Set<TReference>>) =>
    Immutable.OrderedSet<TReference>(referenceChunks.flatMap((references) => references.toArray())),
  );

const aggregateIdReferencesMemoized = createAggregateReferencesMemoized<Uuid>();

// CONTENT ITEMS
export enum ReferenceType {
  ModularItemsAndTextLinks = 'TextLinksAndModularItems',
  ModularItems = 'ModularItems',
}

export const getReferencedContentItemIds: IGetItemIdsFromElements = memoize.maxOne(
  (
    elements: ReadonlyArray<ICompiledContentItemElementData>,
    referenceType: ReferenceType,
    contentComponents?: ReadonlyMap<Uuid, IContentComponent>,
  ): Immutable.Set<Uuid> => {
    const preparedElements = ensureEditorStateInRichTextElements(elements);
    const contentItemIdsPerElement = preparedElements
      .map((element) =>
        getReferencedContentItemIdsInElement(element, referenceType, contentComponents),
      )
      .filter((ids) => !ids.isEmpty());

    const allRelatedItemIds = aggregateIdReferencesMemoized(...contentItemIdsPerElement);
    return allRelatedItemIds;
  },
);

const getReferencedContentItemIdsInModularContent = memoize.weak(
  (element: ILinkedItemsElement): Immutable.Set<Uuid> => {
    return Immutable.Set.of<Uuid>(...element.value);
  },
);

function getReferencedContentItemIdsFromContentStateRoot(
  content: ContentState,
  referenceType: ReferenceType,
): Immutable.Set<Uuid> {
  const modularContentItemIds = getModularContentItemIds(content);
  if (referenceType === ReferenceType.ModularItems) {
    return Immutable.Set(modularContentItemIds);
  }

  const linkIds = getAllContentLinkIds(content);
  return Immutable.Set.of(...modularContentItemIds, ...linkIds);
}

export type IGetReferencedContentItemIdsInContentState = (
  content: ContentState,
  referenceType: ReferenceType,
  contentComponents: ReadonlyMap<Uuid, IContentComponent>,
) => Immutable.Set<Uuid>;

export const getReferencedContentItemIdsInContentState: IGetReferencedContentItemIdsInContentState =
  (content, referenceType, contentComponents): Immutable.Set<Uuid> => {
    const rootReferencedIds = getReferencedContentItemIdsFromContentStateRoot(
      content,
      referenceType,
    );
    const contentComponentsReferencedIds = getReferencesFromContentComponents(
      content,
      (contentComponentId) =>
        getReferencedContentItemIds(
          contentComponents.get(contentComponentId)?.elements ?? [],
          referenceType,
          contentComponents,
        ),
      aggregateIdReferencesMemoized,
    );

    return contentComponentsReferencedIds.union(rootReferencedIds);
  };

const getReferencedContentItemIdsInRichText = memoize.weak(
  (
    element: IRichTextItemElement,
    referenceType: ReferenceType,
    contentComponents: ReadonlyMap<Uuid, IContentComponent>,
  ): Immutable.Set<Uuid> => {
    const content = element._editorState.getCurrentContent();

    return getReferencedContentItemIdsInContentState(
      content,
      referenceType,
      contentComponents ?? element.contentComponents,
    );
  },
);

const getReferencedContentItemIdsInRichTextWithoutNested = (
  element: IRichTextItemElement,
): Immutable.Set<Uuid> => {
  const content = element._editorState.getCurrentContent();

  return getReferencedContentItemIdsFromContentStateRoot(
    content,
    ReferenceType.ModularItemsAndTextLinks,
  );
};

export function getReferencedContentItemIdsInElement(
  element: ICompiledContentItemElementData,
  referenceType: ReferenceType,
  contentComponents?: ReadonlyMap<Uuid, IContentComponent>,
): Immutable.Set<Uuid> {
  if (isRichTextElement(element)) {
    return getReferencedContentItemIdsInRichText(
      element,
      referenceType,
      contentComponents ?? element.contentComponents,
    );
  }
  if (isModularContentElement(element)) {
    return getReferencedContentItemIdsInModularContent(element);
  }
  return EmptyIds;
}

export function getReferencedContentItemIdsInElementWithoutNested(
  element: ICompiledContentItemElementData,
): Immutable.Set<Uuid> {
  if (isRichTextElement(element)) {
    return getReferencedContentItemIdsInRichTextWithoutNested(element);
  }

  if (isModularContentElement(element)) {
    return getReferencedContentItemIdsInModularContent(element);
  }

  return EmptyIds;
}

// CONTENT TYPES
export const getUsedContentTypeIds: IGetReferencesFromElements = (
  elements: ReadonlyArray<ICompiledContentItemElementData>,
  contentComponents?: ReadonlyMap<Uuid, IContentComponent>,
): Immutable.Set<Uuid> => {
  const preparedElements = ensureEditorStateInRichTextElements(elements);
  const contentTypeIdsPerElement = preparedElements.map((element) =>
    getUsedContentTypeIdsInElement(element, contentComponents),
  );

  const allRelatedContentTypeIds = aggregateIdReferencesMemoized(...contentTypeIdsPerElement);
  return allRelatedContentTypeIds;
};

const getUsedContentTypeIdsFromContentComponent = memoize.weak(
  (
    component: IContentComponent,
    contentComponents: ReadonlyMap<Uuid, IContentComponent>,
  ): Immutable.Set<Uuid> =>
    Immutable.Set.of(component.contentTypeId).union(
      getUsedContentTypeIds(component.elements, contentComponents),
    ),
);

export type IGetUsedContentTypeIdsInContentState = (
  content: ContentState,
  contentComponents: ReadonlyMap<Uuid, IContentComponent>,
) => Immutable.Set<Uuid>;

export const getUsedContentTypeIdsInContentState =
  memoize.weak<IGetUsedContentTypeIdsInContentState>((content, contentComponents) => {
    const contentComponentsReferencedIds = getReferencesFromContentComponents(
      content,
      (contentComponentId) => {
        const contentComponent = contentComponents.get(contentComponentId);
        return contentComponent
          ? getUsedContentTypeIdsFromContentComponent(contentComponent, contentComponents)
          : Immutable.Set();
      },
      aggregateIdReferencesMemoized,
    );
    return contentComponentsReferencedIds;
  });

function getUsedContentTypeIdsInRichText(
  element: IRichTextItemElement,
  contentComponents: ReadonlyMap<Uuid, IContentComponent>,
): Immutable.Set<Uuid> {
  const content = element._editorState.getCurrentContent();

  return getUsedContentTypeIdsInContentState(content, contentComponents);
}

function getUsedContentTypeIdsInElement(
  element: ICompiledContentItemElementData,
  contentComponents?: ReadonlyMap<Uuid, IContentComponent>,
): Immutable.Set<Uuid> {
  if (isRichTextElement(element)) {
    return getUsedContentTypeIdsInRichText(element, contentComponents ?? element.contentComponents);
  }
  return EmptyIds;
}

// ASSETS
const EmptyAssetReferences = Immutable.Set.of<AssetReference>();

const aggregateAssetReferencesMemoized = createAggregateReferencesMemoized<AssetReference>();

export const getAssetReferences: IGetReferencesFromElements<AssetReference> = (
  elements: ReadonlyArray<ICompiledContentItemElementData>,
  contentComponents?: ReadonlyMap<Uuid, IContentComponent>,
): Immutable.Set<AssetReference> => {
  const preparedElements = ensureEditorStateInRichTextElements(elements);
  const assetReferencesPerElement = preparedElements
    .map((element) => getAssetReferencesInElement(element, contentComponents))
    .filter((assetReferences) => !assetReferences.isEmpty());

  const allAssetIds = aggregateAssetReferencesMemoized(...assetReferencesPerElement);
  return allAssetIds;
};

function getAssetReferencesFromContentStateRoot(
  content: ContentState,
): Immutable.Set<AssetReference> {
  const inlineImageAssetReferences = getImageAssetReferences(content);
  const linkedAssetReferences = getLinkedAssetIds(content).map((assetId) =>
    createAssetReferenceMemoized(assetId),
  );

  return Immutable.Set.of(...inlineImageAssetReferences, ...linkedAssetReferences);
}

export type IGetAssetReferencesInContentState = (
  content: ContentState,
  contentComponents: ReadonlyMap<Uuid, IContentComponent>,
) => Immutable.Set<AssetReference>;

export const getAssetReferencesInContentState = memoize.weak<IGetAssetReferencesInContentState>(
  (content, contentComponents) => {
    const rootReferencesIds = getAssetReferencesFromContentStateRoot(content);
    const contentComponentsReferencedIds = getReferencesFromContentComponents(
      content,
      (contentComponentId) =>
        getAssetReferences(
          contentComponents.get(contentComponentId)?.elements ?? [],
          contentComponents,
        ),
      aggregateAssetReferencesMemoized,
    );

    return contentComponentsReferencedIds.union(rootReferencesIds);
  },
);

function getAssetReferencesInRichText(
  element: IRichTextItemElement,
  contentComponents?: ReadonlyMap<Uuid, IContentComponent>,
): Immutable.Set<AssetReference> {
  const content = element._editorState.getCurrentContent();

  return getAssetReferencesInContentState(content, contentComponents ?? element.contentComponents);
}

function getAssetReferencesInRichTextWithoutNested(
  element: IRichTextItemElement,
): Immutable.Set<AssetReference> {
  const content = element._editorState.getCurrentContent();

  return getAssetReferencesFromContentStateRoot(content);
}

function getReferencesFromContentComponents<TReference = Uuid>(
  content: ContentState,
  getIdsFromContentComponent: IGetReferencesFromContentComponent<TReference>,
  aggregateReferences: (
    ...references: Array<Immutable.Set<TReference>>
  ) => Immutable.Set<TReference>,
): Immutable.Set<TReference> {
  const idsPerComponent = getContentComponentIds(content).map((contentComponentId) =>
    getIdsFromContentComponent(contentComponentId),
  );
  if (!idsPerComponent.length) {
    return Immutable.Set<TReference>();
  }

  const allReferencedIds = aggregateReferences(...idsPerComponent);
  return allReferencedIds;
}

export function getAssetReferencesInElement(
  element: ICompiledContentItemElementData,
  contentComponents?: ReadonlyMap<Uuid, IContentComponent>,
): Immutable.Set<AssetReference> {
  if (isRichTextElement(element)) {
    const referencedAssetIds = getAssetReferencesInRichText(element, contentComponents);
    return referencedAssetIds;
  }

  if (isAssetElement(element)) {
    const assetElement = element;
    return assetElement.value.valueSeq().toSet();
  }

  return EmptyAssetReferences;
}

export function getAssetReferencesInElementWithoutNested(
  element: ICompiledContentItemElementData,
): Immutable.Set<AssetReference> {
  if (isRichTextElement(element)) {
    const referencedAssetIds = getAssetReferencesInRichTextWithoutNested(element);
    return referencedAssetIds;
  }

  if (isAssetElement(element)) {
    const assetElement = element;
    return assetElement.value.valueSeq().toSet();
  }

  return EmptyAssetReferences;
}
