import { isAiTranslationsEnabled } from '../../../../../../_shared/selectors/aiSelectors.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import { getAllActiveLanguagesForCurrentUserForCollection } from '../../../../../../data/reducers/languages/selectors/getLanguages.ts';
import { TypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import {
  isRichTextTypeElement,
  isTextTypeElement,
} from '../../../../../contentModels/shared/types/typeElementTypeGuards.ts';

export const shouldShowAiTranslateButton = (s: IStore, typeElement: TypeElement): boolean => {
  const {
    sharedApp: { currentProjectId },
    contentApp: { editedContentItem },
    data: { languages, user },
  } = s;

  const collectionId = editedContentItem?.collectionId ?? null;
  const activeLanguages = getAllActiveLanguagesForCurrentUserForCollection(
    user,
    currentProjectId,
    collectionId,
    languages,
    Capability.ViewContent,
  );
  const hasMoreThanOneActiveLanguage = activeLanguages.count() > 1;

  const isTranslationEnabled = isAiTranslationsEnabled(s);
  const isElementTranslatable =
    isTextTypeElement(typeElement) || isRichTextTypeElement(typeElement);

  return (
    isTranslationEnabled &&
    isElementTranslatable &&
    hasMoreThanOneActiveLanguage &&
    !typeElement.isNonLocalizable
  );
};
