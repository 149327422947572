import { usePrevious } from '@kontent-ai/hooks';
import React, {
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { UnsupportedAssetFileTypeModal } from '../../../applications/contentInventory/assets/components/AssetListing/UnsupportedAssetFileTypeModal.tsx';
import { CancelScheduleUnpublishConfirmationDialog } from '../../../applications/contentInventory/content/containers/cancelConfirmation/CancelScheduleUnpublishConfirmationDialog.tsx';
import { ContentItemsBulkDeleteConfirmationDialog } from '../../../applications/contentInventory/content/features/ContentItemInventory/containers/ContentItemsBulkDeleteConfirmationDialog.tsx';
import { MoveItemsToArchivedStepConfirmationDialog } from '../../../applications/contentInventory/content/features/ContentItemInventory/containers/MoveItemsToArchivedStepConfirmationDialog.tsx';
import { ChangeDefaultRootConfirmationDialog } from '../../../applications/contentInventory/relations/containers/ChangeDefaultRootConfirmationDialog.tsx';
import { ContentTypeUsedAsComponentItemListingModal } from '../../../applications/contentModels/contentTypes/containers/ContentTypeUsedAsComponentItemListingModal.tsx';
import { ContentModelingSurveyModal } from '../../../applications/contentModels/shared/containers/ContentModelingSurveyModal.tsx';
import { TermRemoveConfirmationDialog } from '../../../applications/contentModels/taxonomy/containers/TermRemoveConfirmationDialog.tsx';
import { CascadePublishFailedItemsModal } from '../../../applications/itemEditor/features/CascadePublish/containers/CascadePublishFailedItemsModal.tsx';
import { CascadePublishModal } from '../../../applications/itemEditor/features/CascadePublish/containers/CascadePublishModal.tsx';
import { CascadeScheduleFailedItemsModal } from '../../../applications/itemEditor/features/CascadePublish/containers/CascadeScheduleFailedItemsModal.tsx';
import { CascadeScheduleModal } from '../../../applications/itemEditor/features/CascadePublish/containers/CascadeScheduleModal.tsx';
import { ChangeWorkflowDialog } from '../../../applications/itemEditor/features/ChangeWorkflowDialog/containers/ChangeWorkflowDialog.tsx';
import { CascadeUnpublishConfirmationDialog } from '../../../applications/itemEditor/features/ContentItemEditing/containers/confirmationDialogs/CascadeUnpublishConfirmationDialog.tsx';
import { ContentItemDeleteConfirmationDialog } from '../../../applications/itemEditor/features/ContentItemEditing/containers/confirmationDialogs/ContentItemDeleteConfirmationDialog.tsx';
import { DiscardNewVersionConfirmationDialog } from '../../../applications/itemEditor/features/ContentItemEditing/containers/confirmationDialogs/DiscardNewVersionConfirmationDialog.tsx';
import { MoveItemToArchivedStepConfirmationDialog } from '../../../applications/itemEditor/features/ContentItemEditing/containers/confirmationDialogs/MoveItemToArchivedStepConfirmationDialog.tsx';
import { ContributorsFormDialog } from '../../../applications/itemEditor/features/ContentItemEditing/containers/details/Contributors/ContributorsFormDialog.tsx';
import { DueDateFormDialog } from '../../../applications/itemEditor/features/ContentItemEditing/containers/details/DueDate/DueDateFormDialog.tsx';
import { NoteFormDialog } from '../../../applications/itemEditor/features/ContentItemEditing/containers/details/Note/NoteFormDialog.tsx';
import { AddTaskFormDialog } from '../../../applications/itemEditor/features/ContentItemEditing/containers/details/Tasks/AddTaskFormDialog.tsx';
import { EditTaskFormDialog } from '../../../applications/itemEditor/features/ContentItemEditing/containers/details/Tasks/EditTaskFormDialog.tsx';
import { CodenameDialog } from '../../../applications/itemEditor/features/ContentItemEditing/containers/editingActions/CodenameDialog.tsx';
import { NotSetupPreviewDialog } from '../../../applications/itemEditor/features/ContentItemEditing/containers/editingActions/actionSubmenuOptions/NotSetupPreviewDialog.tsx';
import { DuplicateToCollectionDialog } from '../../../applications/itemEditor/features/DuplicateToCollection/containers/DuplicateToCollectionDialog.tsx';
import { MoveItemToCollectionDialog } from '../../../applications/itemEditor/features/MoveToCollectionDialog/containers/MoveItemToCollectionDialog.tsx';
import { NewContentItemDialog } from '../../../applications/itemEditor/features/NewContentItem/containers/NewContentItemDialogWrapper.tsx';
import { NewVariantWorkflowSelectionDialog } from '../../../applications/itemEditor/features/NewVariantWorkflowSelectionDialog/containers/NewVariantWorkflowSelectionDialog.tsx';
import { ConfigureContentTypePreviewUrlsDialog } from '../../../applications/previewConfiguration/components/ConfigureContentTypePreviewUrlsDialog.tsx';
import { RevokeApiKeyDialog } from '../../../applications/projectSettings/apiKeys/containers/ApiKeyDetail/RevokeApiKeyDialog.tsx';
import { ApiKeyRegenerationDialog } from '../../../applications/projectSettings/apiKeys/containers/ApiKeyRegenerationDialog.tsx';
import { SubscriptionKeyGenerationDialog } from '../../../applications/subscriptionManagement/ApiKeys/containers/SubscriptionKeyGenerationDialog.tsx';
import { ModalDialogType } from '../../constants/modalDialogType.ts';
import { AiFeedbackDialog } from '../../containers/AiSurvey/AiFeedbackDialog.tsx';
import { ThanksForFeedbackModal } from '../../containers/FeedbackModal/ThanksForFeedbackModal.tsx';
import { HelpUsImproveSurveyDialog } from '../../containers/HelpUsImproveSurvey/HelpUsImproveSurveyDialog.tsx';
import { RestoreRevisionConfirmationDialog } from '../../containers/StatusBar/revisions/RestoreRevisionConfirmationDialog.tsx';
import { ChangeWorkflowStepEditingModal } from '../../features/ChangeWorkflowStepModal/containers/ChangeWorkflowStepEditingModal.tsx';
import { ChangeWorkflowStepListingModal } from '../../features/ChangeWorkflowStepModal/containers/ChangeWorkflowStepListingModal.tsx';
import { SendYourFeedbackDialog } from '../SendYourFeedbackModal/SendYourFeedbackDialog.tsx';
import { IAnimatedModalDialogProps } from './IAnimatedModalDialogProps.type.ts';

function useRenderAnimatedDialog(currentModal: ModalDialogType) {
  const previousModal = usePrevious(currentModal);
  const [renderAnimatedDialog, setRenderAnimatedDialog] = useState<AnimatedModals | null>(null);
  useEffect(() => {
    if (!isOldModalDialogType(currentModal) && currentModal !== previousModal) {
      setRenderAnimatedDialog(currentModal);
    }
  }, [currentModal, previousModal]);

  const resetRenderAnimatedDialogOnUnmount = useCallback(
    (element: HTMLDivElement | null) => {
      // on unmount
      if (!element) {
        // skip when switching between 2 animated dialogs
        if (!isOldModalDialogType(currentModal) && currentModal !== previousModal) {
          return;
        }
        setRenderAnimatedDialog(null);
      }
    },
    [currentModal, previousModal],
  );
  return {
    renderAnimatedDialog,
    resetRenderAnimatedDialogOnUnmount,
  };
}

export interface IModalDialogSelectorProps {
  readonly currentModal: ModalDialogType;
}

const NoDialog: React.FC = () => null;

type AnimatedModals =
  | ModalDialogType.ApiKeyRegenerationDialog
  | ModalDialogType.BulkItemsDeleteConfirmationDialog
  | ModalDialogType.CancelItemScheduledUnpublishConfirmationDialog
  | ModalDialogType.CascadePublishDialog
  | ModalDialogType.CascadePublishFailedItemsDialog
  | ModalDialogType.CascadeScheduleDialog
  | ModalDialogType.CascadeScheduleFailedItemsDialog
  | ModalDialogType.CascadeUnpublishConfirmationDialog
  | ModalDialogType.ChangeContentItemCollectionDialog
  | ModalDialogType.ChangeDefaultRootConfirmationDialog
  | ModalDialogType.ChangeWorkflowDialog
  | ModalDialogType.ChangeWorkflowStepDialog
  | ModalDialogType.ChangeWorkflowStepListingDialog
  | ModalDialogType.ConfigureContentTypePreviewUrls
  | ModalDialogType.ContentItemAddTaskDialog
  | ModalDialogType.ContentItemAssignContributorsDialog
  | ModalDialogType.ContentItemDueDateDialog
  | ModalDialogType.ContentItemEditTaskDialog
  | ModalDialogType.ContentItemNoteDialog
  | ModalDialogType.ContentItemCodenameDialog
  | ModalDialogType.ContentItemConfigurePreviewDialog
  | ModalDialogType.DeleteItemConfirmationDialog
  | ModalDialogType.DiscardNewVersionConfirmationDialog
  | ModalDialogType.DuplicateItemWithContent
  | ModalDialogType.DuplicateItemWithoutContent
  | ModalDialogType.MoveItemToArchivedStepConfirmationDialog
  | ModalDialogType.MoveItemsToArchivedStepConfirmationDialog
  | ModalDialogType.NewContentItemDialog
  | ModalDialogType.NewContentItemDialogForWebSpotlight
  | ModalDialogType.RemoveTaxonomyTermConfirmationDialog
  | ModalDialogType.RestoreRevisionConfirmationDialog
  | ModalDialogType.RevokeApiKeyDialog
  | ModalDialogType.SapiKeyGenerationDialog
  | ModalDialogType.SendYourFeedbackDialog;

type OldModals = Exclude<ModalDialogType, AnimatedModals>;

const oldModalDialogsMap: ReadonlyRecord<OldModals, React.ComponentType> = {
  [ModalDialogType.AiFeedbackDialog]: AiFeedbackDialog,
  [ModalDialogType.ContentTypeAsComponentUsageDialog]: ContentTypeUsedAsComponentItemListingModal,
  [ModalDialogType.NewVariantWorkflowSelectionDialog]: NewVariantWorkflowSelectionDialog,
  [ModalDialogType.None]: NoDialog,
  [ModalDialogType.ThanksForFeedbackDialog]: ThanksForFeedbackModal,
  [ModalDialogType.UnsupportedAssetFileType]: UnsupportedAssetFileTypeModal,
  [ModalDialogType.HelpUsImproveSurveyDialog]: HelpUsImproveSurveyDialog,
  [ModalDialogType.ContentModelingSatisfactionSurveyDialog]: ContentModelingSurveyModal,
};

const isOldModalDialogType = (type: ModalDialogType): type is keyof typeof oldModalDialogsMap =>
  (Object.keys(oldModalDialogsMap) as ReadonlyArray<ModalDialogType>).includes(type);

type AnimatedModalComponentWithRef = ForwardRefExoticComponent<
  IAnimatedModalDialogProps & RefAttributes<HTMLDivElement>
>;

const animatedModalDialogsMap: ReadonlyRecord<AnimatedModals, AnimatedModalComponentWithRef> = {
  [ModalDialogType.ApiKeyRegenerationDialog]: ApiKeyRegenerationDialog,
  [ModalDialogType.ContentItemAddTaskDialog]: AddTaskFormDialog,
  [ModalDialogType.ContentItemEditTaskDialog]: EditTaskFormDialog,
  [ModalDialogType.ContentItemNoteDialog]: NoteFormDialog,
  [ModalDialogType.ContentItemAssignContributorsDialog]: ContributorsFormDialog,
  [ModalDialogType.ContentItemDueDateDialog]: DueDateFormDialog,
  [ModalDialogType.ContentItemCodenameDialog]: CodenameDialog,
  [ModalDialogType.ContentItemConfigurePreviewDialog]: NotSetupPreviewDialog,
  [ModalDialogType.ChangeContentItemCollectionDialog]: MoveItemToCollectionDialog,
  [ModalDialogType.ChangeWorkflowDialog]: ChangeWorkflowDialog,
  [ModalDialogType.ChangeWorkflowStepDialog]: ChangeWorkflowStepEditingModal,
  [ModalDialogType.ChangeWorkflowStepListingDialog]: ChangeWorkflowStepListingModal,
  [ModalDialogType.ConfigureContentTypePreviewUrls]: ConfigureContentTypePreviewUrlsDialog,
  [ModalDialogType.NewContentItemDialogForWebSpotlight]: NewContentItemDialog,
  [ModalDialogType.NewContentItemDialog]: NewContentItemDialog,
  [ModalDialogType.RestoreRevisionConfirmationDialog]: RestoreRevisionConfirmationDialog,
  [ModalDialogType.RevokeApiKeyDialog]: RevokeApiKeyDialog,
  [ModalDialogType.SapiKeyGenerationDialog]: SubscriptionKeyGenerationDialog,
  [ModalDialogType.SendYourFeedbackDialog]: SendYourFeedbackDialog,
  [ModalDialogType.BulkItemsDeleteConfirmationDialog]: ContentItemsBulkDeleteConfirmationDialog,
  [ModalDialogType.CancelItemScheduledUnpublishConfirmationDialog]:
    CancelScheduleUnpublishConfirmationDialog,
  [ModalDialogType.CascadeUnpublishConfirmationDialog]: CascadeUnpublishConfirmationDialog,
  [ModalDialogType.ChangeDefaultRootConfirmationDialog]: ChangeDefaultRootConfirmationDialog,
  [ModalDialogType.DeleteItemConfirmationDialog]: ContentItemDeleteConfirmationDialog,
  [ModalDialogType.DiscardNewVersionConfirmationDialog]: DiscardNewVersionConfirmationDialog,
  [ModalDialogType.MoveItemToArchivedStepConfirmationDialog]:
    MoveItemToArchivedStepConfirmationDialog,
  [ModalDialogType.MoveItemsToArchivedStepConfirmationDialog]:
    MoveItemsToArchivedStepConfirmationDialog,
  [ModalDialogType.RemoveTaxonomyTermConfirmationDialog]: TermRemoveConfirmationDialog,
  [ModalDialogType.CascadePublishDialog]: CascadePublishModal,
  [ModalDialogType.CascadeScheduleDialog]: CascadeScheduleModal,
  [ModalDialogType.DuplicateItemWithContent]: DuplicateToCollectionDialog,
  [ModalDialogType.DuplicateItemWithoutContent]: DuplicateToCollectionDialog,
  [ModalDialogType.CascadePublishFailedItemsDialog]: CascadePublishFailedItemsModal,
  [ModalDialogType.CascadeScheduleFailedItemsDialog]: CascadeScheduleFailedItemsModal,
};

export const ModalDialogSelector: React.FC<IModalDialogSelectorProps> = ({ currentModal }) => {
  const OldModalDialog = isOldModalDialogType(currentModal)
    ? oldModalDialogsMap[currentModal]
    : NoDialog;

  const { renderAnimatedDialog, resetRenderAnimatedDialogOnUnmount } =
    useRenderAnimatedDialog(currentModal);

  const AnimatedDialog = renderAnimatedDialog && animatedModalDialogsMap[renderAnimatedDialog];

  return (
    <>
      <OldModalDialog />
      {AnimatedDialog && (
        <AnimatedDialog
          isOpen={renderAnimatedDialog === currentModal}
          ref={resetRenderAnimatedDialogOnUnmount}
        />
      )}
    </>
  );
};

ModalDialogSelector.displayName = 'ModalDialogSelector';
