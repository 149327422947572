import { memoize } from '@kontent-ai/memoization';
import { add } from 'date-fns';
import { ICurrentUserInfo } from '../../data/models/user/CurrentUserInfo.ts';
import { IUserProjectInfo } from '../../data/models/user/UserProjectInfo.ts';
import { isProjectTrial } from '../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import {
  parseDatetime,
  shiftByUTCOffsetForward,
} from '../components/DatetimePicker/InternalFiles/datetimeUtils.ts';
import { ProjectType } from '../models/ProjectType.ts';
import { getProjectType } from '../models/utils/quickstartUtils.ts';
import { IStore } from '../stores/IStore.type.ts';
import { isEmployee } from './subscriptionUsageUtils.ts';

function hasAccountLessDaysThan(numberOfDays: number, userInfo: ICurrentUserInfo): boolean {
  const { isValid, value } = parseDatetime(userInfo.createdAt);
  if (!isValid) {
    return false;
  }

  return add(shiftByUTCOffsetForward(value), { days: numberOfDays }) > new Date();
}

export const isAccountLessThan30DaysOld = memoize.maxAge(
  (userInfo: ICurrentUserInfo): boolean => hasAccountLessDaysThan(30, userInfo),
  // Cache for 1 hour, so it can still change if the user account gets older while staying in the browser
  60 * 60 * 1000,
  { maxSize: 1 },
);

export enum QuickstartType {
  None = 'None',
  Quickstart = 'Quickstart',
  Kickstart = 'Kickstart',
}

export function getProjectQuickstartType(state: IStore, project: IUserProjectInfo): QuickstartType {
  const {
    data: { user },
  } = state;

  if (
    !isProjectTrial(state, project.projectId) &&
    !isAccountLessThan30DaysOld(user.info) &&
    !isEmployee(user.info.email)
  ) {
    return QuickstartType.None;
  }

  switch (getProjectType(project, state.sharedApp.projectProperties)) {
    case ProjectType.Kickstart:
      return QuickstartType.Kickstart;
    case ProjectType.DancingGoat:
    case ProjectType.GettingStarted:
      return QuickstartType.Quickstart;
    case ProjectType.UserProject:
      return QuickstartType.None;
  }
}
