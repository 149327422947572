import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Spacing, gridUnit } from '@kontent-ai/component-library/tokens';
import { ReactNode, RefObject, useCallback, useState } from 'react';
import { defaultDropdownTippyOptions } from '../../../../component-library/components/DropDownMenu/DropDownMenuPositioner.tsx';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { DropDownCaptionOption } from '../../../_shared/uiComponents/DropDown/DropDownCaptionOption.tsx';
import { DropDownFrame } from '../../../_shared/uiComponents/DropDown/DropDownFrame.tsx';
import { DropDownOption } from '../../../_shared/uiComponents/DropDown/DropDownOption.tsx';
import { DropDownOptionName } from '../../../_shared/uiComponents/DropDown/DropDownOptionName.tsx';
import { DropDownOptionsGroup } from '../../../_shared/uiComponents/DropDown/DropDownOptionsGroup.tsx';
import { IDropdownTippyOptions } from '../../../_shared/uiComponents/DropDown/dropDownTippyOptions.ts';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isValidUrlInfo } from '../../../_shared/utils/validation/isValidUrlInfo.ts';
import { webSpotlightPreviewAutoRefreshChanged } from '../actions/webSpotlightActions.ts';
import { useWebSpotlight } from '../context/WebSpotlightContext.tsx';
import { isPreviewAutoRefreshFeatureEnabledByUser } from '../selectors/webSpotlightSelectors.ts';
import { WebSpotlightSharePreviewForm } from './WebSpotlightSharePreviewForm.tsx';

enum WebSpotlightActionMenuState {
  None = 'None',
  ShowMenu = 'ShowMenu',
  ShareLink = 'ShareLink',
}

interface IWebSpotlightActionMenuItem {
  readonly dataUiAction: DataUiAction;
  readonly disabled?: boolean;
  readonly disabledTooltipText?: string;
  readonly icon: ReactNode;
  readonly id: string;
  readonly label: string;
  readonly onClick: () => void;
  readonly withSubmenu?: boolean;
}

const actionMenuTippyOptions: IDropdownTippyOptions = {
  ...defaultDropdownTippyOptions,
  placement: 'bottom-end',
};

type Props = {
  readonly onRefreshPreview: () => void;
};

export const WebSpotlightActionMenu = ({ onRefreshPreview }: Props) => {
  const dispatch = useDispatch();
  const {
    editableElementsVisible,
    isSmartLinkSdkInitialized,
    supportedSmartLinkFeatures,
    toggleEditableElements,
  } = useWebSpotlight();

  const isAutoRefreshSupportedBySDK = supportedSmartLinkFeatures?.refreshHandler;
  const isAutoRefreshEnabledByUser = useSelector(isPreviewAutoRefreshFeatureEnabledByUser);
  const isAutoRefreshEnabled = isAutoRefreshSupportedBySDK && isAutoRefreshEnabledByUser;

  const isPreviewIFrameCurrentUrlFeatureSupportedBySDK =
    supportedSmartLinkFeatures?.previewIFrameCurrentUrlHandler;
  const isPreviewValid = useSelector((s) =>
    isValidUrlInfo(s.webSpotlightApp.itemPreviewInfo?.previewUrlInfo),
  );

  const [activeState, setActiveState] = useState(WebSpotlightActionMenuState.None);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const hideMenu = useCallback(() => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  }, [isMenuOpen]);

  const showMainMenu = useCallback(() => {
    setActiveState(WebSpotlightActionMenuState.ShowMenu);
    setIsMenuOpen(true);
  }, []);

  const togglePreviewAutoRefresh = useCallback(() => {
    const isPreviewAutoRefreshEnabled = !isAutoRefreshEnabledByUser;
    dispatch(webSpotlightPreviewAutoRefreshChanged(isPreviewAutoRefreshEnabled));
  }, [isAutoRefreshEnabledByUser]);

  const menuItems: ReadonlyArray<IWebSpotlightActionMenuItem> = [
    {
      dataUiAction: DataUiAction.Share,
      id: 'share-preview',
      label: 'Share preview',
      icon: <Icons.ShareGoogle />,
      onClick: () => setActiveState(WebSpotlightActionMenuState.ShareLink),
      disabled: !isPreviewIFrameCurrentUrlFeatureSupportedBySDK || !isPreviewValid,
      disabledTooltipText: 'Contact your developer to set up sharing preview for you.',
      withSubmenu: true,
    },
    {
      dataUiAction: DataUiAction.ToggleEditableElements,
      id: 'toggle-editable-elements',
      label: editableElementsVisible ? 'Hide editable elements' : 'Show editable elements',
      icon: editableElementsVisible ? <Icons.EyeSlash /> : <Icons.Eye />,
      onClick: () => {
        toggleEditableElements();
        hideMenu();
      },
      disabled: !isSmartLinkSdkInitialized,
      disabledTooltipText: 'Contact your developer to set up editable elements for you.',
    },
    {
      dataUiAction: DataUiAction.ToggleAutorefresh,
      id: 'toggle-autorefresh',
      label: isAutoRefreshEnabled ? 'Disable autorefresh' : 'Enable autorefresh',
      icon: isAutoRefreshEnabled ? <Icons.RotateDoubleRightSlash /> : <Icons.RotateDoubleRightA />,
      onClick: () => {
        togglePreviewAutoRefresh();
        hideMenu();
      },
      disabled: !isAutoRefreshSupportedBySDK,
      disabledTooltipText: 'Contact your developer to set up autorefresh for you.',
    },
    {
      dataUiAction: DataUiAction.Refresh,
      id: 'refresh-review',
      label: 'Refresh manually',
      icon: <Icons.RotateDoubleRight />,
      onClick: () => {
        onRefreshPreview();
        hideMenu();
      },
    },
  ];

  return (
    <DropDownFrame
      renderContent={() => (
        <>
          {activeState === WebSpotlightActionMenuState.ShowMenu && (
            <DropDownOptionsGroup>
              {menuItems.map((item) => (
                <DropDownOption
                  isDisabled={item.disabled}
                  key={item.id}
                  onClick={item.onClick}
                  tooltipText={item.disabled ? item.disabledTooltipText : ''}
                  dataUiAttributes={getDataUiActionAttribute(item.dataUiAction)}
                >
                  <Box display="flex" alignItems="center" minWidth={gridUnit * 23}>
                    <Box marginRight={Spacing.S}>{item.icon}</Box>
                    <DropDownOptionName text={item.label} />
                  </Box>
                  {item.withSubmenu && <Icons.ChevronRight />}
                </DropDownOption>
              ))}
            </DropDownOptionsGroup>
          )}
          {activeState === WebSpotlightActionMenuState.ShareLink && (
            <>
              <DropDownOptionsGroup>
                <DropDownCaptionOption name="Share preview" onClick={showMainMenu} />
              </DropDownOptionsGroup>
              <DropDownOptionsGroup>
                <WebSpotlightSharePreviewForm />
              </DropDownOptionsGroup>
            </>
          )}
        </>
      )}
      renderSelector={(ref: RefObject<HTMLButtonElement>): JSX.Element => (
        <Button
          ref={ref}
          activated={isMenuOpen}
          buttonStyle="tertiary"
          onClick={isMenuOpen ? hideMenu : showMainMenu}
          {...getDataUiActionAttribute(DataUiAction.WebSpotlightPreviewActions)}
        >
          <Button.Icon icon={Icons.Ellipsis} />
          Actions
        </Button>
      )}
      isOptionListVisible={isMenuOpen}
      onClickOutside={hideMenu}
      optionListDataUiAttributes={getDataUiCollectionAttribute(
        DataUiCollection.MoreActionsDropdown,
      )}
      tippyOptions={actionMenuTippyOptions}
    />
  );
};
