import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import {
  Spacing,
  borderWidthDefault,
  colorBorderLowEmphasis,
  px,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import { WebSpotlightPreviewApiLastChangesStatus } from '../../../../../webSpotlight/components/WebSpotlightPreviewApiLastChangesStatus.tsx';
import { useIsPreviewUrlOutdated } from '../../../../../webSpotlight/hooks/useIsPreviewUrlOutdated.ts';
import { SpaceSelector } from '../../containers/contentItemPreview/SpaceSelector.tsx';
import { useWebSpotlightInItemEditing } from '../../context/WebSpotlightInItemEditingContext.tsx';
import { OpenInNewTabButton } from './OpenInNewTabButton.tsx';
import { PreviewActionsMenu } from './PreviewActionsMenu.tsx';
import { RefreshPreviewButton } from './RefreshPreviewButton.tsx';

type Props = Readonly<{
  spaceId: Uuid | null;
  setSpaceId: (newState: Uuid) => void;
}>;

export const ContentItemPreviewActionBar: React.FC<Props> = ({ spaceId, setSpaceId }) => {
  const {
    isAutoRefreshAvailable,
    isAutoRefreshEnabledByUser,
    refreshPreview,
    supportedSmartLinkFeatures,
  } = useWebSpotlightInItemEditing();
  const isPreviewUrlOutdated = useIsPreviewUrlOutdated();

  return (
    <Box
      paddingX={Spacing.L}
      paddingY={Spacing.S}
      css={`border-bottom: ${px(borderWidthDefault)} solid ${colorBorderLowEmphasis}`}
    >
      <Row spacingY={Spacing.S}>
        <Column>
          <SpaceSelector spaceId={spaceId} setSpaceId={setSpaceId} />
        </Column>

        <Column width="fit-content">
          <Row spacingX={Spacing.M} spacingY={Spacing.S}>
            {isAutoRefreshEnabledByUser && !!supportedSmartLinkFeatures?.refreshHandler && (
              <Column css="display: flex;">
                <WebSpotlightPreviewApiLastChangesStatus
                  key={spaceId}
                  onRefreshPreview={() => {
                    refreshPreview({
                      isManualRefresh: true,
                      isPreviewUrlOutdated,
                    });
                  }}
                />
              </Column>
            )}
            <Column>
              <OpenInNewTabButton spaceId={spaceId} />
            </Column>
            {isAutoRefreshAvailable ? (
              <Column>
                <PreviewActionsMenu />
              </Column>
            ) : (
              <Column>
                <RefreshPreviewButton />
              </Column>
            )}
          </Row>
        </Column>
      </Row>
    </Box>
  );
};
