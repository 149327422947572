import { Card } from '@kontent-ai/component-library/Card';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { CreateNewBarItemHidingButtonAnimated } from '../../../_shared/components/BarItems/CreateNewBarItemHidingButtonAnimated.tsx';
import { HtmlSettingsPageTitle } from '../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { Loader } from '../../../_shared/components/Loader.tsx';
import { PageTitle } from '../../../_shared/components/PageTitle.tsx';
import { Warning } from '../../../_shared/components/infos/Warning.tsx';
import { LoadingStatus } from '../../../_shared/models/LoadingStatusEnum.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  IWebhookSetting,
  emptyObject as emptyWebhook,
} from '../../../data/models/webhooks/WebhookSetting.ts';
import { EnvironmentSettingsAppNames } from '../../environmentSettings/root/constants/EnvironmentSettingsAppNames.ts';
import { WebhookItem } from './WebhookItem.tsx';
import { LegacyWebhookQuickTip } from './messages/LegacyWebhookQuickTip.tsx';
import { WebhookEmptyState } from './messages/WebhookEmptyState.tsx';

type Props = {
  readonly createIsEnabled: boolean;
  readonly createWebhook: () => void;
  readonly editedWebhookId: Uuid | null;
  readonly loadingStatus: LoadingStatus;
  readonly newWebhook: IWebhookSetting | undefined;
  readonly webhooks: ReadonlyArray<IWebhookSetting>;
  readonly webhooksWithNoTrigger?: ReadonlyArray<IWebhookSetting>;
};

export const WebhookListing = ({
  createIsEnabled,
  createWebhook,
  editedWebhookId,
  loadingStatus,
  newWebhook,
  webhooks,
  webhooksWithNoTrigger,
}: Props) => {
  const renderWebhookItem = (webhook: IWebhookSetting): JSX.Element => {
    const isEdited = !!editedWebhookId && editedWebhookId === webhook.id;

    return <WebhookItem key={webhook.id} isEdited={isEdited} webhookSetting={webhook} />;
  };

  const getWebhookList = () => {
    const enabledWebhooks = webhooks.filter((webhook) => webhook.enabled);

    const disabledWebhooks = webhooks.filter((webhook) => !webhook.enabled);

    return (
      <>
        {!!webhooksWithNoTrigger?.length && (
          <div className="webhook-listing__misconfigured-webhook-callout">
            <Warning>
              <ul>
                {webhooksWithNoTrigger.map((webhook) => (
                  <li key={webhook.id}>
                    {`The “${webhook.name}” webhook is misconfigured. Add at least one trigger to start receiving notifications.`}
                  </li>
                ))}
              </ul>
            </Warning>
          </div>
        )}
        <ul className="bar-item__list">
          {enabledWebhooks.map(renderWebhookItem)}
          {!!disabledWebhooks.length && (
            <>
              <li className="bar-item__section-title">Disabled</li>
              {disabledWebhooks.map(renderWebhookItem)}
            </>
          )}
          <CreateNewBarItemHidingButtonAnimated
            estimatedMaxHeightWhenExpanded={1200}
            isCreatingAllowed={createIsEnabled}
            isFormDisplayed={!!newWebhook}
            itemName="Webhook"
            onCreateNewClick={createWebhook}
          >
            {renderWebhookItem(newWebhook || emptyWebhook)}
          </CreateNewBarItemHidingButtonAnimated>
        </ul>
      </>
    );
  };

  const getContent = () => {
    if (loadingStatus !== LoadingStatus.Loaded) {
      return <Loader />;
    }

    if (!webhooks.length && !newWebhook) {
      return (
        <div {...getDataUiAppNameAttribute(DataUiAppName.LegacyWebhooks)}>
          <WebhookEmptyState onCreateButtonNewClick={createWebhook} />
        </div>
      );
    }

    return (
      <Stack spacing={Spacing.XL}>
        <PageTitle>{EnvironmentSettingsAppNames.LegacyWebhooks}</PageTitle>
        <Row spacing={Spacing.L}>
          <Column flexFactor={5} flexBasis={600} maxWidth={1000 + Spacing.L}>
            <Card component="section" {...getDataUiAppNameAttribute(DataUiAppName.LegacyWebhooks)}>
              <Card.Body>{getWebhookList()}</Card.Body>
            </Card>
          </Column>
          <Column flexFactor={1} flexBasis={250}>
            <LegacyWebhookQuickTip />
          </Column>
        </Row>
      </Stack>
    );
  };

  return (
    <div className="canvas__inner-section">
      <HtmlSettingsPageTitle settingsAppName={EnvironmentSettingsAppNames.LegacyWebhooks} />
      {getContent()}
    </div>
  );
};
