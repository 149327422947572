import { ItemEditorLayoutStorageKey } from '../_shared/constants/localStorageKeys.ts';
import { StorageParams, getProjectSpecificStorage } from './projectSpecificStorage.ts';

type ItemEditorLayout = {
  readonly isPreviewOpened: boolean;
};

const initialState: ItemEditorLayout = {
  isPreviewOpened: false,
};

const getEditorLayoutFromJS = (rawData: JsonParseOutput): ItemEditorLayout => {
  if (!rawData || typeof rawData !== 'object' || !('isPreviewOpened' in rawData)) {
    return initialState;
  }

  return {
    isPreviewOpened:
      typeof rawData.isPreviewOpened === 'boolean'
        ? rawData.isPreviewOpened
        : initialState.isPreviewOpened,
  };
};

const itemEditorLayoutStorageParams: StorageParams<ItemEditorLayout> = {
  key: ItemEditorLayoutStorageKey,
  initialState,
  fromJS: getEditorLayoutFromJS,
};

export const ItemEditorLayoutStorage = getProjectSpecificStorage<ItemEditorLayout>(
  itemEditorLayoutStorageParams,
);
