import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { MenuItemWithMouseHover } from '@kontent-ai/component-library/MenuItem';
import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import { VerticalMenuSection, useVerticalMenu } from '@kontent-ai/component-library/VerticalMenu';
import { spacingPopupDistance } from '@kontent-ai/component-library/tokens';
import { notNull } from '@kontent-ai/utils';
import React, { useState } from 'react';
import { DropDownMenuControlled } from '../../../../../../../../component-library/components/DropDownMenu/DropDownMenuControlled.tsx';
import { AiTaskProgress } from '../../../../../../../_shared/features/AI/hooks/aiTasks/useAiTaskProgress.ts';
import { AiOperationState } from '../../../../../../../_shared/features/AI/types/AiOperationState.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { TranslateDropdownMenuMaxWidth } from '../constants/uiConstants.ts';

export type ActionStatusProps = {
  readonly availableLanguages: ReadonlyArray<IBaseSelectItem>;
  readonly aiOperationState: AiOperationState;
  readonly otherTasksProgress: AiTaskProgress;
};

type Props = ActionStatusProps & {
  readonly isEmpty: boolean;
  readonly isInContentComponent: boolean;
  readonly perform: (sourceLanguageId: string | null) => void;
  readonly targetLanguageName: string | null;
};

const translateFromContentId = 'translate-from-content';

export const AiTranslateButton: React.FC<Props> = (props) => {
  const { perform } = props;

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const { verticalMenuState, VerticalMenu, verticalMenuProps } = useVerticalMenu(
    createMenuSections(props),
  );

  return (
    <DropDownMenuControlled
      onDropDownVisibilityChange={setIsMenuVisible}
      isDropDownVisible={isMenuVisible}
      tippyOptions={{
        placement: 'bottom-end',
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['top-end'],
              },
            },
          ],
        },
        offset: [0, spacingPopupDistance],
      }}
      renderDropDown={() => (
        <VerticalMenu
          {...verticalMenuProps}
          aria-label="Translate from"
          maxWidth={TranslateDropdownMenuMaxWidth}
          renderItem={({ item }) => {
            if (!item.value) {
              return null;
            }

            const { id } = item.value;
            return (
              <MenuItemWithMouseHover
                menuItemState="default"
                text={item.textValue}
                onPress={() => {
                  perform(id !== translateFromContentId ? id : null);
                  setIsMenuVisible(false);
                }}
              />
            );
          }}
          state={verticalMenuState}
          contain={false}
        />
      )}
      renderTrigger={(triggerProps) => {
        const disabledTooltipText = getDisabledTooltipText(props);

        return (
          <QuinaryButton
            tooltipText={isMenuVisible ? '' : (disabledTooltipText ?? 'Translate with AI')}
            tooltipPlacement="bottom"
            disabled={!!disabledTooltipText}
            activated={isMenuVisible}
            onClick={() => setIsMenuVisible(true)}
            ref={triggerProps.ref}
            {...getDataUiElementAttribute(DataUiElement.AiTranslateButton)}
          >
            <QuinaryButton.Icon icon={Icons.Translate} />
          </QuinaryButton>
        );
      }}
    />
  );
};

const getDisabledTooltipText = ({
  aiOperationState,
  availableLanguages,
  otherTasksProgress,
  isEmpty,
  isInContentComponent,
}: Props): string | null => {
  if (otherTasksProgress === AiTaskProgress.Running) {
    return 'Another action is running';
  }

  if (aiOperationState === AiOperationState.Pending) {
    return 'Translating';
  }

  if (isEmpty && isInContentComponent) {
    return 'Nothing to translate. Provide content first.';
  }

  if (isEmpty && !availableLanguages.length) {
    return 'Nothing to translate. Provide content in this language variant or other language variants first.';
  }

  return null;
};

const createMenuSections = ({
  availableLanguages,
  isEmpty,
  isInContentComponent,
  targetLanguageName,
}: Props): ReadonlyArray<VerticalMenuSection<IBaseSelectItem>> =>
  (
    [
      isEmpty
        ? null
        : {
            id: 'content',
            items: [
              {
                id: translateFromContentId,
                label: 'Use existing content',
              },
            ],
            label: getMenuHeadline(targetLanguageName),
            type: 'section',
          },
      !isInContentComponent && availableLanguages.length > 0
        ? {
            id: 'availableLanguages',
            items: availableLanguages,
            label: isEmpty ? getMenuHeadline(targetLanguageName) : undefined,
            type: 'section',
          }
        : null,
    ] satisfies ReadonlyArray<VerticalMenuSection<IBaseSelectItem> | null>
  ).filter(notNull);

const getMenuHeadline = (targetLanguageName: string | null): string =>
  targetLanguageName ? `Translate to ${targetLanguageName}` : 'Translate';
