import { memoize } from '@kontent-ai/memoization';
import { notNull } from '@kontent-ai/utils';
import { MaximumLimitPerRequest } from '../../../../_shared/constants/scrollGridConstants.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { IAsset } from '../../../models/assets/Asset.ts';
import { IAssets } from '../IAssets.type.ts';

export const getAssetsForScrollGrid = memoize.allForever(
  (assetsStore: IAssets): ReadonlyArray<IAsset | null> => {
    const skelets: ReadonlyArray<null> =
      assetsStore.loadingStatus === LoadingStatus.Loading
        ? new Array(MaximumLimitPerRequest).fill(null)
        : [];

    return [
      ...assetsStore.listingAssets.listingIds
        .toArray()
        .map((id: Uuid) => assetsStore.byId.get(id) ?? null)
        .filter(notNull),
      ...skelets,
    ];
  },
);
