import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IProjectSpecificStorage } from '../../../../../localStorages/projectSpecificStorage.ts';
import { IRelationsConfigStorage } from '../../../../../localStorages/relationsConfigStorage.ts';
import { ILoadListingItemsAction } from '../../../../itemEditor/features/LoadedItems/actions/thunks/loadListingItems.ts';
import {
  Relations_SetRoot_Failed,
  Relations_SetRoot_Finished,
  Relations_SetRoot_Started,
} from '../../constants/relationsActionTypes.ts';
import { UnableToSelectRootErrorMessage } from '../../constants/uiConstants.ts';
import { getRelationsNodeId } from '../../utils/relationsUtils.ts';
import { collapseAllRelationsNodes } from '../relationsActions.ts';

interface IDeps {
  readonly expandRelationsNode: (
    params: { itemId: Uuid; nodeId: string },
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
  readonly loadListingItems: ILoadListingItemsAction;
  readonly relationsConfigStorage: IProjectSpecificStorage<IRelationsConfigStorage>;
  readonly setDefaultRelationsRoot: (
    params: { itemId: Uuid },
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
}

interface IParams {
  readonly rootId: Uuid | null;
}

const started = (itemId: Uuid | null) =>
  ({
    type: Relations_SetRoot_Started,
    payload: {
      itemId,
    },
  }) as const;

const finished = () =>
  ({
    type: Relations_SetRoot_Finished,
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: Relations_SetRoot_Failed,
    payload: {
      errorMessage,
    },
  }) as const;

export type SetRelationsRootActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const setRelationsRootActionCreator =
  (deps: IDeps) =>
  (params: IParams, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      sharedApp: { currentProjectId },
      relationsApp: { defaultRootId, expandedNodesData, selectedRootId },
    } = getState();

    dispatch(started(params.rootId));

    try {
      if (params.rootId !== selectedRootId) {
        dispatch(collapseAllRelationsNodes());
      }

      if (params.rootId) {
        const itemsToLoad: ReadonlyArray<Uuid> = expandedNodesData
          .toArray()
          .flatMap((data) => [data.itemId, ...data.childrenIds]);

        await dispatch(deps.loadListingItems([...itemsToLoad, params.rootId], abortSignal));

        await dispatch(
          deps.expandRelationsNode(
            {
              itemId: params.rootId,
              nodeId: getRelationsNodeId(params.rootId),
            },
            abortSignal,
          ),
        );
      }

      if (!defaultRootId && params.rootId) {
        await dispatch(
          deps.setDefaultRelationsRoot(
            {
              itemId: params.rootId,
            },
            abortSignal,
          ),
        );
      } else {
        deps.relationsConfigStorage.save(
          {
            rootId: params.rootId !== defaultRootId ? params.rootId : null,
          },
          currentProjectId,
        );
      }

      dispatch(finished());
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failed(UnableToSelectRootErrorMessage));
      }

      throw error;
    }
  };
