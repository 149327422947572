import classNames from 'classnames';
import { useEffect } from 'react';
import { Feature } from '../../../../../../../../@types/FeatureEnum.ts';
import { Loader } from '../../../../../../../_shared/components/Loader.tsx';
import {
  DataUiElement,
  getDataUiComponentIdAttribute,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isFeatureEnabled } from '../../../../../../../_shared/utils/featureToggles.ts';
import { ICompiledContentType } from '../../../../../../contentInventory/content/models/CompiledContentType.ts';
import { ElementType } from '../../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { IMultipleChoiceTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/MultipleChoiceTypeElement.ts';
import { TypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { isEditableElement } from '../../../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { NoElementsElement } from '../../../../../../itemEditor/features/ContentItemEditing/components/NoElementsElement.tsx';
import { ContentGroupTabsInComponent } from '../../../../../../itemEditor/features/ContentItemEditing/containers/ContentGroupTabsInComponent.tsx';
import { ContentNestingContextProvider } from '../../../../../../itemEditor/features/ContentItemEditing/context/ContentNestingContext.tsx';
import {
  getItemElementOrDefault,
  getTypeElementsInContentGroup,
} from '../../../../../../itemEditor/stores/utils/contentItemElementsUtils.ts';
import { isElementVisible } from '../../../../../../itemEditor/utils/itemElementConditionUtils.ts';
import { useContentComponent } from '../../../../../hooks/useContentComponent.ts';
import { ContentComponentItemElement } from './ContentComponentItemElement.tsx';

export interface IContentComponentItemElementsOwnProps {
  readonly autoFocus: boolean;
  readonly contentComponentId: Uuid;
  readonly disabled: boolean;
  readonly isHighlighted: boolean;
  readonly onMount: () => void;
}

interface IContentComponentItemElementsStateProps {
  readonly canUpdateContent: boolean;
  readonly contentType: ICompiledContentType;
  readonly selectedContentGroupId: Uuid | null;
}

interface IContentComponentItemElementsWrapperProps
  extends IContentComponentItemElementsOwnProps,
    IContentComponentItemElementsStateProps {}

type IContentComponentItemElementsProps = Omit<
  IContentComponentItemElementsWrapperProps,
  'onMount'
>;

const NoElements: ReadonlyArray<TypeElement> = [];

const useGetVisibleElementsForComponent = (
  typeElements: ReadonlyArray<TypeElement>,
  contentComponentId: Uuid,
): ReadonlyArray<TypeElement> => {
  const component = useContentComponent(contentComponentId);
  if (!component) {
    return NoElements;
  }

  if (!isFeatureEnabled(Feature.ConditionalElements)) {
    return typeElements;
  }

  const consistentEditableItemElements = typeElements
    .filter(isEditableElement)
    .map((typeElement: IMultipleChoiceTypeElement) =>
      getItemElementOrDefault(typeElement, component.elements),
    );
  return typeElements.filter((typeElement) =>
    isElementVisible(typeElement, consistentEditableItemElements),
  );
};

const ContentComponentItemElementsWrapper = ({
  onMount,
  ...props
}: IContentComponentItemElementsWrapperProps) => {
  useEffect(() => {
    onMount();
  }, [onMount]);

  if (!props.contentType) {
    return <Loader />;
  }

  return <ComponentItemElements {...props} />;
};

const ComponentItemElements = ({
  autoFocus,
  canUpdateContent,
  contentComponentId,
  contentType,
  disabled,
  isHighlighted,
  selectedContentGroupId,
}: IContentComponentItemElementsProps) => {
  const contentTypeElements = contentType.contentElements;
  const indexOfFirstFocusableElement = contentTypeElements.findIndex(
    (typeElement) => typeElement.type !== ElementType.Guidelines,
  );

  const typeElementsInGroup = getTypeElementsInContentGroup(
    contentTypeElements,
    selectedContentGroupId,
  );
  const visibleElements = useGetVisibleElementsForComponent(
    typeElementsInGroup,
    contentComponentId,
  );

  const canEdit = !disabled && canUpdateContent;

  return (
    <div
      className={classNames('content-component__content', {
        'content-component__content--is-highlighted': isHighlighted,
      })}
      {...getDataUiElementAttribute(DataUiElement.ContentComponentContent)}
      {...getDataUiComponentIdAttribute(contentComponentId)}
    >
      {!!contentType.contentGroups.length && (
        <ContentGroupTabsInComponent
          canEditContent={canEdit}
          contentComponentId={contentComponentId}
          contentType={contentType}
        />
      )}
      <div
        className="content-component__elements-pane"
        {...getDataUiElementAttribute(DataUiElement.ContentComponentElements)}
      >
        {visibleElements.length === 0 ? (
          <NoElementsElement isDisabled={disabled} />
        ) : (
          <ContentNestingContextProvider isCurrentLevelContentComponent>
            {visibleElements.map((typeElement, index) => (
              <ContentComponentItemElement
                key={typeElement.elementId}
                autoFocus={autoFocus && index === indexOfFirstFocusableElement}
                contentComponentId={contentComponentId}
                disabled={disabled}
                typeElement={typeElement}
              />
            ))}
          </ContentNestingContextProvider>
        )}
      </div>
    </div>
  );
};

ContentComponentItemElementsWrapper.displayName = 'ContentComponentItemElements';

export { ContentComponentItemElementsWrapper as ContentComponentItemElements };
