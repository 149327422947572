import { Collection } from '@kontent-ai/utils';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { SpaceSelector as SpaceSelectorComponent } from '../../components/contentItemPreview/SpaceSelector.tsx';
import { getAvailableSpacesForUser } from '../../utils/getAvailableSpacesForUser.ts';

type Props = Readonly<{
  spaceId: Uuid | null;
  setSpaceId: (newState: Uuid) => void;
}>;

export const SpaceSelector = ({ spaceId, setSpaceId }: Props) => {
  const availableSpaces = useSelector((s) =>
    getAvailableSpacesForUser(
      s.contentApp.editedContentItemVariant?.id.variantId ?? null,
      s.sharedApp.currentProjectId,
      Collection.getValues(s.data.spaces.byId),
      Collection.getValues(s.data.collections.byId),
      s.data.user,
    ),
  );

  if (!availableSpaces.length) return null;

  const spaceMenuItems = availableSpaces.map((space) => ({
    id: space.id,
    label: space.name,
  }));

  return (
    <SpaceSelectorComponent
      onSelectionChange={setSpaceId}
      selectedSpaceId={spaceId}
      spaceMenuItems={spaceMenuItems}
    />
  );
};
