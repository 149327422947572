import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { IContentItemRepository } from '../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { TimelineItem } from '../../../../models/revisions/TimeLineItem.ts';
import { fetchTimelineUntilAllRestoredFromReferencesResolved } from '../../utils/fetchTimelineUntilReferencesResolved.ts';
import {
  itemVariantTimelineLoadMoreItemsFinished,
  itemVariantTimelineLoadMoreItemsStarted,
} from '../timelineActions.ts';

interface IDeps {
  readonly contentItemRepository: IContentItemRepository;
}

export const loadMoreItemsVariantTimelineActionCreator =
  ({ contentItemRepository }: IDeps) =>
  (
    contentItemId: Uuid,
    variantId: Uuid,
    abortSignal?: AbortSignal,
  ): ThunkPromise<ReadonlyArray<TimelineItem>> =>
  async (dispatch, getState) => {
    const {
      contentApp: { timelineContinuationToken },
    } = getState();

    if (!timelineContinuationToken) {
      return [];
    }

    dispatch(itemVariantTimelineLoadMoreItemsStarted());

    const { timeline, continuationToken } =
      await fetchTimelineUntilAllRestoredFromReferencesResolved(
        contentItemRepository,
        contentItemId,
        variantId,
        timelineContinuationToken,
        abortSignal,
      );

    dispatch(itemVariantTimelineLoadMoreItemsFinished(timeline, continuationToken));

    return timeline;
  };
