import { InvariantException } from '@kontent-ai/errors';
import { History } from 'history';
import {
  Dispatch,
  GetState,
  ThunkFunction,
  ThunkPromise,
} from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  ContentItemRevisionCompareRoute,
  ContentItemRevisionCompareRouteParams,
  ContentItemRoute,
  ContentItemRouteParams,
} from '../../../../../../_shared/constants/routePaths.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import {
  buildPath,
  matchPath,
  parseContentItemIds,
} from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { deactivateContentItemEditingAction } from '../contentItemEditingActions.ts';

interface IDeps {
  readonly loadTimeline: () => ThunkPromise;
  readonly enableComparison: () => ThunkFunction;
}

export const createRedirectToCompareVersionsAction =
  (deps: IDeps) =>
  (actionOrigin: ContentItemEditingEventOrigins, history: History): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const matchParams = matchPath<ContentItemRouteParams<string>>(
      history.location.pathname,
      ContentItemRoute,
    );
    if (!matchParams) {
      throw InvariantException('Current route does not belong to ContentItem routes');
    }

    await dispatch(deps.loadTimeline());

    const {
      contentApp: { entryTimeline },
    } = getState();
    const selectedRevision = entryTimeline?.[0];
    const originalItem = entryTimeline?.[1];
    if (!entryTimeline || !selectedRevision || !originalItem) {
      throw InvariantException('redirectToCompareVersions.ts: entryTimeline is not loaded');
    }

    dispatch(deps.enableComparison());
    dispatch(deactivateContentItemEditingAction());
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingEventTypes.CompareVersions,
        origin: actionOrigin,
      }),
    );

    const path = buildPath<ContentItemRevisionCompareRouteParams<UuidArray>>(
      ContentItemRevisionCompareRoute,
      {
        app: matchParams.app,
        projectId: matchParams.projectId,
        variantId: matchParams.variantId,
        spaceId: matchParams.spaceId,
        contentItemIds: parseContentItemIds(matchParams.contentItemIds),
        timelineItemId: selectedRevision.itemId,
        compareItemId: originalItem.itemId,
      },
    );
    history.push(path);
  };
