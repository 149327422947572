import { Button } from '@kontent-ai/component-library/Button';
import { IProjectListingUser } from '../../../applications/projects/models/ProjectListingUser.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { formatUserName } from '../../utils/usersUtils.ts';
import { Loader } from '../Loader.tsx';
import { ModalDialog } from '../ModalDialog/ModalDialog.tsx';

type Props = {
  readonly admins: ReadonlyArray<IProjectListingUser> | null;
  readonly onClose: () => void;
  readonly subscriptionName?: string;
};

export const AvailableSubscriptionAdmins = ({ admins, onClose, subscriptionName }: Props) => {
  const subscriptionSpecifier = subscriptionName ? `“${subscriptionName}”` : 'your';

  const body = admins ? (
    <div>
      {admins.map((admin) => (
        <p key={admin.userId}>{formatUserName(admin)}</p>
      ))}
    </div>
  ) : (
    <Loader />
  );

  const footer = (
    <Button
      autoFocus
      onClick={onClose}
      buttonStyle="primary"
      {...getDataUiActionAttribute(DataUiAction.CloseSubscriptionAdminInfo)}
    >
      Close
    </Button>
  );

  return (
    <ModalDialog
      bodyContent={body}
      dataUiElement={DataUiElement.SubscriptionAdminsDialog}
      footerContentRight={footer}
      headerContent={`Admins of ${subscriptionSpecifier} subscription`}
      onClose={onClose}
    />
  );
};
