import { Box } from '@kontent-ai/component-library/Box';
import { offsetFocus, px, shadowFocusStyles } from '@kontent-ai/component-library/tokens';
import React from 'react';

type Props = Readonly<{
  isFocusVisible: boolean;
}>;

export const CommentThreadFocusRing: React.FC<Props> = ({ isFocusVisible }) => (
  <Box
    position="absolute"
    inset={px(-1 * offsetFocus)}
    pointerEvents="none"
    borderRadius="inherit"
    css={`${isFocusVisible ? shadowFocusStyles : undefined}`}
  />
);
