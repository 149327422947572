import React from 'react';

interface IFormattedMessageDataProps {
  readonly ariaDescription?: string;
  readonly messageWithFormattingTags: string;
  readonly className?: string;
}

function getInnerHtml(messageWithFormattingTags: string): { __html: string } {
  return { __html: messageWithFormattingTags };
}

export const FormattedMessage: React.FC<IFormattedMessageDataProps> = ({
  messageWithFormattingTags,
  className,
  ...props
}) => (
  <div
    className={className}
    dangerouslySetInnerHTML={getInnerHtml(messageWithFormattingTags)}
    {...props}
  />
);

FormattedMessage.displayName = 'FormattedMessage';
