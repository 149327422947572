import { assert, Collection, groupBy } from '@kontent-ai/utils';
import React, { useRef } from 'react';
import useResizeObserver from 'use-resize-observer';
import { splitToGroups } from '../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { isTableCell } from '../../../utils/blocks/blockTypeUtils.ts';
import {
  getBlockFromBlockElement,
  isCellBoundary,
} from '../../draftJs/utils/draftJsEditorUtils.ts';
import { getCoordinatesFromDepth } from '../api/depthCoordinatesConversion.ts';

export type RowCells = ReadonlyArray<ReadonlyArray<React.ReactElement>>;

type Props = {
  readonly children: React.ReactNode;
  readonly onResize: () => void;
  readonly renderRow: (cells: RowCells, rowIndex: number) => JSX.Element;
};

export const Table = ({ children, onResize, renderRow }: Props) => {
  const tableRef = useRef<HTMLDivElement>(null);
  useResizeObserver({ ref: tableRef, onResize });

  const childrenArray = React.Children.toArray(children) as ReadonlyArray<React.ReactElement>;
  const groupedChildren = splitToGroups(childrenArray, isCellBoundary);

  const rows = Collection.getEntries(
    groupBy(groupedChildren, (cellElements) => {
      const cellBlocks = cellElements.map(getBlockFromBlockElement);
      const cellBlock = cellBlocks.find(isTableCell) || cellBlocks[0];
      assert(
        cellBlock,
        () =>
          `${__filename}: Cell block is not a content block. Value: ${JSON.stringify(cellBlock)}.`,
      );
      return getCoordinatesFromDepth(cellBlock.getDepth()).y;
    }),
  );

  return (
    <div ref={tableRef} className="rte__table-table">
      {rows.map(([rowIndex, cells]) => renderRow(cells, rowIndex))}
    </div>
  );
};
