import { ThunkPromise } from '../../../../../../../@types/Dispatcher.type.ts';
import {
  CancelScheduling,
  Undo,
  Unpublish,
} from '../../../../../../../_shared/constants/itemActions.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { StatusMessageAction } from '../../../../stores/IContentAppStoreState.ts';

export type AvailableUndoFunctions = {
  undoPublishContentItems: (languageId: string, affectedIds: ReadonlyArray<Uuid>) => ThunkPromise;
  undoSchedulePublishContentItems: (
    languageId: string,
    affectedIds: ReadonlyArray<Uuid>,
  ) => ThunkPromise;
  undoDeleteContentItems: (languageId: string, affectedIds: ReadonlyArray<Uuid>) => ThunkPromise;
};

export interface IUndo {
  readonly undoFn: () => ThunkPromise;
  readonly undoText: string;
}

export const makeGetUndo =
  (deps: AvailableUndoFunctions) =>
  (state: IStore): IUndo | undefined => {
    const { lastAction, successfulIds, variantId } = state.contentApp.listingUi.statusInfo;

    if (successfulIds.isEmpty()) {
      return undefined;
    }

    switch (lastAction.lastActionType) {
      case StatusMessageAction.Published:
        return {
          undoText: Unpublish,
          undoFn: () => deps.undoPublishContentItems(variantId, successfulIds.toArray()),
        };

      case StatusMessageAction.ScheduledToPublish:
        return {
          undoText: CancelScheduling,
          undoFn: () => deps.undoSchedulePublishContentItems(variantId, successfulIds.toArray()),
        };

      case StatusMessageAction.Archived:
        return {
          undoText: Undo,
          undoFn: () => deps.undoDeleteContentItems(variantId, successfulIds.toArray()),
        };

      default:
        return undefined;
    }
  };
