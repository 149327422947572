import { IImageTransformation } from '../../../../../data/models/assetRenditions/AssetRendition.ts';
import { IFileProperties } from '../../../../../data/models/assets/Asset.ts';
import {
  ResponsiveImageExtensionsMessage,
  ResponsiveImageSupportedTypes,
} from '../../../constants/responsiveImageSupportedTypes.ts';

export const getQueryString = (transformation: IImageTransformation): string => {
  const {
    rect: { x, y, width, height },
  } = transformation;

  const params: Record<string, string | number> = {
    w: transformation.width,
    h: transformation.height,
    fit: 'clip',
    rect: `${x},${y},${width},${height}`,
  };

  if (transformation.format) {
    params.fm = transformation.format;
  }

  return Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
};

export const maximumThumbnailWidth = 450;
export const maximumThumbnailHeight = 140;

export const optimizeTransformationForThumbnail = (
  transformation: IImageTransformation,
): IImageTransformation => {
  const { width: w, height: h } = transformation.rect;
  const result: IImageTransformation = {
    ...transformation,
    format: 'jpg',
  };

  if (w > maximumThumbnailWidth || h > maximumThumbnailHeight) {
    const isHeightMoreViolatedThanWidth = h / maximumThumbnailHeight > w / maximumThumbnailWidth;
    const aspectRatio = w / h;

    return isHeightMoreViolatedThanWidth
      ? {
          ...result,
          width: Math.round(maximumThumbnailHeight * aspectRatio),
          height: maximumThumbnailHeight,
        }
      : {
          ...result,
          width: maximumThumbnailWidth,
          height: Math.round(maximumThumbnailWidth / aspectRatio),
        };
  }

  return result;
};

export const buildThumbnailLinkForCustomizedImage = (
  originalAssetLink: string,
  transformation: IImageTransformation,
): string => buildAssetLink(originalAssetLink, optimizeTransformationForThumbnail(transformation));

export const buildAssetLink = (
  originalAssetLink: string,
  transformation: IImageTransformation | null,
): string => {
  if (!transformation) {
    return originalAssetLink;
  }

  const hasQueryString = originalAssetLink.includes('?');

  return `${originalAssetLink}${hasQueryString ? '&' : '?'}${getQueryString(transformation)}`;
};

export const MaximumOutputDimensions = 12_000;
const MaximumInputDimensions = MaximumOutputDimensions;
const MaximumInputFileSize = 50 * 1024 * 1024;

export const getImageTransformationNotSupportedMessage = (file: IFileProperties): string | null => {
  if (!ResponsiveImageSupportedTypes.includes(file.type)) {
    return `To customize the image, use ${ResponsiveImageExtensionsMessage} file format.`;
  }

  const areDimensionsSupported =
    !!file.width &&
    file.width > 0 &&
    file.width <= MaximumInputDimensions &&
    !!file.height &&
    file.height > 0 &&
    file.height <= MaximumInputDimensions;
  if (!areDimensionsSupported) {
    return `To customize the image, use images of up to ${MaximumInputDimensions} px in both dimensions.`;
  }

  const isFileSizeSupported = file.fileSize > 0 && file.fileSize <= MaximumInputFileSize;
  if (!isFileSizeSupported) {
    return 'To customize the image, use files of up to 50MB in size.';
  }

  return null;
};
