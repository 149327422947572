import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import React, { useCallback } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export type CommentButtonDispatchProps = {
  readonly onClick: () => void;
  readonly disableTabulator?: boolean;
};

export type CommentButtonDataProps = {
  readonly id: Uuid | null;
  readonly hasComment: boolean;
  readonly isFocused: boolean;
};

export type CommentButtonProps = CommentButtonDispatchProps & CommentButtonDataProps;

export const CommentButton: React.FC<CommentButtonProps> = ({
  hasComment,
  id,
  isFocused,
  onClick,
  disableTabulator,
}) => {
  const onButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onClick();
    },
    [onClick],
  );

  const label = hasComment ? 'Show comment' : 'Add comment';

  return (
    <QuinaryButton
      id={id ?? undefined}
      activated={isFocused}
      aria-label={label}
      onClick={onButtonClick}
      tooltipPlacement="bottom"
      tooltipText={label}
      disableTabulator={disableTabulator}
      {...getDataUiElementAttribute(DataUiElement.CommentButton)}
    >
      <QuinaryButton.Icon icon={hasComment ? Icons.BubbleWithLines : Icons.Bubble} />
    </QuinaryButton>
  );
};
