import { Box } from '@kontent-ai/component-library/Box';
import { ContentItemCollection } from '../containers/elements/ContentItemCollection.tsx';
import { ItemNameElement } from '../containers/elements/ItemNameElement.tsx';
import { ContentItemPaneElementsGroupCompact } from './ContentItemPaneElementsGroupCompact.tsx';

type ContentItemHeaderCompactProps = Readonly<{
  canEditName: boolean;
  isCollectionInMainPaneEnabled: boolean;
}>;

export const ContentItemHeaderCompact = ({
  canEditName,
  isCollectionInMainPaneEnabled,
}: ContentItemHeaderCompactProps) => {
  return (
    <Box>
      <ContentItemPaneElementsGroupCompact isDisabled={!canEditName}>
        <ItemNameElement />
        {isCollectionInMainPaneEnabled && <ContentItemCollection />}
      </ContentItemPaneElementsGroupCompact>
    </Box>
  );
};
