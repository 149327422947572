import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import classNames from 'classnames';
import { useCallback } from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import { Apply, Render } from '../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { DraftJsEditorPlugin } from '../../../plugins/draftJs/DraftJsEditorPlugin.type.ts';
import { WrapperPlugin } from '../../../plugins/visuals/WrapperPlugin.tsx';

type AutoGeneratedUrlSlugPluginProps = {
  readonly customMode: boolean;
  readonly onRegenerate?: () => void;
  readonly regenerateDisabled?: boolean;
  readonly id?: string;
};

export type AutoGeneratedUrlSlugPlugin = DraftJsEditorPlugin<
  None,
  AutoGeneratedUrlSlugPluginProps,
  None,
  None,
  [WrapperPlugin]
>;

// Plugin that wraps the editor in another div whose properties can be customized
export const AutoGeneratedUrlSlugPlugin: PluginComponent<AutoGeneratedUrlSlugPlugin> = (props) => {
  const { customMode, onRegenerate, regenerateDisabled, disabled, id } = props;

  const render: Decorator<Render<AutoGeneratedUrlSlugPlugin>> = useCallback(
    (baseRender) => (state) => (
      <div
        className={classNames('text-field text-field--is-growing', {
          'text-field--is-disabled': disabled,
          'text-field--is-disabled-in-content-editor': disabled,
          'text-field--has-button': customMode && !regenerateDisabled,
        })}
      >
        <div className="text-field__input text-field__input--is-multiline u-transparent-border u-no-left-padding">
          {baseRender(state)}
        </div>
        {customMode && !regenerateDisabled && onRegenerate && (
          <div className="text-field__button-pane">
            <QuinaryButton
              aria-controls={id}
              aria-label="Autogenerate"
              disabled={disabled}
              onClick={onRegenerate}
              tooltipText="Autogenerate"
              {...getDataUiActionAttribute(DataUiAction.Regenerate)}
            >
              <QuinaryButton.Icon icon={Icons.RotateDoubleRight} />
            </QuinaryButton>
          </div>
        )}
      </div>
    ),
    [customMode, disabled, regenerateDisabled, onRegenerate, id],
  );

  const apply: Apply<AutoGeneratedUrlSlugPlugin> = useCallback(
    (state) => {
      state.render.decorate(render);
      return {};
    },
    [render],
  );

  return useEditorWithPlugin(props, { apply });
};
