import { Spacing } from '@kontent-ai/component-library/tokens';
import { usePrevious } from '@kontent-ai/hooks';
import { DraftEditorLeafs } from 'draft-js';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  DropDownMenuPositioner,
  DropdownTippyOptions,
} from '../../../../../../../../component-library/components/DropDownMenu/DropDownMenuPositioner.tsx';
import { usePreventOverflowFromScrollContainer } from '../../../../../../../../component-library/components/ScrollContainer/usePreventOverflowFromScrollContainer.ts';
import { MultipleOptionSelectDropDown } from '../../../../../../../_shared/components/MultipleOptionSelect/MultipleOptionSelectDropDown.tsx';
import { MultipleOptionSelectDropDownOption } from '../../../../../../../_shared/components/MultipleOptionSelect/MultipleSelectDropDownOption.tsx';
import { IUserInfo } from '../../../../../../../_shared/models/UserInfo.ts';
import { formatCurrentUserName } from '../../../../../../../_shared/utils/usersUtils.ts';

const getNextHighlightedOption = (
  options: ReadonlyArray<IUserInfo>,
  index: number,
  direction: Direction,
): IUserInfo | undefined => {
  const length = options.length;
  const increment = direction === Direction.Down ? 1 : -1;
  const newIndex = (index + increment + length) % length;
  const newHighlightedOption = options[newIndex];

  return newHighlightedOption;
};

const selectId = (option: IUserInfo) => option.userId;

export type NewMentionProps = {
  readonly children: DraftEditorLeafs;
  readonly currentUserId: UserId;
  readonly onCancel: () => void;
  readonly onCreateMention: () => void;
  readonly onUserSelected: (userId: UserId) => void;
  readonly searchText: string;
  readonly users: ReadonlyArray<IUserInfo>;
};

enum Direction {
  Up = 'up',
  Down = 'down',
}

export type NewMentionHandle = {
  readonly onArrowUp: (event: KeyboardEvent | React.KeyboardEvent) => void;
  readonly onArrowDown: (event: KeyboardEvent | React.KeyboardEvent) => void;
  readonly onEnter: (event: KeyboardEvent | React.KeyboardEvent) => void;
};

export const NewMention = forwardRef<NewMentionHandle, NewMentionProps>(
  (
    { children, currentUserId, onCancel, onCreateMention, onUserSelected, searchText, users },
    ref,
  ) => {
    const mentionRef = useRef<HTMLSpanElement | null>(null);

    const [highlightedOption, setHighlightedOption] = useState<IUserInfo | undefined>(users[0]);

    const usersCount = users.length;
    const firstUser = users[0];
    const highlightFirstOption = useCallback((): void => {
      setHighlightedOption(firstUser);
    }, [firstUser]);

    const highlightedOptionIndex = highlightedOption ? users.indexOf(highlightedOption) : -1;
    const isValidOptionHighlighted = highlightedOptionIndex >= 0;

    const ensureHighlightedOption = useCallback((): void => {
      if (!isValidOptionHighlighted) {
        highlightFirstOption();
      }
    }, [isValidOptionHighlighted, highlightFirstOption]);

    const confirmOption = useCallback(
      (option: IUserInfo): void => {
        onUserSelected(option.userId);
        onCreateMention();
      },
      [onUserSelected, onCreateMention],
    );

    const highlightNextOption = useCallback(
      (direction: Direction): void => {
        const newHighlightedOption = getNextHighlightedOption(
          users,
          highlightedOptionIndex,
          direction,
        );
        setHighlightedOption(newHighlightedOption);
      },
      [highlightedOptionIndex, users],
    );

    const selectName = useCallback(
      (option: IUserInfo): string => {
        return formatCurrentUserName(currentUserId)(option);
      },
      [currentUserId],
    );

    const onArrowUp = useCallback(
      (event: KeyboardEvent): void => {
        event.preventDefault();
        event.stopPropagation();

        highlightNextOption(Direction.Up);
      },
      [highlightNextOption],
    );

    const onArrowDown = useCallback(
      (event: KeyboardEvent): void => {
        event.preventDefault();
        event.stopPropagation();

        highlightNextOption(Direction.Down);
      },
      [highlightNextOption],
    );

    const areUsersAvailable = users.length > 0;
    const onEnter = useCallback(
      (event: KeyboardEvent): void => {
        event.preventDefault();
        event.stopPropagation();

        if (highlightedOption && areUsersAvailable) {
          confirmOption(highlightedOption);
        } else {
          onCancel();
        }
      },
      [onCancel, confirmOption, highlightedOption, areUsersAvailable],
    );

    useImperativeHandle(ref, () => ({
      onArrowUp,
      onArrowDown,
      onEnter,
    }));

    const prevSearchText = usePrevious(searchText);

    useEffect(() => {
      // Special behaviors when typing space
      if (`${prevSearchText} ` === searchText) {
        if (!firstUser) {
          // Cancel in case no users are already available
          onCancel();
        } else if (usersCount === 1) {
          // Finalize if only one user left
          onUserSelected(firstUser.userId);
        }
      }

      if (searchText !== prevSearchText) {
        ensureHighlightedOption();
      }
    }, [
      searchText,
      prevSearchText,
      onCancel,
      onUserSelected,
      ensureHighlightedOption,
      usersCount,
      firstUser,
    ]);

    const { preventOverflowModifier, boundaryProps } = usePreventOverflowFromScrollContainer();

    const tippyOptions: DropdownTippyOptions = {
      placement: 'bottom-start',
      offset: [0, Spacing.S],
      popperOptions: {
        modifiers: [
          {
            name: 'flip',
            options: {
              ...boundaryProps,
              fallbackPlacements: ['top-start'],
            },
          },
          preventOverflowModifier,
        ],
      },
    };

    return (
      <DropDownMenuPositioner
        isDropDownVisible={areUsersAvailable}
        renderDropDown={(_triggerWidth, _triggerRef, menuProps) => (
          <div {...menuProps} className="new-mention-users" contentEditable={false}>
            <MultipleOptionSelectDropDown<IUserInfo>
              getOptionId={selectId}
              getOptionName={selectName}
              options={users}
              getIsOptionSelected={() => false}
              onOptionHighlighted={setHighlightedOption}
              highlightedOption={highlightedOption}
              highlightedPattern={(searchText || '').trim()}
              onOptionConfirmed={confirmOption}
              renderDropdownOption={(props) => (
                <MultipleOptionSelectDropDownOption
                  key={props.getOptionId(props.option)}
                  {...props}
                />
              )}
            />
          </div>
        )}
        renderTrigger={({ ref: triggerRef, ...triggerProps }) => (
          <span
            {...triggerProps}
            ref={(c) => {
              mentionRef.current = c;
              triggerRef(c);
            }}
            className="new-mention"
          >
            {children}
          </span>
        )}
        tippyOptions={tippyOptions}
      />
    );
  },
);

NewMention.displayName = 'NewMention';
