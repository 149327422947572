import React from 'react';
import { mainMenuStructure } from '../../../constants/editingActions/mainMenuStructure.ts';
import { EditingAction } from '../../../models/EditingAction.ts';
import { ActionsMenuGroup } from './ActionsMenuGroup.tsx';

type ActionsMainMenuProps = {
  readonly actions: readonly EditingAction[];
  readonly activatedActions: EditingAction;
};

export const ActionsMainMenu: React.FC<ActionsMainMenuProps> = (props) =>
  mainMenuStructure
    .filter(({ actions }) => actions.some((a) => props.actions.includes(a)))
    .map((group, index) => {
      return (
        <ActionsMenuGroup
          allGroupActions={group.actions}
          availableActions={props.actions}
          key={group.key}
          title={group.title}
          isFirst={index === 0}
        />
      );
    });

ActionsMainMenu.displayName = 'ActionsMainMenu';
