import { shallowEqual } from '../../../../_shared/utils/shallowEqual.ts';
import { ILinkedItemsElement } from '../../models/contentItemElements/modularItems/ILinkedItemsElement.ts';
import { basePropertiesAreTheSame } from './basePropertiesAreTheSame.ts';

export const areModularContentsItemElementsTheSame = (
  itemElement1: ILinkedItemsElement,
  itemElement2: ILinkedItemsElement,
): boolean => {
  if (!basePropertiesAreTheSame(itemElement1, itemElement2)) {
    return false;
  }
  return shallowEqual(itemElement1.value, itemElement2.value);
};
