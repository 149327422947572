import classNames from 'classnames';
import { PropsWithChildren } from 'react';

type ContentItemPaneElementsGroupCompactProps = Readonly<{
  areHeaderContentGroups?: boolean;
  isDisabled: boolean;
  isGuideline?: boolean;
}>;

export const ContentItemPaneElementsGroupCompact = ({
  areHeaderContentGroups,
  children,
  isDisabled,
  isGuideline,
}: PropsWithChildren<ContentItemPaneElementsGroupCompactProps>) => {
  return (
    <div
      className={classNames('content-item-pane__elements-group', {
        'content-item-pane__elements-group--is-disabled': isDisabled,
        'content-item-pane__elements-group--is-guideline': isGuideline,
        'content-item-pane__elements-group--are-header-content-group-tabs': areHeaderContentGroups,
      })}
    >
      {children}
    </div>
  );
};
