import { memoize } from '@kontent-ai/memoization';
import { notUndefined } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { validateNumberOfItems } from '../../../../_shared/utils/validation/limitations/validateNumberOfItems.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { ILinkedItemsTypeElement } from '../../../contentInventory/content/models/contentTypeElements/LinkedItemsTypeElement.ts';
import { validModularContentItemWarningResult } from '../../constants/validElementWarningResults.ts';
import {
  ModularContentExactlyWarning,
  ModularContentIsRequiredWarning,
  ModularContentMaximumWarning,
  ModularContentMinimumWarning,
  ModularElementDisallowedContentTypes,
} from '../../constants/warningMessageTemplates.ts';
import { ILinkedItemsElement } from '../../models/contentItemElements/modularItems/ILinkedItemsElement.ts';
import { IModularContentItemElementWarningResult } from './types/IModularContentItemElementWarningResult.type.ts';
import { emptyItemElementWarningResult } from './types/Warnings.ts';

const getExistingReferencedItems = (
  referencedItemsIds: ReadonlyArray<Uuid>,
  loadedItems: Immutable.Map<Uuid, IListingContentItem>,
): ReadonlyArray<IListingContentItem> =>
  referencedItemsIds
    .map((itemId) => loadedItems.get(itemId))
    .filter(notUndefined)
    .filter((item) => !item.item.archived);

const isRequiredLimitValid = (
  typeElement: ILinkedItemsTypeElement,
  numberOfItems: number,
): boolean => !typeElement.isRequired || numberOfItems > 0;

const getInvalidNumberOfItemsErrorMessage = (
  minItems: number | null,
  maxItems: number | null,
): string => {
  if (minItems && maxItems && minItems === maxItems) {
    return ModularContentExactlyWarning(minItems);
  }
  if (minItems) {
    return ModularContentMinimumWarning(minItems);
  }
  if (maxItems) {
    return ModularContentMaximumWarning(maxItems);
  }

  return '';
};

const hasOnlyAllowedContentTypes = (
  allowedTypesIds: ReadonlyArray<Uuid>,
  referencedItems: ReadonlyArray<IListingContentItem>,
): boolean => {
  if (!allowedTypesIds || !allowedTypesIds.length) {
    return true;
  }

  return referencedItems.every((item) => allowedTypesIds.includes(item.item.typeId));
};

interface IParams {
  readonly typeElement: ILinkedItemsTypeElement;
  readonly itemElement: ILinkedItemsElement;
  readonly loadedEntries: Immutable.Map<Uuid, IListingContentItem>;
}

const getMemoizedResult = memoize.allForever(
  (
    minItems: number | null,
    maxItems: number | null,
    containsOnlyAllowedContentTypes: boolean,
    isNumberOfItemsLimitMet: boolean,
    isRequiredLimitMet: boolean,
  ): IModularContentItemElementWarningResult => {
    const warningMessages: string[] = [];

    if (!containsOnlyAllowedContentTypes) {
      warningMessages.push(ModularElementDisallowedContentTypes);
    }

    if (isRequiredLimitMet && !isNumberOfItemsLimitMet) {
      warningMessages.push(getInvalidNumberOfItemsErrorMessage(minItems, maxItems));
    }

    return {
      ...emptyItemElementWarningResult,
      isNumberOfItemsLimitMet,
      containsOnlyAllowedContentTypes,
      limitationMessages: [...warningMessages],
      requiredMessage: isRequiredLimitMet ? null : ModularContentIsRequiredWarning,
    };
  },
);

const areConditionsToSkipValidationMet = (
  typeElement: ILinkedItemsTypeElement,
  isEmpty: boolean,
): boolean => !typeElement.isRequired && isEmpty;

export const getLinkedItemElementValidationWarning = ({
  typeElement,
  itemElement,
  loadedEntries,
}: IParams): IModularContentItemElementWarningResult => {
  const { minItems, maxItems, allowedTypes } = typeElement;
  const existingReferencedItems = getExistingReferencedItems(itemElement.value, loadedEntries);
  const numberOfItems = existingReferencedItems.length;
  const isEmpty = numberOfItems === 0;

  const isRequiredLimitMet = isRequiredLimitValid(typeElement, numberOfItems);

  if (areConditionsToSkipValidationMet(typeElement, isEmpty)) {
    return validModularContentItemWarningResult;
  }
  const isNumberOfItemsLimitMet = validateNumberOfItems(minItems, maxItems, numberOfItems);
  const containsOnlyAllowedContentTypes = hasOnlyAllowedContentTypes(
    allowedTypes,
    existingReferencedItems,
  );

  return getMemoizedResult(
    minItems,
    maxItems,
    containsOnlyAllowedContentTypes,
    isNumberOfItemsLimitMet,
    isRequiredLimitMet,
  );
};
