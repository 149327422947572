import React from 'react';
import { urlStartsWithHttps } from '../../../../../../_shared/utils/urlUtils.ts';
import { AccessDeniedToContentItemAction } from '../../../../../errorHandling/constants/AccessDeniedToContentItemAction.ts';
import { AccessDeniedToContentItem } from '../../../../../errorHandling/containers/AccessDeniedToContentItem.tsx';
import { WebSpotlightPreviewEmptyState } from '../../../../../webSpotlight/components/preview/WebSpotlightPreviewEmptyState.tsx';
import { WebSpotlightPreviewError } from '../../../../../webSpotlight/components/preview/WebSpotlightPreviewError.tsx';
import { PreviewError } from '../../../../utils/previewUtils.ts';
import { PreviewContentItemInfo } from '../../../../utils/selectDeepestItemWithPreview.ts';
import { PreviewMetadata } from '../../context/WebSpotlightInItemEditingContext.tsx';
import { WebSpotlightPreviewSandboxInItemEditing } from './WebSpotlightPreviewSandboxInItemEditing.tsx';

const sharedPreviewUrlExcludedErrors: ReadonlyArray<PreviewError> = [
  PreviewError.MissingUrlSlugValue,
  PreviewError.MissingUrlSlugElement,
];

type IWebSpotlightPreviewContentProps = Readonly<{
  previewContentItemInfo: PreviewContentItemInfo | null;
  previewMetadata: PreviewMetadata;
  sharedPreviewUrl: string | null;
}>;

export const WebSpotlightPreviewInItemEditingContent: React.FC<
  IWebSpotlightPreviewContentProps
> = ({
  previewContentItemInfo,
  previewMetadata: { error: previewError, url: previewUrl },
  sharedPreviewUrl,
}) => {
  if (previewError) {
    if (previewError === PreviewError.AccessDenied) {
      return <AccessDeniedToContentItem requestedAction={AccessDeniedToContentItemAction.View} />;
    }

    if (!sharedPreviewUrl || !sharedPreviewUrlExcludedErrors.includes(previewError)) {
      return (
        <WebSpotlightPreviewEmptyState>
          {previewError !== PreviewError.NoPreview && previewContentItemInfo && (
            <WebSpotlightPreviewError
              error={previewError}
              previewContentItemInfo={previewContentItemInfo}
            />
          )}
        </WebSpotlightPreviewEmptyState>
      );
    }
  }

  if ((!sharedPreviewUrl && !previewUrl) || !previewContentItemInfo) {
    return <WebSpotlightPreviewEmptyState />;
  }

  const selectedPreviewUrlStartsWithHttps = sharedPreviewUrl
    ? urlStartsWithHttps(sharedPreviewUrl)
    : previewUrl && urlStartsWithHttps(previewUrl);

  if (urlStartsWithHttps(document.location.href) && !selectedPreviewUrlStartsWithHttps) {
    return (
      <WebSpotlightPreviewEmptyState hideDocumentationLink={!!sharedPreviewUrl}>
        <WebSpotlightPreviewError
          error={PreviewError.NotHttps}
          previewContentItemInfo={previewContentItemInfo}
        />
      </WebSpotlightPreviewEmptyState>
    );
  }

  return <WebSpotlightPreviewSandboxInItemEditing isResizable={false} />;
};
