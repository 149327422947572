import { InvariantException } from '@kontent-ai/errors';
import { ContentItemId } from '../../../_shared/models/ContentItemId.ts';
import {
  VariantCompletionStatus,
  getContentVariantState,
} from '../../../_shared/utils/contentItemVariantUtils.ts';
import { IListingContentItemServerModel } from '../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import { PublishingState } from '../../constants/PublishingState.ts';
import { IAssignmentWorkflowStep, mapToAssignmentWorkflowStep } from '../workflow/WorkflowStep.ts';
import { getListingVariantDataFromJS } from './IListingVariantData.ts';

export type YourContentItem = {
  readonly id: ContentItemId;
  readonly contentTypeId: Uuid;
  readonly due: DateTimeStamp | null;
  readonly lastUpdatedAt: DateTimeStamp | null;
  readonly name: string;
  readonly publishingState: PublishingState;
  readonly scheduledToPublishAt: DateTimeStamp | null;
  readonly scheduledToUnpublishAt: DateTimeStamp | null;
  readonly variantCompletionStatus: VariantCompletionStatus | null;
  readonly workflowStatus: IAssignmentWorkflowStep;
};

export type DecoratedYourContentItem = YourContentItem & {
  readonly contentType: string;
  readonly language: string;
};

export const createYourContentItem = (data: IListingContentItemServerModel): YourContentItem => {
  const { item, variant } = data;
  if (!variant) {
    throw InvariantException('Content item variant wasn’t loaded.');
  }

  return {
    id: variant.id,
    contentTypeId: item.type._id,
    due: variant.assignment.due,
    lastUpdatedAt: variant.lastModified,
    name: item.name,
    publishingState: variant.publishingState,
    scheduledToPublishAt: variant.assignment.publishScheduleTime,
    scheduledToUnpublishAt: variant.assignment.unpublishScheduleTime,
    variantCompletionStatus: getContentVariantState(getListingVariantDataFromJS(variant)),
    workflowStatus: mapToAssignmentWorkflowStep(variant.assignment.workflowStatus),
  };
};
