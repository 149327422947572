import { Action } from '../../../../@types/Action.type.ts';
import {
  Subscription_AdminListing_DeleteAdminsFinished,
  Subscription_PromoteAdmin_Finished,
} from '../../../../applications/subscriptionManagement/Admins/constants/actionTypes.ts';
import {
  Subscription_SelectSubscription,
  Subscription_SubscriptionUsers_Loaded,
} from '../../../../applications/subscriptionManagement/shared/constants/subscriptionManagementActionTypes.ts';
import { ISubscriptionAdmin } from '../../../../applications/subscriptionManagement/shared/models/SubscriptionAdmin.ts';
import {
  ISubscriptionUser,
  createSubscriptionUser,
} from '../../../../applications/subscriptionManagement/shared/models/SubscriptionUserInfo.ts';

export const subscriptionUsers = (
  state: ReadonlyArray<ISubscriptionUser> = [],
  action: Action,
): ReadonlyArray<ISubscriptionUser> => {
  switch (action.type) {
    case Subscription_SubscriptionUsers_Loaded:
      return action.payload.subscriptionUsers;

    case Subscription_PromoteAdmin_Finished: {
      const promotedUserIds = action.payload.promotedUsers.map(
        (user: ISubscriptionAdmin) => user.userId,
      );
      return state.filter((user) => !promotedUserIds.includes(user.userId));
    }

    case Subscription_AdminListing_DeleteAdminsFinished: {
      const deletedAdmins = action.payload.deletedAdmins;
      const usersToPromote = deletedAdmins
        .filter((a) => !!a && !a.isInvitationPending)
        .map((admin) => createSubscriptionUser(admin));
      return state.concat(usersToPromote);
    }

    case Subscription_SelectSubscription:
      return [];

    default:
      return state;
  }
};
