import { isAbortError } from '@kontent-ai/errors';
import { assert, Collection } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getCannotViewItemMessage } from '../../../../_shared/utils/permissions/getCannotViewItemMessage.ts';
import { IContentItemRepository } from '../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { IWebSpotlightRepository } from '../../../../repositories/interfaces/IWebSpotlightRepository.type.ts';
import { IParseContentItemVariant } from '../../../itemEditor/features/ContentItemEditing/utils/parseContentItem.ts';
import { ILoadListingItemsAction } from '../../../itemEditor/features/LoadedItems/actions/thunks/loadListingItems.ts';
import {
  PreviewError,
  PreviewUrlInfo,
  getContentItemPreviewUrlInfo,
} from '../../../itemEditor/utils/previewUtils.ts';
import {
  PreviewContentItemInfo,
  checkHasPreviewConfigured,
  selectDeepestItemWithPreview,
} from '../../../itemEditor/utils/selectDeepestItemWithPreview.ts';
import {
  WebSpotlight_LoadItemPreviewInfo_Failed,
  WebSpotlight_LoadItemPreviewInfo_Finished,
  WebSpotlight_LoadItemPreviewInfo_Started,
} from '../../constants/webSpotlightActionTypes.ts';

interface IDeps {
  readonly contentItemRepository: IContentItemRepository;
  readonly loadListingItems: ILoadListingItemsAction;
  readonly parseContentItemVariant: IParseContentItemVariant;
  readonly webSpotlightRepository: IWebSpotlightRepository;
}

const started = () =>
  ({
    type: WebSpotlight_LoadItemPreviewInfo_Started,
  }) as const;

const finished = (
  routeContentItemIds: UuidArray,
  previewContentItemInfo: PreviewContentItemInfo | null,
  previewUrlInfo: PreviewUrlInfo,
) =>
  ({
    type: WebSpotlight_LoadItemPreviewInfo_Finished,
    payload: {
      routeContentItemIds,
      previewContentItemInfo,
      previewUrlInfo,
    },
  }) as const;

const failed = (routeContentItemIds: UuidArray, previewContentItemInfo: PreviewContentItemInfo) =>
  ({
    type: WebSpotlight_LoadItemPreviewInfo_Failed,
    payload: {
      routeContentItemIds,
      previewContentItemInfo,
    },
  }) as const;

export type LoadItemPreviewInfoActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const loadItemPreviewInfoActionCreator =
  (deps: IDeps) =>
  (routeContentItemIds: UuidArray, spaceId: Uuid | null, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      contentApp: {
        editedContentItem,
        editedContentItemVariant,
        previewConfiguration,
        editedContentItemVariantElements,
      },
      data: { collections, languages },
    } = state;

    assert(
      previewConfiguration,
      () => 'loadItemPreviewInfo.ts: "previewConfiguration" is not loaded.',
    );

    const variantId = getSelectedLanguageIdOrThrow(state);

    dispatch(started());

    const itemPageContentItemsIds = await deps.webSpotlightRepository.getPageContentItemsIds(
      variantId,
      routeContentItemIds,
      abortSignal,
    );

    const lastRouteContentItemId = Collection.getLast(routeContentItemIds);

    const lastRouteContentItemPageContentItemsIds = itemPageContentItemsIds.data.find(
      (item) => item.itemId === lastRouteContentItemId,
    )?.pageContentItemsIds;

    // It doesn't make sense to evaluate, whether edited item has preview configured, when edited item has page content item(s).
    // Preview url can be configured in this page content item or error should be returned when there are more than one Page content items linked
    const hasPageContentItems = lastRouteContentItemPageContentItemsIds?.length;

    // Use edited item data directly to prevent additional requests for data when not needed
    if (
      lastRouteContentItemId &&
      editedContentItem &&
      editedContentItem.id === lastRouteContentItemId &&
      !hasPageContentItems &&
      checkHasPreviewConfigured(
        previewConfiguration,
        editedContentItem.editedContentItemTypeId,
        spaceId,
      )
    ) {
      assert(
        editedContentItemVariant,
        () => 'loadItemPreviewInfo.ts: "editedContentItemVariant" is not loaded.',
      );
      assert(
        editedContentItemVariantElements,
        () => 'loadItemPreviewInfo.ts: "editedContentItemVariantElements" is not loaded.',
      );

      const previewContentItemInfo: PreviewContentItemInfo = {
        contentItemId: lastRouteContentItemId,
        isForLastRouteItem: true,
        isPageContent: false,
      };

      const editedItemPreviewUrlInfo = getContentItemPreviewUrlInfo(
        lastRouteContentItemId,
        editedContentItem.codename,
        editedContentItem.editedContentItemTypeId,
        editedContentItem.collectionId,
        editedContentItemVariant.id.variantId,
        editedContentItemVariantElements,
        previewConfiguration,
        languages.defaultLanguage,
        languages.byId,
        collections.byId,
        spaceId,
      );

      dispatch(finished(routeContentItemIds, previewContentItemInfo, editedItemPreviewUrlInfo));
      return;
    }

    const pageContentItemsIds = itemPageContentItemsIds.data.flatMap(
      (item) => item.pageContentItemsIds,
    );
    const uniqueContentItemIds = new Set([...routeContentItemIds, ...pageContentItemsIds]);

    await dispatch(deps.loadListingItems([...uniqueContentItemIds], abortSignal));

    const {
      data: {
        listingContentItems: { byId: listingContentItems },
      },
    } = getState();

    const previewContentItemSearchResult = selectDeepestItemWithPreview(
      routeContentItemIds,
      listingContentItems,
      previewConfiguration,
      itemPageContentItemsIds.data,
      spaceId,
    );

    if (
      previewContentItemSearchResult.error ||
      !previewContentItemSearchResult.previewContentItemInfo
    ) {
      dispatch(
        finished(routeContentItemIds, previewContentItemSearchResult.previewContentItemInfo, {
          error: previewContentItemSearchResult.error ?? PreviewError.NoPreview,
          shouldShowPreviewLink: false,
          spaceId,
          url: undefined,
          usedUrlSlug: null,
        }),
      );
      return;
    }

    const previewContentItemId =
      previewContentItemSearchResult.previewContentItemInfo.contentItemId;
    const previewContentItem = listingContentItems.get(previewContentItemId);

    try {
      assert(
        previewContentItem,
        () => 'loadItemPreviewInfo.ts: `"previewContentItem" is not loaded.',
      );

      const isTranslated = previewContentItem.variant && !previewContentItem.variant.isArchived;
      if (!isTranslated) {
        dispatch(
          finished(routeContentItemIds, previewContentItemSearchResult.previewContentItemInfo, {
            error: PreviewError.NotTranslated,
            shouldShowPreviewLink: true,
            spaceId,
            url: undefined,
            usedUrlSlug: null,
          }),
        );
        return;
      }

      const cannotViewDisabledMessage = getCannotViewItemMessage(previewContentItem);
      if (cannotViewDisabledMessage) {
        dispatch(
          finished(routeContentItemIds, previewContentItemSearchResult.previewContentItemInfo, {
            error: PreviewError.AccessDenied,
            shouldShowPreviewLink: false,
            spaceId,
            url: undefined,
            usedUrlSlug: null,
          }),
        );
        return;
      }

      // Load content item with preview url data explicitly to not influence edited item data
      const previewUrlContentItemWithVariant = await deps.contentItemRepository.getItemWithVariant(
        previewContentItemId,
        variantId,
        abortSignal,
      );
      const { editedContentItemVariantElements: parentEditedContentItemVariantElements } =
        deps.parseContentItemVariant(previewUrlContentItemWithVariant);

      const parentItemPreviewUrlInfo = getContentItemPreviewUrlInfo(
        previewContentItemId,
        previewUrlContentItemWithVariant.item.codeName,
        previewUrlContentItemWithVariant.item.type._id,
        previewUrlContentItemWithVariant.item.collectionId,
        variantId,
        parentEditedContentItemVariantElements,
        previewConfiguration,
        languages.defaultLanguage,
        languages.byId,
        collections.byId,
        spaceId,
      );

      dispatch(
        finished(
          routeContentItemIds,
          previewContentItemSearchResult.previewContentItemInfo,
          parentItemPreviewUrlInfo,
        ),
      );
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(
          failed(routeContentItemIds, previewContentItemSearchResult.previewContentItemInfo),
        );
      }

      throw error;
    }
  };
