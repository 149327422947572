import Immutable from 'immutable';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { UsageItemReference } from '../../../../_shared/utils/usage/usageUtils.ts';
import { IAsset } from '../../../../data/models/assets/Asset.ts';
import { IAssetLibraryQuery } from '../models/IAssetLibraryQuery.ts';
import { LastAssetsAction } from '../types/lastAssetsAction.ts';
import { AssetQueryOrigin } from '../utils/assetLibraryQueryUtils.ts';
import { AssetOrderingCode } from '../utils/assetListingOrderingUtils.ts';

export enum StatusMessageAction {
  None = 'None',

  ArchivedAssets = 'ArchivedAssets',
  Archiving = 'Archiving',

  ArchivedFolder = 'ArchivedFolder',
  ArchivingFolder = 'ArchivingFolders',

  MovedAssetsToCollection = 'MovedAssetsToCollection',
  MovedAssetsToFolder = 'MovedAssetsToFolder',
  Moving = 'Moving',

  MovedFolder = 'MovedFolder',
  MovingFolder = 'MovingFolder',

  MovedAssetsBackToCollection = 'MovedAssetsBackToCollection',
  MovingAssetsBackToCollection = 'MovingAssetsBackToCollection',
  MovedAssetsBackToFolder = 'MovedAssetsBackToFolder',
  MovingAssetsBackToFolder = 'MovingAssetsBackToFolder',

  MovedFolderBack = 'MovedFolderBack',
  MovingFolderBack = 'MovingFolderBack',

  RestoredAssets = 'RestoredAssets',
  Restoring = 'Restoring',

  RestoredFolder = 'RestoredFolder',
  RestoringFolder = 'RestoringFolder',

  AssigningTerms = 'AssigningTerms',
  AssignedTerms = 'AssignedTerms',
  UnassignedTerms = 'UnassignedTerms',

  Selected = 'Selected',
}

export interface IAssetUiEditor {
  readonly assetEditorLoadingStatus: LoadingStatus;
  readonly editedAsset: IAsset | null;
  readonly hasConflict: boolean;
  readonly isEditedAssetBeingSaved: boolean;
  readonly isEditedAssetModified: boolean;
}

// contains usages across all variants
export type AssetUsages = Immutable.Map<Uuid, ReadonlyArray<UsageItemReference>>;

export interface IAssetLibraryState {
  readonly assetListingScrollGridState: IScrollGridState;
  readonly assetUiEditor: IAssetUiEditor;
  readonly isAssetSelectorOpened: boolean;
  readonly lastAction: LastAssetsAction;
  readonly openedFolderId: Uuid;
  readonly potentiallyUnsortedBy: ReadonlySet<AssetOrderingCode>;
  readonly query: IAssetLibraryQuery;
  readonly queryLoadingStatus: Immutable.Map<AssetQueryOrigin, LoadingStatus>;
  readonly usage: AssetUsages;
}

export interface IScrollGridState {
  readonly availableHeightPx: number;
  readonly scrollHeightPx: number;
  readonly scrollPositionPx: number;
}
