import {
  ContentItemOpenCommentRoute,
  ContentItemOpenCommentRouteParams,
  ContentItemsAppRouteSegment,
} from '../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getCurrentProjectId } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ICommentThread } from '../../../itemEditor/models/comments/CommentThreads.ts';
import { ContentStatusCommentsTableRow as ContentStatusCommentsTableRowComponent } from '../components/ContentStatusCommentsTableRow.tsx';

type ContentStatusCommentsTableRowProps = Readonly<{
  commentThread: ICommentThread;
  itemId: Uuid;
  variantId: Uuid;
}>;

export const ContentStatusCommentsTableRow: React.FC<ContentStatusCommentsTableRowProps> = ({
  commentThread,
  itemId,
  variantId,
}) => {
  const firstThreadItem = commentThread.threadItems[0];

  const projectId = useSelector(getCurrentProjectId);
  const createdBy = useSelector((s) =>
    firstThreadItem ? s.data.users.usersById.get(firstThreadItem.createdBy) : null,
  );

  if (!firstThreadItem) {
    return null;
  }

  const linkPath = buildPath<ContentItemOpenCommentRouteParams<UuidArray>>(
    ContentItemOpenCommentRoute,
    {
      app: ContentItemsAppRouteSegment.Content,
      commentThreadId: commentThread.id,
      variantId,
      contentItemIds: [itemId],
      projectId,
      spaceId: undefined,
    },
  );

  return (
    <ContentStatusCommentsTableRowComponent
      commentThread={commentThread}
      commentThreadItem={firstThreadItem}
      createdBy={createdBy ?? null}
      linkPath={linkPath}
    />
  );
};

ContentStatusCommentsTableRow.displayName = 'ContentStatusCommentsTableRow';
