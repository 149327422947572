import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { Cache } from '../_shared/utils/cache.ts';
import { getMilliseconds } from '../_shared/utils/dateTime/timeUtils.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { CopyState } from '../applications/projects/constants/copyState.ts';
import { parseCopyState } from '../applications/projects/utils/copyStateUtils.ts';
import {
  DummyProjectsKey,
  ProjectsKey,
  touchLanguages,
  touchProjectsDependenciesAndSpecificProject,
} from './cacheKeys/projectCacheUtils.ts';
import { touchSubscriptionAndItsDependencies } from './cacheKeys/subscriptionCacheUtils.ts';
import { IProjectRepository } from './interfaces/IProjectRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import {
  ICreateEnvironmentServerModel,
  ISwapEnvironmentServerModel,
} from './serverModels/EnvironmentServerModels.type.ts';
import { FeatureFlagsServerModel } from './serverModels/FeatureFlagsServerModel.type.ts';
import { ICopyProjectDataInfoServerModel } from './serverModels/ICopyProjectDataInfoServerModel.type.ts';
import {
  ICopyProjectServerModel,
  INewProjectServerModel,
  IProjectServerModel,
} from './serverModels/IProjectServerModel.type.ts';
import { IUserProjectCacheSynchronizationStatus } from './serverModels/IUserProjectCacheSynchronizationStatus.type.ts';
import { IItemVariantCountServerModel } from './serverModels/ItemVariantCountServerModels.type.ts';
import { IProjectProperty } from './serverModels/ProjectDetailsServerModel.type.ts';
import { ProjectPropertyServerModel } from './serverModels/ProjectPropertyServerModel.type.ts';
import {
  ICustomAssetdomainStatusServerModel,
  IFallbacksForLinkedContentStatusServerModel,
  IFullTextSearchStatusServerModel,
} from './serverModels/deliveryApiServerModels.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());
const cacheExpiration = getMilliseconds({ minutes: 10 });

export const projectRepository: RepositoryWithContext<IProjectRepository> = {
  getAvailableProjectLocations: (requestContext: IRequestContext, abortSignal?: AbortSignal) => {
    const url = `${getUrlFactory().getDraftApiUrl()}/project-management/locations`;
    return Cache.cache(
      'ProjectLocations',
      ['ProjectLocations'],
      () => restProvider.get(url, null, abortSignal, requestContext),
      cacheExpiration,
      abortSignal,
    );
  },

  getMyProjects: (requestContext: IRequestContext, abortSignal?: AbortSignal) => {
    const url = `${getUrlFactory().getDraftApiUrl()}/project-management`;
    return Cache.cache(
      ProjectsKey,
      [DummyProjectsKey],
      () => restProvider.get(url, null, abortSignal, requestContext),
      cacheExpiration,
      abortSignal,
    );
  },

  getCopyState: async (
    requestContext: IRequestContext,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<CopyState> => {
    const url = `${getUrlFactory().getDraftApiUrl()}/project/${projectId}/copy-state`;
    const result: IProjectProperty = await restProvider.get(url, null, abortSignal, requestContext);
    return parseCopyState(result.value);
  },

  createProject: async (
    requestContext: IRequestContext,
    project: INewProjectServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IProjectServerModel> => {
    Cache.throwAway(DummyProjectsKey);
    const url = `${getUrlFactory().getDraftApiUrl()}/project-management`;

    try {
      const result_2 = await restProvider.post(url, project, abortSignal, requestContext);
      touchProjectsDependenciesAndSpecificProject(result_2.projectGuid);
      touchSubscriptionAndItsDependencies();
      return result_2;
    } catch (e) {
      touchProjectsDependenciesAndSpecificProject();
      throw e;
    }
  },

  getProjectDataInfo: async (
    requestContest: IRequestContext,
    projectTemplateId: string,
    abortSignal?: AbortSignal,
  ): Promise<ICopyProjectDataInfoServerModel> => {
    const url = `${getUrlFactory().getDraftProjectApiUrl(projectTemplateId)}/data-info`;

    return await restProvider.get(url, null, abortSignal, requestContest);
  },

  getProperties: (
    requestContext: IRequestContext,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ProjectPropertyServerModel[]> => {
    const url = `${getUrlFactory().getDraftProjectApiUrl(projectId)}/property`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  getFeatureFlags: (
    requestContext: IRequestContext,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<FeatureFlagsServerModel> => {
    const url = `${getUrlFactory().getDraftProjectApiUrl(projectId)}/feature-flags`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  renameProject: async (
    requestContext: IRequestContext,
    projectName: string,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IProjectServerModel> => {
    Cache.throwAway(DummyProjectsKey);
    const url = `${getUrlFactory().getDraftProjectManagementApiUrl(
      projectId,
    )}/rename-project-container`;
    try {
      const result = await restProvider.put(url, projectName, abortSignal, requestContext);
      touchProjectsDependenciesAndSpecificProject(result.projectGuid);
      return result;
    } catch (e) {
      touchProjectsDependenciesAndSpecificProject();
      throw e;
    }
  },

  getItemVariantCount: (
    requestContext: IRequestContext,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IItemVariantCountServerModel> => {
    const url = `${getUrlFactory().getDraftProjectApiUrl(projectId)}/item-variant-count`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  setLastLoginForCurrentUser: (
    requestContext: IRequestContext,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<void> => {
    const url = `${getUrlFactory().getDraftProjectManagementApiUrl(projectId)}/user/set-last-login`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },

  activateProject: async (
    requestContext: IRequestContext,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IProjectServerModel> => {
    Cache.throwAway(DummyProjectsKey);
    const url = `${getUrlFactory().getDraftProjectManagementApiUrl(projectId)}/activate`;
    try {
      const result = await restProvider.put(url, null, abortSignal, requestContext);
      touchProjectsDependenciesAndSpecificProject(result.projectGuid);
      return result;
    } catch (e) {
      touchProjectsDependenciesAndSpecificProject();
      throw e;
    }
  },

  deactivateProject: async (
    requestContext: IRequestContext,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IProjectServerModel> => {
    Cache.throwAway(DummyProjectsKey);
    const url = `${getUrlFactory().getDraftProjectManagementApiUrl(projectId)}/deactivate`;
    try {
      const result = await restProvider.put(url, null, abortSignal, requestContext);
      touchProjectsDependenciesAndSpecificProject(result.projectGuid);
      touchLanguages(result.projectGuid);
      return result;
    } catch (e) {
      touchProjectsDependenciesAndSpecificProject();
      throw e;
    }
  },

  deleteProject: async (
    requestContext: IRequestContext,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<void> => {
    Cache.throwAway(DummyProjectsKey);
    const url = `${getUrlFactory().getDraftProjectManagementApiUrl(projectId)}/delete`;

    try {
      await restProvider.put(url, null, abortSignal, requestContext);
      touchProjectsDependenciesAndSpecificProject(projectId);
      touchLanguages(projectId);
    } catch (e) {
      touchProjectsDependenciesAndSpecificProject();
      throw e;
    }
  },

  activateContentManagementApiKey(requestContext: IRequestContext, abortSignal?: AbortSignal) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/activate-cmapi`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },

  deactivateContentManagementApiKey(requestContext: IRequestContext, abortSignal?: AbortSignal) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/deactivate-cmapi`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },

  getContentManagementApiStatus(requestContext: IRequestContext, abortSignal?: AbortSignal) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/cmapi-status`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  activateDeliveryApiSecuredAccess(requestContext: IRequestContext, abortSignal?: AbortSignal) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/activate-secured-delivery`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },

  deactivateDeliveryApiSecuredAccess(requestContext: IRequestContext, abortSignal?: AbortSignal) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/deactivate-secured-delivery`;
    return restProvider.put(url, null, abortSignal, requestContext);
  },

  getSecuredDeliveryApiStatus(requestContext: IRequestContext, abortSignal?: AbortSignal) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/secured-delivery-api-status`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  getFullTextSearchStatus(
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<IFullTextSearchStatusServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/full-text-search-status`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  getCustomAssetDomainStatus(
    requestContext: IRequestContext,
    masterProjectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ICustomAssetdomainStatusServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      masterProjectId,
    )}/custom-asset-domain-status`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  getFallbacksForLinkedContentStatus(
    requestContext: IRequestContext,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IFallbacksForLinkedContentStatusServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      projectId,
    )}/fallbacks-for-linked-content-status`;
    return restProvider.get(url, null, abortSignal, requestContext);
  },

  getUserCacheStatus: (
    requestContext: IRequestContext,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IUserProjectCacheSynchronizationStatus> => {
    const injectedContext: IRequestContext = {
      ...requestContext,
      projectId,
    };
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      projectId,
    )}/user-cache-synchronization-status`;
    return restProvider.get(url, null, abortSignal, injectedContext);
  },

  copyDancingGoatSampleProjectData: (
    requestContext: IRequestContext,
    destinationProjectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IProjectServerModel> => {
    Cache.throwAway(DummyProjectsKey);
    const injectedContext: IRequestContext = {
      ...requestContext,
      projectId: destinationProjectId,
    };
    const url = `${getUrlFactory().getDraftProjectApiUrl(destinationProjectId)}/copy-sample-data`;
    return restProvider.post(url, null, abortSignal, injectedContext);
  },

  copyHealthTechSampleProjectData: (
    requestContext: IRequestContext,
    destinationProjectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IProjectServerModel> => {
    Cache.throwAway(DummyProjectsKey);
    const injectedContext: IRequestContext = {
      ...requestContext,
      projectId: destinationProjectId,
    };
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      destinationProjectId,
    )}/copy-healthtech-sample-data`;
    return restProvider.post(url, null, abortSignal, injectedContext);
  },

  copyKickstartSampleProjectData: (
    requestContext: IRequestContext,
    destinationProjectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IProjectServerModel> => {
    Cache.throwAway(DummyProjectsKey);
    const injectedContext: IRequestContext = {
      ...requestContext,
      projectId: destinationProjectId,
    };
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      destinationProjectId,
    )}/copy-kickstart-sample-data`;
    return restProvider.post(url, null, abortSignal, injectedContext);
  },

  copyGettingStartedProjectData: (
    requestContext: IRequestContext,
    destinationProjectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IProjectServerModel> => {
    Cache.throwAway(DummyProjectsKey);
    const injectedContext: IRequestContext = {
      ...requestContext,
      projectId: destinationProjectId,
    };
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      destinationProjectId,
    )}/copy-getting-started-project-data`;
    return restProvider.post(url, null, abortSignal, injectedContext);
  },

  copyProject: async (
    requestContext: IRequestContext,
    sourceProjectId: string,
    project: ICopyProjectServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IProjectServerModel> => {
    Cache.throwAway(DummyProjectsKey);
    const url = `${getUrlFactory().getDraftProjectApiUrl(sourceProjectId)}/copy-project`;

    try {
      const result = await restProvider.post(url, project, abortSignal, requestContext);
      touchProjectsDependenciesAndSpecificProject(result.projectGuid);
      touchSubscriptionAndItsDependencies();
      return result;
    } catch (e) {
      touchProjectsDependenciesAndSpecificProject();
      throw e;
    }
  },

  createEnvironment: async (
    requestContext: IRequestContext,
    sourceProjectId: Uuid,
    environment: ICreateEnvironmentServerModel,
    abortSignal?: AbortSignal,
  ): Promise<IProjectServerModel> => {
    Cache.throwAway(DummyProjectsKey);
    const url = `${getUrlFactory().getDraftProjectApiUrl(sourceProjectId)}/clone-environment`;

    try {
      const result = await restProvider.post(url, environment, abortSignal, requestContext);
      touchProjectsDependenciesAndSpecificProject(result.projectGuid);
      touchSubscriptionAndItsDependencies();
      return result;
    } catch (e) {
      touchProjectsDependenciesAndSpecificProject();
      throw e;
    }
  },

  deleteEnvironment: async (
    requestContext: IRequestContext,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<void> => {
    Cache.throwAway(DummyProjectsKey);
    const url = `${getUrlFactory().getDraftProjectManagementApiUrl(projectId)}/delete`;

    try {
      await restProvider.put(url, null, abortSignal, requestContext);
      touchProjectsDependenciesAndSpecificProject(projectId);
      touchLanguages(projectId);
    } catch (e) {
      touchProjectsDependenciesAndSpecificProject();
      throw e;
    }
  },

  renameEnvironment: async (
    requestContext: IRequestContext,
    projectName: string,
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<IProjectServerModel> => {
    Cache.throwAway(DummyProjectsKey);
    const url = `${getUrlFactory().getDraftProjectManagementApiUrl(projectId)}/rename`;

    try {
      const result = await restProvider.put(url, projectName, abortSignal, requestContext);
      touchProjectsDependenciesAndSpecificProject(result.projectGuid);
      return result;
    } catch (e) {
      touchProjectsDependenciesAndSpecificProject();
      throw e;
    }
  },

  swapEnvironments: async (
    requestContext: IRequestContext,
    environmentId: Uuid,
    oldProductionId: Uuid,
    swapEnvironmentInfo: ISwapEnvironmentServerModel,
    abortSignal?: AbortSignal,
  ): Promise<void> => {
    Cache.throwAway(DummyProjectsKey);
    const url = `${getUrlFactory().getDraftProjectApiUrl(environmentId)}/swap`;

    try {
      await restProvider.post(url, swapEnvironmentInfo, abortSignal, requestContext);
      touchProjectsDependenciesAndSpecificProject(environmentId);
      touchProjectsDependenciesAndSpecificProject(oldProductionId);
    } catch (e) {
      touchProjectsDependenciesAndSpecificProject();
      throw e;
    }
  },
};
