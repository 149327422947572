import { Action } from '../../../../@types/Action.type.ts';
import {
  AuditLog_Listing_Left,
  AuditLog_Search_Started,
} from '../../../../applications/environmentSettings/auditLog/constants/auditLogActionTypes.ts';
import {
  Data_AuditEvents_Failed,
  Data_AuditEvents_Success,
} from '../../../constants/dataActionTypes.ts';
import {
  IAuditEvent,
  createAuditEventsFromServerModel,
} from '../../../models/auditLog/AuditEvent.ts';

const initialState: ReadonlyArray<IAuditEvent> = [];

export function events(
  state: ReadonlyArray<IAuditEvent> = initialState,
  action: Action,
): ReadonlyArray<IAuditEvent> {
  switch (action.type) {
    case Data_AuditEvents_Failed:
    case AuditLog_Listing_Left:
    case AuditLog_Search_Started:
      return initialState;

    case Data_AuditEvents_Success: {
      const auditEvents = createAuditEventsFromServerModel(action.payload).auditEvents;
      return [...state, ...auditEvents];
    }

    default:
      return state;
  }
}
