import {
  AssetElementUpdateData,
  LinkedItemsElementUpdateData,
  MultipleChoiceElementUpdateData,
  TaxonomyElementUpdateData,
} from '@kontent-ai/smart-link';
import { notNullNorUndefined } from '@kontent-ai/utils';
import { GetState } from '../../../../@types/Dispatcher.type.ts';
import { IAssetRendition } from '../../../../data/models/assetRenditions/AssetRendition.ts';
import { IAsset } from '../../../../data/models/assets/Asset.ts';
import { IMultipleChoiceTypeElement } from '../../../contentInventory/content/models/contentTypeElements/MultipleChoiceTypeElement.ts';
import { ITaxonomyTypeElement } from '../../../contentInventory/content/models/contentTypeElements/TaxonomyTypeElement.ts';
import { getQueryString } from '../../../itemEditor/features/ContentItemEditing/utils/renditionUtils.ts';
import { IAssetItemElement } from '../../../itemEditor/models/contentItemElements/AssetItemElement.ts';
import { IMultipleChoiceItemElement } from '../../../itemEditor/models/contentItemElements/MultipleChoiceItemElement.ts';
import { ITaxonomyItemElement } from '../../../itemEditor/models/contentItemElements/TaxonomyItemElement.ts';
import { ITextItemElement } from '../../../itemEditor/models/contentItemElements/TextItemElement.ts';
import { ILinkedItemsElement } from '../../../itemEditor/models/contentItemElements/modularItems/ILinkedItemsElement.ts';

export const getPlainText = (element: ITextItemElement): string =>
  element._editorState.getCurrentContent().getPlainText('');

export const getMultipleChoiceValues = (
  element: IMultipleChoiceItemElement,
  typeElement: IMultipleChoiceTypeElement,
): MultipleChoiceElementUpdateData['data']['value'] =>
  element.options.map((optionId: Uuid) => {
    const option = typeElement.options[optionId];
    return {
      id: optionId,
      name: option?.label || '',
      codename: option?.codename || '',
    };
  });

export const getTaxonomyData = (
  element: ITaxonomyItemElement,
  typeElement: ITaxonomyTypeElement,
  getState: GetState,
): TaxonomyElementUpdateData['data'] | null => {
  const taxonomy = typeElement.taxonomyGroupId
    ? getState().data.taxonomyGroups.byId.get(typeElement.taxonomyGroupId)
    : undefined;
  if (!taxonomy) {
    return null;
  }

  return {
    taxonomyGroup: taxonomy.codename,
    value: [...element.value]
      .map((termId) => taxonomy.terms.get(termId))
      .filter(notNullNorUndefined)
      .map((term) => ({
        name: term.name,
        codename: term.codename,
      })),
  };
};

type SmartLinkSdkAssetRendition = Exclude<
  AssetElementUpdateData['data']['value'][number]['renditions'],
  null
>[string];

const getRendition = (asset: IAsset, rendition: IAssetRendition): SmartLinkSdkAssetRendition => {
  const query = getQueryString(rendition.transformation);

  return {
    rendition_id: rendition.renditionId,
    preset_id: rendition.presetId,
    width: rendition.imageWidth,
    height: rendition.imageHeight,
    query,
    url: `${asset._deliveryLink}?${query}`,
  };
};

export const getAssetData = (
  element: IAssetItemElement,
  getState: GetState,
): AssetElementUpdateData['data'] | null => {
  const {
    sharedApp: {
      selectedLanguage: { id: selectedLanguageId },
    },
    data: {
      assets: { byId: assetsById },
      assetRenditions: { byId: assetRenditionsById },
    },
  } = getState();

  if (!selectedLanguageId) {
    return null;
  }

  return {
    value: element.value
      .toArray()
      .map((assetReference) => {
        const asset = assetsById.get(assetReference.id);
        if (!asset?._deliveryLink) {
          return null;
        }

        const rendition = assetReference.renditions
          .map(({ id: renditionId }) => assetRenditionsById.get(renditionId))
          .filter(notNullNorUndefined)?.[0];

        return {
          url: asset._deliveryLink,
          name: asset.filename,
          type: asset.type,
          size: asset.fileSize,
          height: asset.height,
          width: asset.width,
          description: asset.descriptions.get(selectedLanguageId) ?? null,
          renditions: rendition ? { default: getRendition(asset, rendition) } : null,
        };
      })
      .filter(notNullNorUndefined),
  };
};

export const getLinkedItemData = (
  element: ILinkedItemsElement,
  getState: GetState,
): LinkedItemsElementUpdateData['data'] => {
  const {
    data: {
      listingContentItems: { byId: listingContentItemsById },
    },
  } = getState();

  return {
    linkedItems: [],
    value: element.value
      .map((itemId) => {
        const listingContentItem = listingContentItemsById.get(itemId);
        if (!listingContentItem) {
          return null;
        }

        return listingContentItem.item.codeName ?? null;
      })
      .filter(notNullNorUndefined),
  };
};
