import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { IItemsSelection } from '../../../../stores/IContentAppStoreState.ts';

export enum ItemSelectionState {
  AllSelected = 'allSelected',
  SomeSelected = 'someSelected',
  ExactNumberSelected = 'exactNumberSelected',
}

export const isItemSelected = (itemsSelection: IItemsSelection, itemId: Uuid): boolean =>
  itemsSelection.selectedItemIds.includes(itemId) ||
  (itemsSelection.selectAll && !itemsSelection.unselectedItemIds.includes(itemId));

/* If not all items are loaded, the result might be missing some selected items not yet loaded.
   Mind you, we're executing bulk operations on BE using a query, therefore selected items here might not be items selected for a bulk operation.
 */
export const getSelectedItems = (state: IStore): ReadonlySet<Uuid> => {
  const {
    contentApp: { listingUi },
    data: { listingContentItems },
  } = state;

  if (listingUi.itemsSelection.selectAll) {
    if (!listingContentItems.allIds) {
      return new Set();
    }

    const actualSelectedItems = listingContentItems.allIds.filter(
      (id) => !listingUi.itemsSelection.unselectedItemIds.includes(id),
    );

    return new Set(actualSelectedItems);
  }

  return new Set(listingUi.itemsSelection.selectedItemIds);
};

// if not all items are loaded, the value should be treated as an estimate of minimum number of selected items
export const totalNumberOfSelectedItems = (state: IStore): number => getSelectedItems(state).size;

export const isSomethingSelected = (state: IStore): boolean => {
  const {
    contentApp: { listingUi },
    data: { listingContentItems },
  } = state;

  return (
    (listingUi.itemsSelection.selectAll && !!listingContentItems.nextContinuationToken) ||
    totalNumberOfSelectedItems(state) > 0
  );
};

export const getItemSelectionState = (state: IStore): ItemSelectionState => {
  const { contentApp, data } = state;

  const isOnlyEstimate =
    contentApp.listingUi.itemsSelection.selectAll &&
    data.listingContentItems.nextContinuationToken !== null;
  const anyUnselected = !!contentApp.listingUi.itemsSelection.unselectedItemIds.length;

  if (isOnlyEstimate && !anyUnselected) {
    return ItemSelectionState.AllSelected;
  }

  if (isOnlyEstimate && anyUnselected) {
    return ItemSelectionState.SomeSelected;
  }

  return ItemSelectionState.ExactNumberSelected;
};
