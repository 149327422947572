import React from 'react';
import { modalOpened } from '../../../../../../../_shared/actions/sharedActions.ts';
import { ModalDialogType } from '../../../../../../../_shared/constants/modalDialogType.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { isProjectMultilingual } from '../../../../../../../_shared/selectors/isProjectMultilingual.ts';
import { getLanguageName } from '../../../../../../../_shared/utils/languageSwitchers/languageSwitcherUtils.ts';
import { hasCapabilityInLanguage } from '../../../../../../../_shared/utils/permissions/capabilitiesInLanguageUtils.ts';
import { Capability } from '../../../../../../../_shared/utils/permissions/capability.ts';
import { getAllLanguagesWithDefaultSuffix } from '../../../../../../../data/reducers/languages/selectors/getLanguages.ts';
import { getUniqueCollectionIdsFromSpaceIds } from '../../../../../../environmentSettings/spaces/utils/spacesUtils.ts';
import { prepareNewContentItemDialogForListing } from '../../../../../../itemEditor/features/NewContentItem/actions/thunkNewContentItemActions.ts';
import {
  areListingFilterAndSearchEmpty,
  isListingFilterEmpty,
} from '../../../../models/filter/IListingFilter.ts';
import {
  clearContentItemListingFilter,
  updateFilterSearchPhrase,
} from '../../actions/thunkContentItemInventoryActions.ts';
import { ContentItemScrollTableEmptyState as ContentItemScrollTableEmptyStateComponent } from '../../components/ItemInventoryScrollTable/ContentItemScrollTableEmptyState.tsx';

interface IContainerProps {
  readonly forcedContentTypeIds?: ReadonlySet<Uuid> | undefined;
  readonly isInDialog: boolean;
}

export const ContentItemScrollTableEmptyState: React.FC<IContainerProps> = (props) => {
  const dispatch = useDispatch();

  const listingContentItems = useSelector((state) => state.data.listingContentItems);
  const filter = useSelector((state) => state.contentApp.listingUi.filter);

  const isMultilingual = useSelector(isProjectMultilingual);
  const selectedLanguageId = useSelector(getSelectedLanguageIdOrThrow);
  const languages = useSelector((state) => state.data.languages);
  const selectedLanguageName = getLanguageName(
    selectedLanguageId,
    getAllLanguagesWithDefaultSuffix(languages),
  );
  const filterIsActive = !areListingFilterAndSearchEmpty(filter);
  const viewIsEmpty = !listingContentItems.allIds || !listingContentItems.allIds.length;
  const canCreate = useSelector((state) =>
    hasCapabilityInLanguage(state, Capability.CreateContent, selectedLanguageId),
  );

  // KCL-12266 Remove isSpaceAndCollectionMismatched during April
  const spacesById = useSelector((state) => state.data.spaces.byId);
  const spaceCollectionIds = getUniqueCollectionIdsFromSpaceIds(
    spacesById,
    filter.selectedSpacesNodes,
  );
  const isSpaceAndCollectionMismatched = [...filter.selectedCollectionsNodes].some(
    (selectedCollectionId) => !spaceCollectionIds.has(selectedCollectionId),
  );

  return (
    <ContentItemScrollTableEmptyStateComponent
      canCreate={canCreate}
      inventoryIsEmpty={!filterIsActive && viewIsEmpty}
      isFilterEmpty={isListingFilterEmpty(filter)}
      isMultilingualProject={isMultilingual}
      noFilteredResults={filterIsActive && viewIsEmpty}
      onClearFilter={() => {
        dispatch(clearContentItemListingFilter(props.forcedContentTypeIds));
      }}
      onClearSearchPhrase={() => dispatch(updateFilterSearchPhrase('', props.isInDialog))}
      onCreateNewItem={() => {
        dispatch(
          prepareNewContentItemDialogForListing({
            allowedContentTypeIds: null,
          }),
        );
        dispatch(modalOpened(ModalDialogType.NewContentItemDialog));
      }}
      searchPhrase={filter.searchPhrase}
      selectedLanguageName={selectedLanguageName}
      isSpaceAndCollectionMismatched={isSpaceAndCollectionMismatched}
    />
  );
};
