import { useAttachRef } from '@kontent-ai/hooks';
import { useButton } from '@react-aria/button';
import { mergeProps } from '@react-aria/utils';
import { PressEvent } from '@react-types/shared';
import React, { PropsWithChildren } from 'react';

interface IOutwardLinkProps {
  readonly className?: string;
  readonly contentEditable?: boolean;
  readonly href: string;
  readonly onClick?: (e: PressEvent) => void;
  readonly stopClickPropagation?: boolean;
  readonly title?: string;
}

export const OutwardLink = React.forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<IOutwardLinkProps>
>(
  (
    {
      children,
      className,
      contentEditable,
      href,
      onClick,
      stopClickPropagation,
      title,
      ...otherProps
    },
    forwardedRef,
  ) => {
    const { refObject, refToForward } = useAttachRef(forwardedRef);
    const { buttonProps } = useButton(
      {
        elementType: 'a',
        target: '_blank',
        rel: 'noopener noreferrer',
        href,
        onPress: (event) => {
          if (!stopClickPropagation) {
            event.continuePropagation();
          }

          if (onClick) {
            onClick(event);
          }
        },
      },
      refObject,
    );

    return (
      <a
        ref={refToForward}
        className={className}
        contentEditable={contentEditable}
        title={title}
        {...mergeProps(otherProps, buttonProps)}
      >
        {children ?? href}
      </a>
    );
  },
);

OutwardLink.displayName = 'OutwardLink';
