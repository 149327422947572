import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { AutoScrollId } from '../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { NoUsage } from '../../../../../_shared/components/Usages/NoUsage.tsx';
import { SortedAssetUsages } from '../../../../../_shared/utils/assets/assetUtils.ts';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ILanguage, Languages } from '../../../../../data/models/languages/Language.ts';
import { getLanguageName } from '../../../../environmentSettings/localization/utils/languageUtils.ts';
import { SectionTitle } from '../../../../itemEditor/features/ContentItemEditing/components/details/SectionTitle.tsx';
import { assetHasNoUsage, loadingAssetUsage } from '../../constants/uiConstants.ts';
import { SingleAssetUsage } from '../../containers/AssetEditing/SingleAssetUsage.tsx';

type Props = {
  readonly assetId: Uuid;
  readonly defaultLanguage: ILanguage;
  readonly isInDialog: boolean;
  readonly isUsageLoaded: boolean;
  readonly languages: Languages;
  readonly projectId: Uuid;
  readonly scrollId?: AutoScrollId;
  readonly sortedLanguageUsage: SortedAssetUsages;
};

export const AssetUsage = ({
  assetId,
  defaultLanguage,
  isInDialog,
  isUsageLoaded,
  languages,
  projectId,
  scrollId,
  sortedLanguageUsage,
}: Props) => {
  if (!sortedLanguageUsage.length) {
    return (
      <div className="sidebar__section sidebar__section--contains-hover asset-usage">
        <NoUsage>{isUsageLoaded ? assetHasNoUsage : loadingAssetUsage}</NoUsage>
      </div>
    );
  }

  return (
    <div className="sidebar__section sidebar__section--contains-hover asset-usage">
      {sortedLanguageUsage.map(({ refs, variantId }) => {
        const languageName = getLanguageName(variantId, defaultLanguage, languages);

        return (
          <div
            key={variantId}
            className="asset-usage__section"
            {...(languageName && getDataUiObjectNameAttribute(languageName))}
          >
            <Box paddingX={Spacing.XXL}>
              <SectionTitle>Used in {languageName}</SectionTitle>
            </Box>
            <div {...getDataUiCollectionAttribute(DataUiCollection.ContentItemUsages)}>
              {assetId &&
                refs.map((assetUsage) => (
                  <div
                    key={assetUsage.primary.id.itemId}
                    {...getDataUiElementAttribute(DataUiElement.UsedInItem)}
                  >
                    <SingleAssetUsage
                      assetId={assetId}
                      isInDialog={isInDialog}
                      projectId={projectId}
                      scrollId={scrollId}
                      usage={assetUsage}
                    />
                  </div>
                ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};
