import { Action } from '../../../../../@types/Action.type.ts';
import { ValidationConstants } from '../../../../../_shared/constants/validationConstants.ts';
import { moveToIndex } from '../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { moveItem } from '../../../../../_shared/utils/dragDrop/dragDropUtils.ts';
import { isEmptyOrWhitespace } from '../../../../../_shared/utils/stringUtils.ts';
import {
  IContentType,
  emptyContentType,
} from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IContentGroup } from '../../../../contentInventory/content/models/contentTypeElements/types/ContentGroup.ts';
import { AssetType_Editor_SavingFinished } from '../../../assetTypes/constants/assetTypeActionTypes.ts';
import {
  ContentType_Creator_CreationFinished,
  ContentType_Creator_InitStarted,
  ContentType_Editor_ChangeCodename,
  ContentType_Editor_ChangeContentTypeName,
  ContentType_Editor_ChangeMultipleChoiceOptionCodename,
  ContentType_Editor_ChangeTypeElementCodename,
  ContentType_Editor_ContentGroupCodenameRenamed,
  ContentType_Editor_ContentGroupCreated,
  ContentType_Editor_ContentGroupDeleted,
  ContentType_Editor_ContentGroupMoveFinished,
  ContentType_Editor_ContentGroupMoved,
  ContentType_Editor_ContentGroupRenamed,
  ContentType_Editor_DuplicatingFinished,
  ContentType_Editor_MultipleChoiceCreateOption,
  ContentType_Editor_MultipleChoiceDeleteOption,
  ContentType_Editor_MultipleChoiceMoveOption,
  ContentType_Editor_MultipleChoiceUpdateOption,
  ContentType_Editor_SavingFinished,
  ContentType_Editor_SelectedTypeElementsSentToContentGroup,
  ContentType_Editor_SelectedTypeElementsSentToNewContentGroup,
} from '../../../contentTypes/constants/contentTypesActionTypes.ts';
import {
  ContentModels_TypeEditor_AssetTypeLoaded,
  ContentModels_TypeEditor_InitStarted,
  ContentModels_TypeEditor_RemoveTypeElement,
  ContentModels_TypeEditor_TypeElementCreated,
  ContentModels_TypeEditor_TypeElementMoved,
  ContentModels_TypeEditor_TypeElementUpdated,
  ContentModels_TypeEditor_TypeLoaded,
} from '../../constants/sharedContentModelsActionTypes.ts';
import { IMultipleChoiceTypeElementData } from '../../models/elements/MultipleChoiceTypeElementData.ts';
import { IBaseTypeElementData } from '../../models/elements/types/TypeElementData.ts';
import { isMultipleChoiceTypeElement } from '../../types/typeElementTypeGuards.ts';
import {
  getElementsSortedByContentGroups,
  getUpdatedElements,
  sendTypeElementsToContentGroup,
} from '../../utils/typeUtils.ts';

const defaultState = emptyContentType;

export function editedType(state: IContentType = defaultState, action: Action): IContentType {
  switch (action.type) {
    case ContentType_Creator_CreationFinished:
    case ContentModels_TypeEditor_AssetTypeLoaded:
    case ContentModels_TypeEditor_TypeLoaded: {
      return action.payload.editedContentType;
    }

    case ContentType_Editor_SavingFinished:
    case AssetType_Editor_SavingFinished: {
      return action.payload.contentType;
    }

    case ContentModels_TypeEditor_InitStarted:
    case ContentType_Editor_DuplicatingFinished:
    case ContentType_Creator_InitStarted: {
      return defaultState;
    }

    case ContentType_Editor_ChangeContentTypeName: {
      const name = action.payload.name;
      if (isEmptyOrWhitespace(name)) {
        return {
          ...state,
          name: defaultState.name,
        };
      }
      const nameWithEnsuredLength = name.substring(0, ValidationConstants.TypeNameMaxLength);
      return {
        ...state,
        name: nameWithEnsuredLength,
      };
    }

    case ContentType_Editor_ChangeCodename: {
      const codename = action.payload.codename;
      return {
        ...state,
        codename,
      };
    }

    case ContentType_Editor_ContentGroupCreated: {
      const contentGroup = action.payload.contentGroup;
      const updatedContentGroups = [...state.contentGroups, contentGroup];
      const updatedElements = state.typeElements.map((element) =>
        element.contentGroupId
          ? element
          : {
              ...element,
              contentGroupId: contentGroup.id,
            },
      );

      return {
        ...state,
        contentGroups: updatedContentGroups,
        typeElements: updatedElements,
      };
    }

    case ContentType_Editor_ContentGroupDeleted: {
      return {
        ...state,
        contentGroups: state.contentGroups.filter(
          (group: IContentGroup) => group.id !== action.payload.contentGroupId,
        ),
      };
    }

    case ContentType_Editor_ContentGroupMoved: {
      const { movedContentGroupId, targetContentGroupId } = action.payload;

      const contentGroups = state.contentGroups;
      const movedGroup = contentGroups.find(
        (group: IContentGroup) => group.id === movedContentGroupId,
      );
      const updatedGroups = movedGroup
        ? moveItem(contentGroups, movedGroup.id, targetContentGroupId, (group) => group.id)
        : contentGroups;

      return {
        ...state,
        contentGroups: updatedGroups,
      };
    }

    case ContentType_Editor_ContentGroupMoveFinished: {
      return {
        ...state,
        typeElements: getElementsSortedByContentGroups(state.typeElements, state.contentGroups),
      };
    }

    case ContentType_Editor_ContentGroupRenamed: {
      const { contentGroupId, newContentGroupName } = action.payload;
      const nameWithEnsuredLength = newContentGroupName.substring(
        0,
        ValidationConstants.ContentGroupNameMaxLength,
      );
      return {
        ...state,
        contentGroups: state.contentGroups.map((group: IContentGroup) => {
          return group.id === contentGroupId
            ? {
                ...group,
                name: nameWithEnsuredLength,
              }
            : group;
        }),
      };
    }

    case ContentType_Editor_ContentGroupCodenameRenamed: {
      const { contentGroupId, newContentGroupCodename } = action.payload;
      const nameWithEnsuredLength = newContentGroupCodename.substring(
        0,
        ValidationConstants.ContentGroupCodeNameMaxLength,
      );
      return {
        ...state,
        contentGroups: state.contentGroups.map((group: IContentGroup) => {
          return group.id === contentGroupId
            ? {
                ...group,
                codename: nameWithEnsuredLength,
              }
            : group;
        }),
      };
    }

    case ContentModels_TypeEditor_TypeElementCreated: {
      const { position, typeElementData } = action.payload;
      return {
        ...state,
        typeElements: [
          ...state.typeElements.slice(0, position),
          typeElementData,
          ...state.typeElements.slice(position),
        ],
      };
    }

    case ContentModels_TypeEditor_TypeElementMoved: {
      const { sourceId, targetId } = action.payload;
      if (sourceId === targetId) {
        return state;
      }

      const newTypeElements = moveItem(
        state.typeElements,
        sourceId,
        targetId,
        (element) => element.elementId,
      );
      if (newTypeElements === state.typeElements) {
        return state;
      }

      return {
        ...state,
        typeElements: newTypeElements,
      };
    }

    case ContentModels_TypeEditor_TypeElementUpdated: {
      const updatedTypeElement = action.payload.updatedTypeElement;
      const newElement: IBaseTypeElementData = {
        ...updatedTypeElement,
        name: isEmptyOrWhitespace(updatedTypeElement.name) ? '' : updatedTypeElement.name,
        guidelines: isEmptyOrWhitespace(updatedTypeElement.guidelines)
          ? ''
          : updatedTypeElement.guidelines,
      };

      return {
        ...state,
        typeElements: getUpdatedElements(state.typeElements, newElement),
      };
    }

    case ContentType_Editor_SelectedTypeElementsSentToContentGroup: {
      const { selectedTypeElementIds, contentGroupId } = action.payload;

      const updatedElements = sendTypeElementsToContentGroup(
        selectedTypeElementIds,
        contentGroupId,
        state.typeElements,
        state.contentGroups,
      );

      return {
        ...state,
        typeElements: updatedElements,
      };
    }

    case ContentType_Editor_SelectedTypeElementsSentToNewContentGroup: {
      const { selectedTypeElementIds, contentGroup } = action.payload;

      const updatedContentGroups = [...state.contentGroups, contentGroup];
      const updatedElements = sendTypeElementsToContentGroup(
        selectedTypeElementIds,
        contentGroup.id,
        state.typeElements,
        updatedContentGroups,
      );

      return {
        ...state,
        contentGroups: updatedContentGroups,
        typeElements: updatedElements,
      };
    }

    case ContentModels_TypeEditor_RemoveTypeElement: {
      return {
        ...state,
        typeElements: state.typeElements.filter(
          (element) => element.elementId !== action.payload.typeElementId,
        ),
      };
    }

    case ContentType_Editor_ChangeTypeElementCodename: {
      const { codename, elementId } = action.payload;
      const oldElement = state.typeElements.find((element) => element.elementId === elementId);
      if (!oldElement) {
        return state;
      }

      const newElement: IBaseTypeElementData = {
        ...oldElement,
        codename,
      };

      return {
        ...state,
        typeElements: getUpdatedElements(state.typeElements, newElement),
      };
    }

    case ContentType_Editor_MultipleChoiceCreateOption: {
      const { elementId, option } = action.payload;
      const editedMultipleChoiceTypeElement = state.typeElements.find(
        (element) => element.elementId === elementId,
      );
      if (!isMultipleChoiceTypeElement(editedMultipleChoiceTypeElement)) {
        return state;
      }

      if (
        !editedMultipleChoiceTypeElement ||
        Object.keys(editedMultipleChoiceTypeElement.options).length >=
          ValidationConstants.MaxNumberOfMultipleChoiceOptions
      ) {
        return state;
      }

      const updatedElement: IMultipleChoiceTypeElementData = {
        ...editedMultipleChoiceTypeElement,
        options: {
          ...editedMultipleChoiceTypeElement.options,
          [option.id]: {
            id: option.id,
            label: option.label.substring(0, ValidationConstants.MultipleChoiceOptionNameMaxLength),
            codename: option.codename,
          },
        },
        optionsOrder: editedMultipleChoiceTypeElement.optionsOrder.concat(option.id),
      };

      return {
        ...state,
        typeElements: getUpdatedElements(state.typeElements, updatedElement),
      };
    }

    case ContentType_Editor_MultipleChoiceUpdateOption: {
      const { elementId, option } = action.payload;
      const editedMultipleChoiceTypeElement = state.typeElements
        .filter(isMultipleChoiceTypeElement)
        .find((element) => element.elementId === elementId);

      const optionExists = !!editedMultipleChoiceTypeElement?.options[option.id];
      if (!option.label || !editedMultipleChoiceTypeElement || !optionExists) {
        return state;
      }

      const updatedElement: IMultipleChoiceTypeElementData = {
        ...editedMultipleChoiceTypeElement,
        options: {
          ...editedMultipleChoiceTypeElement.options,
          [option.id]: {
            id: option.id,
            label: option.label.substring(0, ValidationConstants.MultipleChoiceOptionNameMaxLength),
            codename: option.codename,
          },
        },
      };

      return {
        ...state,
        typeElements: getUpdatedElements(state.typeElements, updatedElement),
      };
    }

    case ContentType_Editor_MultipleChoiceDeleteOption: {
      const { elementId, optionId } = action.payload;
      const editedMultipleChoiceTypeElement = state.typeElements.find(
        (element) => element.elementId === elementId,
      );
      if (!isMultipleChoiceTypeElement(editedMultipleChoiceTypeElement) || !optionId) {
        return state;
      }
      const updatedElement: IMultipleChoiceTypeElementData = {
        ...editedMultipleChoiceTypeElement,
        defaultValue: editedMultipleChoiceTypeElement.defaultValue.filter((id) => id !== optionId),
        options: Object.fromEntries(
          Object.entries(editedMultipleChoiceTypeElement.options).filter(
            ([key]) => key !== optionId,
          ),
        ),
        optionsOrder: editedMultipleChoiceTypeElement.optionsOrder.filter((id) => id !== optionId),
      };

      return {
        ...state,
        typeElements: getUpdatedElements(state.typeElements, updatedElement),
      };
    }

    case ContentType_Editor_MultipleChoiceMoveOption: {
      const { elementId, draggedOptionId, targetOptionId } = action.payload;
      const editedMultipleChoiceTypeElement = state.typeElements.find(
        (element) => element.elementId === elementId,
      );
      if (
        !isMultipleChoiceTypeElement(editedMultipleChoiceTypeElement) ||
        !draggedOptionId ||
        !targetOptionId
      ) {
        return state;
      }

      const editedOptionsOrder = editedMultipleChoiceTypeElement.optionsOrder;
      const draggedOptionIndex = editedOptionsOrder.indexOf(draggedOptionId);
      const targetOptionIndex = editedOptionsOrder.indexOf(targetOptionId);
      if (draggedOptionIndex < 0 || targetOptionIndex < 0) {
        return state;
      }

      const updatedOptionsOrder = moveToIndex(
        editedOptionsOrder,
        draggedOptionId,
        targetOptionIndex,
      );

      const updatedElement: IMultipleChoiceTypeElementData = {
        ...editedMultipleChoiceTypeElement,
        optionsOrder: updatedOptionsOrder,
      };

      return {
        ...state,
        typeElements: getUpdatedElements(state.typeElements, updatedElement),
      };
    }

    case ContentType_Editor_ChangeMultipleChoiceOptionCodename: {
      const { codename, elementId, optionId } = action.payload;
      const editedMultipleChoiceTypeElement = state.typeElements.find(
        (element) => element.elementId === elementId,
      );
      if (!isMultipleChoiceTypeElement(editedMultipleChoiceTypeElement)) {
        return state;
      }

      const originalElement = editedMultipleChoiceTypeElement.options[optionId];
      const updatedElement: IMultipleChoiceTypeElementData = {
        ...editedMultipleChoiceTypeElement,
        options: {
          ...editedMultipleChoiceTypeElement.options,
          [optionId]: {
            id: originalElement?.id ?? '',
            label: originalElement?.label ?? '',
            codename,
          },
        },
      };

      return {
        ...state,
        typeElements: getUpdatedElements(state.typeElements, updatedElement),
      };
    }

    default:
      return state;
  }
}
