import { noOperation } from '@kontent-ai/utils';
import React, { createContext, useState, useMemo, useContext, useCallback, useEffect } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectId } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ItemEditorLayoutStorage } from '../../../../../localStorages/itemEditorLayoutStorage.ts';

type PreviewContextState = Readonly<{
  isPreviewOpened: boolean;
  setIsPreviewOpened: (newState: boolean) => void;
}>;

const PreviewContext = createContext<PreviewContextState>({
  isPreviewOpened: false,
  setIsPreviewOpened: noOperation,
});

export const PreviewContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const currentEnvironmentId = useSelector(getCurrentProjectId);

  const [isPreviewOpened, setIsPreviewOpened] = useState(false);

  const saveIsPreviewOpenedToLocalStorage = useCallback(
    (previewState: boolean): void => {
      ItemEditorLayoutStorage.save({ isPreviewOpened: previewState }, currentEnvironmentId);
    },
    [currentEnvironmentId],
  );

  useEffect(() => {
    const lastPreviewState = ItemEditorLayoutStorage.load(currentEnvironmentId).isPreviewOpened;
    setIsPreviewOpened(lastPreviewState);
  }, [currentEnvironmentId]);

  const state: PreviewContextState = useMemo(
    () => ({
      isPreviewOpened,
      setIsPreviewOpened: (previewState: boolean) => {
        setIsPreviewOpened(previewState);
        saveIsPreviewOpenedToLocalStorage(previewState);
      },
    }),
    [isPreviewOpened, saveIsPreviewOpenedToLocalStorage],
  );

  return <PreviewContext.Provider value={state}>{children}</PreviewContext.Provider>;
};

export const usePreviewContext = () => useContext(PreviewContext);
