import { Box } from '@kontent-ai/component-library/Box';
import {
  borderWidthDefault,
  colorBorderLowEmphasis,
  px,
} from '@kontent-ai/component-library/tokens';
import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { isGuidelinesTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { ContentItemElement } from './ContentItemElement.tsx';
import { ContentItemPaneElementsGroupCompact } from './ContentItemPaneElementsGroupCompact.tsx';

type ContentItemElementGroupCompactProps = Readonly<{
  canEdit: boolean;
  typeElements: ReadonlyArray<TypeElement>;
}>;

export const ContentItemElementGroupCompact = ({
  canEdit,
  typeElements,
}: ContentItemElementGroupCompactProps) => {
  return (
    <Box css={`border-bottom: ${px(borderWidthDefault)} solid ${colorBorderLowEmphasis}`}>
      <ContentItemPaneElementsGroupCompact
        isDisabled={!canEdit}
        isGuideline={isGuidelinesTypeElement(typeElements[0])}
      >
        {typeElements.map((typeElement) => (
          <ContentItemElement
            key={typeElement.elementId}
            canEdit={canEdit}
            typeElement={typeElement}
          />
        ))}
      </ContentItemPaneElementsGroupCompact>
    </Box>
  );
};
